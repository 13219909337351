var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EnvironmentService, } from 'src/app/shared/services/environment/environment.service';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { MatSidenav } from '@angular/material';
import { takeWhile } from 'rxjs/operators';
import { MediaObserver } from '@angular/flex-layout';
var UiMerchantChromeComponent = (function () {
    function UiMerchantChromeComponent(router, identityService, environmentService, mediaObserver) {
        this.router = router;
        this.identityService = identityService;
        this.environmentService = environmentService;
        this.mediaObserver = mediaObserver;
        this.opened = false;
        this.showTopbar = false;
        this.displayVersionInfo = 'unknown version';
        this.mobileMode = false;
        this.hideTopAndSideBar = false;
    }
    UiMerchantChromeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkParentWindow();
        this.isIFrameSignUp = location.href.endsWith('/signup-iframe');
        this.componentActive = true;
        this.initMediaListener();
        this.identityService.watchActiveUser().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (user) {
            _this.showTopbar = !!user && !_this.isIFrameSignUp;
        });
        if (this.router.url.indexOf('/pos') > -1) {
            this.hideTopAndSideBar = true;
        }
        this.router.events.pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/pos') > -1) {
                    _this.hideTopAndSideBar = true;
                }
                else {
                    _this.hideTopAndSideBar = false;
                    _this.checkParentWindow();
                }
                if (_this.mobileMode) {
                    setTimeout(function () {
                        if (_this.sidenav) {
                            _this.sidenav.close();
                        }
                        _this.opened = false;
                    }, 200);
                }
                _this.handleRedirect(event.url);
            }
        });
        this.populateEnvironmentInfo();
        this.loadInitialUser();
        this.handleRedirect();
    };
    UiMerchantChromeComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    UiMerchantChromeComponent.prototype.initMediaListener = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isNative;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.environmentService.isNative()];
                    case 1:
                        isNative = _a.sent();
                        this.mobileMode = isNative || this.mediaObserver.isActive('lt-md');
                        this.opened = !this.mobileMode;
                        this.mediaObserver.media$.pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function () {
                            _this.mobileMode = isNative || _this.mediaObserver.isActive('lt-md');
                        });
                        return [2];
                }
            });
        });
    };
    UiMerchantChromeComponent.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sidenav.close();
                        return [4, this.identityService.logout().toPromise()];
                    case 1:
                        _a.sent();
                        this.router.navigateByUrl('/login');
                        return [2];
                }
            });
        });
    };
    UiMerchantChromeComponent.prototype.loadInitialUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.identityService.isLoaded()];
                    case 1:
                        _a.sent();
                        this.showTopbar = !!this.identityService.getActiveUser() && !this.isIFrameSignUp;
                        return [2];
                }
            });
        });
    };
    UiMerchantChromeComponent.prototype.populateEnvironmentInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var versionData, platform, native, displayMode, displayBuildNumber, displayPlatform;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.environmentService.getVersions()];
                    case 1:
                        versionData = _a.sent();
                        return [4, this.environmentService.getPlatformName()];
                    case 2:
                        platform = _a.sent();
                        return [4, this.environmentService.isNative()];
                    case 3:
                        native = _a.sent();
                        displayMode = 'merchant';
                        displayBuildNumber = versionData && versionData.build && versionData.build.number || 'unknown';
                        displayPlatform = platform;
                        if (native) {
                            displayPlatform += " (" + versionData.deviceNumber + " - " + versionData.deviceCode + ")";
                        }
                        else {
                            displayPlatform += " (" + versionData.packageVersion + ")";
                        }
                        this.displayVersionInfo = displayMode + " - " + displayPlatform + " - " + displayBuildNumber;
                        return [2];
                }
            });
        });
    };
    UiMerchantChromeComponent.prototype.checkParentWindow = function () {
        if (window.self !== window.top) {
            this.hideTopAndSideBar = true;
            return true;
        }
        return false;
    };
    UiMerchantChromeComponent.prototype.handleRedirect = function (url) {
        if (url === void 0) { url = this.router.url; }
        var isUserLoggedIn = this.identityService.getActiveUser();
        var isJoeAdmin = this.identityService.isActiveUserAdmin();
        var isInsideIframe = this.checkParentWindow();
        var isLogInScreen = url.includes("/login") || url === "/m";
        var shouldRedirect = isUserLoggedIn && !isLogInScreen && !isInsideIframe;
        if (isJoeAdmin) {
            return;
        }
        if (shouldRedirect) {
            var isProduction = this.environmentService.getEnvName() === "Production";
            window.location.href = "https://" + (isProduction ? "" : "staging-") + "manage.joe.coffee/";
            return;
        }
    };
    return UiMerchantChromeComponent;
}());
export { UiMerchantChromeComponent };
