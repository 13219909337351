import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { PaymentService, StripeElementMap } from 'src/app/shared/services/payment/payment.service';

@Component({
  selector: 'card-entry-stripe-element',
  templateUrl: './card-entry-stripe-element.component.html',
  styleUrls: ['./card-entry-stripe-element.component.scss'],
})
export class CardEntryStripeElementComponent implements OnInit, OnDestroy {

  @Output()
  tokenUpdate = new EventEmitter<string>();

  @Input()
  fake: boolean;

  @ViewChild('paymentCardInputTarget')
  paymentCardInputTargetRef: ElementRef;

  @ViewChild('paymentExpInputTarget')
  paymentExpInputTargetRef: ElementRef;

  @ViewChild('paymentCvcInputTarget')
  paymentCvcInputTargetRef: ElementRef;

  @ViewChild('paymentPostalInputTarget')
  paymentPostalInputTargetRef: ElementRef;

  cardElements: StripeElementMap;

  constructor(
    private paymentService: PaymentService,
  ) { }

  public ngOnInit(): void {
    const style = {
      base: {
        fontSize: '18px',
      },
    };
    this.cardElements = this.paymentService.createSecureInputElement({
      cardNumber: this.paymentCardInputTargetRef.nativeElement,
      cardExpiry: this.paymentExpInputTargetRef.nativeElement,
      cardCvc: this.paymentCvcInputTargetRef.nativeElement,
      postalCode: this.paymentPostalInputTargetRef.nativeElement,
    }, style, this.fake);

    Object.keys(this.cardElements).forEach(key => {
      this.cardElements[key].element.addEventListener('change', this.cardElementChangeListener.bind(this));
    });
  }

  public ngOnDestroy(): void {
    if (!this.cardElements) {
      return;
    }

    Object.keys(this.cardElements).forEach(key => {
      const element = this.cardElements[key].element;
      element.removeAllListeners();
      element.unmount();
    });
    this.cardElements = undefined;
  }

  // this is only async so we can wait for the tokenization to complete for testing
  private async cardElementChangeListener(event: stripe.elements.ElementChangeResponse): Promise<void> {
    const element = this.cardElements[event.elementType];
    element.error = event.error ? event.error.message : undefined;
    element.complete = event.complete;

    if (this.allElementsComplete()) {
      await this.tokenizeCard();
    }
  }

  private allElementsComplete(): boolean {
    const keys = Object.keys(this.cardElements);
    return keys.filter(key => this.cardElements[key].complete).length === keys.length;
  }

  private async tokenizeCard(): Promise<void> {
    const result = await this.paymentService.tokenizeCardFromInputElement(this.cardElements['cardNumber'].element, this.fake);

    if (result.error) {
      this.cardElements['cardNumber'].error = result.error.message;
    } else {
      this.tokenUpdate.emit(result.token.id);
    }
  }

}
