import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ApiConfiguration } from 'src/gen/joeServerCore';

import { EnvironmentService, EnvironmentModes, EnvironmentPlatforms } from './shared/services/environment/environment.service';
import { UpdateService, HotUpdateStatus } from './shared/services/update/update.service';
import { LoadingIndicatorService } from './shared/packages/ui-loading-indicator';
import { MerchantTopbarService } from './merchant/packages/ui-merchant-chrome/services/merchant-topbar/merchant-topbar.service';
import { MerchantBottombarService } from './merchant/packages/ui-merchant-chrome/services/merchant-bottombar/merchant-bottombar.service';
import { takeWhile } from 'rxjs/operators';
import { AppInitializationService } from './shared/services/app-initialization/app-initialization.service';
import { NativeBackButtonService } from './shared/packages/native-support/services/native-back-button/native-back-button.service';
import { TelemetryService } from './shared/packages/telemetry/services/telemetry/telemetry.service';
import { BottombarService } from './shared/packages/ui-bottom-bar/services/bottombar/bottombar.service';
import { promiseDelayAtLeast } from './shared/helpers/promise/promise-delay-at-least';
import { HttpClient } from '@angular/common/http';
import { safeGet } from './shared/helpers/object/safe-get';
import { FeatureSwitchService } from './shared/services/feature-switch/feature-switch.service';
import { MatDialog } from '@angular/material';
import { MerchantPlusNagDialogComponent } from './merchant/dialogs/merchant-plus-nag-dialog/components/merchant-plus-nag-dialog/merchant-plus-nag-dialog.component';

// check for updates at least this often ( 3600000 = 1 hour )
const UPDATE_CHECK_INTERVAL = 3600000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  merchantMode = true;
  loading = true;
  criticalUpdateNeeded = false;
  versionShortCode: string;
  checkingForUpdate: boolean;

  private componentActive: boolean;
  private updateCheckTimeout: NodeJS.Timer;

  constructor(
    private router: Router,
    private environmentService: EnvironmentService,
    private changeDetectorRef: ChangeDetectorRef,
    private initializationService: AppInitializationService,
    private updateService: UpdateService,
    private loadingIndicatorService: LoadingIndicatorService,
    private merchantTopbarService: MerchantTopbarService,
    private bottombarService: BottombarService,
    private merchantBottombarService: MerchantBottombarService,
    apiConfig: ApiConfiguration,
    private nativeBackButtonService: NativeBackButtonService,
    private telemetryService: TelemetryService,
    featureSwitchService: FeatureSwitchService,
    http: HttpClient,
    private dialog: MatDialog,
  ) {
    featureSwitchService.initQueryParams();
    const coreApiUrlOverride = featureSwitchService.getValue('CORE_SERVICE_API_URL_OVERRIDE');
    const joeCoreApiUrl = this.environmentService.getConfig().apiUrls.joeServerCore;
    apiConfig.rootUrl = coreApiUrlOverride ? coreApiUrlOverride + '/api/v1' : joeCoreApiUrl;
    this.telemetryService.init(http);
  }

  async ngOnInit(): Promise<void> {
    this.checkingForUpdate = false;
    await this.setMode();
    const appVersionData = await this.environmentService.getVersions();
    const appMode = await this.environmentService.getMode();
    const appBuildNumber = appVersionData && appVersionData.build && appVersionData.build.number || 'unknown';
    // remove everything before the last underscore
    this.versionShortCode = appBuildNumber.replace(/.*_(?!.*_)/, '');

    // HACK - add css scroll fix to older versions of the app
    // TODO: remove this in a few versions (added: 1/23/2019 - 0.8.0 - _1574)
    if (await this.environmentService.getPlatform() === EnvironmentPlatforms.IOS) {
      const htmlTag = document.querySelector('html');
      if (htmlTag && htmlTag.classList) {
        if (!((<any>window)['Ionic'] && (<any>window)['Ionic'].WebView)) {
          htmlTag.classList.add('old-webview-scroll-fix');
        }
      }
    }

    if (await this.environmentService.getPlatform() !== EnvironmentPlatforms.WEB) {
      const htmlTag = document.querySelector('html');
      if (htmlTag && htmlTag.classList) {
        htmlTag.classList.add('block-document-selection');
      }
    }

    this.componentActive = true;

    // show / hide loading indicator on route change
    this.router.events.pipe(takeWhile(() => this.componentActive)).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (appMode === EnvironmentModes.MERCHANT) {
          this.loadingIndicatorService.show();
        }
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loadingIndicatorService.dismiss();
      }

      if (event instanceof NavigationStart) {
        // reset top bar title on successful navigation
        this.merchantTopbarService.setTitle('');
        // clear bottombars on successful navigation
        this.bottombarService.setTemplate();
        this.merchantBottombarService.setTemplate();
        this.nativeBackButtonService.clearBackAction();
      }

      if (event instanceof NavigationEnd) {
        // scroll back to top after navigation
        const scrollContainer = document.querySelector<HTMLElement>('.mat-drawer-content');
        if (scrollContainer) {
          if (scrollContainer.scrollTo) {
            scrollContainer.scrollTo(0, 0);
          } else {
            scrollContainer.scrollTop = 0;
          }
        }

        this.telemetryService.logPageUrlChange(event.url);
      }
    });

    // initialize the environment and continue once it's ready
    await this.initializationService.init();

    // TODO: prevent UX glitch
    // sometimes the user sees the store search appear then the app restarts
    // tried await, but it was causing this to get stuck after a hot update (sometimes)
    // perhaps some "updating..." ui
    await promiseDelayAtLeast(this.checkForUpdates(), 2000);

    this.environmentService.onResume().pipe(takeWhile(() => this.componentActive)).subscribe(async () => {
      await this.checkForUpdates();
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });

    this.environmentService.onPause().pipe(takeWhile(() => this.componentActive)).subscribe(() => {
      this.clearUpdateTimer();
    });

    this.loading = false;
    const isNative = await this.environmentService.isNative();

    if (isNative) {
      this.dialog.open(MerchantPlusNagDialogComponent, {
        disableClose: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.clearUpdateTimer();
  }

  private async setMode(): Promise<void> {
    this.merchantMode = true;
  }

  private clearUpdateTimer(): void {
    if (this.updateCheckTimeout) {
      clearTimeout(this.updateCheckTimeout);
      this.updateCheckTimeout = undefined;
    }
  }

  private async checkForUpdates(): Promise<void> {
    if (!await this.environmentService.isNative()) {
      return;
    }

    // this is no longer a valid consumer app. All consumer apps on this channel must update
    if (!this.merchantMode) {
      this.criticalUpdateNeeded = true;
      return;
    }

    this.checkingForUpdate = true;
    this.clearUpdateTimer();
    this.updateCheckTimeout = setTimeout(() => this.checkForUpdates(), UPDATE_CHECK_INTERVAL);

    try {
      const hotUpdateStatus = await this.updateService.fetchHotUpdate();

      switch (hotUpdateStatus) {
        case HotUpdateStatus.UP_TO_DATE:
          return;
        case HotUpdateStatus.BUILD_VERSION_TOO_LOW:
          this.criticalUpdateNeeded = true;
          const dialogMessage = 'Your application is out of date. A New version of the application is available on the store.';
          this.updateService.requestApplicationUpdate(dialogMessage);
          break;
        case HotUpdateStatus.READY_TO_INSTALL:
          const versionInfo = await this.updateService.getVersionInfo();
          if (versionInfo.readyToInstallWebVersion &&
            this.releaseToInt(versionInfo.currentWebVersion) < this.releaseToInt(versionInfo.readyToInstallWebVersion)) {
            this.loading = true;
            await this.updateService.installHotUpdate();
            this.loading = false;
          } else {
            // This should never happen. It means the version installed is NEWER than the hot update version
            const versionUpdateString = versionInfo && `${versionInfo.currentWebVersion} -> ${versionInfo.readyToInstallWebVersion}`;
            // alert(`Loaded version is newer than latest ${versionUpdateString}`);
            this.telemetryService.logError(`Hot Update Error INVALID_VERSION ${versionUpdateString}`, true);
          }
          break;
        case HotUpdateStatus.FAILURE_DISK:
          // Alert user to full disk error?
          // alert('Unable to update app. \n\nReason: Device is full. Please delete some media or apps to make more room.');

          this.telemetryService.logError('Hot Update Error FAILURE_DISK');
          return;
        default:
          return;
      }
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError('Hot Update Error ' + errorMessage);
      this.loading = false;
    }
    this.checkingForUpdate = false;
  }

  private releaseToInt(releaseVersion: string): number {
    try {
      const dateArr = releaseVersion.split('-');
      const day = dateArr[0].split('.');
      const time = dateArr[1].split('.');
      return parseInt(day.concat(time).join(''), 10);
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
    }
    return 0;
  }

}
