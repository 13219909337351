import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelemetryService } from './services/telemetry/telemetry.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [TelemetryService],
  exports: [],
  entryComponents: [],

})
export class TelemetryModule { }
