import { OnInit, ElementRef } from '@angular/core';
import { confetti } from 'dom-confetti';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
var MerchantRegistrationSuccessDialogComponent = (function () {
    function MerchantRegistrationSuccessDialogComponent(_a, router, dialogRef) {
        var companyId = _a.companyId, approved = _a.approved;
        this.router = router;
        this.dialogRef = dialogRef;
        this.companyId = companyId;
        this.companyApproved = !!approved;
    }
    MerchantRegistrationSuccessDialogComponent.prototype.ngOnInit = function () {
        this.runAnimation();
    };
    MerchantRegistrationSuccessDialogComponent.prototype.alert = function (message) {
        alert(message);
    };
    MerchantRegistrationSuccessDialogComponent.prototype.goToDashboard = function () {
        this.dialogRef.close();
        this.router.navigate(['/m/company', this.companyId, 'dashboard']);
    };
    MerchantRegistrationSuccessDialogComponent.prototype.runAnimation = function () {
        var _this = this;
        setTimeout(function () {
            confetti(_this.successTarget.nativeElement, { angle: 0, spread: 360, elementCount: 400 });
            confetti(_this.successTarget.nativeElement, { angle: 60 });
            confetti(_this.successTarget.nativeElement, { angle: 0 });
            confetti(_this.successTarget.nativeElement, { angle: -60 });
            _this.titleBig = true;
            setTimeout(function () {
                _this.titleTop = true;
            }, 500);
        }, 200);
    };
    return MerchantRegistrationSuccessDialogComponent;
}());
export { MerchantRegistrationSuccessDialogComponent };
