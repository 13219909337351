import { EnvironmentService } from '../environment/environment.service';
var UpdateService = (function () {
    function UpdateService(environmentService) {
        this.environmentService = environmentService;
    }
    UpdateService.prototype.fetchHotUpdate = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!chcp) {
                return reject('chcp not loaded');
            }
            var environmentConfig = _this.environmentService.getConfig();
            var options = {
                'config-file': environmentConfig.hotUpdateUrl + '/chcp.json',
            };
            chcp.fetchUpdate(function (error, data) {
                if (error) {
                    switch (error.code) {
                        case chcp.error.APPLICATION_BUILD_VERSION_TOO_LOW:
                            return resolve(4);
                        case chcp.error.NOTHING_TO_UPDATE:
                        case chcp.error.NOTHING_TO_INSTALL:
                            return resolve(1);
                        case chcp.error.FAILED_TO_DOWNLOAD_APPLICATION_CONFIG:
                        case chcp.error.FAILED_TO_DOWNLOAD_CONTENT_MANIFEST:
                        case chcp.error.FAILED_TO_DOWNLOAD_UPDATE_FILES:
                            return resolve(0);
                        case chcp.error.FAILED_TO_MOVE_LOADED_FILES_TO_INSTALLATION_FOLDER:
                        case chcp.error.FAILED_TO_COPY_FILES_FROM_PREVIOUS_RELEASE:
                        case chcp.error.FAILED_TO_COPY_NEW_CONTENT_FILES:
                        case chcp.error.FAILED_TO_INSTALL_ASSETS_ON_EXTERNAL_STORAGE:
                        case chcp.error.LOCAL_VERSION_OF_APPLICATION_CONFIG_NOT_FOUND:
                        case chcp.error.LOCAL_VERSION_OF_MANIFEST_NOT_FOUND:
                            return resolve(3);
                        default:
                            return reject(error.code);
                    }
                }
                else {
                    resolve(2);
                }
            }, options);
        });
    };
    UpdateService.prototype.installHotUpdate = function () {
        return new Promise(function (resolve, reject) {
            if (!chcp) {
                return reject('chcp not loaded');
            }
            chcp.installUpdate(function (error, data) {
                if (error) {
                    switch (error.code) {
                        case chcp.error.NOTHING_TO_INSTALL:
                            resolve(1);
                            break;
                        default:
                            reject(error);
                    }
                }
                else {
                    resolve(1);
                }
            });
        });
    };
    UpdateService.prototype.getVersionInfo = function () {
        return new Promise(function (resolve, reject) {
            chcp.getVersionInfo(function (err, versionInfo) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(versionInfo);
                }
            });
        });
    };
    UpdateService.prototype.requestApplicationUpdate = function (message) {
        chcp.requestApplicationUpdate(message, function (e, d) {
        }, function (e, d) {
        });
    };
    return UpdateService;
}());
export { UpdateService };
