import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface ErrorOptions {
  title: string;
  message: string;
  details: any;
  retryButtonAction?: () => void;
}

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public errorData: ErrorOptions,
  ) { }

  public ngOnInit(): void {
  }

}
