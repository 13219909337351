<mat-dialog-content>
  <div class="new-user-welcome-wrap">
    <div class="confetti" #success></div>
    <p>Hi {{customerName}},</p>
    <p>Now you can get the coffee you love without the wait. To say thanks, here’s a drink on joe. We've taken up to $6
      off the most expensive item in your order.</p>
    <p>Oh, don't forget to tell your friends - we bet they love great coffee too.</p>
    <p>Sincerely,</p>
    <p>Team joe</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="new-user-welcome-button-wrap">
    <button mat-flat-button color="primary" mat-dialog-close>
      <mat-icon>local_cafe</mat-icon> Claim Reward
    </button>
  </div>
</mat-dialog-actions>