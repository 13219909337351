import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoadingIndicatorComponent, LoadingOptions } from '../../components/loading-indicator/loading-indicator.component';

@Injectable()
export class LoadingIndicatorService {

  private static dialogRef: MatDialogRef<LoadingIndicatorComponent>;

  constructor(
    private dialog: MatDialog,
  ) { }

  show(options?: LoadingOptions): void {
    if (LoadingIndicatorService.dialogRef) {
      return;
    }

    LoadingIndicatorService.dialogRef = this.dialog.open(LoadingIndicatorComponent, {
      data: options, disableClose: true, hasBackdrop: true, panelClass: 'transparent-dialog',
    });
  }

  /**
   * Used for progress bar loading indicator
   * @param percent
   */
  setProgress(percent: number): void {
    if (LoadingIndicatorService.dialogRef) {
      LoadingIndicatorService.dialogRef.componentInstance.setLoadingProgress(percent);
    }
  }

  dismiss(): void {
    if (LoadingIndicatorService.dialogRef) {
      LoadingIndicatorService.dialogRef.close();
      LoadingIndicatorService.dialogRef = undefined;
    }
  }
}
