import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderViewConsumerDto } from 'src/gen/joeServerCore';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/consumer/services/order/order.service';
import { StoreCartService } from 'src/app/consumer/services/store-cart/store-cart.service';
import { StringUtilService } from 'src/app/shared/services/string-util/string-util.service';
import { OrderStatusStanding } from 'src/app/consumer/pages/recent-orders/recent-order-card/recent-order-card.component';

@Component({
  selector: 'sidebar-last-order-card',
  templateUrl: './sidebar-last-order-card.component.html',
  styleUrls: ['./sidebar-last-order-card.component.scss'],
})
export class SidebarLastOrderCardComponent implements OnInit {

  @Input()
  loading: boolean;

  @Input()
  order?: OrderViewConsumerDto;

  @Output()
  close: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private orderService: OrderService,
    private storeCartService: StoreCartService,
    private stringUtilService: StringUtilService,
  ) { }

  public async ngOnInit(): Promise<void> {
  }

  public checkStatusStanding(orderStatus: string): string {
    if (orderStatus === 'completed') {
      return OrderStatusStanding.POSITIVE;
    } else if (orderStatus === 'new' || orderStatus === 'accepted') {
      return OrderStatusStanding.PENDING;
    } else {
      return OrderStatusStanding.NEGATIVE;
    }
  }

  public checkStatusOrReview(order: OrderViewConsumerDto) {
    this.router.navigateByUrl(`/order/${order.storeId}/${order.id}`);
  }

  public async reorder(order: OrderViewConsumerDto): Promise<void> {
    const cart = await this.orderService.getCartFromOrderViewConsumerDto(order);
    const storeName = this.stringUtilService.urlify(order.storeName);

    if (cart && cart.length) {
      this.storeCartService.addCart(cart, order.storeId);
      this.router.navigateByUrl(`/stores/${storeName}/${order.storeId}/details/(store:cart)`);
    } else {
      this.router.navigate(['stores', storeName, order.storeId, 'details'], { queryParams: { reorderFailure: true } });
    }
  }
}
