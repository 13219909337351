import { MatIconModule, MatButtonModule, MatBadgeModule, MatMenuModule, MatDividerModule } from '@angular/material';
import { UiPosChromeComponent } from './components/ui-pos-chrome/ui-pos-chrome.component';
export var MATERIAL_IMPORTS = [
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
];
var EXPORTED_COMPONENTS = [
    UiPosChromeComponent,
];
var UiPosChromeModule = (function () {
    function UiPosChromeModule() {
    }
    return UiPosChromeModule;
}());
export { UiPosChromeModule };
