export const JOE_ASCII_LOGO = [
  '   ',
  '       ===                                       ',
  '     .=====                                      ',
  '     .=====                                      ',
  '      .===                                       ',
  '                                               ',
  '     .~====      ========~        .========      ',
  '      =====    =============    .============    ',
  '     =====  .====~=~.=======  .=====    =====   ',
  '     =====  =====      .====~.====    ~======   ',
  '     =====  =====      .=====.====.========     ',
  '     =====  =====      .=====.=========         ',
  '     =====  =====      .====:.======    .~===   ',
  '    .=====  .===============  .======  :=====   ',
  '     =====    =============    .============    ',
  '     =====      :=======,        .:======,      ',
  '     =====                                      ',
  '  .,====                                       ',
  '  .====                                        ',
  '  ====                                         ',
  '  .,=                                          ',
  '   ',
  '   ',
  '   ',
];
