import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';

import { PosDeviceService } from '../../../pos/services/pos-devices/pos-device.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class PosDeviceGuardService implements CanActivate {

  constructor(
    private posDevice: PosDeviceService,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return fromPromise(this.posDevice.isLoaded()).pipe(switchMap(() => {
      const isPaired = !!this.posDevice.getPairedDevice();
      if (!isPaired) {
        this.router.navigateByUrl('/m');
      }
      return of(isPaired);
    }));
  }

}
