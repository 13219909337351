
/**
 * returns the timezone offset
 * This fixes a REALLY WEIRD bug that causes the timezone offset to be 0 occasionally without warning
 * I think this is an issue with cordova or the web view or our tablets. Hard to tell
 */
export function getTimezoneOffset(): string {

  const now = new Date();
  const nowOffset = now.getTimezoneOffset();

  if (nowOffset !== 0) {
    return (-nowOffset / 60) + '00';
  }

  const localeNow = new Date(now.toLocaleString());
  return Math.round((localeNow.getTime() - now.getTime()) / 1000 / 60 / 60) + '00';
}
