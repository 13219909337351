var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { merge as _merge, omit as _omit } from 'lodash';
import { JOE_ASCII_LOGO } from './joe-logo';
import { NativeInitializationService } from '../../packages/native-support/services/native-initialization/native-initialization.service';
import { EnvironmentService } from '../environment/environment.service';
var AppInitializationService = (function () {
    function AppInitializationService(nativeInitialization, environmentService) {
        this.nativeInitialization = nativeInitialization;
        this.environmentService = environmentService;
    }
    AppInitializationService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var mode, native;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.environmentService.onReady()];
                    case 1:
                        _a.sent();
                        return [4, this.environmentService.getMode()];
                    case 2:
                        mode = _a.sent();
                        return [4, this.environmentService.isNative()];
                    case 3:
                        native = _a.sent();
                        if (native) {
                            this.nativeInitialization.init({ mode: mode });
                        }
                        else {
                        }
                        this.outputConsoleEnv();
                        return [2];
                }
            });
        });
    };
    AppInitializationService.prototype.outputConsoleEnv = function () {
        return __awaiter(this, void 0, void 0, function () {
            function padItUp(value, pad, char) {
                if (char === void 0) { char = ' '; }
                var spaces = pad - value.length;
                if (spaces < 1) {
                    return value;
                }
                return value + new Array(spaces).fill(char).join('');
            }
            var mode, platform, versions, host, envInfo, outLines;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.environmentService.getMode()];
                    case 1:
                        mode = (_a.sent()) === 1 ? 'merchant' : 'consumer';
                        return [4, this.environmentService.getPlatformName()];
                    case 2:
                        platform = _a.sent();
                        return [4, this.environmentService.getVersions()];
                    case 3:
                        versions = _a.sent();
                        host = this.environmentService.getHost();
                        envInfo = _merge({ platform: platform, mode: mode, host: host }, _omit(versions, 'build'), versions.build);
                        outLines = JOE_ASCII_LOGO.map(function (line) { return ('             ' + line); });
                        Object.keys(envInfo).forEach(function (key) {
                            var value = envInfo[key];
                            if (!value) {
                                return;
                            }
                            outLines.push(':::  ' + padItUp(key, 35, '.') + ' ' + value);
                        });
                        console.log(outLines.join('\r\n').replace(/ /g, '\xa0'));
                        return [2];
                }
            });
        });
    };
    return AppInitializationService;
}());
export { AppInitializationService };
