import { Router } from '@angular/router';
import { of } from 'rxjs';
import { PosDeviceService } from '../../../pos/services/pos-devices/pos-device.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap } from 'rxjs/operators';
var PosDeviceGuardService = (function () {
    function PosDeviceGuardService(posDevice, router) {
        this.posDevice = posDevice;
        this.router = router;
    }
    PosDeviceGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        return fromPromise(this.posDevice.isLoaded()).pipe(switchMap(function () {
            var isPaired = !!_this.posDevice.getPairedDevice();
            if (!isPaired) {
                _this.router.navigateByUrl('/m');
            }
            return of(isPaired);
        }));
    };
    return PosDeviceGuardService;
}());
export { PosDeviceGuardService };
