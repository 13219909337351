import { OrderViewDto } from 'src/gen/joeServerCore';

// time to remind barista of order = 5 minutes
export const FUTURE_ORDER_REMINDER_DELAY = 300000;

export function newOrdersFilter(orders: OrderViewDto[]): OrderViewDto[] {
  const now = Date.now();
  return orders.filter(order => {
    if (order.orderStatus === 'new') {
      return true;
    }

    if (!order.scheduledPickup) {
      return false;
    }

    const orderMicroTime = new Date(order.scheduledPickup).getTime();
    return (!order.scheduleAlertAcknowledged && now > orderMicroTime - FUTURE_ORDER_REMINDER_DELAY);
  });
}

export function activeOrdersFilter(orders: OrderViewDto[]): OrderViewDto[] {
  const now = Date.now();
  return orders.filter(order => {
    if (order.orderStatus === 'new' || !order.scheduledPickup) {
      return true;
    }

    const orderMicroTime = new Date(order.scheduledPickup).getTime();
    return now > orderMicroTime - FUTURE_ORDER_REMINDER_DELAY;
  });
}

export function futureOrdersFilter(orders: OrderViewDto[]): OrderViewDto[] {
  const now = Date.now();
  return orders.filter(order => {
    if (order.orderStatus === 'new' || !order.scheduledPickup) {
      return false;
    }

    const orderMicroTime = new Date(order.scheduledPickup).getTime();
    return now <= orderMicroTime - FUTURE_ORDER_REMINDER_DELAY;
  });
}
