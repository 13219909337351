import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

export interface NativeSharePayload {
  message?: string;
  subject?: string;
  url?: string;
}

@Injectable()
export class NativeSocialShareService {

  hasPlugin: boolean;

  constructor(
    private nativeSocialSharing: SocialSharing,
    private environmentService: EnvironmentService,
  ) {
    this.nativeShareAvailable().then(enabled => {
      this.hasPlugin = enabled;
    });
  }

  public async nativeShareAvailable(): Promise<boolean> {
    const isNative = await this.environmentService.isNative();

    return isNative && !!(<any>window).plugins && !!(<any>window).plugins.socialsharing ? true : false;
  }

  // title can only be overridden on android, and then only applies to certain share types ie email.
  // subject is for email only.
  public share({ message, subject, url }: NativeSharePayload): Promise<any> {
    return this.nativeSocialSharing.shareWithOptions({
      message,
      subject,
      url,
    });
  }

}
