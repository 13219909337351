import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFlipFabComponent } from './components/ui-flip-fab/ui-flip-fab.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UiFlipFabComponent,
  ],
  providers: [],
  exports: [UiFlipFabComponent],
  entryComponents: [],

})
export class UiFlipFabModule { }
