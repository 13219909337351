import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CentCurrencyPipe } from 'src/app/shared/packages/data-transform-pipes/currency/cent-currency/cent-currency.pipe';
import { SocialShareService } from 'src/app/shared/services/social-share/social-share.service';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { TelemetryEventName } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry-event-name';

@Component({
  selector: 'sidebar-links',
  templateUrl: './sidebar-links.component.html',
  styleUrls: ['./sidebar-links.component.scss'],
})
export class SidebarLinksComponent implements OnInit {

  @Input()
  set joeBucksBalance(value: string) {
    this.floorBalance = this.getFloorBalance(value);
  }

  @Input()
  rewardsRedeemable: number;

  floorBalance: string;

  @Output()
  showAddFunds: EventEmitter<void> = new EventEmitter();

  @Output()
  showRewardInfo: EventEmitter<void> = new EventEmitter();

  @Output()
  showShareSheet: EventEmitter<void> = new EventEmitter();

  @Output()
  close: EventEmitter<void> = new EventEmitter();

  constructor(
    private centCurrencyPipe: CentCurrencyPipe,
    private socialShareService: SocialShareService,
    private telemetryService: TelemetryService,
  ) { }

  public ngOnInit(): void { }

  public async share(): Promise<void> {
    try {
      const data = await this.socialShareService.openShareSheet();
      this.telemetryService.logEvent(TelemetryEventName.SIDEBAR_SOCIAL_SHARE, {
        data,
      });
    } catch (err) {
      this.telemetryService.logError(err, false);
      this.showShareSheet.emit();
    }
  }

  private getFloorBalance(balanceStr: string): string {
    if (balanceStr === undefined) {
      return undefined;
    }

    const balanceNum = parseInt(balanceStr, 10);
    return this.centCurrencyPipe.transform(balanceNum).split('.')[0];
  }

}
