import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'niceBoolean',
})
export class BooleanNiceResponse implements PipeTransform {

  transform(bool: boolean): string {
    if (bool === undefined || bool === null) {
      return '-';
    }

    if (bool) {
      return 'Yes';
    }
    return 'No';
  }

}
