var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { MatSnackBar, MatBottomSheetRef } from '@angular/material';
import { CloudinaryUploadService } from 'src/app/shared/services/cloudinary-upload/cloudinary-upload.service';
import { UserService } from 'src/app/shared/services/user/user.service';
var UserConsumerUserInfoBottomsheetComponent = (function () {
    function UserConsumerUserInfoBottomsheetComponent(data, bottomSheetRef, identityService, changeDetectorRef, cloudinaryUploadService, userService, snackbar) {
        this.data = data;
        this.bottomSheetRef = bottomSheetRef;
        this.identityService = identityService;
        this.changeDetectorRef = changeDetectorRef;
        this.cloudinaryUploadService = cloudinaryUploadService;
        this.userService = userService;
        this.snackbar = snackbar;
        this.userInfoMode = data.mode;
        this.infoMessage = data.message;
        this.currentUser = identityService.getActiveUser();
    }
    UserConsumerUserInfoBottomsheetComponent.prototype.ngOnInit = function () {
        this.dismissIfInfoNotNeeded();
        this.cloudinaryUploadService.initCloudinaryWidget('USER_PROFILE');
    };
    UserConsumerUserInfoBottomsheetComponent.prototype.onEmailSubmit = function (_a) {
        var email = _a.email, password = _a.password, passwordConfirm = _a.passwordConfirm;
        return __awaiter(this, void 0, void 0, function () {
            var unknownErrorMessage, updateResult, e_1, errorMessage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        unknownErrorMessage = 'Unable to add an e-mail / password at this time. Please try again later';
                        this.setErrorMessage();
                        if (password !== passwordConfirm) {
                            this.setErrorMessage('Password confirmation does not match.');
                            this.changeDetectorRef.detectChanges();
                            return [2];
                        }
                        if (!password || password.length < 8) {
                            this.setErrorMessage('Password must be at least 8 characters.');
                            this.changeDetectorRef.detectChanges();
                            return [2];
                        }
                        this.loading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4, this.identityService.addEmailPassword(email, password).toPromise()];
                    case 2:
                        updateResult = _b.sent();
                        if (!updateResult) {
                            this.setErrorMessage(unknownErrorMessage);
                        }
                        return [3, 4];
                    case 3:
                        e_1 = _b.sent();
                        errorMessage = e_1 && e_1.status === 400 ? 'Invalid e-mail address or password.' : e_1 && e_1.error && e_1.error.message;
                        this.setErrorMessage(errorMessage || unknownErrorMessage);
                        return [3, 4];
                    case 4:
                        this.loading = false;
                        if (updateResult) {
                            this.dismiss();
                        }
                        this.changeDetectorRef.detectChanges();
                        return [2];
                }
            });
        });
    };
    UserConsumerUserInfoBottomsheetComponent.prototype.updatePhoto = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, photoUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setErrorMessage();
                        return [4, this.cloudinaryUploadService.open('USER_PROFILE')];
                    case 1:
                        result = _a.sent();
                        if (!result) return [3, 3];
                        this.loading = true;
                        return [4, this.userService.addImage(result.id).toPromise()];
                    case 2:
                        photoUrl = _a.sent();
                        this.identityService.setActiveUserPhoto(photoUrl);
                        this.loading = false;
                        _a.label = 3;
                    case 3:
                        this.skipForNow();
                        return [2];
                }
            });
        });
    };
    UserConsumerUserInfoBottomsheetComponent.prototype.skipForNow = function () {
        if (this.userInfoMode === 'photo' && !this.currentUser.hasEmail) {
            this.userInfoMode = 'email';
        }
        else {
            this.dismiss();
        }
        this.changeDetectorRef.detectChanges();
    };
    UserConsumerUserInfoBottomsheetComponent.prototype.dismissIfInfoNotNeeded = function () {
        switch (this.userInfoMode) {
            case 'email':
                if (this.currentUser.hasEmail) {
                    this.dismiss();
                }
                return;
            case 'photo':
                if (this.currentUser.photo) {
                    this.dismiss();
                }
                return;
        }
    };
    UserConsumerUserInfoBottomsheetComponent.prototype.setErrorMessage = function (message) {
        if (message) {
            this.snackbar.open("Error: " + message, '', { verticalPosition: 'top', duration: 10000, panelClass: 'error-toast' });
        }
        else {
            this.snackbar.dismiss();
        }
    };
    UserConsumerUserInfoBottomsheetComponent.prototype.dismiss = function () {
        this.bottomSheetRef.dismiss();
    };
    return UserConsumerUserInfoBottomsheetComponent;
}());
export { UserConsumerUserInfoBottomsheetComponent };
