import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFirst',
})
export class GetFirstPipe implements PipeTransform {

  transform(value: any[]): any {
    if (!value || !Array.isArray(value) || value.length < 1) {
      return;
    }
    return value[0];
  }

}
