<div [hidden]="processingCheckout || loading">

  <!-- JOE BUCKS AMOUNT SELECTION -->
  <ng-container *ngIf="joeBucksUploadMode; else tipSelectionTemplate">
    <h1 class="checkout-title">Add Funds</h1>
    <div class="tip-wrap">
      <label>Preload Amount</label>
      <mat-button-toggle-group fxLayout="row" [(ngModel)]="joeBucksUploadAmount" (change)="joeBucksUploadAmountChange()"
        color="primary">
        <mat-button-toggle fxFlex [value]="uploadAmount" *ngFor="let uploadAmount of ['2500', '5000', '7500', '10000']">
          {{uploadAmount | centCurrency}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-container>

  <!-- TIP INPUT -->
  <ng-template #tipSelectionTemplate>
    <h1 class="checkout-title">Checkout</h1>
    <div class="tip-wrap" *ngIf="!hideTip">
      <label>Tip Amount</label>
      <mat-button-toggle-group fxLayout="row" [(ngModel)]="tipSelection" (change)="tipChange()" color="primary">
        <mat-button-toggle fxFlex value="0">
          No Tip
        </mat-button-toggle>
        <mat-button-toggle fxFlex [value]="tip" *ngFor="let tip of tipAmounts">
          {{tip | centCurrency}}
        </mat-button-toggle>
        <mat-button-toggle fxFlex value="+">
          +
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field class="other-tip-input" *ngIf="tipSelection === '+'">
        <mat-currency-input placeholder="Tip amount" [(ngModel)]="otherTip" (ngModelChange)="tipChange()">
        </mat-currency-input>
      </mat-form-field>
    </div>
    <div *ngIf="hideTip && tipDisabledMessage?.length > 0" class="tip-disabled-message">
      <b>Tipping Disabled</b> - {{tipDisabledMessage}}
    </div>
    <div *ngIf="invalidTip" class="tip-error-message">Tip must be at least $0.50.</div>
  </ng-template>
  <ng-container
    *ngIf="(tipSelection !== undefined && !joeBucksUploadMode) || (joeBucksUploadMode && joeBucksUploadAmount !== undefined)">
    <div>

      <!-- EXISTING CARDS DROPDOWN -->
      <mat-form-field class="select-payment-field">
        <mat-select placeholder="Payment Method" [(ngModel)]="selectedPaymentMethod"
          (ngModelChange)="paymentMethodChange()">
          <mat-select-trigger>
            <span *ngIf="selectedPaymentMethod?.type" class="card-type-icon"
              [ngClass]="paymentIcons[selectedPaymentMethod?.type]"></span>
            <span *ngIf="selectedPaymentMethod?.type">
              <span *ngIf="!selectedPaymentMethod?.fake">
                {{selectedPaymentMethod?.last4?.length > 4 ? selectedPaymentMethod.last4 : 'card ending with -' +
                  selectedPaymentMethod?.last4}}
              </span>
              <span *ngIf="selectedPaymentMethod?.fake" class="fake-card-label">FAKE CARD
                -{{selectedPaymentMethod?.last4}}</span>
            </span>
            <span *ngIf="selectedPaymentMethod === 'new'">New Payment Method...</span>
            <span class="fake-card-label" *ngIf="selectedPaymentMethod === 'new-fake'">New FAKE Payment Method...</span>
            <div *ngIf="selectedPaymentMethod === 'joebucks'" fxLayoutAlign="start center">
              <img src="public-web-assets/images/joe-store-map-marker.png" class="joebucks-logo">
              <span>joe balance ({{joeBucksBalance | centCurrency}})</span>
            </div>
          </mat-select-trigger>

          <mat-option value="joebucks" *ngIf="!joeBucksUploadMode">
            <div fxLayoutAlign="start center">
              <img src="public-web-assets/images/joe-store-map-marker.png" class="joebucks-logo">
              <span>joe balance ({{joeBucksBalance | centCurrency}})</span>
            </div>
          </mat-option>

          <mat-option *ngFor="let card of paymentMethods" [value]="card">
            <span class="card-type-icon" [ngClass]="paymentIcons[card.type]"></span>
            <span>{{card?.last4?.length > 4 ? card.last4 : 'card ending with -' + card?.last4}}</span>
          </mat-option>
          <ng-container *ngIf="canUseFakeCard && fakePaymentMethods?.length > 0 && !joeBucksUploadMode">
            <mat-divider></mat-divider>
            <mat-option *ngFor="let card of fakePaymentMethods" [value]="card">
              <span class="card-type-icon" [ngClass]="paymentIcons[card.type]"></span>
              <span>
                <span class="fake-card-label">FAKE CARD</span>
                -{{card.last4}}
              </span>
            </mat-option>
          </ng-container>
          <mat-divider></mat-divider>
          <mat-option value="new">New Payment Method...</mat-option>
          <mat-option value="new-fake" *ngIf="canUseFakeCard && !joeBucksUploadMode"><span class="fake-card-label">New
              FAKE Payment
              Method...</span></mat-option>
        </mat-select>
        <mat-hint *ngIf="selectedPaymentMethod === 'joebucks'">Service fee waived. Thanks for preloading funds.
        </mat-hint>
      </mat-form-field>
      <div *ngIf="unableToLoadPaymentMethods" class="payment-methods-load-error">Failed to load stored cards. Please try
        again later.</div>
      <div *ngIf="!joeBucksUploadMode && selectedPaymentMethod === 'joebucks'" class="add-joebucks-funds-button"
        fxLayoutAlign="end start">
        <button *ngIf="joeBucksQuickUploadAmount && remainingJoeBucksAfterPurchase < 0" mat-flat-button color="primary"
          size="small" (click)="quickAddFunds()">
          <mat-icon>refresh</mat-icon> Quick add {{joeBucksQuickUploadAmount | centCurrency}}
        </button>&nbsp;
        <button mat-flat-button color="primary" size="small" (click)="showAddFunds()">
          <mat-icon>attach_money</mat-icon> Add Funds
        </button>
      </div>

      <!-- NEW CARD / NO CARDS ON FILE - REAL CARD ENTRY -->
      <card-entry-stripe-element (tokenUpdate)="onStripeTokenUpdate($event)" *ngIf="selectedPaymentMethod === 'new'">
      </card-entry-stripe-element>

      <!-- NEW CARD / NO CARDS ON FILE - FAKE CARD ENTRY -->
      <card-entry-stripe-element (tokenUpdate)="onStripeTokenUpdate($event)" [fake]="true"
        *ngIf="selectedPaymentMethod === 'new-fake'">
      </card-entry-stripe-element>
    </div>
    <div class="service-fee-message" *ngIf="!joeBucksUploadMode && orderServiceFee">
      Includes a {{orderServiceFee | centCurrency}} service fee (+ tax). This fee is waived if you <a
        (click)="switchToJoeBalance()">pay with your joe balance</a>.
      <ng-container *ngIf="joeBucksVirgin">
        <br><br>
        Get a free drink (up to $6 value) the first time you add funds.
      </ng-container>
    </div>
    <div class="schedule-time-select-wrap" *ngIf="!joeBucksUploadMode">
      <div>
        <mat-form-field fxFlex="100">
          <mat-select placeholder="Pickup Time" [(ngModel)]="selectedScheduleTime">
            <mat-option [value]="0">Now (3 - 7 minutes)</mat-option>
            <mat-option *ngFor="let min of [15,20,30,40,50,60,75,90,105,120]" [value]="min">
              {{min | secondsDurationToText}}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="selectedScheduleTime > 0">
            Your order should be ready by
            <strong>{{(currentTime + (selectedScheduleTime * 60 * 1000)) | datePatched: 'short'}}</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!joeBucksUploadMode && selectedPaymentMethod === 'joebucks' && remainingJoeBucksAfterPurchase < 0"
      class="joe-bucks-insufficient-error">
      Insufficient account balance.
    </div>
  </ng-container>
  <div fxLayout="row" fxLayoutGap="20px" #chargeButtons>
    <button mat-flat-button color="primary" fxFlex (click)="cancel()">
      <mat-icon>close</mat-icon>
      Cancel
    </button>
    <button mat-flat-button color="primary" fxFlex (click)="checkout()" [disabled]="isCheckoutDisabled()">
      <mat-icon>credit_card</mat-icon>
      Pay {{chargeTotal | centCurrency}}
    </button>
  </div>
  <div *ngIf="joeBucksUploadMode && joeBucksBalanceAfterUpload > 0" class="joe-bucks-new-balance-wrap">
    <strong>{{joeBucksUploadAmount ? 'New' : 'Current'}} Balance: </strong>
    {{joeBucksBalanceAfterUpload | centCurrency}}
    <div *ngIf="joeBucksBalanceAfterUpload >= 15000" class="joe-upload-error">
      Account balance cannot exceed $150.
    </div>
  </div>
  <div *ngIf="!joeBucksUploadMode && todayStoreHours" class="today-store-hours-wrap">
    <span>Store hours: </span>
    <ng-container *ngIf="todayStoreHours?.open === 0 && todayStoreHours?.close === 1439">
      <small>Open 24 Hours</small>
    </ng-container>
    <ng-container
      *ngIf="todayStoreHours?.open !== todayStoreHours?.close && !(todayStoreHours?.open === 0 && todayStoreHours?.close === 1439)">
      <small>{{ todayStoreHours?.open | minutesToLocaleTimePipe }} - {{
          todayStoreHours?.close | minutesToLocaleTimePipe }}</small>
    </ng-container>
    <ng-container *ngIf="todayStoreHours?.open === todayStoreHours?.close">
      <small>Closed</small>
    </ng-container>
  </div>
  <mat-card class="cart-reward-card" fxLayout="row" *ngIf="joeBucksVirgin && joeBucksUploadMode">
    <div class="cart-reward-badge" fxLayout="column" fxLayoutAlign="center">
      <img class="cart-reward-badge-img" src="public-web-assets/images/joe-reward-wreath-cream.png">
    </div>
    <div class="cart-reward-info" fxFlex>
      <div class="cart-reward-info-title">First time adding funds!</div>
      <div>Pre-load funds today and earn a reward instantly.</div>
      <div class="reward-info-subtext">limited time offer. Up to $6 off one item</div>
    </div>
  </mat-card>
</div>

<!-- PROGRESS BAR -->
<div class="code-verification-progress" *ngIf="processingCheckout || loading">
  <p>Processing...</p>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<!-- spacer to ensure iPhone SE can scroll down to the checkout button -->
<div style="height: 50px"></div>