import { Injectable } from '@angular/core';
import { Push, PushObject, PushOptions, EventResponse } from '@ionic-native/push';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PushMessageType, OrderStatusData } from './push.interface';

@Injectable()
export class NativePushService {

  constructor(private push: Push) { }

  private pushObject: PushObject;
  private pushOptions: PushOptions = {
    android: {
      sound: true,
      forceShow: true,
      vibrate: true,
      icon: 'ic_push_android_icon',
      iconColor: '#E21E1A',
    },
    ios: {
      alert: true,
      badge: true,
      sound: true,
    },
    browser: {
      pushServiceURL: 'http://push.api.phonegap.com/v1/push',
    },
  };

  /**
   * Listen for push messages.
   *
   * Usage:
   * this.nativePush.orderStatusUpdateListener.subscribe(event => {
   *   alert(event.title, event.message);
   * });
   */

  private orderStatusUpdateSubject: Subject<OrderStatusData> = new Subject<OrderStatusData>();
  public orderStatusUpdateListener = this.orderStatusUpdateSubject.asObservable();

  // Maps push types to a particular subject
  // This allows consumers to not worry about parsing other pushes

  async init(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.pushObject = this.push.init(this.pushOptions);

      this.pushObject.on('registration').pipe(take(1)).subscribe(registration => {
        // TODO: confirm this is actually unsubscribing.
        this.listen();
        resolve(registration.registrationId);
      });
    });
  }

  private async listen(): Promise<void> {
    const hasPermission = await this.push.hasPermission();
    if (hasPermission) {
      this.pushObject.on('notification').subscribe((response: EventResponse) => {
        // delegate to appropriate subject
        switch (response.additionalData.action) {
          case PushMessageType.userOrderStatusUpdate:
            this.orderStatusUpdateSubject.next(response.additionalData.appData);
            break;
          default:
            break;
        }
      });

      // tslint:disable-next-line
      this.pushObject.on('error').subscribe(error => {
        // TODO - handle errors
        // errors in subjects will shut the subject down entirely
        // maybe we need an error handling subject...
      });
    }
  }
}
