var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter } from '@angular/core';
import { CentCurrencyPipe } from 'src/app/shared/packages/data-transform-pipes/currency/cent-currency/cent-currency.pipe';
import { SocialShareService } from 'src/app/shared/services/social-share/social-share.service';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
var SidebarLinksComponent = (function () {
    function SidebarLinksComponent(centCurrencyPipe, socialShareService, telemetryService) {
        this.centCurrencyPipe = centCurrencyPipe;
        this.socialShareService = socialShareService;
        this.telemetryService = telemetryService;
        this.showAddFunds = new EventEmitter();
        this.showRewardInfo = new EventEmitter();
        this.showShareSheet = new EventEmitter();
        this.close = new EventEmitter();
    }
    Object.defineProperty(SidebarLinksComponent.prototype, "joeBucksBalance", {
        set: function (value) {
            this.floorBalance = this.getFloorBalance(value);
        },
        enumerable: true,
        configurable: true
    });
    SidebarLinksComponent.prototype.ngOnInit = function () { };
    SidebarLinksComponent.prototype.share = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.socialShareService.openShareSheet()];
                    case 1:
                        data = _a.sent();
                        this.telemetryService.logEvent("sidebar_social_share", {
                            data: data,
                        });
                        return [3, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.telemetryService.logError(err_1, false);
                        this.showShareSheet.emit();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    SidebarLinksComponent.prototype.getFloorBalance = function (balanceStr) {
        if (balanceStr === undefined) {
            return undefined;
        }
        var balanceNum = parseInt(balanceStr, 10);
        return this.centCurrencyPipe.transform(balanceNum).split('.')[0];
    };
    return SidebarLinksComponent;
}());
export { SidebarLinksComponent };
