import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatButtonToggleModule,
  MatCheckboxModule, MatRadioModule, MatCardModule, MatProgressBarModule,
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserVerificationComponent } from './components/user-verification/user-verification.component';
import { UserVerificationDialogComponent } from './components/user-verification-dialog/user-verification-dialog.component';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatCardModule,
  MatProgressBarModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    UserVerificationComponent,
    UserVerificationDialogComponent,
  ],
  entryComponents: [
    UserVerificationComponent,
    UserVerificationDialogComponent,
  ],
  exports: [
    UserVerificationComponent,
    UserVerificationDialogComponent,
  ],
})
export class UserVerificationModule { }
