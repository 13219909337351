import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BottombarService } from '../../../services/bottombar/bottombar.service';

@Component({
  selector: 'bottombar-template',
  template: '',
})
export class BottombarTemplateComponent implements OnInit {

  @Input()
  set template(template: TemplateRef<any>) {
    this.bottombarService.setTemplate(template);
  }

  constructor(
    private bottombarService: BottombarService,
  ) { }

  public ngOnInit(): void {
  }
}
