var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';
import { AudioAlertService } from '../../services/audio-alert/audio-alert.service';
import { takeWhile } from 'rxjs/operators';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
var PosDeviceBadgeComponent = (function () {
    function PosDeviceBadgeComponent(audioAlert, posDeviceService) {
        this.audioAlert = audioAlert;
        this.posDeviceService = posDeviceService;
        this.statusChange = new EventEmitter();
        this.status = 'online';
        this.updateCurrentTime();
    }
    Object.defineProperty(PosDeviceBadgeComponent.prototype, "error", {
        set: function (error) {
            this.errorMessage = error;
            this.updateStatus();
        },
        enumerable: true,
        configurable: true
    });
    PosDeviceBadgeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.audioAlert.initOrderAlert();
        this.audioAlert.initErrorAlert();
        this.watchDeviceStatus();
        this.posDeviceService.storeStats
            .pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (storeStats) {
            _this.kpiOrderCount = safeGet(storeStats, function (s) { return s.orderCount; });
            _this.kpiSalesTotal = safeGet(storeStats, function (s) { return s.totalSales; });
            _this.kipTipsTotal = safeGet(storeStats, function (s) { return s.totalTips; });
        });
        this.timeInterval = setInterval(function () { return _this.updateCurrentTime(); }, 1000);
    };
    PosDeviceBadgeComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
        clearInterval(this.timeInterval);
    };
    PosDeviceBadgeComponent.prototype.toggleStoreDrawer = function () {
        var _this = this;
        this.deviceOpen = false;
        if (this.storeOpen) {
            this.drawerOpen = false;
            setTimeout(function () { return _this.resetDrawerState(); }, 500);
        }
        else {
            this.storeOpen = true;
            this.drawerOpen = true;
        }
    };
    PosDeviceBadgeComponent.prototype.toggleDeviceDrawer = function () {
        var _this = this;
        this.storeOpen = false;
        if (this.deviceOpen) {
            this.drawerOpen = false;
            setTimeout(function () { return _this.resetDrawerState(); }, 500);
        }
        else {
            this.deviceOpen = true;
            this.drawerOpen = true;
        }
    };
    PosDeviceBadgeComponent.prototype.resetDrawerState = function () {
        if (!this.drawerOpen) {
            this.storeOpen = false;
            this.deviceOpen = false;
        }
    };
    PosDeviceBadgeComponent.prototype.setOnline = function (online) {
        return __awaiter(this, void 0, void 0, function () {
            var result, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!online) return [3, 2];
                        return [4, this.posDeviceService.connect()];
                    case 1:
                        result = _a.sent();
                        this.deviceActive = result;
                        return [3, 4];
                    case 2: return [4, this.posDeviceService.disconnect()];
                    case 3:
                        result = _a.sent();
                        if (result) {
                            this.deviceActive = !result;
                        }
                        _a.label = 4;
                    case 4:
                        this.updateStatus();
                        return [2];
                }
            });
        });
    };
    PosDeviceBadgeComponent.prototype.watchDeviceStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.posDeviceService.isLoaded()];
                    case 1:
                        _a.sent();
                        this.posDeviceService.pairedDevice
                            .pipe(takeWhile(function () { return _this.componentActive; }))
                            .subscribe(this.updateActiveStoreProperties.bind(this));
                        return [2];
                }
            });
        });
    };
    PosDeviceBadgeComponent.prototype.updateActiveStoreProperties = function (pairedDevice) {
        this.deviceActive = safeGet(pairedDevice, function (d) { return d.active; });
        this.storeId = safeGet(pairedDevice, function (d) { return d.storeId; });
        this.updateStatus();
    };
    PosDeviceBadgeComponent.prototype.updateStatus = function () {
        if (this.errorMessage) {
            this.status = 'error';
            return;
        }
        this.status = this.deviceActive ? 'online' : 'offline';
    };
    PosDeviceBadgeComponent.prototype.updateCurrentTime = function () {
        this.currentTime = Date.now();
    };
    return PosDeviceBadgeComponent;
}());
export { PosDeviceBadgeComponent };
