import * as i0 from "./mat-currency-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/form-field";
import * as i3 from "@angular/material/input";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/core";
import * as i7 from "@angular/cdk/text-field";
import * as i8 from "./mat-currency-input.component";
import * as i9 from "@angular/cdk/a11y";
var styles_MatCurrencyInputComponent = [i0.styles];
var RenderType_MatCurrencyInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatCurrencyInputComponent, data: {} });
export { RenderType_MatCurrencyInputComponent as RenderType_MatCurrencyInputComponent };
export function View_MatCurrencyInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mat-currency-input-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "mat-currency-symbol"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["$"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "input", [["class", "mat-input-element mat-form-field-autofill-control"], ["matInput", ""], ["step", "0.01"], ["type", "number"]], [[2, "mat-input-server", null], [1, "id", 0], [1, "placeholder", 0], [8, "disabled", 0], [8, "required", 0], [1, "readonly", 0], [1, "aria-describedby", 0], [1, "aria-invalid", 0], [1, "aria-required", 0]], [[null, "input"], [null, "click"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._focusChanged(false) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._focusChanged(true) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._onInput() !== false);
        ad = (pd_2 && ad);
    } if (("input" === en)) {
        var pd_3 = (_co.onChange($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = ((_co.selectOnFocus ? $event.target.select() : undefined) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.MatFormFieldControl, null, [i3.MatInput]), i1.ɵdid(5, 999424, null, 0, i3.MatInput, [i1.ElementRef, i4.Platform, [8, null], [2, i5.NgForm], [2, i5.FormGroupDirective], i6.ErrorStateMatcher, [8, null], i7.AutofillMonitor, i1.NgZone], { disabled: [0, "disabled"], type: [1, "type"], value: [2, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.disabled; var currVal_10 = "number"; var currVal_11 = _co.dollarValue; _ck(_v, 5, 0, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5)._isServer; var currVal_1 = i1.ɵnov(_v, 5).id; var currVal_2 = i1.ɵnov(_v, 5).placeholder; var currVal_3 = i1.ɵnov(_v, 5).disabled; var currVal_4 = i1.ɵnov(_v, 5).required; var currVal_5 = ((i1.ɵnov(_v, 5).readonly && !i1.ɵnov(_v, 5)._isNativeSelect) || null); var currVal_6 = (i1.ɵnov(_v, 5)._ariaDescribedby || null); var currVal_7 = i1.ɵnov(_v, 5).errorState; var currVal_8 = i1.ɵnov(_v, 5).required.toString(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_MatCurrencyInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-currency-input", [], [[1, "aria-describedby", 0], [8, "id", 0], [2, "floating", null]], null, null, View_MatCurrencyInputComponent_0, RenderType_MatCurrencyInputComponent)), i1.ɵprd(6144, null, i2.MatFormFieldControl, null, [i8.MatCurrencyInputComponent]), i1.ɵdid(2, 180224, null, 0, i8.MatCurrencyInputComponent, [[8, null], i9.FocusMonitor, i1.ElementRef], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).describedBy; var currVal_1 = i1.ɵnov(_v, 2).id; var currVal_2 = i1.ɵnov(_v, 2).shouldLabelFloat; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var MatCurrencyInputComponentNgFactory = i1.ɵccf("mat-currency-input", i8.MatCurrencyInputComponent, View_MatCurrencyInputComponent_Host_0, { selectOnFocus: "selectOnFocus", placeholder: "placeholder", required: "required", disabled: "disabled" }, {}, []);
export { MatCurrencyInputComponentNgFactory as MatCurrencyInputComponentNgFactory };
