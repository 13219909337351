<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center">
  <div class="last-order-title" fxLayout="row" fxLayoutAlign="end center">
    <a routerLink="/orders" (click)="close.emit()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      <span>recent orders</span>
      <mat-icon>history</mat-icon>
    </a>
  </div>
  <div class="last-order-card loading">
    <mat-card>
      <p>Loading your last order...</p>
      <ul class="loading-list-spacer">
        <li>&nbsp;</li>
        <li>&nbsp;</li>
        <li>&nbsp;</li>
      </ul>
      <div class="loading-bar-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-card>
  </div>
</div>

<!-- New user/no order -->
<ng-container *ngIf="!order && !loading">
  <div>
    <p>&nbsp;</p>
  </div>
  <mat-card>
    <div fxLayout="column" fxLayoutAlign="center center">
      <h4 class="no-orders-title">
        Looks like you don't have any orders yet.
      </h4>
      <button mat-raised-button color="primary" routerLink="/stores">
        <mat-icon>search</mat-icon>
        Find Stores Near me
      </button>
    </div>
  </mat-card>
</ng-container>
<!-- exsisting user/has ordered -->
<ng-container *ngIf="order && !loading">
  <div class="last-order-title" fxLayout="row" fxLayoutAlign="end center">
    <a routerLink="/orders" (click)="close.emit()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      <span>recent orders</span>
      <mat-icon>history</mat-icon>
    </a>
  </div>
  <div class="last-order-card">
    <mat-card>
      <h4 class="last-order-store-name">{{order.storeName}}</h4>
      <ul class="last-order-item-list">
        <li>- {{ order.items[0].name }}</li>
        <li>{{ order.items.length > 1 ? '- '+ order.items[1].name : '&nbsp;' }}</li>
        <li>
          {{ order.items.length > 2 ? ('- and '  + (order.items.length - 2 + ' more item' + (order.items.length - 2 >= 1 ? '...' : 's...'))) : '&nbsp;' }}
        </li>
      </ul>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2%">
        <button fxFlex *ngIf="checkStatusStanding(order?.orderStatus) === 'positive'" mat-raised-button color="primary"
          size="small" (click)="reorder(order); close.emit()">
          <mat-icon>refresh</mat-icon> Reorder
        </button>
        <button fxFlex *ngIf="checkStatusStanding(order?.orderStatus) === 'pending'" mat-raised-button color="primary"
          size="small" (click)="checkStatusOrReview(order)">
          Check Status
        </button>
        <button fxFlex *ngIf="checkStatusStanding(order?.orderStatus) === 'positive'" mat-raised-button color="primary"
          size="small" (click)="checkStatusOrReview(order); close.emit()">
          <ng-container *ngIf="!order?.customerReview && !order?.customerRating; else receiptButtonTemplate">
            <mat-icon>chat</mat-icon>
            Review
          </ng-container>
          <ng-template #receiptButtonTemplate>
            <mat-icon>list_alt</mat-icon>
            Receipt
          </ng-template>
        </button>
      </div>
    </mat-card>
  </div>
</ng-container>