import { AbstractControl, ValidatorFn } from '@angular/forms';

const BANNED_PASSWORDS = new Set([
  'password', 'qwertyuiop', 'qwertyui', 'asdfghjk', '10293847', 'iloveyou', 'starwars', 'passw0rd', 'whatever', 'password1',
  'princess', '1qaz2wsx', '!qaz@wsx', '1qaz2wsx3edc', '!qaz@wsx#edc',
]);
const MAX_SEQUENTIAL_CHARS = 5;
const MIN_UNIQUE_CHARS = 3;
const MIN_LENGTH = 8;

function createErrorResponse(message: string) {
  return { pw_insecure: message };
}

function countUniqueChars(chars: string): number {
  return new Set(chars.split('')).size;
}

function countSequentialChars(value: string): number {
  const lowerValue = value.toLowerCase();
  let count = 0;
  let currentCount = 0;
  let lastCharCode = Infinity;
  for (let i = 0; i < lowerValue.length; i++) {
    const charCode = lowerValue.charCodeAt(i);
    if (Math.abs(lastCharCode - charCode) === 1) {
      currentCount++;
    } else {
      count = Math.max(count, currentCount);
      currentCount = 0;
    }
    lastCharCode = charCode;
  }
  return Math.max(count, currentCount);
}

export class PasswordQualityValidators {

  static all: ValidatorFn[] = [
    PasswordQualityValidators.minLength,
    PasswordQualityValidators.uniqueChars,
    PasswordQualityValidators.sequentialChars,
    PasswordQualityValidators.bannedPasswords,
  ];

  static minLength({ value }: AbstractControl): { [key: string]: any } {
    return value.length < MIN_LENGTH ?
      createErrorResponse('Password must be at least 8 characters long.') : null;
  }

  static uniqueChars({ value }: AbstractControl): { [key: string]: any } {
    return countUniqueChars(value) < MIN_UNIQUE_CHARS ?
      createErrorResponse('Password must contain at least 3 unique characters.') : null;
  }

  static sequentialChars({ value }: AbstractControl): { [key: string]: any } {
    return countSequentialChars(value) > MAX_SEQUENTIAL_CHARS ?
      createErrorResponse('Password cannot contain more than 5 sequential characters.') : null;
  }

  static bannedPasswords({ value }: AbstractControl): { [key: string]: any } {
    return BANNED_PASSWORDS.has(value.toLowerCase()) ?
      createErrorResponse('Password not allowed try another.') : null;
  }
}
