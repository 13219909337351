<div class="share-link">
  <a class="share-link-button" mat-flat-button href="{{facebookShareUrl}}" target="_blank" color="primary"
    (click)="dismiss('facebook')">
    <mat-icon>share</mat-icon>
    Share to Facebook
  </a>
</div>
<div class="share-link">
  <a class="share-link-button" mat-flat-button href="{{twitterShareUrl}}" target="_blank" color="primary"
    (click)="dismiss('twitter')">
    <mat-icon>share</mat-icon>
    Share to Twitter
  </a>
</div>
<div class="share-link">
  <a class="share-link-button" mat-flat-button (click)="copyToClipboard()" color="primary">
    <mat-icon>link</mat-icon>
    Copy Link
  </a>
</div>