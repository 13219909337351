import { MatDialog } from '@angular/material';
import { LoadingIndicatorComponent } from '../../components/loading-indicator/loading-indicator.component';
var LoadingIndicatorService = (function () {
    function LoadingIndicatorService(dialog) {
        this.dialog = dialog;
    }
    LoadingIndicatorService.prototype.show = function (options) {
        if (LoadingIndicatorService.dialogRef) {
            return;
        }
        LoadingIndicatorService.dialogRef = this.dialog.open(LoadingIndicatorComponent, {
            data: options, disableClose: true, hasBackdrop: true, panelClass: 'transparent-dialog',
        });
    };
    LoadingIndicatorService.prototype.setProgress = function (percent) {
        if (LoadingIndicatorService.dialogRef) {
            LoadingIndicatorService.dialogRef.componentInstance.setLoadingProgress(percent);
        }
    };
    LoadingIndicatorService.prototype.dismiss = function () {
        if (LoadingIndicatorService.dialogRef) {
            LoadingIndicatorService.dialogRef.close();
            LoadingIndicatorService.dialogRef = undefined;
        }
    };
    return LoadingIndicatorService;
}());
export { LoadingIndicatorService };
