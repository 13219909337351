import { OnInit, OnDestroy } from '@angular/core';
import { PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';
import { takeWhile } from 'rxjs/operators';
import { AudioAlertService } from '../../services/audio-alert/audio-alert.service';
import { ApplicationError } from 'src/app/shared/errors/application-error';
var ALERT_INTERVAL = 15000;
var ERROR_INTERVAL = 1000;
var ERROR_ALERT_DELAY = 600000;
var UiPosChromeComponent = (function () {
    function UiPosChromeComponent(posDevice, audioAlert) {
        this.posDevice = posDevice;
        this.audioAlert = audioAlert;
    }
    UiPosChromeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.posDevice.pairedDevice
            .pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (pairedDevice) { _this.pairedDevice = pairedDevice; });
        this.posDevice.newOrders
            .pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (orders) { _this.updateOrders(orders); });
        this.posDevice.activeOrdersError
            .pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (error) { _this.updateError(error); });
    };
    UiPosChromeComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    UiPosChromeComponent.prototype.updateOrders = function (orders) {
        this.newOrders = orders.length;
        this.toggleAlert();
    };
    UiPosChromeComponent.prototype.updateError = function (error) {
        this.errorType = error instanceof ApplicationError ? 'network' : 'unplugged';
        this.errorMessage = error && error.message;
        this.toggleError();
    };
    UiPosChromeComponent.prototype.toggleError = function () {
        var _this = this;
        if (this.errorMessage) {
            if (!this.errorInterval) {
                this.unpluggedSince = Date.now();
                this.errorInterval = setInterval(function () { return _this.doError(); }, ERROR_INTERVAL);
            }
        }
        else {
            if (this.errorInterval) {
                clearInterval(this.errorInterval);
                this.errorInterval = undefined;
                this.unpluggedSince = undefined;
                this.displayTimeUntilAlarm = undefined;
            }
        }
    };
    UiPosChromeComponent.prototype.toggleAlert = function () {
        var _this = this;
        if (this.newOrders > 0) {
            if (!this.alertInterval) {
                this.doAlert();
                this.alertInterval = setInterval(function () {
                    if (_this.alertInterval) {
                        _this.doAlert();
                    }
                }, ALERT_INTERVAL);
            }
        }
        else {
            if (this.alertInterval) {
                this.alerting = false;
                clearInterval(this.alertInterval);
                this.alertInterval = undefined;
            }
        }
    };
    UiPosChromeComponent.prototype.doAlert = function () {
        var _this = this;
        if (!this.isStorePaired()) {
            return;
        }
        this.alerting = true;
        this.audioAlert.playOrderAlert();
        setTimeout(function () { return _this.alerting = false; }, 1000);
    };
    UiPosChromeComponent.prototype.doError = function () {
        if (!this.isStorePaired() || !this.unpluggedSince) {
            return;
        }
        if (this.errorType === 'unplugged') {
            var unpluggedDuration = Date.now() - this.unpluggedSince;
            var timeUntilAlarm = Math.floor((ERROR_ALERT_DELAY - unpluggedDuration) / 60000) + 1;
            this.displayTimeUntilAlarm = "alarm in " + timeUntilAlarm + "m";
            if (this.errorMessage && unpluggedDuration > ERROR_ALERT_DELAY) {
                this.unpluggedSince = Date.now();
                this.audioAlert.playErrorAlert();
            }
        }
        else {
            this.unpluggedSince = undefined;
            this.displayTimeUntilAlarm = undefined;
        }
    };
    UiPosChromeComponent.prototype.isStorePaired = function () {
        return this.pairedDevice && this.pairedDevice.active;
    };
    return UiPosChromeComponent;
}());
export { UiPosChromeComponent };
