import { Component, OnInit, OnDestroy, Input, TemplateRef, ViewChild } from '@angular/core';
import { Big } from 'big.js';

import { IdentityService, User } from 'src/app/shared/services/identity/identity.service';
import { takeWhile } from 'rxjs/operators';
import { MatSidenav, MatDialog, MatBottomSheet, MatSnackBar } from '@angular/material';
import { UserRewardService } from 'src/app/shared/services/user-reward/user-reward.service';
import { RewardDto, OrderViewConsumerDto } from 'src/gen/joeServerCore';
import { UiConfirmDialogComponent, ConfirmDialogOptions } from 'src/app/shared/packages/ui-confirm-dialog/components/ui-confirm-dialog/ui-confirm-dialog.component';
import { UserConsumerLoginBottomsheetComponent } from 'src/app/shared/packages/user-consumer-login/components/user-consumer-login-bottomsheet/user-consumer-login-bottomsheet.component';
import { UserConsumerUserInfoBottomsheetComponent, UserInfoBottomsheetData } from 'src/app/shared/packages/user-consumer-login/components/user-consumer-user-info-bottomsheet/user-consumer-user-info-bottomsheet.component';
import { StoreCheckoutBottomsheetComponent, CheckoutData, CheckoutBottomSheetResult } from 'src/app/consumer/pages/store/store-checkout/store-checkout-bottomsheet/store-checkout-bottomsheet.component';
import { UserService } from 'src/app/shared/services/user/user.service';
import { OrderService } from 'src/app/consumer/services/order/order.service';
import { SocialShareBottomSheetComponent } from 'src/app/shared/packages/social-share/components/social-share-bottom-sheet/social-share-bottom-sheet.component';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { TelemetryEventName } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry-event-name';
import { StoreService } from 'src/app/consumer/services/store/store.service';

@Component({
  selector: 'consumer-sidebar',
  templateUrl: './consumer-sidebar.component.html',
  styleUrls: ['./consumer-sidebar.component.scss'],
})
export class ConsumerSidebarComponent implements OnInit, OnDestroy {

  @Input()
  private sideNavRef: MatSidenav;

  @ViewChild('rewardInfoDialog')
  rewardInfoDialog: TemplateRef<any>;

  activeUser: User;

  rewardProgress: number;
  rewards: RewardDto[];

  joeBucksBalance: string;
  joeBucksVirgin: boolean;

  userOrders: OrderViewConsumerDto[];
  lastOrder: OrderViewConsumerDto;

  orderDataReady: boolean;
  joeBucksDataReady: boolean;
  rewardDataReady: boolean;

  userHasStoreRoles: boolean;

  private componentActive: boolean;

  constructor(
    private identityService: IdentityService,
    private rewardService: UserRewardService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private orderService: OrderService,
    private telemetryService: TelemetryService,
    private storeService: StoreService,
  ) { }

  public ngOnInit(): void {
    this.componentActive = true;
    this.identityService.watchActiveUser().pipe(takeWhile(() => this.componentActive)).subscribe(user => {
      this.activeUser = user;
    });

    this.sideNavRef.openedStart.pipe(takeWhile(() => this.componentActive))
      .subscribe(async () => {
        this.activeUser = this.identityService.getActiveUser();

        if (this.activeUser) {
          this.userOrders = await this.orderService.getUserOrders().toPromise();
          this.lastOrder = this.userOrders.filter(order => {
            return order.orderStatus !== 'cancelled_store' &&
              order.orderStatus !== 'rejected' &&
              order.orderStatus !== 'cancelled_customer';
          })[0] || undefined;
        }

        const userStores = await this.storeService.getUserStores().toPromise();
        this.userHasStoreRoles = userStores && userStores.length > 0;

        await this.updateRewards();
        await this.updateAccountBalance();

        this.setLoading(false);
      });

    this.sideNavRef.closedStart.pipe(takeWhile(() => this.componentActive))
      .subscribe(() => {
        this.setLoading(true);
      });
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  async logout(): Promise<void> {
    await this.identityService.logout().toPromise();
  }

  close(): void {
    this.sideNavRef.close();
  }

  async updateAccountBalance(): Promise<void> {
    if (!this.identityService.getActiveUser()) {
      return;
    }

    const joeBucksStatus = await this.userService.getJoeBucksBalanceStatus().toPromise();
    this.joeBucksBalance = joeBucksStatus.balance;
    this.joeBucksVirgin = joeBucksStatus.virgin;
  }

  showRewardInfo(): void {
    this.dialog.open<UiConfirmDialogComponent, ConfirmDialogOptions>(UiConfirmDialogComponent, {
      data: {
        content: this.rewardInfoDialog,
        cancellable: false,
      },
    });
  }

  // TODO: refactor this / move it somewhere shared
  // There is code identical almost to this in the ui-consumer-chrome component
  async showAddFunds(): Promise<void> {
    // this.close();

    if (!this.activeUser.hasEmail) {
      this.snackBar.open('Please add an e-mail address and password before adding funds.', '', { duration: 5000 });
      await this.bottomSheet.open<UserConsumerUserInfoBottomsheetComponent, UserInfoBottomsheetData, void>(
        UserConsumerUserInfoBottomsheetComponent,
        { data: { mode: 'email' }, panelClass: 'full-screen-sheet' },
      ).afterDismissed().toPromise();

      const activeUser = this.identityService.getActiveUser();
      if (!activeUser.hasEmail) {
        this.snackBar.open('Cannot add funds until you add an e-mail address and password.', '', { duration: 5000 });
        return;
      }
    }

    const uploadResult = await this.bottomSheet.open<StoreCheckoutBottomsheetComponent, CheckoutData, CheckoutBottomSheetResult>(
      StoreCheckoutBottomsheetComponent,
      { data: { joeBucksOnly: true }, panelClass: 'full-screen-sheet' },
    ).afterDismissed().toPromise();

    if (uploadResult && uploadResult.joebucksBalance) {
      const successMessage = this.joeBucksVirgin ?
        'Thanks for adding funds for the first time. We added a reward to your account!' :
        'Funds added successfully.';
      this.snackBar.open(successMessage, '', { duration: 5000, verticalPosition: 'top' });
      this.joeBucksBalance = uploadResult.joebucksBalance.balance;
    }
  }

  async showShareSheet(): Promise<void> {
    const sharedViaUrl =
      await this.bottomSheet.open<SocialShareBottomSheetComponent>(SocialShareBottomSheetComponent).afterDismissed().toPromise();

    this.telemetryService.logEvent(TelemetryEventName.SIDEBAR_SOCIAL_SHARE, {
      sharedVia: sharedViaUrl || 'cancelled',
    });
  }

  showLogin(): void {
    this.close();
    this.bottomSheet.open(UserConsumerLoginBottomsheetComponent, {
      panelClass: 'full-screen-sheet',
    });
  }

  private async updateRewards(): Promise<void> {
    if (!this.identityService.getActiveUser()) {
      return;
    }

    try {
      const result = await this.rewardService.getRewardStatus().toPromise();
      if (result) {
        this.rewardProgress = parseInt(Big(result.rewardProgress).times(10).toString(), 10);
        this.rewards = result.rewards;
      }
    } catch (e) {
    }
  }

  private setLoading(isLoading: boolean) {
    this.orderDataReady = !isLoading;
    this.rewardDataReady = !isLoading;
    if (this.activeUser) {
      this.activeUser.photo = isLoading ? undefined : this.activeUser.photo;
    }
    this.rewardProgress = isLoading ? undefined : this.rewardProgress;
  }
}
