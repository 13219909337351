var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Big } from 'big.js';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { takeWhile } from 'rxjs/operators';
import { MatSidenav, MatDialog, MatBottomSheet, MatSnackBar } from '@angular/material';
import { UserRewardService } from 'src/app/shared/services/user-reward/user-reward.service';
import { UiConfirmDialogComponent } from 'src/app/shared/packages/ui-confirm-dialog/components/ui-confirm-dialog/ui-confirm-dialog.component';
import { UserConsumerLoginBottomsheetComponent } from 'src/app/shared/packages/user-consumer-login/components/user-consumer-login-bottomsheet/user-consumer-login-bottomsheet.component';
import { UserConsumerUserInfoBottomsheetComponent } from 'src/app/shared/packages/user-consumer-login/components/user-consumer-user-info-bottomsheet/user-consumer-user-info-bottomsheet.component';
import { StoreCheckoutBottomsheetComponent } from 'src/app/consumer/pages/store/store-checkout/store-checkout-bottomsheet/store-checkout-bottomsheet.component';
import { UserService } from 'src/app/shared/services/user/user.service';
import { OrderService } from 'src/app/consumer/services/order/order.service';
import { SocialShareBottomSheetComponent } from 'src/app/shared/packages/social-share/components/social-share-bottom-sheet/social-share-bottom-sheet.component';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { StoreService } from 'src/app/consumer/services/store/store.service';
var ConsumerSidebarComponent = (function () {
    function ConsumerSidebarComponent(identityService, rewardService, dialog, bottomSheet, snackBar, userService, orderService, telemetryService, storeService) {
        this.identityService = identityService;
        this.rewardService = rewardService;
        this.dialog = dialog;
        this.bottomSheet = bottomSheet;
        this.snackBar = snackBar;
        this.userService = userService;
        this.orderService = orderService;
        this.telemetryService = telemetryService;
        this.storeService = storeService;
    }
    ConsumerSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.identityService.watchActiveUser().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (user) {
            _this.activeUser = user;
        });
        this.sideNavRef.openedStart.pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, userStores;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.activeUser = this.identityService.getActiveUser();
                        if (!this.activeUser) return [3, 2];
                        _a = this;
                        return [4, this.orderService.getUserOrders().toPromise()];
                    case 1:
                        _a.userOrders = _b.sent();
                        this.lastOrder = this.userOrders.filter(function (order) {
                            return order.orderStatus !== 'cancelled_store' &&
                                order.orderStatus !== 'rejected' &&
                                order.orderStatus !== 'cancelled_customer';
                        })[0] || undefined;
                        _b.label = 2;
                    case 2: return [4, this.storeService.getUserStores().toPromise()];
                    case 3:
                        userStores = _b.sent();
                        this.userHasStoreRoles = userStores && userStores.length > 0;
                        return [4, this.updateRewards()];
                    case 4:
                        _b.sent();
                        return [4, this.updateAccountBalance()];
                    case 5:
                        _b.sent();
                        this.setLoading(false);
                        return [2];
                }
            });
        }); });
        this.sideNavRef.closedStart.pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function () {
            _this.setLoading(true);
        });
    };
    ConsumerSidebarComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    ConsumerSidebarComponent.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.identityService.logout().toPromise()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ConsumerSidebarComponent.prototype.close = function () {
        this.sideNavRef.close();
    };
    ConsumerSidebarComponent.prototype.updateAccountBalance = function () {
        return __awaiter(this, void 0, void 0, function () {
            var joeBucksStatus;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.identityService.getActiveUser()) {
                            return [2];
                        }
                        return [4, this.userService.getJoeBucksBalanceStatus().toPromise()];
                    case 1:
                        joeBucksStatus = _a.sent();
                        this.joeBucksBalance = joeBucksStatus.balance;
                        this.joeBucksVirgin = joeBucksStatus.virgin;
                        return [2];
                }
            });
        });
    };
    ConsumerSidebarComponent.prototype.showRewardInfo = function () {
        this.dialog.open(UiConfirmDialogComponent, {
            data: {
                content: this.rewardInfoDialog,
                cancellable: false,
            },
        });
    };
    ConsumerSidebarComponent.prototype.showAddFunds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var activeUser, uploadResult, successMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.activeUser.hasEmail) return [3, 2];
                        this.snackBar.open('Please add an e-mail address and password before adding funds.', '', { duration: 5000 });
                        return [4, this.bottomSheet.open(UserConsumerUserInfoBottomsheetComponent, { data: { mode: 'email' }, panelClass: 'full-screen-sheet' }).afterDismissed().toPromise()];
                    case 1:
                        _a.sent();
                        activeUser = this.identityService.getActiveUser();
                        if (!activeUser.hasEmail) {
                            this.snackBar.open('Cannot add funds until you add an e-mail address and password.', '', { duration: 5000 });
                            return [2];
                        }
                        _a.label = 2;
                    case 2: return [4, this.bottomSheet.open(StoreCheckoutBottomsheetComponent, { data: { joeBucksOnly: true }, panelClass: 'full-screen-sheet' }).afterDismissed().toPromise()];
                    case 3:
                        uploadResult = _a.sent();
                        if (uploadResult && uploadResult.joebucksBalance) {
                            successMessage = this.joeBucksVirgin ?
                                'Thanks for adding funds for the first time. We added a reward to your account!' :
                                'Funds added successfully.';
                            this.snackBar.open(successMessage, '', { duration: 5000, verticalPosition: 'top' });
                            this.joeBucksBalance = uploadResult.joebucksBalance.balance;
                        }
                        return [2];
                }
            });
        });
    };
    ConsumerSidebarComponent.prototype.showShareSheet = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sharedViaUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.bottomSheet.open(SocialShareBottomSheetComponent).afterDismissed().toPromise()];
                    case 1:
                        sharedViaUrl = _a.sent();
                        this.telemetryService.logEvent("sidebar_social_share", {
                            sharedVia: sharedViaUrl || 'cancelled',
                        });
                        return [2];
                }
            });
        });
    };
    ConsumerSidebarComponent.prototype.showLogin = function () {
        this.close();
        this.bottomSheet.open(UserConsumerLoginBottomsheetComponent, {
            panelClass: 'full-screen-sheet',
        });
    };
    ConsumerSidebarComponent.prototype.updateRewards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.identityService.getActiveUser()) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.rewardService.getRewardStatus().toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result) {
                            this.rewardProgress = parseInt(Big(result.rewardProgress).times(10).toString(), 10);
                            this.rewards = result.rewards;
                        }
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ConsumerSidebarComponent.prototype.setLoading = function (isLoading) {
        this.orderDataReady = !isLoading;
        this.rewardDataReady = !isLoading;
        if (this.activeUser) {
            this.activeUser.photo = isLoading ? undefined : this.activeUser.photo;
        }
        this.rewardProgress = isLoading ? undefined : this.rewardProgress;
    };
    return ConsumerSidebarComponent;
}());
export { ConsumerSidebarComponent };
