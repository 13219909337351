import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TopbarService, TopbarShowStyle, TopbarOptions } from '../../services/topbar/topbar.service';
import { takeWhile } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

@Component({
  selector: 'consumer-topbar',
  templateUrl: './consumer-topbar.component.html',
  styleUrls: ['./consumer-topbar.component.scss'],
})
export class ConsumerTopbarComponent implements OnInit, OnDestroy {

  @Input()
  show: boolean;

  @Input()
  drawerOpen: boolean;

  @Output()
  private onToggleMenu = new EventEmitter();

  @Output()
  private onLogout = new EventEmitter();

  private componentActive: boolean;

  topbarHidden = true;
  topbarBouncePrepare = false;
  topbarBounce = false;
  mobileWebType: 'iphone' | 'android';

  constructor(
    private topbarService: TopbarService,
    private environmentService: EnvironmentService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.componentActive = true;

    if (!await this.environmentService.isNative()) {
      const userAgent = navigator.userAgent.toLowerCase();
      this.mobileWebType =
        userAgent.indexOf('iphone') > -1 ? 'iphone' :
          userAgent.indexOf('android') > -1 ? 'android' :
            undefined;
    }

    this.handleTopbarOptionChange(this.topbarService.getOptions());

    this.topbarService.watchOptions().pipe(takeWhile(() => this.componentActive))
      .subscribe(this.handleTopbarOptionChange.bind(this));
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  menuClick() {
    this.onToggleMenu.emit();
    this.drawerOpen = !this.drawerOpen;
  }

  logout() {
    this.onLogout.emit();
  }

  private handleTopbarOptionChange(options: TopbarOptions): void {
    switch (options.show) {
      case TopbarShowStyle.hidden:
        this.topbarHidden = true;
        this.topbarBouncePrepare = false;
        this.topbarBounce = false;
        break;
      case TopbarShowStyle.show:
        this.topbarHidden = false;
        this.topbarBouncePrepare = false;
        this.topbarBounce = false;
        break;
      case TopbarShowStyle.bounceIn:
        this.topbarHidden = false;
        this.topbarBouncePrepare = true;
        setTimeout(() => {
          this.topbarBounce = true;
        }, 50);
        break;
    }
  }

}
