import { OnInit } from '@angular/core';
import { MerchantTopbarService } from '../../services/merchant-topbar/merchant-topbar.service';
var MerchantTopbarOptionsComponent = (function () {
    function MerchantTopbarOptionsComponent(topbarService) {
        this.topbarService = topbarService;
    }
    Object.defineProperty(MerchantTopbarOptionsComponent.prototype, "title", {
        set: function (title) {
            this.topbarService.setTitle(title);
        },
        enumerable: true,
        configurable: true
    });
    MerchantTopbarOptionsComponent.prototype.ngOnInit = function () {
    };
    return MerchantTopbarOptionsComponent;
}());
export { MerchantTopbarOptionsComponent };
