import { OnInit, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import { DragulaService } from '../../services/dragula/dragula.service';
import { takeWhile } from 'rxjs/operators';
var DragulaDirective = (function () {
    function DragulaDirective(dragulaService, elementRef) {
        this.dragulaService = dragulaService;
        this.elementRef = elementRef;
        this.dragulaDrop = new EventEmitter();
        this.dragulaRemove = new EventEmitter();
    }
    Object.defineProperty(DragulaDirective.prototype, "dragulaId", {
        set: function (dragulaId) {
            this.internalDragulaId = dragulaId;
            if (this.directiveActive) {
                this.dragulaService.updateContainerId(this.dragula, this.elementRef.nativeElement, dragulaId);
            }
        },
        enumerable: true,
        configurable: true
    });
    DragulaDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.directiveActive = true;
        var containerId = this.dragulaService.addContainer(this.dragula, this.elementRef.nativeElement, this.internalDragulaId, this.dragulaOptions);
        this.dragulaService.on(3, this.dragula, containerId)
            .pipe(takeWhile(function () { return _this.directiveActive; })).subscribe(function (event) { return _this.dragulaDrop.emit(event); });
        this.dragulaService.on(0, this.dragula, containerId)
            .pipe(takeWhile(function () { return _this.directiveActive; })).subscribe(function (event) { return _this.dragulaRemove.emit(event); });
    };
    DragulaDirective.prototype.ngOnDestroy = function () {
        this.directiveActive = false;
        this.dragulaService.removeContainer(this.dragula, this.elementRef.nativeElement);
    };
    return DragulaDirective;
}());
export { DragulaDirective };
