import { Injectable } from '@angular/core';

@Injectable()
export class ScrollServiceService {

  constructor() { }

  disable(): void {
    const scrollContainer = this.getScrollContainer();
    if (scrollContainer) {
      scrollContainer.style.overflow = 'hidden';
      if (scrollContainer.scrollTo) {
        scrollContainer.scrollTo(0, 0);
      } else {
        scrollContainer.scrollTop = 0;
      }
    }
  }

  enable(): void {
    const scrollContainer = this.getScrollContainer();
    if (scrollContainer) {
      scrollContainer.style.overflow = 'auto';
    }
  }

  private getScrollContainer(): HTMLDivElement {
    return document.querySelector('.mat-drawer-content') as HTMLDivElement;
  }

}
