import { MatProgressSpinnerModule, MatTabsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDialogModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatProgressSpinnerModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
];
var LoginModule = (function () {
    function LoginModule() {
    }
    return LoginModule;
}());
export { LoginModule };
