<mat-nav-list>
  <div class="div-toggle-side-nav">
    <button (click)="toggleSidNav()" class="btn-toggle-side-nav" mat-button>
      <ng-container *ngIf="isDrawerOpen; else collapsible">
        <mat-icon matListIcon>expand_less</mat-icon>
        <mat-icon matListIcon>expand_less</mat-icon>
      </ng-container>
      <ng-template #collapsible>
        <mat-icon matListIcon>expand_more</mat-icon>
        <mat-icon matListIcon>expand_more</mat-icon>
      </ng-template>
    </button>
  </div>
  <ng-container *ngIf="activeCompanyId">
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'dashboard']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>dashboard</mat-icon>
      <h3 matLine>Dashboard</h3>
    </a>
    <!-- <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'report']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>analytics</mat-icon>
      <h3 matLine>Reporting BETA</h3>
    </a> -->
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'stores']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>store</mat-icon>
      <h3 matLine>Stores</h3>
    </a>
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'menus']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>list_alt</mat-icon>
      <h3 matLine>Menus</h3>
    </a>
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'promotions']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>public</mat-icon>
      <h3 matLine>Promote</h3>
    </a>
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'banking']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>account_balance</mat-icon>
      <h3 matLine>Banking</h3>
    </a>
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'edit']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>settings</mat-icon>
      <h3 matLine>Company Settings</h3>
    </a>
    <a mat-list-item [routerLink]="['/m/company', activeCompanyId, 'users']" routerLinkActive="active"
      class="sidenav-link">
      <mat-icon matListIcon>people</mat-icon>
      <h3 matLine>Users</h3>
    </a>
  </ng-container>
  <mat-divider></mat-divider>
  <a mat-list-item routerLink="/m/companies" routerLinkActive="active" class="sidenav-link">
    <mat-icon matListIcon>format_list_bulleted</mat-icon>
    <h3 matLine>Companies</h3>
  </a>
  <a mat-list-item routerLink="/m/company/create" routerLinkActive="active" class="sidenav-link">
    <mat-icon matListIcon>business</mat-icon>
    <h3 matLine>Create Company</h3>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item href="https://support.joe.coffee" target="_blank" class="sidenav-link">
    <mat-icon matListIcon>help_outline</mat-icon>
    <h3 matLine>Support</h3>
  </a>
  <!-- ADMIN ONLY -->
  <ng-container *ngIf="isUserAdmin">
    <mat-divider></mat-divider>
    <a mat-list-item routerLink="/m/joe-admin/territory-dashboard" routerLinkActive="active" class="sidenav-link">
      <mat-icon matListIcon>ballot</mat-icon>
      <h3 matLine>Ops Dashboard</h3>
    </a>
    <a mat-list-item routerLink="/m/joe-admin/bi-dashboard" routerLinkActive="active" class="sidenav-link">
      <mat-icon matListIcon>show_chart</mat-icon>
      <h3 matLine>Network BI</h3>
    </a>
    <a mat-list-item routerLink="/m/joe-admin/bi-map" routerLinkActive="active" class="sidenav-link">
      <mat-icon matListIcon>map</mat-icon>
      <h3 matLine>Network Map</h3>
    </a>
    <a mat-list-item routerLink="/m/joe-admin/control-panel" routerLinkActive="active" class="sidenav-link">
      <mat-icon matListIcon>tune</mat-icon>
      <h3 matLine>Network Control Panel</h3>
    </a>
  </ng-container>
  <!-- BI ROLE ONLY -->
  <ng-container *ngIf="isUserBiRole">
    <mat-divider></mat-divider>
    <a mat-list-item routerLink="/m/joe-admin/bi-map" routerLinkActive="active" class="sidenav-link">
      <mat-icon matListIcon>map</mat-icon>
      <h3 matLine>Network Map</h3>
    </a>
  </ng-container>
</mat-nav-list>
