import { EventEmitter, TemplateRef } from '@angular/core';
var SUPPORTED_COLORS = new Set(['primary', 'light', 'accent', 'dark', 'transparent']);
var DEFAULT_FRONT_COLOR = 'primary';
var DEFAULT_BACK_COLOR = 'primary';
var DEFAULT_FRONT_shadow = true;
var DEFAULT_BACK_shadow = true;
var UiFlipFabComponent = (function () {
    function UiFlipFabComponent() {
        this.flipped = false;
        this.colorFrontClass = DEFAULT_FRONT_COLOR;
        this.colorBackClass = DEFAULT_BACK_COLOR;
        this.shadowFront = DEFAULT_FRONT_shadow;
        this.shadowBack = DEFAULT_BACK_shadow;
        this.flippedChange = new EventEmitter();
    }
    Object.defineProperty(UiFlipFabComponent.prototype, "colorFront", {
        set: function (colorFront) {
            this.colorFrontClass = SUPPORTED_COLORS.has(colorFront) ? colorFront : DEFAULT_FRONT_COLOR;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UiFlipFabComponent.prototype, "colorBack", {
        set: function (colorFront) {
            this.colorBackClass = SUPPORTED_COLORS.has(colorFront) ? colorFront : DEFAULT_BACK_COLOR;
        },
        enumerable: true,
        configurable: true
    });
    UiFlipFabComponent.prototype.toggle = function () {
        this.flipped = !this.flipped;
        this.flippedChange.emit(this.flipped);
    };
    return UiFlipFabComponent;
}());
export { UiFlipFabComponent };
