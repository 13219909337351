import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MerchantPlusNagDialogComponent } from './components/merchant-plus-nag-dialog/merchant-plus-nag-dialog.component';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    MerchantPlusNagDialogComponent,
  ],
  entryComponents: [
    MerchantPlusNagDialogComponent,
  ],
})
export class MerchantPlusNagDialogModule { }
