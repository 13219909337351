import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { SOCIAL_SHARE_DEFAULT_MESSAGE } from 'src/app/shared/services/social-share/social-share.service';

type ShareSource = 'facebook' | 'twitter' | 'copy';

@Component({
  selector: 'social-share-bottom-sheet',
  templateUrl: './social-share-bottom-sheet.component.html',
  styleUrls: ['./social-share-bottom-sheet.component.scss'],
})
export class SocialShareBottomSheetComponent {

  facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=get.joe.coffee';
  twitterShareUrl = `http://www.twitter.com/share?url=get.joe.coffee&text=${encodeURI(SOCIAL_SHARE_DEFAULT_MESSAGE)}&via=joecoffeeapp`;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<any, any>,
  ) { }

  copyToClipboard(): void {
    // strange but this is the lightest way to achieve copy to clipboard.
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://get.joe.coffee';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.dismiss('copy');
  }

  dismiss(shareSource?: ShareSource) {
    this.bottomSheetRef.dismiss(shareSource);
  }

}
