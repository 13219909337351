import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsDurationToText',
})
export class SecondsDurationToTextPipe implements PipeTransform {

  transform(totalMinutes: number): string {
    if (totalMinutes < 60) {
      return `${totalMinutes} minutes`;
    }

    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minuteText = minutes > 0 ? ` ${minutes} minutes` : '';
    return `${hours} hours${minuteText}`;
  }

}
