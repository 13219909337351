import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosDeviceService } from '../pos-devices/pos-device.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { BatteryStatus } from '@ionic-native/battery-status';
import { SocketIoModule } from 'ngx-socket-io';
import { MerchantServiceSocketService } from '../merchant-service-socket/merchant-service-socket.service';

@NgModule({
  imports: [
    CommonModule,
    SocketIoModule,
  ],
  providers: [PosDeviceService, MerchantServiceSocketService],
  declarations: [],
})
export class PosDeviceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PosDeviceModule,
      providers: [PosDeviceService, BatteryStatus],
    };
  }
}
