import { PipeTransform } from '@angular/core';
var NumberPadPipe = (function () {
    function NumberPadPipe() {
    }
    NumberPadPipe.prototype.transform = function (value, left, decimal) {
        if (decimal === void 0) { decimal = 0; }
        if (isNaN(parseFloat(value))) {
            value = 0;
        }
        var decimalLength = decimal > 0 ? decimal + 1 : 0;
        var displayNumber = value.toFixed(decimal);
        var zeros = '0'.repeat(Math.max(0, left - displayNumber.length + decimalLength));
        return zeros + displayNumber;
    };
    return NumberPadPipe;
}());
export { NumberPadPipe };
