export type CallbackFn<T extends any[] = [], R extends any = void> = (...args: T) => R;

/**
 * Safely access a property of an object or array (null checks) while preserving type safety
 */
export function safeGet<T extends any, R extends any>(
  obj: T, accessor: CallbackFn<[T], R>, fallback?: R, // = undefined
): R {
  if (!obj || typeof accessor !== 'function') {
    return fallback;
  }

  try {
    const result = accessor(obj);
    return result === null || result === undefined ? fallback : result;
  } catch (e) {
    return fallback;
  }
}
