import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { getTimezoneOffset } from 'src/app/shared/helpers/date/get-timezone-offset';

@Pipe({
  name: 'datePatched',
})
export class DatePatchedPipe implements PipeTransform {

  // to expose for testing
  private getTimezoneOffset = getTimezoneOffset;

  constructor(private date: DatePipe) { }

  /**
   * Patched angular date pipe
   * This fixes a REALLY WEIRD bug that causes the timezone offset to be 0 occasionally without warning
   * I think this is an issue with cordova or the web view or our tablets. Hard to tell
   */
  transform(value: any, format?: string, timezone?: string, locale?: string): string {

    if (!timezone) {
      timezone = this.getTimezoneOffset();
    }

    return this.date.transform(value, format, timezone, locale);
  }

}
