var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit, NgZone } from '@angular/core';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { DeviceRegistrationService } from 'src/app/shared/services/device-registration/device-registration.service';
import { MatSnackBar, MatBottomSheetRef } from '@angular/material';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { NativeSimInfoService } from '../../../native-support/services/native-sim-info/native-sim-info.service';
var MAX_VERIFICATION_ATTEMPTS = 5;
var RESET_PASSWORD_URI = '/reset-password';
var UserConsumerLoginBottomsheetComponent = (function () {
    function UserConsumerLoginBottomsheetComponent(data, bottomSheetRef, identityService, changeDetectorRef, deviceRegistrationService, snackbar, environmentService, nativeSimInfoService, ngZone) {
        this.data = data;
        this.bottomSheetRef = bottomSheetRef;
        this.identityService = identityService;
        this.changeDetectorRef = changeDetectorRef;
        this.deviceRegistrationService = deviceRegistrationService;
        this.snackbar = snackbar;
        this.environmentService = environmentService;
        this.nativeSimInfoService = nativeSimInfoService;
        this.ngZone = ngZone;
        this.loginStep = 'phone';
        this.verificationTryCount = 0;
        this.userPhoneNumber = '';
        this.enteredCode = '';
    }
    UserConsumerLoginBottomsheetComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var number;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.nativeSimInfoService.tryGetPhoneNumber()];
                    case 1:
                        number = _a.sent();
                        if (number && number.length > 9) {
                            this.userPhoneNumber = number.slice(-10);
                            this.changeDetectorRef.markForCheck();
                        }
                        return [2];
                }
            });
        });
    };
    UserConsumerLoginBottomsheetComponent.prototype.onUserPhoneSubmit = function () {
        this.userInfo = __assign({}, this.userInfo, { phone: this.userPhoneNumber });
        this.loading = false;
        this.sendCode();
    };
    UserConsumerLoginBottomsheetComponent.prototype.onUserNameSubmit = function (nameData) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, firstName, lastName;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        return [4, this.identityService.updateUserName(nameData.firstName, nameData.lastName).toPromise()];
                    case 1:
                        _a = _b.sent(), firstName = _a.firstName, lastName = _a.lastName;
                        this.userInfo = __assign({}, this.userInfo, { firstName: firstName, lastName: lastName });
                        this.dismiss();
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    UserConsumerLoginBottomsheetComponent.prototype.onEnteredCode = function (code) {
        return __awaiter(this, void 0, void 0, function () {
            var device, _a, userId, firstName, photo, hasEmail, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!code) {
                            this.userInfo = undefined;
                            this.loading = false;
                            this.loginStep = 'phone';
                            this.changeDetectorRef.markForCheck();
                            return [2];
                        }
                        this.loading = true;
                        return [4, this.deviceRegistrationService.getConsumerDevice()];
                    case 1:
                        device = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4, this.identityService.consumerLoginWithVerificationCode({
                                userId: this.verifyUserId,
                                code: code,
                                deviceId: device && device.id,
                            }).toPromise()];
                    case 3:
                        _a = _b.sent(), userId = _a.id, firstName = _a.firstName, photo = _a.photo, hasEmail = _a.hasEmail;
                        this.identityService.setActiveUser(userId);
                        this.userInfo = __assign({}, this.userInfo, { firstName: firstName, photo: photo, hasEmail: hasEmail });
                        this.setPostLoginStep();
                        return [3, 5];
                    case 4:
                        e_1 = _b.sent();
                        this.verificationTryCount++;
                        if (this.verificationTryCount >= MAX_VERIFICATION_ATTEMPTS) {
                            this.userInfo = undefined;
                            this.loginStep = 'phone';
                            this.verificationTryCount = 0;
                        }
                        return [3, 5];
                    case 5:
                        this.loading = false;
                        this.changeDetectorRef.detectChanges();
                        return [2];
                }
            });
        });
    };
    UserConsumerLoginBottomsheetComponent.prototype.showForgotPassword = function () {
        this.setErrorMessage();
        this.loginStep = 'forgot-password';
        this.changeDetectorRef.markForCheck();
    };
    UserConsumerLoginBottomsheetComponent.prototype.onForgotPasswordSubmit = function (_a) {
        var email = _a.email;
        return __awaiter(this, void 0, void 0, function () {
            var envConfig, resetUrl, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setErrorMessage();
                        this.loading = true;
                        envConfig = this.environmentService.getConfig();
                        resetUrl = envConfig.baseWebUrl.consumer + RESET_PASSWORD_URI;
                        return [4, this.identityService.requestPasswordReset(email, resetUrl).toPromise()];
                    case 1:
                        result = (_b.sent()).result;
                        if (result === true) {
                            this.setMessage('Password reset e-mail sent (if your account exists). Check your e-mail to reset your password.');
                        }
                        else {
                            this.setErrorMessage('Unable to reset your password right now.');
                        }
                        this.loading = false;
                        this.loginStep = 'password-login';
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    UserConsumerLoginBottomsheetComponent.prototype.cancelResetPassword = function () {
        this.userInfo = undefined;
        this.loading = false;
        this.loginStep = 'phone';
        this.changeDetectorRef.markForCheck();
    };
    UserConsumerLoginBottomsheetComponent.prototype.setPostLoginStep = function () {
        if (!this.userInfo.firstName) {
            this.loginStep = 'name';
        }
        else {
            this.dismiss();
        }
    };
    UserConsumerLoginBottomsheetComponent.prototype.onPhonePasswordLoginSubmit = function (_a) {
        var phone = _a.phone, password = _a.password;
        return __awaiter(this, void 0, void 0, function () {
            var device, userId, user, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setErrorMessage();
                        this.loading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4, this.deviceRegistrationService.getConsumerDevice()];
                    case 2:
                        device = _b.sent();
                        return [4, this.identityService.login({ phone: '1' + phone, password: password, deviceId: device && device.id }).toPromise()];
                    case 3:
                        userId = _b.sent();
                        this.identityService.setActiveUser(userId);
                        user = this.identityService.getActiveUser();
                        this.userInfo = __assign({}, this.userInfo, user);
                        this.setPostLoginStep();
                        return [3, 5];
                    case 4:
                        e_2 = _b.sent();
                        if (e_2.status === 422) {
                            this.setErrorMessage('Invalid phone number / password combination.');
                        }
                        else {
                            this.setErrorMessage('Unable to login at this time. Please try again later.');
                        }
                        return [3, 5];
                    case 5:
                        this.loading = false;
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    UserConsumerLoginBottomsheetComponent.prototype.sendCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var storeId, _a, e_3, suspensionMessage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setErrorMessage();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        storeId = this.data && this.data.storeId ? this.data.storeId : undefined;
                        _a = this;
                        return [4, this.identityService.consumerSignupWithPhone(this.userInfo, storeId).toPromise()];
                    case 2:
                        _a.verifyUserId = _b.sent();
                        this.loginStep = 'code';
                        return [3, 4];
                    case 3:
                        e_3 = _b.sent();
                        if (e_3.status === 409) {
                            this.loginStep = 'password-login';
                        }
                        else {
                            this.userInfo = undefined;
                            if (e_3.status === 403) {
                                suspensionMessage = e_3 && e_3.error && e_3.error.message;
                                this.setErrorMessage("Your account has been suspended. Reason: " + suspensionMessage);
                            }
                            else {
                                this.setErrorMessage('Unable to send confirmation code. Please try again.');
                            }
                        }
                        return [3, 4];
                    case 4:
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    UserConsumerLoginBottomsheetComponent.prototype.verifyNumberOnlyInput = function (event) {
        var elem = event.target;
        elem.value = elem.value.replace(/[^0-9]+/g, '');
        return elem.value === '' ? false : true;
    };
    UserConsumerLoginBottomsheetComponent.prototype.setErrorMessage = function (message) {
        this.setMessage(message, 'Error', 'error-toast');
    };
    UserConsumerLoginBottomsheetComponent.prototype.setMessage = function (message, prefix, panelClass) {
        if (message) {
            this.snackbar.open("" + (prefix ? prefix + ': ' : '') + message, '', { verticalPosition: 'top', duration: 10000, panelClass: panelClass });
        }
        else {
            this.snackbar.dismiss();
        }
    };
    UserConsumerLoginBottomsheetComponent.prototype.dismiss = function () {
        var _this = this;
        this.ngZone.run(function () { return _this.bottomSheetRef.dismiss(); });
    };
    return UserConsumerLoginBottomsheetComponent;
}());
export { UserConsumerLoginBottomsheetComponent };
