import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
export var REWARD_PROGRESS_ANIMATION_LENGTH = 1000;
var SidebarRewardProgressComponent = (function () {
    function SidebarRewardProgressComponent() {
        var _this = this;
        this.rewardAnimationValue = 0;
        this.time = {
            start: performance.now(),
            total: REWARD_PROGRESS_ANIMATION_LENGTH,
            elapsed: 0,
        };
        this.animateRewardProgress = function (now) {
            _this.time.elapsed = now - _this.time.start;
            var currentProgress = _this.time.elapsed / _this.time.total;
            var possiblePosition = currentProgress * _this.rewardProgressValue;
            var position = possiblePosition > _this.rewardProgressValue ? _this.rewardProgressValue : possiblePosition;
            if (_this.rewardAnimationValue < _this.rewardProgressValue) {
                _this.rewardAnimationValue = position;
            }
            if (currentProgress < 1) {
                requestAnimationFrame(function (progress) {
                    _this.animateRewardProgress(progress);
                });
            }
        };
    }
    SidebarRewardProgressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.rewardAnimationValue = 0;
        if ((this.imgUrl || this.imgUrl === null) && this.rewardProgress !== undefined) {
            this.rewardProgressValue = Math.floor(this.rewardProgress / 10);
            this.loading = false;
            this.time.start = performance.now();
            requestAnimationFrame(function (progress) {
                _this.animateRewardProgress(progress);
            });
        }
    };
    SidebarRewardProgressComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        this.loading = true;
        this.rewardAnimationValue = 0;
        if (changes.imgUrl && !this.imgUrl) {
            this.imgUrl = changes.imgUrl.currentValue;
        }
        if ((this.imgUrl || this.imgUrl === null) && this.rewardProgress !== undefined && changes.rewardProgress) {
            this.rewardProgress = changes.rewardProgress.currentValue;
            this.rewardProgressValue = Math.floor(this.rewardProgress / 10);
            this.loading = false;
            this.time.start = performance.now();
            requestAnimationFrame(function (progress) {
                _this.animateRewardProgress(progress);
            });
        }
    };
    return SidebarRewardProgressComponent;
}());
export { SidebarRewardProgressComponent };
