var ScrollServiceService = (function () {
    function ScrollServiceService() {
    }
    ScrollServiceService.prototype.disable = function () {
        var scrollContainer = this.getScrollContainer();
        if (scrollContainer) {
            scrollContainer.style.overflow = 'hidden';
            if (scrollContainer.scrollTo) {
                scrollContainer.scrollTo(0, 0);
            }
            else {
                scrollContainer.scrollTop = 0;
            }
        }
    };
    ScrollServiceService.prototype.enable = function () {
        var scrollContainer = this.getScrollContainer();
        if (scrollContainer) {
            scrollContainer.style.overflow = 'auto';
        }
    };
    ScrollServiceService.prototype.getScrollContainer = function () {
        return document.querySelector('.mat-drawer-content');
    };
    return ScrollServiceService;
}());
export { ScrollServiceService };
