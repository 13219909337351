import { ModuleWithProviders } from '@angular/core';
import { BottombarComponent } from './components/bottombar/bottombar.component';
import { BottombarService } from './services/bottombar/bottombar.service';
import { BottombarTemplateComponent } from './components/bottombar/bottombar-template/bottombar-template.component';
var EXPORTED_COMPONENTS = [
    BottombarComponent,
    BottombarTemplateComponent,
];
var UiBottomBarModule = (function () {
    function UiBottomBarModule() {
    }
    UiBottomBarModule.forRoot = function () {
        return {
            ngModule: UiBottomBarModule,
            providers: [BottombarService],
        };
    };
    return UiBottomBarModule;
}());
export { UiBottomBarModule };
