import { EnvironmentFeatureSwitches } from './interfaces/environment-features.interface';

export const FEATURES_PRODUCTION: EnvironmentFeatureSwitches = {

  /**
   * This list should stay short.
   *
   * Shortly after setting production to true
   * the feature switch should be removed
   */

};
