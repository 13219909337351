import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
var MenuItemSizeChipComponent = (function () {
    function MenuItemSizeChipComponent() {
    }
    MenuItemSizeChipComponent.prototype.ngOnInit = function () {
    };
    MenuItemSizeChipComponent.prototype.inputClick = function (event) {
        event.stopPropagation();
    };
    return MenuItemSizeChipComponent;
}());
export { MenuItemSizeChipComponent };
