import { PipeTransform } from '@angular/core';
var DateDifferencePipe = (function () {
    function DateDifferencePipe() {
    }
    DateDifferencePipe.prototype.transform = function (value1, value2) {
        var date1 = new Date(value1);
        var date2 = new Date(value2);
        return date1.getTime() - date2.getTime();
    };
    return DateDifferencePipe;
}());
export { DateDifferencePipe };
