import { of } from 'rxjs';
import { IdentityService } from '../../services/identity/identity.service';
import { PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';
var LogoutGuardService = (function () {
    function LogoutGuardService(identityService, posDevice) {
        this.identityService = identityService;
        this.posDevice = posDevice;
    }
    LogoutGuardService.prototype.canActivate = function () {
        this.identityService.logout();
        this.posDevice.unpair();
        return of(true);
    };
    return LogoutGuardService;
}());
export { LogoutGuardService };
