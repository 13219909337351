import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageService } from './storage/storage.service';
import { NativeStorage } from '@ionic-native/native-storage';
import { File } from '@ionic-native/file';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    StorageService,
    NativeStorage,
    File,
  ],
  exports: [],
  entryComponents: [],

})
export class StorageModule { }
