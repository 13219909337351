import { Component, ChangeDetectorRef, Inject, OnInit, NgZone } from '@angular/core';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { DeviceRegistrationService } from 'src/app/shared/services/device-registration/device-registration.service';
import { MatSnackBar, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { InputEvent } from 'src/app/shared/types/input-event.type';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { NativeSimInfoService } from '../../../native-support/services/native-sim-info/native-sim-info.service';

const MAX_VERIFICATION_ATTEMPTS = 5;

interface UserInfo {
  firstName: string;
  lastName: string;
  phone: string;
  photo?: string;
  hasEmail?: boolean;
}

export interface LoginBottomsheetData {
  storeId?: string;
}

const RESET_PASSWORD_URI = '/reset-password';

@Component({
  selector: 'user-consumer-login-bottomsheet',
  templateUrl: './user-consumer-login-bottomsheet.component.html',
  styleUrls: ['./user-consumer-login-bottomsheet.component.scss'],
})
export class UserConsumerLoginBottomsheetComponent implements OnInit {

  loginStep: 'phone' | 'code' | 'name' | 'password-login' | 'forgot-password' = 'phone';
  userInfo: UserInfo;
  verifyUserId: string;
  loading: boolean;
  verificationTryCount = 0;
  userPhoneNumber = '';
  enteredCode = '';

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private readonly data: LoginBottomsheetData,
    private readonly bottomSheetRef: MatBottomSheetRef<any, any>,
    private readonly identityService: IdentityService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly deviceRegistrationService: DeviceRegistrationService,
    private readonly snackbar: MatSnackBar,
    private readonly environmentService: EnvironmentService,
    private readonly nativeSimInfoService: NativeSimInfoService,
    private readonly ngZone: NgZone,
  ) { }

  async ngOnInit(): Promise<void> {
    const number = await this.nativeSimInfoService.tryGetPhoneNumber();
    if (number && number.length > 9) {
      // remove country code (just last 10 digits)
      this.userPhoneNumber = number.slice(-10);
      // bottom sheet requires manual change detection
      this.changeDetectorRef.markForCheck();
    }
  }

  onUserPhoneSubmit(): void {
    this.userInfo = { ...this.userInfo, phone: this.userPhoneNumber };
    this.loading = false;
    this.sendCode();
  }

  async onUserNameSubmit(nameData: UserInfo): Promise<void> {
    this.loading = true;
    const { firstName, lastName } = await this.identityService.updateUserName(nameData.firstName, nameData.lastName).toPromise();
    this.userInfo = { ...this.userInfo, firstName, lastName };
    this.dismiss();

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  async onEnteredCode(code?: string): Promise<void> {
    if (!code) {
      // user clicked cancel (back)
      this.userInfo = undefined;
      this.loading = false;
      this.loginStep = 'phone';
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.loading = true;

    const device = await this.deviceRegistrationService.getConsumerDevice();

    try {
      const { id: userId, firstName, photo, hasEmail } = await this.identityService.consumerLoginWithVerificationCode({
        userId: this.verifyUserId,
        code,
        deviceId: device && device.id,
      }).toPromise();

      this.identityService.setActiveUser(userId);
      this.userInfo = { ...this.userInfo, firstName, photo, hasEmail };
      this.setPostLoginStep();

    } catch (e) {
      this.verificationTryCount++;
      if (this.verificationTryCount >= MAX_VERIFICATION_ATTEMPTS) {
        this.userInfo = undefined;
        this.loginStep = 'phone';
        this.verificationTryCount = 0;
      }
    }

    this.loading = false;

    // bottom sheet requires manual change detection
    this.changeDetectorRef.detectChanges();
  }

  showForgotPassword(): void {
    this.setErrorMessage();
    this.loginStep = 'forgot-password';
    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  async onForgotPasswordSubmit({ email }: { email: string }): Promise<void> {
    this.setErrorMessage();

    this.loading = true;
    const envConfig = this.environmentService.getConfig();

    const resetUrl = envConfig.baseWebUrl.consumer + RESET_PASSWORD_URI;

    const { result } = await this.identityService.requestPasswordReset(email, resetUrl).toPromise();

    if (result === true) {
      this.setMessage('Password reset e-mail sent (if your account exists). Check your e-mail to reset your password.');
    } else {
      this.setErrorMessage('Unable to reset your password right now.');
    }

    this.loading = false;
    this.loginStep = 'password-login';
    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  cancelResetPassword(): void {
    this.userInfo = undefined;
    this.loading = false;
    this.loginStep = 'phone';
    this.changeDetectorRef.markForCheck();
  }

  private setPostLoginStep(): void {
    if (!this.userInfo.firstName) {
      this.loginStep = 'name';
    } else {
      this.dismiss();
    }
  }

  async onPhonePasswordLoginSubmit({ phone, password }: { phone: string, password: string }): Promise<void> {
    this.setErrorMessage();
    this.loading = true;

    try {
      const device = await this.deviceRegistrationService.getConsumerDevice();
      const userId = await this.identityService.login({ phone: '1' + phone, password, deviceId: device && device.id }).toPromise();
      this.identityService.setActiveUser(userId);
      const user = this.identityService.getActiveUser();
      this.userInfo = { ...this.userInfo, ...user };
      this.setPostLoginStep();
    } catch (e) {
      if (e.status === 422) {
        this.setErrorMessage('Invalid phone number / password combination.');
      } else {
        this.setErrorMessage('Unable to login at this time. Please try again later.');
      }
    }

    this.loading = false;

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  async sendCode(): Promise<void> {
    this.setErrorMessage();
    try {
      const storeId = this.data && this.data.storeId ? this.data.storeId : undefined;
      this.verifyUserId = await this.identityService.consumerSignupWithPhone(this.userInfo, storeId).toPromise();
      this.loginStep = 'code';
    } catch (e) {

      // 409 Conflict = User already has a password
      if (e.status === 409) {
        this.loginStep = 'password-login';
      } else {
        this.userInfo = undefined;

        // 403 Forbidden = User account suspended
        if (e.status === 403) {
          const suspensionMessage = e && e.error && e.error.message;
          this.setErrorMessage(`Your account has been suspended. Reason: ${suspensionMessage}`);
        } else {
          // Unknown error
          this.setErrorMessage('Unable to send confirmation code. Please try again.');
        }
      }
    }

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  verifyNumberOnlyInput(event: InputEvent<HTMLInputElement>): boolean {
    const elem = event.target;
    elem.value = elem.value.replace(/[^0-9]+/g, '');
    return elem.value === '' ? false : true;
  }

  private setErrorMessage(message?: string): void {
    this.setMessage(message, 'Error', 'error-toast');
  }

  private setMessage(message?: string, prefix?: string, panelClass?: string): void {
    if (message) {
      this.snackbar.open(
        `${prefix ? prefix + ': ' : ''}${message}`,
        '',
        { verticalPosition: 'top', duration: 10000, panelClass },
      );
    } else {
      this.snackbar.dismiss();
    }
  }

  dismiss() {
    this.ngZone.run(() => this.bottomSheetRef.dismiss());
  }

}
