import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import {
  EnvironmentService,
} from 'src/app/shared/services/environment/environment.service';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { MatSidenav } from '@angular/material';
import { takeWhile } from 'rxjs/operators';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'ui-merchant-chrome',
  templateUrl: './ui-merchant-chrome.component.html',
  styleUrls: ['./ui-merchant-chrome.component.scss'],
})
export class UiMerchantChromeComponent implements OnInit, OnDestroy {
  opened = false;
  showTopbar = false;
  displayVersionInfo = 'unknown version';
  // kinda hacky way to hide the top bar when the signup form is loaded via iFrame
  isIFrameSignUp: boolean;
  mobileMode = false;
  hideTopAndSideBar = false;
  private componentActive: boolean;

  @ViewChild(MatSidenav)
  private sidenav: MatSidenav;

  constructor(
    private router: Router,
    private identityService: IdentityService,
    private environmentService: EnvironmentService,
    private mediaObserver: MediaObserver,
  ) { }

  public ngOnInit(): void {
    this.checkParentWindow();
    this.isIFrameSignUp = location.href.endsWith('/signup-iframe');

    this.componentActive = true;
    this.initMediaListener();

    this.identityService.watchActiveUser().pipe(takeWhile(() => this.componentActive)).subscribe(user => {
      this.showTopbar = !!user && !this.isIFrameSignUp;
    });

    if (this.router.url.indexOf('/pos') > -1) {
      this.hideTopAndSideBar = true;
    }

    // auto close sidebar on navigation
    this.router.events.pipe(takeWhile(() => this.componentActive)).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url.indexOf('/pos') > -1) {
          this.hideTopAndSideBar = true;
        } else {
          this.hideTopAndSideBar = false;
          this.checkParentWindow();
        }
        // auto close nav on MOBILE ONLY
        // Timeout needed to fix a weird bug where the view doesn't update after navigation
        // classes are updated in dom, but changes aren't reflected as best I can tell it's a bug in chrome
        if (this.mobileMode) {
          setTimeout(() => {
            if (this.sidenav) {
              this.sidenav.close();
            }
            this.opened = false;
          }, 200);
        }
        
        this.handleRedirect(event.url);
      }
    });

    this.populateEnvironmentInfo();
    this.loadInitialUser();
    this.handleRedirect();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private async initMediaListener(): Promise<void> {
    const isNative = await this.environmentService.isNative();

    this.mobileMode = isNative || this.mediaObserver.isActive('lt-md');
    this.opened = !this.mobileMode;
    this.mediaObserver.media$.pipe(takeWhile(() => this.componentActive)).subscribe(() => {
      this.mobileMode = isNative || this.mediaObserver.isActive('lt-md');
    });

  }

  async logout(): Promise<void> {
    this.sidenav.close();
    await this.identityService.logout().toPromise();
    this.router.navigateByUrl('/login');
  }

  private async loadInitialUser(): Promise<void> {
    await this.identityService.isLoaded();
    this.showTopbar = !!this.identityService.getActiveUser() && !this.isIFrameSignUp;
  }

  private async populateEnvironmentInfo(): Promise<void> {
    const versionData = await this.environmentService.getVersions();
    const platform = await this.environmentService.getPlatformName();
    const native = await this.environmentService.isNative();

    const displayMode = 'merchant';
    const displayBuildNumber = versionData && versionData.build && versionData.build.number || 'unknown';
    let displayPlatform = platform;

    if (native) {
      displayPlatform += ` (${versionData.deviceNumber} - ${versionData.deviceCode})`;
    } else {
      displayPlatform += ` (${versionData.packageVersion})`;
    }

    this.displayVersionInfo = `${displayMode} - ${displayPlatform} - ${displayBuildNumber}`;
  }

  private checkParentWindow(): boolean {
    if (window.self !== window.top) {
      this.hideTopAndSideBar = true;
      return true;
    }

    return false;
  }

  private handleRedirect(url: string = this.router.url) {
    const isUserLoggedIn = this.identityService.getActiveUser();
    const isJoeAdmin = this.identityService.isActiveUserAdmin();
    const isInsideIframe = this.checkParentWindow();
    const isLogInScreen = url.includes("/login") || url === "/m";
    const shouldRedirect = isUserLoggedIn && !isLogInScreen && !isInsideIframe;

    if (isJoeAdmin) {
      return;
    }

    if (shouldRedirect) {
      const isProduction =
        this.environmentService.getEnvName() === "Production";

      window.location.href = `https://${
        isProduction ? "" : "staging-"
      }manage.joe.coffee/`;

      return;
    }
  }

}
