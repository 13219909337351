import { PipeTransform } from '@angular/core';
var MinutesToLocaleTimePipe = (function () {
    function MinutesToLocaleTimePipe() {
    }
    MinutesToLocaleTimePipe.prototype.transform = function (value) {
        var hours = Math.floor(value / 60);
        var minutes = value % 60;
        var now = new Date();
        return new Date(now.setHours(hours, minutes))
            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };
    return MinutesToLocaleTimePipe;
}());
export { MinutesToLocaleTimePipe };
