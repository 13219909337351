import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { getTimezoneOffset } from 'src/app/shared/helpers/date/get-timezone-offset';
var DatePatchedPipe = (function () {
    function DatePatchedPipe(date) {
        this.date = date;
        this.getTimezoneOffset = getTimezoneOffset;
    }
    DatePatchedPipe.prototype.transform = function (value, format, timezone, locale) {
        if (!timezone) {
            timezone = this.getTimezoneOffset();
        }
        return this.date.transform(value, format, timezone, locale);
    };
    return DatePatchedPipe;
}());
export { DatePatchedPipe };
