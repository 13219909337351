var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl } from '@angular/forms';
import { MenuOptionService } from '../../../../services/menu-option/menu-option.service';
import { LoadingIndicatorService } from 'src/app/shared/packages/ui-loading-indicator';
import { ErrorDialogService } from 'src/app/shared/packages/ui-error-dialog';
import { ReactiveFormService } from 'src/app/shared/services/reactive-form/reactive-form.service';
import { SWAGGER_SCHEMA_DEFINITIONS } from 'src/gen/joeServerCore/schema';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
var MerchantMenuOptionEditComponent = (function () {
    function MerchantMenuOptionEditComponent(loadingIndicatorService, formBuilder, menuOptionService, dialogRef, errorDialogService, reactiveFormService, telemetryService, loadOption) {
        this.loadingIndicatorService = loadingIndicatorService;
        this.formBuilder = formBuilder;
        this.menuOptionService = menuOptionService;
        this.dialogRef = dialogRef;
        this.errorDialogService = errorDialogService;
        this.reactiveFormService = reactiveFormService;
        this.telemetryService = telemetryService;
        this.loadOption = loadOption;
        this.dragulaOptions = {
            direction: 'vertical',
            axis: 'y',
        };
    }
    MerchantMenuOptionEditComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, id, menuId, companyId, tags, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.loadOption, id = _a.id, menuId = _a.menuId, companyId = _a.companyId, tags = _a.tags;
                        this.tags = tags;
                        if (!id) return [3, 2];
                        _b = this;
                        return [4, this.menuOptionService.get(companyId, menuId, id).toPromise()];
                    case 1:
                        _b.option = _c.sent();
                        this.editMode = true;
                        return [3, 3];
                    case 2:
                        this.option = this.menuOptionService.new();
                        this.editMode = false;
                        _c.label = 3;
                    case 3:
                        this.initForm();
                        return [2];
                }
            });
        });
    };
    MerchantMenuOptionEditComponent.prototype.addQuantity = function () {
        var quantityNames = this.optionForm.get('quantityNames');
        quantityNames.push(new FormControl(''));
        this.clampDefaultQuantity();
        quantityNames.markAsDirty();
    };
    MerchantMenuOptionEditComponent.prototype.deleteQuantity = function (index) {
        var quantityNames = this.optionForm.get('quantityNames');
        quantityNames.removeAt(index);
        this.clampDefaultQuantity();
        quantityNames.markAsDirty();
    };
    MerchantMenuOptionEditComponent.prototype.onChoiceDrop = function (_a) {
        var sourceIndex = _a.sourceIndex, targetIndex = _a.targetIndex;
        var options = this.optionForm.get('choices');
        var item = options.controls[sourceIndex];
        options.removeAt(sourceIndex);
        options.insert(targetIndex, item);
        options.markAsDirty();
    };
    MerchantMenuOptionEditComponent.prototype.quantityTypeChange = function (_a) {
        var value = _a.value;
        var finiteQuantity = value !== 'named';
        var defaultQuantity = this.optionForm.get('defaultQuantity');
        this.optionForm.get('finiteQuantity').setValue(finiteQuantity);
        if (finiteQuantity) {
            var minimumQuantity = this.optionForm.get('minimumQuantity');
            var maximumQuantity = this.optionForm.get('maximumQuantity');
            var quantityIncrement = this.optionForm.get('quantityIncrement');
            var quantityUnit = this.optionForm.get('quantityUnit');
            this.namedQty = defaultQuantity.value;
            if (value === 'one') {
                this.finiteQty = 1;
                minimumQuantity.setValue(1);
                maximumQuantity.setValue(1);
                quantityIncrement.setValue(1);
                defaultQuantity.setValue(1);
                quantityUnit.setValue('');
                minimumQuantity.disable();
                maximumQuantity.disable();
                quantityIncrement.disable();
                defaultQuantity.disable();
                quantityUnit.disable();
            }
            else if (this.finiteQty !== undefined) {
                defaultQuantity.setValue(this.finiteQty);
                minimumQuantity.enable();
                maximumQuantity.enable();
                quantityIncrement.enable();
                defaultQuantity.enable();
                quantityUnit.enable();
            }
        }
        else {
            defaultQuantity.enable();
            this.finiteQty = defaultQuantity.value;
            this.clampDefaultQuantity(this.namedQty);
        }
        this.showFiniteQty = finiteQuantity;
        this.optionForm.markAsDirty();
    };
    MerchantMenuOptionEditComponent.prototype.addChoice = function (count) {
        if (count === void 0) { count = 1; }
        var choices = this.optionForm.get('choices');
        for (var i = 0; i < count; i++) {
            choices.push(this.formBuilder.group({ name: '', price: '0' }));
        }
        choices.markAsDirty();
    };
    MerchantMenuOptionEditComponent.prototype.deleteChoice = function (index) {
        var choices = this.optionForm.get('choices');
        choices.removeAt(index);
        choices.markAsDirty();
    };
    MerchantMenuOptionEditComponent.prototype.save = function () {
        var _this = this;
        this.loadingIndicatorService.show();
        var menuOptionData = this.optionForm.getRawValue();
        if (!menuOptionData.finiteQuantity) {
            menuOptionData.minimumQuantity = 0;
            menuOptionData.maximumQuantity = menuOptionData.quantityNames.length - 1;
        }
        var _a = this.loadOption, id = _a.id, menuId = _a.menuId, companyId = _a.companyId;
        (id === undefined ?
            this.menuOptionService.create(companyId, menuId, __assign({}, menuOptionData, { tags: this.tags })) :
            this.menuOptionService.update(companyId, menuId, id, __assign({}, menuOptionData, { tags: this.tags }))).subscribe(function (data) {
            var result = {
                option: data,
                new: id === undefined,
            };
            _this.loadingIndicatorService.dismiss();
            _this.dialogRef.close(result);
        }, function (error) {
            _this.loadingIndicatorService.dismiss();
            var details = error;
            try {
                details = JSON.stringify(error, null, 2);
            }
            catch (error) {
                var errorMessage = safeGet(error, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                _this.telemetryService.logError(errorMessage);
            }
            _this.errorDialogService.show({
                title: 'Failed to create menu option',
                message: 'Unable to create a menu option at this time. Please try again later. If this problem persists please let us know.',
                details: details,
            });
        });
    };
    MerchantMenuOptionEditComponent.prototype.initForm = function () {
        var _this = this;
        var option = this.option;
        var menuOptionData = {
            id: option.id,
            name: option.name,
            finiteQuantity: option.finiteQuantity,
            minimumQuantity: option.minimumQuantity,
            maximumQuantity: option.maximumQuantity,
            defaultQuantity: option.defaultQuantity,
            quantityUnit: option.quantityUnit || '',
            quantityIncrement: option.quantityIncrement,
            quantityNames: this.formBuilder.array(option.quantityNames ? option.quantityNames : []),
            choices: this.formBuilder.array(option.choices ? option.choices.map(function (choice) { return _this.formBuilder.group({
                id: choice.id,
                name: choice.name,
                price: choice.price,
            }); }) : []),
        };
        this.optionForm = this.reactiveFormService.createModelValidatedForm(SWAGGER_SCHEMA_DEFINITIONS.MenuOptionCreateDto, menuOptionData, [this.formValidator.bind(this)]);
        this.getError = this.reactiveFormService.createErrorAccessor(this.optionForm);
        this.showFiniteQty = option.finiteQuantity;
        if (option.finiteQuantity) {
            this.finiteQty = option.defaultQuantity;
            this.quantityTypeToggleValue =
                option.minimumQuantity === 1 && option.maximumQuantity === 1 && option.defaultQuantity === 1 ?
                    'one' : 'finite';
            if (this.quantityTypeToggleValue === 'one') {
                setTimeout(function () { return _this.quantityTypeChange({ value: 'one' }); }, 0);
            }
        }
        else {
            this.namedQty = option.defaultQuantity;
            this.quantityTypeToggleValue = 'named';
        }
    };
    MerchantMenuOptionEditComponent.prototype.formValidator = function (control) {
        var fieldErrorMap = {};
        return fieldErrorMap;
    };
    MerchantMenuOptionEditComponent.prototype.clampDefaultQuantity = function (value) {
        var quantityNames = this.optionForm.get('quantityNames');
        var defaultQuantity = this.optionForm.get('defaultQuantity');
        var defaultValue = value === undefined ? defaultQuantity.value : value;
        defaultQuantity.setValue(Math.min(defaultValue, Math.max(0, quantityNames.length - 1)));
    };
    return MerchantMenuOptionEditComponent;
}());
export { MerchantMenuOptionEditComponent };
