import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { InputEvent } from 'src/app/shared/types/input-event.type';

@Component({
  selector: 'user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.scss'],
})
export class UserVerificationComponent implements OnInit, AfterViewInit {
  @Output()
  done = new EventEmitter<string>();

  @Output()
  resendCode = new EventEmitter<void>();

  @Input()
  phoneNumber: string;

  @Input()
  tryCount: number;

  @Input()
  set code(code: string) {
    if (code && code.length === 6) {
      this.verificationInputKey({ data: code, preventDefault: () => { }, target: null, inputType: '' });
    }
  }

  @ViewChild('verificationInput')
  private inputField: ElementRef<HTMLInputElement>;

  digits: string[] = new Array(6).fill('');
  activeInput: number;

  phoneNumberNormalized: string;
  sendCodeProgress: number;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) { }

  cancel(): void {
    this.done.emit();
  }

  codeEntered(formValue: string): void {
    this.done.emit(formValue);
    this.changeDetectorRef.detectChanges();
  }

  onResendCode(): void {
    this.resendCode.emit();
    this.sendCodeProgress = 0;

    // fake progress indicator / throttle resend request
    let progressInterval = setInterval(() => {
      this.sendCodeProgress += 5;
      if (this.sendCodeProgress > 100) {
        clearInterval(progressInterval);
        this.sendCodeProgress = undefined;
        progressInterval = undefined;
      }
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  verificationInputKey(event: InputEvent): void {
    event.preventDefault();

    // which is deprecated, but code is not backward compatible, but ts lint hates which
    const key = event.data;

    if (event.inputType === 'deleteContentBackward') {
      this.removeDigit();
    } else {
      if (key.length > 1) {
        this.clearInput();

        key.split('').forEach(digitString => {
          const input = parseInt(digitString, 10);
          this.addDigit(input);
        });
      } else {
        const input = parseInt(key, 10);
        this.addDigit(input);
      }
      if (this.activeInput >= this.digits.length) {
        this.codeEntered(this.digits.join(''));
      }
    }
  }

  userClick(): void {
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.focus();
      this.setActiveInput();
    }
  }

  private setActiveInput(): void {
    for (let i = 0; i < this.digits.length; i++) {
      if (this.digits[i] === '') {
        this.activeInput = i;
        break;
      }
    }
  }

  private clearInput(): void {
    for (let i = 0; i < this.digits.length; i++) {
      this.digits[i] = '';
      this.activeInput = 0;
    }
  }

  private addDigit(value: number): void {
    if (!isNaN(value)) {
      for (let i = 0; i < this.digits.length; i++) {
        if (this.digits[i] === '') {
          this.digits[i] = value + '';
          this.activeInput = i + 1;
          break;
        }
      }
    }
  }

  private removeDigit(): void {
    for (let i = this.digits.length - 1; i >= 0; i--) {
      if (this.digits[i] !== '') {
        this.digits[i] = '';
        this.activeInput = i;
        break;
      }
    }
  }

  public ngOnInit(): void {
    if (this.phoneNumber) {
      // remove country code, add hyphens
      this.phoneNumberNormalized = this.phoneNumber.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.userClick());
  }
}
