var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { OrderService } from 'src/app/consumer/services/order/order.service';
import { StoreCartService } from 'src/app/consumer/services/store-cart/store-cart.service';
import { StringUtilService } from 'src/app/shared/services/string-util/string-util.service';
import { RecentOrderReorderComponent } from './recent-order-reorder/recent-order-reorder.component';
export var OrderStatusStanding;
(function (OrderStatusStanding) {
    OrderStatusStanding["POSITIVE"] = "positive";
    OrderStatusStanding["PENDING"] = "pending";
    OrderStatusStanding["NEGATIVE"] = "negative";
})(OrderStatusStanding || (OrderStatusStanding = {}));
var RecentOrderCardComponent = (function () {
    function RecentOrderCardComponent(router, orderService, storeCartService, stringUtilService, matDialog) {
        this.router = router;
        this.orderService = orderService;
        this.storeCartService = storeCartService;
        this.stringUtilService = stringUtilService;
        this.matDialog = matDialog;
    }
    RecentOrderCardComponent.prototype.ngOnInit = function () {
    };
    RecentOrderCardComponent.prototype.checkStatusStanding = function (orderStatus) {
        if (orderStatus === 'completed') {
            return OrderStatusStanding.POSITIVE;
        }
        else if (orderStatus === 'new' || orderStatus === 'accepted') {
            return OrderStatusStanding.PENDING;
        }
        else {
            return OrderStatusStanding.NEGATIVE;
        }
    };
    RecentOrderCardComponent.prototype.checkStatusOrReview = function (order) {
        this.router.navigateByUrl("/order/" + order.storeId + "/" + order.id);
    };
    RecentOrderCardComponent.prototype.reorder = function (order) {
        return __awaiter(this, void 0, void 0, function () {
            var cart, storeName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.orderService.getCartFromOrderViewConsumerDto(order)];
                    case 1:
                        cart = _a.sent();
                        if (cart && cart.length) {
                            this.storeCartService.addCart(cart, order.storeId);
                            storeName = this.stringUtilService.urlify(order.storeName);
                            this.router.navigateByUrl("/stores/" + storeName + "/" + order.storeId + "/details/(store:cart)");
                        }
                        else {
                            this.matDialog.open(RecentOrderReorderComponent, {
                                data: {
                                    order: order,
                                    cart: cart,
                                },
                            });
                        }
                        return [2];
                }
            });
        });
    };
    return RecentOrderCardComponent;
}());
export { RecentOrderCardComponent };
