import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumerSidebarComponent } from './components/consumer-sidebar/consumer-sidebar.component';
import { ConsumerTopbarComponent } from './components/consumer-topbar/consumer-topbar.component';
import { UiConsumerChromeComponent } from './components/ui-consumer-chrome/ui-consumer-chrome.component';
import { MatSidenavModule, MatListModule, MatIconModule, MatDividerModule, MatDialogModule, MatButtonModule, MatBottomSheetModule, MatSnackBarModule, MatProgressSpinnerModule, MatCardModule, MatBadgeModule, MatProgressBarModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EnvironmentService } from '../../../shared/services/environment/environment.service';
import { UiFlipFabModule } from 'src/app/shared/packages/ui-flip-fab';
import { TopbarService } from './services/topbar/topbar.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserRewardService } from 'src/app/shared/services/user-reward/user-reward.service';
import { UiBottomBarModule } from 'src/app/shared/packages/ui-bottom-bar';
import { DataTransformPipesModule } from 'src/app/shared/packages/data-transform-pipes';
import { SidebarRewardProgressComponent } from './components/consumer-sidebar/components/sidebar-reward-progress/sidebar-reward-progress.component';
import { SidebarLastOrderCardComponent } from './components/consumer-sidebar/components/sidebar-last-order-card/sidebar-last-order-card.component';
import { SidebarLinksComponent } from './components/consumer-sidebar/components/sidebar-links/sidebar-links.component';
import { SocialShareService } from 'src/app/shared/services/social-share/social-share.service';
import { StoreService } from '../../services/store/store.service';

const EXPORTED_COMPONENTS = [
  ConsumerSidebarComponent,
  SidebarRewardProgressComponent,
  SidebarLastOrderCardComponent,
  SidebarLinksComponent,
  UiConsumerChromeComponent,
];

export const MATERIAL_IMPORTS = [
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatDividerModule,
  MatDialogModule,
  MatButtonModule,
  MatBottomSheetModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatCardModule,
  MatBadgeModule,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UiFlipFabModule,
    FlexLayoutModule,
    UiBottomBarModule,
    DataTransformPipesModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    ConsumerTopbarComponent,
    ...EXPORTED_COMPONENTS,
  ],
  providers: [
    EnvironmentService,
    UserRewardService,
    SocialShareService,
    StoreService,
  ],
  exports: [
    ...EXPORTED_COMPONENTS,
    ...MATERIAL_IMPORTS,
  ],
  entryComponents: [],
})
export class UiConsumerChromeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UiConsumerChromeModule,
      providers: [TopbarService],
    };
  }
}
