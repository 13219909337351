import { Injectable } from '@angular/core';
import { safeGet } from '../../helpers/object/safe-get';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';

const WIDGET_TYPE_OPTIONS: WidgetTypeOptionMap = {
  USER_PROFILE: {
    cloudName: 'joe-coffee',
    uploadPreset: 'jjaewamq_joe_profile_upload',
    sources: ['local'], // , 'facebook', 'instagram'],
    multiple: false,
    maxFiles: 1,
    cropping: false,
    croppingAspectRatio: 1,
    croppingDefaultSelectionRatio: 0.8,
    resourceType: 'image',
    minImageWidth: 400,
    minImageHeight: 400,
    // debug: true,
  },
  MENU_ITEM: {
    cloudName: 'joe-coffee',
    uploadPreset: 'e2g59vxa_joe_menu_item',
    sources: ['local'],
    multiple: false,
    maxFiles: 1,
    cropping: true,
    croppingAspectRatio: 1,
    croppingDefaultSelectionRatio: 1,
    resourceType: 'image',
    minImageWidth: 1000,
    minImageHeight: 1000,
    showSkipCropButton: false,
    croppingValidateDimensions: true,
    showPoweredBy: false,
    // debug: true,
  },
  STORE_BANNER: {
    cloudName: 'joe-coffee',
    uploadPreset: 'twcawn1m_joe_store_banner',
    sources: ['local'],
    multiple: false,
    maxFiles: 1,
    cropping: true,
    croppingAspectRatio: 2.5,
    croppingCoordinatesMode: 'custom',
    croppingDefaultSelectionRatio: 2.5,
    resourceType: 'image',
    minImageWidth: 1536,
    minImageHeight: 615,
    showSkipCropButton: false,
    croppingValidateDimensions: true,
    showPoweredBy: false,
    // debug: true,
  },
  PICKUP_LOCATION: {
    cloudName: 'joe-coffee',
    uploadPreset: 'yafwrdzk_store_pickup_location',
    sources: ['local'],
    multiple: false,
    maxFiles: 1,
    cropping: true,
    croppingAspectRatio: 1,
    croppingDefaultSelectionRatio: 0.8,
    croppingCoordinatesMode: 'custom',
    resourceType: 'image',
    minImageWidth: 400,
    minImageHeight: 400,
    showSkipCropButton: true,
    croppingValidateDimensions: true,
    showPoweredBy: false,
    debug: true,
  },
};

export interface CloudinaryImage {
  id: string;
  url: string;
  height: number;
  width: number;
  format: string;
}

interface CloudinaryWidgetOptions {
  cloudName: string;
  uploadPreset: string;
  sources?: string[];
  multiple?: boolean;
  maxFiles?: number;
  cropping?: boolean;
  croppingAspectRatio?: number;
  croppingDefaultSelectionRatio?: number;
  resourceType?: string;
  minImageWidth?: number;
  minImageHeight?: number;
  showSkipCropButton?: boolean;
  croppingCoordinatesMode?: 'face' | 'custom';
  croppingValidateDimensions?: boolean;
  showPoweredBy?: boolean;
  debug?: boolean;
}

interface Cloudinary {
  createUploadWidget: (options: any, callback: (e: any, r: any) => void) => CloudinaryWidget;
}

interface CloudinaryWidget {
  open: () => void;
}

interface WidgetTypeOptionMap {
  USER_PROFILE: CloudinaryWidgetOptions;
  MENU_ITEM: CloudinaryWidgetOptions;
  STORE_BANNER: CloudinaryWidgetOptions;
  PICKUP_LOCATION: CloudinaryWidgetOptions;
}

declare const cloudinary: Cloudinary;

@Injectable()
export class CloudinaryUploadService {

  constructor(
    private telemetryService: TelemetryService,
  ) { }

  private widgets: { [widgetTypeId: string]: CloudinaryWidget } = {};
  private widgetCallbacks: { [widgetTypeId: string]: { success: Function, failure: Function } } = {};

  /**
   * Initializes the cloudinary upload widget. Must be called before open.
   */
  initCloudinaryWidget(widgetTypeId: keyof WidgetTypeOptionMap): void {
    if (!WIDGET_TYPE_OPTIONS[widgetTypeId]) {
      throw new Error('invalid cloudinary widget type');
    }
    if (this.widgets.hasOwnProperty(widgetTypeId)) {
      return;
    }

    this.widgets[widgetTypeId] = cloudinary.createUploadWidget(
      WIDGET_TYPE_OPTIONS[widgetTypeId],
      (error, result) => this.handleCloudinaryCallback(widgetTypeId, error, result),
    );
  }

  /**
   * Opens a pre-initialized cloudinary widget
   */
  open(widgetTypeId: keyof WidgetTypeOptionMap): Promise<CloudinaryImage> {
    if (!this.widgets.hasOwnProperty(widgetTypeId)) {
      throw new Error('cloudinary widget not initialized');
    }

    try {
      this.widgets[widgetTypeId].open();

      return this.registerWidgetCallback(widgetTypeId);
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
    }
  }

  private handleCloudinaryCallback(widgetId: keyof WidgetTypeOptionMap, error: any, result: any): void {
    if (!this.widgetCallbacks[widgetId]) {
      return;
    }
    const { success, failure } = this.widgetCallbacks[widgetId];

    if (error) {
      failure(error);
      this.clearWidgetCallback(widgetId);
      return;
    }

    if (result) {
      if (result.event === 'close') {
        success();
        this.clearWidgetCallback(widgetId);
      } else if (result.event === 'success' && result.info) {
        const info = result.info;
        success({
          id: info.public_id,
          url: info.secure_url,
          width: info.width,
          height: info.height,
          format: info.format,
        });
        this.clearWidgetCallback(widgetId);
      }
    }
  }

  private clearWidgetCallback(widgetId: keyof WidgetTypeOptionMap): void {
    this.widgetCallbacks[widgetId] = undefined;
  }

  private registerWidgetCallback(widgetId: keyof WidgetTypeOptionMap): Promise<CloudinaryImage> {
    return new Promise((resolve, reject) => {
      this.widgetCallbacks[widgetId] = { success: resolve, failure: reject };
    });
  }

}
