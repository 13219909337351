import * as i0 from "./loading-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-indicator.component";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingIndicatorComponent, data: {} });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
function View_LoadingIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading-progress"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "%\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingProgress; _ck(_v, 1, 0, currVal_0); }); }
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "flipping loading-image"], ["src", "public-web-assets/images/logo-new.png"]], [[2, "small", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingIndicatorComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.loadingProgress !== undefined); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.small; _ck(_v, 1, 0, currVal_0); }); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-indicator", [], null, null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("loading-indicator", i3.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, { small: "small" }, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };
