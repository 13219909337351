import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';

@Injectable()
export class MerchantServiceSocketService extends Socket {

  constructor(
    environmentService: EnvironmentService,
  ) {
    super({
      url: environmentService.getConfig().apiUrls.joeMerchantService,
      options: {
        autoConnect: false,
      },
    });
  }
}
