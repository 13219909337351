var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { Router, NavigationStart } from '@angular/router';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { MatSnackBar, MatBottomSheet } from '@angular/material';
import { UserConsumerUserInfoBottomsheetComponent } from 'src/app/shared/packages/user-consumer-login/components/user-consumer-user-info-bottomsheet/user-consumer-user-info-bottomsheet.component';
import { StoreCheckoutBottomsheetComponent } from 'src/app/consumer/pages/store/store-checkout/store-checkout-bottomsheet/store-checkout-bottomsheet.component';
import { UserService } from 'src/app/shared/services/user/user.service';
var UiConsumerChromeComponent = (function () {
    function UiConsumerChromeComponent(router, identityService, storageService, userService, snackBar, bottomSheet) {
        this.router = router;
        this.identityService = identityService;
        this.storageService = storageService;
        this.userService = userService;
        this.snackBar = snackBar;
        this.bottomSheet = bottomSheet;
        this.opened = false;
        this.showTopbar = false;
        this.showBottombar = true;
        this.showAlertOverlay = false;
    }
    UiConsumerChromeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.identityService.watchActiveUser().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (user) {
            _this.showTopbar = !!user;
        });
        this.router.events.pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.opened = false;
            }
        });
        this.showAlertOverlayIfNeeded();
    };
    UiConsumerChromeComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
        this.stopCurrentTimeInterval();
    };
    UiConsumerChromeComponent.prototype.startCurrentTimeInterval = function () {
        this.stopCurrentTimeInterval();
        this.currentTimeInterval = setInterval(this.updateCurrentTime.bind(this), 1000);
    };
    UiConsumerChromeComponent.prototype.stopCurrentTimeInterval = function () {
        if (this.currentTimeInterval) {
            clearInterval(this.currentTimeInterval);
        }
        this.currentTimeInterval = undefined;
    };
    UiConsumerChromeComponent.prototype.updateCurrentTime = function () {
        this.currentTime = Date.now();
    };
    UiConsumerChromeComponent.prototype.showAlertOverlayIfNeeded = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, alertHistory, reactivationDiscount, lastReactivateNag, joeBucksStatus;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.identityService.isLoaded()];
                    case 1:
                        _a.sent();
                        return [4, this.identityService.getActiveUser()];
                    case 2:
                        user = _a.sent();
                        if (!user) {
                            return [2];
                        }
                        return [4, this.storageService.get(StorageKeys.USER_ALERT_OVERLAY_HISTORY)];
                    case 3:
                        alertHistory = _a.sent();
                        if (!(!alertHistory || !alertHistory.lastReactivateNag || Date.now() > alertHistory.lastReactivateNag + 3600000)) return [3, 6];
                        return [4, this.userService.getReactivationPromoStatus().toPromise()];
                    case 4:
                        reactivationDiscount = _a.sent();
                        if (!(reactivationDiscount && reactivationDiscount.amount && reactivationDiscount.category === 'promo')) return [3, 6];
                        this.reactivationDiscount = reactivationDiscount;
                        this.reactivationExpiration = new Date(reactivationDiscount.expires).getTime();
                        this.showAlertOverlay = true;
                        this.alertOverlayType = 'reactivation';
                        this.startCurrentTimeInterval();
                        lastReactivateNag = Date.now();
                        return [4, this.storageService.set(StorageKeys.USER_ALERT_OVERLAY_HISTORY, __assign({}, alertHistory, { lastReactivateNag: lastReactivateNag }))];
                    case 5:
                        _a.sent();
                        return [2];
                    case 6:
                        if (alertHistory && alertHistory.shownUploadFunds) {
                            return [2];
                        }
                        return [4, this.userService.getJoeBucksBalanceStatus().toPromise()];
                    case 7:
                        joeBucksStatus = _a.sent();
                        this.showAlertOverlay = joeBucksStatus.virgin;
                        this.alertOverlayType = joeBucksStatus.virgin ? 'upload' : undefined;
                        return [4, this.storageService.set(StorageKeys.USER_ALERT_OVERLAY_HISTORY, __assign({}, alertHistory, { shownUploadFunds: true }))];
                    case 8:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    UiConsumerChromeComponent.prototype.dismissAlert = function () {
        this.showAlertOverlay = false;
        this.stopCurrentTimeInterval();
    };
    UiConsumerChromeComponent.prototype.showAddFunds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var activeUser, previousJoeBucksStatus, uploadResult, successMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showAlertOverlay = false;
                        return [4, this.identityService.isLoaded()];
                    case 1:
                        _a.sent();
                        activeUser = this.identityService.getActiveUser();
                        if (!!activeUser.hasEmail) return [3, 3];
                        this.snackBar.open('Please add an e-mail address and password before adding funds.', '', { duration: 5000 });
                        return [4, this.bottomSheet.open(UserConsumerUserInfoBottomsheetComponent, { data: { mode: 'email' }, panelClass: 'full-screen-sheet' }).afterDismissed().toPromise()];
                    case 2:
                        _a.sent();
                        activeUser = this.identityService.getActiveUser();
                        if (!activeUser.hasEmail) {
                            this.snackBar.open('Cannot add funds until you add an e-mail address and password.', '', { duration: 5000 });
                            return [2];
                        }
                        _a.label = 3;
                    case 3: return [4, this.userService.getJoeBucksBalanceStatus().toPromise()];
                    case 4:
                        previousJoeBucksStatus = _a.sent();
                        return [4, this.bottomSheet.open(StoreCheckoutBottomsheetComponent, { data: { joeBucksOnly: true }, panelClass: 'full-screen-sheet' }).afterDismissed().toPromise()];
                    case 5:
                        uploadResult = _a.sent();
                        if (uploadResult && uploadResult.joebucksBalance) {
                            successMessage = previousJoeBucksStatus && previousJoeBucksStatus.virgin ?
                                'Thanks for adding funds for the first time. We added a reward to your account!' :
                                'Funds added successfully.';
                            this.snackBar.open(successMessage, '', { duration: 5000, verticalPosition: 'top' });
                        }
                        return [2];
                }
            });
        });
    };
    UiConsumerChromeComponent.prototype.logout = function () {
        this.router.navigateByUrl('/');
        this.opened = false;
        this.identityService.logout();
    };
    return UiConsumerChromeComponent;
}());
export { UiConsumerChromeComponent };
