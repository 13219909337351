import * as i0 from "./error-404.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./error-404.component";
var styles_Error404Component = [i0.styles];
var RenderType_Error404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Error404Component, data: {} });
export { RenderType_Error404Component as RenderType_Error404Component };
export function View_Error404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "full-height"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "error-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error page not found"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, we couldn't find the page you were looking for. Please check the URL and try again."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [". . ."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [["class", "joke"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.joke; _ck(_v, 11, 0, currVal_2); }); }
export function View_Error404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-error-404", [], null, null, null, View_Error404Component_0, RenderType_Error404Component)), i1.ɵdid(1, 114688, null, 0, i4.Error404Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Error404ComponentNgFactory = i1.ɵccf("page-error-404", i4.Error404Component, View_Error404Component_Host_0, {}, {}, []);
export { Error404ComponentNgFactory as Error404ComponentNgFactory };
