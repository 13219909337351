var VALID_CHARS_REGEXP = /[^a-z0-9]/ig;
var StringUtilService = (function () {
    function StringUtilService() {
    }
    StringUtilService.prototype.urlify = function (str) {
        return str.replace(VALID_CHARS_REGEXP, '-').toLowerCase();
    };
    return StringUtilService;
}());
export { StringUtilService };
