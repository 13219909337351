<div class="login-form-wrap" [class.native-device-mode]="!isWeb" *ngIf="!iframeSignupMode; else signupFormTemplate">
  <div class="flip-container" [class.flipped]="!flipped" [class.done]="done">
    <div class="flipper">

      <div class="front">
        <div class="joe-loader">
          <mat-progress-spinner diameter="50" mode="indeterminate" class="joe-loader-spinner"></mat-progress-spinner>
        </div>
      </div>

      <div class="back">
        <div class="login-error" *ngIf="errorMessage">{{errorMessage}}</div>
        <div class="back-content">

          <!-- CHANGE / RESET PASSWORD -->
          <div *ngIf="changePasswordMode">
            <form class="change-password-form" *ngIf="changePasswordForm" [formGroup]="changePasswordForm"
              (submit)="changePassword()">
              <h2>Update Password</h2>
              <div class="change-password-spacer form-row" *ngIf="resetCode"></div>
              <mat-form-field class="full-width form-row" *ngIf="!resetCode">
                <input matInput placeholder="Old Password" type="password" formControlName="oldPassword">
                <mat-error *ngIf="changePasswordForm?.get('oldPassword')?.hasError('required')">
                  Old password is required
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width form-row">
                <input matInput placeholder="New Password" type="password" formControlName="password">
                <mat-error *ngIf="changePasswordForm?.get('password')?.hasError('required')">
                  Password is required
                </mat-error>
                <mat-error
                  *ngIf="!changePasswordForm?.get('password').hasError('required') && changePasswordForm?.get('password').hasError('minlength')">
                  Password must be at least 8 characters
                </mat-error>
                <mat-error
                  *ngIf="!changePasswordForm?.get('password').hasError('required') && !changePasswordForm?.get('password').hasError('minlength') && changePasswordForm.get('password').hasError('pw_insecure')">
                  {{ changePasswordForm.get('password').getError('pw_insecure') }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width form-row">
                <input matInput placeholder="New Password (again)" type="password"
                  formControlName="passwordConfirmation">
                <mat-error *ngIf="changePasswordForm?.get('passwordConfirmation')?.hasError('required')">
                  Password is required
                </mat-error>
                <mat-error
                  *ngIf="!changePasswordForm?.get('passwordConfirmation').hasError('required') && changePasswordForm?.get('passwordConfirmation').hasError('minlength')">
                  Password must be at least 8 characters
                </mat-error>
                <mat-error
                  *ngIf="!changePasswordForm?.get('passwordConfirmation').hasError('required') && !changePasswordForm?.get('passwordConfirmation').hasError('minlength') && signupForm.get('passwordConfirmation').hasError('matches')">
                  Passwords must match
                </mat-error>
              </mat-form-field>
              <div class="form-row centered">
                <button mat-flat-button color="primary" type="submit">Change Password</button>
              </div>
            </form>
          </div>

          <!-- LOGIN / REGISTER -->
          <mat-tab-group *ngIf="!changePasswordMode" [selectedIndex]="selectedTab">

            <!-- LOGIN TAB -->
            <mat-tab label="Login">
              <form class="e2e-login-form login-form" *ngIf="loginForm" [formGroup]="loginForm" (submit)="login()">
                <mat-form-field class="full-width form-row">

                  <input matInput placeholder="Email" formControlName="email" type="email">
                  <mat-error
                    *ngIf="loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="loginForm.get('email').hasError('required')">
                    Email is required
                  </mat-error>
                </mat-form-field>

                <!-- FORGOT PASSWORD PANE -->
                <div class="forgot-password centered" [class.show]="forgotPasswordMode">
                  <button mat-flat-button color="primary" type="button" (click)="resetPassword()"
                    [disabled]="!forgotPasswordMode">Reset
                    Password</button>

                  <div class="form-row centered">
                    <a class="forgot-password-link remember" (click)="toggleForgotPassword()">Remember your
                      password?</a>
                  </div>
                </div>

                <mat-form-field class="full-width form-row">

                  <input matInput placeholder="Password" type="password" formControlName="password">
                  <mat-error *ngIf="loginForm.get('password').hasError('required')">
                    Password is required
                  </mat-error>
                </mat-form-field>
                <div class="form-row centered">
                  <button mat-flat-button color="primary" type="submit" [disabled]="forgotPasswordMode">Login</button>
                </div>
                <div class="form-row centered">
                  <a class="forgot-password-link" (click)="toggleForgotPassword()">Forgot your password?</a>
                </div>
              </form>
            </mat-tab>

            <!-- SIGNUP TAB -->
            <mat-tab label="Signup" *ngIf="showSignup">
              <ng-container *ngTemplateOutlet="signupFormTemplate"></ng-container>
            </mat-tab>

          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SIGNUP FORM TEMPLATE -->
<ng-template #signupFormTemplate>
  <div [class.iframe-mode]="iframeSignupMode">
    <div *ngIf="iframeSignupMode && iframeSignupComplete" class="iframe-signup-complete">
      <h1>Signup Complete!</h1>
      <p>Proceed to <a href="{{pageBaseHref}}" target="_top">{{pageBaseHref}}</a> to finish setting up your
        shop.</p>
    </div>
    <form class="signup-form" *ngIf="signupForm && !(iframeSignupMode && iframeSignupComplete)" [formGroup]="signupForm"
      (submit)="signup()">

      <div class="full-width form-row" fxLayout="row" fxLayoutAlign="space-between">

        <mat-form-field fxFlex="46">
          <input matInput placeholder="First Name" formControlName="firstName">
          <mat-error *ngIf="signupForm.get('firstName').hasError('required')">
            First name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="46">
          <input matInput placeholder="Last Name" formControlName="lastName">
          <mat-error *ngIf="signupForm.get('lastName').hasError('required')">
            Last name is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <mat-form-field class="full-width form-row">
        <input matInput placeholder="Company" formControlName="companyName">

        <mat-error *ngIf="signupForm.get('companyName').invalid">
          Please enter a company name
        </mat-error>
      </mat-form-field> -->

      <mat-form-field class="full-width form-row">

        <input matInput placeholder="Email" formControlName="email">

        <mat-error *ngIf="signupForm.get('email').invalid">
          Please enter a valid email
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width form-row">

        <input matInput placeholder="Mobile Number" formControlName="phone" type="tel">
        <mat-hint>Confirmation code used for authentication</mat-hint>
        <mat-error *ngIf="signupForm.get('phone').invalid">
          Please enter a valid phone number
        </mat-error>
      </mat-form-field>

      <div class="full-width form-row" fxLayout="row" fxLayoutAlign="space-between">
        <mat-form-field fxFlex="46">
          <input matInput placeholder="Password" type="password" formControlName="password">
          <mat-error *ngIf="signupForm.get('password').hasError('required')">
            Password is required
          </mat-error>
          <mat-error
            *ngIf="!signupForm.get('password').hasError('required') && signupForm.get('password').hasError('minlength')">
            Password must be at least 8 characters
          </mat-error>
          <mat-error
            *ngIf="!signupForm?.get('password').hasError('required') && !signupForm?.get('password').hasError('minlength') && signupForm.get('password').hasError('pw_insecure')">
            {{ signupForm.get('password').getError('pw_insecure') }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="46">
          <input matInput placeholder="Password (again)" type="password" formControlName="passwordConfirmation">
          <mat-error *ngIf="signupForm.get('passwordConfirmation').hasError('required')">
            Password is required
          </mat-error>
          <mat-error
            *ngIf="!signupForm.get('passwordConfirmation').hasError('required') && signupForm.get('passwordConfirmation').hasError('minlength')">
            Password must be at least 8 characters
          </mat-error>
          <mat-error
            *ngIf="!signupForm.get('passwordConfirmation').hasError('required') && !signupForm.get('passwordConfirmation').hasError('minlength') && signupForm.get('passwordConfirmation').hasError('matches')">
            Passwords must match
          </mat-error>
        </mat-form-field>
      </div>
      <div class="iframe-login-error" *ngIf="iframeSignupMode && errorMessage">{{errorMessage}}</div>

      <div class="form-row centered">
        <button mat-flat-button color="primary">Signup</button>
      </div>
    </form>
  </div>
</ng-template>