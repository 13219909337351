import { PipeTransform } from '@angular/core';
var BooleanNiceResponse = (function () {
    function BooleanNiceResponse() {
    }
    BooleanNiceResponse.prototype.transform = function (bool) {
        if (bool === undefined || bool === null) {
            return '-';
        }
        if (bool) {
            return 'Yes';
        }
        return 'No';
    };
    return BooleanNiceResponse;
}());
export { BooleanNiceResponse };
