<mat-dialog-content>
  <div class="success-wrap" fxLayout="column" fxLayoutAlign="center" [class.big]="titleBig" [class.top]="titleTop">
    <div class="confetti" #success></div>
    <h1 class="dialog-title">ACCOUNT CREATED!!</h1>
    <mat-card class="success-card">
      <h3>Just one more step...</h3>
      <p>
        Congrats! We've verified the basics on your account.
      </p>
      <ng-container *ngIf="companyApproved; else selfServeMessage">
        <p>
          Now we just need to get a few details about your store and you'll be ready to launch.
        </p>
        <div fxLayout="row" fxLayoutAlign="space-between" class="success-buttons">
          <button mat-flat-button color="primary" mat-dialog-close [routerLink]="['/m/company', companyId, 'stores']">
            <mat-icon>local_cafe</mat-icon> Store Setup
          </button>
        </div>
      </ng-container>
      <ng-template #selfServeMessage>
        <p>
          Now we just need to get a few details on your menu and we'll have you ready to launch in about 24 hours.
        </p>
        <p>Want to talk to a human?<br>Email us at success@joe.coffee or call 206-531-3212</p>
        <div fxLayout="row" fxLayoutAlign="space-between" class="success-buttons">
          <a href="https://share.hsforms.com/1EW71Vjv2TheQ-GzWSEotmA2qf0f" target="_blank" mat-flat-button
            color="primary" (click)="goToDashboard()">
            <mat-icon>local_cafe</mat-icon> Complete Setup
          </a>
          <!-- <button mat-dialog-close [routerLink]="['/m/company', companyId]" [queryParams]="{tab: 'users'}" mat-flat-button
          color="primary">
          <mat-icon>person_add</mat-icon>Invite Users
        </button>
        <button mat-flat-button color="primary" mat-dialog-close [routerLink]="['/m/company', companyId]">
          <mat-icon>dashboard</mat-icon> Dashboard
        </button> -->
        </div>
      </ng-template>
    </mat-card>
  </div>
</mat-dialog-content>