import { PipeTransform } from '@angular/core';
var OrderStatusPipe = (function () {
    function OrderStatusPipe() {
    }
    OrderStatusPipe.prototype.transform = function (order) {
        if (!order) {
            return '';
        }
        switch (order.orderStatus) {
            case 'draft':
                return 'Pending';
            case 'new':
                return 'New';
            case 'accepted':
                return !order.scheduledPickup || order.scheduleAlertAcknowledged ? 'Started' : 'Accepted';
            case 'completed':
                switch (order.refundStatus) {
                    case 'partially_refunded':
                        return 'Completed - partially refunded';
                    case 'refunded':
                        return 'Completed - refunded';
                    default:
                        break;
                }
                return 'Completed';
            case 'rejected':
                return 'Rejected';
            case 'cancelled_store':
                return 'Cancelled by Store';
            case 'cancelled_customer':
                return 'Cancelled by User';
            default:
                return order.orderStatus;
        }
    };
    return OrderStatusPipe;
}());
export { OrderStatusPipe };
