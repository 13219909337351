import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { Router, NavigationStart } from '@angular/router';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { MatSnackBar, MatBottomSheet } from '@angular/material';
import { UserConsumerUserInfoBottomsheetComponent, UserInfoBottomsheetData } from 'src/app/shared/packages/user-consumer-login/components/user-consumer-user-info-bottomsheet/user-consumer-user-info-bottomsheet.component';
import { StoreCheckoutBottomsheetComponent, CheckoutData, CheckoutBottomSheetResult } from 'src/app/consumer/pages/store/store-checkout/store-checkout-bottomsheet/store-checkout-bottomsheet.component';
import { UserService } from 'src/app/shared/services/user/user.service';
import { OrderPricingDiscountDto } from 'src/gen/joeServerCore';

interface AlertOverlayHistory {
  shownUploadFunds: boolean;
  lastReactivateNag: number;
}

type AlertOverlayTypes = 'upload' | 'reactivation';

@Component({
  selector: 'ui-consumer-chrome',
  templateUrl: './ui-consumer-chrome.component.html',
  styleUrls: ['./ui-consumer-chrome.component.scss'],
})
export class UiConsumerChromeComponent implements OnInit, OnDestroy {

  opened = false;
  showTopbar = false;
  showBottombar = true;
  showAlertOverlay = false;
  alertOverlayType: AlertOverlayTypes;
  reactivationDiscount: OrderPricingDiscountDto;
  reactivationExpiration: number;
  currentTime: number;
  currentTimeInterval: NodeJS.Timer;

  private componentActive: boolean;

  constructor(
    private router: Router,
    private identityService: IdentityService,
    private storageService: StorageService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
  ) { }

  public ngOnInit(): void {

    this.componentActive = true;

    this.identityService.watchActiveUser().pipe(takeWhile(() => this.componentActive)).subscribe(user => {
      // never show topbar (to keep things simple for demo)
      this.showTopbar = !!user;
    });

    // auto close sidebar on navigation
    this.router.events.pipe(takeWhile(() => this.componentActive)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this.opened = false;
      }
    });

    this.showAlertOverlayIfNeeded();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.stopCurrentTimeInterval();
  }

  private startCurrentTimeInterval(): void {
    this.stopCurrentTimeInterval();
    this.currentTimeInterval = setInterval(this.updateCurrentTime.bind(this), 1000);
  }

  private stopCurrentTimeInterval(): void {
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
    this.currentTimeInterval = undefined;
  }

  private updateCurrentTime(): void {
    this.currentTime = Date.now();
  }

  private async showAlertOverlayIfNeeded(): Promise<void> {
    await this.identityService.isLoaded();
    const user = await this.identityService.getActiveUser();
    if (!user) {
      return;
    }

    const alertHistory = await this.storageService.get<AlertOverlayHistory>(StorageKeys.USER_ALERT_OVERLAY_HISTORY);

    // show no more often than once per hour (60 * 60 * 1000 = 3600000)
    if (!alertHistory || !alertHistory.lastReactivateNag || Date.now() > alertHistory.lastReactivateNag + 3600000) {
      const reactivationDiscount = await this.userService.getReactivationPromoStatus().toPromise();
      if (reactivationDiscount && reactivationDiscount.amount && reactivationDiscount.category === 'promo') {
        this.reactivationDiscount = reactivationDiscount;
        this.reactivationExpiration = new Date(reactivationDiscount.expires).getTime();
        this.showAlertOverlay = true;
        this.alertOverlayType = 'reactivation';
        this.startCurrentTimeInterval();

        const lastReactivateNag = Date.now();
        await this.storageService.set<AlertOverlayHistory>(StorageKeys.USER_ALERT_OVERLAY_HISTORY, { ...alertHistory, lastReactivateNag });
        return;
      }
    }

    if (alertHistory && alertHistory.shownUploadFunds) {
      return;
    }

    const joeBucksStatus = await this.userService.getJoeBucksBalanceStatus().toPromise();

    // show overlay if they've never uploaded funds
    this.showAlertOverlay = joeBucksStatus.virgin;
    this.alertOverlayType = joeBucksStatus.virgin ? 'upload' : undefined;

    await this.storageService.set<AlertOverlayHistory>(StorageKeys.USER_ALERT_OVERLAY_HISTORY, { ...alertHistory, shownUploadFunds: true });
  }

  dismissAlert(): void {
    this.showAlertOverlay = false;
    this.stopCurrentTimeInterval();
  }

  // TODO: refactor this / move it somewhere shared
  // There is code identical almost to this in the consumer-sidebar component
  async showAddFunds(): Promise<void> {
    this.showAlertOverlay = false;

    await this.identityService.isLoaded();
    let activeUser = this.identityService.getActiveUser();

    if (!activeUser.hasEmail) {
      this.snackBar.open('Please add an e-mail address and password before adding funds.', '', { duration: 5000 });
      await this.bottomSheet.open<UserConsumerUserInfoBottomsheetComponent, UserInfoBottomsheetData, void>(
        UserConsumerUserInfoBottomsheetComponent,
        { data: { mode: 'email' }, panelClass: 'full-screen-sheet' },
      ).afterDismissed().toPromise();

      activeUser = this.identityService.getActiveUser();
      if (!activeUser.hasEmail) {
        this.snackBar.open('Cannot add funds until you add an e-mail address and password.', '', { duration: 5000 });
        return;
      }
    }

    const previousJoeBucksStatus = await this.userService.getJoeBucksBalanceStatus().toPromise();

    const uploadResult = await this.bottomSheet.open<StoreCheckoutBottomsheetComponent, CheckoutData, CheckoutBottomSheetResult>(
      StoreCheckoutBottomsheetComponent,
      { data: { joeBucksOnly: true }, panelClass: 'full-screen-sheet' },
    ).afterDismissed().toPromise();

    if (uploadResult && uploadResult.joebucksBalance) {
      const successMessage = previousJoeBucksStatus && previousJoeBucksStatus.virgin ?
        'Thanks for adding funds for the first time. We added a reward to your account!' :
        'Funds added successfully.';
      this.snackBar.open(successMessage, '', { duration: 5000, verticalPosition: 'top' });
    }
  }

  logout(): void {
    this.router.navigateByUrl('/');
    this.opened = false;
    this.identityService.logout();
  }

}
