import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable()
export class NativeBackButtonService {

  private backUrl: string[];
  private backUrlExtras: NavigationExtras;
  private backExit: boolean;
  private backCallback: Function;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) {
    document.addEventListener('backbutton', this.handleBackKey.bind(this), false);
  }

  setBackUrl(url: string[], extras?: NavigationExtras): void {
    this.clearBackAction();

    this.backUrl = url;
    this.backUrlExtras = extras;
  }

  setBackExit(): void {
    this.clearBackAction();

    this.backExit = true;
  }

  setBackCallback(callback: Function): void {
    this.clearBackAction();

    this.backCallback = callback;
  }

  clearBackAction(): void {
    this.backUrl = undefined;
    this.backUrlExtras = undefined;
    this.backExit = false;
    this.backCallback = undefined;
  }

  private handleBackKey(event: Event): void {
    event.preventDefault();

    if (this.backExit) {
      const app = (<any>navigator).app;
      if (app && app.exitApp) {
        app.exitApp();
      }
    } else if (this.backUrl) {
      this.ngZone.run(() => {
        this.router.navigate(this.backUrl, this.backUrlExtras);
        this.backUrl = undefined;
      });
    } else if (this.backCallback) {
      this.ngZone.run(() => this.backCallback());
    }
  }
}
