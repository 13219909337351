var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Subject, of, BehaviorSubject, merge } from 'rxjs';
import { BatteryStatus } from '@ionic-native/battery-status';
import { ApiService } from 'src/gen/joeServerCore';
import { map, tap, catchError, switchMap, takeWhile } from 'rxjs/operators';
import { DeviceRegistrationService } from 'src/app/shared/services/device-registration/device-registration.service';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';
import { ApplicationError, ErrorNames } from 'src/app/shared/errors/application-error';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { fromPromise } from 'rxjs/internal-compatibility';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { NativeAudioVolumeService } from 'src/app/shared/packages/native-support/services/native-audio-volume/native-audio-volume.service';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { activeOrdersFilter, newOrdersFilter, futureOrdersFilter } from '../../utils/order-filters';
import { Timer } from '../../utils/Timer.class';
import { MerchantServiceSocketService } from '../merchant-service-socket/merchant-service-socket.service';
import { AudioAlertService } from '../../packages/ui-pos-chrome/services/audio-alert/audio-alert.service';
import * as jwtDecode from 'jwt-decode';
var UPDATED_FAILURE_THRESHOLD = 5;
var JWT_REFRESH_EXPIRATION_PADDING = 30;
var POLLING_MODE_INTERVAL = 10000;
var ACTIVE_ORDER_STATUSES = new Set(['draft', 'new', 'accepted']);
var API_ACTION_MAP = {
    completed: 'complete',
    accepted: 'approve',
    rejected: 'reject',
    cancelled_store: 'cancel',
};
export var PollingMode;
(function (PollingMode) {
    PollingMode["SOCKET"] = "SOCKET";
    PollingMode["POLLING"] = "POLLING";
})(PollingMode || (PollingMode = {}));
var PosDeviceService = (function () {
    function PosDeviceService(environmentService, apiService, storageService, batteryStatus, deviceRegistrationService, audioVolumeService, telemetryService, socket, audioAlertService) {
        var _this = this;
        this.environmentService = environmentService;
        this.apiService = apiService;
        this.storageService = storageService;
        this.batteryStatus = batteryStatus;
        this.deviceRegistrationService = deviceRegistrationService;
        this.audioVolumeService = audioVolumeService;
        this.telemetryService = telemetryService;
        this.socket = socket;
        this.audioAlertService = audioAlertService;
        this._activeOrders = new BehaviorSubject([]);
        this.activeOrders = this._activeOrders
            .pipe(map(activeOrdersFilter), map(this.sortOrderItems.bind(this)), map(this.populateOrderData.bind(this)));
        this.newOrders = this._activeOrders
            .pipe(map(newOrdersFilter), map(this.populateOrderData.bind(this)));
        this.futureOrders = this._activeOrders
            .pipe(map(futureOrdersFilter), map(this.populateOrderData.bind(this)));
        this.completedOrders = new BehaviorSubject([]);
        this.activeOrdersError = new BehaviorSubject(undefined);
        this.pairedDevice = new BehaviorSubject(undefined);
        this.storeStats = new BehaviorSubject({});
        this.activeOrderUpdateTimer = new Timer();
        this.checkinTimer = new Timer();
        this.orderTimer = new Timer();
        this.pollingModeTimer = new Timer();
        this.pollingMode = PollingMode.SOCKET;
        this.serviceActive = true;
        this.updateFailureCount = new BehaviorSubject(0);
        this.isDeviceUnplugged = new BehaviorSubject(false);
        this.deviceCheckinInProgress = false;
        this.activeOrdersInProgress = false;
        this.inactiveOrdersInProgress = false;
        this.orderStatsInProgress = false;
        this.activeOrdersEtag = '';
        this.inactiveOrdersEtag = '';
        this.orderStatsEtag = '';
        this.lastCheckinTime = new BehaviorSubject(undefined);
        this.jwtDecode = jwtDecode;
        this.socket.on('connect', function () { return _this.setupSocketEvents(); });
        environmentService.getMode().then(function (mode) {
            if (mode === 1) {
                _this.socket.connect();
            }
        });
        this.pollingPosConfig = environmentService.getConfig().pollingPosConfig;
        this.socketPosConfig = environmentService.getConfig().socketPosConfig;
        this.loadedPromise = new Promise(function (resolve) { return _this.loadedPromiseResolver = resolve; });
        this.activeOrderUpdateTimer = new Timer(function () { return _this._activeOrders.next(_this._activeOrders.value); }, 500).start();
        this.pairedDevice.subscribe(function () { return _this.setTelemetryDeviceTag(); });
        this.errorSubscription = merge(this.updateFailureCount.pipe(map(function (updateFailureCount) { return ({ updateFailureCount: updateFailureCount, isDeviceUnplugged: _this.isDeviceUnplugged.value }); })), this.isDeviceUnplugged.pipe(map(function (isDeviceUnplugged) { return ({ isDeviceUnplugged: isDeviceUnplugged, updateFailureCount: _this.updateFailureCount.value }); }))).subscribe(function (_a) {
            var updateFailureCount = _a.updateFailureCount, isDeviceUnplugged = _a.isDeviceUnplugged;
            var error;
            if (updateFailureCount > UPDATED_FAILURE_THRESHOLD) {
                error = new ApplicationError({
                    name: ErrorNames.DATA_CONNECTIVITY,
                    message: "Unable to contact joe server please check your internet connection (" + updateFailureCount + " tries).",
                    details: {
                        message: "Unsuccessfully tried to call server " + updateFailureCount + " times.",
                    },
                });
            }
            else if (isDeviceUnplugged) {
                error = new Error('Device is unplugged. Please reconnect to ensure you remain available to receive orders.');
            }
            _this.activeOrdersError.next(error);
        });
        this.setDeviceVersionMetaData();
        this.loadPairedDevice();
        this.watchBatteryStatus();
    }
    PosDeviceService.prototype.ngOnDestroy = function () {
        this.serviceActive = false;
        this.activeOrderUpdateTimer.stop();
        this.stopPolling();
        this.socket.removeAllListeners();
        this.socket.disconnect();
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
        if (this.pairedDevice) {
            this.pairedDevice.unsubscribe();
        }
        if (this.batteryStatusSubscription) {
            this.batteryStatusSubscription.unsubscribe();
        }
    };
    PosDeviceService.prototype.setTelemetryDeviceTag = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice;
            return __generator(this, function (_a) {
                pairedDevice = this.getPairedDevice();
                if (pairedDevice && (pairedDevice.storeName !== this.previousPairedStoreName || pairedDevice.storeId !== this.previousPairedStoreId)) {
                    this.telemetryService.setActiveDevice(pairedDevice.storeName, pairedDevice.storeId);
                    this.previousPairedStoreName = pairedDevice.storeName;
                    this.previousPairedStoreId = pairedDevice.storeId;
                }
                return [2];
            });
        });
    };
    PosDeviceService.prototype.isLoaded = function () {
        return this.loadedPromise;
    };
    PosDeviceService.prototype.setupSocketEvents = function () {
        var _this = this;
        this.socket.removeAllListeners();
        this.socket.on('connect', this.setupSocketEvents.bind(this));
        this.socket.on('device-check-in-ack', this.acknowledgeCheckin.bind(this));
        this.socket.on('order-created', this.getActiveOrders.bind(this));
        this.socket.on('order-approved', this.getActiveOrders.bind(this));
        this.socket.on('order-reviewed', this.getInactiveOrders.bind(this));
        this.socket.on('order-arrived', function () {
            _this.getInactiveOrders();
            _this.audioAlertService.playArrivedAlert();
        });
        this.socket.on('order-refunded', this.getInactiveOrders.bind(this));
        this.socket.on('order-rejected', function () {
            _this.getActiveOrders();
            _this.getInactiveOrders();
        });
        this.socket.on('order-canceled', function () {
            _this.getActiveOrders();
            _this.getInactiveOrders();
        });
        this.socket.on('order-completed', function () {
            _this.getActiveOrders();
            _this.getInactiveOrders();
            _this.getOrderStats();
        });
    };
    PosDeviceService.prototype.acknowledgeCheckin = function (response) {
        this.lastCheckinTime.next(Date.now());
        var pairedDevice = this.getPairedDevice();
        if (pairedDevice && pairedDevice.active !== response.active) {
            this.setPairedDevice(__assign({}, pairedDevice, { active: response.active }));
        }
        this.updateFailureCount.next(0);
        this.deviceCheckinInProgress = false;
    };
    PosDeviceService.prototype.watchBatteryStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.isLoaded()];
                    case 1:
                        _a.sent();
                        if (this.batteryStatus && this.batteryStatus.onChange) {
                            this.batteryStatusSubscription = this.batteryStatus.onChange()
                                .pipe(takeWhile(function () { return _this.serviceActive; }))
                                .subscribe(function (status) { return _this.isDeviceUnplugged.next(!status.isPlugged); });
                        }
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.getPairedDevices = function (companyId, storeId) {
        return this.apiService.deviceList({ companyId: companyId, storeId: storeId });
    };
    PosDeviceService.prototype.pairPosDeviceFromCode = function (_a) {
        var storeId = _a.storeId, companyId = _a.companyId, deviceId = _a.deviceId;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.apiService.devicePairFromId({
                            companyId: companyId,
                            DevicePairFromCodeRequestDto: { storeId: storeId, deviceId: deviceId },
                        }).toPromise()];
                    case 1:
                        result = (_b.sent()).result;
                        return [2, result];
                }
            });
        });
    };
    PosDeviceService.prototype.pair = function (_a) {
        var storeId = _a.storeId, companyId = _a.companyId, storeName = _a.storeName;
        return __awaiter(this, void 0, void 0, function () {
            var _b, deviceInfo, pairedDeviceData, pushToken, isWeb, mobilePlatform, _c, id, token, refreshToken, active;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, Promise.all([
                            this.deviceRegistrationService.getDeviceInfo(),
                            this.storageService.get(StorageKeys.MERCHANT_PAIRED_DEVICE_STORAGE_KEY),
                        ])];
                    case 1:
                        _b = _d.sent(), deviceInfo = _b[0], pairedDeviceData = _b[1];
                        pushToken = deviceInfo.pushToken || pairedDeviceData.pushToken;
                        if (deviceInfo.pushToken === undefined) {
                            deviceInfo.pushToken = '';
                        }
                        if (!(deviceInfo.deviceType === undefined)) return [3, 4];
                        return [4, this.environmentService.isWeb()];
                    case 2:
                        isWeb = _d.sent();
                        return [4, this.environmentService.getPlatform()];
                    case 3:
                        mobilePlatform = _d.sent();
                        deviceInfo.deviceType = isWeb ? 'web' : (mobilePlatform === 2 ? 'ios' : 'android');
                        _d.label = 4;
                    case 4: return [4, this.apiService.devicePairCreate({
                            companyId: companyId,
                            CreatePairDeviceRequestDto: {
                                id: pairedDeviceData.deviceId,
                                storeId: storeId,
                                deviceInfo: deviceInfo,
                            },
                        }).toPromise()];
                    case 5:
                        _c = _d.sent(), id = _c.id, token = _c.token, refreshToken = _c.refreshToken, active = _c.active;
                        return [4, this.setPairedDevice({
                                deviceId: id,
                                companyId: companyId,
                                storeId: storeId,
                                storeName: storeName,
                                pushToken: pushToken,
                                token: token,
                                refreshToken: refreshToken,
                                active: active,
                            })];
                    case 6:
                        _d.sent();
                        this.startPolling();
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.unpairPosDeviceById = function (_a) {
        var storeId = _a.storeId, companyId = _a.companyId, deviceId = _a.deviceId;
        return this.apiService.deviceUnpair({ deviceId: deviceId, companyId: companyId, storeId: storeId });
    };
    PosDeviceService.prototype.unpair = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, pairedDevice;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.disconnect()];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            pairedDevice = this.getPairedDevice();
                            this.socket.emit('leave-store', { deviceAuthorization: "Bearer " + pairedDevice.token });
                            this.setPairedDevice(null);
                        }
                        return [2, result];
                }
            });
        });
    };
    PosDeviceService.prototype.getPairedDevice = function () {
        return this.pairedDevice.value;
    };
    PosDeviceService.prototype.connect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pairedDevice = this.getPairedDevice();
                        if (!pairedDevice || !pairedDevice.companyId) {
                            return [2, false];
                        }
                        return [4, this.apiService.merchantDeviceSetActive({ companyId: pairedDevice.companyId, isActive: 'true' }).toPromise()];
                    case 1:
                        result = (_a.sent()).result;
                        if (result) {
                            this.setPairedDevice(__assign({}, this.getPairedDevice(), { active: true }));
                        }
                        this.startPolling();
                        return [2, result];
                }
            });
        });
    };
    PosDeviceService.prototype.disconnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice;
            return __generator(this, function (_a) {
                this.stopPolling();
                pairedDevice = this.getPairedDevice();
                if (!pairedDevice || !pairedDevice.companyId) {
                    return [2, false];
                }
                this.setPairedDevice(__assign({}, this.getPairedDevice(), { active: false }));
                return [2, true];
            });
        });
    };
    PosDeviceService.prototype.acknowledgeScheduled = function (_a) {
        var id = _a.id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.apiService.orderScheduleAlertAcknowledged(id).toPromise()];
                    case 1:
                        _b.sent();
                        return [4, this.getActiveOrders()];
                    case 2:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.refundOrder = function (orderId, itemIds) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.apiService.orderRefund({
                            orderId: orderId,
                            RefundOrderRequestDto: {
                                itemIds: itemIds,
                            },
                        }).toPromise()];
                    case 1:
                        _a.sent();
                        return [4, this.getInactiveOrders()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.setOrderStatus = function (_a, status, reason) {
        var orderId = _a.id, storeId = _a.storeId;
        return __awaiter(this, void 0, void 0, function () {
            var optimisticUpdateResult;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!API_ACTION_MAP.hasOwnProperty(status)) {
                            return [2];
                        }
                        return [4, this.apiService.orderAcknowledge({
                                orderId: orderId,
                                AcknowledgeOrderRequestDto: { action: API_ACTION_MAP[status], storeId: storeId, reason: reason },
                            }).toPromise()];
                    case 1:
                        _b.sent();
                        optimisticUpdateResult = this.updateOrderStatusLocal(orderId, status);
                        if (!(optimisticUpdateResult === false)) return [3, 3];
                        return [4, Promise.all([this.getActiveOrders(), this.getInactiveOrders()])];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.updateOrderStatusLocal = function (orderId, status) {
        var orders = this._activeOrders.getValue();
        var updateOrderIdx = orders.findIndex(function (o) { return o.id === orderId; });
        if (updateOrderIdx === -1) {
            return false;
        }
        orders[updateOrderIdx].orderStatus = status;
        if (!ACTIVE_ORDER_STATUSES.has(status)) {
            var completeOrders = this.completedOrders.getValue();
            var orderToMoveToComplete = orders[updateOrderIdx];
            completeOrders.unshift(orderToMoveToComplete);
            orders.splice(updateOrderIdx, 1);
            this.completedOrders.next(completeOrders);
        }
        this._activeOrders.next(orders);
        return true;
    };
    PosDeviceService.prototype.initAuthRefreshSubject = function () {
        PosDeviceService.authRefreshSubject = new Subject();
    };
    PosDeviceService.prototype.sendAndKillAuthRefreshSubject = function (value) {
        if (PosDeviceService.authRefreshSubject) {
            PosDeviceService.authRefreshSubject.next(value);
            PosDeviceService.authRefreshSubject.complete();
            PosDeviceService.authRefreshSubject.unsubscribe();
            PosDeviceService.authRefreshSubject = undefined;
        }
    };
    PosDeviceService.prototype.refreshActiveDeviceToken = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        return fromPromise(this.isLoaded())
            .pipe(switchMap(function () {
            if (PosDeviceService.authRefreshSubject) {
                return PosDeviceService.authRefreshSubject.asObservable();
            }
            _this.initAuthRefreshSubject();
            var activeDevice = _this.getPairedDevice();
            if (!activeDevice || !activeDevice.token || !activeDevice.refreshToken) {
                _this.sendAndKillAuthRefreshSubject(false);
                return of(false);
            }
            var exp = _this.jwtDecode(activeDevice.token).exp;
            var expired = Date.now() / 1000 >= exp - JWT_REFRESH_EXPIRATION_PADDING;
            if (expired || force) {
                return _this.apiService.merchantDeviceRefresh({
                    deviceId: activeDevice.deviceId, refreshToken: activeDevice.refreshToken, public: true,
                })
                    .pipe(tap(function (_a) {
                    var token = _a.token, refreshToken = _a.refreshToken;
                    activeDevice.lastRefresh = Date.now();
                    _this.setPairedDevice(__assign({}, activeDevice, { token: token, refreshToken: refreshToken }));
                    _this.sendAndKillAuthRefreshSubject(true);
                }), map(function () { return true; }), catchError(function (err) {
                    var errorMessage = safeGet(err, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                    _this.telemetryService.logError(errorMessage);
                    _this.sendAndKillAuthRefreshSubject(false);
                    return of(false);
                }));
            }
            _this.sendAndKillAuthRefreshSubject(false);
            return of(false);
        }));
    };
    PosDeviceService.prototype.checkinDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice, active, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pairedDevice = this.getPairedDevice();
                        if (!pairedDevice || this.deviceCheckinInProgress) {
                            return [2];
                        }
                        if (!(this.pollingMode === PollingMode.SOCKET)) return [3, 1];
                        this.deviceCheckinInProgress = true;
                        this.socket.emit('device-check-in', {
                            deviceAuthorization: "Bearer " + this.getPairedDevice().token,
                            companyId: pairedDevice.companyId,
                            clientTimestamp: Date.now().toString(),
                            buildNumber: safeGet(this.environmentVersions, function (e) { return e.build.number; }),
                            nativeVersion: safeGet(this.environmentVersions, function (e) { return e.deviceNumber; }),
                            nativeVersionCode: safeGet(this.environmentVersions, function (e) { return e.deviceCode; }),
                        });
                        this.updateFailureCount.next(this.updateFailureCount.value + 1);
                        return [3, 4];
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.deviceCheckinInProgress = true;
                        return [4, this.apiService.merchantDeviceCheckinOnly({
                                ifNoneMatch: '',
                                companyId: pairedDevice.companyId,
                                clientTimestamp: Date.now().toString(),
                                buildNumber: safeGet(this.environmentVersions, function (e) { return e.build.number; }),
                                nativeVersion: safeGet(this.environmentVersions, function (e) { return e.deviceNumber; }),
                                nativeVersionCode: safeGet(this.environmentVersions, function (e) { return e.deviceCode; }),
                            }).toPromise()];
                    case 2:
                        active = (_a.sent()).active;
                        this.setPairedDevice(__assign({}, pairedDevice, { active: active }));
                        this.lastCheckinTime.next(Date.now());
                        this.updateFailureCount.next(0);
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.updateFailureCount.next(this.updateFailureCount.value + 1);
                        return [3, 4];
                    case 4:
                        this.deviceCheckinInProgress = false;
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.getActiveOrders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice, activeOrdersResponse, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pairedDevice = this.getPairedDevice();
                        if (!pairedDevice || this.activeOrdersInProgress) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.activeOrdersInProgress = true;
                        return [4, this.apiService.merchantDeviceActiveOrdersResponse({
                                ifNoneMatch: this.activeOrdersEtag,
                                companyId: pairedDevice.companyId,
                            }).toPromise()];
                    case 2:
                        activeOrdersResponse = _a.sent();
                        this.activeOrdersEtag = activeOrdersResponse.headers.get('etag');
                        if (activeOrdersResponse.status === 200) {
                            this._activeOrders.next(activeOrdersResponse.body.orders);
                        }
                        this.updateFailureCount.next(0);
                        return [3, 4];
                    case 3:
                        e_2 = _a.sent();
                        this.updateFailureCount.next(this.updateFailureCount.value + 1);
                        return [3, 4];
                    case 4:
                        this.activeOrdersInProgress = false;
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.getInactiveOrders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice, inactiveOrdersResponse, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pairedDevice = this.getPairedDevice();
                        if (!pairedDevice || this.inactiveOrdersInProgress) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.inactiveOrdersInProgress = true;
                        return [4, this.apiService.merchantDeviceInactiveOrdersResponse({
                                ifNoneMatch: this.inactiveOrdersEtag,
                                companyId: pairedDevice.companyId,
                            }).toPromise()];
                    case 2:
                        inactiveOrdersResponse = _a.sent();
                        this.inactiveOrdersEtag = inactiveOrdersResponse.headers.get('etag');
                        if (inactiveOrdersResponse.status === 200) {
                            this.completedOrders.next(inactiveOrdersResponse.body.orders);
                        }
                        this.updateFailureCount.next(0);
                        return [3, 4];
                    case 3:
                        e_3 = _a.sent();
                        this.updateFailureCount.next(this.updateFailureCount.value + 1);
                        return [3, 4];
                    case 4:
                        this.inactiveOrdersInProgress = false;
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.getOrderStats = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice, orderStatsResponse, _a, orderCount, totalSales, totalTips, e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        pairedDevice = this.getPairedDevice();
                        if (!pairedDevice || this.orderStatsInProgress) {
                            return [2];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.orderStatsInProgress = true;
                        return [4, this.apiService.merchantDeviceOrderStatsResponse({
                                ifNoneMatch: this.orderStatsEtag,
                                companyId: pairedDevice.companyId,
                            }).toPromise()];
                    case 2:
                        orderStatsResponse = _b.sent();
                        this.orderStatsEtag = orderStatsResponse.headers.get('etag');
                        if (orderStatsResponse.status === 200) {
                            _a = orderStatsResponse.body, orderCount = _a.orderCount, totalSales = _a.totalSales, totalTips = _a.totalTips;
                            this.storeStats.next({ orderCount: orderCount, totalSales: totalSales, totalTips: totalTips });
                        }
                        this.updateFailureCount.next(0);
                        return [3, 4];
                    case 3:
                        e_4 = _b.sent();
                        this.updateFailureCount.next(this.updateFailureCount.value + 1);
                        return [3, 4];
                    case 4:
                        this.orderStatsInProgress = false;
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.startPolling = function () {
        var _this = this;
        var pairedDevice = this.getPairedDevice();
        var _a = this.socketPosConfig, checkinPollInterval = _a.checkinPollInterval, orderPollInterval = _a.orderPollInterval;
        this.stopPolling();
        if (!pairedDevice) {
            return;
        }
        this.socket.emit('join-store', { deviceAuthorization: "Bearer " + pairedDevice.token });
        this.checkinTimer = new Timer(function () { return _this.checkinDevice(); }, checkinPollInterval).start();
        this.orderTimer = new Timer(function () {
            _this.getActiveOrders();
            _this.getInactiveOrders();
            _this.getOrderStats();
        }, orderPollInterval).start();
        this.pollingModeTimer = new Timer(function () {
            _this.audioVolumeService.setMediaVolume(100);
            if (_this.pollingMode === PollingMode.SOCKET && _this.socket.ioSocket.disconnected) {
                _this.pollingMode = PollingMode.POLLING;
                _this.checkinTimer.reset(_this.pollingPosConfig.checkinPollInterval);
                _this.orderTimer.reset(_this.pollingPosConfig.orderPollInterval);
            }
            else if (_this.pollingMode === PollingMode.POLLING && _this.socket.ioSocket.connected) {
                _this.pollingMode = PollingMode.SOCKET;
                _this.checkinTimer.reset(_this.socketPosConfig.checkinPollInterval);
                _this.orderTimer.reset(_this.socketPosConfig.orderPollInterval);
            }
        }, POLLING_MODE_INTERVAL).start();
    };
    PosDeviceService.prototype.stopPolling = function () {
        this.checkinTimer.stop();
        this.orderTimer.stop();
        this.pollingModeTimer.stop();
    };
    PosDeviceService.prototype.setPairedDevice = function (pairedDevice) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pairedDevice.next(pairedDevice);
                        return [4, this.storageService.set(StorageKeys.MERCHANT_PAIRED_DEVICE_STORAGE_KEY, pairedDevice)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.loadPairedDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pairedDevice;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.storageService.get(StorageKeys.MERCHANT_PAIRED_DEVICE_STORAGE_KEY)];
                    case 1:
                        pairedDevice = _a.sent();
                        if (!safeGet(pairedDevice, function (d) { return d.storeId; })) return [3, 3];
                        return [4, this.setPairedDevice(pairedDevice)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (safeGet(pairedDevice, function (d) { return d.active; })) {
                            this.startPolling();
                        }
                        this.loadedPromiseResolver();
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.setDeviceVersionMetaData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.environmentService.getVersions()];
                    case 1:
                        _a.environmentVersions = _b.sent();
                        return [2];
                }
            });
        });
    };
    PosDeviceService.prototype.populateOrderData = function (orders) {
        return orders.map(function (order) {
            var isActive = ACTIVE_ORDER_STATUSES.has(order.orderStatus);
            return __assign({}, order, { isActive: isActive });
        });
    };
    PosDeviceService.prototype.sortOrderItems = function (orders) {
        return orders.map(function (order) {
            order.items.sort(function (a, b) {
                var aVal = a.refunded ? -1 : 1;
                var bVal = b.refunded ? -1 : 1;
                return bVal - aVal;
            });
            return order;
        });
    };
    return PosDeviceService;
}());
export { PosDeviceService };
