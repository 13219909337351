import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { MerchantBottombarService } from '../../services/merchant-bottombar/merchant-bottombar.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'merchant-bottombar',
  templateUrl: './merchant-bottombar.component.html',
  styleUrls: ['./merchant-bottombar.component.scss'],
})
export class MerchantBottombarComponent implements OnInit, OnDestroy {
  bottombar: TemplateRef<any>;
  componentActive: boolean;
  show: boolean;

  constructor(
    private bottombarService: MerchantBottombarService,
  ) { }

  public ngOnInit(): void {
    this.componentActive = true;
    this.bottombarService.watchTemplate().pipe(takeWhile(() => this.componentActive))
      .subscribe(templateRef => {
        this.bottombar = templateRef;
        this.show = this.bottombar === undefined ? false : true;
      });
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }
}
