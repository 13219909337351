import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { IdentityService } from '../../services/identity/identity.service';
import { EnvironmentService, EnvironmentModes } from '../../services/environment/environment.service';
import { PasswordQualityValidators } from './password-quality-validators';
import { matchesValidator } from '../../form-validators/matches.validator';
import { MatDialog } from '@angular/material';
import { UserVerificationDialogComponent } from '../../packages/user-verification/components/user-verification-dialog/user-verification-dialog.component';
import { takeWhile } from 'rxjs/operators';
import { ScrollServiceService } from '../../services/scroll-service/scroll-service.service';

const RESET_PASSWORD_BASE_URL = `${location.protocol}//${location.host}/reset-password`;

interface HttpErrorResponse {
  status: number;
  error: { message: string };
}

@Component({
  selector: 'page-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  flipped = false;
  done = false;
  errorMessage: string;

  loginForm: FormGroup;
  signupForm: FormGroup;
  changePasswordForm: FormGroup;
  showSignup: boolean;
  forgotPasswordMode: boolean;
  changePasswordMode: boolean;
  resetCode: string;

  isMerchant: boolean;
  isWeb: boolean;
  selectedTab: number;
  iframeSignupMode: boolean;
  iframeSignupComplete: boolean;
  pageBaseHref: string;

  private validateCode: string;
  private validatePhone: string;
  private validateTryCount: number;
  private returnUrl: string;
  private componentActive: boolean;

  constructor(
    private identityService: IdentityService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private environmentService: EnvironmentService,
    private dialog: MatDialog,
    private scrollService: ScrollServiceService,
  ) {
    this.pageBaseHref = `${location.protocol}//${location.host}`;
  }

  public async ngOnInit(): Promise<void> {
    this.componentActive = true;
    setTimeout(() => this.flipped = true, 1000);

    // TEMP DISABLED SIGN UP
    this.showSignup = true; // location.hash === '#letmeinplease';
    const { url, params, queryParams } = this.route.snapshot;
    const urlPath = url && url[0].path.toLowerCase();
    this.returnUrl = queryParams['returnUrl'];

    this.changePasswordMode = urlPath === 'reset-password' || urlPath === 'change-password';
    this.resetCode = params['resetCode'] || '';
    if (this.changePasswordMode && this.resetCode && this.resetCode.length < 20) {
      this.errorMessage = 'Invalid reset password code';
      this.changePasswordMode = false;
    }

    this.isWeb = await this.environmentService.isWeb();
    this.isMerchant = (await this.environmentService.getMode()) === EnvironmentModes.MERCHANT;

    this.initForms();

    if (urlPath === 'signup' && this.showSignup) {
      this.selectedTab = 1;
    }

    this.iframeSignupMode = urlPath === 'signup-iframe';

    // holy hack-ity-hack - DISABLE SCROLLING IN IFRAME
    if (this.iframeSignupMode) {
      document.body.style.overflow = 'hidden';
      this.scrollService.disable();
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  async signup(): Promise<void> {
    const phoneField = this.signupForm.get('phone');
    const phoneValue = phoneField.value as string;
    const phoneValueClean = phoneValue.replace(/[^0-9]/g, '');
    if (phoneValue !== phoneValueClean) {
      phoneField.setValue(phoneValueClean);
    }

    if (!this.signupForm.valid) {
      return;
    }

    this.validatePhone = phoneValueClean;
    this.validateTryCount = 0;

    this.errorMessage = undefined;
    this.flipped = false;

    const signupData = this.signupForm.getRawValue();
    if (this.validateCode) {
      signupData.validationCode = this.validateCode;
    }

    this.identityService.signup(signupData)
      .subscribe(userId => {

        if (this.iframeSignupMode) {
          this.identityService.setActiveUser(userId);
          this.iframeSignupComplete = true;
          window.open(this.pageBaseHref + '/m/company/create', '_top');
          return;
        }

        this.done = true;

        // wait for "done animation" to finish
        setTimeout(() => this.loginAndRedirect(userId), 1000);

      }, this.httpErrorHandler.bind(this));
  }

  async login(): Promise<void> {
    if (!this.loginForm.valid) {
      return;
    }

    this.errorMessage = undefined;
    this.flipped = false;
    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;

    this.identityService.login({ email, password })
      .subscribe(userId => {
        this.done = true;

        // wait for "done animation" to finish
        setTimeout(() => this.loginAndRedirect(userId), 1000);

      }, this.httpErrorHandler.bind(this));
  }

  changePassword(): void {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.flipped = false;

    const { oldPassword, password } = this.changePasswordForm.getRawValue();
    (this.resetCode ?
      this.identityService.changePasswordWithResetCode(this.resetCode, password) :
      this.identityService.changePassword(oldPassword, password)
    ).subscribe(({ result }) => {
      if (result === true) {
        this.router.navigateByUrl('/');
        this.errorMessage = '';
      }
      this.flipped = true;
    }, this.httpErrorHandler.bind(this));
  }

  resetPassword(): void {
    const email = this.loginForm.get('email');

    if (email.invalid) {
      email.markAsDirty();
      email.markAsTouched();
      return;
    }

    this.flipped = false;
    this.toggleForgotPassword();
    this.identityService.requestPasswordReset(email.value, RESET_PASSWORD_BASE_URL).subscribe(({ result }) => {
      if (result === true) {
        this.errorMessage = 'Check your e-mail. Click the link. Get back in.';
      } else {
        this.errorMessage = 'Unable to reset your password right now.';
      }
      this.flipped = true;
    }, this.httpErrorHandler.bind(this));
  }

  toggleForgotPassword(): void {
    this.forgotPasswordMode = !this.forgotPasswordMode;
    this.forgotPasswordMode ? this.loginForm.get('password').disable() : this.loginForm.get('password').enable();
  }

  private loginAndRedirect(userId: string): void {
    this.identityService.setActiveUser(userId);
    this.router.navigateByUrl(this.returnUrl || (this.isMerchant ? '/m' : '/stores'));
  }

  private sendValidationCode(): void {
    this.identityService.consumerSignupWithPhone(this.signupForm.getRawValue()).toPromise();
  }

  private showPhoneValidationDialog(): void {
    this.sendValidationCode();
    let dialogOpen = true;
    const dialogRef = this.dialog.open(UserVerificationDialogComponent, {
      width: '500px',
      height: '200px',
      data: { phoneNumber: this.validatePhone, tryCount: this.validateTryCount },
    });

    dialogRef.componentInstance.resendCode.pipe(takeWhile(() => dialogOpen)).subscribe(() => {
      this.sendValidationCode();
    });

    dialogRef.afterClosed().pipe(takeWhile(() => dialogOpen)).subscribe(code => {
      dialogOpen = false;
      if (code) {
        this.validateCode = code;
        this.signup();
      } else {
        // cancel
        this.flipped = true;
      }
    });
  }

  private initForms(): void {

    const trimControlValueIfNeeded = (val: string, control: FormControl) => {
      if (!val) {
        return;
      }
      const trimmedVal = val.trim();
      if (val !== trimmedVal) {
        control.patchValue(trimmedVal);
      }
    };

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    const loginEmailControl = this.loginForm.get('email') as FormControl;
    loginEmailControl.valueChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(val => trimControlValueIfNeeded(val, loginEmailControl));

    this.signupForm = this.formBuilder.group({
      lastName: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        ...PasswordQualityValidators.all,
      ]],
      passwordConfirmation: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          matchesValidator(() => this.signupForm && this.signupForm.get('password').value),
        ],
      ],
    });

    const signupEmailControl = this.signupForm.get('email') as FormControl;
    this.signupForm.get('email').valueChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(val => trimControlValueIfNeeded(val, signupEmailControl));

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', this.resetCode ? undefined : [Validators.required]],
      resetCode: this.resetCode,
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        ...PasswordQualityValidators.all,
      ]],
      passwordConfirmation: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          matchesValidator(() => this.changePasswordForm && this.changePasswordForm.get('password').value),
        ],
      ],
    });
  }

  private httpErrorHandler(result: HttpErrorResponse) {
    this.validateCode = undefined;
    this.validatePhone = undefined;

    switch (result.status) {
      case 0:
        this.errorMessage = 'Unable to reach Joe server. Please check your internet connection.';
        this.flipped = true;
        return;
      case 404:
        this.errorMessage = 'Incorrect validation code. Please try again.';
        this.flipped = true;
        return;
      case 409:
        this.errorMessage = '';
        this.showPhoneValidationDialog();
        return;
      default:
        // tslint:disable-next-line
        console.log('unknown login error', result);
        const error = result.error;
        this.errorMessage = error && error.message ? error.message : 'Unknown error.';
        this.flipped = true;
    }
  }
}
