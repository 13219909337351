import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ActiveCompanyService } from '../../services/active-company/active-company.service';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { takeWhile } from 'rxjs/operators';
import { EnvironmentService } from '../../../../../shared/services/environment/environment.service';

@Component({
  selector: 'merchant-sidebar',
  templateUrl: './merchant-sidebar.component.html',
  styleUrls: ['./merchant-sidebar.component.scss'],
})
export class MerchantSidebarComponent implements OnInit, OnDestroy {

  @Output()
  onToggleMenu = new EventEmitter();

  @Input()
  isDrawerOpen: boolean;

  activeCompanyId: string;
  componentActive: boolean;

  isUserAdmin: boolean;
  isUserBiRole: boolean;
  isProduction = false;

  constructor(
    private activeCompanyService: ActiveCompanyService,
    private environmentService: EnvironmentService,
    private identityService: IdentityService,
  ) {
    this.isProduction = this.environmentService.getEnvName() === 'Production';
  }

  public ngOnInit(): void {
    this.componentActive = true;
    // this.activeCompanyId = this.activeCompanyService.getActive().id;
    this.activeCompanyService.watch().pipe(takeWhile(() => this.componentActive)).subscribe(({ companies, active }) => {
      this.activeCompanyId = active && active.id;
    });

    this.setUserRoles();

    this.identityService.watchActiveUser().pipe(takeWhile(() => this.componentActive)).subscribe(user => {
      this.setUserRoles();
    });
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private setUserRoles(): void {
    this.isUserAdmin = this.identityService.isActiveUserAdmin();
    this.isUserBiRole = this.identityService.isActiveUserRole('BiAdmin');
  }

  toggleSidNav() {
    this.onToggleMenu.emit();
  }

}
