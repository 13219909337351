export enum StorageKeys {
  USER_DATA = 'USER_DATA',
  USER_REFRESH_LOCK = 'USER_REFRESH_LOCK_V2',
  USER_DEFAULT_PAYMENT_METHOD = 'USER_DEFAULT_PAYMENT_METHOD',
  USER_ALERT_OVERLAY_HISTORY = 'USER_ALERT_OVERLAY_HISTORY',
  PREVIOUS_STORE_STORAGE_KEY = 'PREVIOUS_STORE_STORAGE_KEY',
  STORE_SEARCH_PARAMS_CACHE = 'STORE_SEARCH_PARAMS_CACHE',
  POS_PRINTER_STORAGE_KEY = 'POS_PRINTER_STORAGE_KEY',
  POS_PRINTER_ACTIONS_STORAGE_KEY = 'POS_PRINTER_ACTIONS_STORAGE_KEY',
  MERCHANT_PAIRED_DEVICE_STORAGE_KEY = 'MERCHANT_PAIRED_DEVICE_STORAGE_KEY',
  MERCHANT_PLUS_NAG_TIMESTAMP = 'MERCHANT_PLUS_NAG_TIMESTAMP',
}
