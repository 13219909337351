var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Big } from 'big.js';
var CentCurrencyPipe = (function (_super) {
    __extends(CentCurrencyPipe, _super);
    function CentCurrencyPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CentCurrencyPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var dollarValue = isNaN(parseFloat(value)) ? 0 : Big(value).div(100).toFixed(2);
        return _super.prototype.transform.apply(this, [dollarValue].concat(args));
    };
    return CentCurrencyPipe;
}(CurrencyPipe));
export { CentCurrencyPipe };
