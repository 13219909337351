<div class="flip-container" (click)="toggle()" [class.flipped]="!flipped">
  <div class="flipper">

    <div class="front" [class.shadow]="shadowFront" [ngClass]="colorFrontClass">
      <ng-container [ngTemplateOutlet]="front"></ng-container>
    </div>

    <div class="back" [class.shadow]="shadowBack" [ngClass]="colorBackClass">
      <ng-container [ngTemplateOutlet]="back"></ng-container>
    </div>
  </div>
</div>