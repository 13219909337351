import { Subject } from 'rxjs';
export var TopbarShowStyle;
(function (TopbarShowStyle) {
    TopbarShowStyle[TopbarShowStyle["hidden"] = 0] = "hidden";
    TopbarShowStyle[TopbarShowStyle["show"] = 1] = "show";
    TopbarShowStyle[TopbarShowStyle["bounceIn"] = 2] = "bounceIn";
})(TopbarShowStyle || (TopbarShowStyle = {}));
var TopbarService = (function () {
    function TopbarService() {
        this.optionChangeSubject = new Subject();
        this.topbarOptions = {
            title: '',
            show: TopbarShowStyle.hidden,
        };
    }
    TopbarService.prototype.hide = function () {
        this.setOptions({ show: TopbarShowStyle.hidden });
    };
    TopbarService.prototype.show = function (style) {
        if (style === void 0) { style = TopbarShowStyle.show; }
        this.setOptions({ show: style });
    };
    TopbarService.prototype.setTitle = function (title) {
        this.topbarOptions.title = title;
        this.emitOptionChanges();
    };
    TopbarService.prototype.setOptions = function (options) {
        Object.assign(this.topbarOptions, options);
        this.emitOptionChanges();
    };
    TopbarService.prototype.getOptions = function () {
        return this.topbarOptions;
    };
    TopbarService.prototype.watchOptions = function () {
        return this.optionChangeSubject.asObservable();
    };
    TopbarService.prototype.emitOptionChanges = function () {
        this.optionChangeSubject.next(this.topbarOptions);
    };
    return TopbarService;
}());
export { TopbarService };
