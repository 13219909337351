import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserVerificationModule } from '../user-verification/user-verification.module';
import { UserConsumerLoginBottomsheetComponent } from './components/user-consumer-login-bottomsheet/user-consumer-login-bottomsheet.component';
import { MatButtonModule, MatIconModule, MatInputModule, MatProgressBarModule, MatSnackBarModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserConsumerUserInfoBottomsheetComponent } from './components/user-consumer-user-info-bottomsheet/user-consumer-user-info-bottomsheet.component';

const entryComponents = [UserConsumerLoginBottomsheetComponent, UserConsumerUserInfoBottomsheetComponent];

export const MATERIAL_IMPORTS = [
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatSnackBarModule,
];

@NgModule({
  imports: [
    CommonModule,
    UserVerificationModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    ...entryComponents,
  ],
  providers: [],
  exports: [],
  entryComponents,

})
export class UserConsumerLoginModule { }
