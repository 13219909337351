import { OnInit } from '@angular/core';
var ErrorDialogComponent = (function () {
    function ErrorDialogComponent(errorData) {
        this.errorData = errorData;
    }
    ErrorDialogComponent.prototype.ngOnInit = function () {
    };
    return ErrorDialogComponent;
}());
export { ErrorDialogComponent };
