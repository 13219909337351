var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import * as jwtDecode from 'jwt-decode';
import { StorageKeys } from '../../enums/storage-keys';
import { ApiService } from 'src/gen/joeServerCore';
import { of } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { StorageService } from '../../packages/storage/storage/storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';
var JWT_REFRESH_EXPIRATION_PADDING = 30;
var JWT_REFRESH_LOCK_EXPIRATION_MS = 1 * 60 * 1000;
var IdentityService = (function () {
    function IdentityService(apiService, storageService, telemetryService) {
        var _this = this;
        this.apiService = apiService;
        this.storageService = storageService;
        this.telemetryService = telemetryService;
        this.users = {};
        this.loadedPromise = new Promise(function (resolve) { return _this.loadedPromiseResolver = resolve; });
        this.jwtDecode = jwtDecode;
        this.activeUserSubject = new Subject();
        this.getUserData();
    }
    IdentityService.prototype.ngOnDestroy = function () {
        this.activeUserSubject.complete();
    };
    IdentityService.prototype.getUsers = function () {
        return this.users;
    };
    IdentityService.prototype.watchActiveUser = function () {
        return this.activeUserSubject.asObservable();
    };
    IdentityService.prototype.getActiveUser = function () {
        if (!this.users.hasOwnProperty(this.activeUserId)) {
            return;
        }
        return this.users[this.activeUserId];
    };
    IdentityService.prototype.isLoaded = function () {
        return this.loadedPromise;
    };
    IdentityService.prototype.initAuthRefreshSubject = function () {
        IdentityService.authRefreshSubject = new Subject();
    };
    IdentityService.prototype.sendAndKillAuthRefreshSubject = function (value) {
        if (IdentityService.authRefreshSubject) {
            IdentityService.authRefreshSubject.next(value);
            IdentityService.authRefreshSubject.complete();
            IdentityService.authRefreshSubject.unsubscribe();
            IdentityService.authRefreshSubject = undefined;
        }
    };
    IdentityService.prototype.refreshActiveUser = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        return fromPromise(this.isLoaded()).pipe(switchMap(function () { return _this.reloadActiveUserData(); }), switchMap(function () {
            if (IdentityService.authRefreshSubject) {
                return IdentityService.authRefreshSubject.asObservable();
            }
            _this.initAuthRefreshSubject();
            var activeUser = _this.getActiveUser();
            if (!activeUser || !activeUser.token || !activeUser.refreshToken) {
                _this.sendAndKillAuthRefreshSubject(false);
                return of(false);
            }
            var exp = _this.jwtDecode(activeUser.token).exp;
            var expired = Date.now() / 1000 >= exp - JWT_REFRESH_EXPIRATION_PADDING;
            if (expired || force) {
                return fromPromise(_this.isRefreshLocked()).pipe(switchMap(function (isLocked) {
                    if (isLocked && !force) {
                        _this.sendAndKillAuthRefreshSubject(false);
                        return of(false);
                    }
                    return fromPromise(_this.lockRefresh())
                        .pipe(switchMap(function () {
                        return _this.apiService.userAuthRefresh({ refreshToken: activeUser.refreshToken, public: true });
                    }), tap(function (user) {
                        user.lastRefresh = Date.now();
                        _this.updateActiveUser(user);
                        _this.unlockRefresh();
                        _this.sendAndKillAuthRefreshSubject(true);
                    }), map(function (user) { return true; }), catchError(function (err) {
                        if (err.status === 401) {
                            _this.logout();
                        }
                        _this.sendAndKillAuthRefreshSubject(false);
                        return of(false);
                    }));
                }));
            }
            _this.sendAndKillAuthRefreshSubject(false);
            return of(false);
        }));
    };
    IdentityService.prototype.setActiveUser = function (id) {
        if (!this.users.hasOwnProperty(id)) {
            return;
        }
        this.activeUserId = id;
        this.storeUserData();
        this.publishActiveUserChange();
    };
    IdentityService.prototype.login = function (_a) {
        var _this = this;
        var email = _a.email, password = _a.password, phone = _a.phone, deviceId = _a.deviceId;
        return this.apiService.authLogin({ email: email, password: password, phone: phone, deviceId: deviceId, public: true })
            .pipe(tap(function (user) { return _this.addUser(user); }))
            .pipe(map(function (user) { return user.id; }));
    };
    IdentityService.prototype.signup = function (userData) {
        var _this = this;
        return this.apiService.authSignup(__assign({}, userData, { phone: userData.phone && '1' + userData.phone, public: true }))
            .pipe(tap(function (user) { return _this.addUser(user); }))
            .pipe(map(function (user) { return user.id; }));
    };
    IdentityService.prototype.consumerSignupWithPhone = function (userData, storeId) {
        return this.apiService.consumerSignup(__assign({}, userData, { phone: '1' + userData.phone, storeId: storeId }))
            .pipe(map(function (user) { return user.id; }));
    };
    IdentityService.prototype.updateUserName = function (firstName, lastName) {
        var _this = this;
        return this.apiService.userUpdateName({ firstName: firstName, lastName: lastName })
            .pipe(tap(function (result) { return _this.updateActiveUser({ firstName: result.firstName }); }), map(function (result) { return ({ firstName: result.firstName, lastName: result.lastName }); }));
    };
    IdentityService.prototype.addEmailPassword = function (email, password) {
        var _this = this;
        return this.apiService.userAddEmailPassword(({ email: email, password: password }))
            .pipe(tap(function (_a) {
            var result = _a.result;
            return _this.updateActiveUser({ hasEmail: result });
        }), map(function (_a) {
            var result = _a.result;
            return result;
        }));
    };
    IdentityService.prototype.consumerLoginWithVerificationCode = function (verificationData) {
        var _this = this;
        return this.apiService.validateUser(verificationData)
            .pipe(tap(function (user) { _this.addUser(user, true); }), map(function (user) { return ({ id: user.id, firstName: user.firstName, hasEmail: user.hasEmail, rewards: user.rewards, photo: user.photo }); }));
    };
    IdentityService.prototype.logout = function (id) {
        if (id === undefined) {
            this.users = {};
            this.activeUserId = undefined;
            this.publishActiveUserChange();
            this.deleteUserData();
        }
        else {
            delete this.users[id];
            if (id === this.activeUserId) {
                this.activeUserId = undefined;
                this.publishActiveUserChange();
            }
            this.storeUserData();
        }
        return of(null);
    };
    IdentityService.prototype.changePassword = function (oldPassword, newPassword) {
        return this.apiService.authChangePassword({ oldPassword: oldPassword, newPassword: newPassword });
    };
    IdentityService.prototype.changePasswordWithResetCode = function (resetCode, newPassword) {
        return this.apiService.authChangePasswordWithCode({ resetCode: resetCode, newPassword: newPassword });
    };
    IdentityService.prototype.requestPasswordReset = function (email, resetBaseUrl) {
        return this.apiService.authResetPassword({ email: email, resetBaseUrl: resetBaseUrl, public: true });
    };
    IdentityService.prototype.isActiveUserAdmin = function () {
        return this.isActiveUserRole('Admin');
    };
    IdentityService.prototype.isActiveUserRole = function (role) {
        var user = this.getActiveUser();
        return user && user.globalRoles && user.globalRoles.indexOf(role) > -1;
    };
    IdentityService.prototype.setActiveUserPhoto = function (photo) {
        this.updateActiveUser({ photo: photo });
    };
    IdentityService.prototype.incrementNagCount = function () {
        var user = this.getActiveUser();
        if (!user) {
            return 1;
        }
        var nagCount = (user.nagCount || 0) + 1;
        this.updateActiveUser({ nagCount: nagCount });
        return nagCount;
    };
    IdentityService.prototype.setLastUploadFundsAmount = function (lastUploadFundsAmount) {
        var user = this.getActiveUser();
        if (!user) {
            return;
        }
        this.updateActiveUser({ lastUploadFundsAmount: lastUploadFundsAmount });
    };
    IdentityService.prototype.isRefreshLocked = function () {
        return this.storageService.get(StorageKeys.USER_REFRESH_LOCK)
            .then(function (_a) {
            var lockedAt = _a.lockedAt;
            return !!(lockedAt && lockedAt + JWT_REFRESH_LOCK_EXPIRATION_MS > Date.now());
        });
    };
    IdentityService.prototype.lockRefresh = function () {
        return this.storageService.set(StorageKeys.USER_REFRESH_LOCK, { lockedAt: Date.now() });
    };
    IdentityService.prototype.unlockRefresh = function () {
        return this.storageService.remove(StorageKeys.USER_REFRESH_LOCK);
    };
    IdentityService.prototype.addUser = function (user, clear) {
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.users = {};
        }
        var jwtData = this.jwtDecode(user.token);
        user.globalRoles = (jwtData && jwtData.globalRoles) || [];
        this.users[user.id] = user;
        this.storeUserData();
    };
    IdentityService.prototype.updateActiveUser = function (user) {
        var activeUser = this.users[this.activeUserId];
        this.users[this.activeUserId] = __assign({}, activeUser, user);
        if (user.token) {
            var jwtData = this.jwtDecode(user.token);
            user.globalRoles = (jwtData && jwtData.globalRoles) || [];
        }
        this.storeUserData();
        this.publishActiveUserChange();
    };
    IdentityService.prototype.publishActiveUserChange = function () {
        var activeUser = this.getActiveUser();
        this.activeUserSubject.next(activeUser);
        this.setTelemetryUserTag();
    };
    IdentityService.prototype.setTelemetryUserTag = function () {
        var activeUser = this.getActiveUser();
        if (activeUser && activeUser.id) {
            this.telemetryService.setActiveUser(activeUser.firstName, activeUser.id);
        }
    };
    IdentityService.prototype.reloadActiveUserData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, users;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.activeUserId) {
                            return [2];
                        }
                        return [4, this.storageService.get(StorageKeys.USER_DATA)];
                    case 1:
                        _a = (_b.sent()).users, users = _a === void 0 ? {} : _a;
                        this.users[this.activeUserId] = users[this.activeUserId];
                        return [2];
                }
            });
        });
    };
    IdentityService.prototype.getUserData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.storageService.get(StorageKeys.USER_DATA)];
                    case 1:
                        data = _a.sent();
                        this.activeUserId = data.activeUserId;
                        this.users = data.users || {};
                        this.publishActiveUserChange();
                        this.loadedPromiseResolver();
                        this.setTelemetryUserTag();
                        return [2];
                }
            });
        });
    };
    IdentityService.prototype.storeUserData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                data = {
                    activeUserId: this.activeUserId,
                    users: this.users,
                };
                return [2, this.storageService.set(StorageKeys.USER_DATA, data)];
            });
        });
    };
    IdentityService.prototype.deleteUserData = function () {
        return this.storageService.remove(StorageKeys.USER_DATA);
    };
    return IdentityService;
}());
export { IdentityService };
