import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToLocaleTimePipe',
})
export class MinutesToLocaleTimePipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const now = new Date();
    return new Date(now.setHours(hours, minutes))
      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }
}
