import { Injectable, Injector } from '@angular/core';
import { ApiServiceTokenProvider } from 'src/gen/joeServerCore';
import { IdentityService } from '../identity/identity.service';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';

@Injectable()
export class ApiTokenProviderServiceService implements ApiServiceTokenProvider {

  // lazy load identity service to avoid circular dependency error
  // ApiService -> ApiTokenProviderService -> IdentityService -> ApiService
  private get identityService(): IdentityService {
    return this.injector.get(IdentityService);
  }

  // lazy load PosDeviceService to avoid circular dependency error
  // ApiService -> ApiTokenProviderService -> PosDeviceService -> ApiService
  private get posDeviceService(): PosDeviceService {
    return this.injector.get(PosDeviceService);
  }

  constructor(
    private injector: Injector,
  ) { }

  getToken(name: string): Observable<string> {
    switch (name) {
      case 'authorization':
        return this.identityService.refreshActiveUser().pipe(
          map(() => {
            const activeUser = this.identityService.getActiveUser();
            return activeUser && activeUser.token ? 'Bearer ' + activeUser.token : '';
          }),
        );
      case 'device-authorization':
        return this.posDeviceService.refreshActiveDeviceToken()
          .pipe(
            map(() => {
              const pairedDevice = this.posDeviceService.getPairedDevice();
              return pairedDevice && pairedDevice.token ? 'Bearer ' + pairedDevice.token : '';
            }),
          );
      default:
        return of('');
    }
  }

}
