import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { confetti } from 'dom-confetti';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

export interface SuccessDialogData {
  companyId: string;
  approved: boolean;
}

@Component({
  selector: 'merchant-registration-success-dialog',
  templateUrl: './merchant-registration-success-dialog.component.html',
  styleUrls: ['./merchant-registration-success-dialog.component.scss'],
})
export class MerchantRegistrationSuccessDialogComponent implements OnInit {

  @ViewChild('success')
  successTarget: ElementRef;
  titleBig: boolean;
  titleTop: boolean;
  companyId: string;
  companyApproved: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) { companyId, approved }: SuccessDialogData,
    private router: Router,
    private dialogRef: MatDialogRef<any, any>,
  ) {
    this.companyId = companyId;
    this.companyApproved = !!approved;
  }

  public ngOnInit(): void {
    this.runAnimation();
  }

  alert(message: string): void {
    alert(message);
  }

  goToDashboard(): void {
    this.dialogRef.close();
    this.router.navigate(['/m/company', this.companyId, 'dashboard']);
  }

  private runAnimation() {
    // hold up.... wait a minute
    setTimeout(() => {
      // fire the confetti
      confetti(this.successTarget.nativeElement, { angle: 0, spread: 360, elementCount: 400 });
      confetti(this.successTarget.nativeElement, { angle: 60 });
      confetti(this.successTarget.nativeElement, { angle: 0 });
      confetti(this.successTarget.nativeElement, { angle: -60 });
      // enlarge the title
      this.titleBig = true;

      // wait a minute... hold up
      setTimeout(() => {
        // become a dialog
        this.titleTop = true;
      }, 500);
    }, 200);
  }

}
