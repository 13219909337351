import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceToMiles',
})
export class DistanceToMilesPipe implements PipeTransform {

  transform(value: number): string {
    const isFloat = value % 1 !== 0;
    let valueStr: string;

    if (isFloat || value !== 1) {
      if (isFloat) { valueStr = value.toString().slice(0, 4); }
      return `${valueStr || value} miles`;
    } else {
      return `${value} mile`;
    }
  }
}
