/* tslint:disable */
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable, of as __of } from 'rxjs';
import { map as __map, filter as __filter, switchMap as __switchMap } from 'rxjs/operators';

interface ApiServiceTokenProvider {
  getToken: (name: string) => __Observable<string>;
}

const ApiServiceTokenProviderInjectionToken = new InjectionToken<ApiServiceTokenProvider>('ApiServiceTokenProviderInjectionToken')

import { RootDto } from '../models/root-dto';
import { StoreSearchPaginatedResponseDto } from '../models/store-search-paginated-response-dto';
import { StoreSearchResponseDto } from '../models/store-search-response-dto';
import { StoreWithAvailabilityDto } from '../models/store-with-availability-dto';
import { ItemDealDto } from '../models/item-deal-dto';
import { ItemDealDeleteResponseDto } from '../models/item-deal-delete-response-dto';
import { StoreWithDetailsDto } from '../models/store-with-details-dto';
import { MenuSectionDto } from '../models/menu-section-dto';
import { MenuExportDto } from '../models/menu-export-dto';
import { StoreMinimalDto } from '../models/store-minimal-dto';
import { StoreDto } from '../models/store-dto';
import { StoreOfflineReportRequestDto } from '../models/store-offline-report-request-dto';
import { StoreDashboardDto } from '../models/store-dashboard-dto';
import { StoreAmenitiesDto } from '../models/store-amenities-dto';
import { GPlacesResponseDto } from '../models/gplaces-response-dto';
import { GPlacesQueryParamsDto } from '../models/gplaces-query-params-dto';
import { GPlacesStoreHoursResponseDto } from '../models/gplaces-store-hours-response-dto';
import { GPlacesPlaceIdDto } from '../models/gplaces-place-id-dto';
import { StoreHoursResponseDto } from '../models/store-hours-response-dto';
import { StoreHoursRequestDto } from '../models/store-hours-request-dto';
import { StorePhoneVerificationResponseDto } from '../models/store-phone-verification-response-dto';
import { StorePhoneVerificationStatusResponseDto } from '../models/store-phone-verification-status-response-dto';
import { StoreSetAlwaysOnlineResponseDto } from '../models/store-set-always-online-response-dto';
import { StoreSetAlwaysOnlineDto } from '../models/store-set-always-online-dto';
import { StoreSquareLocationIdDto } from '../models/store-square-location-id-dto';
import { StoreSetMinimumWaitTimeResponseDto } from '../models/store-set-minimum-wait-time-response-dto';
import { StoreSetMinimumWaitTimeDto } from '../models/store-set-minimum-wait-time-dto';
import { StoreUpdateDto } from '../models/store-update-dto';
import { OrderListViewDto } from '../models/order-list-view-dto';
import { StoreCreateInviteResponseDto } from '../models/store-create-invite-response-dto';
import { StoreCreateInviteRequestDto } from '../models/store-create-invite-request-dto';
import { StoreAddImageResponseDto } from '../models/store-add-image-response-dto';
import { StoreAddImageRequestDto } from '../models/store-add-image-request-dto';
import { StorePickupInstructionsLocationImageDto } from '../models/store-pickup-instructions-location-image-dto';
import { StoreReportSubscriberDto } from '../models/store-report-subscriber-dto';
import { StoreReportSubscriberDeleteResultDto } from '../models/store-report-subscriber-delete-result-dto';
import { StoreItemInventoryCountDto } from '../models/store-item-inventory-count-dto';
import { StoreItemInventoryStatusDto } from '../models/store-item-inventory-status-dto';
import { StoreItemInventoryResponseDto } from '../models/store-item-inventory-response-dto';
import { StoreItemInventoryRequestDto } from '../models/store-item-inventory-request-dto';
import { StoreDeleteItemInventoryResponseDto } from '../models/store-delete-item-inventory-response-dto';
import { StoreOptionInventoryCountDto } from '../models/store-option-inventory-count-dto';
import { StoreOptionInventoryStatusDto } from '../models/store-option-inventory-status-dto';
import { OrderViewConsumerPaginatedDto } from '../models/order-view-consumer-paginated-dto';
import { OrderViewDto } from '../models/order-view-dto';
import { OrderWithDetailDto } from '../models/order-with-detail-dto';
import { OrderDto } from '../models/order-dto';
import { OrderViewConsumerDto } from '../models/order-view-consumer-dto';
import { OrderCreateRequestDto } from '../models/order-create-request-dto';
import { OrderCustomerArrivedResponseDto } from '../models/order-customer-arrived-response-dto';
import { OrderReviewResponseDto } from '../models/order-review-response-dto';
import { OrderReviewDto } from '../models/order-review-dto';
import { OrderPricingDto } from '../models/order-pricing-dto';
import { RefundOrderRequestDto } from '../models/refund-order-request-dto';
import { OrderItemSetStatusResponseDto } from '../models/order-item-set-status-response-dto';
import { AcknowledgeOrderResponseDto } from '../models/acknowledge-order-response-dto';
import { AcknowledgeOrderRequestDto } from '../models/acknowledge-order-request-dto';
import { OrderSetWaitTimeResponseDto } from '../models/order-set-wait-time-response-dto';
import { OrderSetWaitTimeRequestDto } from '../models/order-set-wait-time-request-dto';
import { OrderItemSetStatusRequestDto } from '../models/order-item-set-status-request-dto';
import { JoeGiftCardCreateResponseDto } from '../models/joe-gift-card-create-response-dto';
import { JoeGiftCardCreateRequestDto } from '../models/joe-gift-card-create-request-dto';
import { JoebucksBalanceResponseDto } from '../models/joebucks-balance-response-dto';
import { UploadJoebucksRequestDto } from '../models/upload-joebucks-request-dto';
import { JoebucksLedgerHistoryPaginatedDto } from '../models/joebucks-ledger-history-paginated-dto';
import { AddBankAccountResponseDto } from '../models/add-bank-account-response-dto';
import { UserBankAccountResponseDto } from '../models/user-bank-account-response-dto';
import { UserBankAccountDto } from '../models/user-bank-account-dto';
import { PaymentMethodDto } from '../models/payment-method-dto';
import { CloverItemDto } from '../models/clover-item-dto';
import { CloverAuthorizationRequestDto } from '../models/clover-authorization-request-dto';
import { SquareLocationDto } from '../models/square-location-dto';
import { AuthResponseDto } from '../models/auth-response-dto';
import { AuthLoginRequestDto } from '../models/auth-login-request-dto';
import { AuthSignupRequestDto } from '../models/auth-signup-request-dto';
import { AuthRefreshRequestDto } from '../models/auth-refresh-request-dto';
import { ConsumerSignupResponseDto } from '../models/consumer-signup-response-dto';
import { ConsumerSignupRequestDto } from '../models/consumer-signup-request-dto';
import { ValidateUserRequestDto } from '../models/validate-user-request-dto';
import { AuthChangePasswordResponseDto } from '../models/auth-change-password-response-dto';
import { AuthChangePasswordRequestDto } from '../models/auth-change-password-request-dto';
import { AuthChangePasswordWithCodeRequestDto } from '../models/auth-change-password-with-code-request-dto';
import { AuthResetPasswordResponseDto } from '../models/auth-reset-password-response-dto';
import { AuthResetPasswordRequestDto } from '../models/auth-reset-password-request-dto';
import { AppCodeDto } from '../models/app-code-dto';
import { AppCodeCreateDto } from '../models/app-code-create-dto';
import { AppCodeAuthenticateRequestDto } from '../models/app-code-authenticate-request-dto';
import { AuthTokenObtainResponseDto } from '../models/auth-token-obtain-response-dto';
import { AuthTokenObtainRequestDto } from '../models/auth-token-obtain-request-dto';
import { AuthTokenRevokeResponseDto } from '../models/auth-token-revoke-response-dto';
import { AuthTokenAuthorizeDirectResponseDto } from '../models/auth-token-authorize-direct-response-dto';
import { AuthTokenAuthorizeDirectRequestDto } from '../models/auth-token-authorize-direct-request-dto';
import { AuthTokenAuthorizeDirectAuthenticateResponseDto } from '../models/auth-token-authorize-direct-authenticate-response-dto';
import { AuthTokenAuthorizeDirectAuthenticateRequestDto } from '../models/auth-token-authorize-direct-authenticate-request-dto';
import { UserProfileBasicDto } from '../models/user-profile-basic-dto';
import { UserProfileDto } from '../models/user-profile-dto';
import { UserUpdateNameResponseDto } from '../models/user-update-name-response-dto';
import { UserUpdateNameRequestDto } from '../models/user-update-name-request-dto';
import { UserAddEmailPasswordResponseDto } from '../models/user-add-email-password-response-dto';
import { UserAddEmailPasswordDto } from '../models/user-add-email-password-dto';
import { UserAddPhotoResponseDto } from '../models/user-add-photo-response-dto';
import { UserAddPhotoDto } from '../models/user-add-photo-dto';
import { UserHasAnyRolesResultDto } from '../models/user-has-any-roles-result-dto';
import { UserPaymentCardDto } from '../models/user-payment-card-dto';
import { UserAddPaymentCardDto } from '../models/user-add-payment-card-dto';
import { UserGetPaymentCardsResponseDto } from '../models/user-get-payment-cards-response-dto';
import { UserPaymentCardsActionResultDto } from '../models/user-payment-cards-action-result-dto';
import { UserReferralLinkDto } from '../models/user-referral-link-dto';
import { UserReferralStatusDto } from '../models/user-referral-status-dto';
import { UserMinimalDto } from '../models/user-minimal-dto';
import { UserReferralCodeDto } from '../models/user-referral-code-dto';
import { UserFavoriteStoreDto } from '../models/user-favorite-store-dto';
import { UserMerchantInviteResponseDto } from '../models/user-merchant-invite-response-dto';
import { OrderPricingDiscountDto } from '../models/order-pricing-discount-dto';
import { UserRewardStatusDto } from '../models/user-reward-status-dto';
import { UserAcknowledgeMerchantInviteResponseDto } from '../models/user-acknowledge-merchant-invite-response-dto';
import { UserAcknowledgeMerchantInviteRequestDto } from '../models/user-acknowledge-merchant-invite-request-dto';
import { PosDevicePairResponseDto } from '../models/pos-device-pair-response-dto';
import { PosDevicePairWithUserLoginRequestDto } from '../models/pos-device-pair-with-user-login-request-dto';
import { PosDeviceGetAllowedStoresResponseDto } from '../models/pos-device-get-allowed-stores-response-dto';
import { PosDeviceGetAllowedStoresRequest } from '../models/pos-device-get-allowed-stores-request';
import { PosDevicePairRequestDto } from '../models/pos-device-pair-request-dto';
import { PosDeviceSetModeResponseDto } from '../models/pos-device-set-mode-response-dto';
import { PosDeviceSetModeRequestDto } from '../models/pos-device-set-mode-request-dto';
import { MerchantDeviceDto } from '../models/merchant-device-dto';
import { PosDeviceGetCardReaderTokenResponseDto } from '../models/pos-device-get-card-reader-token-response-dto';
import { PosDeviceGetCardReaderTokenRequestDto } from '../models/pos-device-get-card-reader-token-request-dto';
import { PosDeviceCardReaderRegisterResponseDto } from '../models/pos-device-card-reader-register-response-dto';
import { PosDeviceCardReaderRegisterRequestDto } from '../models/pos-device-card-reader-register-request-dto';
import { PosDeviceCreatePaymentIntentResponseDto } from '../models/pos-device-create-payment-intent-response-dto';
import { PosDeviceCreatePaymentIntentRequestDto } from '../models/pos-device-create-payment-intent-request-dto';
import { PosSessionOrderDto } from '../models/pos-session-order-dto';
import { PosSessionOrderPutDto } from '../models/pos-session-order-put-dto';
import { PosDeviceSessionSetValueResponseDto } from '../models/pos-device-session-set-value-response-dto';
import { PosDeviceSessionSetCurrentOrderTipAmountRequestDto } from '../models/pos-device-session-set-current-order-tip-amount-request-dto';
import { PosDeviceSessionCustomerDto } from '../models/pos-device-session-customer-dto';
import { PosDeviceSessionSetCustomerFromPhoneRequestDto } from '../models/pos-device-session-set-customer-from-phone-request-dto';
import { PosDeviceSessionSetCustomerResponseDto } from '../models/pos-device-session-set-customer-response-dto';
import { PosSessionOrderCompleteRequestDto } from '../models/pos-session-order-complete-request-dto';
import { PosDeviceGenericResultResponseDto } from '../models/pos-device-generic-result-response-dto';
import { PosDeviceCashTillOpenRequestDto } from '../models/pos-device-cash-till-open-request-dto';
import { PosDeviceCashTillCloseRequestDto } from '../models/pos-device-cash-till-close-request-dto';
import { PosDeviceCashTillTransactionSummaryResponseDto } from '../models/pos-device-cash-till-transaction-summary-response-dto';
import { PosDeviceTillTransactionSummaryResponseDto } from '../models/pos-device-till-transaction-summary-response-dto';
import { ReportCreateResponseDto } from '../models/report-create-response-dto';
import { ReportRequestDto } from '../models/report-request-dto';
import { DailySummaryDeviceRowDto } from '../models/daily-summary-device-row-dto';
import { InternalReportRequestDto } from '../models/internal-report-request-dto';
import { MerchantReportV2Dto } from '../models/merchant-report-v2dto';
import { MerchantDashboardReportRequestDto } from '../models/merchant-dashboard-report-request-dto';
import { AdminBiActiveUsersDto } from '../models/admin-bi-active-users-dto';
import { AdminOrderRefundDto } from '../models/admin-order-refund-dto';
import { AdminUserSearchResultEntryDto } from '../models/admin-user-search-result-entry-dto';
import { AdminUserEmailResponseDto } from '../models/admin-user-email-response-dto';
import { AdminUserEmailRequestDto } from '../models/admin-user-email-request-dto';
import { AdminGiftCardSearchResultEntryDto } from '../models/admin-gift-card-search-result-entry-dto';
import { AdminOrderSearchResultEntryDto } from '../models/admin-order-search-result-entry-dto';
import { AdminBiActiveStoresDto } from '../models/admin-bi-active-stores-dto';
import { AdminBiGetJoeBucksActivityDto } from '../models/admin-bi-get-joe-bucks-activity-dto';
import { AdminGetUserReferralsDto } from '../models/admin-get-user-referrals-dto';
import { AdminSetReferrerResponseDto } from '../models/admin-set-referrer-response-dto';
import { AdminBiOrderTotalsDto } from '../models/admin-bi-order-totals-dto';
import { AdminBiUserWeeklyCohortTotalDto } from '../models/admin-bi-user-weekly-cohort-total-dto';
import { AdminGetStoresWithStatusDto } from '../models/admin-get-stores-with-status-dto';
import { AdminSendMerchantTransferResponseDto } from '../models/admin-send-merchant-transfer-response-dto';
import { AdminMarketingPushDto } from '../models/admin-marketing-push-dto';
import { AdminMerchantTransferDto } from '../models/admin-merchant-transfer-dto';
import { MerchantTransferDto } from '../models/merchant-transfer-dto';
import { AdminUserBalanceDto } from '../models/admin-user-balance-dto';
import { AdminNetworkStatusTerritoryDto } from '../models/admin-network-status-territory-dto';
import { AdminCreateNetworkStatusTerritoryDto } from '../models/admin-create-network-status-territory-dto';
import { AdminNetworkStatusTerritoryDeleteResponseDto } from '../models/admin-network-status-territory-delete-response-dto';
import { AdminNetworkStatusTerritoryDetailsDto } from '../models/admin-network-status-territory-details-dto';
import { AdminSetStoreFeeOverrideResponseDto } from '../models/admin-set-store-fee-override-response-dto';
import { AdminSetPosPaymentEnabledDto } from '../models/admin-set-pos-payment-enabled-dto';
import { AdminSetStoreFeeOverrideDto } from '../models/admin-set-store-fee-override-dto';
import { AdminSetCompanyApprovalResponseDto } from '../models/admin-set-company-approval-response-dto';
import { AdminSetCompanyApprovalDto } from '../models/admin-set-company-approval-dto';
import { StoreOfflineReportResponseDto } from '../models/store-offline-report-response-dto';
import { AdminStoreOfflineReportRequestDto } from '../models/admin-store-offline-report-request-dto';
import { AdminMergeUsersResponseDto } from '../models/admin-merge-users-response-dto';
import { AdminMergeUsersRequestDto } from '../models/admin-merge-users-request-dto';
import { AdminResetUserPasswordResponseDto } from '../models/admin-reset-user-password-response-dto';
import { AdminResetUserPasswordDto } from '../models/admin-reset-user-password-dto';
import { PromoDto } from '../models/promo-dto';
import { AdminExportResponseDto } from '../models/admin-export-response-dto';
import { AdminStoreStatusExportRequestDto } from '../models/admin-store-status-export-request-dto';
import { AdminPromoStatusExportRequestDto } from '../models/admin-promo-status-export-request-dto';
import { AdminStoreCustomerFeedbackExportRequestDto } from '../models/admin-store-customer-feedback-export-request-dto';
import { AdminAccountingReportExportRequestDto } from '../models/admin-accounting-report-export-request-dto';
import { AdminBiCurrentStatusV2Dto } from '../models/admin-bi-current-status-v2dto';
import { StoreSearchStoreDto } from '../models/store-search-store-dto';
import { AdminBiCurrentStatusDto } from '../models/admin-bi-current-status-dto';
import { CompanyDashboardDto } from '../models/company-dashboard-dto';
import { CompanyDto } from '../models/company-dto';
import { CompanyLandingDataDto } from '../models/company-landing-data-dto';
import { CompanyAddImageResponseDto } from '../models/company-add-image-response-dto';
import { CompanyUpdateDto } from '../models/company-update-dto';
import { CompanyViewDto } from '../models/company-view-dto';
import { CompanyVerificationDto } from '../models/company-verification-dto';
import { CompanyVerificationResponseDto } from '../models/company-verification-response-dto';
import { MerchantTransferDownloadResultDto } from '../models/merchant-transfer-download-result-dto';
import { UserMinimalWithRolesDto } from '../models/user-minimal-with-roles-dto';
import { MenuMinimalDto } from '../models/menu-minimal-dto';
import { CompanyCreateInviteResponseDto } from '../models/company-create-invite-response-dto';
import { CompanyCreateInviteRequestDto } from '../models/company-create-invite-request-dto';
import { CompanyDeleteUserRoleResponseDto } from '../models/company-delete-user-role-response-dto';
import { CompanyCancelInviteResponseDto } from '../models/company-cancel-invite-response-dto';
import { CompanyReportSubscriberDto } from '../models/company-report-subscriber-dto';
import { CompanyReportSubscriberDeleteResultDto } from '../models/company-report-subscriber-delete-result-dto';
import { MenuOptionWithChoicesDto } from '../models/menu-option-with-choices-dto';
import { MenuOptionCreateDto } from '../models/menu-option-create-dto';
import { MenuOptionDeleteResponseDto } from '../models/menu-option-delete-response-dto';
import { MenuIterationDto } from '../models/menu-iteration-dto';
import { MenuDeleteResponseDto } from '../models/menu-delete-response-dto';
import { MenuExportEncodedDto } from '../models/menu-export-encoded-dto';
import { MenuImportResponseDto } from '../models/menu-import-response-dto';
import { CompanyBankAccountDto } from '../models/company-bank-account-dto';
import { CompanyBankAccountAddDto } from '../models/company-bank-account-add-dto';
import { CompanyBankAccountActionResultDto } from '../models/company-bank-account-action-result-dto';
import { DevicePairFromCodeResponseDto } from '../models/device-pair-from-code-response-dto';
import { DevicePairFromCodeRequestDto } from '../models/device-pair-from-code-request-dto';
import { DeviceUnpairResponseDto } from '../models/device-unpair-response-dto';
import { CreatePairDeviceResponseDto } from '../models/create-pair-device-response-dto';
import { CreatePairDeviceRequestDto } from '../models/create-pair-device-request-dto';
import { DeviceSetActiveResponseDto } from '../models/device-set-active-response-dto';
import { MerchantDeviceCheckinResponseDto } from '../models/merchant-device-checkin-response-dto';
import { CheckinResponseDto } from '../models/checkin-response-dto';
import { CheckinRequestDto } from '../models/checkin-request-dto';
import { OrderStatsResponseDto } from '../models/order-stats-response-dto';
import { OrderViewWithWaitTimeResponseDto } from '../models/order-view-with-wait-time-response-dto';
import { OrderViewResponseDto } from '../models/order-view-response-dto';
import { CustomerListPaginatedDto } from '../models/customer-list-paginated-dto';
import { CustomerWithDetailsDto } from '../models/customer-with-details-dto';
import { CustomerRecentOrderSummaryDto } from '../models/customer-recent-order-summary-dto';
import { LandingPageDto } from '../models/landing-page-dto';
import { LandingPageActionResultDto } from '../models/landing-page-action-result-dto';
import { PromoCreateDto } from '../models/promo-create-dto';
import { PromoUpdateDto } from '../models/promo-update-dto';
import { ConsumerLaunchResponseDto } from '../models/consumer-launch-response-dto';
import { ConsumerLaunchRequestDto } from '../models/consumer-launch-request-dto';
import { ConsumerLaunchV2ResponseDto } from '../models/consumer-launch-v2response-dto';
import { ConsumerLaunchV2RequestDto } from '../models/consumer-launch-v2request-dto';
import { UserFeedbackResponseDto } from '../models/user-feedback-response-dto';
import { UserFeedbackDto } from '../models/user-feedback-dto';
import { ApiAppCredentialListResponseDto } from '../models/api-app-credential-list-response-dto';
import { ApiAppCredentialCreateDto } from '../models/api-app-credential-create-dto';
import { ApiAppCredentialDeleteResponseDto } from '../models/api-app-credential-delete-response-dto';
import { ApiAppCredentialCreateResponseDto } from '../models/api-app-credential-create-response-dto';
import { ConsumerActivityCreateResultDto } from '../models/consumer-activity-create-result-dto';
import { ConsumerActivityDto } from '../models/consumer-activity-dto';
@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  static readonly statusGetPath = '/';
  static readonly storesGetNearbyPaginatedPath = '/store/nearby/paginated/{lat}/{lon}';
  static readonly storesGetNearbyPath = '/store/nearby/{lat}/{lon}';
  static readonly storeIsAvailableForOrdersPath = '/store/{storeId}/available-for-order';
  static readonly storeGetDealsPath = '/store/{storeId}/deal';
  static readonly storePutDealPath = '/store/{storeId}/deal';
  static readonly storeDeleteDealPath = '/store/{storeId}/deal/{dealId}';
  static readonly storeGetDetailsPath = '/store/{storeId}/details';
  static readonly storeGetMenuCategorySectionsWithInventoryPath = '/store/{storeId}/menu-category-sections-with-inventory/{categoryId}';
  static readonly storeGetMenuV2Path = '/store/{storeId}/menu/v2';
  static readonly storeGetMenuV3Path = '/store/{storeId}/menu/v3';
  static readonly storeGetMenuPath = '/store/{storeId}/menu';
  static readonly storeGetSeoListPath = '/store/list-for-blog-seo';
  static readonly storeListPath = '/store';
  static readonly storeCreatePath = '/store';
  static readonly storeGetOfflineReportPath = '/store/{storeId}/offline-report';
  static readonly storeDashboardPath = '/store/{storeId}/dashboard';
  static readonly storePutAmenitiesPath = '/store/{storeId}/amenities';
  static readonly storeFindDetailsPath = '/store/find';
  static readonly storeFindHoursByPlaceIdPath = '/store/find/hours';
  static readonly updateStoreHoursPath = '/store/{storeId}/set-hours';
  static readonly storeValidatePhonePath = '/store/{storeId}/validate-phone';
  static readonly storeValidatePhoneCheckStatusPath = '/store/{storeId}/validate-phone';
  static readonly storeSetAlwaysOnlinePath = '/store/{storeId}/set-always-online';
  static readonly storeSetSquareLocationIdPath = '/store/{storeId}/set-location-id';
  static readonly storeSetMinimumWaitTimePath = '/store/set-minimum-wait-time';
  static readonly storeClearSquareLocationIdPath = '/store/{storeId}/clear-location-id';
  static readonly storeDisconnectSquareIntegrationPath = '/store/{storeId}/disconnect-square-integration';
  static readonly storeValidatePhoneTwilioInboundWebHookPath = '/store/validate-phone-twilio-hook';
  static readonly storeUpdateV2Path = '/store/{storeId}/v2';
  static readonly storeGetV2Path = '/store/{storeId}/v2';
  static readonly storeGetPath = '/store/{storeId}';
  static readonly storeUpdatePath = '/store/{storeId}';
  static readonly getOrdersByStorePath = '/store/{storeId}/orders';
  static readonly storeInviteUserPath = '/store/{storeId}/invite';
  static readonly storeAddImageFromIdPath = '/store/{storeId}/add-image-from-id';
  static readonly storeAddPickupLocationImageFromIdPath = '/store/{storeId}/add-pickup-location-image-from-id';
  static readonly storeAddImagePath = '/store/{storeId}/add-image';
  static readonly storeReportSubscriberListPath = '/store/{storeId}/report-subscriber';
  static readonly storeReportSubscriberCreatePath = '/store/{storeId}/report-subscriber';
  static readonly storeReportSubscriberDeletePath = '/store/{storeId}/report-subscriber/{subscriberId}';
  static readonly storeItemInventoryGetOutOfStockCountPath = '/store-item-inventory';
  static readonly storeSetItemInventoryPath = '/store-item-inventory';
  static readonly storeAddItemInventoryPath = '/store-item-inventory';
  static readonly storeDeleteItemInventoryPath = '/store-item-inventory/{itemInventoryId}';
  static readonly storeOptionInventoryGetOutOfStockCountPath = '/store-option-inventory';
  static readonly storeSetOptionInventoryPath = '/store-option-inventory';
  static readonly orderListPaginatedPath = '/order/paginated';
  static readonly orderGetPath = '/order/{orderId}';
  static readonly orderGetDetailedPath = '/order/detailed/{orderId}';
  static readonly orderGetReorderPath = '/order/reorder/{orderId}';
  static readonly orderListPath = '/order';
  static readonly orderCreatePath = '/order';
  static readonly orderCreateV2Path = '/order/v2';
  static readonly orderCreateV3Path = '/order/v3';
  static readonly orderCustomerArrivedPath = '/order/{orderId}/customer-arrived';
  static readonly orderReviewPath = '/order/{orderId}/review';
  static readonly orderPricingComputePath = '/order/pricing';
  static readonly orderPricingComputeWithSourceTypePath = '/order/pricing-with-source-type';
  static readonly orderCustomerPickupOrderPath = '/order/{orderId}/customer-pickup-order';
  static readonly orderRefundPath = '/order/{orderId}/refund';
  static readonly orderArchivePath = '/order/{orderId}/archive';
  static readonly orderAcknowledgePath = '/order/{orderId}/acknowledge';
  static readonly orderSetWaitTimePath = '/order/{orderId}/wait-time';
  static readonly orderScheduleAlertAcknowledgedPath = '/order/{orderId}/acknowledge-schedule-alert';
  static readonly orderGetDetailedPosPath = '/order/detailed/{orderId}/for-pos';
  static readonly orderCreateForPosPath = '/order/for-pos';
  static readonly orderItemSetStatusPath = '/order/{orderId}/item/{orderItemId}/status';
  static readonly orderUserPendingOrderClearPath = '/order/pending-order-at-store/{storeId}';
  static readonly createJoeGiftCardPath = '/payment/joe-gift-card';
  static readonly joebucksUploadFundsPath = '/payment/joebucks/upload-funds';
  static readonly joebucksCheckBalancePath = '/payment/joebucks/balance';
  static readonly joebucksUploadsHistoryPath = '/payment/joebucks/history';
  static readonly authorizeBankAccountPath = '/payment/bank-accounts/authorize';
  static readonly storeUserBankAccountPath = '/payment/bank-accounts/user-bank-account';
  static readonly deleteBankAccountPath = '/payment/bank-accounts/delete';
  static readonly cloverInventoryListPath = '/clover-inventory/store/{storeId}/inventory';
  static readonly cloverInventoryAssociationPath = '/clover-inventory/store/{storeId}/inventory/{cloverItemId}/associate';
  static readonly cloverInventoryWebhookPath = '/clover-inventory/webhook';
  static readonly cloverAuthAuthorizationPath = '/clover-auth/store/{storeId}/authorization';
  static readonly cloverAuthRevokePath = '/clover-auth/store/{storeId}/revoke';
  static readonly squareAuthorizationRedirectEndpointPath = '/square/authorization';
  static readonly squareGetAllLocationsPath = '/square/{storeId}/get-square-locations';
  static readonly authLoginPath = '/auth/login';
  static readonly authSignupPath = '/auth/signup';
  static readonly userAuthRefreshPath = '/auth/refresh';
  static readonly merchantDeviceRefreshPath = '/auth/refresh-merchant';
  static readonly consumerSignupPath = '/auth/consumerSignup';
  static readonly validateUserPath = '/auth/validateUser';
  static readonly authChangePasswordPath = '/auth/change-password';
  static readonly authChangePasswordWithCodePath = '/auth/change-password-with-code';
  static readonly authResetPasswordPath = '/auth/reset-password';
  static readonly createAppCodePath = '/auth/app-code';
  static readonly redeemAppCodePath = '/auth/app-code/{code}/redeem';
  static readonly checkAppCodeRedeemedPath = '/auth/app-code/{code}/status';
  static readonly appCodeAuthenticatePath = '/auth/app-code/authenticate';
  static readonly oauthAuthObtainTokenPath = '/auth/oauth/token';
  static readonly oauthAuthRevokeTokenPath = '/auth/oauth/token';
  static readonly oauthAuthAuthorizeDirectPath = '/auth/oauth/authorize/direct';
  static readonly oauthAuthAuthorizeDirectAuthenticatePath = '/auth/oauth/authorize/direct/{authenticationRequestToken}/authenticate';
  static readonly userGetProfileBasicPath = '/user/profile-basic';
  static readonly userGetProfilePath = '/user/profile';
  static readonly userUpdateNamePath = '/user/name';
  static readonly userAddEmailPasswordPath = '/user/add-email-password';
  static readonly userSetPhotoPath = '/user/add-photo';
  static readonly userHasMerchantRolesPath = '/user/has-merchant-roles';
  static readonly userAddCardPath = '/user/cards';
  static readonly userGetCardsPath = '/user/cards';
  static readonly userRemoveOneCardPath = '/user/cards/{cardId}';
  static readonly userGetReferralShortUrlPath = '/user/referral-link';
  static readonly userSetReferralShortUrlPath = '/user/referral-link';
  static readonly userGetReferralStatusesPath = '/user/referral-status';
  static readonly userGetByReferralCodePath = '/user/referred-user/{code}';
  static readonly userGetReferralCodePath = '/user/referral-code';
  static readonly userGetFavoriteStoresPath = '/user/favorites/stores';
  static readonly userGetInvitesPath = '/user/invites';
  static readonly userGetReactivationPromoStatusPath = '/user/reactivation-status';
  static readonly userGetRewardStatusPath = '/user/rewards';
  static readonly userAcknowledgeMerchantInvitePath = '/user/invite/{inviteId}/acknowledge';
  static readonly posDevicePairWithUserLoginPath = '/pos-device/pair-with-user-login';
  static readonly posDeviceGetAllowedStoresFromUserLoginPath = '/pos-device/stores-from-user-login';
  static readonly posDevicePairPath = '/pos-device/pair';
  static readonly posDeviceSetModePath = '/pos-device/set-mode';
  static readonly posDeviceListPath = '/pos-device';
  static readonly posDeviceCreateCardReaderConnectionTokenPath = '/pos-device/card-reader/connection-token';
  static readonly posDeviceCreateCardReaderRegisterPath = '/pos-device/card-reader/register';
  static readonly posDeviceCreateCardReaderPaymentIntentPath = '/pos-device/card-reader/payment-intent';
  static readonly posSessionSetCurrentOrderPath = '/pos-device/{deviceId}/customer-session/current-order';
  static readonly posSessionGetCurrentOrderPath = '/pos-device/{deviceId}/customer-session/current-order';
  static readonly posSessionSetCurrentOrderTipAmountPath = '/pos-device/{deviceId}/customer-session/current-order-tip-amount';
  static readonly posSessionClearPath = '/pos-device/{deviceId}/customer-session/clear';
  static readonly posSessionGetOrderSessionPath = '/pos-device/{deviceId}/customer-session/order-session/{orderSessionId}';
  static readonly posSessionDeleteOrderSessionPath = '/pos-device/{deviceId}/customer-session/order-session/{orderSessionId}';
  static readonly posSessionSetCurrentCustomerFromPhonePath = '/pos-device/{deviceId}/customer-session/current-customer-from-phone';
  static readonly posSessionGetCurrentCustomerPath = '/pos-device/{deviceId}/customer-session/current-customer';
  static readonly posSessionSetCurrentCustomerFromMobileDevicePath = '/pos-device/{deviceId}/customer-session/current-customer-from-mobile-device';
  static readonly posSessionCustomerGetCurrentOrderPath = '/pos-device/{deviceId}/customer-session/store/{storeId}/customer-current-order';
  static readonly posSessionCurrentOrderCompletePath = '/pos-device/{deviceId}/customer-session/current-order/complete';
  static readonly posSessionCustomerTransferOrderToMobilePath = '/pos-device/{deviceId}/customer-session/store/{storeId}/transfer-order-to-mobile';
  static readonly posSessionDevicePendingOrderClearPath = '/pos-device/{deviceId}/customer-session/pending-order-for-user/{userId}';
  static readonly posDeviceCashTillOpenPath = '/pos-device/cash-till/open';
  static readonly posDeviceCashTillClosePath = '/pos-device/cash-till/close';
  static readonly posDeviceCashTillTransactionSummaryPath = '/pos-device/cash-till/transactions/{tillId}';
  static readonly posDeviceAllTillTransactionSummaryPath = '/pos-device/cash-till/transactions-all/{tillId}';
  static readonly reportCreatePath = '/report';
  static readonly reportGetDailySummaryDeviceV2Path = '/report/get-daily-summary-device/{storeId}/{date}/v2';
  static readonly reportGetDailySummaryDevicePath = '/report/get-daily-summary-device/{storeId}/{date}';
  static readonly reportGetInternalReportsPath = '/report/internal';
  static readonly merchantDashboardV2Path = '/report/merchant-dashboard-v2';
  static readonly adminRefundOrderHackThisIsBadPath = '/admin/joe-admin/refund-order-hack-bad/{orderId}';
  static readonly adminGetOrderPath = '/admin/joe-admin/get-order/{orderId}';
  static readonly adminGetActiveUsersPath = '/admin/joe-admin/get-active-users';
  static readonly adminRefundOrderPath = '/admin/joe-admin/refund-order';
  static readonly adminFindUsersPath = '/admin/joe-admin/find-users';
  static readonly adminUpdateUserEmailPath = '/admin/joe-admin/update-user-email';
  static readonly adminFindGiftCardPath = '/admin/joe-admin/find-gift-card';
  static readonly adminFindOrdersPath = '/admin/joe-admin/find-orders';
  static readonly adminGetActiveStoresPath = '/admin/joe-admin/get-active-stores';
  static readonly adminGetJoeBucksActivityPath = '/admin/joe-admin/get-joe-bucks-activity';
  static readonly adminGetReferralHistoryPath = '/admin/joe-admin/get-referral-history/{userId}';
  static readonly adminSetReferrerPath = '/admin/joe-admin/set-referrer/{userId}/{referringUserId}';
  static readonly adminBypassStorePhoneVerificationPath = '/admin/joe-admin/store/{storeId}/bypass-phone-verification';
  static readonly adminGetOrderTotalsPath = '/admin/joe-admin/order-totals';
  static readonly adminGetUserWeeklyCohortTotalsPath = '/admin/joe-admin/user-weekly-cohort-totals-v2';
  static readonly adminGetStoresWithStatusPath = '/admin/joe-admin/get-stores-with-status';
  static readonly adminSearchStoresWithStatusPath = '/admin/joe-admin/search-stores-with-status';
  static readonly adminSendConsumerMarketingUserPushPath = '/admin/joe-admin/send-consumer-marketing-push';
  static readonly adminSendConsumerMarketingOrphanPushPath = '/admin/joe-admin/send-consumer-marketing-orphan';
  static readonly adminSendConsumerMarketingSinglePushPath = '/admin/joe-admin/send-consumer-marketing-single/{userId}';
  static readonly adminGetMerchantTransfersOldPath = '/admin/joe-admin/get-merchant-transfers';
  static readonly adminGetMerchantTransfersPath = '/admin/joe-admin/get-merchant-transfers/v2';
  static readonly adminSendMerchantTransferTransferPath = '/admin/joe-admin/send-merchant-transfer-transfer/{transferId}';
  static readonly adminSendMerchantTransferEmailPath = '/admin/joe-admin/send-merchant-transfer-email/{transferId}';
  static readonly adminSendMerchantTransferEmailTestPath = '/admin/joe-admin/send-merchant-transfer-email-test/{transferId}';
  static readonly adminUserRewardStatusGetPath = '/admin/joe-admin/user/{userId}/rewards';
  static readonly adminUserBalanceGetPath = '/admin/joe-admin/user/{userId}/balance';
  static readonly adminNetworkStatusTerritoryCreatePath = '/admin/joe-admin/network-status-territory';
  static readonly adminNetworkStatusTerritoryListPath = '/admin/joe-admin/network-status-territory';
  static readonly adminNetworkStatusTerritoryUpdatePath = '/admin/joe-admin/network-status-territory/{territoryId}';
  static readonly adminNetworkStatusTerritoryGetPath = '/admin/joe-admin/network-status-territory/{territoryId}';
  static readonly adminNetworkStatusTerritoryDeletePath = '/admin/joe-admin/network-status-territory/{territoryId}';
  static readonly adminNetworkStatusTerritoryGetDetailsPath = '/admin/joe-admin/network-status-territory-details/{territoryId}';
  static readonly adminNetworkStatusTerritoryListWithDetailsPath = '/admin/joe-admin/network-status-territory-details';
  static readonly adminSetStorePosPaymentEnabledPath = '/admin/joe-admin/store/{storeId}/set-pos-payment-enabled';
  static readonly adminSetStoreFeeOverridePath = '/admin/joe-admin/store/{storeId}/set-fee-override';
  static readonly adminSetCompanyApprovalPath = '/admin/joe-admin/set-company-approval';
  static readonly adminMenuTransferToCompanyPath = '/admin/joe-admin/{menuId}/transfer-to/{toCompanyId}';
  static readonly adminGetStoreOfflineReportPath = '/admin/joe-admin/store-offline-report';
  static readonly adminMergeUsersPath = '/admin/joe-admin/user-merge';
  static readonly adminResetUserPasswordPath = '/admin/joe-admin/user-reset-password';
  static readonly adminResetUserPath = '/admin/joe-admin/user-reset/{userId}';
  static readonly adminRefundRemainingUserBalancePath = '/admin/joe-admin/user-refund-remaining-joebucks/{userId}';
  static readonly adminGetGlobalPromosPath = '/admin/joe-admin/get-global-promos';
  static readonly adminGetUserWeeklyCohortTotalsDeprecatedPath = '/admin/joe-admin/user-weekly-cohort-totals';
  static readonly adminGetUserReactivationCohortsDeprecatedPath = '/admin/joe-admin/user-reactivation-cohorts';
  static readonly adminGetStoreStatusReportPath = '/admin/export/store-status-report';
  static readonly adminGetMerchantDeviceReportPath = '/admin/export/merchant-device-report';
  static readonly adminGetSquareIntegrationReportPath = '/admin/export/square-integration-report';
  static readonly adminGetPromoStatusReportPath = '/admin/export/promo-status-report';
  static readonly adminGetStoreCustomerFeedbackReportPath = '/admin/export/store-customer-feedback-report';
  static readonly adminGetAccountingReportCsvPath = '/admin/export/joe-admin/accounting-report-csv';
  static readonly adminStoreExportPath = '/admin/export/store-export';
  static readonly adminGetCurrentStatusV2Path = '/bi-role/joe-admin/get-current-status/v2';
  static readonly adminGetRecentOrdersPath = '/bi-role/joe-admin/recent-orders';
  static readonly adminGetStoresNearbyPath = '/bi-role/joe-admin/stores-nearby';
  static readonly adminGetCurrentStatusPath = '/bi-role/joe-admin/get-current-status';
  static readonly companyDashboardPath = '/company/{companyId}/dashboard';
  static readonly companyListPath = '/company';
  static readonly companyCreatePath = '/company';
  static readonly companyGetLandingPageFromSlugPath = '/company/{companySlug}/landing-page-data';
  static readonly companyLandingAddHeroBackgroundPath = '/company/{companyId}/add-landing-hero-background';
  static readonly companyLandingAddLogoPath = '/company/{companyId}/add-landing-logo';
  static readonly companyUpdatePath = '/company/{companyId}';
  static readonly companyGetPath = '/company/{companyId}';
  static readonly companyVerificationPath = '/company/{companyId}/verification';
  static readonly companyVerificationV2Path = '/company/{companyId}/verification/v2';
  static readonly companyGetTransfersPath = '/company/{companyId}/transfers';
  static readonly companyDownloadTransferReportPath = '/company/{companyId}/download-transfer/{transferBatchId}';
  static readonly companyGetStoresPath = '/company/{companyId}/stores';
  static readonly companyGetUsersPath = '/company/{companyId}/users';
  static readonly companyGetMenusPath = '/company/{companyId}/menus';
  static readonly companyGetLandingPagePath = '/company/{companyId}/landing-page';
  static readonly companyInviteUserPath = '/company/{companyId}/invite';
  static readonly companyGetInvitesPath = '/company/{companyId}/invite';
  static readonly companyDeleteUserRolePath = '/company/{companyId}/role/{userId}/{roleName}';
  static readonly companyCancelInvitePath = '/company/{companyId}/invite/{inviteId}/cancel';
  static readonly companyReportSubscriberListPath = '/company/{companyId}/report-subscriber';
  static readonly companyReportSubscriberCreatePath = '/company/{companyId}/report-subscriber';
  static readonly companyReportSubscriberDeletePath = '/company/{companyId}/report-subscriber/{subscriberId}';
  static readonly menuOptionGetPath = '/company/{companyId}/menu/{menuId}/menu-option/{menuOptionId}';
  static readonly menuOptionUpdatePath = '/company/{companyId}/menu/{menuId}/menu-option/{menuOptionId}';
  static readonly menuOptionDeletePath = '/company/{companyId}/menu/{menuId}/menu-option/{menuOptionId}';
  static readonly menuOptionListPath = '/company/{companyId}/menu/{menuId}/menu-option';
  static readonly menuOptionCreatePath = '/company/{companyId}/menu/{menuId}/menu-option';
  static readonly menuGetPath = '/company/{companyId}/menu/{menuId}';
  static readonly menuDeletePath = '/company/{companyId}/menu/{menuId}';
  static readonly menuExportEncodedPath = '/company/{companyId}/menu/{menuId}/export/encoded';
  static readonly menuExportPath = '/company/{companyId}/menu/{menuId}/export';
  static readonly menuImportEncodedPath = '/company/{companyId}/menu/import/encoded';
  static readonly menuImportPath = '/company/{companyId}/menu/import';
  static readonly menuCreatePath = '/company/{companyId}/menu/v2';
  static readonly menuCreateLegacyPath = '/company/{companyId}/menu';
  static readonly companyBankAccountListPath = '/company/{companyId}/bank-account/list';
  static readonly companyBankAccountAddPath = '/company/{companyId}/bank-account';
  static readonly companyBankAccountGetPath = '/company/{companyId}/bank-account/{bankId}';
  static readonly companyBankAccountDeletePath = '/company/{companyId}/bank-account/{bankId}';
  static readonly companyBankAccountSetDefaultPath = '/company/{companyId}/bank-account/{bankId}/default';
  static readonly devicePairFromIdPath = '/company/{companyId}/devices/pair-from-code';
  static readonly deviceUnpairPath = '/company/{companyId}/devices/{deviceId}/unpair';
  static readonly deviceListPath = '/company/{companyId}/devices';
  static readonly devicePairCreatePath = '/company/{companyId}/devices/pair';
  static readonly merchantDeviceSetActivePath = '/company/{companyId}/devices/set-active/{isActive}';
  static readonly merchantDeviceCheckinPath = '/company/{companyId}/devices/checkin';
  static readonly merchantDeviceCheckinOnlyPutPath = '/company/{companyId}/devices/check-in';
  static readonly merchantDeviceCheckinOnlyPath = '/company/{companyId}/devices/check-in';
  static readonly merchantDeviceOrderStatsPath = '/company/{companyId}/devices/order-stats';
  static readonly merchantDeviceActiveOrdersPath = '/company/{companyId}/devices/active-orders';
  static readonly merchantDeviceArchivedOrdersPath = '/company/{companyId}/devices/archived-orders';
  static readonly merchantDeviceInactiveOrdersPath = '/company/{companyId}/devices/inactive-orders';
  static readonly customerListPaginatedPath = '/customer';
  static readonly customerGetPath = '/customer/{userId}';
  static readonly customerGetRecentOrdersPath = '/customer/{userId}/recent-orders';
  static readonly landingPageLoadedPath = '/landing-page/{slug}/load';
  static readonly landingPageLinkClickedPath = '/landing-page/{slug}/click';
  static readonly promoCreatePath = '/promo';
  static readonly promoUpdatePath = '/promo';
  static readonly promoGetPath = '/promo';
  static readonly consumerLaunchPath = '/client/consumer-launch';
  static readonly consumerLaunchV2Path = '/client/consumer-launch/v2';
  static readonly feedbackSendPath = '/feedback';
  static readonly apiAppListPath = '/api-app';
  static readonly apiAppCreatePath = '/api-app';
  static readonly apiAppCredentialListPath = '/api-app/{apiAppId}/credentials';
  static readonly apiAppGetPath = '/api-app/{apiAppId}';
  static readonly apiAppCreateCredentialPath = '/api-app/{apiAppId}/credential';
  static readonly apiAppRevokeCredentialPath = '/api-app/{apiAppId}/credential/{apiCredentialId}';
  static readonly apiAppRollCredentialSecretPath = '/api-app/{apiAppId}/credential/{apiCredentialId}/roll';
  static readonly consumerActivityCreatePath = '/analytics/consumer-activity';
  static readonly squareWebhookEndpointPath = '/square-webhook';

  private tokenProvider: (name: string) => __Observable<string> = _ => __of(null);

  constructor(
    config: __Configuration,
    http: HttpClient,
    @Optional() @Inject(ApiServiceTokenProviderInjectionToken) tokenProvider: ApiServiceTokenProvider,
  ) {
    super(config, http);
    if (tokenProvider) {
      this.tokenProvider = (v: string) => tokenProvider.getToken(v);
    }
  }


  /**
   * Status Get
   */
  statusGetResponse(): __Observable<__StrictHttpResponse<RootDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RootDto>;
      })
    );
  }
  /**
   * Status Get
   */
  statusGet(): __Observable<RootDto> {
    return this.statusGetResponse().pipe(
      __map(_r => _r.body as RootDto)
    );
  }

  /**
   * Stores Get Nearby Paginated
   * @param params The `ApiService.StoresGetNearbyPaginatedParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `storeName`:
   *
   * - `resultCount`:
   *
   * - `postalCode`:
   *
   * - `pageKey`:
   *
   * - `maxDistance`:
   */
  storesGetNearbyPaginatedResponse(params: ApiService.StoresGetNearbyPaginatedParams): __Observable<__StrictHttpResponse<StoreSearchPaginatedResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.storeName != null) __params = __params.set('storeName', params.storeName.toString());
    if (params.resultCount != null) __params = __params.set('resultCount', params.resultCount.toString());
    if (params.postalCode != null) __params = __params.set('postalCode', params.postalCode.toString());
    if (params.pageKey != null) __params = __params.set('pageKey', params.pageKey.toString());
    if (params.maxDistance != null) __params = __params.set('maxDistance', params.maxDistance.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/nearby/paginated/${encodeURIComponent(params.lat)}/${encodeURIComponent(params.lon)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreSearchPaginatedResponseDto>;
      })
    );
  }
  /**
   * Stores Get Nearby Paginated
   * @param params The `ApiService.StoresGetNearbyPaginatedParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `storeName`:
   *
   * - `resultCount`:
   *
   * - `postalCode`:
   *
   * - `pageKey`:
   *
   * - `maxDistance`:
   */
  storesGetNearbyPaginated(params: ApiService.StoresGetNearbyPaginatedParams): __Observable<StoreSearchPaginatedResponseDto> {
    return this.storesGetNearbyPaginatedResponse(params).pipe(
      __map(_r => _r.body as StoreSearchPaginatedResponseDto)
    );
  }

  /**
   * Stores Get Nearby
   * @param params The `ApiService.StoresGetNearbyParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `storeName`:
   *
   * - `resultOffset`:
   *
   * - `postalCode`:
   *
   * - `maxResults`:
   *
   * - `maxDistance`:
   */
  storesGetNearbyResponse(params: ApiService.StoresGetNearbyParams): __Observable<__StrictHttpResponse<StoreSearchResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.storeName != null) __params = __params.set('storeName', params.storeName.toString());
    if (params.resultOffset != null) __params = __params.set('resultOffset', params.resultOffset.toString());
    if (params.postalCode != null) __params = __params.set('postalCode', params.postalCode.toString());
    if (params.maxResults != null) __params = __params.set('maxResults', params.maxResults.toString());
    if (params.maxDistance != null) __params = __params.set('maxDistance', params.maxDistance.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/nearby/${encodeURIComponent(params.lat)}/${encodeURIComponent(params.lon)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreSearchResponseDto>;
      })
    );
  }
  /**
   * Stores Get Nearby
   * @param params The `ApiService.StoresGetNearbyParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `storeName`:
   *
   * - `resultOffset`:
   *
   * - `postalCode`:
   *
   * - `maxResults`:
   *
   * - `maxDistance`:
   */
  storesGetNearby(params: ApiService.StoresGetNearbyParams): __Observable<StoreSearchResponseDto> {
    return this.storesGetNearbyResponse(params).pipe(
      __map(_r => _r.body as StoreSearchResponseDto)
    );
  }

  /**
   * Store Is Available For Orders
   * @param storeId undefined
   */
  storeIsAvailableForOrdersResponse(storeId: string): __Observable<__StrictHttpResponse<StoreWithAvailabilityDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/available-for-order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreWithAvailabilityDto>;
      })
    );
  }
  /**
   * Store Is Available For Orders
   * @param storeId undefined
   */
  storeIsAvailableForOrders(storeId: string): __Observable<StoreWithAvailabilityDto> {
    return this.storeIsAvailableForOrdersResponse(storeId).pipe(
      __map(_r => _r.body as StoreWithAvailabilityDto)
    );
  }

  /**
   * Store Get Deals
   * @param storeId undefined
   */
  storeGetDealsResponse(storeId: string): __Observable<__StrictHttpResponse<Array<ItemDealDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/deal`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ItemDealDto>>;
      })
    );
  }
  /**
   * Store Get Deals
   * @param storeId undefined
   */
  storeGetDeals(storeId: string): __Observable<Array<ItemDealDto>> {
    return this.storeGetDealsResponse(storeId).pipe(
      __map(_r => _r.body as Array<ItemDealDto>)
    );
  }

  /**
   * Store Put Deal
   * @param params The `ApiService.StorePutDealParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `ItemDealDto`:
   */
  storePutDealResponse(params: ApiService.StorePutDealParams): __Observable<__StrictHttpResponse<ItemDealDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.ItemDealDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/deal`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemDealDto>;
      })
    );
  }
  /**
   * Store Put Deal
   * @param params The `ApiService.StorePutDealParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `ItemDealDto`:
   */
  storePutDeal(params: ApiService.StorePutDealParams): __Observable<ItemDealDto> {
    return this.storePutDealResponse(params).pipe(
      __map(_r => _r.body as ItemDealDto)
    );
  }

  /**
   * Store Delete Deal
   * @param params The `ApiService.StoreDeleteDealParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `dealId`:
   */
  storeDeleteDealResponse(params: ApiService.StoreDeleteDealParams): __Observable<__StrictHttpResponse<ItemDealDeleteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/deal/${encodeURIComponent(params.dealId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemDealDeleteResponseDto>;
      })
    );
  }
  /**
   * Store Delete Deal
   * @param params The `ApiService.StoreDeleteDealParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `dealId`:
   */
  storeDeleteDeal(params: ApiService.StoreDeleteDealParams): __Observable<ItemDealDeleteResponseDto> {
    return this.storeDeleteDealResponse(params).pipe(
      __map(_r => _r.body as ItemDealDeleteResponseDto)
    );
  }

  /**
   * Store Get Details
   * @param storeId undefined
   */
  storeGetDetailsResponse(storeId: string): __Observable<__StrictHttpResponse<StoreWithDetailsDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/details`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreWithDetailsDto>;
      })
    );
  }
  /**
   * Store Get Details
   * @param storeId undefined
   */
  storeGetDetails(storeId: string): __Observable<StoreWithDetailsDto> {
    return this.storeGetDetailsResponse(storeId).pipe(
      __map(_r => _r.body as StoreWithDetailsDto)
    );
  }

  /**
   * Store Get Menu Category Sections With Inventory
   * @param params The `ApiService.StoreGetMenuCategorySectionsWithInventoryParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `categoryId`:
   */
  storeGetMenuCategorySectionsWithInventoryResponse(params: ApiService.StoreGetMenuCategorySectionsWithInventoryParams): __Observable<__StrictHttpResponse<Array<MenuSectionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/menu-category-sections-with-inventory/${encodeURIComponent(params.categoryId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MenuSectionDto>>;
      })
    );
  }
  /**
   * Store Get Menu Category Sections With Inventory
   * @param params The `ApiService.StoreGetMenuCategorySectionsWithInventoryParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `categoryId`:
   */
  storeGetMenuCategorySectionsWithInventory(params: ApiService.StoreGetMenuCategorySectionsWithInventoryParams): __Observable<Array<MenuSectionDto>> {
    return this.storeGetMenuCategorySectionsWithInventoryResponse(params).pipe(
      __map(_r => _r.body as Array<MenuSectionDto>)
    );
  }

  /**
   * Store Get Menu V2
   * @param params The `ApiService.StoreGetMenuV2Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `includeSections`:
   *
   * - `includeMenuOptions`:
   *
   * - `includeItemOptions`:
   *
   * - `includeInventory`:
   */
  storeGetMenuV2Response(params: ApiService.StoreGetMenuV2Params): __Observable<__StrictHttpResponse<MenuExportDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeSections != null) __params = __params.set('includeSections', params.includeSections.toString());
    if (params.includeMenuOptions != null) __params = __params.set('includeMenuOptions', params.includeMenuOptions.toString());
    if (params.includeItemOptions != null) __params = __params.set('includeItemOptions', params.includeItemOptions.toString());
    if (params.includeInventory != null) __params = __params.set('includeInventory', params.includeInventory.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/menu/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuExportDto>;
      })
    );
  }
  /**
   * Store Get Menu V2
   * @param params The `ApiService.StoreGetMenuV2Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `includeSections`:
   *
   * - `includeMenuOptions`:
   *
   * - `includeItemOptions`:
   *
   * - `includeInventory`:
   */
  storeGetMenuV2(params: ApiService.StoreGetMenuV2Params): __Observable<MenuExportDto> {
    return this.storeGetMenuV2Response(params).pipe(
      __map(_r => _r.body as MenuExportDto)
    );
  }

  /**
   * Store Get Menu V3
   * @param params The `ApiService.StoreGetMenuV3Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `includeStoreHidden`:
   *
   * - `includeSections`:
   *
   * - `includeMenuOptions`:
   *
   * - `includeItemOptions`:
   *
   * - `includeInventory`:
   */
  storeGetMenuV3Response(params: ApiService.StoreGetMenuV3Params): __Observable<__StrictHttpResponse<MenuExportDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeStoreHidden != null) __params = __params.set('includeStoreHidden', params.includeStoreHidden.toString());
    if (params.includeSections != null) __params = __params.set('includeSections', params.includeSections.toString());
    if (params.includeMenuOptions != null) __params = __params.set('includeMenuOptions', params.includeMenuOptions.toString());
    if (params.includeItemOptions != null) __params = __params.set('includeItemOptions', params.includeItemOptions.toString());
    if (params.includeInventory != null) __params = __params.set('includeInventory', params.includeInventory.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/menu/v3`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuExportDto>;
      })
    );
  }
  /**
   * Store Get Menu V3
   * @param params The `ApiService.StoreGetMenuV3Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `includeStoreHidden`:
   *
   * - `includeSections`:
   *
   * - `includeMenuOptions`:
   *
   * - `includeItemOptions`:
   *
   * - `includeInventory`:
   */
  storeGetMenuV3(params: ApiService.StoreGetMenuV3Params): __Observable<MenuExportDto> {
    return this.storeGetMenuV3Response(params).pipe(
      __map(_r => _r.body as MenuExportDto)
    );
  }

  /**
   * Store Get Menu
   * @param storeId undefined
   */
  storeGetMenuResponse(storeId: string): __Observable<__StrictHttpResponse<MenuExportDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/menu`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuExportDto>;
      })
    );
  }
  /**
   * Store Get Menu
   * @param storeId undefined
   */
  storeGetMenu(storeId: string): __Observable<MenuExportDto> {
    return this.storeGetMenuResponse(storeId).pipe(
      __map(_r => _r.body as MenuExportDto)
    );
  }

  /**
   * Store Get Seo List
   */
  storeGetSeoListResponse(): __Observable<__StrictHttpResponse<Array<StoreMinimalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/list-for-blog-seo`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreMinimalDto>>;
      })
    );
  }
  /**
   * Store Get Seo List
   */
  storeGetSeoList(): __Observable<Array<StoreMinimalDto>> {
    return this.storeGetSeoListResponse().pipe(
      __map(_r => _r.body as Array<StoreMinimalDto>)
    );
  }

  /**
   * Store List
   */
  storeListResponse(): __Observable<__StrictHttpResponse<Array<StoreMinimalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreMinimalDto>>;
      })
    );
  }
  /**
   * Store List
   */
  storeList(): __Observable<Array<StoreMinimalDto>> {
    return this.storeListResponse().pipe(
      __map(_r => _r.body as Array<StoreMinimalDto>)
    );
  }

  /**
   * Store Create
   * @param StoreDto undefined
   */
  storeCreateResponse(StoreDto: StoreDto): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = StoreDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Create
   * @param StoreDto undefined
   */
  storeCreate(StoreDto: StoreDto): __Observable<StoreDto> {
    return this.storeCreateResponse(StoreDto).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Get Offline Report
   * @param params The `ApiService.StoreGetOfflineReportParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreOfflineReportRequestDto`:
   */
  storeGetOfflineReportResponse(params: ApiService.StoreGetOfflineReportParams): __Observable<__StrictHttpResponse<Array<any>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreOfflineReportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/offline-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<any>>;
      })
    );
  }
  /**
   * Store Get Offline Report
   * @param params The `ApiService.StoreGetOfflineReportParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreOfflineReportRequestDto`:
   */
  storeGetOfflineReport(params: ApiService.StoreGetOfflineReportParams): __Observable<Array<any>> {
    return this.storeGetOfflineReportResponse(params).pipe(
      __map(_r => _r.body as Array<any>)
    );
  }

  /**
   * Store Dashboard
   * @param params The `ApiService.StoreDashboardParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `date`:
   */
  storeDashboardResponse(params: ApiService.StoreDashboardParams): __Observable<__StrictHttpResponse<StoreDashboardDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.date != null) __params = __params.set('date', params.date.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/dashboard`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDashboardDto>;
      })
    );
  }
  /**
   * Store Dashboard
   * @param params The `ApiService.StoreDashboardParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `date`:
   */
  storeDashboard(params: ApiService.StoreDashboardParams): __Observable<StoreDashboardDto> {
    return this.storeDashboardResponse(params).pipe(
      __map(_r => _r.body as StoreDashboardDto)
    );
  }

  /**
   * Store Put Amenities
   * @param params The `ApiService.StorePutAmenitiesParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreAmenitiesDto`:
   */
  storePutAmenitiesResponse(params: ApiService.StorePutAmenitiesParams): __Observable<__StrictHttpResponse<StoreAmenitiesDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreAmenitiesDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/amenities`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreAmenitiesDto>;
      })
    );
  }
  /**
   * Store Put Amenities
   * @param params The `ApiService.StorePutAmenitiesParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreAmenitiesDto`:
   */
  storePutAmenities(params: ApiService.StorePutAmenitiesParams): __Observable<StoreAmenitiesDto> {
    return this.storePutAmenitiesResponse(params).pipe(
      __map(_r => _r.body as StoreAmenitiesDto)
    );
  }

  /**
   * Store Find Details
   * @param GPlacesQueryParamsDto undefined
   */
  storeFindDetailsResponse(GPlacesQueryParamsDto: GPlacesQueryParamsDto): __Observable<__StrictHttpResponse<Array<GPlacesResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = GPlacesQueryParamsDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/find`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GPlacesResponseDto>>;
      })
    );
  }
  /**
   * Store Find Details
   * @param GPlacesQueryParamsDto undefined
   */
  storeFindDetails(GPlacesQueryParamsDto: GPlacesQueryParamsDto): __Observable<Array<GPlacesResponseDto>> {
    return this.storeFindDetailsResponse(GPlacesQueryParamsDto).pipe(
      __map(_r => _r.body as Array<GPlacesResponseDto>)
    );
  }

  /**
   * Store Find Hours By Place Id
   * @param GPlacesPlaceIdDto undefined
   */
  storeFindHoursByPlaceIdResponse(GPlacesPlaceIdDto: GPlacesPlaceIdDto): __Observable<__StrictHttpResponse<Array<GPlacesStoreHoursResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = GPlacesPlaceIdDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/find/hours`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GPlacesStoreHoursResponseDto>>;
      })
    );
  }
  /**
   * Store Find Hours By Place Id
   * @param GPlacesPlaceIdDto undefined
   */
  storeFindHoursByPlaceId(GPlacesPlaceIdDto: GPlacesPlaceIdDto): __Observable<Array<GPlacesStoreHoursResponseDto>> {
    return this.storeFindHoursByPlaceIdResponse(GPlacesPlaceIdDto).pipe(
      __map(_r => _r.body as Array<GPlacesStoreHoursResponseDto>)
    );
  }

  /**
   * Update Store Hours
   * @param params The `ApiService.UpdateStoreHoursParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreHoursRequestDto`:
   */
  updateStoreHoursResponse(params: ApiService.UpdateStoreHoursParams): __Observable<__StrictHttpResponse<Array<StoreHoursResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreHoursRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/set-hours`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreHoursResponseDto>>;
      })
    );
  }
  /**
   * Update Store Hours
   * @param params The `ApiService.UpdateStoreHoursParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreHoursRequestDto`:
   */
  updateStoreHours(params: ApiService.UpdateStoreHoursParams): __Observable<Array<StoreHoursResponseDto>> {
    return this.updateStoreHoursResponse(params).pipe(
      __map(_r => _r.body as Array<StoreHoursResponseDto>)
    );
  }

  /**
   * Store Validate Phone
   * @param storeId undefined
   */
  storeValidatePhoneResponse(storeId: string): __Observable<__StrictHttpResponse<StorePhoneVerificationResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/validate-phone`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorePhoneVerificationResponseDto>;
      })
    );
  }
  /**
   * Store Validate Phone
   * @param storeId undefined
   */
  storeValidatePhone(storeId: string): __Observable<StorePhoneVerificationResponseDto> {
    return this.storeValidatePhoneResponse(storeId).pipe(
      __map(_r => _r.body as StorePhoneVerificationResponseDto)
    );
  }

  /**
   * Store Validate Phone Check Status
   * @param storeId undefined
   */
  storeValidatePhoneCheckStatusResponse(storeId: string): __Observable<__StrictHttpResponse<StorePhoneVerificationStatusResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/validate-phone`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorePhoneVerificationStatusResponseDto>;
      })
    );
  }
  /**
   * Store Validate Phone Check Status
   * @param storeId undefined
   */
  storeValidatePhoneCheckStatus(storeId: string): __Observable<StorePhoneVerificationStatusResponseDto> {
    return this.storeValidatePhoneCheckStatusResponse(storeId).pipe(
      __map(_r => _r.body as StorePhoneVerificationStatusResponseDto)
    );
  }

  /**
   * Store Set Always Online
   * @param params The `ApiService.StoreSetAlwaysOnlineParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreSetAlwaysOnlineDto`:
   */
  storeSetAlwaysOnlineResponse(params: ApiService.StoreSetAlwaysOnlineParams): __Observable<__StrictHttpResponse<StoreSetAlwaysOnlineResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreSetAlwaysOnlineDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/set-always-online`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreSetAlwaysOnlineResponseDto>;
      })
    );
  }
  /**
   * Store Set Always Online
   * @param params The `ApiService.StoreSetAlwaysOnlineParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreSetAlwaysOnlineDto`:
   */
  storeSetAlwaysOnline(params: ApiService.StoreSetAlwaysOnlineParams): __Observable<StoreSetAlwaysOnlineResponseDto> {
    return this.storeSetAlwaysOnlineResponse(params).pipe(
      __map(_r => _r.body as StoreSetAlwaysOnlineResponseDto)
    );
  }

  /**
   * Store Set Square Location Id
   * @param params The `ApiService.StoreSetSquareLocationIdParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreSquareLocationIdDto`:
   */
  storeSetSquareLocationIdResponse(params: ApiService.StoreSetSquareLocationIdParams): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreSquareLocationIdDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/set-location-id`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Set Square Location Id
   * @param params The `ApiService.StoreSetSquareLocationIdParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreSquareLocationIdDto`:
   */
  storeSetSquareLocationId(params: ApiService.StoreSetSquareLocationIdParams): __Observable<StoreDto> {
    return this.storeSetSquareLocationIdResponse(params).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Set Minimum Wait Time
   * @param StoreSetMinimumWaitTimeDto undefined
   */
  storeSetMinimumWaitTimeResponse(StoreSetMinimumWaitTimeDto: StoreSetMinimumWaitTimeDto): __Observable<__StrictHttpResponse<StoreSetMinimumWaitTimeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = StoreSetMinimumWaitTimeDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/set-minimum-wait-time`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreSetMinimumWaitTimeResponseDto>;
      })
    );
  }
  /**
   * Store Set Minimum Wait Time
   * @param StoreSetMinimumWaitTimeDto undefined
   */
  storeSetMinimumWaitTime(StoreSetMinimumWaitTimeDto: StoreSetMinimumWaitTimeDto): __Observable<StoreSetMinimumWaitTimeResponseDto> {
    return this.storeSetMinimumWaitTimeResponse(StoreSetMinimumWaitTimeDto).pipe(
      __map(_r => _r.body as StoreSetMinimumWaitTimeResponseDto)
    );
  }

  /**
   * Store Clear Square Location Id
   * @param storeId undefined
   */
  storeClearSquareLocationIdResponse(storeId: string): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/clear-location-id`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Clear Square Location Id
   * @param storeId undefined
   */
  storeClearSquareLocationId(storeId: string): __Observable<StoreDto> {
    return this.storeClearSquareLocationIdResponse(storeId).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Disconnect Square Integration
   * @param storeId undefined
   */
  storeDisconnectSquareIntegrationResponse(storeId: string): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/disconnect-square-integration`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Disconnect Square Integration
   * @param storeId undefined
   */
  storeDisconnectSquareIntegration(storeId: string): __Observable<StoreDto> {
    return this.storeDisconnectSquareIntegrationResponse(storeId).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Validate Phone Twilio Inbound Web Hook
   * @param params The `ApiService.StoreValidatePhoneTwilioInboundWebHookParams` containing the following parameters:
   *
   * - `Digits`:
   *
   * - `Called`:
   */
  storeValidatePhoneTwilioInboundWebHookResponse(params: ApiService.StoreValidatePhoneTwilioInboundWebHookParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Digits != null) __params = __params.set('Digits', params.Digits.toString());
    if (params.Called != null) __params = __params.set('Called', params.Called.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/validate-phone-twilio-hook`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'text'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Store Validate Phone Twilio Inbound Web Hook
   * @param params The `ApiService.StoreValidatePhoneTwilioInboundWebHookParams` containing the following parameters:
   *
   * - `Digits`:
   *
   * - `Called`:
   */
  storeValidatePhoneTwilioInboundWebHook(params: ApiService.StoreValidatePhoneTwilioInboundWebHookParams): __Observable<string> {
    return this.storeValidatePhoneTwilioInboundWebHookResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Store Update V2
   * @param params The `ApiService.StoreUpdateV2Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreUpdateDto`:
   */
  storeUpdateV2Response(params: ApiService.StoreUpdateV2Params): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreUpdateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Update V2
   * @param params The `ApiService.StoreUpdateV2Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreUpdateDto`:
   */
  storeUpdateV2(params: ApiService.StoreUpdateV2Params): __Observable<StoreDto> {
    return this.storeUpdateV2Response(params).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Get V2
   * @param storeId undefined
   */
  storeGetV2Response(storeId: string): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Get V2
   * @param storeId undefined
   */
  storeGetV2(storeId: string): __Observable<StoreDto> {
    return this.storeGetV2Response(storeId).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Get
   * @param storeId undefined
   */
  storeGetResponse(storeId: string): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Get
   * @param storeId undefined
   */
  storeGet(storeId: string): __Observable<StoreDto> {
    return this.storeGetResponse(storeId).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Store Update
   * @param params The `ApiService.StoreUpdateParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreUpdateDto`:
   */
  storeUpdateResponse(params: ApiService.StoreUpdateParams): __Observable<__StrictHttpResponse<StoreDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreUpdateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDto>;
      })
    );
  }
  /**
   * Store Update
   * @param params The `ApiService.StoreUpdateParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreUpdateDto`:
   */
  storeUpdate(params: ApiService.StoreUpdateParams): __Observable<StoreDto> {
    return this.storeUpdateResponse(params).pipe(
      __map(_r => _r.body as StoreDto)
    );
  }

  /**
   * Get Orders By Store
   * @param params The `ApiService.GetOrdersByStoreParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `status`:
   */
  getOrdersByStoreResponse(params: ApiService.GetOrdersByStoreParams): __Observable<__StrictHttpResponse<OrderListViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.status != null) __params = __params.set('status', params.status.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderListViewDto>;
      })
    );
  }
  /**
   * Get Orders By Store
   * @param params The `ApiService.GetOrdersByStoreParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `status`:
   */
  getOrdersByStore(params: ApiService.GetOrdersByStoreParams): __Observable<OrderListViewDto> {
    return this.getOrdersByStoreResponse(params).pipe(
      __map(_r => _r.body as OrderListViewDto)
    );
  }

  /**
   * Store Invite User
   * @param params The `ApiService.StoreInviteUserParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreCreateInviteRequestDto`:
   */
  storeInviteUserResponse(params: ApiService.StoreInviteUserParams): __Observable<__StrictHttpResponse<StoreCreateInviteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreCreateInviteRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/invite`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreCreateInviteResponseDto>;
      })
    );
  }
  /**
   * Store Invite User
   * @param params The `ApiService.StoreInviteUserParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreCreateInviteRequestDto`:
   */
  storeInviteUser(params: ApiService.StoreInviteUserParams): __Observable<StoreCreateInviteResponseDto> {
    return this.storeInviteUserResponse(params).pipe(
      __map(_r => _r.body as StoreCreateInviteResponseDto)
    );
  }

  /**
   * Store Add Image From Id
   * @param params The `ApiService.StoreAddImageFromIdParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreAddImageRequestDto`:
   */
  storeAddImageFromIdResponse(params: ApiService.StoreAddImageFromIdParams): __Observable<__StrictHttpResponse<StoreAddImageResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreAddImageRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/add-image-from-id`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreAddImageResponseDto>;
      })
    );
  }
  /**
   * Store Add Image From Id
   * @param params The `ApiService.StoreAddImageFromIdParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreAddImageRequestDto`:
   */
  storeAddImageFromId(params: ApiService.StoreAddImageFromIdParams): __Observable<StoreAddImageResponseDto> {
    return this.storeAddImageFromIdResponse(params).pipe(
      __map(_r => _r.body as StoreAddImageResponseDto)
    );
  }

  /**
   * Store Add Pickup Location Image From Id
   * @param params The `ApiService.StoreAddPickupLocationImageFromIdParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StorePickupInstructionsLocationImageDto`:
   */
  storeAddPickupLocationImageFromIdResponse(params: ApiService.StoreAddPickupLocationImageFromIdParams): __Observable<__StrictHttpResponse<StoreAddImageResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StorePickupInstructionsLocationImageDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/add-pickup-location-image-from-id`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreAddImageResponseDto>;
      })
    );
  }
  /**
   * Store Add Pickup Location Image From Id
   * @param params The `ApiService.StoreAddPickupLocationImageFromIdParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StorePickupInstructionsLocationImageDto`:
   */
  storeAddPickupLocationImageFromId(params: ApiService.StoreAddPickupLocationImageFromIdParams): __Observable<StoreAddImageResponseDto> {
    return this.storeAddPickupLocationImageFromIdResponse(params).pipe(
      __map(_r => _r.body as StoreAddImageResponseDto)
    );
  }

  /**
   * Store Add Image
   * @param params The `ApiService.StoreAddImageParams` containing the following parameters:
   *
   * - `y`:
   *
   * - `x`:
   *
   * - `width`:
   *
   * - `storeId`:
   *
   * - `scale`:
   *
   * - `height`:
   *
   * - `file`:
   */
  storeAddImageResponse(params: ApiService.StoreAddImageParams): __Observable<__StrictHttpResponse<StoreAddImageResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.y != null) __params = __params.set('y', params.y.toString());
    if (params.x != null) __params = __params.set('x', params.x.toString());
    if (params.width != null) __params = __params.set('width', params.width.toString());

    if (params.scale != null) __params = __params.set('scale', params.scale.toString());
    if (params.height != null) __params = __params.set('height', params.height.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/add-image`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreAddImageResponseDto>;
      })
    );
  }
  /**
   * Store Add Image
   * @param params The `ApiService.StoreAddImageParams` containing the following parameters:
   *
   * - `y`:
   *
   * - `x`:
   *
   * - `width`:
   *
   * - `storeId`:
   *
   * - `scale`:
   *
   * - `height`:
   *
   * - `file`:
   */
  storeAddImage(params: ApiService.StoreAddImageParams): __Observable<StoreAddImageResponseDto> {
    return this.storeAddImageResponse(params).pipe(
      __map(_r => _r.body as StoreAddImageResponseDto)
    );
  }

  /**
   * Store Report Subscriber List
   * @param storeId undefined
   */
  storeReportSubscriberListResponse(storeId: string): __Observable<__StrictHttpResponse<Array<StoreReportSubscriberDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store/${encodeURIComponent(storeId)}/report-subscriber`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreReportSubscriberDto>>;
      })
    );
  }
  /**
   * Store Report Subscriber List
   * @param storeId undefined
   */
  storeReportSubscriberList(storeId: string): __Observable<Array<StoreReportSubscriberDto>> {
    return this.storeReportSubscriberListResponse(storeId).pipe(
      __map(_r => _r.body as Array<StoreReportSubscriberDto>)
    );
  }

  /**
   * Store Report Subscriber Create
   * @param params The `ApiService.StoreReportSubscriberCreateParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreReportSubscriberDto`:
   */
  storeReportSubscriberCreateResponse(params: ApiService.StoreReportSubscriberCreateParams): __Observable<__StrictHttpResponse<StoreReportSubscriberDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.StoreReportSubscriberDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/report-subscriber`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreReportSubscriberDto>;
      })
    );
  }
  /**
   * Store Report Subscriber Create
   * @param params The `ApiService.StoreReportSubscriberCreateParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `StoreReportSubscriberDto`:
   */
  storeReportSubscriberCreate(params: ApiService.StoreReportSubscriberCreateParams): __Observable<StoreReportSubscriberDto> {
    return this.storeReportSubscriberCreateResponse(params).pipe(
      __map(_r => _r.body as StoreReportSubscriberDto)
    );
  }

  /**
   * Store Report Subscriber Delete
   * @param params The `ApiService.StoreReportSubscriberDeleteParams` containing the following parameters:
   *
   * - `subscriberId`:
   *
   * - `storeId`:
   */
  storeReportSubscriberDeleteResponse(params: ApiService.StoreReportSubscriberDeleteParams): __Observable<__StrictHttpResponse<StoreReportSubscriberDeleteResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/store/${encodeURIComponent(params.storeId)}/report-subscriber/${encodeURIComponent(params.subscriberId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreReportSubscriberDeleteResultDto>;
      })
    );
  }
  /**
   * Store Report Subscriber Delete
   * @param params The `ApiService.StoreReportSubscriberDeleteParams` containing the following parameters:
   *
   * - `subscriberId`:
   *
   * - `storeId`:
   */
  storeReportSubscriberDelete(params: ApiService.StoreReportSubscriberDeleteParams): __Observable<StoreReportSubscriberDeleteResultDto> {
    return this.storeReportSubscriberDeleteResponse(params).pipe(
      __map(_r => _r.body as StoreReportSubscriberDeleteResultDto)
    );
  }

  /**
   * Store Item Inventory Get Out Of Stock Count
   */
  storeItemInventoryGetOutOfStockCountResponse(): __Observable<__StrictHttpResponse<StoreItemInventoryCountDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store-item-inventory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreItemInventoryCountDto>;
      })
    );
  }
  /**
   * Store Item Inventory Get Out Of Stock Count
   */
  storeItemInventoryGetOutOfStockCount(): __Observable<StoreItemInventoryCountDto> {
    return this.storeItemInventoryGetOutOfStockCountResponse().pipe(
      __map(_r => _r.body as StoreItemInventoryCountDto)
    );
  }

  /**
   * Store Set Item Inventory
   * @param StoreItemInventoryStatusDto undefined
   */
  storeSetItemInventoryResponse(StoreItemInventoryStatusDto: StoreItemInventoryStatusDto): __Observable<__StrictHttpResponse<StoreItemInventoryStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = StoreItemInventoryStatusDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store-item-inventory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreItemInventoryStatusDto>;
      })
    );
  }
  /**
   * Store Set Item Inventory
   * @param StoreItemInventoryStatusDto undefined
   */
  storeSetItemInventory(StoreItemInventoryStatusDto: StoreItemInventoryStatusDto): __Observable<StoreItemInventoryStatusDto> {
    return this.storeSetItemInventoryResponse(StoreItemInventoryStatusDto).pipe(
      __map(_r => _r.body as StoreItemInventoryStatusDto)
    );
  }

  /**
   * Store Add Item Inventory
   * @param StoreItemInventoryRequestDto undefined
   */
  storeAddItemInventoryResponse(StoreItemInventoryRequestDto: StoreItemInventoryRequestDto): __Observable<__StrictHttpResponse<StoreItemInventoryResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = StoreItemInventoryRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/store-item-inventory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreItemInventoryResponseDto>;
      })
    );
  }
  /**
   * Store Add Item Inventory
   * @param StoreItemInventoryRequestDto undefined
   */
  storeAddItemInventory(StoreItemInventoryRequestDto: StoreItemInventoryRequestDto): __Observable<StoreItemInventoryResponseDto> {
    return this.storeAddItemInventoryResponse(StoreItemInventoryRequestDto).pipe(
      __map(_r => _r.body as StoreItemInventoryResponseDto)
    );
  }

  /**
   * Store Delete Item Inventory
   * @param itemInventoryId undefined
   */
  storeDeleteItemInventoryResponse(itemInventoryId: string): __Observable<__StrictHttpResponse<StoreDeleteItemInventoryResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/store-item-inventory/${encodeURIComponent(itemInventoryId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreDeleteItemInventoryResponseDto>;
      })
    );
  }
  /**
   * Store Delete Item Inventory
   * @param itemInventoryId undefined
   */
  storeDeleteItemInventory(itemInventoryId: string): __Observable<StoreDeleteItemInventoryResponseDto> {
    return this.storeDeleteItemInventoryResponse(itemInventoryId).pipe(
      __map(_r => _r.body as StoreDeleteItemInventoryResponseDto)
    );
  }

  /**
   * Store Option Inventory Get Out Of Stock Count
   */
  storeOptionInventoryGetOutOfStockCountResponse(): __Observable<__StrictHttpResponse<StoreOptionInventoryCountDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/store-option-inventory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreOptionInventoryCountDto>;
      })
    );
  }
  /**
   * Store Option Inventory Get Out Of Stock Count
   */
  storeOptionInventoryGetOutOfStockCount(): __Observable<StoreOptionInventoryCountDto> {
    return this.storeOptionInventoryGetOutOfStockCountResponse().pipe(
      __map(_r => _r.body as StoreOptionInventoryCountDto)
    );
  }

  /**
   * Store Set Option Inventory
   * @param StoreOptionInventoryStatusDto undefined
   */
  storeSetOptionInventoryResponse(StoreOptionInventoryStatusDto: StoreOptionInventoryStatusDto): __Observable<__StrictHttpResponse<StoreOptionInventoryStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = StoreOptionInventoryStatusDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/store-option-inventory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreOptionInventoryStatusDto>;
      })
    );
  }
  /**
   * Store Set Option Inventory
   * @param StoreOptionInventoryStatusDto undefined
   */
  storeSetOptionInventory(StoreOptionInventoryStatusDto: StoreOptionInventoryStatusDto): __Observable<StoreOptionInventoryStatusDto> {
    return this.storeSetOptionInventoryResponse(StoreOptionInventoryStatusDto).pipe(
      __map(_r => _r.body as StoreOptionInventoryStatusDto)
    );
  }

  /**
   * Order List Paginated
   * @param params The `ApiService.OrderListPaginatedParams` containing the following parameters:
   *
   * - `count`:
   *
   * - `storeId`:
   *
   * - `pageKey`:
   */
  orderListPaginatedResponse(params: ApiService.OrderListPaginatedParams): __Observable<__StrictHttpResponse<OrderViewConsumerPaginatedDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.count != null) __params = __params.set('count', params.count.toString());
    if (params.storeId != null) __params = __params.set('storeId', params.storeId.toString());
    if (params.pageKey != null) __params = __params.set('pageKey', params.pageKey.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/order/paginated`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewConsumerPaginatedDto>;
      })
    );
  }
  /**
   * Order List Paginated
   * @param params The `ApiService.OrderListPaginatedParams` containing the following parameters:
   *
   * - `count`:
   *
   * - `storeId`:
   *
   * - `pageKey`:
   */
  orderListPaginated(params: ApiService.OrderListPaginatedParams): __Observable<OrderViewConsumerPaginatedDto> {
    return this.orderListPaginatedResponse(params).pipe(
      __map(_r => _r.body as OrderViewConsumerPaginatedDto)
    );
  }

  /**
   * Order Get
   * @param orderId undefined
   */
  orderGetResponse(orderId: string): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/order/${encodeURIComponent(orderId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Order Get
   * @param orderId undefined
   */
  orderGet(orderId: string): __Observable<OrderViewDto> {
    return this.orderGetResponse(orderId).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Order Get Detailed
   * @param orderId undefined
   */
  orderGetDetailedResponse(orderId: string): __Observable<__StrictHttpResponse<OrderWithDetailDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/order/detailed/${encodeURIComponent(orderId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderWithDetailDto>;
      })
    );
  }
  /**
   * Order Get Detailed
   * @param orderId undefined
   */
  orderGetDetailed(orderId: string): __Observable<OrderWithDetailDto> {
    return this.orderGetDetailedResponse(orderId).pipe(
      __map(_r => _r.body as OrderWithDetailDto)
    );
  }

  /**
   * Order Get Reorder
   * @param orderId undefined
   */
  orderGetReorderResponse(orderId: string): __Observable<__StrictHttpResponse<OrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/order/reorder/${encodeURIComponent(orderId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDto>;
      })
    );
  }
  /**
   * Order Get Reorder
   * @param orderId undefined
   */
  orderGetReorder(orderId: string): __Observable<OrderDto> {
    return this.orderGetReorderResponse(orderId).pipe(
      __map(_r => _r.body as OrderDto)
    );
  }

  /**
   * Order List
   * @param storeId undefined
   */
  orderListResponse(storeId?: string): __Observable<__StrictHttpResponse<Array<OrderViewConsumerDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (storeId != null) __params = __params.set('storeId', storeId.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderViewConsumerDto>>;
      })
    );
  }
  /**
   * Order List
   * @param storeId undefined
   */
  orderList(storeId?: string): __Observable<Array<OrderViewConsumerDto>> {
    return this.orderListResponse(storeId).pipe(
      __map(_r => _r.body as Array<OrderViewConsumerDto>)
    );
  }

  /**
   * Order Create
   * @param OrderCreateRequestDto undefined
   */
  orderCreateResponse(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<__StrictHttpResponse<OrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = OrderCreateRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDto>;
      })
    );
  }
  /**
   * Order Create
   * @param OrderCreateRequestDto undefined
   */
  orderCreate(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<OrderDto> {
    return this.orderCreateResponse(OrderCreateRequestDto).pipe(
      __map(_r => _r.body as OrderDto)
    );
  }

  /**
   * Order Create V2
   * @param OrderCreateRequestDto undefined
   */
  orderCreateV2Response(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = OrderCreateRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Order Create V2
   * @param OrderCreateRequestDto undefined
   */
  orderCreateV2(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<OrderViewDto> {
    return this.orderCreateV2Response(OrderCreateRequestDto).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Order Create V3
   * @param OrderCreateRequestDto undefined
   */
  orderCreateV3Response(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = OrderCreateRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/v3`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Order Create V3
   * @param OrderCreateRequestDto undefined
   */
  orderCreateV3(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<OrderViewDto> {
    return this.orderCreateV3Response(OrderCreateRequestDto).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Order Customer Arrived
   * @param orderId undefined
   */
  orderCustomerArrivedResponse(orderId: string): __Observable<__StrictHttpResponse<OrderCustomerArrivedResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/${encodeURIComponent(orderId)}/customer-arrived`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderCustomerArrivedResponseDto>;
      })
    );
  }
  /**
   * Order Customer Arrived
   * @param orderId undefined
   */
  orderCustomerArrived(orderId: string): __Observable<OrderCustomerArrivedResponseDto> {
    return this.orderCustomerArrivedResponse(orderId).pipe(
      __map(_r => _r.body as OrderCustomerArrivedResponseDto)
    );
  }

  /**
   * Order Review
   * @param params The `ApiService.OrderReviewParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `OrderReviewDto`:
   */
  orderReviewResponse(params: ApiService.OrderReviewParams): __Observable<__StrictHttpResponse<OrderReviewResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.OrderReviewDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/${encodeURIComponent(params.orderId)}/review`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderReviewResponseDto>;
      })
    );
  }
  /**
   * Order Review
   * @param params The `ApiService.OrderReviewParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `OrderReviewDto`:
   */
  orderReview(params: ApiService.OrderReviewParams): __Observable<OrderReviewResponseDto> {
    return this.orderReviewResponse(params).pipe(
      __map(_r => _r.body as OrderReviewResponseDto)
    );
  }

  /**
   * Order Pricing Compute
   * @param OrderDto undefined
   */
  orderPricingComputeResponse(OrderDto: OrderDto): __Observable<__StrictHttpResponse<OrderPricingDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = OrderDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/pricing`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderPricingDto>;
      })
    );
  }
  /**
   * Order Pricing Compute
   * @param OrderDto undefined
   */
  orderPricingCompute(OrderDto: OrderDto): __Observable<OrderPricingDto> {
    return this.orderPricingComputeResponse(OrderDto).pipe(
      __map(_r => _r.body as OrderPricingDto)
    );
  }

  /**
   * Order Pricing Compute With Source Type
   * @param params The `ApiService.OrderPricingComputeWithSourceTypeParams` containing the following parameters:
   *
   * - `OrderDto`:
   *
   * - `paymentSourceType`:
   */
  orderPricingComputeWithSourceTypeResponse(params: ApiService.OrderPricingComputeWithSourceTypeParams): __Observable<__StrictHttpResponse<OrderPricingDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.OrderDto;
    if (params.paymentSourceType != null) __params = __params.set('paymentSourceType', params.paymentSourceType.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/pricing-with-source-type`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderPricingDto>;
      })
    );
  }
  /**
   * Order Pricing Compute With Source Type
   * @param params The `ApiService.OrderPricingComputeWithSourceTypeParams` containing the following parameters:
   *
   * - `OrderDto`:
   *
   * - `paymentSourceType`:
   */
  orderPricingComputeWithSourceType(params: ApiService.OrderPricingComputeWithSourceTypeParams): __Observable<OrderPricingDto> {
    return this.orderPricingComputeWithSourceTypeResponse(params).pipe(
      __map(_r => _r.body as OrderPricingDto)
    );
  }

  /**
   * Order Customer Pickup Order
   * @param orderId undefined
   */
  orderCustomerPickupOrderResponse(orderId: string): __Observable<__StrictHttpResponse<OrderCustomerArrivedResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/${encodeURIComponent(orderId)}/customer-pickup-order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderCustomerArrivedResponseDto>;
      })
    );
  }
  /**
   * Order Customer Pickup Order
   * @param orderId undefined
   */
  orderCustomerPickupOrder(orderId: string): __Observable<OrderCustomerArrivedResponseDto> {
    return this.orderCustomerPickupOrderResponse(orderId).pipe(
      __map(_r => _r.body as OrderCustomerArrivedResponseDto)
    );
  }

  /**
   * Order Refund
   * @param params The `ApiService.OrderRefundParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `RefundOrderRequestDto`:
   */
  orderRefundResponse(params: ApiService.OrderRefundParams): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.RefundOrderRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/order/${encodeURIComponent(params.orderId)}/refund`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Order Refund
   * @param params The `ApiService.OrderRefundParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `RefundOrderRequestDto`:
   */
  orderRefund(params: ApiService.OrderRefundParams): __Observable<OrderViewDto> {
    return this.orderRefundResponse(params).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Order Archive
   * @param orderId undefined
   */
  orderArchiveResponse(orderId: string): __Observable<__StrictHttpResponse<OrderItemSetStatusResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/${encodeURIComponent(orderId)}/archive`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderItemSetStatusResponseDto>;
      })
    );
  }
  /**
   * Order Archive
   * @param orderId undefined
   */
  orderArchive(orderId: string): __Observable<OrderItemSetStatusResponseDto> {
    return this.orderArchiveResponse(orderId).pipe(
      __map(_r => _r.body as OrderItemSetStatusResponseDto)
    );
  }

  /**
   * Order Acknowledge
   * @param params The `ApiService.OrderAcknowledgeParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `AcknowledgeOrderRequestDto`:
   */
  orderAcknowledgeResponse(params: ApiService.OrderAcknowledgeParams): __Observable<__StrictHttpResponse<AcknowledgeOrderResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.AcknowledgeOrderRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/${encodeURIComponent(params.orderId)}/acknowledge`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AcknowledgeOrderResponseDto>;
      })
    );
  }
  /**
   * Order Acknowledge
   * @param params The `ApiService.OrderAcknowledgeParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `AcknowledgeOrderRequestDto`:
   */
  orderAcknowledge(params: ApiService.OrderAcknowledgeParams): __Observable<AcknowledgeOrderResponseDto> {
    return this.orderAcknowledgeResponse(params).pipe(
      __map(_r => _r.body as AcknowledgeOrderResponseDto)
    );
  }

  /**
   * Order Set Wait Time
   * @param params The `ApiService.OrderSetWaitTimeParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `OrderSetWaitTimeRequestDto`:
   */
  orderSetWaitTimeResponse(params: ApiService.OrderSetWaitTimeParams): __Observable<__StrictHttpResponse<OrderSetWaitTimeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.OrderSetWaitTimeRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/order/${encodeURIComponent(params.orderId)}/wait-time`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderSetWaitTimeResponseDto>;
      })
    );
  }
  /**
   * Order Set Wait Time
   * @param params The `ApiService.OrderSetWaitTimeParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `OrderSetWaitTimeRequestDto`:
   */
  orderSetWaitTime(params: ApiService.OrderSetWaitTimeParams): __Observable<OrderSetWaitTimeResponseDto> {
    return this.orderSetWaitTimeResponse(params).pipe(
      __map(_r => _r.body as OrderSetWaitTimeResponseDto)
    );
  }

  /**
   * Order Schedule Alert Acknowledged
   * @param orderId undefined
   */
  orderScheduleAlertAcknowledgedResponse(orderId: string): __Observable<__StrictHttpResponse<AcknowledgeOrderResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/order/${encodeURIComponent(orderId)}/acknowledge-schedule-alert`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AcknowledgeOrderResponseDto>;
      })
    );
  }
  /**
   * Order Schedule Alert Acknowledged
   * @param orderId undefined
   */
  orderScheduleAlertAcknowledged(orderId: string): __Observable<AcknowledgeOrderResponseDto> {
    return this.orderScheduleAlertAcknowledgedResponse(orderId).pipe(
      __map(_r => _r.body as AcknowledgeOrderResponseDto)
    );
  }

  /**
   * Order Get Detailed Pos
   * @param orderId undefined
   */
  orderGetDetailedPosResponse(orderId: string): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/order/detailed/${encodeURIComponent(orderId)}/for-pos`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Order Get Detailed Pos
   * @param orderId undefined
   */
  orderGetDetailedPos(orderId: string): __Observable<OrderViewDto> {
    return this.orderGetDetailedPosResponse(orderId).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Order Create For Pos
   * @param OrderCreateRequestDto undefined
   */
  orderCreateForPosResponse(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = OrderCreateRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/order/for-pos`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Order Create For Pos
   * @param OrderCreateRequestDto undefined
   */
  orderCreateForPos(OrderCreateRequestDto: OrderCreateRequestDto): __Observable<OrderViewDto> {
    return this.orderCreateForPosResponse(OrderCreateRequestDto).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Order Item Set Status
   * @param params The `ApiService.OrderItemSetStatusParams` containing the following parameters:
   *
   * - `orderItemId`:
   *
   * - `orderId`:
   *
   * - `OrderItemSetStatusRequestDto`:
   */
  orderItemSetStatusResponse(params: ApiService.OrderItemSetStatusParams): __Observable<__StrictHttpResponse<OrderItemSetStatusResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.OrderItemSetStatusRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/order/${encodeURIComponent(params.orderId)}/item/${encodeURIComponent(params.orderItemId)}/status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderItemSetStatusResponseDto>;
      })
    );
  }
  /**
   * Order Item Set Status
   * @param params The `ApiService.OrderItemSetStatusParams` containing the following parameters:
   *
   * - `orderItemId`:
   *
   * - `orderId`:
   *
   * - `OrderItemSetStatusRequestDto`:
   */
  orderItemSetStatus(params: ApiService.OrderItemSetStatusParams): __Observable<OrderItemSetStatusResponseDto> {
    return this.orderItemSetStatusResponse(params).pipe(
      __map(_r => _r.body as OrderItemSetStatusResponseDto)
    );
  }

  /**
   * Order User Pending Order Clear
   * @param storeId undefined
   */
  orderUserPendingOrderClearResponse(storeId: string): __Observable<__StrictHttpResponse<OrderItemSetStatusResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/order/pending-order-at-store/${encodeURIComponent(storeId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderItemSetStatusResponseDto>;
      })
    );
  }
  /**
   * Order User Pending Order Clear
   * @param storeId undefined
   */
  orderUserPendingOrderClear(storeId: string): __Observable<OrderItemSetStatusResponseDto> {
    return this.orderUserPendingOrderClearResponse(storeId).pipe(
      __map(_r => _r.body as OrderItemSetStatusResponseDto)
    );
  }

  /**
   * Create Joe Gift Card
   * @param JoeGiftCardCreateRequestDto undefined
   */
  createJoeGiftCardResponse(JoeGiftCardCreateRequestDto: JoeGiftCardCreateRequestDto): __Observable<__StrictHttpResponse<JoeGiftCardCreateResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = JoeGiftCardCreateRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/payment/joe-gift-card`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JoeGiftCardCreateResponseDto>;
      })
    );
  }
  /**
   * Create Joe Gift Card
   * @param JoeGiftCardCreateRequestDto undefined
   */
  createJoeGiftCard(JoeGiftCardCreateRequestDto: JoeGiftCardCreateRequestDto): __Observable<JoeGiftCardCreateResponseDto> {
    return this.createJoeGiftCardResponse(JoeGiftCardCreateRequestDto).pipe(
      __map(_r => _r.body as JoeGiftCardCreateResponseDto)
    );
  }

  /**
   * Joebucks Upload Funds
   * @param UploadJoebucksRequestDto undefined
   */
  joebucksUploadFundsResponse(UploadJoebucksRequestDto: UploadJoebucksRequestDto): __Observable<__StrictHttpResponse<JoebucksBalanceResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UploadJoebucksRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/payment/joebucks/upload-funds`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JoebucksBalanceResponseDto>;
      })
    );
  }
  /**
   * Joebucks Upload Funds
   * @param UploadJoebucksRequestDto undefined
   */
  joebucksUploadFunds(UploadJoebucksRequestDto: UploadJoebucksRequestDto): __Observable<JoebucksBalanceResponseDto> {
    return this.joebucksUploadFundsResponse(UploadJoebucksRequestDto).pipe(
      __map(_r => _r.body as JoebucksBalanceResponseDto)
    );
  }

  /**
   * Joebucks Check Balance
   */
  joebucksCheckBalanceResponse(): __Observable<__StrictHttpResponse<JoebucksBalanceResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/payment/joebucks/balance`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JoebucksBalanceResponseDto>;
      })
    );
  }
  /**
   * Joebucks Check Balance
   */
  joebucksCheckBalance(): __Observable<JoebucksBalanceResponseDto> {
    return this.joebucksCheckBalanceResponse().pipe(
      __map(_r => _r.body as JoebucksBalanceResponseDto)
    );
  }

  /**
   * Joebucks Uploads History
   * @param params The `ApiService.JoebucksUploadsHistoryParams` containing the following parameters:
   *
   * - `count`:
   *
   * - `pageKey`:
   */
  joebucksUploadsHistoryResponse(params: ApiService.JoebucksUploadsHistoryParams): __Observable<__StrictHttpResponse<JoebucksLedgerHistoryPaginatedDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.count != null) __params = __params.set('count', params.count.toString());
    if (params.pageKey != null) __params = __params.set('pageKey', params.pageKey.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/payment/joebucks/history`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JoebucksLedgerHistoryPaginatedDto>;
      })
    );
  }
  /**
   * Joebucks Uploads History
   * @param params The `ApiService.JoebucksUploadsHistoryParams` containing the following parameters:
   *
   * - `count`:
   *
   * - `pageKey`:
   */
  joebucksUploadsHistory(params: ApiService.JoebucksUploadsHistoryParams): __Observable<JoebucksLedgerHistoryPaginatedDto> {
    return this.joebucksUploadsHistoryResponse(params).pipe(
      __map(_r => _r.body as JoebucksLedgerHistoryPaginatedDto)
    );
  }

  /**
   * Authorize Bank Account
   * @param userId undefined
   */
  authorizeBankAccountResponse(userId: string): __Observable<__StrictHttpResponse<AddBankAccountResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/payment/bank-accounts/authorize`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddBankAccountResponseDto>;
      })
    );
  }
  /**
   * Authorize Bank Account
   * @param userId undefined
   */
  authorizeBankAccount(userId: string): __Observable<AddBankAccountResponseDto> {
    return this.authorizeBankAccountResponse(userId).pipe(
      __map(_r => _r.body as AddBankAccountResponseDto)
    );
  }

  /**
   * Store User Bank Account
   * @param UserBankAccountDto undefined
   */
  storeUserBankAccountResponse(UserBankAccountDto: UserBankAccountDto): __Observable<__StrictHttpResponse<UserBankAccountResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserBankAccountDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/payment/bank-accounts/user-bank-account`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserBankAccountResponseDto>;
      })
    );
  }
  /**
   * Store User Bank Account
   * @param UserBankAccountDto undefined
   */
  storeUserBankAccount(UserBankAccountDto: UserBankAccountDto): __Observable<UserBankAccountResponseDto> {
    return this.storeUserBankAccountResponse(UserBankAccountDto).pipe(
      __map(_r => _r.body as UserBankAccountResponseDto)
    );
  }

  /**
   * Delete Bank Account
   * @param PaymentMethodDto undefined
   */
  deleteBankAccountResponse(PaymentMethodDto: PaymentMethodDto): __Observable<__StrictHttpResponse<PaymentMethodDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PaymentMethodDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/payment/bank-accounts/delete`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentMethodDto>;
      })
    );
  }
  /**
   * Delete Bank Account
   * @param PaymentMethodDto undefined
   */
  deleteBankAccount(PaymentMethodDto: PaymentMethodDto): __Observable<PaymentMethodDto> {
    return this.deleteBankAccountResponse(PaymentMethodDto).pipe(
      __map(_r => _r.body as PaymentMethodDto)
    );
  }

  /**
   * Clover Inventory List
   * @param storeId undefined
   */
  cloverInventoryListResponse(storeId: string): __Observable<__StrictHttpResponse<Array<CloverItemDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/clover-inventory/store/${encodeURIComponent(storeId)}/inventory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CloverItemDto>>;
      })
    );
  }
  /**
   * Clover Inventory List
   * @param storeId undefined
   */
  cloverInventoryList(storeId: string): __Observable<Array<CloverItemDto>> {
    return this.cloverInventoryListResponse(storeId).pipe(
      __map(_r => _r.body as Array<CloverItemDto>)
    );
  }

  /**
   * Clover Inventory Association
   * @param params The `ApiService.CloverInventoryAssociationParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `cloverItemId`:
   *
   * - `StoreItemInventoryRequestDto`:
   */
  cloverInventoryAssociationResponse(params: ApiService.CloverInventoryAssociationParams): __Observable<__StrictHttpResponse<StoreItemInventoryResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.StoreItemInventoryRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/clover-inventory/store/${encodeURIComponent(params.storeId)}/inventory/${encodeURIComponent(params.cloverItemId)}/associate`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreItemInventoryResponseDto>;
      })
    );
  }
  /**
   * Clover Inventory Association
   * @param params The `ApiService.CloverInventoryAssociationParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `cloverItemId`:
   *
   * - `StoreItemInventoryRequestDto`:
   */
  cloverInventoryAssociation(params: ApiService.CloverInventoryAssociationParams): __Observable<StoreItemInventoryResponseDto> {
    return this.cloverInventoryAssociationResponse(params).pipe(
      __map(_r => _r.body as StoreItemInventoryResponseDto)
    );
  }

  /**
   * Clover Inventory Webhook
   * @param x-clover-auth undefined
   */
  cloverInventoryWebhookResponse(xCloverAuth: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (xCloverAuth != null) __headers = __headers.set('x-clover-auth', xCloverAuth.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/clover-inventory/webhook`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clover Inventory Webhook
   * @param x-clover-auth undefined
   */
  cloverInventoryWebhook(xCloverAuth: string): __Observable<null> {
    return this.cloverInventoryWebhookResponse(xCloverAuth).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Clover Auth Authorization
   * @param params The `ApiService.CloverAuthAuthorizationParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `CloverAuthorizationRequestDto`:
   */
  cloverAuthAuthorizationResponse(params: ApiService.CloverAuthAuthorizationParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CloverAuthorizationRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/clover-auth/store/${encodeURIComponent(params.storeId)}/authorization`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Clover Auth Authorization
   * @param params The `ApiService.CloverAuthAuthorizationParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `CloverAuthorizationRequestDto`:
   */
  cloverAuthAuthorization(params: ApiService.CloverAuthAuthorizationParams): __Observable<any> {
    return this.cloverAuthAuthorizationResponse(params).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * Clover Auth Revoke
   * @param storeId undefined
   */
  cloverAuthRevokeResponse(storeId: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/clover-auth/store/${encodeURIComponent(storeId)}/revoke`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Clover Auth Revoke
   * @param storeId undefined
   */
  cloverAuthRevoke(storeId: string): __Observable<any> {
    return this.cloverAuthRevokeResponse(storeId).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * SquareAuthorizationRedirectEndpoint
   * @param params The `ApiService.SquareAuthorizationRedirectEndpointParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   */
  squareAuthorizationRedirectEndpointResponse(params: ApiService.SquareAuthorizationRedirectEndpointParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/square/authorization`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * SquareAuthorizationRedirectEndpoint
   * @param params The `ApiService.SquareAuthorizationRedirectEndpointParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   */
  squareAuthorizationRedirectEndpoint(params: ApiService.SquareAuthorizationRedirectEndpointParams): __Observable<null> {
    return this.squareAuthorizationRedirectEndpointResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Square Get All Locations
   * @param storeId undefined
   */
  squareGetAllLocationsResponse(storeId: string): __Observable<__StrictHttpResponse<Array<SquareLocationDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/square/${encodeURIComponent(storeId)}/get-square-locations`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SquareLocationDto>>;
      })
    );
  }
  /**
   * Square Get All Locations
   * @param storeId undefined
   */
  squareGetAllLocations(storeId: string): __Observable<Array<SquareLocationDto>> {
    return this.squareGetAllLocationsResponse(storeId).pipe(
      __map(_r => _r.body as Array<SquareLocationDto>)
    );
  }

  /**
   * Auth Login
   * @param AuthLoginRequestDto undefined
   */
  authLoginResponse(AuthLoginRequestDto: AuthLoginRequestDto): __Observable<__StrictHttpResponse<AuthResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthLoginRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/login`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponseDto>;
      })
    );
  }
  /**
   * Auth Login
   * @param AuthLoginRequestDto undefined
   */
  authLogin(AuthLoginRequestDto: AuthLoginRequestDto): __Observable<AuthResponseDto> {
    return this.authLoginResponse(AuthLoginRequestDto).pipe(
      __map(_r => _r.body as AuthResponseDto)
    );
  }

  /**
   * Auth Signup
   * @param AuthSignupRequestDto undefined
   */
  authSignupResponse(AuthSignupRequestDto: AuthSignupRequestDto): __Observable<__StrictHttpResponse<AuthResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthSignupRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/signup`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponseDto>;
      })
    );
  }
  /**
   * Auth Signup
   * @param AuthSignupRequestDto undefined
   */
  authSignup(AuthSignupRequestDto: AuthSignupRequestDto): __Observable<AuthResponseDto> {
    return this.authSignupResponse(AuthSignupRequestDto).pipe(
      __map(_r => _r.body as AuthResponseDto)
    );
  }

  /**
   * User Auth Refresh
   * @param AuthRefreshRequestDto undefined
   */
  userAuthRefreshResponse(AuthRefreshRequestDto: AuthRefreshRequestDto): __Observable<__StrictHttpResponse<AuthResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthRefreshRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/refresh`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponseDto>;
      })
    );
  }
  /**
   * User Auth Refresh
   * @param AuthRefreshRequestDto undefined
   */
  userAuthRefresh(AuthRefreshRequestDto: AuthRefreshRequestDto): __Observable<AuthResponseDto> {
    return this.userAuthRefreshResponse(AuthRefreshRequestDto).pipe(
      __map(_r => _r.body as AuthResponseDto)
    );
  }

  /**
   * Merchant Device Refresh
   * @param AuthRefreshRequestDto undefined
   */
  merchantDeviceRefreshResponse(AuthRefreshRequestDto: AuthRefreshRequestDto): __Observable<__StrictHttpResponse<AuthResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthRefreshRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/refresh-merchant`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Refresh
   * @param AuthRefreshRequestDto undefined
   */
  merchantDeviceRefresh(AuthRefreshRequestDto: AuthRefreshRequestDto): __Observable<AuthResponseDto> {
    return this.merchantDeviceRefreshResponse(AuthRefreshRequestDto).pipe(
      __map(_r => _r.body as AuthResponseDto)
    );
  }

  /**
   * Consumer Signup
   * @param ConsumerSignupRequestDto undefined
   */
  consumerSignupResponse(ConsumerSignupRequestDto: ConsumerSignupRequestDto): __Observable<__StrictHttpResponse<ConsumerSignupResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ConsumerSignupRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/consumerSignup`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConsumerSignupResponseDto>;
      })
    );
  }
  /**
   * Consumer Signup
   * @param ConsumerSignupRequestDto undefined
   */
  consumerSignup(ConsumerSignupRequestDto: ConsumerSignupRequestDto): __Observable<ConsumerSignupResponseDto> {
    return this.consumerSignupResponse(ConsumerSignupRequestDto).pipe(
      __map(_r => _r.body as ConsumerSignupResponseDto)
    );
  }

  /**
   * Validate User
   * @param ValidateUserRequestDto undefined
   */
  validateUserResponse(ValidateUserRequestDto: ValidateUserRequestDto): __Observable<__StrictHttpResponse<AuthResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ValidateUserRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/validateUser`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponseDto>;
      })
    );
  }
  /**
   * Validate User
   * @param ValidateUserRequestDto undefined
   */
  validateUser(ValidateUserRequestDto: ValidateUserRequestDto): __Observable<AuthResponseDto> {
    return this.validateUserResponse(ValidateUserRequestDto).pipe(
      __map(_r => _r.body as AuthResponseDto)
    );
  }

  /**
   * Auth Change Password
   * @param AuthChangePasswordRequestDto undefined
   */
  authChangePasswordResponse(AuthChangePasswordRequestDto: AuthChangePasswordRequestDto): __Observable<__StrictHttpResponse<AuthChangePasswordResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthChangePasswordRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/change-password`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthChangePasswordResponseDto>;
      })
    );
  }
  /**
   * Auth Change Password
   * @param AuthChangePasswordRequestDto undefined
   */
  authChangePassword(AuthChangePasswordRequestDto: AuthChangePasswordRequestDto): __Observable<AuthChangePasswordResponseDto> {
    return this.authChangePasswordResponse(AuthChangePasswordRequestDto).pipe(
      __map(_r => _r.body as AuthChangePasswordResponseDto)
    );
  }

  /**
   * Auth Change Password With Code
   * @param AuthChangePasswordWithCodeRequestDto undefined
   */
  authChangePasswordWithCodeResponse(AuthChangePasswordWithCodeRequestDto: AuthChangePasswordWithCodeRequestDto): __Observable<__StrictHttpResponse<AuthChangePasswordResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthChangePasswordWithCodeRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/change-password-with-code`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthChangePasswordResponseDto>;
      })
    );
  }
  /**
   * Auth Change Password With Code
   * @param AuthChangePasswordWithCodeRequestDto undefined
   */
  authChangePasswordWithCode(AuthChangePasswordWithCodeRequestDto: AuthChangePasswordWithCodeRequestDto): __Observable<AuthChangePasswordResponseDto> {
    return this.authChangePasswordWithCodeResponse(AuthChangePasswordWithCodeRequestDto).pipe(
      __map(_r => _r.body as AuthChangePasswordResponseDto)
    );
  }

  /**
   * Auth Reset Password
   * @param AuthResetPasswordRequestDto undefined
   */
  authResetPasswordResponse(AuthResetPasswordRequestDto: AuthResetPasswordRequestDto): __Observable<__StrictHttpResponse<AuthResetPasswordResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthResetPasswordRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/reset-password`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResetPasswordResponseDto>;
      })
    );
  }
  /**
   * Auth Reset Password
   * @param AuthResetPasswordRequestDto undefined
   */
  authResetPassword(AuthResetPasswordRequestDto: AuthResetPasswordRequestDto): __Observable<AuthResetPasswordResponseDto> {
    return this.authResetPasswordResponse(AuthResetPasswordRequestDto).pipe(
      __map(_r => _r.body as AuthResetPasswordResponseDto)
    );
  }

  /**
   * Create App Code
   * @param AppCodeCreateDto undefined
   */
  createAppCodeResponse(AppCodeCreateDto: AppCodeCreateDto): __Observable<__StrictHttpResponse<AppCodeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AppCodeCreateDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/app-code`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppCodeDto>;
      })
    );
  }
  /**
   * Create App Code
   * @param AppCodeCreateDto undefined
   */
  createAppCode(AppCodeCreateDto: AppCodeCreateDto): __Observable<AppCodeDto> {
    return this.createAppCodeResponse(AppCodeCreateDto).pipe(
      __map(_r => _r.body as AppCodeDto)
    );
  }

  /**
   * Redeem App Code
   * @param code undefined
   */
  redeemAppCodeResponse(code: string): __Observable<__StrictHttpResponse<AppCodeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/app-code/${encodeURIComponent(code)}/redeem`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppCodeDto>;
      })
    );
  }
  /**
   * Redeem App Code
   * @param code undefined
   */
  redeemAppCode(code: string): __Observable<AppCodeDto> {
    return this.redeemAppCodeResponse(code).pipe(
      __map(_r => _r.body as AppCodeDto)
    );
  }

  /**
   * Check App Code Redeemed
   * @param code undefined
   */
  checkAppCodeRedeemedResponse(code: string): __Observable<__StrictHttpResponse<AppCodeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/auth/app-code/${encodeURIComponent(code)}/status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppCodeDto>;
      })
    );
  }
  /**
   * Check App Code Redeemed
   * @param code undefined
   */
  checkAppCodeRedeemed(code: string): __Observable<AppCodeDto> {
    return this.checkAppCodeRedeemedResponse(code).pipe(
      __map(_r => _r.body as AppCodeDto)
    );
  }

  /**
   * App Code Authenticate
   * @param AppCodeAuthenticateRequestDto undefined
   */
  appCodeAuthenticateResponse(AppCodeAuthenticateRequestDto: AppCodeAuthenticateRequestDto): __Observable<__StrictHttpResponse<AuthResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AppCodeAuthenticateRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/app-code/authenticate`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponseDto>;
      })
    );
  }
  /**
   * App Code Authenticate
   * @param AppCodeAuthenticateRequestDto undefined
   */
  appCodeAuthenticate(AppCodeAuthenticateRequestDto: AppCodeAuthenticateRequestDto): __Observable<AuthResponseDto> {
    return this.appCodeAuthenticateResponse(AppCodeAuthenticateRequestDto).pipe(
      __map(_r => _r.body as AuthResponseDto)
    );
  }

  /**
   * Oauth Auth Obtain Token
   * @param AuthTokenObtainRequestDto undefined
   */
  oauthAuthObtainTokenResponse(AuthTokenObtainRequestDto: AuthTokenObtainRequestDto): __Observable<__StrictHttpResponse<AuthTokenObtainResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthTokenObtainRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/oauth/token`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthTokenObtainResponseDto>;
      })
    );
  }
  /**
   * Oauth Auth Obtain Token
   * @param AuthTokenObtainRequestDto undefined
   */
  oauthAuthObtainToken(AuthTokenObtainRequestDto: AuthTokenObtainRequestDto): __Observable<AuthTokenObtainResponseDto> {
    return this.oauthAuthObtainTokenResponse(AuthTokenObtainRequestDto).pipe(
      __map(_r => _r.body as AuthTokenObtainResponseDto)
    );
  }

  /**
   * Oauth Auth Revoke Token
   */
  oauthAuthRevokeTokenResponse(): __Observable<__StrictHttpResponse<AuthTokenRevokeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/auth/oauth/token`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthTokenRevokeResponseDto>;
      })
    );
  }
  /**
   * Oauth Auth Revoke Token
   */
  oauthAuthRevokeToken(): __Observable<AuthTokenRevokeResponseDto> {
    return this.oauthAuthRevokeTokenResponse().pipe(
      __map(_r => _r.body as AuthTokenRevokeResponseDto)
    );
  }

  /**
   * Oauth Auth Authorize Direct
   * @param AuthTokenAuthorizeDirectRequestDto undefined
   */
  oauthAuthAuthorizeDirectResponse(AuthTokenAuthorizeDirectRequestDto: AuthTokenAuthorizeDirectRequestDto): __Observable<__StrictHttpResponse<AuthTokenAuthorizeDirectResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AuthTokenAuthorizeDirectRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/oauth/authorize/direct`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthTokenAuthorizeDirectResponseDto>;
      })
    );
  }
  /**
   * Oauth Auth Authorize Direct
   * @param AuthTokenAuthorizeDirectRequestDto undefined
   */
  oauthAuthAuthorizeDirect(AuthTokenAuthorizeDirectRequestDto: AuthTokenAuthorizeDirectRequestDto): __Observable<AuthTokenAuthorizeDirectResponseDto> {
    return this.oauthAuthAuthorizeDirectResponse(AuthTokenAuthorizeDirectRequestDto).pipe(
      __map(_r => _r.body as AuthTokenAuthorizeDirectResponseDto)
    );
  }

  /**
   * Oauth Auth Authorize Direct Authenticate
   * @param params The `ApiService.OauthAuthAuthorizeDirectAuthenticateParams` containing the following parameters:
   *
   * - `authenticationRequestToken`:
   *
   * - `AuthTokenAuthorizeDirectAuthenticateRequestDto`:
   */
  oauthAuthAuthorizeDirectAuthenticateResponse(params: ApiService.OauthAuthAuthorizeDirectAuthenticateParams): __Observable<__StrictHttpResponse<AuthTokenAuthorizeDirectAuthenticateResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.AuthTokenAuthorizeDirectAuthenticateRequestDto;
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/auth/oauth/authorize/direct/${encodeURIComponent(params.authenticationRequestToken)}/authenticate`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthTokenAuthorizeDirectAuthenticateResponseDto>;
      })
    );
  }
  /**
   * Oauth Auth Authorize Direct Authenticate
   * @param params The `ApiService.OauthAuthAuthorizeDirectAuthenticateParams` containing the following parameters:
   *
   * - `authenticationRequestToken`:
   *
   * - `AuthTokenAuthorizeDirectAuthenticateRequestDto`:
   */
  oauthAuthAuthorizeDirectAuthenticate(params: ApiService.OauthAuthAuthorizeDirectAuthenticateParams): __Observable<AuthTokenAuthorizeDirectAuthenticateResponseDto> {
    return this.oauthAuthAuthorizeDirectAuthenticateResponse(params).pipe(
      __map(_r => _r.body as AuthTokenAuthorizeDirectAuthenticateResponseDto)
    );
  }

  /**
   * User Get Profile Basic
   */
  userGetProfileBasicResponse(): __Observable<__StrictHttpResponse<UserProfileBasicDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/profile-basic`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileBasicDto>;
      })
    );
  }
  /**
   * User Get Profile Basic
   */
  userGetProfileBasic(): __Observable<UserProfileBasicDto> {
    return this.userGetProfileBasicResponse().pipe(
      __map(_r => _r.body as UserProfileBasicDto)
    );
  }

  /**
   * User Get Profile
   */
  userGetProfileResponse(): __Observable<__StrictHttpResponse<UserProfileDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/profile`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileDto>;
      })
    );
  }
  /**
   * User Get Profile
   */
  userGetProfile(): __Observable<UserProfileDto> {
    return this.userGetProfileResponse().pipe(
      __map(_r => _r.body as UserProfileDto)
    );
  }

  /**
   * User Update Name
   * @param UserUpdateNameRequestDto undefined
   */
  userUpdateNameResponse(UserUpdateNameRequestDto: UserUpdateNameRequestDto): __Observable<__StrictHttpResponse<UserUpdateNameResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserUpdateNameRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/user/name`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUpdateNameResponseDto>;
      })
    );
  }
  /**
   * User Update Name
   * @param UserUpdateNameRequestDto undefined
   */
  userUpdateName(UserUpdateNameRequestDto: UserUpdateNameRequestDto): __Observable<UserUpdateNameResponseDto> {
    return this.userUpdateNameResponse(UserUpdateNameRequestDto).pipe(
      __map(_r => _r.body as UserUpdateNameResponseDto)
    );
  }

  /**
   * User Add Email Password
   * @param UserAddEmailPasswordDto undefined
   */
  userAddEmailPasswordResponse(UserAddEmailPasswordDto: UserAddEmailPasswordDto): __Observable<__StrictHttpResponse<UserAddEmailPasswordResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserAddEmailPasswordDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/user/add-email-password`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAddEmailPasswordResponseDto>;
      })
    );
  }
  /**
   * User Add Email Password
   * @param UserAddEmailPasswordDto undefined
   */
  userAddEmailPassword(UserAddEmailPasswordDto: UserAddEmailPasswordDto): __Observable<UserAddEmailPasswordResponseDto> {
    return this.userAddEmailPasswordResponse(UserAddEmailPasswordDto).pipe(
      __map(_r => _r.body as UserAddEmailPasswordResponseDto)
    );
  }

  /**
   * User Set Photo
   * @param UserAddPhotoDto undefined
   */
  userSetPhotoResponse(UserAddPhotoDto: UserAddPhotoDto): __Observable<__StrictHttpResponse<UserAddPhotoResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserAddPhotoDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/user/add-photo`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAddPhotoResponseDto>;
      })
    );
  }
  /**
   * User Set Photo
   * @param UserAddPhotoDto undefined
   */
  userSetPhoto(UserAddPhotoDto: UserAddPhotoDto): __Observable<UserAddPhotoResponseDto> {
    return this.userSetPhotoResponse(UserAddPhotoDto).pipe(
      __map(_r => _r.body as UserAddPhotoResponseDto)
    );
  }

  /**
   * User Has Merchant Roles
   */
  userHasMerchantRolesResponse(): __Observable<__StrictHttpResponse<UserHasAnyRolesResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/has-merchant-roles`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserHasAnyRolesResultDto>;
      })
    );
  }
  /**
   * User Has Merchant Roles
   */
  userHasMerchantRoles(): __Observable<UserHasAnyRolesResultDto> {
    return this.userHasMerchantRolesResponse().pipe(
      __map(_r => _r.body as UserHasAnyRolesResultDto)
    );
  }

  /**
   * User Add Card
   * @param UserAddPaymentCardDto undefined
   */
  userAddCardResponse(UserAddPaymentCardDto: UserAddPaymentCardDto): __Observable<__StrictHttpResponse<UserPaymentCardDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserAddPaymentCardDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/user/cards`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPaymentCardDto>;
      })
    );
  }
  /**
   * User Add Card
   * @param UserAddPaymentCardDto undefined
   */
  userAddCard(UserAddPaymentCardDto: UserAddPaymentCardDto): __Observable<UserPaymentCardDto> {
    return this.userAddCardResponse(UserAddPaymentCardDto).pipe(
      __map(_r => _r.body as UserPaymentCardDto)
    );
  }

  /**
   * User Get Cards
   */
  userGetCardsResponse(): __Observable<__StrictHttpResponse<UserGetPaymentCardsResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/cards`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserGetPaymentCardsResponseDto>;
      })
    );
  }
  /**
   * User Get Cards
   */
  userGetCards(): __Observable<UserGetPaymentCardsResponseDto> {
    return this.userGetCardsResponse().pipe(
      __map(_r => _r.body as UserGetPaymentCardsResponseDto)
    );
  }

  /**
   * User Remove One Card
   * @param params The `ApiService.UserRemoveOneCardParams` containing the following parameters:
   *
   * - `cardId`:
   *
   * - `fake`:
   */
  userRemoveOneCardResponse(params: ApiService.UserRemoveOneCardParams): __Observable<__StrictHttpResponse<UserPaymentCardsActionResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.fake != null) __params = __params.set('fake', params.fake.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/user/cards/${encodeURIComponent(params.cardId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPaymentCardsActionResultDto>;
      })
    );
  }
  /**
   * User Remove One Card
   * @param params The `ApiService.UserRemoveOneCardParams` containing the following parameters:
   *
   * - `cardId`:
   *
   * - `fake`:
   */
  userRemoveOneCard(params: ApiService.UserRemoveOneCardParams): __Observable<UserPaymentCardsActionResultDto> {
    return this.userRemoveOneCardResponse(params).pipe(
      __map(_r => _r.body as UserPaymentCardsActionResultDto)
    );
  }

  /**
   * User Get Referral Short Url
   */
  userGetReferralShortUrlResponse(): __Observable<__StrictHttpResponse<UserReferralLinkDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/referral-link`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserReferralLinkDto>;
      })
    );
  }
  /**
   * User Get Referral Short Url
   */
  userGetReferralShortUrl(): __Observable<UserReferralLinkDto> {
    return this.userGetReferralShortUrlResponse().pipe(
      __map(_r => _r.body as UserReferralLinkDto)
    );
  }

  /**
   * User Set Referral Short Url
   * @param UserReferralLinkDto undefined
   */
  userSetReferralShortUrlResponse(UserReferralLinkDto: UserReferralLinkDto): __Observable<__StrictHttpResponse<UserReferralLinkDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserReferralLinkDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/user/referral-link`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserReferralLinkDto>;
      })
    );
  }
  /**
   * User Set Referral Short Url
   * @param UserReferralLinkDto undefined
   */
  userSetReferralShortUrl(UserReferralLinkDto: UserReferralLinkDto): __Observable<UserReferralLinkDto> {
    return this.userSetReferralShortUrlResponse(UserReferralLinkDto).pipe(
      __map(_r => _r.body as UserReferralLinkDto)
    );
  }

  /**
   * User Get Referral Statuses
   */
  userGetReferralStatusesResponse(): __Observable<__StrictHttpResponse<UserReferralStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/referral-status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserReferralStatusDto>;
      })
    );
  }
  /**
   * User Get Referral Statuses
   */
  userGetReferralStatuses(): __Observable<UserReferralStatusDto> {
    return this.userGetReferralStatusesResponse().pipe(
      __map(_r => _r.body as UserReferralStatusDto)
    );
  }

  /**
   * User Get By Referral Code
   * @param code undefined
   */
  userGetByReferralCodeResponse(code: string): __Observable<__StrictHttpResponse<UserMinimalDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/referred-user/${encodeURIComponent(code)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMinimalDto>;
      })
    );
  }
  /**
   * User Get By Referral Code
   * @param code undefined
   */
  userGetByReferralCode(code: string): __Observable<UserMinimalDto> {
    return this.userGetByReferralCodeResponse(code).pipe(
      __map(_r => _r.body as UserMinimalDto)
    );
  }

  /**
   * User Get Referral Code
   */
  userGetReferralCodeResponse(): __Observable<__StrictHttpResponse<UserReferralCodeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/user/referral-code`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserReferralCodeDto>;
      })
    );
  }
  /**
   * User Get Referral Code
   */
  userGetReferralCode(): __Observable<UserReferralCodeDto> {
    return this.userGetReferralCodeResponse().pipe(
      __map(_r => _r.body as UserReferralCodeDto)
    );
  }

  /**
   * User Get Favorite Stores
   */
  userGetFavoriteStoresResponse(): __Observable<__StrictHttpResponse<Array<UserFavoriteStoreDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/favorites/stores`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserFavoriteStoreDto>>;
      })
    );
  }
  /**
   * User Get Favorite Stores
   */
  userGetFavoriteStores(): __Observable<Array<UserFavoriteStoreDto>> {
    return this.userGetFavoriteStoresResponse().pipe(
      __map(_r => _r.body as Array<UserFavoriteStoreDto>)
    );
  }

  /**
   * User Get Invites
   */
  userGetInvitesResponse(): __Observable<__StrictHttpResponse<Array<UserMerchantInviteResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/invites`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserMerchantInviteResponseDto>>;
      })
    );
  }
  /**
   * User Get Invites
   */
  userGetInvites(): __Observable<Array<UserMerchantInviteResponseDto>> {
    return this.userGetInvitesResponse().pipe(
      __map(_r => _r.body as Array<UserMerchantInviteResponseDto>)
    );
  }

  /**
   * User Get Reactivation Promo Status
   */
  userGetReactivationPromoStatusResponse(): __Observable<__StrictHttpResponse<OrderPricingDiscountDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/reactivation-status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderPricingDiscountDto>;
      })
    );
  }
  /**
   * User Get Reactivation Promo Status
   */
  userGetReactivationPromoStatus(): __Observable<OrderPricingDiscountDto> {
    return this.userGetReactivationPromoStatusResponse().pipe(
      __map(_r => _r.body as OrderPricingDiscountDto)
    );
  }

  /**
   * User Get Reward Status
   * @param storeId undefined
   */
  userGetRewardStatusResponse(storeId?: string): __Observable<__StrictHttpResponse<UserRewardStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (storeId != null) __params = __params.set('storeId', storeId.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/user/rewards`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserRewardStatusDto>;
      })
    );
  }
  /**
   * User Get Reward Status
   * @param storeId undefined
   */
  userGetRewardStatus(storeId?: string): __Observable<UserRewardStatusDto> {
    return this.userGetRewardStatusResponse(storeId).pipe(
      __map(_r => _r.body as UserRewardStatusDto)
    );
  }

  /**
   * User Acknowledge Merchant Invite
   * @param params The `ApiService.UserAcknowledgeMerchantInviteParams` containing the following parameters:
   *
   * - `inviteId`:
   *
   * - `UserAcknowledgeMerchantInviteRequestDto`:
   */
  userAcknowledgeMerchantInviteResponse(params: ApiService.UserAcknowledgeMerchantInviteParams): __Observable<__StrictHttpResponse<UserAcknowledgeMerchantInviteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.UserAcknowledgeMerchantInviteRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/user/invite/${encodeURIComponent(params.inviteId)}/acknowledge`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAcknowledgeMerchantInviteResponseDto>;
      })
    );
  }
  /**
   * User Acknowledge Merchant Invite
   * @param params The `ApiService.UserAcknowledgeMerchantInviteParams` containing the following parameters:
   *
   * - `inviteId`:
   *
   * - `UserAcknowledgeMerchantInviteRequestDto`:
   */
  userAcknowledgeMerchantInvite(params: ApiService.UserAcknowledgeMerchantInviteParams): __Observable<UserAcknowledgeMerchantInviteResponseDto> {
    return this.userAcknowledgeMerchantInviteResponse(params).pipe(
      __map(_r => _r.body as UserAcknowledgeMerchantInviteResponseDto)
    );
  }

  /**
   * Pos Device Pair With User Login
   * @param PosDevicePairWithUserLoginRequestDto undefined
   */
  posDevicePairWithUserLoginResponse(PosDevicePairWithUserLoginRequestDto: PosDevicePairWithUserLoginRequestDto): __Observable<__StrictHttpResponse<PosDevicePairResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDevicePairWithUserLoginRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/pair-with-user-login`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDevicePairResponseDto>;
      })
    );
  }
  /**
   * Pos Device Pair With User Login
   * @param PosDevicePairWithUserLoginRequestDto undefined
   */
  posDevicePairWithUserLogin(PosDevicePairWithUserLoginRequestDto: PosDevicePairWithUserLoginRequestDto): __Observable<PosDevicePairResponseDto> {
    return this.posDevicePairWithUserLoginResponse(PosDevicePairWithUserLoginRequestDto).pipe(
      __map(_r => _r.body as PosDevicePairResponseDto)
    );
  }

  /**
   * Pos Device Get Allowed Stores From User Login
   * @param PosDeviceGetAllowedStoresRequest undefined
   */
  posDeviceGetAllowedStoresFromUserLoginResponse(PosDeviceGetAllowedStoresRequest: PosDeviceGetAllowedStoresRequest): __Observable<__StrictHttpResponse<PosDeviceGetAllowedStoresResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceGetAllowedStoresRequest;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/stores-from-user-login`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceGetAllowedStoresResponseDto>;
      })
    );
  }
  /**
   * Pos Device Get Allowed Stores From User Login
   * @param PosDeviceGetAllowedStoresRequest undefined
   */
  posDeviceGetAllowedStoresFromUserLogin(PosDeviceGetAllowedStoresRequest: PosDeviceGetAllowedStoresRequest): __Observable<PosDeviceGetAllowedStoresResponseDto> {
    return this.posDeviceGetAllowedStoresFromUserLoginResponse(PosDeviceGetAllowedStoresRequest).pipe(
      __map(_r => _r.body as PosDeviceGetAllowedStoresResponseDto)
    );
  }

  /**
   * Pos Device Pair
   * @param PosDevicePairRequestDto undefined
   */
  posDevicePairResponse(PosDevicePairRequestDto: PosDevicePairRequestDto): __Observable<__StrictHttpResponse<PosDevicePairResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDevicePairRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/pair`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDevicePairResponseDto>;
      })
    );
  }
  /**
   * Pos Device Pair
   * @param PosDevicePairRequestDto undefined
   */
  posDevicePair(PosDevicePairRequestDto: PosDevicePairRequestDto): __Observable<PosDevicePairResponseDto> {
    return this.posDevicePairResponse(PosDevicePairRequestDto).pipe(
      __map(_r => _r.body as PosDevicePairResponseDto)
    );
  }

  /**
   * Pos Device Set Mode
   * @param PosDeviceSetModeRequestDto undefined
   */
  posDeviceSetModeResponse(PosDeviceSetModeRequestDto: PosDeviceSetModeRequestDto): __Observable<__StrictHttpResponse<PosDeviceSetModeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceSetModeRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/set-mode`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSetModeResponseDto>;
      })
    );
  }
  /**
   * Pos Device Set Mode
   * @param PosDeviceSetModeRequestDto undefined
   */
  posDeviceSetMode(PosDeviceSetModeRequestDto: PosDeviceSetModeRequestDto): __Observable<PosDeviceSetModeResponseDto> {
    return this.posDeviceSetModeResponse(PosDeviceSetModeRequestDto).pipe(
      __map(_r => _r.body as PosDeviceSetModeResponseDto)
    );
  }

  /**
   * Pos Device List
   * @param deviceMode undefined
   */
  posDeviceListResponse(deviceMode: 'pos' | 'consumer' | 'kiosk' | 'pickup_screen'): __Observable<__StrictHttpResponse<Array<MerchantDeviceDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (deviceMode != null) __params = __params.set('deviceMode', deviceMode.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MerchantDeviceDto>>;
      })
    );
  }
  /**
   * Pos Device List
   * @param deviceMode undefined
   */
  posDeviceList(deviceMode: 'pos' | 'consumer' | 'kiosk' | 'pickup_screen'): __Observable<Array<MerchantDeviceDto>> {
    return this.posDeviceListResponse(deviceMode).pipe(
      __map(_r => _r.body as Array<MerchantDeviceDto>)
    );
  }

  /**
   * Pos Device Create Card Reader Connection Token
   * @param PosDeviceGetCardReaderTokenRequestDto undefined
   */
  posDeviceCreateCardReaderConnectionTokenResponse(PosDeviceGetCardReaderTokenRequestDto: PosDeviceGetCardReaderTokenRequestDto): __Observable<__StrictHttpResponse<PosDeviceGetCardReaderTokenResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceGetCardReaderTokenRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/card-reader/connection-token`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceGetCardReaderTokenResponseDto>;
      })
    );
  }
  /**
   * Pos Device Create Card Reader Connection Token
   * @param PosDeviceGetCardReaderTokenRequestDto undefined
   */
  posDeviceCreateCardReaderConnectionToken(PosDeviceGetCardReaderTokenRequestDto: PosDeviceGetCardReaderTokenRequestDto): __Observable<PosDeviceGetCardReaderTokenResponseDto> {
    return this.posDeviceCreateCardReaderConnectionTokenResponse(PosDeviceGetCardReaderTokenRequestDto).pipe(
      __map(_r => _r.body as PosDeviceGetCardReaderTokenResponseDto)
    );
  }

  /**
   * Pos Device Create Card Reader Register
   * @param PosDeviceCardReaderRegisterRequestDto undefined
   */
  posDeviceCreateCardReaderRegisterResponse(PosDeviceCardReaderRegisterRequestDto: PosDeviceCardReaderRegisterRequestDto): __Observable<__StrictHttpResponse<PosDeviceCardReaderRegisterResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceCardReaderRegisterRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/card-reader/register`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceCardReaderRegisterResponseDto>;
      })
    );
  }
  /**
   * Pos Device Create Card Reader Register
   * @param PosDeviceCardReaderRegisterRequestDto undefined
   */
  posDeviceCreateCardReaderRegister(PosDeviceCardReaderRegisterRequestDto: PosDeviceCardReaderRegisterRequestDto): __Observable<PosDeviceCardReaderRegisterResponseDto> {
    return this.posDeviceCreateCardReaderRegisterResponse(PosDeviceCardReaderRegisterRequestDto).pipe(
      __map(_r => _r.body as PosDeviceCardReaderRegisterResponseDto)
    );
  }

  /**
   * Pos Device Create Card Reader Payment Intent
   * @param PosDeviceCreatePaymentIntentRequestDto undefined
   */
  posDeviceCreateCardReaderPaymentIntentResponse(PosDeviceCreatePaymentIntentRequestDto: PosDeviceCreatePaymentIntentRequestDto): __Observable<__StrictHttpResponse<PosDeviceCreatePaymentIntentResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceCreatePaymentIntentRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/card-reader/payment-intent`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceCreatePaymentIntentResponseDto>;
      })
    );
  }
  /**
   * Pos Device Create Card Reader Payment Intent
   * @param PosDeviceCreatePaymentIntentRequestDto undefined
   */
  posDeviceCreateCardReaderPaymentIntent(PosDeviceCreatePaymentIntentRequestDto: PosDeviceCreatePaymentIntentRequestDto): __Observable<PosDeviceCreatePaymentIntentResponseDto> {
    return this.posDeviceCreateCardReaderPaymentIntentResponse(PosDeviceCreatePaymentIntentRequestDto).pipe(
      __map(_r => _r.body as PosDeviceCreatePaymentIntentResponseDto)
    );
  }

  /**
   * Pos Session Set Current Order
   * @param params The `ApiService.PosSessionSetCurrentOrderParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosSessionOrderPutDto`:
   */
  posSessionSetCurrentOrderResponse(params: ApiService.PosSessionSetCurrentOrderParams): __Observable<__StrictHttpResponse<PosSessionOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.PosSessionOrderPutDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/current-order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosSessionOrderDto>;
      })
    );
  }
  /**
   * Pos Session Set Current Order
   * @param params The `ApiService.PosSessionSetCurrentOrderParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosSessionOrderPutDto`:
   */
  posSessionSetCurrentOrder(params: ApiService.PosSessionSetCurrentOrderParams): __Observable<PosSessionOrderDto> {
    return this.posSessionSetCurrentOrderResponse(params).pipe(
      __map(_r => _r.body as PosSessionOrderDto)
    );
  }

  /**
   * Pos Session Get Current Order
   * @param deviceId undefined
   */
  posSessionGetCurrentOrderResponse(deviceId: string): __Observable<__StrictHttpResponse<PosSessionOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device/${encodeURIComponent(deviceId)}/customer-session/current-order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosSessionOrderDto>;
      })
    );
  }
  /**
   * Pos Session Get Current Order
   * @param deviceId undefined
   */
  posSessionGetCurrentOrder(deviceId: string): __Observable<PosSessionOrderDto> {
    return this.posSessionGetCurrentOrderResponse(deviceId).pipe(
      __map(_r => _r.body as PosSessionOrderDto)
    );
  }

  /**
   * Pos Session Set Current Order Tip Amount
   * @param params The `ApiService.PosSessionSetCurrentOrderTipAmountParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosDeviceSessionSetCurrentOrderTipAmountRequestDto`:
   */
  posSessionSetCurrentOrderTipAmountResponse(params: ApiService.PosSessionSetCurrentOrderTipAmountParams): __Observable<__StrictHttpResponse<PosDeviceSessionSetValueResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.PosDeviceSessionSetCurrentOrderTipAmountRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/current-order-tip-amount`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionSetValueResponseDto>;
      })
    );
  }
  /**
   * Pos Session Set Current Order Tip Amount
   * @param params The `ApiService.PosSessionSetCurrentOrderTipAmountParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosDeviceSessionSetCurrentOrderTipAmountRequestDto`:
   */
  posSessionSetCurrentOrderTipAmount(params: ApiService.PosSessionSetCurrentOrderTipAmountParams): __Observable<PosDeviceSessionSetValueResponseDto> {
    return this.posSessionSetCurrentOrderTipAmountResponse(params).pipe(
      __map(_r => _r.body as PosDeviceSessionSetValueResponseDto)
    );
  }

  /**
   * Pos Session Clear
   * @param params The `ApiService.PosSessionClearParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `clearSession`:
   */
  posSessionClearResponse(params: ApiService.PosSessionClearParams): __Observable<__StrictHttpResponse<PosDeviceSessionSetValueResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.clearSession != null) __params = __params.set('clearSession', params.clearSession.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/clear`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionSetValueResponseDto>;
      })
    );
  }
  /**
   * Pos Session Clear
   * @param params The `ApiService.PosSessionClearParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `clearSession`:
   */
  posSessionClear(params: ApiService.PosSessionClearParams): __Observable<PosDeviceSessionSetValueResponseDto> {
    return this.posSessionClearResponse(params).pipe(
      __map(_r => _r.body as PosDeviceSessionSetValueResponseDto)
    );
  }

  /**
   * Pos Session Get Order Session
   * @param params The `ApiService.PosSessionGetOrderSessionParams` containing the following parameters:
   *
   * - `orderSessionId`:
   *
   * - `deviceId`:
   */
  posSessionGetOrderSessionResponse(params: ApiService.PosSessionGetOrderSessionParams): __Observable<__StrictHttpResponse<PosSessionOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/order-session/${encodeURIComponent(params.orderSessionId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosSessionOrderDto>;
      })
    );
  }
  /**
   * Pos Session Get Order Session
   * @param params The `ApiService.PosSessionGetOrderSessionParams` containing the following parameters:
   *
   * - `orderSessionId`:
   *
   * - `deviceId`:
   */
  posSessionGetOrderSession(params: ApiService.PosSessionGetOrderSessionParams): __Observable<PosSessionOrderDto> {
    return this.posSessionGetOrderSessionResponse(params).pipe(
      __map(_r => _r.body as PosSessionOrderDto)
    );
  }

  /**
   * Pos Session Delete Order Session
   * @param params The `ApiService.PosSessionDeleteOrderSessionParams` containing the following parameters:
   *
   * - `orderSessionId`:
   *
   * - `deviceId`:
   */
  posSessionDeleteOrderSessionResponse(params: ApiService.PosSessionDeleteOrderSessionParams): __Observable<__StrictHttpResponse<PosDeviceSessionSetValueResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/order-session/${encodeURIComponent(params.orderSessionId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionSetValueResponseDto>;
      })
    );
  }
  /**
   * Pos Session Delete Order Session
   * @param params The `ApiService.PosSessionDeleteOrderSessionParams` containing the following parameters:
   *
   * - `orderSessionId`:
   *
   * - `deviceId`:
   */
  posSessionDeleteOrderSession(params: ApiService.PosSessionDeleteOrderSessionParams): __Observable<PosDeviceSessionSetValueResponseDto> {
    return this.posSessionDeleteOrderSessionResponse(params).pipe(
      __map(_r => _r.body as PosDeviceSessionSetValueResponseDto)
    );
  }

  /**
   * Pos Session Set Current Customer From Phone
   * @param params The `ApiService.PosSessionSetCurrentCustomerFromPhoneParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosDeviceSessionSetCustomerFromPhoneRequestDto`:
   *
   * - `sessionId`:
   */
  posSessionSetCurrentCustomerFromPhoneResponse(params: ApiService.PosSessionSetCurrentCustomerFromPhoneParams): __Observable<__StrictHttpResponse<PosDeviceSessionCustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.PosDeviceSessionSetCustomerFromPhoneRequestDto;
    if (params.sessionId != null) __params = __params.set('sessionId', params.sessionId.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/current-customer-from-phone`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionCustomerDto>;
      })
    );
  }
  /**
   * Pos Session Set Current Customer From Phone
   * @param params The `ApiService.PosSessionSetCurrentCustomerFromPhoneParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosDeviceSessionSetCustomerFromPhoneRequestDto`:
   *
   * - `sessionId`:
   */
  posSessionSetCurrentCustomerFromPhone(params: ApiService.PosSessionSetCurrentCustomerFromPhoneParams): __Observable<PosDeviceSessionCustomerDto> {
    return this.posSessionSetCurrentCustomerFromPhoneResponse(params).pipe(
      __map(_r => _r.body as PosDeviceSessionCustomerDto)
    );
  }

  /**
   * Pos Session Get Current Customer
   * @param deviceId undefined
   */
  posSessionGetCurrentCustomerResponse(deviceId: string): __Observable<__StrictHttpResponse<PosDeviceSessionCustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device/${encodeURIComponent(deviceId)}/customer-session/current-customer`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionCustomerDto>;
      })
    );
  }
  /**
   * Pos Session Get Current Customer
   * @param deviceId undefined
   */
  posSessionGetCurrentCustomer(deviceId: string): __Observable<PosDeviceSessionCustomerDto> {
    return this.posSessionGetCurrentCustomerResponse(deviceId).pipe(
      __map(_r => _r.body as PosDeviceSessionCustomerDto)
    );
  }

  /**
   * Pos Session Set Current Customer From Mobile Device
   * @param deviceId undefined
   */
  posSessionSetCurrentCustomerFromMobileDeviceResponse(deviceId: string): __Observable<__StrictHttpResponse<PosDeviceSessionSetCustomerResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(deviceId)}/customer-session/current-customer-from-mobile-device`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionSetCustomerResponseDto>;
      })
    );
  }
  /**
   * Pos Session Set Current Customer From Mobile Device
   * @param deviceId undefined
   */
  posSessionSetCurrentCustomerFromMobileDevice(deviceId: string): __Observable<PosDeviceSessionSetCustomerResponseDto> {
    return this.posSessionSetCurrentCustomerFromMobileDeviceResponse(deviceId).pipe(
      __map(_r => _r.body as PosDeviceSessionSetCustomerResponseDto)
    );
  }

  /**
   * Pos Session Customer Get Current Order
   * @param params The `ApiService.PosSessionCustomerGetCurrentOrderParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `deviceId`:
   */
  posSessionCustomerGetCurrentOrderResponse(params: ApiService.PosSessionCustomerGetCurrentOrderParams): __Observable<__StrictHttpResponse<PosSessionOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/store/${encodeURIComponent(params.storeId)}/customer-current-order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosSessionOrderDto>;
      })
    );
  }
  /**
   * Pos Session Customer Get Current Order
   * @param params The `ApiService.PosSessionCustomerGetCurrentOrderParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `deviceId`:
   */
  posSessionCustomerGetCurrentOrder(params: ApiService.PosSessionCustomerGetCurrentOrderParams): __Observable<PosSessionOrderDto> {
    return this.posSessionCustomerGetCurrentOrderResponse(params).pipe(
      __map(_r => _r.body as PosSessionOrderDto)
    );
  }

  /**
   * Pos Session Current Order Complete
   * @param params The `ApiService.PosSessionCurrentOrderCompleteParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosSessionOrderCompleteRequestDto`:
   */
  posSessionCurrentOrderCompleteResponse(params: ApiService.PosSessionCurrentOrderCompleteParams): __Observable<__StrictHttpResponse<PosSessionOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.PosSessionOrderCompleteRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/current-order/complete`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosSessionOrderDto>;
      })
    );
  }
  /**
   * Pos Session Current Order Complete
   * @param params The `ApiService.PosSessionCurrentOrderCompleteParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `PosSessionOrderCompleteRequestDto`:
   */
  posSessionCurrentOrderComplete(params: ApiService.PosSessionCurrentOrderCompleteParams): __Observable<PosSessionOrderDto> {
    return this.posSessionCurrentOrderCompleteResponse(params).pipe(
      __map(_r => _r.body as PosSessionOrderDto)
    );
  }

  /**
   * Pos Session Customer Transfer Order To Mobile
   * @param params The `ApiService.PosSessionCustomerTransferOrderToMobileParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `deviceId`:
   */
  posSessionCustomerTransferOrderToMobileResponse(params: ApiService.PosSessionCustomerTransferOrderToMobileParams): __Observable<__StrictHttpResponse<PosSessionOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/store/${encodeURIComponent(params.storeId)}/transfer-order-to-mobile`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosSessionOrderDto>;
      })
    );
  }
  /**
   * Pos Session Customer Transfer Order To Mobile
   * @param params The `ApiService.PosSessionCustomerTransferOrderToMobileParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `deviceId`:
   */
  posSessionCustomerTransferOrderToMobile(params: ApiService.PosSessionCustomerTransferOrderToMobileParams): __Observable<PosSessionOrderDto> {
    return this.posSessionCustomerTransferOrderToMobileResponse(params).pipe(
      __map(_r => _r.body as PosSessionOrderDto)
    );
  }

  /**
   * Pos Session Device Pending Order Clear
   * @param params The `ApiService.PosSessionDevicePendingOrderClearParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `deviceId`:
   */
  posSessionDevicePendingOrderClearResponse(params: ApiService.PosSessionDevicePendingOrderClearParams): __Observable<__StrictHttpResponse<PosDeviceSessionSetValueResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/pos-device/${encodeURIComponent(params.deviceId)}/customer-session/pending-order-for-user/${encodeURIComponent(params.userId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceSessionSetValueResponseDto>;
      })
    );
  }
  /**
   * Pos Session Device Pending Order Clear
   * @param params The `ApiService.PosSessionDevicePendingOrderClearParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `deviceId`:
   */
  posSessionDevicePendingOrderClear(params: ApiService.PosSessionDevicePendingOrderClearParams): __Observable<PosDeviceSessionSetValueResponseDto> {
    return this.posSessionDevicePendingOrderClearResponse(params).pipe(
      __map(_r => _r.body as PosDeviceSessionSetValueResponseDto)
    );
  }

  /**
   * Pos Device Cash Till Open
   * @param PosDeviceCashTillOpenRequestDto undefined
   */
  posDeviceCashTillOpenResponse(PosDeviceCashTillOpenRequestDto: PosDeviceCashTillOpenRequestDto): __Observable<__StrictHttpResponse<PosDeviceGenericResultResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceCashTillOpenRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/cash-till/open`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceGenericResultResponseDto>;
      })
    );
  }
  /**
   * Pos Device Cash Till Open
   * @param PosDeviceCashTillOpenRequestDto undefined
   */
  posDeviceCashTillOpen(PosDeviceCashTillOpenRequestDto: PosDeviceCashTillOpenRequestDto): __Observable<PosDeviceGenericResultResponseDto> {
    return this.posDeviceCashTillOpenResponse(PosDeviceCashTillOpenRequestDto).pipe(
      __map(_r => _r.body as PosDeviceGenericResultResponseDto)
    );
  }

  /**
   * Pos Device Cash Till Close
   * @param PosDeviceCashTillCloseRequestDto undefined
   */
  posDeviceCashTillCloseResponse(PosDeviceCashTillCloseRequestDto: PosDeviceCashTillCloseRequestDto): __Observable<__StrictHttpResponse<PosDeviceGenericResultResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PosDeviceCashTillCloseRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/pos-device/cash-till/close`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceGenericResultResponseDto>;
      })
    );
  }
  /**
   * Pos Device Cash Till Close
   * @param PosDeviceCashTillCloseRequestDto undefined
   */
  posDeviceCashTillClose(PosDeviceCashTillCloseRequestDto: PosDeviceCashTillCloseRequestDto): __Observable<PosDeviceGenericResultResponseDto> {
    return this.posDeviceCashTillCloseResponse(PosDeviceCashTillCloseRequestDto).pipe(
      __map(_r => _r.body as PosDeviceGenericResultResponseDto)
    );
  }

  /**
   * Pos Device Cash Till Transaction Summary
   * @param tillId undefined
   */
  posDeviceCashTillTransactionSummaryResponse(tillId: string): __Observable<__StrictHttpResponse<PosDeviceCashTillTransactionSummaryResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device/cash-till/transactions/${encodeURIComponent(tillId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceCashTillTransactionSummaryResponseDto>;
      })
    );
  }
  /**
   * Pos Device Cash Till Transaction Summary
   * @param tillId undefined
   */
  posDeviceCashTillTransactionSummary(tillId: string): __Observable<PosDeviceCashTillTransactionSummaryResponseDto> {
    return this.posDeviceCashTillTransactionSummaryResponse(tillId).pipe(
      __map(_r => _r.body as PosDeviceCashTillTransactionSummaryResponseDto)
    );
  }

  /**
   * Pos Device All Till Transaction Summary
   * @param tillId undefined
   */
  posDeviceAllTillTransactionSummaryResponse(tillId: string): __Observable<__StrictHttpResponse<PosDeviceTillTransactionSummaryResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/pos-device/cash-till/transactions-all/${encodeURIComponent(tillId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosDeviceTillTransactionSummaryResponseDto>;
      })
    );
  }
  /**
   * Pos Device All Till Transaction Summary
   * @param tillId undefined
   */
  posDeviceAllTillTransactionSummary(tillId: string): __Observable<PosDeviceTillTransactionSummaryResponseDto> {
    return this.posDeviceAllTillTransactionSummaryResponse(tillId).pipe(
      __map(_r => _r.body as PosDeviceTillTransactionSummaryResponseDto)
    );
  }

  /**
   * Report Create
   * @param ReportRequestDto undefined
   */
  reportCreateResponse(ReportRequestDto: ReportRequestDto): __Observable<__StrictHttpResponse<ReportCreateResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ReportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportCreateResponseDto>;
      })
    );
  }
  /**
   * Report Create
   * @param ReportRequestDto undefined
   */
  reportCreate(ReportRequestDto: ReportRequestDto): __Observable<ReportCreateResponseDto> {
    return this.reportCreateResponse(ReportRequestDto).pipe(
      __map(_r => _r.body as ReportCreateResponseDto)
    );
  }

  /**
   * Report Get Daily Summary Device V2
   * @param params The `ApiService.ReportGetDailySummaryDeviceV2Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `date`:
   */
  reportGetDailySummaryDeviceV2Response(params: ApiService.ReportGetDailySummaryDeviceV2Params): __Observable<__StrictHttpResponse<Array<DailySummaryDeviceRowDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/report/get-daily-summary-device/${encodeURIComponent(params.storeId)}/${encodeURIComponent(params.date)}/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DailySummaryDeviceRowDto>>;
      })
    );
  }
  /**
   * Report Get Daily Summary Device V2
   * @param params The `ApiService.ReportGetDailySummaryDeviceV2Params` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `date`:
   */
  reportGetDailySummaryDeviceV2(params: ApiService.ReportGetDailySummaryDeviceV2Params): __Observable<Array<DailySummaryDeviceRowDto>> {
    return this.reportGetDailySummaryDeviceV2Response(params).pipe(
      __map(_r => _r.body as Array<DailySummaryDeviceRowDto>)
    );
  }

  /**
   * Report Get Daily Summary Device
   * @param params The `ApiService.ReportGetDailySummaryDeviceParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `date`:
   */
  reportGetDailySummaryDeviceResponse(params: ApiService.ReportGetDailySummaryDeviceParams): __Observable<__StrictHttpResponse<ReportCreateResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/report/get-daily-summary-device/${encodeURIComponent(params.storeId)}/${encodeURIComponent(params.date)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportCreateResponseDto>;
      })
    );
  }
  /**
   * Report Get Daily Summary Device
   * @param params The `ApiService.ReportGetDailySummaryDeviceParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `date`:
   */
  reportGetDailySummaryDevice(params: ApiService.ReportGetDailySummaryDeviceParams): __Observable<ReportCreateResponseDto> {
    return this.reportGetDailySummaryDeviceResponse(params).pipe(
      __map(_r => _r.body as ReportCreateResponseDto)
    );
  }

  /**
   * Report Get Internal Reports
   * @param InternalReportRequestDto undefined
   */
  reportGetInternalReportsResponse(InternalReportRequestDto: InternalReportRequestDto): __Observable<__StrictHttpResponse<ReportCreateResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = InternalReportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/report/internal`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportCreateResponseDto>;
      })
    );
  }
  /**
   * Report Get Internal Reports
   * @param InternalReportRequestDto undefined
   */
  reportGetInternalReports(InternalReportRequestDto: InternalReportRequestDto): __Observable<ReportCreateResponseDto> {
    return this.reportGetInternalReportsResponse(InternalReportRequestDto).pipe(
      __map(_r => _r.body as ReportCreateResponseDto)
    );
  }

  /**
   * Merchant Dashboard V2
   * @param MerchantDashboardReportRequestDto undefined
   */
  merchantDashboardV2Response(MerchantDashboardReportRequestDto: MerchantDashboardReportRequestDto): __Observable<__StrictHttpResponse<MerchantReportV2Dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = MerchantDashboardReportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/report/merchant-dashboard-v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MerchantReportV2Dto>;
      })
    );
  }
  /**
   * Merchant Dashboard V2
   * @param MerchantDashboardReportRequestDto undefined
   */
  merchantDashboardV2(MerchantDashboardReportRequestDto: MerchantDashboardReportRequestDto): __Observable<MerchantReportV2Dto> {
    return this.merchantDashboardV2Response(MerchantDashboardReportRequestDto).pipe(
      __map(_r => _r.body as MerchantReportV2Dto)
    );
  }

  /**
   * Admin Refund Order Hack This Is Bad
   * @param orderId undefined
   */
  adminRefundOrderHackThisIsBadResponse(orderId: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/refund-order-hack-bad/${encodeURIComponent(orderId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Admin Refund Order Hack This Is Bad
   * @param orderId undefined
   */
  adminRefundOrderHackThisIsBad(orderId: string): __Observable<any> {
    return this.adminRefundOrderHackThisIsBadResponse(orderId).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * Admin Get Order
   * @param orderId undefined
   */
  adminGetOrderResponse(orderId: string): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-order/${encodeURIComponent(orderId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Admin Get Order
   * @param orderId undefined
   */
  adminGetOrder(orderId: string): __Observable<OrderViewDto> {
    return this.adminGetOrderResponse(orderId).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Admin Get Active Users
   * @param period undefined
   */
  adminGetActiveUsersResponse(period: 'day' | 'week' | 'month'): __Observable<__StrictHttpResponse<Array<AdminBiActiveUsersDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (period != null) __params = __params.set('period', period.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-active-users`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminBiActiveUsersDto>>;
      })
    );
  }
  /**
   * Admin Get Active Users
   * @param period undefined
   */
  adminGetActiveUsers(period: 'day' | 'week' | 'month'): __Observable<Array<AdminBiActiveUsersDto>> {
    return this.adminGetActiveUsersResponse(period).pipe(
      __map(_r => _r.body as Array<AdminBiActiveUsersDto>)
    );
  }

  /**
   * Admin Refund Order
   * @param AdminOrderRefundDto undefined
   */
  adminRefundOrderResponse(AdminOrderRefundDto: AdminOrderRefundDto): __Observable<__StrictHttpResponse<OrderViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminOrderRefundDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/admin/joe-admin/refund-order`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewDto>;
      })
    );
  }
  /**
   * Admin Refund Order
   * @param AdminOrderRefundDto undefined
   */
  adminRefundOrder(AdminOrderRefundDto: AdminOrderRefundDto): __Observable<OrderViewDto> {
    return this.adminRefundOrderResponse(AdminOrderRefundDto).pipe(
      __map(_r => _r.body as OrderViewDto)
    );
  }

  /**
   * Admin Find Users
   * @param params The `ApiService.AdminFindUsersParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `lastName`:
   *
   * - `id`:
   *
   * - `firstName`:
   *
   * - `email`:
   */
  adminFindUsersResponse(params: ApiService.AdminFindUsersParams): __Observable<__StrictHttpResponse<Array<AdminUserSearchResultEntryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/find-users`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminUserSearchResultEntryDto>>;
      })
    );
  }
  /**
   * Admin Find Users
   * @param params The `ApiService.AdminFindUsersParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `lastName`:
   *
   * - `id`:
   *
   * - `firstName`:
   *
   * - `email`:
   */
  adminFindUsers(params: ApiService.AdminFindUsersParams): __Observable<Array<AdminUserSearchResultEntryDto>> {
    return this.adminFindUsersResponse(params).pipe(
      __map(_r => _r.body as Array<AdminUserSearchResultEntryDto>)
    );
  }

  /**
   * Admin Update User Email
   * @param AdminUserEmailRequestDto undefined
   */
  adminUpdateUserEmailResponse(AdminUserEmailRequestDto: AdminUserEmailRequestDto): __Observable<__StrictHttpResponse<AdminUserEmailResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminUserEmailRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/update-user-email`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminUserEmailResponseDto>;
      })
    );
  }
  /**
   * Admin Update User Email
   * @param AdminUserEmailRequestDto undefined
   */
  adminUpdateUserEmail(AdminUserEmailRequestDto: AdminUserEmailRequestDto): __Observable<AdminUserEmailResponseDto> {
    return this.adminUpdateUserEmailResponse(AdminUserEmailRequestDto).pipe(
      __map(_r => _r.body as AdminUserEmailResponseDto)
    );
  }

  /**
   * Admin Find Gift Card
   * @param email undefined
   */
  adminFindGiftCardResponse(email?: string): __Observable<__StrictHttpResponse<Array<AdminGiftCardSearchResultEntryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/find-gift-card`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminGiftCardSearchResultEntryDto>>;
      })
    );
  }
  /**
   * Admin Find Gift Card
   * @param email undefined
   */
  adminFindGiftCard(email?: string): __Observable<Array<AdminGiftCardSearchResultEntryDto>> {
    return this.adminFindGiftCardResponse(email).pipe(
      __map(_r => _r.body as Array<AdminGiftCardSearchResultEntryDto>)
    );
  }

  /**
   * Admin Find Orders
   * @param params The `ApiService.AdminFindOrdersParams` containing the following parameters:
   *
   * - `timeStart`:
   *
   * - `timeEnd`:
   *
   * - `storeId`:
   *
   * - `id`:
   *
   * - `customerId`:
   */
  adminFindOrdersResponse(params: ApiService.AdminFindOrdersParams): __Observable<__StrictHttpResponse<Array<AdminOrderSearchResultEntryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeStart != null) __params = __params.set('timeStart', params.timeStart.toString());
    if (params.timeEnd != null) __params = __params.set('timeEnd', params.timeEnd.toString());
    if (params.storeId != null) __params = __params.set('storeId', params.storeId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.customerId != null) __params = __params.set('customerId', params.customerId.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/find-orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminOrderSearchResultEntryDto>>;
      })
    );
  }
  /**
   * Admin Find Orders
   * @param params The `ApiService.AdminFindOrdersParams` containing the following parameters:
   *
   * - `timeStart`:
   *
   * - `timeEnd`:
   *
   * - `storeId`:
   *
   * - `id`:
   *
   * - `customerId`:
   */
  adminFindOrders(params: ApiService.AdminFindOrdersParams): __Observable<Array<AdminOrderSearchResultEntryDto>> {
    return this.adminFindOrdersResponse(params).pipe(
      __map(_r => _r.body as Array<AdminOrderSearchResultEntryDto>)
    );
  }

  /**
   * Admin Get Active Stores
   * @param period undefined
   */
  adminGetActiveStoresResponse(period: 'day' | 'week' | 'month'): __Observable<__StrictHttpResponse<Array<AdminBiActiveStoresDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (period != null) __params = __params.set('period', period.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-active-stores`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminBiActiveStoresDto>>;
      })
    );
  }
  /**
   * Admin Get Active Stores
   * @param period undefined
   */
  adminGetActiveStores(period: 'day' | 'week' | 'month'): __Observable<Array<AdminBiActiveStoresDto>> {
    return this.adminGetActiveStoresResponse(period).pipe(
      __map(_r => _r.body as Array<AdminBiActiveStoresDto>)
    );
  }

  /**
   * Admin Get Joe Bucks Activity
   * @param period undefined
   */
  adminGetJoeBucksActivityResponse(period: 'day' | 'week' | 'month'): __Observable<__StrictHttpResponse<AdminBiGetJoeBucksActivityDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (period != null) __params = __params.set('period', period.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-joe-bucks-activity`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminBiGetJoeBucksActivityDto>;
      })
    );
  }
  /**
   * Admin Get Joe Bucks Activity
   * @param period undefined
   */
  adminGetJoeBucksActivity(period: 'day' | 'week' | 'month'): __Observable<AdminBiGetJoeBucksActivityDto> {
    return this.adminGetJoeBucksActivityResponse(period).pipe(
      __map(_r => _r.body as AdminBiGetJoeBucksActivityDto)
    );
  }

  /**
   * Admin Get Referral History
   * @param userId undefined
   */
  adminGetReferralHistoryResponse(userId: string): __Observable<__StrictHttpResponse<Array<AdminGetUserReferralsDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-referral-history/${encodeURIComponent(userId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminGetUserReferralsDto>>;
      })
    );
  }
  /**
   * Admin Get Referral History
   * @param userId undefined
   */
  adminGetReferralHistory(userId: string): __Observable<Array<AdminGetUserReferralsDto>> {
    return this.adminGetReferralHistoryResponse(userId).pipe(
      __map(_r => _r.body as Array<AdminGetUserReferralsDto>)
    );
  }

  /**
   * Admin Set Referrer
   * @param params The `ApiService.AdminSetReferrerParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `referringUserId`:
   */
  adminSetReferrerResponse(params: ApiService.AdminSetReferrerParams): __Observable<__StrictHttpResponse<AdminSetReferrerResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/set-referrer/${encodeURIComponent(params.userId)}/${encodeURIComponent(params.referringUserId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSetReferrerResponseDto>;
      })
    );
  }
  /**
   * Admin Set Referrer
   * @param params The `ApiService.AdminSetReferrerParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `referringUserId`:
   */
  adminSetReferrer(params: ApiService.AdminSetReferrerParams): __Observable<AdminSetReferrerResponseDto> {
    return this.adminSetReferrerResponse(params).pipe(
      __map(_r => _r.body as AdminSetReferrerResponseDto)
    );
  }

  /**
   * Admin Bypass Store Phone Verification
   * @param storeId undefined
   */
  adminBypassStorePhoneVerificationResponse(storeId: string): __Observable<__StrictHttpResponse<AdminSetReferrerResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/store/${encodeURIComponent(storeId)}/bypass-phone-verification`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSetReferrerResponseDto>;
      })
    );
  }
  /**
   * Admin Bypass Store Phone Verification
   * @param storeId undefined
   */
  adminBypassStorePhoneVerification(storeId: string): __Observable<AdminSetReferrerResponseDto> {
    return this.adminBypassStorePhoneVerificationResponse(storeId).pipe(
      __map(_r => _r.body as AdminSetReferrerResponseDto)
    );
  }

  /**
   * Admin Get Order Totals
   * @param period undefined
   */
  adminGetOrderTotalsResponse(period: 'day' | 'week' | 'month'): __Observable<__StrictHttpResponse<Array<AdminBiOrderTotalsDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (period != null) __params = __params.set('period', period.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/order-totals`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminBiOrderTotalsDto>>;
      })
    );
  }
  /**
   * Admin Get Order Totals
   * @param period undefined
   */
  adminGetOrderTotals(period: 'day' | 'week' | 'month'): __Observable<Array<AdminBiOrderTotalsDto>> {
    return this.adminGetOrderTotalsResponse(period).pipe(
      __map(_r => _r.body as Array<AdminBiOrderTotalsDto>)
    );
  }

  /**
   * Admin Get User Weekly Cohort Totals
   * @param params The `ApiService.AdminGetUserWeeklyCohortTotalsParams` containing the following parameters:
   *
   * - `period`:
   *
   * - `endWeek`:
   */
  adminGetUserWeeklyCohortTotalsResponse(params: ApiService.AdminGetUserWeeklyCohortTotalsParams): __Observable<__StrictHttpResponse<Array<AdminBiUserWeeklyCohortTotalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.period != null) __params = __params.set('period', params.period.toString());
    if (params.endWeek != null) __params = __params.set('endWeek', params.endWeek.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/user-weekly-cohort-totals-v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminBiUserWeeklyCohortTotalDto>>;
      })
    );
  }
  /**
   * Admin Get User Weekly Cohort Totals
   * @param params The `ApiService.AdminGetUserWeeklyCohortTotalsParams` containing the following parameters:
   *
   * - `period`:
   *
   * - `endWeek`:
   */
  adminGetUserWeeklyCohortTotals(params: ApiService.AdminGetUserWeeklyCohortTotalsParams): __Observable<Array<AdminBiUserWeeklyCohortTotalDto>> {
    return this.adminGetUserWeeklyCohortTotalsResponse(params).pipe(
      __map(_r => _r.body as Array<AdminBiUserWeeklyCohortTotalDto>)
    );
  }

  /**
   * Admin Get Stores With Status
   */
  adminGetStoresWithStatusResponse(): __Observable<__StrictHttpResponse<Array<AdminGetStoresWithStatusDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-stores-with-status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminGetStoresWithStatusDto>>;
      })
    );
  }
  /**
   * Admin Get Stores With Status
   */
  adminGetStoresWithStatus(): __Observable<Array<AdminGetStoresWithStatusDto>> {
    return this.adminGetStoresWithStatusResponse().pipe(
      __map(_r => _r.body as Array<AdminGetStoresWithStatusDto>)
    );
  }

  /**
   * Admin Search Stores With Status
   * @param params The `ApiService.AdminSearchStoresWithStatusParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `id`:
   */
  adminSearchStoresWithStatusResponse(params: ApiService.AdminSearchStoresWithStatusParams): __Observable<__StrictHttpResponse<Array<AdminGetStoresWithStatusDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/search-stores-with-status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminGetStoresWithStatusDto>>;
      })
    );
  }
  /**
   * Admin Search Stores With Status
   * @param params The `ApiService.AdminSearchStoresWithStatusParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `id`:
   */
  adminSearchStoresWithStatus(params: ApiService.AdminSearchStoresWithStatusParams): __Observable<Array<AdminGetStoresWithStatusDto>> {
    return this.adminSearchStoresWithStatusResponse(params).pipe(
      __map(_r => _r.body as Array<AdminGetStoresWithStatusDto>)
    );
  }

  /**
   * Admin Send Consumer Marketing User Push
   * @param AdminMarketingPushDto undefined
   */
  adminSendConsumerMarketingUserPushResponse(AdminMarketingPushDto: AdminMarketingPushDto): __Observable<__StrictHttpResponse<AdminSendMerchantTransferResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminMarketingPushDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/send-consumer-marketing-push`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSendMerchantTransferResponseDto>;
      })
    );
  }
  /**
   * Admin Send Consumer Marketing User Push
   * @param AdminMarketingPushDto undefined
   */
  adminSendConsumerMarketingUserPush(AdminMarketingPushDto: AdminMarketingPushDto): __Observable<AdminSendMerchantTransferResponseDto> {
    return this.adminSendConsumerMarketingUserPushResponse(AdminMarketingPushDto).pipe(
      __map(_r => _r.body as AdminSendMerchantTransferResponseDto)
    );
  }

  /**
   * Admin Send Consumer Marketing Orphan Push
   * @param AdminMarketingPushDto undefined
   */
  adminSendConsumerMarketingOrphanPushResponse(AdminMarketingPushDto: AdminMarketingPushDto): __Observable<__StrictHttpResponse<AdminSendMerchantTransferResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminMarketingPushDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/send-consumer-marketing-orphan`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSendMerchantTransferResponseDto>;
      })
    );
  }
  /**
   * Admin Send Consumer Marketing Orphan Push
   * @param AdminMarketingPushDto undefined
   */
  adminSendConsumerMarketingOrphanPush(AdminMarketingPushDto: AdminMarketingPushDto): __Observable<AdminSendMerchantTransferResponseDto> {
    return this.adminSendConsumerMarketingOrphanPushResponse(AdminMarketingPushDto).pipe(
      __map(_r => _r.body as AdminSendMerchantTransferResponseDto)
    );
  }

  /**
   * Admin Send Consumer Marketing Single Push
   * @param params The `ApiService.AdminSendConsumerMarketingSinglePushParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `AdminMarketingPushDto`:
   */
  adminSendConsumerMarketingSinglePushResponse(params: ApiService.AdminSendConsumerMarketingSinglePushParams): __Observable<__StrictHttpResponse<AdminSendMerchantTransferResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.AdminMarketingPushDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/send-consumer-marketing-single/${encodeURIComponent(params.userId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSendMerchantTransferResponseDto>;
      })
    );
  }
  /**
   * Admin Send Consumer Marketing Single Push
   * @param params The `ApiService.AdminSendConsumerMarketingSinglePushParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `AdminMarketingPushDto`:
   */
  adminSendConsumerMarketingSinglePush(params: ApiService.AdminSendConsumerMarketingSinglePushParams): __Observable<AdminSendMerchantTransferResponseDto> {
    return this.adminSendConsumerMarketingSinglePushResponse(params).pipe(
      __map(_r => _r.body as AdminSendMerchantTransferResponseDto)
    );
  }

  /**
   * Admin Get Merchant Transfers Old
   */
  adminGetMerchantTransfersOldResponse(): __Observable<__StrictHttpResponse<Array<AdminMerchantTransferDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-merchant-transfers`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminMerchantTransferDto>>;
      })
    );
  }
  /**
   * Admin Get Merchant Transfers Old
   */
  adminGetMerchantTransfersOld(): __Observable<Array<AdminMerchantTransferDto>> {
    return this.adminGetMerchantTransfersOldResponse().pipe(
      __map(_r => _r.body as Array<AdminMerchantTransferDto>)
    );
  }

  /**
   * Admin Get Merchant Transfers
   */
  adminGetMerchantTransfersResponse(): __Observable<__StrictHttpResponse<Array<MerchantTransferDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/get-merchant-transfers/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MerchantTransferDto>>;
      })
    );
  }
  /**
   * Admin Get Merchant Transfers
   */
  adminGetMerchantTransfers(): __Observable<Array<MerchantTransferDto>> {
    return this.adminGetMerchantTransfersResponse().pipe(
      __map(_r => _r.body as Array<MerchantTransferDto>)
    );
  }

  /**
   * Admin Send Merchant Transfer Transfer
   * @param transferId undefined
   */
  adminSendMerchantTransferTransferResponse(transferId: string): __Observable<__StrictHttpResponse<AdminSendMerchantTransferResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/send-merchant-transfer-transfer/${encodeURIComponent(transferId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSendMerchantTransferResponseDto>;
      })
    );
  }
  /**
   * Admin Send Merchant Transfer Transfer
   * @param transferId undefined
   */
  adminSendMerchantTransferTransfer(transferId: string): __Observable<AdminSendMerchantTransferResponseDto> {
    return this.adminSendMerchantTransferTransferResponse(transferId).pipe(
      __map(_r => _r.body as AdminSendMerchantTransferResponseDto)
    );
  }

  /**
   * Admin Send Merchant Transfer Email
   * @param transferId undefined
   */
  adminSendMerchantTransferEmailResponse(transferId: string): __Observable<__StrictHttpResponse<AdminSendMerchantTransferResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/send-merchant-transfer-email/${encodeURIComponent(transferId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSendMerchantTransferResponseDto>;
      })
    );
  }
  /**
   * Admin Send Merchant Transfer Email
   * @param transferId undefined
   */
  adminSendMerchantTransferEmail(transferId: string): __Observable<AdminSendMerchantTransferResponseDto> {
    return this.adminSendMerchantTransferEmailResponse(transferId).pipe(
      __map(_r => _r.body as AdminSendMerchantTransferResponseDto)
    );
  }

  /**
   * Admin Send Merchant Transfer Email Test
   * @param transferId undefined
   */
  adminSendMerchantTransferEmailTestResponse(transferId: string): __Observable<__StrictHttpResponse<AdminSendMerchantTransferResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/send-merchant-transfer-email-test/${encodeURIComponent(transferId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSendMerchantTransferResponseDto>;
      })
    );
  }
  /**
   * Admin Send Merchant Transfer Email Test
   * @param transferId undefined
   */
  adminSendMerchantTransferEmailTest(transferId: string): __Observable<AdminSendMerchantTransferResponseDto> {
    return this.adminSendMerchantTransferEmailTestResponse(transferId).pipe(
      __map(_r => _r.body as AdminSendMerchantTransferResponseDto)
    );
  }

  /**
   * Admin User Reward Status Get
   * @param userId undefined
   */
  adminUserRewardStatusGetResponse(userId: string): __Observable<__StrictHttpResponse<UserRewardStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/user/${encodeURIComponent(userId)}/rewards`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserRewardStatusDto>;
      })
    );
  }
  /**
   * Admin User Reward Status Get
   * @param userId undefined
   */
  adminUserRewardStatusGet(userId: string): __Observable<UserRewardStatusDto> {
    return this.adminUserRewardStatusGetResponse(userId).pipe(
      __map(_r => _r.body as UserRewardStatusDto)
    );
  }

  /**
   * Admin User Balance Get
   * @param userId undefined
   */
  adminUserBalanceGetResponse(userId: string): __Observable<__StrictHttpResponse<AdminUserBalanceDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/user/${encodeURIComponent(userId)}/balance`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminUserBalanceDto>;
      })
    );
  }
  /**
   * Admin User Balance Get
   * @param userId undefined
   */
  adminUserBalanceGet(userId: string): __Observable<AdminUserBalanceDto> {
    return this.adminUserBalanceGetResponse(userId).pipe(
      __map(_r => _r.body as AdminUserBalanceDto)
    );
  }

  /**
   * Admin Network Status Territory Create
   * @param AdminCreateNetworkStatusTerritoryDto undefined
   */
  adminNetworkStatusTerritoryCreateResponse(AdminCreateNetworkStatusTerritoryDto: AdminCreateNetworkStatusTerritoryDto): __Observable<__StrictHttpResponse<AdminNetworkStatusTerritoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminCreateNetworkStatusTerritoryDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/network-status-territory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminNetworkStatusTerritoryDto>;
      })
    );
  }
  /**
   * Admin Network Status Territory Create
   * @param AdminCreateNetworkStatusTerritoryDto undefined
   */
  adminNetworkStatusTerritoryCreate(AdminCreateNetworkStatusTerritoryDto: AdminCreateNetworkStatusTerritoryDto): __Observable<AdminNetworkStatusTerritoryDto> {
    return this.adminNetworkStatusTerritoryCreateResponse(AdminCreateNetworkStatusTerritoryDto).pipe(
      __map(_r => _r.body as AdminNetworkStatusTerritoryDto)
    );
  }

  /**
   * Admin Network Status Territory List
   */
  adminNetworkStatusTerritoryListResponse(): __Observable<__StrictHttpResponse<Array<AdminNetworkStatusTerritoryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/network-status-territory`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminNetworkStatusTerritoryDto>>;
      })
    );
  }
  /**
   * Admin Network Status Territory List
   */
  adminNetworkStatusTerritoryList(): __Observable<Array<AdminNetworkStatusTerritoryDto>> {
    return this.adminNetworkStatusTerritoryListResponse().pipe(
      __map(_r => _r.body as Array<AdminNetworkStatusTerritoryDto>)
    );
  }

  /**
   * Admin Network Status Territory Update
   * @param params The `ApiService.AdminNetworkStatusTerritoryUpdateParams` containing the following parameters:
   *
   * - `territoryId`:
   *
   * - `AdminCreateNetworkStatusTerritoryDto`:
   */
  adminNetworkStatusTerritoryUpdateResponse(params: ApiService.AdminNetworkStatusTerritoryUpdateParams): __Observable<__StrictHttpResponse<AdminNetworkStatusTerritoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.AdminCreateNetworkStatusTerritoryDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/admin/joe-admin/network-status-territory/${encodeURIComponent(params.territoryId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminNetworkStatusTerritoryDto>;
      })
    );
  }
  /**
   * Admin Network Status Territory Update
   * @param params The `ApiService.AdminNetworkStatusTerritoryUpdateParams` containing the following parameters:
   *
   * - `territoryId`:
   *
   * - `AdminCreateNetworkStatusTerritoryDto`:
   */
  adminNetworkStatusTerritoryUpdate(params: ApiService.AdminNetworkStatusTerritoryUpdateParams): __Observable<AdminNetworkStatusTerritoryDto> {
    return this.adminNetworkStatusTerritoryUpdateResponse(params).pipe(
      __map(_r => _r.body as AdminNetworkStatusTerritoryDto)
    );
  }

  /**
   * Admin Network Status Territory Get
   * @param territoryId undefined
   */
  adminNetworkStatusTerritoryGetResponse(territoryId: string): __Observable<__StrictHttpResponse<AdminNetworkStatusTerritoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/network-status-territory/${encodeURIComponent(territoryId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminNetworkStatusTerritoryDto>;
      })
    );
  }
  /**
   * Admin Network Status Territory Get
   * @param territoryId undefined
   */
  adminNetworkStatusTerritoryGet(territoryId: string): __Observable<AdminNetworkStatusTerritoryDto> {
    return this.adminNetworkStatusTerritoryGetResponse(territoryId).pipe(
      __map(_r => _r.body as AdminNetworkStatusTerritoryDto)
    );
  }

  /**
   * Admin Network Status Territory Delete
   * @param territoryId undefined
   */
  adminNetworkStatusTerritoryDeleteResponse(territoryId: string): __Observable<__StrictHttpResponse<AdminNetworkStatusTerritoryDeleteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/admin/joe-admin/network-status-territory/${encodeURIComponent(territoryId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminNetworkStatusTerritoryDeleteResponseDto>;
      })
    );
  }
  /**
   * Admin Network Status Territory Delete
   * @param territoryId undefined
   */
  adminNetworkStatusTerritoryDelete(territoryId: string): __Observable<AdminNetworkStatusTerritoryDeleteResponseDto> {
    return this.adminNetworkStatusTerritoryDeleteResponse(territoryId).pipe(
      __map(_r => _r.body as AdminNetworkStatusTerritoryDeleteResponseDto)
    );
  }

  /**
   * Admin Network Status Territory Get Details
   * @param params The `ApiService.AdminNetworkStatusTerritoryGetDetailsParams` containing the following parameters:
   *
   * - `territoryId`:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   *
   * - `includeStores`:
   */
  adminNetworkStatusTerritoryGetDetailsResponse(params: ApiService.AdminNetworkStatusTerritoryGetDetailsParams): __Observable<__StrictHttpResponse<AdminNetworkStatusTerritoryDetailsDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.periodStart != null) __params = __params.set('periodStart', params.periodStart.toString());
    if (params.periodEnd != null) __params = __params.set('periodEnd', params.periodEnd.toString());
    if (params.includeStores != null) __params = __params.set('includeStores', params.includeStores.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/network-status-territory-details/${encodeURIComponent(params.territoryId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminNetworkStatusTerritoryDetailsDto>;
      })
    );
  }
  /**
   * Admin Network Status Territory Get Details
   * @param params The `ApiService.AdminNetworkStatusTerritoryGetDetailsParams` containing the following parameters:
   *
   * - `territoryId`:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   *
   * - `includeStores`:
   */
  adminNetworkStatusTerritoryGetDetails(params: ApiService.AdminNetworkStatusTerritoryGetDetailsParams): __Observable<AdminNetworkStatusTerritoryDetailsDto> {
    return this.adminNetworkStatusTerritoryGetDetailsResponse(params).pipe(
      __map(_r => _r.body as AdminNetworkStatusTerritoryDetailsDto)
    );
  }

  /**
   * Admin Network Status Territory List With Details
   * @param params The `ApiService.AdminNetworkStatusTerritoryListWithDetailsParams` containing the following parameters:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   */
  adminNetworkStatusTerritoryListWithDetailsResponse(params: ApiService.AdminNetworkStatusTerritoryListWithDetailsParams): __Observable<__StrictHttpResponse<Array<AdminNetworkStatusTerritoryDetailsDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.periodStart != null) __params = __params.set('periodStart', params.periodStart.toString());
    if (params.periodEnd != null) __params = __params.set('periodEnd', params.periodEnd.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/network-status-territory-details`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminNetworkStatusTerritoryDetailsDto>>;
      })
    );
  }
  /**
   * Admin Network Status Territory List With Details
   * @param params The `ApiService.AdminNetworkStatusTerritoryListWithDetailsParams` containing the following parameters:
   *
   * - `periodStart`:
   *
   * - `periodEnd`:
   */
  adminNetworkStatusTerritoryListWithDetails(params: ApiService.AdminNetworkStatusTerritoryListWithDetailsParams): __Observable<Array<AdminNetworkStatusTerritoryDetailsDto>> {
    return this.adminNetworkStatusTerritoryListWithDetailsResponse(params).pipe(
      __map(_r => _r.body as Array<AdminNetworkStatusTerritoryDetailsDto>)
    );
  }

  /**
   * Admin Set Store Pos Payment Enabled
   * @param params The `ApiService.AdminSetStorePosPaymentEnabledParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `AdminSetPosPaymentEnabledDto`:
   */
  adminSetStorePosPaymentEnabledResponse(params: ApiService.AdminSetStorePosPaymentEnabledParams): __Observable<__StrictHttpResponse<AdminSetStoreFeeOverrideResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.AdminSetPosPaymentEnabledDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/store/${encodeURIComponent(params.storeId)}/set-pos-payment-enabled`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSetStoreFeeOverrideResponseDto>;
      })
    );
  }
  /**
   * Admin Set Store Pos Payment Enabled
   * @param params The `ApiService.AdminSetStorePosPaymentEnabledParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `AdminSetPosPaymentEnabledDto`:
   */
  adminSetStorePosPaymentEnabled(params: ApiService.AdminSetStorePosPaymentEnabledParams): __Observable<AdminSetStoreFeeOverrideResponseDto> {
    return this.adminSetStorePosPaymentEnabledResponse(params).pipe(
      __map(_r => _r.body as AdminSetStoreFeeOverrideResponseDto)
    );
  }

  /**
   * Admin Set Store Fee Override
   * @param params The `ApiService.AdminSetStoreFeeOverrideParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `AdminSetStoreFeeOverrideDto`:
   */
  adminSetStoreFeeOverrideResponse(params: ApiService.AdminSetStoreFeeOverrideParams): __Observable<__StrictHttpResponse<AdminSetStoreFeeOverrideResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.AdminSetStoreFeeOverrideDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/store/${encodeURIComponent(params.storeId)}/set-fee-override`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSetStoreFeeOverrideResponseDto>;
      })
    );
  }
  /**
   * Admin Set Store Fee Override
   * @param params The `ApiService.AdminSetStoreFeeOverrideParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `AdminSetStoreFeeOverrideDto`:
   */
  adminSetStoreFeeOverride(params: ApiService.AdminSetStoreFeeOverrideParams): __Observable<AdminSetStoreFeeOverrideResponseDto> {
    return this.adminSetStoreFeeOverrideResponse(params).pipe(
      __map(_r => _r.body as AdminSetStoreFeeOverrideResponseDto)
    );
  }

  /**
   * Admin Set Company Approval
   * @param AdminSetCompanyApprovalDto undefined
   */
  adminSetCompanyApprovalResponse(AdminSetCompanyApprovalDto: AdminSetCompanyApprovalDto): __Observable<__StrictHttpResponse<AdminSetCompanyApprovalResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminSetCompanyApprovalDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/set-company-approval`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminSetCompanyApprovalResponseDto>;
      })
    );
  }
  /**
   * Admin Set Company Approval
   * @param AdminSetCompanyApprovalDto undefined
   */
  adminSetCompanyApproval(AdminSetCompanyApprovalDto: AdminSetCompanyApprovalDto): __Observable<AdminSetCompanyApprovalResponseDto> {
    return this.adminSetCompanyApprovalResponse(AdminSetCompanyApprovalDto).pipe(
      __map(_r => _r.body as AdminSetCompanyApprovalResponseDto)
    );
  }

  /**
   * Admin Menu Transfer To Company
   * @param params The `ApiService.AdminMenuTransferToCompanyParams` containing the following parameters:
   *
   * - `toCompanyId`:
   *
   * - `menuId`:
   */
  adminMenuTransferToCompanyResponse(params: ApiService.AdminMenuTransferToCompanyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/admin/joe-admin/${encodeURIComponent(params.menuId)}/transfer-to/${encodeURIComponent(params.toCompanyId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Admin Menu Transfer To Company
   * @param params The `ApiService.AdminMenuTransferToCompanyParams` containing the following parameters:
   *
   * - `toCompanyId`:
   *
   * - `menuId`:
   */
  adminMenuTransferToCompany(params: ApiService.AdminMenuTransferToCompanyParams): __Observable<null> {
    return this.adminMenuTransferToCompanyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Admin Get Store Offline Report
   * @param AdminStoreOfflineReportRequestDto undefined
   */
  adminGetStoreOfflineReportResponse(AdminStoreOfflineReportRequestDto: AdminStoreOfflineReportRequestDto): __Observable<__StrictHttpResponse<StoreOfflineReportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminStoreOfflineReportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/store-offline-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoreOfflineReportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Store Offline Report
   * @param AdminStoreOfflineReportRequestDto undefined
   */
  adminGetStoreOfflineReport(AdminStoreOfflineReportRequestDto: AdminStoreOfflineReportRequestDto): __Observable<StoreOfflineReportResponseDto> {
    return this.adminGetStoreOfflineReportResponse(AdminStoreOfflineReportRequestDto).pipe(
      __map(_r => _r.body as StoreOfflineReportResponseDto)
    );
  }

  /**
   * Admin Merge Users
   * @param AdminMergeUsersRequestDto undefined
   */
  adminMergeUsersResponse(AdminMergeUsersRequestDto: AdminMergeUsersRequestDto): __Observable<__StrictHttpResponse<AdminMergeUsersResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminMergeUsersRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/user-merge`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminMergeUsersResponseDto>;
      })
    );
  }
  /**
   * Admin Merge Users
   * @param AdminMergeUsersRequestDto undefined
   */
  adminMergeUsers(AdminMergeUsersRequestDto: AdminMergeUsersRequestDto): __Observable<AdminMergeUsersResponseDto> {
    return this.adminMergeUsersResponse(AdminMergeUsersRequestDto).pipe(
      __map(_r => _r.body as AdminMergeUsersResponseDto)
    );
  }

  /**
   * Admin Reset User Password
   * @param AdminResetUserPasswordDto undefined
   */
  adminResetUserPasswordResponse(AdminResetUserPasswordDto: AdminResetUserPasswordDto): __Observable<__StrictHttpResponse<AdminResetUserPasswordResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminResetUserPasswordDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/user-reset-password`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminResetUserPasswordResponseDto>;
      })
    );
  }
  /**
   * Admin Reset User Password
   * @param AdminResetUserPasswordDto undefined
   */
  adminResetUserPassword(AdminResetUserPasswordDto: AdminResetUserPasswordDto): __Observable<AdminResetUserPasswordResponseDto> {
    return this.adminResetUserPasswordResponse(AdminResetUserPasswordDto).pipe(
      __map(_r => _r.body as AdminResetUserPasswordResponseDto)
    );
  }

  /**
   * Admin Reset User
   * @param userId undefined
   */
  adminResetUserResponse(userId: string): __Observable<__StrictHttpResponse<AdminUserSearchResultEntryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/user-reset/${encodeURIComponent(userId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminUserSearchResultEntryDto>;
      })
    );
  }
  /**
   * Admin Reset User
   * @param userId undefined
   */
  adminResetUser(userId: string): __Observable<AdminUserSearchResultEntryDto> {
    return this.adminResetUserResponse(userId).pipe(
      __map(_r => _r.body as AdminUserSearchResultEntryDto)
    );
  }

  /**
   * Admin Refund Remaining User Balance
   * @param userId undefined
   */
  adminRefundRemainingUserBalanceResponse(userId: string): __Observable<__StrictHttpResponse<AdminMergeUsersResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/user-refund-remaining-joebucks/${encodeURIComponent(userId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminMergeUsersResponseDto>;
      })
    );
  }
  /**
   * Admin Refund Remaining User Balance
   * @param userId undefined
   */
  adminRefundRemainingUserBalance(userId: string): __Observable<AdminMergeUsersResponseDto> {
    return this.adminRefundRemainingUserBalanceResponse(userId).pipe(
      __map(_r => _r.body as AdminMergeUsersResponseDto)
    );
  }

  /**
   * Admin Get Global Promos
   * @param targetType undefined
   */
  adminGetGlobalPromosResponse(targetType?: string): __Observable<__StrictHttpResponse<Array<PromoDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (targetType != null) __params = __params.set('targetType', targetType.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/joe-admin/get-global-promos`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PromoDto>>;
      })
    );
  }
  /**
   * Admin Get Global Promos
   * @param targetType undefined
   */
  adminGetGlobalPromos(targetType?: string): __Observable<Array<PromoDto>> {
    return this.adminGetGlobalPromosResponse(targetType).pipe(
      __map(_r => _r.body as Array<PromoDto>)
    );
  }

  /**
   * Admin Get User Weekly Cohort Totals Deprecated
   * @param endWeek undefined
   */
  adminGetUserWeeklyCohortTotalsDeprecatedResponse(endWeek?: string): __Observable<__StrictHttpResponse<Array<AdminBiUserWeeklyCohortTotalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (endWeek != null) __params = __params.set('endWeek', endWeek.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/user-weekly-cohort-totals`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminBiUserWeeklyCohortTotalDto>>;
      })
    );
  }
  /**
   * Admin Get User Weekly Cohort Totals Deprecated
   * @param endWeek undefined
   */
  adminGetUserWeeklyCohortTotalsDeprecated(endWeek?: string): __Observable<Array<AdminBiUserWeeklyCohortTotalDto>> {
    return this.adminGetUserWeeklyCohortTotalsDeprecatedResponse(endWeek).pipe(
      __map(_r => _r.body as Array<AdminBiUserWeeklyCohortTotalDto>)
    );
  }

  /**
   * Admin Get User Reactivation Cohorts Deprecated
   */
  adminGetUserReactivationCohortsDeprecatedResponse(): __Observable<__StrictHttpResponse<Array<AdminBiUserWeeklyCohortTotalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/admin/joe-admin/user-reactivation-cohorts`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdminBiUserWeeklyCohortTotalDto>>;
      })
    );
  }
  /**
   * Admin Get User Reactivation Cohorts Deprecated
   */
  adminGetUserReactivationCohortsDeprecated(): __Observable<Array<AdminBiUserWeeklyCohortTotalDto>> {
    return this.adminGetUserReactivationCohortsDeprecatedResponse().pipe(
      __map(_r => _r.body as Array<AdminBiUserWeeklyCohortTotalDto>)
    );
  }

  /**
   * Admin Get Store Status Report
   * @param AdminStoreStatusExportRequestDto undefined
   */
  adminGetStoreStatusReportResponse(AdminStoreStatusExportRequestDto: AdminStoreStatusExportRequestDto): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminStoreStatusExportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/store-status-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Store Status Report
   * @param AdminStoreStatusExportRequestDto undefined
   */
  adminGetStoreStatusReport(AdminStoreStatusExportRequestDto: AdminStoreStatusExportRequestDto): __Observable<AdminExportResponseDto> {
    return this.adminGetStoreStatusReportResponse(AdminStoreStatusExportRequestDto).pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Get Merchant Device Report
   */
  adminGetMerchantDeviceReportResponse(): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/merchant-device-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Merchant Device Report
   */
  adminGetMerchantDeviceReport(): __Observable<AdminExportResponseDto> {
    return this.adminGetMerchantDeviceReportResponse().pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Get Square Integration Report
   */
  adminGetSquareIntegrationReportResponse(): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/square-integration-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Square Integration Report
   */
  adminGetSquareIntegrationReport(): __Observable<AdminExportResponseDto> {
    return this.adminGetSquareIntegrationReportResponse().pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Get Promo Status Report
   * @param AdminPromoStatusExportRequestDto undefined
   */
  adminGetPromoStatusReportResponse(AdminPromoStatusExportRequestDto: AdminPromoStatusExportRequestDto): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminPromoStatusExportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/promo-status-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Promo Status Report
   * @param AdminPromoStatusExportRequestDto undefined
   */
  adminGetPromoStatusReport(AdminPromoStatusExportRequestDto: AdminPromoStatusExportRequestDto): __Observable<AdminExportResponseDto> {
    return this.adminGetPromoStatusReportResponse(AdminPromoStatusExportRequestDto).pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Get Store Customer Feedback Report
   * @param AdminStoreCustomerFeedbackExportRequestDto undefined
   */
  adminGetStoreCustomerFeedbackReportResponse(AdminStoreCustomerFeedbackExportRequestDto: AdminStoreCustomerFeedbackExportRequestDto): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminStoreCustomerFeedbackExportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/store-customer-feedback-report`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Store Customer Feedback Report
   * @param AdminStoreCustomerFeedbackExportRequestDto undefined
   */
  adminGetStoreCustomerFeedbackReport(AdminStoreCustomerFeedbackExportRequestDto: AdminStoreCustomerFeedbackExportRequestDto): __Observable<AdminExportResponseDto> {
    return this.adminGetStoreCustomerFeedbackReportResponse(AdminStoreCustomerFeedbackExportRequestDto).pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Get Accounting Report Csv
   * @param AdminAccountingReportExportRequestDto undefined
   */
  adminGetAccountingReportCsvResponse(AdminAccountingReportExportRequestDto: AdminAccountingReportExportRequestDto): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdminAccountingReportExportRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/joe-admin/accounting-report-csv`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Get Accounting Report Csv
   * @param AdminAccountingReportExportRequestDto undefined
   */
  adminGetAccountingReportCsv(AdminAccountingReportExportRequestDto: AdminAccountingReportExportRequestDto): __Observable<AdminExportResponseDto> {
    return this.adminGetAccountingReportCsvResponse(AdminAccountingReportExportRequestDto).pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Store Export
   */
  adminStoreExportResponse(): __Observable<__StrictHttpResponse<AdminExportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/admin/export/store-export`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminExportResponseDto>;
      })
    );
  }
  /**
   * Admin Store Export
   */
  adminStoreExport(): __Observable<AdminExportResponseDto> {
    return this.adminStoreExportResponse().pipe(
      __map(_r => _r.body as AdminExportResponseDto)
    );
  }

  /**
   * Admin Get Current Status V2
   * @param date undefined
   */
  adminGetCurrentStatusV2Response(date?: string): __Observable<__StrictHttpResponse<AdminBiCurrentStatusV2Dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/bi-role/joe-admin/get-current-status/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminBiCurrentStatusV2Dto>;
      })
    );
  }
  /**
   * Admin Get Current Status V2
   * @param date undefined
   */
  adminGetCurrentStatusV2(date?: string): __Observable<AdminBiCurrentStatusV2Dto> {
    return this.adminGetCurrentStatusV2Response(date).pipe(
      __map(_r => _r.body as AdminBiCurrentStatusV2Dto)
    );
  }

  /**
   * Admin Get Recent Orders
   * @param since undefined
   */
  adminGetRecentOrdersResponse(since: string): __Observable<__StrictHttpResponse<Array<OrderDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (since != null) __params = __params.set('since', since.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/bi-role/joe-admin/recent-orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderDto>>;
      })
    );
  }
  /**
   * Admin Get Recent Orders
   * @param since undefined
   */
  adminGetRecentOrders(since: string): __Observable<Array<OrderDto>> {
    return this.adminGetRecentOrdersResponse(since).pipe(
      __map(_r => _r.body as Array<OrderDto>)
    );
  }

  /**
   * Admin Get Stores Nearby
   */
  adminGetStoresNearbyResponse(): __Observable<__StrictHttpResponse<Array<StoreSearchStoreDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/bi-role/joe-admin/stores-nearby`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreSearchStoreDto>>;
      })
    );
  }
  /**
   * Admin Get Stores Nearby
   */
  adminGetStoresNearby(): __Observable<Array<StoreSearchStoreDto>> {
    return this.adminGetStoresNearbyResponse().pipe(
      __map(_r => _r.body as Array<StoreSearchStoreDto>)
    );
  }

  /**
   * Admin Get Current Status
   * @param date undefined
   */
  adminGetCurrentStatusResponse(date?: string): __Observable<__StrictHttpResponse<AdminBiCurrentStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/bi-role/joe-admin/get-current-status`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdminBiCurrentStatusDto>;
      })
    );
  }
  /**
   * Admin Get Current Status
   * @param date undefined
   */
  adminGetCurrentStatus(date?: string): __Observable<AdminBiCurrentStatusDto> {
    return this.adminGetCurrentStatusResponse(date).pipe(
      __map(_r => _r.body as AdminBiCurrentStatusDto)
    );
  }

  /**
   * Company Dashboard
   * @param params The `ApiService.CompanyDashboardParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `date`:
   */
  companyDashboardResponse(params: ApiService.CompanyDashboardParams): __Observable<__StrictHttpResponse<CompanyDashboardDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.date != null) __params = __params.set('date', params.date.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/dashboard`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDashboardDto>;
      })
    );
  }
  /**
   * Company Dashboard
   * @param params The `ApiService.CompanyDashboardParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `date`:
   */
  companyDashboard(params: ApiService.CompanyDashboardParams): __Observable<CompanyDashboardDto> {
    return this.companyDashboardResponse(params).pipe(
      __map(_r => _r.body as CompanyDashboardDto)
    );
  }

  /**
   * Company List
   */
  companyListResponse(): __Observable<__StrictHttpResponse<Array<CompanyDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyDto>>;
      })
    );
  }
  /**
   * Company List
   */
  companyList(): __Observable<Array<CompanyDto>> {
    return this.companyListResponse().pipe(
      __map(_r => _r.body as Array<CompanyDto>)
    );
  }

  /**
   * Company Create
   * @param CompanyDto undefined
   */
  companyCreateResponse(CompanyDto: CompanyDto): __Observable<__StrictHttpResponse<CompanyDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = CompanyDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDto>;
      })
    );
  }
  /**
   * Company Create
   * @param CompanyDto undefined
   */
  companyCreate(CompanyDto: CompanyDto): __Observable<CompanyDto> {
    return this.companyCreateResponse(CompanyDto).pipe(
      __map(_r => _r.body as CompanyDto)
    );
  }

  /**
   * Company Get Landing Page From Slug
   * @param params The `ApiService.CompanyGetLandingPageFromSlugParams` containing the following parameters:
   *
   * - `companySlug`:
   *
   * - `lon`:
   *
   * - `lat`:
   */
  companyGetLandingPageFromSlugResponse(params: ApiService.CompanyGetLandingPageFromSlugParams): __Observable<__StrictHttpResponse<CompanyLandingDataDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.lon != null) __params = __params.set('lon', params.lon.toString());
    if (params.lat != null) __params = __params.set('lat', params.lat.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companySlug)}/landing-page-data`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyLandingDataDto>;
      })
    );
  }
  /**
   * Company Get Landing Page From Slug
   * @param params The `ApiService.CompanyGetLandingPageFromSlugParams` containing the following parameters:
   *
   * - `companySlug`:
   *
   * - `lon`:
   *
   * - `lat`:
   */
  companyGetLandingPageFromSlug(params: ApiService.CompanyGetLandingPageFromSlugParams): __Observable<CompanyLandingDataDto> {
    return this.companyGetLandingPageFromSlugResponse(params).pipe(
      __map(_r => _r.body as CompanyLandingDataDto)
    );
  }

  /**
   * Company Landing Add Hero Background
   * @param params The `ApiService.CompanyLandingAddHeroBackgroundParams` containing the following parameters:
   *
   * - `y`:
   *
   * - `x`:
   *
   * - `width`:
   *
   * - `scale`:
   *
   * - `height`:
   *
   * - `file`:
   *
   * - `companyId`:
   */
  companyLandingAddHeroBackgroundResponse(params: ApiService.CompanyLandingAddHeroBackgroundParams): __Observable<__StrictHttpResponse<CompanyAddImageResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.y != null) __params = __params.set('y', params.y.toString());
    if (params.x != null) __params = __params.set('x', params.x.toString());
    if (params.width != null) __params = __params.set('width', params.width.toString());
    if (params.scale != null) __params = __params.set('scale', params.scale.toString());
    if (params.height != null) __params = __params.set('height', params.height.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/add-landing-hero-background`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddImageResponseDto>;
      })
    );
  }
  /**
   * Company Landing Add Hero Background
   * @param params The `ApiService.CompanyLandingAddHeroBackgroundParams` containing the following parameters:
   *
   * - `y`:
   *
   * - `x`:
   *
   * - `width`:
   *
   * - `scale`:
   *
   * - `height`:
   *
   * - `file`:
   *
   * - `companyId`:
   */
  companyLandingAddHeroBackground(params: ApiService.CompanyLandingAddHeroBackgroundParams): __Observable<CompanyAddImageResponseDto> {
    return this.companyLandingAddHeroBackgroundResponse(params).pipe(
      __map(_r => _r.body as CompanyAddImageResponseDto)
    );
  }

  /**
   * Company Landing Add Logo
   * @param params The `ApiService.CompanyLandingAddLogoParams` containing the following parameters:
   *
   * - `file`:
   *
   * - `companyId`:
   */
  companyLandingAddLogoResponse(params: ApiService.CompanyLandingAddLogoParams): __Observable<__StrictHttpResponse<CompanyAddImageResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/add-landing-logo`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddImageResponseDto>;
      })
    );
  }
  /**
   * Company Landing Add Logo
   * @param params The `ApiService.CompanyLandingAddLogoParams` containing the following parameters:
   *
   * - `file`:
   *
   * - `companyId`:
   */
  companyLandingAddLogo(params: ApiService.CompanyLandingAddLogoParams): __Observable<CompanyAddImageResponseDto> {
    return this.companyLandingAddLogoResponse(params).pipe(
      __map(_r => _r.body as CompanyAddImageResponseDto)
    );
  }

  /**
   * Company Update
   * @param params The `ApiService.CompanyUpdateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyUpdateDto`:
   */
  companyUpdateResponse(params: ApiService.CompanyUpdateParams): __Observable<__StrictHttpResponse<CompanyDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CompanyUpdateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDto>;
      })
    );
  }
  /**
   * Company Update
   * @param params The `ApiService.CompanyUpdateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyUpdateDto`:
   */
  companyUpdate(params: ApiService.CompanyUpdateParams): __Observable<CompanyDto> {
    return this.companyUpdateResponse(params).pipe(
      __map(_r => _r.body as CompanyDto)
    );
  }

  /**
   * Company Get
   * @param companyId undefined
   */
  companyGetResponse(companyId: string): __Observable<__StrictHttpResponse<CompanyViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyViewDto>;
      })
    );
  }
  /**
   * Company Get
   * @param companyId undefined
   */
  companyGet(companyId: string): __Observable<CompanyViewDto> {
    return this.companyGetResponse(companyId).pipe(
      __map(_r => _r.body as CompanyViewDto)
    );
  }

  /**
   * Company Verification
   * @param params The `ApiService.CompanyVerificationParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyVerificationDto`:
   */
  companyVerificationResponse(params: ApiService.CompanyVerificationParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CompanyVerificationDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/verification`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Company Verification
   * @param params The `ApiService.CompanyVerificationParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyVerificationDto`:
   */
  companyVerification(params: ApiService.CompanyVerificationParams): __Observable<any> {
    return this.companyVerificationResponse(params).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * Company Verification V2
   * @param params The `ApiService.CompanyVerificationV2Params` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyVerificationDto`:
   */
  companyVerificationV2Response(params: ApiService.CompanyVerificationV2Params): __Observable<__StrictHttpResponse<CompanyVerificationResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CompanyVerificationDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/verification/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyVerificationResponseDto>;
      })
    );
  }
  /**
   * Company Verification V2
   * @param params The `ApiService.CompanyVerificationV2Params` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyVerificationDto`:
   */
  companyVerificationV2(params: ApiService.CompanyVerificationV2Params): __Observable<CompanyVerificationResponseDto> {
    return this.companyVerificationV2Response(params).pipe(
      __map(_r => _r.body as CompanyVerificationResponseDto)
    );
  }

  /**
   * Company Get Transfers
   * @param companyId undefined
   */
  companyGetTransfersResponse(companyId: string): __Observable<__StrictHttpResponse<Array<MerchantTransferDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/transfers`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MerchantTransferDto>>;
      })
    );
  }
  /**
   * Company Get Transfers
   * @param companyId undefined
   */
  companyGetTransfers(companyId: string): __Observable<Array<MerchantTransferDto>> {
    return this.companyGetTransfersResponse(companyId).pipe(
      __map(_r => _r.body as Array<MerchantTransferDto>)
    );
  }

  /**
   * Company Download Transfer Report
   * @param params The `ApiService.CompanyDownloadTransferReportParams` containing the following parameters:
   *
   * - `transferBatchId`:
   *
   * - `companyId`:
   *
   * - `type`:
   *
   * - `format`:
   */
  companyDownloadTransferReportResponse(params: ApiService.CompanyDownloadTransferReportParams): __Observable<__StrictHttpResponse<MerchantTransferDownloadResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.format != null) __params = __params.set('format', params.format.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/download-transfer/${encodeURIComponent(params.transferBatchId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MerchantTransferDownloadResultDto>;
      })
    );
  }
  /**
   * Company Download Transfer Report
   * @param params The `ApiService.CompanyDownloadTransferReportParams` containing the following parameters:
   *
   * - `transferBatchId`:
   *
   * - `companyId`:
   *
   * - `type`:
   *
   * - `format`:
   */
  companyDownloadTransferReport(params: ApiService.CompanyDownloadTransferReportParams): __Observable<MerchantTransferDownloadResultDto> {
    return this.companyDownloadTransferReportResponse(params).pipe(
      __map(_r => _r.body as MerchantTransferDownloadResultDto)
    );
  }

  /**
   * Company Get Stores
   * @param companyId undefined
   */
  companyGetStoresResponse(companyId: string): __Observable<__StrictHttpResponse<Array<StoreMinimalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/stores`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreMinimalDto>>;
      })
    );
  }
  /**
   * Company Get Stores
   * @param companyId undefined
   */
  companyGetStores(companyId: string): __Observable<Array<StoreMinimalDto>> {
    return this.companyGetStoresResponse(companyId).pipe(
      __map(_r => _r.body as Array<StoreMinimalDto>)
    );
  }

  /**
   * Company Get Users
   * @param companyId undefined
   */
  companyGetUsersResponse(companyId: string): __Observable<__StrictHttpResponse<Array<UserMinimalWithRolesDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/users`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserMinimalWithRolesDto>>;
      })
    );
  }
  /**
   * Company Get Users
   * @param companyId undefined
   */
  companyGetUsers(companyId: string): __Observable<Array<UserMinimalWithRolesDto>> {
    return this.companyGetUsersResponse(companyId).pipe(
      __map(_r => _r.body as Array<UserMinimalWithRolesDto>)
    );
  }

  /**
   * Company Get Menus
   * @param companyId undefined
   */
  companyGetMenusResponse(companyId: string): __Observable<__StrictHttpResponse<Array<MenuMinimalDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/menus`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MenuMinimalDto>>;
      })
    );
  }
  /**
   * Company Get Menus
   * @param companyId undefined
   */
  companyGetMenus(companyId: string): __Observable<Array<MenuMinimalDto>> {
    return this.companyGetMenusResponse(companyId).pipe(
      __map(_r => _r.body as Array<MenuMinimalDto>)
    );
  }

  /**
   * Company Get Landing Page
   * @param companyId undefined
   */
  companyGetLandingPageResponse(companyId: string): __Observable<__StrictHttpResponse<CompanyLandingDataDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/landing-page`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyLandingDataDto>;
      })
    );
  }
  /**
   * Company Get Landing Page
   * @param companyId undefined
   */
  companyGetLandingPage(companyId: string): __Observable<CompanyLandingDataDto> {
    return this.companyGetLandingPageResponse(companyId).pipe(
      __map(_r => _r.body as CompanyLandingDataDto)
    );
  }

  /**
   * Company Invite User
   * @param params The `ApiService.CompanyInviteUserParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyCreateInviteRequestDto`:
   */
  companyInviteUserResponse(params: ApiService.CompanyInviteUserParams): __Observable<__StrictHttpResponse<CompanyCreateInviteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CompanyCreateInviteRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/invite`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyCreateInviteResponseDto>;
      })
    );
  }
  /**
   * Company Invite User
   * @param params The `ApiService.CompanyInviteUserParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyCreateInviteRequestDto`:
   */
  companyInviteUser(params: ApiService.CompanyInviteUserParams): __Observable<CompanyCreateInviteResponseDto> {
    return this.companyInviteUserResponse(params).pipe(
      __map(_r => _r.body as CompanyCreateInviteResponseDto)
    );
  }

  /**
   * Company Get Invites
   * @param companyId undefined
   */
  companyGetInvitesResponse(companyId: string): __Observable<__StrictHttpResponse<Array<UserMerchantInviteResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/invite`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserMerchantInviteResponseDto>>;
      })
    );
  }
  /**
   * Company Get Invites
   * @param companyId undefined
   */
  companyGetInvites(companyId: string): __Observable<Array<UserMerchantInviteResponseDto>> {
    return this.companyGetInvitesResponse(companyId).pipe(
      __map(_r => _r.body as Array<UserMerchantInviteResponseDto>)
    );
  }

  /**
   * Company Delete User Role
   * @param params The `ApiService.CompanyDeleteUserRoleParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `roleName`:
   *
   * - `companyId`:
   */
  companyDeleteUserRoleResponse(params: ApiService.CompanyDeleteUserRoleParams): __Observable<__StrictHttpResponse<CompanyDeleteUserRoleResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/role/${encodeURIComponent(params.userId)}/${encodeURIComponent(params.roleName)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDeleteUserRoleResponseDto>;
      })
    );
  }
  /**
   * Company Delete User Role
   * @param params The `ApiService.CompanyDeleteUserRoleParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `roleName`:
   *
   * - `companyId`:
   */
  companyDeleteUserRole(params: ApiService.CompanyDeleteUserRoleParams): __Observable<CompanyDeleteUserRoleResponseDto> {
    return this.companyDeleteUserRoleResponse(params).pipe(
      __map(_r => _r.body as CompanyDeleteUserRoleResponseDto)
    );
  }

  /**
   * Company Cancel Invite
   * @param params The `ApiService.CompanyCancelInviteParams` containing the following parameters:
   *
   * - `inviteId`:
   *
   * - `companyId`:
   */
  companyCancelInviteResponse(params: ApiService.CompanyCancelInviteParams): __Observable<__StrictHttpResponse<CompanyCancelInviteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/invite/${encodeURIComponent(params.inviteId)}/cancel`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyCancelInviteResponseDto>;
      })
    );
  }
  /**
   * Company Cancel Invite
   * @param params The `ApiService.CompanyCancelInviteParams` containing the following parameters:
   *
   * - `inviteId`:
   *
   * - `companyId`:
   */
  companyCancelInvite(params: ApiService.CompanyCancelInviteParams): __Observable<CompanyCancelInviteResponseDto> {
    return this.companyCancelInviteResponse(params).pipe(
      __map(_r => _r.body as CompanyCancelInviteResponseDto)
    );
  }

  /**
   * Company Report Subscriber List
   * @param companyId undefined
   */
  companyReportSubscriberListResponse(companyId: string): __Observable<__StrictHttpResponse<Array<CompanyReportSubscriberDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/report-subscriber`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyReportSubscriberDto>>;
      })
    );
  }
  /**
   * Company Report Subscriber List
   * @param companyId undefined
   */
  companyReportSubscriberList(companyId: string): __Observable<Array<CompanyReportSubscriberDto>> {
    return this.companyReportSubscriberListResponse(companyId).pipe(
      __map(_r => _r.body as Array<CompanyReportSubscriberDto>)
    );
  }

  /**
   * Company Report Subscriber Create
   * @param params The `ApiService.CompanyReportSubscriberCreateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyReportSubscriberDto`:
   */
  companyReportSubscriberCreateResponse(params: ApiService.CompanyReportSubscriberCreateParams): __Observable<__StrictHttpResponse<CompanyReportSubscriberDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CompanyReportSubscriberDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/report-subscriber`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyReportSubscriberDto>;
      })
    );
  }
  /**
   * Company Report Subscriber Create
   * @param params The `ApiService.CompanyReportSubscriberCreateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyReportSubscriberDto`:
   */
  companyReportSubscriberCreate(params: ApiService.CompanyReportSubscriberCreateParams): __Observable<CompanyReportSubscriberDto> {
    return this.companyReportSubscriberCreateResponse(params).pipe(
      __map(_r => _r.body as CompanyReportSubscriberDto)
    );
  }

  /**
   * Company Report Subscriber Delete
   * @param params The `ApiService.CompanyReportSubscriberDeleteParams` containing the following parameters:
   *
   * - `subscriberId`:
   *
   * - `companyId`:
   */
  companyReportSubscriberDeleteResponse(params: ApiService.CompanyReportSubscriberDeleteParams): __Observable<__StrictHttpResponse<CompanyReportSubscriberDeleteResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/report-subscriber/${encodeURIComponent(params.subscriberId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyReportSubscriberDeleteResultDto>;
      })
    );
  }
  /**
   * Company Report Subscriber Delete
   * @param params The `ApiService.CompanyReportSubscriberDeleteParams` containing the following parameters:
   *
   * - `subscriberId`:
   *
   * - `companyId`:
   */
  companyReportSubscriberDelete(params: ApiService.CompanyReportSubscriberDeleteParams): __Observable<CompanyReportSubscriberDeleteResultDto> {
    return this.companyReportSubscriberDeleteResponse(params).pipe(
      __map(_r => _r.body as CompanyReportSubscriberDeleteResultDto)
    );
  }

  /**
   * Menu Option Get
   * @param params The `ApiService.MenuOptionGetParams` containing the following parameters:
   *
   * - `menuOptionId`:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuOptionGetResponse(params: ApiService.MenuOptionGetParams): __Observable<__StrictHttpResponse<MenuOptionWithChoicesDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/menu-option/${encodeURIComponent(params.menuOptionId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuOptionWithChoicesDto>;
      })
    );
  }
  /**
   * Menu Option Get
   * @param params The `ApiService.MenuOptionGetParams` containing the following parameters:
   *
   * - `menuOptionId`:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuOptionGet(params: ApiService.MenuOptionGetParams): __Observable<MenuOptionWithChoicesDto> {
    return this.menuOptionGetResponse(params).pipe(
      __map(_r => _r.body as MenuOptionWithChoicesDto)
    );
  }

  /**
   * Menu Option Update
   * @param params The `ApiService.MenuOptionUpdateParams` containing the following parameters:
   *
   * - `menuOptionId`:
   *
   * - `menuId`:
   *
   * - `companyId`:
   *
   * - `MenuOptionCreateDto`:
   */
  menuOptionUpdateResponse(params: ApiService.MenuOptionUpdateParams): __Observable<__StrictHttpResponse<MenuOptionWithChoicesDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.MenuOptionCreateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/menu-option/${encodeURIComponent(params.menuOptionId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuOptionWithChoicesDto>;
      })
    );
  }
  /**
   * Menu Option Update
   * @param params The `ApiService.MenuOptionUpdateParams` containing the following parameters:
   *
   * - `menuOptionId`:
   *
   * - `menuId`:
   *
   * - `companyId`:
   *
   * - `MenuOptionCreateDto`:
   */
  menuOptionUpdate(params: ApiService.MenuOptionUpdateParams): __Observable<MenuOptionWithChoicesDto> {
    return this.menuOptionUpdateResponse(params).pipe(
      __map(_r => _r.body as MenuOptionWithChoicesDto)
    );
  }

  /**
   * Menu Option Delete
   * @param params The `ApiService.MenuOptionDeleteParams` containing the following parameters:
   *
   * - `menuOptionId`:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuOptionDeleteResponse(params: ApiService.MenuOptionDeleteParams): __Observable<__StrictHttpResponse<MenuOptionDeleteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/menu-option/${encodeURIComponent(params.menuOptionId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuOptionDeleteResponseDto>;
      })
    );
  }
  /**
   * Menu Option Delete
   * @param params The `ApiService.MenuOptionDeleteParams` containing the following parameters:
   *
   * - `menuOptionId`:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuOptionDelete(params: ApiService.MenuOptionDeleteParams): __Observable<MenuOptionDeleteResponseDto> {
    return this.menuOptionDeleteResponse(params).pipe(
      __map(_r => _r.body as MenuOptionDeleteResponseDto)
    );
  }

  /**
   * Menu Option List
   * @param params The `ApiService.MenuOptionListParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuOptionListResponse(params: ApiService.MenuOptionListParams): __Observable<__StrictHttpResponse<Array<MenuOptionWithChoicesDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/menu-option`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MenuOptionWithChoicesDto>>;
      })
    );
  }
  /**
   * Menu Option List
   * @param params The `ApiService.MenuOptionListParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuOptionList(params: ApiService.MenuOptionListParams): __Observable<Array<MenuOptionWithChoicesDto>> {
    return this.menuOptionListResponse(params).pipe(
      __map(_r => _r.body as Array<MenuOptionWithChoicesDto>)
    );
  }

  /**
   * Menu Option Create
   * @param params The `ApiService.MenuOptionCreateParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   *
   * - `MenuOptionCreateDto`:
   */
  menuOptionCreateResponse(params: ApiService.MenuOptionCreateParams): __Observable<__StrictHttpResponse<MenuOptionWithChoicesDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.MenuOptionCreateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/menu-option`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuOptionWithChoicesDto>;
      })
    );
  }
  /**
   * Menu Option Create
   * @param params The `ApiService.MenuOptionCreateParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   *
   * - `MenuOptionCreateDto`:
   */
  menuOptionCreate(params: ApiService.MenuOptionCreateParams): __Observable<MenuOptionWithChoicesDto> {
    return this.menuOptionCreateResponse(params).pipe(
      __map(_r => _r.body as MenuOptionWithChoicesDto)
    );
  }

  /**
   * Menu Get
   * @param params The `ApiService.MenuGetParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuGetResponse(params: ApiService.MenuGetParams): __Observable<__StrictHttpResponse<MenuIterationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuIterationDto>;
      })
    );
  }
  /**
   * Menu Get
   * @param params The `ApiService.MenuGetParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuGet(params: ApiService.MenuGetParams): __Observable<MenuIterationDto> {
    return this.menuGetResponse(params).pipe(
      __map(_r => _r.body as MenuIterationDto)
    );
  }

  /**
   * Menu Delete
   * @param params The `ApiService.MenuDeleteParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuDeleteResponse(params: ApiService.MenuDeleteParams): __Observable<__StrictHttpResponse<MenuDeleteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuDeleteResponseDto>;
      })
    );
  }
  /**
   * Menu Delete
   * @param params The `ApiService.MenuDeleteParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuDelete(params: ApiService.MenuDeleteParams): __Observable<MenuDeleteResponseDto> {
    return this.menuDeleteResponse(params).pipe(
      __map(_r => _r.body as MenuDeleteResponseDto)
    );
  }

  /**
   * Menu Export Encoded
   * @param params The `ApiService.MenuExportEncodedParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuExportEncodedResponse(params: ApiService.MenuExportEncodedParams): __Observable<__StrictHttpResponse<MenuExportEncodedDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/export/encoded`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuExportEncodedDto>;
      })
    );
  }
  /**
   * Menu Export Encoded
   * @param params The `ApiService.MenuExportEncodedParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuExportEncoded(params: ApiService.MenuExportEncodedParams): __Observable<MenuExportEncodedDto> {
    return this.menuExportEncodedResponse(params).pipe(
      __map(_r => _r.body as MenuExportEncodedDto)
    );
  }

  /**
   * Menu Export
   * @param params The `ApiService.MenuExportParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuExportResponse(params: ApiService.MenuExportParams): __Observable<__StrictHttpResponse<MenuExportDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/${encodeURIComponent(params.menuId)}/export`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuExportDto>;
      })
    );
  }
  /**
   * Menu Export
   * @param params The `ApiService.MenuExportParams` containing the following parameters:
   *
   * - `menuId`:
   *
   * - `companyId`:
   */
  menuExport(params: ApiService.MenuExportParams): __Observable<MenuExportDto> {
    return this.menuExportResponse(params).pipe(
      __map(_r => _r.body as MenuExportDto)
    );
  }

  /**
   * Menu Import Encoded
   * @param params The `ApiService.MenuImportEncodedParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuExportEncodedDto`:
   */
  menuImportEncodedResponse(params: ApiService.MenuImportEncodedParams): __Observable<__StrictHttpResponse<MenuImportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.MenuExportEncodedDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/import/encoded`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuImportResponseDto>;
      })
    );
  }
  /**
   * Menu Import Encoded
   * @param params The `ApiService.MenuImportEncodedParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuExportEncodedDto`:
   */
  menuImportEncoded(params: ApiService.MenuImportEncodedParams): __Observable<MenuImportResponseDto> {
    return this.menuImportEncodedResponse(params).pipe(
      __map(_r => _r.body as MenuImportResponseDto)
    );
  }

  /**
   * Menu Import
   * @param params The `ApiService.MenuImportParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuExportDto`:
   */
  menuImportResponse(params: ApiService.MenuImportParams): __Observable<__StrictHttpResponse<MenuImportResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.MenuExportDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/import`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuImportResponseDto>;
      })
    );
  }
  /**
   * Menu Import
   * @param params The `ApiService.MenuImportParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuExportDto`:
   */
  menuImport(params: ApiService.MenuImportParams): __Observable<MenuImportResponseDto> {
    return this.menuImportResponse(params).pipe(
      __map(_r => _r.body as MenuImportResponseDto)
    );
  }

  /**
   * Menu Create
   * @param params The `ApiService.MenuCreateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuIterationDto`:
   */
  menuCreateResponse(params: ApiService.MenuCreateParams): __Observable<__StrictHttpResponse<MenuIterationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.MenuIterationDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuIterationDto>;
      })
    );
  }
  /**
   * Menu Create
   * @param params The `ApiService.MenuCreateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuIterationDto`:
   */
  menuCreate(params: ApiService.MenuCreateParams): __Observable<MenuIterationDto> {
    return this.menuCreateResponse(params).pipe(
      __map(_r => _r.body as MenuIterationDto)
    );
  }

  /**
   * Menu Create Legacy
   * @param params The `ApiService.MenuCreateLegacyParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuIterationDto`:
   */
  menuCreateLegacyResponse(params: ApiService.MenuCreateLegacyParams): __Observable<__StrictHttpResponse<MenuIterationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.MenuIterationDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/menu`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MenuIterationDto>;
      })
    );
  }
  /**
   * Menu Create Legacy
   * @param params The `ApiService.MenuCreateLegacyParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `MenuIterationDto`:
   */
  menuCreateLegacy(params: ApiService.MenuCreateLegacyParams): __Observable<MenuIterationDto> {
    return this.menuCreateLegacyResponse(params).pipe(
      __map(_r => _r.body as MenuIterationDto)
    );
  }

  /**
   * Company Bank Account List
   * @param companyId undefined
   */
  companyBankAccountListResponse(companyId: string): __Observable<__StrictHttpResponse<Array<CompanyBankAccountDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(companyId)}/bank-account/list`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyBankAccountDto>>;
      })
    );
  }
  /**
   * Company Bank Account List
   * @param companyId undefined
   */
  companyBankAccountList(companyId: string): __Observable<Array<CompanyBankAccountDto>> {
    return this.companyBankAccountListResponse(companyId).pipe(
      __map(_r => _r.body as Array<CompanyBankAccountDto>)
    );
  }

  /**
   * Company Bank Account Add
   * @param params The `ApiService.CompanyBankAccountAddParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyBankAccountAddDto`:
   */
  companyBankAccountAddResponse(params: ApiService.CompanyBankAccountAddParams): __Observable<__StrictHttpResponse<CompanyBankAccountDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CompanyBankAccountAddDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/bank-account`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyBankAccountDto>;
      })
    );
  }
  /**
   * Company Bank Account Add
   * @param params The `ApiService.CompanyBankAccountAddParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CompanyBankAccountAddDto`:
   */
  companyBankAccountAdd(params: ApiService.CompanyBankAccountAddParams): __Observable<CompanyBankAccountDto> {
    return this.companyBankAccountAddResponse(params).pipe(
      __map(_r => _r.body as CompanyBankAccountDto)
    );
  }

  /**
   * Company Bank Account Get
   * @param params The `ApiService.CompanyBankAccountGetParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `bankId`:
   */
  companyBankAccountGetResponse(params: ApiService.CompanyBankAccountGetParams): __Observable<__StrictHttpResponse<CompanyBankAccountDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/bank-account/${encodeURIComponent(params.bankId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyBankAccountDto>;
      })
    );
  }
  /**
   * Company Bank Account Get
   * @param params The `ApiService.CompanyBankAccountGetParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `bankId`:
   */
  companyBankAccountGet(params: ApiService.CompanyBankAccountGetParams): __Observable<CompanyBankAccountDto> {
    return this.companyBankAccountGetResponse(params).pipe(
      __map(_r => _r.body as CompanyBankAccountDto)
    );
  }

  /**
   * Company Bank Account Delete
   * @param params The `ApiService.CompanyBankAccountDeleteParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `bankId`:
   */
  companyBankAccountDeleteResponse(params: ApiService.CompanyBankAccountDeleteParams): __Observable<__StrictHttpResponse<CompanyBankAccountActionResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/bank-account/${encodeURIComponent(params.bankId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyBankAccountActionResultDto>;
      })
    );
  }
  /**
   * Company Bank Account Delete
   * @param params The `ApiService.CompanyBankAccountDeleteParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `bankId`:
   */
  companyBankAccountDelete(params: ApiService.CompanyBankAccountDeleteParams): __Observable<CompanyBankAccountActionResultDto> {
    return this.companyBankAccountDeleteResponse(params).pipe(
      __map(_r => _r.body as CompanyBankAccountActionResultDto)
    );
  }

  /**
   * Company Bank Account Set Default
   * @param params The `ApiService.CompanyBankAccountSetDefaultParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `bankId`:
   */
  companyBankAccountSetDefaultResponse(params: ApiService.CompanyBankAccountSetDefaultParams): __Observable<__StrictHttpResponse<CompanyBankAccountActionResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/bank-account/${encodeURIComponent(params.bankId)}/default`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyBankAccountActionResultDto>;
      })
    );
  }
  /**
   * Company Bank Account Set Default
   * @param params The `ApiService.CompanyBankAccountSetDefaultParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `bankId`:
   */
  companyBankAccountSetDefault(params: ApiService.CompanyBankAccountSetDefaultParams): __Observable<CompanyBankAccountActionResultDto> {
    return this.companyBankAccountSetDefaultResponse(params).pipe(
      __map(_r => _r.body as CompanyBankAccountActionResultDto)
    );
  }

  /**
   * Device Pair From Id
   * @param params The `ApiService.DevicePairFromIdParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `DevicePairFromCodeRequestDto`:
   */
  devicePairFromIdResponse(params: ApiService.DevicePairFromIdParams): __Observable<__StrictHttpResponse<DevicePairFromCodeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.DevicePairFromCodeRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/pair-from-code`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DevicePairFromCodeResponseDto>;
      })
    );
  }
  /**
   * Device Pair From Id
   * @param params The `ApiService.DevicePairFromIdParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `DevicePairFromCodeRequestDto`:
   */
  devicePairFromId(params: ApiService.DevicePairFromIdParams): __Observable<DevicePairFromCodeResponseDto> {
    return this.devicePairFromIdResponse(params).pipe(
      __map(_r => _r.body as DevicePairFromCodeResponseDto)
    );
  }

  /**
   * Device Unpair
   * @param params The `ApiService.DeviceUnpairParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `deviceId`:
   *
   * - `companyId`:
   */
  deviceUnpairResponse(params: ApiService.DeviceUnpairParams): __Observable<__StrictHttpResponse<DeviceUnpairResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.storeId != null) __params = __params.set('storeId', params.storeId.toString());


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/${encodeURIComponent(params.deviceId)}/unpair`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeviceUnpairResponseDto>;
      })
    );
  }
  /**
   * Device Unpair
   * @param params The `ApiService.DeviceUnpairParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `deviceId`:
   *
   * - `companyId`:
   */
  deviceUnpair(params: ApiService.DeviceUnpairParams): __Observable<DeviceUnpairResponseDto> {
    return this.deviceUnpairResponse(params).pipe(
      __map(_r => _r.body as DeviceUnpairResponseDto)
    );
  }

  /**
   * Device List
   * @param params The `ApiService.DeviceListParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `companyId`:
   */
  deviceListResponse(params: ApiService.DeviceListParams): __Observable<__StrictHttpResponse<Array<MerchantDeviceDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.storeId != null) __params = __params.set('storeId', params.storeId.toString());

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MerchantDeviceDto>>;
      })
    );
  }
  /**
   * Device List
   * @param params The `ApiService.DeviceListParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `companyId`:
   */
  deviceList(params: ApiService.DeviceListParams): __Observable<Array<MerchantDeviceDto>> {
    return this.deviceListResponse(params).pipe(
      __map(_r => _r.body as Array<MerchantDeviceDto>)
    );
  }

  /**
   * Device Pair Create
   * @param params The `ApiService.DevicePairCreateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CreatePairDeviceRequestDto`:
   */
  devicePairCreateResponse(params: ApiService.DevicePairCreateParams): __Observable<__StrictHttpResponse<CreatePairDeviceResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.CreatePairDeviceRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/pair`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatePairDeviceResponseDto>;
      })
    );
  }
  /**
   * Device Pair Create
   * @param params The `ApiService.DevicePairCreateParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `CreatePairDeviceRequestDto`:
   */
  devicePairCreate(params: ApiService.DevicePairCreateParams): __Observable<CreatePairDeviceResponseDto> {
    return this.devicePairCreateResponse(params).pipe(
      __map(_r => _r.body as CreatePairDeviceResponseDto)
    );
  }

  /**
   * Merchant Device Set Active
   * @param params The `ApiService.MerchantDeviceSetActiveParams` containing the following parameters:
   *
   * - `isActive`:
   *
   * - `companyId`:
   */
  merchantDeviceSetActiveResponse(params: ApiService.MerchantDeviceSetActiveParams): __Observable<__StrictHttpResponse<DeviceSetActiveResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/set-active/${encodeURIComponent(params.isActive)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeviceSetActiveResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Set Active
   * @param params The `ApiService.MerchantDeviceSetActiveParams` containing the following parameters:
   *
   * - `isActive`:
   *
   * - `companyId`:
   */
  merchantDeviceSetActive(params: ApiService.MerchantDeviceSetActiveParams): __Observable<DeviceSetActiveResponseDto> {
    return this.merchantDeviceSetActiveResponse(params).pipe(
      __map(_r => _r.body as DeviceSetActiveResponseDto)
    );
  }

  /**
   * Merchant Device Checkin
   * @param params The `ApiService.MerchantDeviceCheckinParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `status`:
   *
   * - `nativeVersionCode`:
   *
   * - `nativeVersion`:
   *
   * - `clientTimestamp`:
   *
   * - `buildNumber`:
   */
  merchantDeviceCheckinResponse(params: ApiService.MerchantDeviceCheckinParams): __Observable<__StrictHttpResponse<MerchantDeviceCheckinResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.nativeVersionCode != null) __params = __params.set('nativeVersionCode', params.nativeVersionCode.toString());
    if (params.nativeVersion != null) __params = __params.set('nativeVersion', params.nativeVersion.toString());
    if (params.clientTimestamp != null) __params = __params.set('clientTimestamp', params.clientTimestamp.toString());
    if (params.buildNumber != null) __params = __params.set('buildNumber', params.buildNumber.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/checkin`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MerchantDeviceCheckinResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Checkin
   * @param params The `ApiService.MerchantDeviceCheckinParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `status`:
   *
   * - `nativeVersionCode`:
   *
   * - `nativeVersion`:
   *
   * - `clientTimestamp`:
   *
   * - `buildNumber`:
   */
  merchantDeviceCheckin(params: ApiService.MerchantDeviceCheckinParams): __Observable<MerchantDeviceCheckinResponseDto> {
    return this.merchantDeviceCheckinResponse(params).pipe(
      __map(_r => _r.body as MerchantDeviceCheckinResponseDto)
    );
  }

  /**
   * Merchant Device Checkin Only Put
   * @param params The `ApiService.MerchantDeviceCheckinOnlyPutParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   *
   * - `CheckinRequestDto`:
   */
  merchantDeviceCheckinOnlyPutResponse(params: ApiService.MerchantDeviceCheckinOnlyPutParams): __Observable<__StrictHttpResponse<CheckinResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ifNoneMatch != null) __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());

    __body = params.CheckinRequestDto;
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/check-in`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckinResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Checkin Only Put
   * @param params The `ApiService.MerchantDeviceCheckinOnlyPutParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   *
   * - `CheckinRequestDto`:
   */
  merchantDeviceCheckinOnlyPut(params: ApiService.MerchantDeviceCheckinOnlyPutParams): __Observable<CheckinResponseDto> {
    return this.merchantDeviceCheckinOnlyPutResponse(params).pipe(
      __map(_r => _r.body as CheckinResponseDto)
    );
  }

  /**
   * Merchant Device Checkin Only
   * @param params The `ApiService.MerchantDeviceCheckinOnlyParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   *
   * - `status`:
   *
   * - `nativeVersionCode`:
   *
   * - `nativeVersion`:
   *
   * - `clientTimestamp`:
   *
   * - `buildNumber`:
   */
  merchantDeviceCheckinOnlyResponse(params: ApiService.MerchantDeviceCheckinOnlyParams): __Observable<__StrictHttpResponse<CheckinResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ifNoneMatch != null) __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());

    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.nativeVersionCode != null) __params = __params.set('nativeVersionCode', params.nativeVersionCode.toString());
    if (params.nativeVersion != null) __params = __params.set('nativeVersion', params.nativeVersion.toString());
    if (params.clientTimestamp != null) __params = __params.set('clientTimestamp', params.clientTimestamp.toString());
    if (params.buildNumber != null) __params = __params.set('buildNumber', params.buildNumber.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/check-in`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckinResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Checkin Only
   * @param params The `ApiService.MerchantDeviceCheckinOnlyParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   *
   * - `status`:
   *
   * - `nativeVersionCode`:
   *
   * - `nativeVersion`:
   *
   * - `clientTimestamp`:
   *
   * - `buildNumber`:
   */
  merchantDeviceCheckinOnly(params: ApiService.MerchantDeviceCheckinOnlyParams): __Observable<CheckinResponseDto> {
    return this.merchantDeviceCheckinOnlyResponse(params).pipe(
      __map(_r => _r.body as CheckinResponseDto)
    );
  }

  /**
   * Merchant Device Order Stats
   * @param params The `ApiService.MerchantDeviceOrderStatsParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   */
  merchantDeviceOrderStatsResponse(params: ApiService.MerchantDeviceOrderStatsParams): __Observable<__StrictHttpResponse<OrderStatsResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ifNoneMatch != null) __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/order-stats`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderStatsResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Order Stats
   * @param params The `ApiService.MerchantDeviceOrderStatsParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   */
  merchantDeviceOrderStats(params: ApiService.MerchantDeviceOrderStatsParams): __Observable<OrderStatsResponseDto> {
    return this.merchantDeviceOrderStatsResponse(params).pipe(
      __map(_r => _r.body as OrderStatsResponseDto)
    );
  }

  /**
   * Merchant Device Active Orders
   * @param params The `ApiService.MerchantDeviceActiveOrdersParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   *
   * - `includeCurbsidePendingPickup`:
   *
   * - `includeComplete`:
   */
  merchantDeviceActiveOrdersResponse(params: ApiService.MerchantDeviceActiveOrdersParams): __Observable<__StrictHttpResponse<OrderViewWithWaitTimeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ifNoneMatch != null) __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());

    if (params.includeCurbsidePendingPickup != null) __params = __params.set('includeCurbsidePendingPickup', params.includeCurbsidePendingPickup.toString());
    if (params.includeComplete != null) __params = __params.set('includeComplete', params.includeComplete.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/active-orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewWithWaitTimeResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Active Orders
   * @param params The `ApiService.MerchantDeviceActiveOrdersParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   *
   * - `includeCurbsidePendingPickup`:
   *
   * - `includeComplete`:
   */
  merchantDeviceActiveOrders(params: ApiService.MerchantDeviceActiveOrdersParams): __Observable<OrderViewWithWaitTimeResponseDto> {
    return this.merchantDeviceActiveOrdersResponse(params).pipe(
      __map(_r => _r.body as OrderViewWithWaitTimeResponseDto)
    );
  }

  /**
   * Merchant Device Archived Orders
   * @param params The `ApiService.MerchantDeviceArchivedOrdersParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   */
  merchantDeviceArchivedOrdersResponse(params: ApiService.MerchantDeviceArchivedOrdersParams): __Observable<__StrictHttpResponse<OrderViewResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ifNoneMatch != null) __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/archived-orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Archived Orders
   * @param params The `ApiService.MerchantDeviceArchivedOrdersParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   */
  merchantDeviceArchivedOrders(params: ApiService.MerchantDeviceArchivedOrdersParams): __Observable<OrderViewResponseDto> {
    return this.merchantDeviceArchivedOrdersResponse(params).pipe(
      __map(_r => _r.body as OrderViewResponseDto)
    );
  }

  /**
   * Merchant Device Inactive Orders
   * @param params The `ApiService.MerchantDeviceInactiveOrdersParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   */
  merchantDeviceInactiveOrdersResponse(params: ApiService.MerchantDeviceInactiveOrdersParams): __Observable<__StrictHttpResponse<OrderViewResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ifNoneMatch != null) __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/company/${encodeURIComponent(params.companyId)}/devices/inactive-orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderViewResponseDto>;
      })
    );
  }
  /**
   * Merchant Device Inactive Orders
   * @param params The `ApiService.MerchantDeviceInactiveOrdersParams` containing the following parameters:
   *
   * - `if-none-match`:
   *
   * - `companyId`:
   */
  merchantDeviceInactiveOrders(params: ApiService.MerchantDeviceInactiveOrdersParams): __Observable<OrderViewResponseDto> {
    return this.merchantDeviceInactiveOrdersResponse(params).pipe(
      __map(_r => _r.body as OrderViewResponseDto)
    );
  }

  /**
   * Customer List Paginated
   * @param params The `ApiService.CustomerListPaginatedParams` containing the following parameters:
   *
   * - `sortField`:
   *
   * - `sortDirection`:
   *
   * - `search`:
   *
   * - `offset`:
   *
   * - `count`:
   */
  customerListPaginatedResponse(params: ApiService.CustomerListPaginatedParams): __Observable<__StrictHttpResponse<CustomerListPaginatedDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortField != null) __params = __params.set('sortField', params.sortField.toString());
    if (params.sortDirection != null) __params = __params.set('sortDirection', params.sortDirection.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/customer`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerListPaginatedDto>;
      })
    );
  }
  /**
   * Customer List Paginated
   * @param params The `ApiService.CustomerListPaginatedParams` containing the following parameters:
   *
   * - `sortField`:
   *
   * - `sortDirection`:
   *
   * - `search`:
   *
   * - `offset`:
   *
   * - `count`:
   */
  customerListPaginated(params: ApiService.CustomerListPaginatedParams): __Observable<CustomerListPaginatedDto> {
    return this.customerListPaginatedResponse(params).pipe(
      __map(_r => _r.body as CustomerListPaginatedDto)
    );
  }

  /**
   * Customer Get
   * @param userId undefined
   */
  customerGetResponse(userId: string): __Observable<__StrictHttpResponse<CustomerWithDetailsDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/customer/${encodeURIComponent(userId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerWithDetailsDto>;
      })
    );
  }
  /**
   * Customer Get
   * @param userId undefined
   */
  customerGet(userId: string): __Observable<CustomerWithDetailsDto> {
    return this.customerGetResponse(userId).pipe(
      __map(_r => _r.body as CustomerWithDetailsDto)
    );
  }

  /**
   * Customer Get Recent Orders
   * @param userId undefined
   */
  customerGetRecentOrdersResponse(userId: string): __Observable<__StrictHttpResponse<Array<CustomerRecentOrderSummaryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('device-authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('device-authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/customer/${encodeURIComponent(userId)}/recent-orders`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerRecentOrderSummaryDto>>;
      })
    );
  }
  /**
   * Customer Get Recent Orders
   * @param userId undefined
   */
  customerGetRecentOrders(userId: string): __Observable<Array<CustomerRecentOrderSummaryDto>> {
    return this.customerGetRecentOrdersResponse(userId).pipe(
      __map(_r => _r.body as Array<CustomerRecentOrderSummaryDto>)
    );
  }

  /**
   * Landing Page Loaded
   * @param slug undefined
   */
  landingPageLoadedResponse(slug: string): __Observable<__StrictHttpResponse<LandingPageDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/landing-page/${encodeURIComponent(slug)}/load`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LandingPageDto>;
      })
    );
  }
  /**
   * Landing Page Loaded
   * @param slug undefined
   */
  landingPageLoaded(slug: string): __Observable<LandingPageDto> {
    return this.landingPageLoadedResponse(slug).pipe(
      __map(_r => _r.body as LandingPageDto)
    );
  }

  /**
   * Landing Page Link Clicked
   * @param slug undefined
   */
  landingPageLinkClickedResponse(slug: string): __Observable<__StrictHttpResponse<LandingPageActionResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/landing-page/${encodeURIComponent(slug)}/click`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LandingPageActionResultDto>;
      })
    );
  }
  /**
   * Landing Page Link Clicked
   * @param slug undefined
   */
  landingPageLinkClicked(slug: string): __Observable<LandingPageActionResultDto> {
    return this.landingPageLinkClickedResponse(slug).pipe(
      __map(_r => _r.body as LandingPageActionResultDto)
    );
  }

  /**
   * Promo Create
   * @param PromoCreateDto undefined
   */
  promoCreateResponse(PromoCreateDto: PromoCreateDto): __Observable<__StrictHttpResponse<PromoDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PromoCreateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/promo`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromoDto>;
      })
    );
  }
  /**
   * Promo Create
   * @param PromoCreateDto undefined
   */
  promoCreate(PromoCreateDto: PromoCreateDto): __Observable<PromoDto> {
    return this.promoCreateResponse(PromoCreateDto).pipe(
      __map(_r => _r.body as PromoDto)
    );
  }

  /**
   * Promo Update
   * @param PromoUpdateDto undefined
   */
  promoUpdateResponse(PromoUpdateDto: PromoUpdateDto): __Observable<__StrictHttpResponse<PromoDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = PromoUpdateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'PUT',
          this.rootUrl + `/promo`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromoDto>;
      })
    );
  }
  /**
   * Promo Update
   * @param PromoUpdateDto undefined
   */
  promoUpdate(PromoUpdateDto: PromoUpdateDto): __Observable<PromoDto> {
    return this.promoUpdateResponse(PromoUpdateDto).pipe(
      __map(_r => _r.body as PromoDto)
    );
  }

  /**
   * Promo Get
   * @param params The `ApiService.PromoGetParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `companyId`:
   */
  promoGetResponse(params: ApiService.PromoGetParams): __Observable<__StrictHttpResponse<Array<PromoDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.storeId != null) __params = __params.set('storeId', params.storeId.toString());
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/promo`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PromoDto>>;
      })
    );
  }
  /**
   * Promo Get
   * @param params The `ApiService.PromoGetParams` containing the following parameters:
   *
   * - `storeId`:
   *
   * - `companyId`:
   */
  promoGet(params: ApiService.PromoGetParams): __Observable<Array<PromoDto>> {
    return this.promoGetResponse(params).pipe(
      __map(_r => _r.body as Array<PromoDto>)
    );
  }

  /**
   * Consumer Launch
   * @param ConsumerLaunchRequestDto undefined
   */
  consumerLaunchResponse(ConsumerLaunchRequestDto: ConsumerLaunchRequestDto): __Observable<__StrictHttpResponse<ConsumerLaunchResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ConsumerLaunchRequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/client/consumer-launch`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConsumerLaunchResponseDto>;
      })
    );
  }
  /**
   * Consumer Launch
   * @param ConsumerLaunchRequestDto undefined
   */
  consumerLaunch(ConsumerLaunchRequestDto: ConsumerLaunchRequestDto): __Observable<ConsumerLaunchResponseDto> {
    return this.consumerLaunchResponse(ConsumerLaunchRequestDto).pipe(
      __map(_r => _r.body as ConsumerLaunchResponseDto)
    );
  }

  /**
   * Consumer Launch V2
   * @param ConsumerLaunchV2RequestDto undefined
   */
  consumerLaunchV2Response(ConsumerLaunchV2RequestDto: ConsumerLaunchV2RequestDto): __Observable<__StrictHttpResponse<ConsumerLaunchV2ResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ConsumerLaunchV2RequestDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/client/consumer-launch/v2`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConsumerLaunchV2ResponseDto>;
      })
    );
  }
  /**
   * Consumer Launch V2
   * @param ConsumerLaunchV2RequestDto undefined
   */
  consumerLaunchV2(ConsumerLaunchV2RequestDto: ConsumerLaunchV2RequestDto): __Observable<ConsumerLaunchV2ResponseDto> {
    return this.consumerLaunchV2Response(ConsumerLaunchV2RequestDto).pipe(
      __map(_r => _r.body as ConsumerLaunchV2ResponseDto)
    );
  }

  /**
   * Feedback Send
   * @param UserFeedbackDto undefined
   */
  feedbackSendResponse(UserFeedbackDto: UserFeedbackDto): __Observable<__StrictHttpResponse<UserFeedbackResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserFeedbackDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/feedback`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserFeedbackResponseDto>;
      })
    );
  }
  /**
   * Feedback Send
   * @param UserFeedbackDto undefined
   */
  feedbackSend(UserFeedbackDto: UserFeedbackDto): __Observable<UserFeedbackResponseDto> {
    return this.feedbackSendResponse(UserFeedbackDto).pipe(
      __map(_r => _r.body as UserFeedbackResponseDto)
    );
  }

  /**
   * Api App List
   */
  apiAppListResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/api-app`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Api App List
   */
  apiAppList(): __Observable<null> {
    return this.apiAppListResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Api App Create
   */
  apiAppCreateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/api-app`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Api App Create
   */
  apiAppCreate(): __Observable<null> {
    return this.apiAppCreateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Api App Credential List
   * @param apiAppId undefined
   */
  apiAppCredentialListResponse(apiAppId: string): __Observable<__StrictHttpResponse<ApiAppCredentialListResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/api-app/${encodeURIComponent(apiAppId)}/credentials`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiAppCredentialListResponseDto>;
      })
    );
  }
  /**
   * Api App Credential List
   * @param apiAppId undefined
   */
  apiAppCredentialList(apiAppId: string): __Observable<ApiAppCredentialListResponseDto> {
    return this.apiAppCredentialListResponse(apiAppId).pipe(
      __map(_r => _r.body as ApiAppCredentialListResponseDto)
    );
  }

  /**
   * Api App Get
   * @param apiAppId undefined
   */
  apiAppGetResponse(apiAppId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/api-app/${encodeURIComponent(apiAppId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Api App Get
   * @param apiAppId undefined
   */
  apiAppGet(apiAppId: string): __Observable<null> {
    return this.apiAppGetResponse(apiAppId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Api App Create Credential
   * @param params The `ApiService.ApiAppCreateCredentialParams` containing the following parameters:
   *
   * - `apiAppId`:
   *
   * - `ApiAppCredentialCreateDto`:
   */
  apiAppCreateCredentialResponse(params: ApiService.ApiAppCreateCredentialParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.ApiAppCredentialCreateDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/api-app/${encodeURIComponent(params.apiAppId)}/credential`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Api App Create Credential
   * @param params The `ApiService.ApiAppCreateCredentialParams` containing the following parameters:
   *
   * - `apiAppId`:
   *
   * - `ApiAppCredentialCreateDto`:
   */
  apiAppCreateCredential(params: ApiService.ApiAppCreateCredentialParams): __Observable<null> {
    return this.apiAppCreateCredentialResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Api App Revoke Credential
   * @param params The `ApiService.ApiAppRevokeCredentialParams` containing the following parameters:
   *
   * - `apiCredentialId`:
   *
   * - `apiAppId`:
   */
  apiAppRevokeCredentialResponse(params: ApiService.ApiAppRevokeCredentialParams): __Observable<__StrictHttpResponse<ApiAppCredentialDeleteResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'DELETE',
          this.rootUrl + `/api-app/${encodeURIComponent(params.apiAppId)}/credential/${encodeURIComponent(params.apiCredentialId)}`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiAppCredentialDeleteResponseDto>;
      })
    );
  }
  /**
   * Api App Revoke Credential
   * @param params The `ApiService.ApiAppRevokeCredentialParams` containing the following parameters:
   *
   * - `apiCredentialId`:
   *
   * - `apiAppId`:
   */
  apiAppRevokeCredential(params: ApiService.ApiAppRevokeCredentialParams): __Observable<ApiAppCredentialDeleteResponseDto> {
    return this.apiAppRevokeCredentialResponse(params).pipe(
      __map(_r => _r.body as ApiAppCredentialDeleteResponseDto)
    );
  }

  /**
   * Api App Roll Credential Secret
   * @param params The `ApiService.ApiAppRollCredentialSecretParams` containing the following parameters:
   *
   * - `apiCredentialId`:
   *
   * - `apiAppId`:
   */
  apiAppRollCredentialSecretResponse(params: ApiService.ApiAppRollCredentialSecretParams): __Observable<__StrictHttpResponse<ApiAppCredentialCreateResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/api-app/${encodeURIComponent(params.apiAppId)}/credential/${encodeURIComponent(params.apiCredentialId)}/roll`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiAppCredentialCreateResponseDto>;
      })
    );
  }
  /**
   * Api App Roll Credential Secret
   * @param params The `ApiService.ApiAppRollCredentialSecretParams` containing the following parameters:
   *
   * - `apiCredentialId`:
   *
   * - `apiAppId`:
   */
  apiAppRollCredentialSecret(params: ApiService.ApiAppRollCredentialSecretParams): __Observable<ApiAppCredentialCreateResponseDto> {
    return this.apiAppRollCredentialSecretResponse(params).pipe(
      __map(_r => _r.body as ApiAppCredentialCreateResponseDto)
    );
  }

  /**
   * Consumer Activity Create
   * @param ConsumerActivityDto undefined
   */
  consumerActivityCreateResponse(ConsumerActivityDto: ConsumerActivityDto): __Observable<__StrictHttpResponse<ConsumerActivityCreateResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ConsumerActivityDto;
    return this.tokenProvider('authorization').pipe(
      __switchMap(_token => {
        if (_token != null) __headers = __headers.set('authorization', _token.toString());
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/analytics/consumer-activity`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConsumerActivityCreateResultDto>;
      })
    );
  }
  /**
   * Consumer Activity Create
   * @param ConsumerActivityDto undefined
   */
  consumerActivityCreate(ConsumerActivityDto: ConsumerActivityDto): __Observable<ConsumerActivityCreateResultDto> {
    return this.consumerActivityCreateResponse(ConsumerActivityDto).pipe(
      __map(_r => _r.body as ConsumerActivityCreateResultDto)
    );
  }

  /**
   * SquareWebhookEndpoint
   * @param x-square-signature undefined
   */
  squareWebhookEndpointResponse(xSquareSignature: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (xSquareSignature != null) __headers = __headers.set('x-square-signature', xSquareSignature.toString());
    return __of(null).pipe(
      __switchMap(_token => {
        let req = new HttpRequest<any>(
          'POST',
          this.rootUrl + `/square-webhook`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'json'
          });

        return this.http.request<any>(req);
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * SquareWebhookEndpoint
   * @param x-square-signature undefined
   */
  squareWebhookEndpoint(xSquareSignature: string): __Observable<null> {
    return this.squareWebhookEndpointResponse(xSquareSignature).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ApiService {

  /**
   * Parameters for storesGetNearbyPaginated
   */
  export interface StoresGetNearbyPaginatedParams {
    lon: string;
    lat: string;
    storeName?: string;
    resultCount?: number;
    postalCode?: string;
    pageKey?: string;
    maxDistance?: number;
  }

  /**
   * Parameters for storesGetNearby
   */
  export interface StoresGetNearbyParams {
    lon: string;
    lat: string;
    storeName?: string;
    resultOffset?: number;
    postalCode?: string;
    maxResults?: number;
    maxDistance?: number;
  }

  /**
   * Parameters for storePutDeal
   */
  export interface StorePutDealParams {
    storeId: string;
    ItemDealDto: ItemDealDto;
  }

  /**
   * Parameters for storeDeleteDeal
   */
  export interface StoreDeleteDealParams {
    storeId: string;
    dealId: string;
  }

  /**
   * Parameters for storeGetMenuCategorySectionsWithInventory
   */
  export interface StoreGetMenuCategorySectionsWithInventoryParams {
    storeId: string;
    categoryId: string;
  }

  /**
   * Parameters for storeGetMenuV2
   */
  export interface StoreGetMenuV2Params {
    storeId: string;
    includeSections?: boolean;
    includeMenuOptions?: boolean;
    includeItemOptions?: boolean;
    includeInventory?: boolean;
  }

  /**
   * Parameters for storeGetMenuV3
   */
  export interface StoreGetMenuV3Params {
    storeId: string;
    includeStoreHidden?: boolean;
    includeSections?: boolean;
    includeMenuOptions?: boolean;
    includeItemOptions?: boolean;
    includeInventory?: boolean;
  }

  /**
   * Parameters for storeGetOfflineReport
   */
  export interface StoreGetOfflineReportParams {
    storeId: string;
    StoreOfflineReportRequestDto: StoreOfflineReportRequestDto;
  }

  /**
   * Parameters for storeDashboard
   */
  export interface StoreDashboardParams {
    storeId: string;
    date?: string;
  }

  /**
   * Parameters for storePutAmenities
   */
  export interface StorePutAmenitiesParams {
    storeId: string;
    StoreAmenitiesDto: StoreAmenitiesDto;
  }

  /**
   * Parameters for updateStoreHours
   */
  export interface UpdateStoreHoursParams {
    storeId: string;
    StoreHoursRequestDto: StoreHoursRequestDto;
  }

  /**
   * Parameters for storeSetAlwaysOnline
   */
  export interface StoreSetAlwaysOnlineParams {
    storeId: string;
    StoreSetAlwaysOnlineDto: StoreSetAlwaysOnlineDto;
  }

  /**
   * Parameters for storeSetSquareLocationId
   */
  export interface StoreSetSquareLocationIdParams {
    storeId: string;
    StoreSquareLocationIdDto: StoreSquareLocationIdDto;
  }

  /**
   * Parameters for storeValidatePhoneTwilioInboundWebHook
   */
  export interface StoreValidatePhoneTwilioInboundWebHookParams {
    Digits: string;
    Called: string;
  }

  /**
   * Parameters for storeUpdateV2
   */
  export interface StoreUpdateV2Params {
    storeId: string;
    StoreUpdateDto: StoreUpdateDto;
  }

  /**
   * Parameters for storeUpdate
   */
  export interface StoreUpdateParams {
    storeId: string;
    StoreUpdateDto: StoreUpdateDto;
  }

  /**
   * Parameters for getOrdersByStore
   */
  export interface GetOrdersByStoreParams {
    storeId: string;
    status?: 'active' | 'inactive';
  }

  /**
   * Parameters for storeInviteUser
   */
  export interface StoreInviteUserParams {
    storeId: string;
    StoreCreateInviteRequestDto: StoreCreateInviteRequestDto;
  }

  /**
   * Parameters for storeAddImageFromId
   */
  export interface StoreAddImageFromIdParams {
    storeId: string;
    StoreAddImageRequestDto: StoreAddImageRequestDto;
  }

  /**
   * Parameters for storeAddPickupLocationImageFromId
   */
  export interface StoreAddPickupLocationImageFromIdParams {
    storeId: string;
    StorePickupInstructionsLocationImageDto: StorePickupInstructionsLocationImageDto;
  }

  /**
   * Parameters for storeAddImage
   */
  export interface StoreAddImageParams {
    y: number;
    x: number;
    width: number;
    storeId: string;
    scale: number;
    height: number;
    file: Blob;
  }

  /**
   * Parameters for storeReportSubscriberCreate
   */
  export interface StoreReportSubscriberCreateParams {
    storeId: string;
    StoreReportSubscriberDto: StoreReportSubscriberDto;
  }

  /**
   * Parameters for storeReportSubscriberDelete
   */
  export interface StoreReportSubscriberDeleteParams {
    subscriberId: string;
    storeId: string;
  }

  /**
   * Parameters for orderListPaginated
   */
  export interface OrderListPaginatedParams {
    count: string;
    storeId?: string;
    pageKey?: string;
  }

  /**
   * Parameters for orderReview
   */
  export interface OrderReviewParams {
    orderId: string;
    OrderReviewDto: OrderReviewDto;
  }

  /**
   * Parameters for orderPricingComputeWithSourceType
   */
  export interface OrderPricingComputeWithSourceTypeParams {
    OrderDto: OrderDto;
    paymentSourceType?: 'card_new' | 'card_existing' | 'mobile_pay' | 'joebucks' | 'us_bank_account';
  }

  /**
   * Parameters for orderRefund
   */
  export interface OrderRefundParams {
    orderId: string;
    RefundOrderRequestDto: RefundOrderRequestDto;
  }

  /**
   * Parameters for orderAcknowledge
   */
  export interface OrderAcknowledgeParams {
    orderId: string;
    AcknowledgeOrderRequestDto: AcknowledgeOrderRequestDto;
  }

  /**
   * Parameters for orderSetWaitTime
   */
  export interface OrderSetWaitTimeParams {
    orderId: string;
    OrderSetWaitTimeRequestDto: OrderSetWaitTimeRequestDto;
  }

  /**
   * Parameters for orderItemSetStatus
   */
  export interface OrderItemSetStatusParams {
    orderItemId: string;
    orderId: string;
    OrderItemSetStatusRequestDto: OrderItemSetStatusRequestDto;
  }

  /**
   * Parameters for joebucksUploadsHistory
   */
  export interface JoebucksUploadsHistoryParams {
    count: string;
    pageKey?: string;
  }

  /**
   * Parameters for cloverInventoryAssociation
   */
  export interface CloverInventoryAssociationParams {
    storeId: string;
    cloverItemId: string;
    StoreItemInventoryRequestDto: StoreItemInventoryRequestDto;
  }

  /**
   * Parameters for cloverAuthAuthorization
   */
  export interface CloverAuthAuthorizationParams {
    storeId: string;
    CloverAuthorizationRequestDto: CloverAuthorizationRequestDto;
  }

  /**
   * Parameters for squareAuthorizationRedirectEndpoint
   */
  export interface SquareAuthorizationRedirectEndpointParams {
    state: string;
    code: string;
  }

  /**
   * Parameters for oauthAuthAuthorizeDirectAuthenticate
   */
  export interface OauthAuthAuthorizeDirectAuthenticateParams {
    authenticationRequestToken: string;
    AuthTokenAuthorizeDirectAuthenticateRequestDto: AuthTokenAuthorizeDirectAuthenticateRequestDto;
  }

  /**
   * Parameters for userRemoveOneCard
   */
  export interface UserRemoveOneCardParams {
    cardId: string;
    fake?: boolean;
  }

  /**
   * Parameters for userAcknowledgeMerchantInvite
   */
  export interface UserAcknowledgeMerchantInviteParams {
    inviteId: string;
    UserAcknowledgeMerchantInviteRequestDto: UserAcknowledgeMerchantInviteRequestDto;
  }

  /**
   * Parameters for posSessionSetCurrentOrder
   */
  export interface PosSessionSetCurrentOrderParams {
    deviceId: string;
    PosSessionOrderPutDto: PosSessionOrderPutDto;
  }

  /**
   * Parameters for posSessionSetCurrentOrderTipAmount
   */
  export interface PosSessionSetCurrentOrderTipAmountParams {
    deviceId: string;
    PosDeviceSessionSetCurrentOrderTipAmountRequestDto: PosDeviceSessionSetCurrentOrderTipAmountRequestDto;
  }

  /**
   * Parameters for posSessionClear
   */
  export interface PosSessionClearParams {
    deviceId: string;
    clearSession?: boolean;
  }

  /**
   * Parameters for posSessionGetOrderSession
   */
  export interface PosSessionGetOrderSessionParams {
    orderSessionId: string;
    deviceId: string;
  }

  /**
   * Parameters for posSessionDeleteOrderSession
   */
  export interface PosSessionDeleteOrderSessionParams {
    orderSessionId: string;
    deviceId: string;
  }

  /**
   * Parameters for posSessionSetCurrentCustomerFromPhone
   */
  export interface PosSessionSetCurrentCustomerFromPhoneParams {
    deviceId: string;
    PosDeviceSessionSetCustomerFromPhoneRequestDto: PosDeviceSessionSetCustomerFromPhoneRequestDto;
    sessionId?: string;
  }

  /**
   * Parameters for posSessionCustomerGetCurrentOrder
   */
  export interface PosSessionCustomerGetCurrentOrderParams {
    storeId: string;
    deviceId: string;
  }

  /**
   * Parameters for posSessionCurrentOrderComplete
   */
  export interface PosSessionCurrentOrderCompleteParams {
    deviceId: string;
    PosSessionOrderCompleteRequestDto: PosSessionOrderCompleteRequestDto;
  }

  /**
   * Parameters for posSessionCustomerTransferOrderToMobile
   */
  export interface PosSessionCustomerTransferOrderToMobileParams {
    storeId: string;
    deviceId: string;
  }

  /**
   * Parameters for posSessionDevicePendingOrderClear
   */
  export interface PosSessionDevicePendingOrderClearParams {
    userId: string;
    deviceId: string;
  }

  /**
   * Parameters for reportGetDailySummaryDeviceV2
   */
  export interface ReportGetDailySummaryDeviceV2Params {
    storeId: string;
    date: string;
  }

  /**
   * Parameters for reportGetDailySummaryDevice
   */
  export interface ReportGetDailySummaryDeviceParams {
    storeId: string;
    date: string;
  }

  /**
   * Parameters for adminFindUsers
   */
  export interface AdminFindUsersParams {
    phone?: string;
    lastName?: string;
    id?: string;
    firstName?: string;
    email?: string;
  }

  /**
   * Parameters for adminFindOrders
   */
  export interface AdminFindOrdersParams {
    timeStart?: string;
    timeEnd?: string;
    storeId?: string;
    id?: string;
    customerId?: string;
  }

  /**
   * Parameters for adminSetReferrer
   */
  export interface AdminSetReferrerParams {
    userId: string;
    referringUserId: string;
  }

  /**
   * Parameters for adminGetUserWeeklyCohortTotals
   */
  export interface AdminGetUserWeeklyCohortTotalsParams {
    period?: string;
    endWeek?: string;
  }

  /**
   * Parameters for adminSearchStoresWithStatus
   */
  export interface AdminSearchStoresWithStatusParams {
    name?: string;
    id?: string;
  }

  /**
   * Parameters for adminSendConsumerMarketingSinglePush
   */
  export interface AdminSendConsumerMarketingSinglePushParams {
    userId: string;
    AdminMarketingPushDto: AdminMarketingPushDto;
  }

  /**
   * Parameters for adminNetworkStatusTerritoryUpdate
   */
  export interface AdminNetworkStatusTerritoryUpdateParams {
    territoryId: string;
    AdminCreateNetworkStatusTerritoryDto: AdminCreateNetworkStatusTerritoryDto;
  }

  /**
   * Parameters for adminNetworkStatusTerritoryGetDetails
   */
  export interface AdminNetworkStatusTerritoryGetDetailsParams {
    territoryId: string;
    periodStart?: string;
    periodEnd?: string;
    includeStores?: boolean;
  }

  /**
   * Parameters for adminNetworkStatusTerritoryListWithDetails
   */
  export interface AdminNetworkStatusTerritoryListWithDetailsParams {
    periodStart?: string;
    periodEnd?: string;
  }

  /**
   * Parameters for adminSetStorePosPaymentEnabled
   */
  export interface AdminSetStorePosPaymentEnabledParams {
    storeId: string;
    AdminSetPosPaymentEnabledDto: AdminSetPosPaymentEnabledDto;
  }

  /**
   * Parameters for adminSetStoreFeeOverride
   */
  export interface AdminSetStoreFeeOverrideParams {
    storeId: string;
    AdminSetStoreFeeOverrideDto: AdminSetStoreFeeOverrideDto;
  }

  /**
   * Parameters for adminMenuTransferToCompany
   */
  export interface AdminMenuTransferToCompanyParams {
    toCompanyId: string;
    menuId: string;
  }

  /**
   * Parameters for companyDashboard
   */
  export interface CompanyDashboardParams {
    companyId: string;
    date?: string;
  }

  /**
   * Parameters for companyGetLandingPageFromSlug
   */
  export interface CompanyGetLandingPageFromSlugParams {
    companySlug: string;
    lon?: string;
    lat?: string;
  }

  /**
   * Parameters for companyLandingAddHeroBackground
   */
  export interface CompanyLandingAddHeroBackgroundParams {
    y: number;
    x: number;
    width: number;
    scale: number;
    height: number;
    file: Blob;
    companyId: string;
  }

  /**
   * Parameters for companyLandingAddLogo
   */
  export interface CompanyLandingAddLogoParams {
    file: Blob;
    companyId: string;
  }

  /**
   * Parameters for companyUpdate
   */
  export interface CompanyUpdateParams {
    companyId: string;
    CompanyUpdateDto: CompanyUpdateDto;
  }

  /**
   * Parameters for companyVerification
   */
  export interface CompanyVerificationParams {
    companyId: string;
    CompanyVerificationDto: CompanyVerificationDto;
  }

  /**
   * Parameters for companyVerificationV2
   */
  export interface CompanyVerificationV2Params {
    companyId: string;
    CompanyVerificationDto: CompanyVerificationDto;
  }

  /**
   * Parameters for companyDownloadTransferReport
   */
  export interface CompanyDownloadTransferReportParams {
    transferBatchId: string;
    companyId: string;
    type?: 'summary' | 'itemized';
    format?: 'csv' | 'json';
  }

  /**
   * Parameters for companyInviteUser
   */
  export interface CompanyInviteUserParams {
    companyId: string;
    CompanyCreateInviteRequestDto: CompanyCreateInviteRequestDto;
  }

  /**
   * Parameters for companyDeleteUserRole
   */
  export interface CompanyDeleteUserRoleParams {
    userId: string;
    roleName: string;
    companyId: string;
  }

  /**
   * Parameters for companyCancelInvite
   */
  export interface CompanyCancelInviteParams {
    inviteId: string;
    companyId: string;
  }

  /**
   * Parameters for companyReportSubscriberCreate
   */
  export interface CompanyReportSubscriberCreateParams {
    companyId: string;
    CompanyReportSubscriberDto: CompanyReportSubscriberDto;
  }

  /**
   * Parameters for companyReportSubscriberDelete
   */
  export interface CompanyReportSubscriberDeleteParams {
    subscriberId: string;
    companyId: string;
  }

  /**
   * Parameters for menuOptionGet
   */
  export interface MenuOptionGetParams {
    menuOptionId: string;
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuOptionUpdate
   */
  export interface MenuOptionUpdateParams {
    menuOptionId: string;
    menuId: string;
    companyId: string;
    MenuOptionCreateDto: MenuOptionCreateDto;
  }

  /**
   * Parameters for menuOptionDelete
   */
  export interface MenuOptionDeleteParams {
    menuOptionId: string;
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuOptionList
   */
  export interface MenuOptionListParams {
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuOptionCreate
   */
  export interface MenuOptionCreateParams {
    menuId: string;
    companyId: string;
    MenuOptionCreateDto: MenuOptionCreateDto;
  }

  /**
   * Parameters for menuGet
   */
  export interface MenuGetParams {
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuDelete
   */
  export interface MenuDeleteParams {
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuExportEncoded
   */
  export interface MenuExportEncodedParams {
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuExport
   */
  export interface MenuExportParams {
    menuId: string;
    companyId: string;
  }

  /**
   * Parameters for menuImportEncoded
   */
  export interface MenuImportEncodedParams {
    companyId: string;
    MenuExportEncodedDto: MenuExportEncodedDto;
  }

  /**
   * Parameters for menuImport
   */
  export interface MenuImportParams {
    companyId: string;
    MenuExportDto: MenuExportDto;
  }

  /**
   * Parameters for menuCreate
   */
  export interface MenuCreateParams {
    companyId: string;
    MenuIterationDto: MenuIterationDto;
  }

  /**
   * Parameters for menuCreateLegacy
   */
  export interface MenuCreateLegacyParams {
    companyId: string;
    MenuIterationDto: MenuIterationDto;
  }

  /**
   * Parameters for companyBankAccountAdd
   */
  export interface CompanyBankAccountAddParams {
    companyId: string;
    CompanyBankAccountAddDto: CompanyBankAccountAddDto;
  }

  /**
   * Parameters for companyBankAccountGet
   */
  export interface CompanyBankAccountGetParams {
    companyId: string;
    bankId: string;
  }

  /**
   * Parameters for companyBankAccountDelete
   */
  export interface CompanyBankAccountDeleteParams {
    companyId: string;
    bankId: string;
  }

  /**
   * Parameters for companyBankAccountSetDefault
   */
  export interface CompanyBankAccountSetDefaultParams {
    companyId: string;
    bankId: string;
  }

  /**
   * Parameters for devicePairFromId
   */
  export interface DevicePairFromIdParams {
    companyId: string;
    DevicePairFromCodeRequestDto: DevicePairFromCodeRequestDto;
  }

  /**
   * Parameters for deviceUnpair
   */
  export interface DeviceUnpairParams {
    storeId: string;
    deviceId: string;
    companyId: string;
  }

  /**
   * Parameters for deviceList
   */
  export interface DeviceListParams {
    storeId: string;
    companyId: string;
  }

  /**
   * Parameters for devicePairCreate
   */
  export interface DevicePairCreateParams {
    companyId: string;
    CreatePairDeviceRequestDto: CreatePairDeviceRequestDto;
  }

  /**
   * Parameters for merchantDeviceSetActive
   */
  export interface MerchantDeviceSetActiveParams {
    isActive: string;
    companyId: string;
  }

  /**
   * Parameters for merchantDeviceCheckin
   */
  export interface MerchantDeviceCheckinParams {
    companyId: string;
    status?: 'active' | 'inactive';
    nativeVersionCode?: string;
    nativeVersion?: string;
    clientTimestamp?: string;
    buildNumber?: string;
  }

  /**
   * Parameters for merchantDeviceCheckinOnlyPut
   */
  export interface MerchantDeviceCheckinOnlyPutParams {
    ifNoneMatch: string;
    companyId: string;
    CheckinRequestDto: CheckinRequestDto;
  }

  /**
   * Parameters for merchantDeviceCheckinOnly
   */
  export interface MerchantDeviceCheckinOnlyParams {
    ifNoneMatch: string;
    companyId: string;
    status?: 'active' | 'inactive';
    nativeVersionCode?: string;
    nativeVersion?: string;
    clientTimestamp?: string;
    buildNumber?: string;
  }

  /**
   * Parameters for merchantDeviceOrderStats
   */
  export interface MerchantDeviceOrderStatsParams {
    ifNoneMatch: string;
    companyId: string;
  }

  /**
   * Parameters for merchantDeviceActiveOrders
   */
  export interface MerchantDeviceActiveOrdersParams {
    ifNoneMatch: string;
    companyId: string;
    includeCurbsidePendingPickup?: boolean;
    includeComplete?: boolean;
  }

  /**
   * Parameters for merchantDeviceArchivedOrders
   */
  export interface MerchantDeviceArchivedOrdersParams {
    ifNoneMatch: string;
    companyId: string;
  }

  /**
   * Parameters for merchantDeviceInactiveOrders
   */
  export interface MerchantDeviceInactiveOrdersParams {
    ifNoneMatch: string;
    companyId: string;
  }

  /**
   * Parameters for customerListPaginated
   */
  export interface CustomerListPaginatedParams {
    sortField?: 'firstName' | 'lastName' | 'firstPurchase' | 'lastPurchase' | 'goodsTotal' | 'orderCount';
    sortDirection?: 'ASC' | 'DESC';
    search?: string;
    offset?: string;
    count?: string;
  }

  /**
   * Parameters for promoGet
   */
  export interface PromoGetParams {
    storeId?: string;
    companyId?: string;
  }

  /**
   * Parameters for apiAppCreateCredential
   */
  export interface ApiAppCreateCredentialParams {
    apiAppId: string;
    ApiAppCredentialCreateDto: ApiAppCredentialCreateDto;
  }

  /**
   * Parameters for apiAppRevokeCredential
   */
  export interface ApiAppRevokeCredentialParams {
    apiCredentialId: string;
    apiAppId: string;
  }

  /**
   * Parameters for apiAppRollCredentialSecret
   */
  export interface ApiAppRollCredentialSecretParams {
    apiCredentialId: string;
    apiAppId: string;
  }
}

export { ApiService, ApiServiceTokenProvider, ApiServiceTokenProviderInjectionToken }
