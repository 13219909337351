import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { StringUtilService } from 'src/app/shared/services/string-util/string-util.service';
var RecentOrderReorderComponent = (function () {
    function RecentOrderReorderComponent(router, dialogRef, stringUtilService, data) {
        this.router = router;
        this.dialogRef = dialogRef;
        this.stringUtilService = stringUtilService;
        this.data = data;
    }
    RecentOrderReorderComponent.prototype.ngOnInit = function () {
        this.order = this.data.order;
    };
    RecentOrderReorderComponent.prototype.orderFrom = function (storeName, storeId) {
        this.router.navigateByUrl("/stores/" + this.stringUtilService.urlify(storeName) + "/" + storeId + "/details/(store:order)");
        this.dialogRef.close();
    };
    return RecentOrderReorderComponent;
}());
export { RecentOrderReorderComponent };
