import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AndroidFullScreen } from '@ionic-native/android-full-screen';
import { Push } from '@ionic-native/push';
import { NativePushService } from './services/native-push/native-push.service';
import { Device } from '@ionic-native/device';
import { AppVersion } from '@ionic-native/app-version';

import { NativeDisplayService } from './services/native-display/native-display.service';
import { NativeEnvironmentService } from './services/native-environment/native-environment.service';
import { Stripe } from '@ionic-native/stripe';
import { NativePaymentService } from './services/native-payment/native-payment.service';
import { DeviceRegistrationService } from '../../services/device-registration/device-registration.service';
import { HTTP } from '@ionic-native/http';
import { NativeInitializationService } from './services/native-initialization/native-initialization.service';
import { NativeBackButtonService } from './services/native-back-button/native-back-button.service';
import { Insomnia } from '@ionic-native/insomnia';
import { NativeAudioVolumeService } from './services/native-audio-volume/native-audio-volume.service';
import { NativeAppUpdateService } from './services/native-app-update/native-app-update.service';
import { SocialSharing } from '@ionic-native/social-sharing';
import { NativeSocialShareService } from './services/native-social-share/native-social-share.service';
import { Sim } from '@ionic-native/sim';
import { NativeSimInfoService } from './services/native-sim-info/native-sim-info.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    // Application services
    DeviceRegistrationService,

    // ionic native services
    AndroidFullScreen,
    AppVersion,
    Device,
    Stripe,
    Push, // push notifications
    HTTP,
    Insomnia,
    SocialSharing,
    Sim,

    // our services
    NativeDisplayService,
    NativeInitializationService,
    NativeAudioVolumeService,
    NativeAppUpdateService,
    NativeSimInfoService,
  ],
  exports: [
  ],
})
export class NativeSupportModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NativeSupportModule,
      providers: [
        NativePushService,
        NativeEnvironmentService,
        NativePaymentService,
        NativeBackButtonService,
        NativeSocialShareService,
      ],
    };
  }
}
