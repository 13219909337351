import { MatBottomSheetRef } from '@angular/material';
import { SOCIAL_SHARE_DEFAULT_MESSAGE } from 'src/app/shared/services/social-share/social-share.service';
var SocialShareBottomSheetComponent = (function () {
    function SocialShareBottomSheetComponent(bottomSheetRef) {
        this.bottomSheetRef = bottomSheetRef;
        this.facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=get.joe.coffee';
        this.twitterShareUrl = "http://www.twitter.com/share?url=get.joe.coffee&text=" + encodeURI(SOCIAL_SHARE_DEFAULT_MESSAGE) + "&via=joecoffeeapp";
    }
    SocialShareBottomSheetComponent.prototype.copyToClipboard = function () {
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = 'https://get.joe.coffee';
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.dismiss('copy');
    };
    SocialShareBottomSheetComponent.prototype.dismiss = function (shareSource) {
        this.bottomSheetRef.dismiss(shareSource);
    };
    return SocialShareBottomSheetComponent;
}());
export { SocialShareBottomSheetComponent };
