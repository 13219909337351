import * as i0 from "./ui-confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/common";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "./ui-confirm-dialog.component";
var styles_UiConfirmDialogComponent = [i0.styles];
var RenderType_UiConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UiConfirmDialogComponent, data: {} });
export { RenderType_UiConfirmDialogComponent as RenderType_UiConfirmDialogComponent };
function View_UiConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "confirm-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); }); }
function View_UiConfirmDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["color", "primary"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dialogRef.close(i1.ɵnov(_v, 1).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(2, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵted(-1, 0, [" Cancel "]))], function (_ck, _v) { var currVal_3 = ""; _ck(_v, 1, 0, currVal_3); var currVal_4 = "primary"; _ck(_v, 2, 0, currVal_4); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaLabel; var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 4).inline; var currVal_6 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_5, currVal_6); }); }
function View_UiConfirmDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["checkmark"])), (_l()(), i1.ɵted(-1, null, [" Confirm "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_UiConfirmDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵted(-1, null, [" Exit "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_UiConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UiConfirmDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "mat-dialog-content", [["class", "confirm-content mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(5, 540672, null, 0, i10.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 12, "mat-dialog-actions", [["class", "confirm-actions mat-dialog-actions"], ["fxLayout", "row"], ["fxLayoutAlign", "space-around center"]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i11.DefaultLayoutDirective, [i1.ElementRef, i12.StyleUtils, [2, i11.LayoutStyleBuilder], i12.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(8, 671744, null, 0, i11.DefaultLayoutAlignDirective, [i1.ElementRef, i12.StyleUtils, [2, i11.LayoutAlignStyleBuilder], i12.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UiConfirmDialogComponent_2)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "button", [["color", "primary"], ["mat-flat-button", ""], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UiConfirmDialogComponent_3)), i1.ɵdid(16, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UiConfirmDialogComponent_4)), i1.ɵdid(18, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.content; _ck(_v, 5, 0, currVal_1); var currVal_2 = "row"; _ck(_v, 7, 0, currVal_2); var currVal_3 = "space-around center"; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.cancellable; _ck(_v, 11, 0, currVal_4); var currVal_8 = true; _ck(_v, 13, 0, currVal_8); var currVal_9 = "primary"; _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.cancellable; _ck(_v, 16, 0, currVal_10); var currVal_11 = !_co.cancellable; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var currVal_5 = i1.ɵnov(_v, 13).ariaLabel; var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_UiConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-confirm-dialog", [], null, null, null, View_UiConfirmDialogComponent_0, RenderType_UiConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i13.UiConfirmDialogComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UiConfirmDialogComponentNgFactory = i1.ɵccf("ui-confirm-dialog", i13.UiConfirmDialogComponent, View_UiConfirmDialogComponent_Host_0, {}, {}, []);
export { UiConfirmDialogComponentNgFactory as UiConfirmDialogComponentNgFactory };
