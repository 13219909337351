import { EnvironmentService } from '../environment/environment.service';
var FeatureSwitchService = (function () {
    function FeatureSwitchService(environmentService) {
        this.baseFeatureSwitches = environmentService.getConfig().featureSwitches;
    }
    FeatureSwitchService.prototype.initQueryParams = function () {
        FeatureSwitchService.queryParamCache = this.getQueryParamMap();
    };
    FeatureSwitchService.prototype.active = function (name) {
        var queryParams = FeatureSwitchService.queryParamCache;
        return this.baseFeatureSwitches[name] === true || !!(queryParams && queryParams[name] === 'true');
    };
    FeatureSwitchService.prototype.getValue = function (name) {
        var queryParams = FeatureSwitchService.queryParamCache;
        return this.baseFeatureSwitches[name] || queryParams ? queryParams[name] : undefined;
    };
    FeatureSwitchService.prototype.getQueryParamMap = function () {
        var result = {};
        var queryString = location.search;
        if (queryString) {
            var params = queryString.slice(1).split('&');
            params.forEach(function (param) {
                var _a = param.split('='), key = _a[0], value = _a[1];
                result[key] = decodeURIComponent(value);
            });
        }
        return result;
    };
    FeatureSwitchService.queryParamCache = {};
    return FeatureSwitchService;
}());
export { FeatureSwitchService };
