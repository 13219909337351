import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { Schema } from 'swagger-schema-official';
import { Validator } from 'jsonschema';
import { isEmpty as _isEmpty } from 'lodash';
import { Subject, Observable } from 'rxjs';
import { takeWhile, debounceTime } from 'rxjs/operators';

interface FieldError {
  name: string;
  message: string;
}

export type ErrorAccessor = (...fieldPath: string[]) => ValidationErrors;

// Usage Example: 'field.path': { required: 'this thing is required, dawg!' }
export interface ErrorMessageMap {
  [fieldPath: string]: {
    [type: string]: string;
  } | string;
}

export interface FieldErrors {
  fieldErrorMap: FieldErrorMap;
  fieldErrorPaths: string[];
  control: FormGroup;
}

export interface FieldErrorMapComponent {
  control: AbstractControl;
  errors: FieldError[];
}

export interface FieldErrorMap {
  [name: string]: FieldErrorMapComponent;
}

function createFieldError(control: AbstractControl): FieldErrorMapComponent {
  return { control, errors: [] };
}

export function addFieldError(control: AbstractControl, path: string, errors: FieldError[], targetMap: FieldErrorMap) {
  if (!targetMap.hasOwnProperty(path)) {
    targetMap[path] = createFieldError(control);
  }
  targetMap[path].errors.push(...errors);
}

export type ValidatorFunction = (control: FormGroup) => FieldErrorMap;

@Injectable()
export class ReactiveFormService implements OnDestroy {

  private validator: Validator;
  private errorSubject: Subject<FieldErrors> = new Subject<FieldErrors>();
  private serviceActive: boolean;
  private errorObservable: Observable<FieldErrors>;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.validator = new Validator();
    this.serviceActive = true;
    this.errorObservable = this.errorSubject.pipe(takeWhile(() => this.serviceActive), debounceTime(100));
    this.errorObservable.subscribe(errors => this.updateFieldErrors(errors));
  }

  ngOnDestroy() {
    this.serviceActive = false;
  }

  createModelValidatedForm<T>(model: Schema, data: T, additionalValidators: ValidatorFunction[] = []): FormGroup {
    const dataClone = Object.assign({}, data);
    const formGroup = this.formBuilder.group(dataClone);
    formGroup.setValidators([this.createModelValidator(model, additionalValidators)]);

    return formGroup;
  }

  async isValid<T>(model: Schema, data: T): Promise<boolean> {
    // as any because of slight difference between swagger and jsonschema type defs
    const validationResult = await this.validator.validate(data, model as any);
    const errors = (validationResult && validationResult.errors) || [];
    return errors && errors.length === 0;
  }

  createErrorAccessor(form: FormGroup, errorMessages: ErrorMessageMap = {}): ErrorAccessor {
    return (...fieldPathSegments): FieldError => {
      const field = form.get(fieldPathSegments);
      if (!field || !field.errors || !field.errors.length) {
        return;
      }
      const error = field.errors[0];

      const fieldPath = fieldPathSegments.join('.');
      if (errorMessages.hasOwnProperty(fieldPath)) {
        const fieldMessages = errorMessages[fieldPath];
        if (typeof fieldMessages === 'string') {
          return { name: error.name, message: fieldMessages };
        } else {
          if (fieldMessages.hasOwnProperty(error.name)) {
            const message = fieldMessages[error.name];
            return { name: error.name, message };
          }
        }

      }
      return error;
    };
  }

  private updateFieldErrors({ fieldErrorMap, control, fieldErrorPaths }: FieldErrors): void {
    if (!fieldErrorPaths || fieldErrorPaths.length < 1) {
      control.setErrors(null);
      return;
    }

    fieldErrorPaths.forEach(path => {
      const fieldError = fieldErrorMap[path];
      fieldError.control.setErrors(fieldError.errors);
    });
  }

  private createModelValidator(model: Schema, additionalValidators: ValidatorFunction[]): ValidatorFn {

    return (control: FormGroup): ValidationErrors | null => {
      (async () => {

        const data = control.getRawValue();

        const fieldErrorMap: FieldErrorMap = {};

        // manual require checking because jsonschema doesn't seem to be doing it
        // TODO: nested properties (currently just root properties are checked)
        if (model.required) {
          model.required.forEach(path => {
            const fieldData = data[path];

            if (typeof fieldData === 'string' && fieldData.length < 1) {
              const field = control.get(path);
              if (field) {
                const fieldErrors: FieldError[] = [{ name: 'required', message: 'This field is required.' }];
                addFieldError(field, path, fieldErrors, fieldErrorMap);
              }
            }

          });
        }

        // json schema validation
        // as any because of slight difference between swagger and jsonschema type defs
        const validationResult = await this.validator.validate(data, model as any);
        const errors = (validationResult && validationResult.errors) || [];

        for (let i = 0, len = errors.length; i < len; i++) {
          const { property, name, message } = errors[i];

          const path = property.replace(/^instance\./, '').replace(/\[(\d*)\]/g, '.$1');
          const field = control.get(path);

          if (field) {
            const fieldErrors: FieldError[] = [{ name, message }];
            addFieldError(field, path, fieldErrors, fieldErrorMap);
          }
        }

        // run passed in additional validators and merge results
        if (additionalValidators && additionalValidators.length > 0) {
          additionalValidators.forEach(fn => {
            const validatorResults = fn(control);
            const paths = Object.keys(validatorResults);
            if (paths.length) {
              paths.forEach(path => {
                const result = validatorResults[path];
                addFieldError(result.control, path, result.errors, fieldErrorMap);
              });
            }
          });
        }

        const fieldErrorPaths = Object.keys(fieldErrorMap);
        this.errorSubject.next({ control, fieldErrorMap, fieldErrorPaths });
      })();
      return null;
    };
  }

}
