<div *ngIf="criticalUpdateNeeded" class="update-required-container">
  <h3>Update required</h3>
  <p>The app needs to be updated to proceed.</p>
  <p>Please update from the app store directly.</p>
  <p>&nbsp;</p>
  <p *ngIf="!merchantMode"><a href="https://get.joe.coffee" target="_blank">Update Now!</a></p>
  <p *ngIf="merchantMode">Having trouble? Give us a call: 1-206-531-3212</p>
</div>
<div *ngIf="loading && !criticalUpdateNeeded" class="updating-message">
  <ng-container *ngIf="checkingForUpdate">Checking for app updates...</ng-container>
  <small>version {{versionShortCode}}</small>
</div>
<ng-container *ngIf="!loading && !criticalUpdateNeeded">
  <ui-pos-chrome *ngIf="merchantMode"></ui-pos-chrome>
  <ui-merchant-chrome *ngIf="merchantMode"></ui-merchant-chrome>
  <ui-consumer-chrome *ngIf="!merchantMode"></ui-consumer-chrome>
</ng-container>