import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export enum TopbarShowStyle {
  hidden,
  show,
  bounceIn,
}

export interface TopbarOptions {
  title: string;
  show: TopbarShowStyle;
}

@Injectable()
export class TopbarService {
  private optionChangeSubject: Subject<TopbarOptions> = new Subject();

  private topbarOptions: TopbarOptions = {
    title: '',
    show: TopbarShowStyle.hidden,
  };

  hide(): void {
    this.setOptions({ show: TopbarShowStyle.hidden });
  }

  show(style: TopbarShowStyle = TopbarShowStyle.show): void {
    this.setOptions({ show: style });
  }

  setTitle(title: string): void {
    this.topbarOptions.title = title;
    this.emitOptionChanges();
  }

  setOptions(options: Partial<TopbarOptions>): void {
    Object.assign(this.topbarOptions, options);
    this.emitOptionChanges();
  }

  getOptions(): TopbarOptions {
    return this.topbarOptions;
  }

  watchOptions(): Observable<TopbarOptions> {
    return this.optionChangeSubject.asObservable();
  }

  private emitOptionChanges(): void {
    this.optionChangeSubject.next(this.topbarOptions);
  }
}
