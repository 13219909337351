import { Injectable } from '@angular/core';
import { dragula, Drake as BaseDrake, DragulaOptions } from 'dragula-reborn';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

interface Drake extends BaseDrake {
  containerIds: (string | number)[];
}

export const enum DragulaEventType {
  remove, drag, cancel, drop,
}

export interface DrakeEvent {
  type: DragulaEventType;
  drakeName: string;
  element?: Element;
  container?: Element;
  source?: Element;
  target?: Element;
}

export interface DragulaEvent {
  type: DragulaEventType;
  groupName: string;
  sourceContainer: string | number;
  targetContainer?: string | number;
  sourceIndex: number;
  targetIndex?: number;
  sourceEvent: DrakeEvent;
}

@Injectable()
export class DragulaService {

  private dragula = dragula;
  private drakes: { [name: string]: Drake } = {};
  private drakeEventSubject: Subject<DragulaEvent> = new Subject<DragulaEvent>();
  private sourceDragIndex: number;
  private sourceContainerId: string | number;

  constructor() { }

  removeContainer(name: string, container: Element) {
    const drake = this.getDrake(name);
    const containerIndex = drake.containers.indexOf(container);

    if (containerIndex > -1) {
      drake.containers.splice(containerIndex, 1);
      drake.containerIds.splice(containerIndex, 1);
    }

    if (drake.containers.length < 1) {
      drake.destroy();
      this.drakes[name] = undefined;
    }
  }

  addContainer(name: string, container: Element, id?: string, options?: DragulaOptions): string | number {
    const drake = this.getDrake(name, options);
    const containerId = id || drake.containers.length;
    drake.containers.push(container);
    drake.containerIds.push(containerId);
    return containerId;
  }

  updateContainerId(name: string, container: Element, id: string) {
    const drake = this.getDrake(name);
    const containerIdx = drake.containers.indexOf(container);
    if (containerIdx !== -1) {
      drake.containerIds[containerIdx] = id;
    }
  }

  on(eventType: DragulaEventType, groupName: string, containerId?: string | number): Observable<DragulaEvent> {
    return this.drakeEventSubject.asObservable().pipe(filter(event => {
      if (eventType !== event.type || groupName !== event.groupName) {
        return false;
      }

      const eventContainerId = event.type === DragulaEventType.drop ? event.targetContainer : event.sourceContainer;
      return containerId !== undefined ? eventContainerId === containerId : true;
    }));
  }

  destroy() {
    Object.keys(this.drakes).forEach(name => this.drakes[name].destroy());
    this.drakes = {};
  }

  /**
   * Find or create a drake
   *
   * (options are discarded except on create)
   */
  private getDrake(name: string, options: DragulaOptions = {}): Drake {
    return this.drakes[name] || this.createDrake(name, options);
  }

  private createDrake(name: string, options: DragulaOptions): Drake {

    const drake = this.dragula([], options) as Drake;
    drake.containerIds = [];

    this.drakes[name] = drake;

    this.addListeners(name, drake);

    return drake;
  }

  private handleDrakeEvent(event: DrakeEvent) {
    const drake = this.drakes[event.drakeName];
    switch (event.type) {

      case DragulaEventType.drag:
        this.sourceDragIndex = this.domIndexOf(event.element, event.source);
        const sourceContainerIndex = drake.containers.indexOf(event.source);
        this.sourceContainerId = drake.containerIds[sourceContainerIndex];
        this.drakeEventSubject.next({
          type: event.type,
          groupName: event.drakeName,
          sourceContainer: this.sourceContainerId,
          sourceIndex: this.sourceDragIndex,
          sourceEvent: event,
        });
        break;

      case DragulaEventType.drop:
        const dropIndex = this.domIndexOf(event.element, event.target);
        const targetContainerIndex = drake.containers.indexOf(event.target);
        const targetContainerId = drake.containerIds[targetContainerIndex];
        this.drakeEventSubject.next({
          type: event.type,
          groupName: event.drakeName,
          sourceContainer: this.sourceContainerId,
          targetContainer: targetContainerId,

          sourceIndex: this.sourceDragIndex,
          targetIndex: dropIndex,
          sourceEvent: event,
        });
        if (this.sourceContainerId !== targetContainerId) {
          this.handleDrakeEvent(Object.assign({}, event, { type: DragulaEventType.remove }));
        }
        break;

      case DragulaEventType.remove:
      case DragulaEventType.cancel:
        this.drakeEventSubject.next({
          type: event.type,
          groupName: event.drakeName,
          sourceContainer: this.sourceContainerId,
          sourceIndex: this.sourceDragIndex,
          sourceEvent: event,
        });
        break;

    }
  }

  private addListeners(drakeName: string, drake: Drake): void {
    drake.on('remove', (element: Element, container: Element, source: Element) =>
      this.handleDrakeEvent({ type: DragulaEventType.remove, drakeName, element, container, source }));

    drake.on('drag', (element: Element, source: Element) =>
      this.handleDrakeEvent({ type: DragulaEventType.drag, drakeName, element, source }));

    drake.on('cancel', () =>
      this.handleDrakeEvent({ type: DragulaEventType.cancel, drakeName }));

    drake.on('drop', (element: Element, target: Element, source: Element) =>
      this.handleDrakeEvent({ type: DragulaEventType.drop, drakeName, element, target, source }));

  }

  private domIndexOf(child: Element, parent: Element): any {
    return Array.prototype.indexOf.call(parent.children, child);
  }
}
