import { StoreMenuSize } from '../../interfaces/store/store-menu.interface';
import { OrderItemOption, OrderItemOptionDescriptor } from '../order-item-option/order-item-option.class';
import { OrderItemOptionChoice, OrderItemOptionChoiceDescriptor, SizePricesMap } from '../order-item-option-choice/order-item-option-choice.class';
import { MenuItemOptionDto, MenuOptionWithChoicesDto } from 'src/gen/joeServerCore';
import { MergeMenuOption, MergeMenuItem } from 'src/app/shared/models/merge-menu/merge-menu';
import { keyBy as _keyBy } from 'lodash';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';

export type OrderItemMenuOption = MenuOptionWithChoicesDto & MenuItemOptionDto;

export class OrderItem {

  readonly options: OrderItemOption[] = [];
  itemId: string;
  categoryId: number;
  sectionId: number;
  availableOptions: Set<number>;
  selectedOptions: Set<number> = new Set([]);
  sizeIndex = 0;
  note = '';

  constructor(private readonly menuItem: MergeMenuItem, categoryId: number, sectionId: number, isReorder: boolean = false) {
    this.options = menuItem.options ? this.mapMenuItemOptions(menuItem.options) : [];
    this.itemId = menuItem.id;
    this.categoryId = categoryId;
    this.sectionId = sectionId;
    const availableOptionsArr = menuItem.options ? this.options.map((_, i) => {
      return i;
    }) : [];
    this.availableOptions = new Set(availableOptionsArr);
    if (!isReorder) {
      this.setSelectedOptions(this.options);
    }
    this.setInStockSize();
  }

  private mapMenuItemOptions(menuOptions: MergeMenuOption[]): OrderItemOption[] {
    return menuOptions.map((option, index) => {
      const choices: OrderItemOptionChoice[] = this.mapMenuItemChoices(option);

      const optionDescriptor: OrderItemOptionDescriptor = {
        id: option.id,
        name: option.name,
        index: index,
        minChoices: option.minimumChoices,
        maxChoices: option.maximumChoices,
        choices,
      };

      return new OrderItemOption(optionDescriptor);
    });
  }

  getImage(): string {
    return safeGet(this.menuItem, i => i.image);
  }

  getTags(): string[] {
    return (this.menuItem && this.menuItem.metaData && this.menuItem.metaData.tags) || [];
  }

  getQuantity(defaultQuantity: number, finiteQuantity: boolean): number {
    if (defaultQuantity !== undefined) {
      return defaultQuantity;
    }
    return finiteQuantity === true ? 1 : 0;
  }

  private mapMenuItemChoices(option: MergeMenuOption): OrderItemOptionChoice[] {
    return option.choices.map((choice, index) => {

      const choiceDescriptor: OrderItemOptionChoiceDescriptor = {
        id: choice.id,
        flags: choice.flags || [],
        hidden: choice.flags.includes('hidden'),
        name: choice.name,
        index: index,
        quantity: this.getQuantity(option.defaultQuantity, option.finiteQuantity),
        unit: option.quantityUnit,
        quantityNames: option.quantityNames,
        maxQuantity: option.maximumQuantity || -1,
        minQuantity: option.minimumQuantity || 1,
        increment: option.quantityIncrement || 1,
        price: choice.price || '0',
      };

      const sizePriceMap: SizePricesMap = {};

      if (option.sizePrices && option.sizePrices.length > 0) {
        option.sizePrices.forEach(sizePrice => {
          if (sizePrice.menuChoiceId && sizePrice.menuChoiceId.find(choiceId => choiceId === choice.id)) {
            sizePriceMap[sizePrice.menuSizeId] = sizePrice.price;
          }
        });
      }

      return new OrderItemOptionChoice(choiceDescriptor, sizePriceMap);
    });
  }

  private setSelectedOptions(orderOptions: OrderItemOption[]): void {
    orderOptions.forEach((option, optionIndex) => {
      option.choices.forEach((choice, choiceIndex) => {
        if (choice.flags.includes('default')) {
          this.addOption(optionIndex, choiceIndex);
        }
      });
    });
  }

  private setInStockSize(): void {
    const foundSizeIndex = this.menuItem.sizes.findIndex(
      size => {
        if (!!size.inventory && size.inventory.length > 0) {
          return size.inventory[0].quantity !== 0;
        } else {
          return true;
        }
      },
    );
    this.sizeIndex = foundSizeIndex !== -1 ? foundSizeIndex : 0;
  }

  getAvailableOptions(): OrderItemOption[] {
    const availableOptionsArr = Array.from(this.availableOptions);
    return availableOptionsArr.map(optionIndex => this.options[optionIndex]);
  }

  getSelectedOptions(): OrderItemOption[] {
    const selectedOptionsArr = Array.from(this.selectedOptions);
    return selectedOptionsArr.map(optionIndex => this.options[optionIndex]);
  }

  setNote(note: string): void {
    this.note = note;
  }

  getDescription(): string {
    return this.menuItem.description;
  }

  getProductId(): string {
    return this.menuItem.name;
  }

  getName(): string {
    const selectedSize = this.menuItem.sizes[this.sizeIndex];
    if (selectedSize.name) {
      return selectedSize.name + ' ' + this.menuItem.name;
    } else {
      return (selectedSize.quantity + selectedSize.quantityUnit) + ' ' + this.menuItem.name;
    }
  }

  getBasePrice(): string {
    return this.menuItem.sizes[this.sizeIndex].price;
  }

  getTotalPrice(): string {
    const basePrice = parseInt(this.getBasePrice(), 10);
    const optionChoicePrices: number[] = [];

    this.getSelectedOptions().forEach(
      option => option.getSelectedChoices().forEach(
        choice => {
          if (choice.flags.indexOf('free') === -1) {
            optionChoicePrices.push(parseInt(choice.getPrice(this.getSize().id), 10));
          }
        },
      ));

    const totalDrinkPrice = basePrice + optionChoicePrices.reduce((a, b) => a + b, 0);
    return totalDrinkPrice.toString();
  }

  getSizes(): StoreMenuSize[] {
    return this.menuItem.sizes;
  }

  getSize(): StoreMenuSize {
    const selectedSize = this.menuItem.sizes[this.sizeIndex];
    if (selectedSize.name !== '') {
      return selectedSize;
    } else {
      selectedSize.name = selectedSize.quantity + selectedSize.quantityUnit;
      return selectedSize;
    }
  }

  getSizeIndex(): number {
    return this.sizeIndex;
  }

  setSize(index: number): void {
    this.sizeIndex = index;
  }

  resetOptions(): void {
    const options = this.options.map((_, i) => i);
    this.availableOptions = new Set(options);
    this.selectedOptions = new Set([]);
  }

  addOption(optionIndex: number, choiceIndex: number): boolean {
    const option = this.options[optionIndex];
    const choice = option.choices[choiceIndex];
    const isValidChoiceAddition = option.maxChoices !== -1 ? option.maxChoices > option.selectedChoices.size : true;

    if (isValidChoiceAddition) {
      const choiceAddResult = option.addChoice(choice.index);

      if (choiceAddResult) {
        this.selectedOptions.add(option.index);

        // if (option.maxChoices !== -1 && option.maxChoices === option.selectedChoices.size || option.availableChoices.size === 0) {
        //   this.removeAvailableOption(optionIndex);
        // }
      }

      return choiceAddResult;
    } else {
      return isValidChoiceAddition;
    }
  }

  removeAvailableOption(optionIndex: number) {
    this.availableOptions.delete(optionIndex);
  }

  addAvailableOption(optionIndex: number) {
    this.availableOptions.add(optionIndex);
    this.availableOptions = new Set(Array.from(this.availableOptions).sort((a, b) => {
      return a - b;
    }));
  }

  removeOption(optionIndex: number, choiceIndex: number): boolean {
    const option = this.getSelectedOptions()[optionIndex];
    const choice = option.getSelectedChoices()[choiceIndex];
    const isOnlySelectedChoice = option.selectedChoices.size === 1 && option.selectedChoices.has(choice.index) ? true : false;

    if (isOnlySelectedChoice) {
      const choiceRemoveResult = option.removeChoice(choice.index);

      if (choiceRemoveResult) {
        this.selectedOptions.delete(option.index);

        // if (option.maxChoices !== -1 && option.maxChoices > option.selectedChoices.size || option.availableChoices.size > 0) {
        //   this.addAvailableOption(option.index);
        // }
      }

      return choiceRemoveResult;
    } else {
      return option.removeChoice(choice.index);
    }
  }
}
