import { OrderItemOption } from '../order-item-option/order-item-option.class';
import { OrderItemOptionChoice } from '../order-item-option-choice/order-item-option-choice.class';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
var OrderItem = (function () {
    function OrderItem(menuItem, categoryId, sectionId, isReorder) {
        if (isReorder === void 0) { isReorder = false; }
        this.menuItem = menuItem;
        this.options = [];
        this.selectedOptions = new Set([]);
        this.sizeIndex = 0;
        this.note = '';
        this.options = menuItem.options ? this.mapMenuItemOptions(menuItem.options) : [];
        this.itemId = menuItem.id;
        this.categoryId = categoryId;
        this.sectionId = sectionId;
        var availableOptionsArr = menuItem.options ? this.options.map(function (_, i) {
            return i;
        }) : [];
        this.availableOptions = new Set(availableOptionsArr);
        if (!isReorder) {
            this.setSelectedOptions(this.options);
        }
        this.setInStockSize();
    }
    OrderItem.prototype.mapMenuItemOptions = function (menuOptions) {
        var _this = this;
        return menuOptions.map(function (option, index) {
            var choices = _this.mapMenuItemChoices(option);
            var optionDescriptor = {
                id: option.id,
                name: option.name,
                index: index,
                minChoices: option.minimumChoices,
                maxChoices: option.maximumChoices,
                choices: choices,
            };
            return new OrderItemOption(optionDescriptor);
        });
    };
    OrderItem.prototype.getImage = function () {
        return safeGet(this.menuItem, function (i) { return i.image; });
    };
    OrderItem.prototype.getTags = function () {
        return (this.menuItem && this.menuItem.metaData && this.menuItem.metaData.tags) || [];
    };
    OrderItem.prototype.getQuantity = function (defaultQuantity, finiteQuantity) {
        if (defaultQuantity !== undefined) {
            return defaultQuantity;
        }
        return finiteQuantity === true ? 1 : 0;
    };
    OrderItem.prototype.mapMenuItemChoices = function (option) {
        var _this = this;
        return option.choices.map(function (choice, index) {
            var choiceDescriptor = {
                id: choice.id,
                flags: choice.flags || [],
                hidden: choice.flags.includes('hidden'),
                name: choice.name,
                index: index,
                quantity: _this.getQuantity(option.defaultQuantity, option.finiteQuantity),
                unit: option.quantityUnit,
                quantityNames: option.quantityNames,
                maxQuantity: option.maximumQuantity || -1,
                minQuantity: option.minimumQuantity || 1,
                increment: option.quantityIncrement || 1,
                price: choice.price || '0',
            };
            var sizePriceMap = {};
            if (option.sizePrices && option.sizePrices.length > 0) {
                option.sizePrices.forEach(function (sizePrice) {
                    if (sizePrice.menuChoiceId && sizePrice.menuChoiceId.find(function (choiceId) { return choiceId === choice.id; })) {
                        sizePriceMap[sizePrice.menuSizeId] = sizePrice.price;
                    }
                });
            }
            return new OrderItemOptionChoice(choiceDescriptor, sizePriceMap);
        });
    };
    OrderItem.prototype.setSelectedOptions = function (orderOptions) {
        var _this = this;
        orderOptions.forEach(function (option, optionIndex) {
            option.choices.forEach(function (choice, choiceIndex) {
                if (choice.flags.includes('default')) {
                    _this.addOption(optionIndex, choiceIndex);
                }
            });
        });
    };
    OrderItem.prototype.setInStockSize = function () {
        var foundSizeIndex = this.menuItem.sizes.findIndex(function (size) {
            if (!!size.inventory && size.inventory.length > 0) {
                return size.inventory[0].quantity !== 0;
            }
            else {
                return true;
            }
        });
        this.sizeIndex = foundSizeIndex !== -1 ? foundSizeIndex : 0;
    };
    OrderItem.prototype.getAvailableOptions = function () {
        var _this = this;
        var availableOptionsArr = Array.from(this.availableOptions);
        return availableOptionsArr.map(function (optionIndex) { return _this.options[optionIndex]; });
    };
    OrderItem.prototype.getSelectedOptions = function () {
        var _this = this;
        var selectedOptionsArr = Array.from(this.selectedOptions);
        return selectedOptionsArr.map(function (optionIndex) { return _this.options[optionIndex]; });
    };
    OrderItem.prototype.setNote = function (note) {
        this.note = note;
    };
    OrderItem.prototype.getDescription = function () {
        return this.menuItem.description;
    };
    OrderItem.prototype.getProductId = function () {
        return this.menuItem.name;
    };
    OrderItem.prototype.getName = function () {
        var selectedSize = this.menuItem.sizes[this.sizeIndex];
        if (selectedSize.name) {
            return selectedSize.name + ' ' + this.menuItem.name;
        }
        else {
            return (selectedSize.quantity + selectedSize.quantityUnit) + ' ' + this.menuItem.name;
        }
    };
    OrderItem.prototype.getBasePrice = function () {
        return this.menuItem.sizes[this.sizeIndex].price;
    };
    OrderItem.prototype.getTotalPrice = function () {
        var _this = this;
        var basePrice = parseInt(this.getBasePrice(), 10);
        var optionChoicePrices = [];
        this.getSelectedOptions().forEach(function (option) { return option.getSelectedChoices().forEach(function (choice) {
            if (choice.flags.indexOf('free') === -1) {
                optionChoicePrices.push(parseInt(choice.getPrice(_this.getSize().id), 10));
            }
        }); });
        var totalDrinkPrice = basePrice + optionChoicePrices.reduce(function (a, b) { return a + b; }, 0);
        return totalDrinkPrice.toString();
    };
    OrderItem.prototype.getSizes = function () {
        return this.menuItem.sizes;
    };
    OrderItem.prototype.getSize = function () {
        var selectedSize = this.menuItem.sizes[this.sizeIndex];
        if (selectedSize.name !== '') {
            return selectedSize;
        }
        else {
            selectedSize.name = selectedSize.quantity + selectedSize.quantityUnit;
            return selectedSize;
        }
    };
    OrderItem.prototype.getSizeIndex = function () {
        return this.sizeIndex;
    };
    OrderItem.prototype.setSize = function (index) {
        this.sizeIndex = index;
    };
    OrderItem.prototype.resetOptions = function () {
        var options = this.options.map(function (_, i) { return i; });
        this.availableOptions = new Set(options);
        this.selectedOptions = new Set([]);
    };
    OrderItem.prototype.addOption = function (optionIndex, choiceIndex) {
        var option = this.options[optionIndex];
        var choice = option.choices[choiceIndex];
        var isValidChoiceAddition = option.maxChoices !== -1 ? option.maxChoices > option.selectedChoices.size : true;
        if (isValidChoiceAddition) {
            var choiceAddResult = option.addChoice(choice.index);
            if (choiceAddResult) {
                this.selectedOptions.add(option.index);
            }
            return choiceAddResult;
        }
        else {
            return isValidChoiceAddition;
        }
    };
    OrderItem.prototype.removeAvailableOption = function (optionIndex) {
        this.availableOptions.delete(optionIndex);
    };
    OrderItem.prototype.addAvailableOption = function (optionIndex) {
        this.availableOptions.add(optionIndex);
        this.availableOptions = new Set(Array.from(this.availableOptions).sort(function (a, b) {
            return a - b;
        }));
    };
    OrderItem.prototype.removeOption = function (optionIndex, choiceIndex) {
        var option = this.getSelectedOptions()[optionIndex];
        var choice = option.getSelectedChoices()[choiceIndex];
        var isOnlySelectedChoice = option.selectedChoices.size === 1 && option.selectedChoices.has(choice.index) ? true : false;
        if (isOnlySelectedChoice) {
            var choiceRemoveResult = option.removeChoice(choice.index);
            if (choiceRemoveResult) {
                this.selectedOptions.delete(option.index);
            }
            return choiceRemoveResult;
        }
        else {
            return option.removeChoice(choice.index);
        }
    };
    return OrderItem;
}());
export { OrderItem };
