import { MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatButtonToggleModule, MatCheckboxModule, MatRadioModule, MatCardModule, MatOptionModule, MatSelectModule, MatProgressBarModule, MatDividerModule, MatSnackBarModule, } from '@angular/material';
import { StoreCheckoutBottomsheetComponent } from './store-checkout-bottomsheet/store-checkout-bottomsheet.component';
import { MatCurrencyInputModule } from 'src/app/shared/packages/mat-currency-input';
import { NewUserWelcomeDialogComponent } from './new-user-welcome-dialog/new-user-welcome-dialog.component';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatCurrencyInputModule,
];
var entryComponents = [
    StoreCheckoutBottomsheetComponent,
    NewUserWelcomeDialogComponent,
];
var StoreCheckoutModule = (function () {
    function StoreCheckoutModule() {
    }
    return StoreCheckoutModule;
}());
export { StoreCheckoutModule };
