import { Injectable } from '@angular/core';

import { Observable, Subject, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { merge as _merge } from 'lodash';

import { OrderItem } from '../../models/order-item/order-item.class';
import { ApiService, StoreDeleteItemInventoryResponseDto, MenuOptionWithChoicesDto, StoreItemInventoryStatusDto, StoreOptionInventoryStatusDto } from 'src/gen/joeServerCore';
import { MenuSerializerService } from 'src/app/shared/services/menu-serializer/menu-serializer.service';
import { MergeMenu, MergeMenuSection, MergeMenuItem } from 'src/app/shared/models/merge-menu/merge-menu';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { filterMap } from 'src/app/shared/helpers/array/filter-map';
import { retryBackoff } from 'backoff-rxjs';
export interface OrderItemIds {
  itemCategoryId: string;
  itemSectionId: string;
}

export interface MenuItemWithIndexes {
  item: MergeMenuItem;
  itemIndex: number;
  sectionIndex: number;
  categoryIndex: number;
}

interface MenuCache {
  menu: MergeMenu;
  created: number;
  flags: MenuCacheFlags;
}

interface MenuCacheFlags {
  includeSections: boolean;
  includeItemOptions: boolean;
  includeMenuOptions: boolean;
  includeInventory: boolean;
}

interface GetStoreMenuOptions {
  /** default = true */
  includeSections?: boolean;
  /** default = true */
  includeItemOptions?: boolean;
  /** default = true */
  includeMenuOptions?: boolean;
  /** default = true */
  includeInventory?: boolean;
  /** default = false */
  bypassCache?: boolean;
  includeStoreHidden?: boolean;
}

@Injectable()
export class StoreMenuService {

  constructor(
    private apiService: ApiService,
    private menuSerializerService: MenuSerializerService,
  ) { }

  static storeMenuCache: { [storeId: string]: MenuCache } = {};

  private orderItemSubject = new Subject<OrderItem>();
  private orderItem: OrderItem;
  private storeMenu: MergeMenu;
  private storeMenuOptions: MenuOptionWithChoicesDto[] = [];
  private storeId: string;
  private storeMenuFlags: MenuCacheFlags;

  public orderItemObservable = this.orderItemSubject.asObservable();

  setOrderItem(orderItem: OrderItem): void {
    this.orderItem = orderItem;
    this.updateOrderItem(this.orderItem);
  }

  getOrderItem(): OrderItem {
    return this.orderItem;
  }

  updateOrderItem(orderItem: OrderItem) {
    this.orderItemSubject.next(orderItem);
  }

  getStoreMenuCategorySectionsWithInventory(categoryId: string): Observable<MergeMenuSection[]> {
    if (!this.storeMenu || !this.storeMenu.categories) {
      return of([]);
    }

    const targetCategory = this.storeMenu.categories.find(({ id }) => id === categoryId);
    if (!targetCategory) {
      return of([]);
    }

    // already loaded this category
    if (targetCategory.sections && targetCategory.sections.length > 0) {
      return of(targetCategory.sections);
    }
    return this.apiService.storeGetMenuCategorySectionsWithInventory({ storeId: this.storeId, categoryId })
      .pipe(take(1), map(menuSections => {
        const filteredSections = menuSections.map(menuSection =>
          this.menuSerializerService.filterMenuSection(menuSection, this.storeMenuOptions),
        );
        targetCategory.sections = filteredSections;
        this.updateMenuCache();
        return filteredSections;
      }));
  }

  getStoreMenuById(
    storeId: string,
    {
      includeSections = true,
      includeItemOptions = true,
      includeMenuOptions = true,
      includeInventory = true,
      includeStoreHidden = false,
    }: GetStoreMenuOptions = {}): Observable<MergeMenu> {

    return this.apiService.storeGetMenuV3(
      { storeId, includeSections, includeItemOptions, includeMenuOptions, includeInventory, includeStoreHidden },
    )
      .pipe(
        // retry with exponential back off... w00t!
        retryBackoff({ maxRetries: 5, initialInterval: 300, resetOnSuccess: true }),
        take(1),
        map(menuExport => this.menuSerializerService.filterMenu(menuExport)),
      );
  }

  /**
   * Returns items to feature on checkout page. Excludes items already in cart.
   * Randomly omits items to get below the specified limit (default = 1)
   */
  getCheckoutFeaturedItems(storeId: string, cartItemsIds: string[] = [], limit = 1): MergeMenuItem[] {
    const menu = safeGet(StoreMenuService.storeMenuCache, c => c[storeId].menu);
    const featuredItemIds = safeGet(menu, m => m.checkoutFeaturedItems.map(i => i.itemId), []);
    if (featuredItemIds.length < 1) {
      return [];
    }

    const itemsInCart = new Set(cartItemsIds);
    const itemMap = this.getMenuItemIdMap(storeId);

    const featuredItems = filterMap(featuredItemIds, itemId => itemsInCart.has(itemId) ? undefined : itemMap[itemId]);

    // remove random items until we are at or below the limit
    // TODO: maybe one day we use some logic to choose appropriate items based on what is in the cart
    while (featuredItems.length > limit) {
      const removeIndex = Math.floor(Math.random() * featuredItems.length);
      featuredItems.splice(removeIndex, 1);
    }

    return featuredItems;
  }

  getItemAndIndexById(storeId: string, menuItemId: string): MenuItemWithIndexes {
    const categories = safeGet(StoreMenuService.storeMenuCache, c => c[storeId].menu.categories);

    for (let cIdx = 0, cLen = categories.length; cIdx < cLen; cIdx++) {
      const category = categories[cIdx];
      const sections = safeGet(category, c => c.sections, []);
      for (let sIdx = 0, sLen = sections.length; sIdx < sLen; sIdx++) {
        const section = sections[sIdx];
        const items = safeGet(section, s => s.items, []);
        for (let iIdx = 0, iLen = sections.length; iIdx < iLen; iIdx++) {
          if (safeGet(items, i => i[iIdx].id) === menuItemId) {
            const item = items[iIdx];
            return {
              item,
              itemIndex: iIdx,
              categoryIndex: cIdx,
              sectionIndex: sIdx,
            };
          }
        }
      }
    }

  }

  private getMenuItemIdMap(storeId: string): { [key: string]: MergeMenuItem } {
    const menu = safeGet(StoreMenuService.storeMenuCache, c => c[storeId].menu);
    const itemMap: { [key: string]: MergeMenuItem } = {};
    (menu.categories || []).forEach(category => {
      (category.sections || []).forEach(section => {
        (section.items || []).forEach(item => {
          itemMap[item.id] = item;
        });
      });
    });
    return itemMap;
  }

  getItem(storeId: string, categoryIndex: number, sectionIndex: number, itemIndex: number): Observable<MergeMenuItem> {
    if (this.storeMenu && this.storeId === storeId) {
      const selectedCategory = this.storeMenu.categories[categoryIndex];
      const item = selectedCategory.sections[sectionIndex].items[itemIndex];
      return of(item);
    }

    return this.getStoreMenuById(storeId).pipe(map((storeMenu) => {
      const selectedCategory = storeMenu.categories[categoryIndex];
      const item = selectedCategory.sections[sectionIndex].items[itemIndex];
      return item;
    }));
  }

  getItemCategoryAndSectionIds(orderItem: OrderItem): OrderItemIds {
    const categoryIndex = orderItem.categoryId;
    const sectionIndex = orderItem.sectionId;

    const categories = safeGet(this.storeMenu, m => m.categories, []);
    const categoryFromIndex = safeGet(categories, c => c[categoryIndex]);
    const sectionFromIndex = safeGet(categoryFromIndex, c => c.sections[sectionIndex]);

    if (categoryFromIndex && sectionFromIndex) {
      return {
        itemCategoryId: categoryFromIndex.id,
        itemSectionId: sectionFromIndex.id,
      };
    } else {
      // No index(es)? Guess we're on our own. Time to traverse that tree boi!
      const orderItemId = orderItem.itemId;
      for (let cIdx = 0, cLen = categories.length; cIdx < cLen; cIdx++) {
        const category = categories[cIdx];
        const sections = safeGet(category, c => c.sections, []);
        for (let sIdx = 0, sLen = sections.length; sIdx < sLen; sIdx++) {
          const section = sections[sIdx];
          const items = safeGet(section, s => s.items, []);
          for (let iIdx = 0, iLen = items.length; iIdx < iLen; iIdx++) {
            if (safeGet(items, i => i[iIdx].id) === orderItemId) {
              return {
                itemCategoryId: category.id,
                itemSectionId: section.id,
              };
            }
          }
        }
      }
    }
  }

  public setStoreItemInventory(menuItemSizeId: string, quantityAvailable: number, hidden: boolean): Promise<StoreItemInventoryStatusDto> {
    return this.apiService.storeSetItemInventory({
      menuItemSizeId,
      quantityAvailable,
      hidden,
    }).toPromise();
  }

  public setStoreOptionInventory(
    menuOptionChoiceId: string, quantityAvailable: number, hidden: boolean,
  ): Promise<StoreOptionInventoryStatusDto> {
    return this.apiService.storeSetOptionInventory({
      menuOptionChoiceId,
      quantityAvailable,
      hidden,
    }).toPromise();
  }

  public deleteStoreItemInventory(itemInventoryId: string): Promise<StoreDeleteItemInventoryResponseDto> {
    return this.apiService.storeDeleteItemInventory(itemInventoryId).toPromise();
  }

  public async getStoreOutOfStockCount(): Promise<number> {
    const [items, options] = await Promise.all([
      this.apiService.storeItemInventoryGetOutOfStockCount().toPromise(),
      this.apiService.storeOptionInventoryGetOutOfStockCount().toPromise(),
    ]);

    const itemsCount = safeGet(items, i => i.outOfStockItemsCount, 0);
    const optionsCount = safeGet(options, o => o.outOfStockOptionsCount, 0);
    return itemsCount + optionsCount;
  }

  private updateMenuCache(): void {
    // squash existing cache (only store one menu in memory for now)
    StoreMenuService.storeMenuCache = {
      [this.storeId]: {
        created: Date.now(),
        menu: this.storeMenu,
        flags: this.storeMenuFlags,
      },
    };
  }

}
