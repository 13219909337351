import { ApiError } from './../../../../../shared/types/api-error.type';
import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatSnackBar } from '@angular/material';
import { Big } from 'big.js';

import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { NativePaymentService, MobilePaymentProvider } from 'src/app/shared/packages/native-support/services/native-payment/native-payment.service';
import { OrderPricingDto, OrderDto, UserPaymentCardDto, JoebucksBalanceResponseDto, UserGetPaymentCardsResponseDto, StoreHoursDto } from 'src/gen/joeServerCore';
import { CheckoutService } from 'src/app/consumer/services/checkout/checkout.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { InputEvent } from 'src/app/shared/types/input-event.type';
import { OrderService, PaymentSourceType } from 'src/app/consumer/services/order/order.service';

const DEFAULT_TIP_RATES = [0.15, 0.20, 0.25];

export interface CheckoutData {
  storeId?: string;
  tipDisabledMessage?: string;
  orderPricing?: OrderPricingDto;
  order?: OrderDto;
  joeBucksOnly?: boolean;
  todayStoreHours?: StoreHoursDto;
  orderIdempotencyKey?: string;
}

type UserCard = UserPaymentCardDto & { fake?: boolean };

export interface CheckoutBottomSheetResult {
  order?: OrderDto;
  joebucksBalance?: JoebucksBalanceResponseDto;
  needEmail?: boolean;
  paymentMethod?: string;
}

@Component({
  selector: 'store-checkout-bottomsheet',
  templateUrl: './store-checkout-bottomsheet.component.html',
  styleUrls: ['./store-checkout-bottomsheet.component.scss'],
})
export class StoreCheckoutBottomsheetComponent implements OnInit, OnDestroy {

  @ViewChild('chargeButtons')
  set chargeButtonElementRef(el: ElementRef) {
    // updates on ngIf change
    this.chargeButtonRef = el;
  }
  private chargeButtonRef: ElementRef<HTMLButtonElement>;

  chargeTotal: string;
  tipSelection: string;
  otherTip = '400';

  loading: boolean;
  userId: string;
  paymentMethods: UserCard[];
  selectedPaymentMethod: UserCard | string;
  processingCheckout: boolean;
  verificationTryCount = 0;
  hideTip: boolean;
  tipAmounts: string[];
  tipDisabledMessage: string;
  orderServiceFee: string;
  canUseFakeCard: boolean;

  invalidTip: boolean;

  joeBucksBalance: string;
  joeBucksVirgin: boolean;
  joeBucksUploadMode: boolean;

  // order mode joe bucks balance
  remainingJoeBucksAfterPurchase: string;

  // upload mode joe bucks balance
  joeBucksUploadAmount: string;
  joeBucksBalanceAfterUpload: number;
  joeBucksQuickUploadAmount: string;

  additionalPaymentMethods: 'apple' | 'google'[];

  fakePaymentMethods: UserCard[];

  // maps card.type to css class for icon
  paymentIcons = {
    'Visa': 'visa',
    'MasterCard': 'mastercard',
    'American Express': 'amex',
    'Discover': 'discover',
    'JCB': 'jcb',
    'Diners Club': 'diners',
    'bitcoin': 'bitcoin',
    'gpay': 'gpay',
    'apay': 'apay',
  };

  unableToLoadPaymentMethods: boolean;

  private tip = '0';
  private currentTimeInterval: NodeJS.Timer;
  currentTime: number = Date.now();
  selectedScheduleTime = 0;
  todayStoreHours: StoreHoursDto;

  private newCardToken: string;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private readonly data: CheckoutData,
    private readonly bottomSheetRef: MatBottomSheetRef<any, CheckoutBottomSheetResult>,
    private readonly identityService: IdentityService,
    private readonly checkoutService: CheckoutService,
    private readonly orderService: OrderService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly snackbar: MatSnackBar,

    // TODO: support non-native payment (use payment service with provider to abstract native / web)
    private readonly nativePayment: NativePaymentService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.joeBucksUploadMode = this.data.joeBucksOnly;
    this.todayStoreHours = this.data.todayStoreHours;

    this.loading = true;

    const user = this.identityService.getActiveUser();

    if (user && user.id) {
      this.userId = user.id;
      this.joeBucksQuickUploadAmount = user.lastUploadFundsAmount;
    }
    this.tipDisabledMessage = this.data.tipDisabledMessage;

    this.populateTipAmounts();
    this.tipChange();
    await this.populatePaymentMethods();
    await this.paymentMethodChange();
    this.updateTipVisibility();

    this.loading = false;

    this.changeDetectorRef.markForCheck();
    this.currentTimeInterval = setInterval(this.updateCurrentTime.bind(this), 1000);
  }

  ngOnDestroy(): void {
    this.setErrorMessage();
    clearInterval(this.currentTimeInterval);
  }

  tipChange(): void {
    const tip = this.tipSelection === '+' ? this.otherTip : this.tipSelection;
    this.tip = isNaN(parseInt(tip, 10)) ? '0' : tip;
    // $0.50 minimum tip
    this.invalidTip = Big(this.tip).gt(0) && Big(this.tip).lt(50);
    this.updateChargeTotal();
  }

  getSelectedPaymentSourceType(): PaymentSourceType {
    let paymentMethod: PaymentSourceType = 'card_existing';

    if (typeof this.selectedPaymentMethod === 'string') {
      if (this.selectedPaymentMethod === 'joebucks') {
        paymentMethod = 'joebucks';
      } else if (this.selectedPaymentMethod === 'new' || this.selectedPaymentMethod === 'new-fake') {
        paymentMethod = 'card_new';
      }
    } else {
      if (this.selectedPaymentMethod.type === 'apay' || this.selectedPaymentMethod.type === 'gpay') {
        paymentMethod = 'mobile_pay';
      }
    }

    return paymentMethod;
  }

  async paymentMethodChange(): Promise<void> {
    if (!this.data || !this.data.order || !this.selectedPaymentMethod) {
      return;
    }

    const paymentMethod: PaymentSourceType = this.getSelectedPaymentSourceType();

    this.data.orderPricing = await this.orderService.getOrderPricing(this.data.order, paymentMethod).toPromise();

    this.canUseFakeCard = this.data.orderPricing.storeRoles && this.data.orderPricing.storeRoles.length > 0;

    this.updateServiceFeeDisplay();
    this.updateChargeTotal();
    this.updateTipVisibility();

    this.changeDetectorRef.markForCheck();
  }

  verifyNumberOnlyInput(event: InputEvent<HTMLInputElement>): boolean {
    const elem = event.target;
    elem.value = elem.value.replace(/[^0-9]+/g, '');
    return elem.value === '' ? false : true;
  }

  cancel() {
    if (this.joeBucksUploadMode && !this.data.joeBucksOnly) {
      this.joeBucksUploadMode = false;
      this.joeBucksUploadAmount = undefined;
      this.selectedPaymentMethod = 'joebucks';
      this.updateChargeTotal();
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.bottomSheetRef.dismiss();
    this.setErrorMessage();
  }

  onFocusField(event: FocusEvent) {
    // scroll checkout button into view after keyboard appears
    if (this.chargeButtonRef && this.chargeButtonRef.nativeElement && this.chargeButtonRef.nativeElement.scrollIntoView) {
      // let keyboard appear first
      setTimeout(() => this.chargeButtonRef.nativeElement.scrollIntoView(), 500);
    }
  }

  async checkout(): Promise<void> {
    this.setErrorMessage();

    // don't allow tip to be > total (to avoid accidental input), but if the tip is less than $4.00 it's ok
    const bigTip = Big(this.tip);
    if (bigTip.gt(400) && bigTip.gt(this.data.orderPricing.total)) {
      this.setErrorMessage('Tip cannot exceed 100% of total');
      return;
    }

    let token: string;
    let newDefaultPaymentId: string;
    let fake: boolean;
    let cardType: string;

    if (typeof this.selectedPaymentMethod === 'object') {
      token = this.selectedPaymentMethod.id;
      fake = this.selectedPaymentMethod.fake;
      cardType = this.selectedPaymentMethod.type;
    } else {
      cardType = this.selectedPaymentMethod;
    }

    let paymentType: PaymentSourceType = 'card_existing';

    if (!token && cardType !== 'joebucks') {
      if (!this.newCardToken) {
        this.setErrorMessage('Invalid credit card data.');
        return;
      }

      fake = cardType === 'new-fake';

      if (fake && this.joeBucksUploadMode) {
        this.setErrorMessage('Cannot use fake cards to preload funds.');
        return;
      }

      if (fake && !confirm('You are adding a fake card. Purchases made with this card will not be filled.')) {
        return;
      }
    }

    this.processingCheckout = true;
    newDefaultPaymentId = token;

    try {

      if (cardType === 'apay' || cardType === 'gpay') {
        if (Big(this.chargeTotal || '0').gt(0)) {
          token = await this.nativePayment.createMobilePayment({ label: 'Joe Coffee Order', amount: this.chargeTotal });
        }
        paymentType = 'mobile_pay';
      } else if (cardType === 'new' || cardType === 'new-fake') {
        token = this.newCardToken;

        // only set default for real new card (not fake)
        if (!fake) {
          newDefaultPaymentId = token;
        }

        paymentType = 'card_new';
      } else if (cardType === 'joebucks' && !this.joeBucksUploadMode) {
        paymentType = 'joebucks';
      }

      if (!token && paymentType !== 'joebucks') {
        this.setErrorMessage('Payment method failed.');
        this.processingCheckout = false;

        // bottom sheet requires manual change detection
        this.changeDetectorRef.markForCheck();
        return;
      }

      const result: CheckoutBottomSheetResult = {};
      const wasJoeBucksVirgin = this.joeBucksVirgin;

      if (this.joeBucksUploadMode) {

        if (paymentType === 'card_new' || paymentType === 'card_existing' || paymentType === 'mobile_pay') {
          // upload funds
          result.joebucksBalance = await this.checkoutService.uploadJoeBucks({
            amount: this.chargeTotal,
            paymentSource: { type: paymentType, token },
          }).toPromise();

          this.identityService.setLastUploadFundsAmount(this.chargeTotal);

          if (paymentType === 'card_new') {
            await this.populatePaymentMethods();
          }
        } else {
          this.setErrorMessage('Invalid payment method for adding funds.');
          this.processingCheckout = false;
          // bottom sheet requires manual change detection
          this.changeDetectorRef.markForCheck();
          return;
        }
      } else {
        // purchase goods (checkout)
        result.order = await this.checkoutService.checkout({
          order: { ...this.data.order, tip: this.tip, fake },
          paymentSource: { type: paymentType, token },
          pickupDelayMinutes: this.selectedScheduleTime,
          orderIdempotencyKey: this.data.orderIdempotencyKey,
        }).toPromise();
      }

      result.paymentMethod = paymentType;

      // fake cards should not be defaulted
      if (!fake && newDefaultPaymentId) {
        this.userService.setDefaultPaymentMethod(newDefaultPaymentId);
      }

      // switch back to order mode after upload funds if we're in the order checkout flow
      // also exit if this is the first upload (joe bucks virgin) to show them the cart with the newly added reward
      if (this.joeBucksUploadMode && !this.data.joeBucksOnly && this.data.order && this.data.orderPricing && !wasJoeBucksVirgin) {
        this.joeBucksBalance = result.joebucksBalance.balance;
        this.joeBucksUploadMode = false;
        this.joeBucksUploadAmount = undefined;
        this.selectedPaymentMethod = 'joebucks';
        this.updateChargeTotal();
        this.snackbar.open('Funds added successfully.', '', { duration: 5000, verticalPosition: 'top' });
      } else {
        this.bottomSheetRef.dismiss(result);
      }

    } catch (e) {
      this.apiErrorHandler(e);
    }
    this.processingCheckout = false;

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  onStripeTokenUpdate(token: string): void {
    this.newCardToken = token;
  }

  private updateCurrentTime(): void {
    // no need to waste a change detection if the time isn't showing
    if (this.joeBucksUploadMode || this.tipSelection === undefined) {
      return;
    }

    this.currentTime = Date.now();
    this.changeDetectorRef.markForCheck();
  }

  private updateTipVisibility(): void {
    if (
      this.tipDisabledMessage ||
      !this.data || !this.data.orderPricing ||
      (Big(this.data.orderPricing.total || '0').lt(1) && this.selectedPaymentMethod !== 'joebucks')
    ) {
      this.hideTip = true;
      this.tipSelection = '0';
      this.tipChange();
    } else {
      this.hideTip = false;
    }
  }

  private updateChargeTotal(): void {
    if (this.joeBucksUploadMode) {
      this.chargeTotal = this.joeBucksUploadAmount;
      this.joeBucksBalanceAfterUpload = parseInt(Big(this.joeBucksBalance || '0').plus(this.chargeTotal || '0').toString(), 10);
    } else {
      this.chargeTotal = Big(this.data.orderPricing.total).plus(this.tip).toString();
      this.remainingJoeBucksAfterPurchase = Big(this.joeBucksBalance || '0').minus(this.chargeTotal || '0').toString();
    }

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  private updateServiceFeeDisplay(): void {
    const serviceFee = this.data.orderPricing.charges && this.data.orderPricing.charges.find(charge => charge.category === 'service-fee');
    this.orderServiceFee = serviceFee && serviceFee.amount;
  }

  private populateTipAmounts(): void {
    if (!this.data.orderPricing) {
      return;
    }
    const total = Big(this.data.orderPricing.total);

    this.tipAmounts = [];
    DEFAULT_TIP_RATES.forEach((rate, i) => {
      const lastTip = i > 0 ? this.tipAmounts[i - 1] : '0';
      const tipAmountBig = total.times(rate).div(50).round(0).times(50);
      const tipAmount = tipAmountBig.lt(50) ? '50' : tipAmountBig.toString();
      this.tipAmounts[i] = Big(tipAmount).lte(lastTip) ? Big(lastTip).plus(50).toString() : tipAmount;
    });

    this.otherTip = Big(this.tipAmounts[this.tipAmounts.length - 1]).plus(100).toString();
  }

  private async populatePaymentMethods(): Promise<void> {
    this.unableToLoadPaymentMethods = false;
    if (!this.userId) {
      this.paymentMethods = undefined;
      this.fakePaymentMethods = undefined;
      return;
    }

    const joeBucksBalanceStatus = await this.userService.getJoeBucksBalanceStatus().toPromise();
    this.joeBucksBalance = joeBucksBalanceStatus.balance;
    this.joeBucksVirgin = joeBucksBalanceStatus.virgin;

    const defaultPaymentMethod = await this.userService.getDefaultPaymentMethod();

    let paymentMethods: UserGetPaymentCardsResponseDto = { realCards: [], fakeCards: [] };
    try {
      paymentMethods = await this.userService.getPaymentCards().toPromise();
    } catch (e) {
      this.unableToLoadPaymentMethods = true;
    }

    const { realCards, fakeCards } = paymentMethods;

    this.paymentMethods = realCards;

    switch (await this.nativePayment.supportsMobilePay()) {
      case MobilePaymentProvider.apple:
        this.paymentMethods.unshift({
          type: 'apay',
          last4: 'Apple Pay',
          defaultCard: true,
          id: 'apple',
          country: 'US',
        });
        break;
      case MobilePaymentProvider.google:
        this.paymentMethods.unshift({
          type: 'gpay',
          last4: 'Google Pay',
          defaultCard: true,
          id: 'google',
          country: 'US',
        });
        break;
    }

    this.fakePaymentMethods = fakeCards.map(card => ({ ...card, fake: true }));

    this.selectedPaymentMethod = undefined;

    if (defaultPaymentMethod !== undefined) {
      this.selectedPaymentMethod = this.paymentMethods.find(p => p.id === defaultPaymentMethod);
    }

    // show joebucks as default if they have any remaining balance (and this is not an upload)
    if (!this.joeBucksUploadMode && (Big(this.joeBucksBalance || '0').gt(0) || Big(this.chargeTotal).eq(0))) {
      this.selectedPaymentMethod = 'joebucks';
    }

    // if there was no default payment method or we couldn't find it in the list
    if (!this.selectedPaymentMethod) {
      if (this.paymentMethods && this.paymentMethods.length > 0) {
        // use 1st if there is only 1, 2nd if there are more than 1
        // defaults to stored credit card over apple pay for existing users to not change their experience
        this.selectedPaymentMethod = this.paymentMethods[this.paymentMethods.length > 1 ? 1 : 0];
      } else if (this.fakePaymentMethods && this.fakePaymentMethods.length > 0) {
        this.selectedPaymentMethod = this.fakePaymentMethods[0];
      } else {
        this.selectedPaymentMethod = 'new';
      }
    }

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  async switchToJoeBalance(): Promise<void> {
    this.selectedPaymentMethod = 'joebucks';
    await this.paymentMethodChange();
    this.changeDetectorRef.markForCheck();
  }

  async showAddFunds(): Promise<void> {
    const activeUser = this.identityService.getActiveUser();

    if (!activeUser.hasEmail) {
      this.bottomSheetRef.dismiss({ needEmail: true });
      return;
    }

    this.joeBucksUploadMode = true;
    this.updateChargeTotal();
    await this.populatePaymentMethods();
  }

  async quickAddFunds(): Promise<void> {
    await this.showAddFunds();
    this.joeBucksUploadAmount = this.joeBucksQuickUploadAmount;
    this.updateChargeTotal();
    this.checkout();
  }

  joeBucksUploadAmountChange(): void {
    this.updateChargeTotal();
  }

  /**
   * The overly complex world of "should the checkout button be disabled"
   *
   * TODO: refactor this chaos
   */
  isCheckoutDisabled(): boolean {

    // card data must be valid the new card form is showing
    return (!this.newCardToken && (this.selectedPaymentMethod === 'new' || this.selectedPaymentMethod === 'new-fake')) ||

      // $0.50 minimum tip
      this.invalidTip ||

      // paying for an order and hasn't selected a tip amount
      (!this.joeBucksUploadMode && this.tipSelection === undefined) ||

      // hasn't selected a joebucks upload amount
      (this.joeBucksUploadMode && this.joeBucksUploadAmount === undefined) ||

      // using joebucks with insufficient funds
      (this.selectedPaymentMethod === 'joebucks' && Big(this.remainingJoeBucksAfterPurchase || '0').lt(0)) ||

      // max joebucks balance $200
      (this.joeBucksUploadMode && Big(this.joeBucksBalanceAfterUpload).gte(15000));
  }

  private setErrorMessage(message?: string): void {
    if (message) {
      this.snackbar.open(`Error: ${message}`, '', { verticalPosition: 'top', duration: 10000, panelClass: 'error-toast' });
    } else {
      this.snackbar.dismiss();
    }

    // bottom sheet requires manual change detection
    this.changeDetectorRef.markForCheck();
  }

  private apiErrorHandler(error: ApiError): void {
    // stripe.js errors are just strings
    if (typeof error === 'string') {
      return this.setErrorMessage(error);
    }

    // server errors need to be unwrapped
    if (error && error.error && error.error.message && error.error.message.length > 0) {
      return this.setErrorMessage(Array.isArray(error.error.message) ? error.error.message[0] : error.error.message);
    }
    this.setErrorMessage('Unknown error. Please try again.');
  }
}
