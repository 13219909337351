import { ModuleWithProviders } from '@angular/core';
import { NativePushService } from './services/native-push/native-push.service';
import { NativeEnvironmentService } from './services/native-environment/native-environment.service';
import { NativePaymentService } from './services/native-payment/native-payment.service';
import { NativeBackButtonService } from './services/native-back-button/native-back-button.service';
import { NativeSocialShareService } from './services/native-social-share/native-social-share.service';
var NativeSupportModule = (function () {
    function NativeSupportModule() {
    }
    NativeSupportModule.forRoot = function () {
        return {
            ngModule: NativeSupportModule,
            providers: [
                NativePushService,
                NativeEnvironmentService,
                NativePaymentService,
                NativeBackButtonService,
                NativeSocialShareService,
            ],
        };
    };
    return NativeSupportModule;
}());
export { NativeSupportModule };
