import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IdentityService, User } from 'src/app/shared/services/identity/identity.service';
import { MatSelect } from '@angular/material';
import { ActiveCompanyService } from '../../services/active-company/active-company.service';
import { CompanyDto } from 'src/app/merchant/services/company/company.service';
import { MerchantTopbarService } from '../../services/merchant-topbar/merchant-topbar.service';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'merchant-topbar',
  templateUrl: './merchant-topbar.component.html',
  styleUrls: ['./merchant-topbar.component.scss'],
})
export class MerchantTopbarComponent implements OnInit, OnDestroy {

  @Input()
  set show(show: boolean) {
    this.isShowing = show;
    if (show === false) {
      this.closing = false;
      this.isDrawerOpen = false;
    }
  }
  get show() {
    return this.isShowing;
  }

  @Input()
  set drawerOpen(open: boolean) {
    if (this.userAnimationTimeout) {
      clearTimeout(this.userAnimationTimeout);
      this.closing = false;
      this.userAnimationTimeout = undefined;
    }

    if (open === false && this.isDrawerOpen) {
      this.closing = true;

      this.userAnimationTimeout = setTimeout(() => {
        this.closing = false;
        this.userAnimationTimeout = undefined;
      }, 500);
    }
    this.isDrawerOpen = open;
  }

  @Input()
  mobileMode: boolean;

  @Output()
  onToggleMenu = new EventEmitter();

  @Output()
  onLogout = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  closing: boolean;
  isDrawerOpen: boolean;
  title: string;
  companies: CompanyDto[];
  activeCompany: string;

  private userAnimationTimeout: NodeJS.Timer;
  private isShowing: boolean;

  private componentActive: boolean;
  user: User;

  constructor(
    private topbarService: MerchantTopbarService,
    private identityService: IdentityService,
    private router: Router,
    private activeCompanyService: ActiveCompanyService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.componentActive = true;

    // watch for service events
    this.topbarService.watchOptions().pipe(takeWhile(() => this.componentActive))
      .subscribe(options => this.title = options.title);

    this.activeCompanyService.watch().pipe(takeWhile(() => this.componentActive))
      .subscribe(({ companies, active }) => {
        this.companies = companies || [];
        this.activeCompany = active && active.id;
      });

    // watch for identity changes
    this.identityService.watchActiveUser().pipe(takeWhile(() => this.componentActive))
      .subscribe(user => {
        this.user = user;
        if (user && user.id) {
          this.activeCompanyService.updateCompanies();
        }
      });

    await this.identityService.isLoaded();
    this.user = this.identityService.getActiveUser();
  }

  openSelect() {
    this.select.focus();
    this.select.toggle();
  }

  dashboardUrl() {
    if (this.activeCompany) {
      return `/m/company/${this.activeCompany}/dashboard`;
    } else {
      return `/m/companies`;
    }
  }

  changeActiveCompany(companyId: string) {
    if (companyId === 'create') {
      this.router.navigateByUrl('/m/company/create');
      return;
    }

    // default to the dashboard
    const targetUrl = `/m/company/${companyId}/dashboard`;

    // TODO... maybe...
    // this is a "data change" so all pages need to subscribe to resolver changes,
    // and be able to reset their state and load different data.
    //
    // const uuidRegExp = /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/ig;
    // const { url } = this.router;
    // const urlUuids = url.match(uuidRegExp);

    // // for urls with exactly 1 uuid belonging to the company we can stay put but change companies
    // if (urlUuids && urlUuids.length === 1 && urlUuids[0] === this.activeCompany) {
    //   targetUrl = url.replace(this.activeCompany, companyId);
    // }

    this.router.navigateByUrl(targetUrl);
    this.activeCompanyService.setActive(companyId);

  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  menuClick() {
    this.onToggleMenu.emit();
  }

  logout() {
    this.onLogout.emit();
  }

}
