import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule, MatButtonModule, MatBadgeModule, MatMenuModule, MatDividerModule } from '@angular/material';
import { NativeAudio } from '@ionic-native/native-audio';

import { UiPosChromeComponent } from './components/ui-pos-chrome/ui-pos-chrome.component';
import { AudioAlertService } from './services/audio-alert/audio-alert.service';
import { PosDeviceBadgeComponent } from './components/pos-device-badge/pos-device-badge.component';
import { DataTransformPipesModule } from 'src/app/shared/packages/data-transform-pipes';

export const MATERIAL_IMPORTS = [
  MatIconModule,
  MatButtonModule,
  MatBadgeModule,
  MatMenuModule,
  MatDividerModule,
];

const EXPORTED_COMPONENTS = [
  UiPosChromeComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    DataTransformPipesModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    PosDeviceBadgeComponent,
    ...EXPORTED_COMPONENTS,
  ],
  providers: [
    NativeAudio,
    AudioAlertService,
  ],
  exports: [
    ...MATERIAL_IMPORTS,
    ...EXPORTED_COMPONENTS,
  ],
  entryComponents: [],

})
export class UiPosChromeModule { }
