var OrderItemOption = (function () {
    function OrderItemOption(data) {
        this.data = data;
        this.resetChoices();
    }
    Object.defineProperty(OrderItemOption.prototype, "id", {
        get: function () {
            return this.data.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOption.prototype, "name", {
        get: function () {
            return this.data.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOption.prototype, "index", {
        get: function () {
            return this.data.index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOption.prototype, "minChoices", {
        get: function () {
            return this.data.minChoices;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOption.prototype, "maxChoices", {
        get: function () {
            return this.data.maxChoices;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOption.prototype, "choices", {
        get: function () {
            return this.data.choices;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOption.prototype, "hiddenSelectedChoiceCount", {
        get: function () {
            return this.getSelectedChoices().filter(function (choice) { return choice.hidden; }).length;
        },
        enumerable: true,
        configurable: true
    });
    OrderItemOption.prototype.resetChoices = function () {
        var availableChoicesArr = this.choices.map(function (_, i) { return i; });
        this.availableChoices = new Set(availableChoicesArr);
        this.selectedChoices = new Set([]);
    };
    OrderItemOption.prototype.addChoice = function (choiceIndex) {
        var validatedAddition = this.maxChoices === -1 || this.selectedChoices.size < this.maxChoices && !this.selectedChoices.has(choiceIndex);
        if (validatedAddition) {
            this.availableChoices.delete(choiceIndex);
            this.selectedChoices.add(choiceIndex);
            return true;
        }
        else {
            return false;
        }
    };
    OrderItemOption.prototype.removeChoice = function (choiceIndex) {
        if (this.selectedChoices.has(choiceIndex)) {
            this.selectedChoices.delete(choiceIndex);
            this.availableChoices.add(choiceIndex);
            this.availableChoices = new Set(Array.from(this.availableChoices).sort(function (a, b) {
                return a - b;
            }));
            return true;
        }
        else {
            return false;
        }
    };
    OrderItemOption.prototype.getAvailableChoices = function () {
        var _this = this;
        var availableChoicesArr = Array.from(this.availableChoices);
        return availableChoicesArr.map(function (choiceIndex) { return _this.choices[choiceIndex]; });
    };
    OrderItemOption.prototype.getSelectedChoices = function () {
        var _this = this;
        var selectedChoicesArr = Array.from(this.selectedChoices);
        return selectedChoicesArr.map(function (choiceIndex) { return _this.choices[choiceIndex]; });
    };
    return OrderItemOption;
}());
export { OrderItemOption };
