<mat-dialog-content class="dialog-content">
  <section class="logo-header-section-wrap" fxLayout="row" fxLayoutAlign="center center">
    <img class="logo-header" src="public-web-assets/images/logo-white.png">
  </section>
  <section class="info-content-section-wrap">
    <div>
      <h2>This version of the app is no longer supported. New app install required.</h2>
      <div>
        <ol>
          <li>Hit the button below to install Joe Merchant Plus from the App Store</li>
          <li><b>Owner/Manager login</b> is required to <a
              href="https://support.joe.coffee/article/18-downloading-joe-merchant-plus">pair your device</a> to your
            store</li>
          <li>Delete the old Joe Merchant App</li>
        </ol>
        <div>Questions? Email us at <a href="mailto:success@joe.coffee">success@joe.coffee</a></div>
      </div>
      <br>
      <div>
        <a [href]="link" target="_blank">
          <button mat-flat-button class="cta-button" color="primary">
            Install Joe Merchant Plus from the App Store
          </button>
        </a>
      </div>
    </div>
  </section>
  <br>
  <section>
    <small>
      <sup>*This application will no longer be supported on December 31st, 2021.</sup>
    </small>
  </section>
</mat-dialog-content>