import { ErrorNames } from '../enums/error-names';

export interface ApplicationErrorData {
  name: ErrorNames;
  message: string;
  details?: any;
  suppressDialog?: boolean;
  retryAction?: () => void;
}

export class ApplicationError extends Error {
  constructor(private data: ApplicationErrorData) {
    super(data.message);
    Object.setPrototypeOf(this, ApplicationError.prototype);
  }
  getData(): ApplicationErrorData {
    return this.data;
  }
}

export { ErrorNames };
