export type CallbackFn<T extends any[] = [], R extends any = void> = (...args: T) => R;

/**
 * Maps and filters an array in one step. If the accessor returns undefined the value will be filtered out otherwise it's mapped
 */
export function filterMap<T, R extends any>(arr: T[], fn: CallbackFn<[T], R>): R[] {
  const result: R[] = [];

  if (arr && arr.length > 0) {
    for (let i = 0, len = arr.length; i < len; i++) {
      const mappedValue = fn(arr[i]);
      // filter out if value is === undefined
      if (mappedValue !== undefined) {
        result.push(mappedValue);
      }
    }
  }

  return result;
}
