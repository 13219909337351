var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validator } from 'jsonschema';
import { Subject } from 'rxjs';
import { takeWhile, debounceTime } from 'rxjs/operators';
function createFieldError(control) {
    return { control: control, errors: [] };
}
export function addFieldError(control, path, errors, targetMap) {
    var _a;
    if (!targetMap.hasOwnProperty(path)) {
        targetMap[path] = createFieldError(control);
    }
    (_a = targetMap[path].errors).push.apply(_a, errors);
}
var ReactiveFormService = (function () {
    function ReactiveFormService(formBuilder) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.errorSubject = new Subject();
        this.validator = new Validator();
        this.serviceActive = true;
        this.errorObservable = this.errorSubject.pipe(takeWhile(function () { return _this.serviceActive; }), debounceTime(100));
        this.errorObservable.subscribe(function (errors) { return _this.updateFieldErrors(errors); });
    }
    ReactiveFormService.prototype.ngOnDestroy = function () {
        this.serviceActive = false;
    };
    ReactiveFormService.prototype.createModelValidatedForm = function (model, data, additionalValidators) {
        if (additionalValidators === void 0) { additionalValidators = []; }
        var dataClone = Object.assign({}, data);
        var formGroup = this.formBuilder.group(dataClone);
        formGroup.setValidators([this.createModelValidator(model, additionalValidators)]);
        return formGroup;
    };
    ReactiveFormService.prototype.isValid = function (model, data) {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult, errors;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.validator.validate(data, model)];
                    case 1:
                        validationResult = _a.sent();
                        errors = (validationResult && validationResult.errors) || [];
                        return [2, errors && errors.length === 0];
                }
            });
        });
    };
    ReactiveFormService.prototype.createErrorAccessor = function (form, errorMessages) {
        if (errorMessages === void 0) { errorMessages = {}; }
        return function () {
            var fieldPathSegments = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                fieldPathSegments[_i] = arguments[_i];
            }
            var field = form.get(fieldPathSegments);
            if (!field || !field.errors || !field.errors.length) {
                return;
            }
            var error = field.errors[0];
            var fieldPath = fieldPathSegments.join('.');
            if (errorMessages.hasOwnProperty(fieldPath)) {
                var fieldMessages = errorMessages[fieldPath];
                if (typeof fieldMessages === 'string') {
                    return { name: error.name, message: fieldMessages };
                }
                else {
                    if (fieldMessages.hasOwnProperty(error.name)) {
                        var message = fieldMessages[error.name];
                        return { name: error.name, message: message };
                    }
                }
            }
            return error;
        };
    };
    ReactiveFormService.prototype.updateFieldErrors = function (_a) {
        var fieldErrorMap = _a.fieldErrorMap, control = _a.control, fieldErrorPaths = _a.fieldErrorPaths;
        if (!fieldErrorPaths || fieldErrorPaths.length < 1) {
            control.setErrors(null);
            return;
        }
        fieldErrorPaths.forEach(function (path) {
            var fieldError = fieldErrorMap[path];
            fieldError.control.setErrors(fieldError.errors);
        });
    };
    ReactiveFormService.prototype.createModelValidator = function (model, additionalValidators) {
        var _this = this;
        return function (control) {
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var data, fieldErrorMap, validationResult, errors, i, len, _a, property, name_1, message, path, field, fieldErrors, fieldErrorPaths;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            data = control.getRawValue();
                            fieldErrorMap = {};
                            if (model.required) {
                                model.required.forEach(function (path) {
                                    var fieldData = data[path];
                                    if (typeof fieldData === 'string' && fieldData.length < 1) {
                                        var field = control.get(path);
                                        if (field) {
                                            var fieldErrors = [{ name: 'required', message: 'This field is required.' }];
                                            addFieldError(field, path, fieldErrors, fieldErrorMap);
                                        }
                                    }
                                });
                            }
                            return [4, this.validator.validate(data, model)];
                        case 1:
                            validationResult = _b.sent();
                            errors = (validationResult && validationResult.errors) || [];
                            for (i = 0, len = errors.length; i < len; i++) {
                                _a = errors[i], property = _a.property, name_1 = _a.name, message = _a.message;
                                path = property.replace(/^instance\./, '').replace(/\[(\d*)\]/g, '.$1');
                                field = control.get(path);
                                if (field) {
                                    fieldErrors = [{ name: name_1, message: message }];
                                    addFieldError(field, path, fieldErrors, fieldErrorMap);
                                }
                            }
                            if (additionalValidators && additionalValidators.length > 0) {
                                additionalValidators.forEach(function (fn) {
                                    var validatorResults = fn(control);
                                    var paths = Object.keys(validatorResults);
                                    if (paths.length) {
                                        paths.forEach(function (path) {
                                            var result = validatorResults[path];
                                            addFieldError(result.control, path, result.errors, fieldErrorMap);
                                        });
                                    }
                                });
                            }
                            fieldErrorPaths = Object.keys(fieldErrorMap);
                            this.errorSubject.next({ control: control, fieldErrorMap: fieldErrorMap, fieldErrorPaths: fieldErrorPaths });
                            return [2];
                    }
                });
            }); })();
            return null;
        };
    };
    return ReactiveFormService;
}());
export { ReactiveFormService };
