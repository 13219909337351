/**
 * Simple date formatter that takes a Date and returns MM/DD/YYYY format date
 */
export function getDateMMDDYYYY(date: Date): string {
  if (!date) {
    return;
  }

  const month = ('00' + (date.getMonth() + 1)).slice(-2); // I don't know why JavaScript months be like this, but they do.
  const day = ('00' + date.getDate()).slice(-2);
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
