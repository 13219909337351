import { PipeTransform } from '@angular/core';
var PhoneNumberPipe = (function () {
    function PhoneNumberPipe() {
    }
    PhoneNumberPipe.prototype.transform = function (value) {
        if (!value || value.length < 10) {
            return value;
        }
        return value.replace(/[^0-9]/g, '').slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    };
    return PhoneNumberPipe;
}());
export { PhoneNumberPipe };
