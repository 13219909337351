<div class="merchant-topbar" [class.show]="show" [class.mobile-mode]="mobileMode">
  <a [routerLink]="dashboardUrl()" class="toggle-side-nav">
    <img src="public-web-assets/images/logo-new.png" class="topbar-logo">
  </a>

  <div class="right-bar">
    <ng-container class="user">
      <button mat-flat-button [matMenuTriggerFor]="accountMenu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #accountMenu="matMenu">
        <ng-template matMenuContent>
          <label class="no-cursor" mat-menu-item> <i>Signed in as
            </i><strong>{{user?.firstName || 'User'}}</strong></label>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <button type="button" mat-menu-item routerLink="/change-password">Change Password</button>
          <mat-divider></mat-divider>
          <button type="button" mat-menu-item (click)="logout()">Sign out</button>
        </ng-template>
      </mat-menu>
    </ng-container>

    <ng-container *ngIf="false">
      <button mat-flat-button>
        <mat-icon>notifications</mat-icon>
      </button>
    </ng-container>
  </div>

  <div class="topbar-title-wrap" fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
    <div *ngIf="activeCompany && companies && companies.length > 0" class="company-select-wrap" fxFlex="300px"
      fxLayoutAlign="center" fxLayout="column">
      <div class="selector-div">
        <button mat-icon-button (click)="openSelect()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-select (selectionChange)="changeActiveCompany($event.value)" [value]="activeCompany" #select>
          <mat-option *ngFor="let company of companies" [value]="company.id">
            {{company.name}}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option value="create">Create Company...</mat-option>
        </mat-select>
      </div>
    </div>
  </div>
</div>