import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EnvironmentService, EnvironmentModes } from '../../services/environment/environment.service';
import { PosDeviceService } from '../../../pos/services/pos-devices/pos-device.service';

const DEFAULT_START_URL = '/login';
const CONSUMER_START_URL = '/home';
const MERCHANT_START_URL = '/m';
const POS_START_URL = '/pos';

@Component({
  selector: 'page-home',
  template: '',
})
export class HomeComponent implements OnInit {

  constructor(
    private environment: EnvironmentService,
    private posDevice: PosDeviceService,
    private router: Router,
  ) {

  }

  async ngOnInit() {
    const mode = await this.environment.getMode();
    await this.posDevice.isLoaded();
    const paired = !!this.posDevice.getPairedDevice();
    let redirectUrl = DEFAULT_START_URL;

    if (mode === EnvironmentModes.MERCHANT) {
      redirectUrl = paired ? POS_START_URL : MERCHANT_START_URL;
    } else {
      redirectUrl = CONSUMER_START_URL;
    }

    // lets change detection finish before redirecting
    setTimeout(() => {
      this.router.navigateByUrl(redirectUrl);
    }, 0);
  }
}
