import { OnInit, TemplateRef } from '@angular/core';
import { BottombarService } from '../../../services/bottombar/bottombar.service';
var BottombarTemplateComponent = (function () {
    function BottombarTemplateComponent(bottombarService) {
        this.bottombarService = bottombarService;
    }
    Object.defineProperty(BottombarTemplateComponent.prototype, "template", {
        set: function (template) {
            this.bottombarService.setTemplate(template);
        },
        enumerable: true,
        configurable: true
    });
    BottombarTemplateComponent.prototype.ngOnInit = function () {
    };
    return BottombarTemplateComponent;
}());
export { BottombarTemplateComponent };
