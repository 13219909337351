import { MatCurrencyInputModule } from 'src/app/shared/packages/mat-currency-input';
import { MatInputModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatInputModule,
    MatCurrencyInputModule,
];
var MenuItemSizeChipModule = (function () {
    function MenuItemSizeChipModule() {
    }
    return MenuItemSizeChipModule;
}());
export { MenuItemSizeChipModule };
