var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Subject } from 'rxjs/Subject';
import { environment } from 'src/environments/environment';
import { NativeEnvironmentService } from '../../packages/native-support/services/native-environment/native-environment.service';
var EnvironmentService = (function () {
    function EnvironmentService(nativeEnvironment) {
        this.nativeEnvironment = nativeEnvironment;
        this.bindEventObservables();
    }
    EnvironmentService.prototype.onReady = function () {
        return this.nativeEnvironment.ready();
    };
    EnvironmentService.prototype.onResume = function () {
        return this.resumeSubject.asObservable();
    };
    EnvironmentService.prototype.onPause = function () {
        return this.pauseSubject.asObservable();
    };
    EnvironmentService.prototype.getPlatform = function () {
        return __awaiter(this, void 0, void 0, function () {
            var platform;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.nativeEnvironment.getPlatform()];
                    case 1:
                        platform = _a.sent();
                        switch (platform) {
                            case 'ios':
                                return [2, 2];
                            case 'android':
                                return [2, 3];
                        }
                        if (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) {
                            return [2, 1];
                        }
                        else {
                            return [2, 0];
                        }
                        return [2];
                }
            });
        });
    };
    EnvironmentService.prototype.getPlatformName = function () {
        return this.getPlatform().then(function (platform) {
            switch (platform) {
                case 3:
                    return 'android';
                case 2:
                    return 'ios';
                case 0:
                    return 'web';
                case 1:
                    return 'pwa';
            }
            return 'unknown';
        });
    };
    EnvironmentService.prototype.isNative = function () {
        return this.getPlatform()
            .then(function (platform) { return platform === 2 || platform === 3; });
    };
    EnvironmentService.prototype.isWeb = function () {
        return this.isNative().then(function (v) { return !v; });
    };
    EnvironmentService.prototype.getMode = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, 1];
            });
        });
    };
    EnvironmentService.prototype.getAppName = function () {
        return this.nativeEnvironment.getAppName();
    };
    EnvironmentService.prototype.getEnvName = function () {
        return environment.name;
    };
    EnvironmentService.prototype.getVersions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deviceNumber, deviceCode, appName, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.isNative()];
                    case 1:
                        if (!((_a.sent()) === true)) return [3, 7];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        return [4, this.nativeEnvironment.getPackageName()];
                    case 3:
                        appName = _a.sent();
                        return [4, this.nativeEnvironment.getVersionNumber()];
                    case 4:
                        deviceNumber = _a.sent();
                        return [4, this.nativeEnvironment.getVersionCode()];
                    case 5:
                        deviceCode = _a.sent();
                        return [3, 7];
                    case 6:
                        e_1 = _a.sent();
                        return [3, 7];
                    case 7: return [2, {
                            appName: appName,
                            deviceNumber: deviceNumber,
                            deviceCode: deviceCode,
                            packageVersion: environment.packageVersion,
                            build: environment.build,
                        }];
                }
            });
        });
    };
    EnvironmentService.prototype.isProduction = function () {
        return !!this.getConfig().production;
    };
    EnvironmentService.prototype.getConfig = function () {
        return environment;
    };
    EnvironmentService.prototype.getHost = function () {
        return location.host;
    };
    EnvironmentService.prototype.bindEventObservables = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.resumeSubject = new Subject();
                        this.pauseSubject = new Subject();
                        return [4, this.onReady()];
                    case 1:
                        _a.sent();
                        return [4, this.isNative()];
                    case 2:
                        if ((_a.sent()) === true) {
                            document.addEventListener('resume', function () { return _this.resumeSubject.next(); });
                            document.addEventListener('pause', function () { return _this.pauseSubject.next(); });
                        }
                        else {
                            window.addEventListener('focus', function () { return _this.resumeSubject.next(); });
                            window.addEventListener('blur', function () { return _this.pauseSubject.next(); });
                        }
                        return [2];
                }
            });
        });
    };
    return EnvironmentService;
}());
export { EnvironmentService };
