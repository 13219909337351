import { PipeTransform } from '@angular/core';
var GetFirstPipe = (function () {
    function GetFirstPipe() {
    }
    GetFirstPipe.prototype.transform = function (value) {
        if (!value || !Array.isArray(value) || value.length < 1) {
            return;
        }
        return value[0];
    };
    return GetFirstPipe;
}());
export { GetFirstPipe };
