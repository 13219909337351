import { Injector } from '@angular/core';
import { IdentityService } from '../identity/identity.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';
var ApiTokenProviderServiceService = (function () {
    function ApiTokenProviderServiceService(injector) {
        this.injector = injector;
    }
    Object.defineProperty(ApiTokenProviderServiceService.prototype, "identityService", {
        get: function () {
            return this.injector.get(IdentityService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiTokenProviderServiceService.prototype, "posDeviceService", {
        get: function () {
            return this.injector.get(PosDeviceService);
        },
        enumerable: true,
        configurable: true
    });
    ApiTokenProviderServiceService.prototype.getToken = function (name) {
        var _this = this;
        switch (name) {
            case 'authorization':
                return this.identityService.refreshActiveUser().pipe(map(function () {
                    var activeUser = _this.identityService.getActiveUser();
                    return activeUser && activeUser.token ? 'Bearer ' + activeUser.token : '';
                }));
            case 'device-authorization':
                return this.posDeviceService.refreshActiveDeviceToken()
                    .pipe(map(function () {
                    var pairedDevice = _this.posDeviceService.getPairedDevice();
                    return pairedDevice && pairedDevice.token ? 'Bearer ' + pairedDevice.token : '';
                }));
            default:
                return of('');
        }
    };
    return ApiTokenProviderServiceService;
}());
export { ApiTokenProviderServiceService };
