import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
})
export class Error404Component implements OnInit {

  joke: string;
  private jokes: string[] = [
    'How are coffee beans like kids? They’re always getting grounded!',
    'What did the barista’s Valentine say? I can’t espresso my love for you.',
    'How does a tech guy drink coffee? He installs Java!',
    'How did the hipster burn his tongue? He drank his coffee before it was cool.',
    'Why do some people call coffee "mud"? Because it was fresh ground a few minutes ago.',
    'What do you call it when someone steals your morning coffee? A mugging.',
    'What do you call it when you walk into a cafe you’re sure you’ve been to before? Déjà brew',
    `A man walks into a coffee shop carrying a big chunk of asphalt under his arm.
    At the counter he says, "I'll take a large latte for myself, please, and one for the road."`,
    'Why shouldn’t you discuss coffee in polite company? It can make for a strong and heated debate.',
    'A yawn is just a silent scream for coffee.',
    'Why did the espresso keep checking his watch? Because he was pressed for time.',
    'Did you hear about the cow that gave birth? It was de-calf-inated.',
    'What do you call sad coffee? Depresso.',
    'Why did the coffee file a police report? Because it was mugged.',
    'What did the coffee lover name his son? Joe, obviously.',
    'What’s the opposite of coffee? Sneezy.',
    'A guy walks into a cafe and orders a coffee to go. The coffee gets up and leaves.',
    'I drink so much coffee at work, I consider it part of my daily grind.',
    'Someone stole my coffee cup from work today. I\'m just off down the police station now to look at a few mug shots.',
    'I just read that every year we spend more on coffee than we do on educating our children. How do we sleep at night?',
    'What do you call it when cafe customers joke about their coffee? A brewhaha.',
  ];

  constructor() { }

  public ngOnInit(): void {
    this.joke = this.jokes[Math.floor(Math.random() * (this.jokes.length - 1))];
  }
}
