import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { environment } from 'src/environments/environment';
import { EnvironmentConfig, BuildData } from 'src/environments/interfaces/environment-config.interface';
import { NativeEnvironmentService } from '../../packages/native-support/services/native-environment/native-environment.service';
import { EnvironmentModes, EnvironmentPlatforms } from './environment.enums';

export { EnvironmentModes, EnvironmentPlatforms };

export interface EnvironmentVersions {
  // name of app ios / android
  appName: string;
  // version of the device (ios / android)
  deviceNumber?: string;
  deviceCode?: string;
  // version from package.json
  packageVersion: string;
  build: BuildData;
}

// cordova reference (todo: maybe move to another file)

@Injectable()
export class EnvironmentService {

  private resumeSubject: Subject<void>;
  private pauseSubject: Subject<void>;

  constructor(

    private nativeEnvironment: NativeEnvironmentService,
  ) {
    this.bindEventObservables();
  }

  onReady(): Promise<void> {
    return this.nativeEnvironment.ready();
  }

  onResume(): Observable<void> {
    return this.resumeSubject.asObservable();
  }

  onPause(): Observable<void> {
    return this.pauseSubject.asObservable();
  }

  async getPlatform(): Promise<EnvironmentPlatforms> {
    const platform = await this.nativeEnvironment.getPlatform();
    switch (platform) {
      case 'ios':
        return EnvironmentPlatforms.IOS;
      case 'android':
        return EnvironmentPlatforms.ANDROID;
    }

    if (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) {
      return EnvironmentPlatforms.PWA;
    } else {
      return EnvironmentPlatforms.WEB;
    }
  }

  getPlatformName(): Promise<string> {
    return this.getPlatform().then(platform => {
      switch (platform) {
        case EnvironmentPlatforms.ANDROID:
          return 'android';
        case EnvironmentPlatforms.IOS:
          return 'ios';
        case EnvironmentPlatforms.WEB:
          return 'web';
        case EnvironmentPlatforms.PWA:
          return 'pwa';
      }
      return 'unknown';
    });
  }

  isNative(): Promise<boolean> {
    return this.getPlatform()
      .then(platform => platform === EnvironmentPlatforms.IOS || platform === EnvironmentPlatforms.ANDROID);
  }

  isWeb(): Promise<boolean> {
    return this.isNative().then(v => !v);
  }

  async getMode(): Promise<EnvironmentModes> {
    return EnvironmentModes.MERCHANT;
  }

  getAppName(): Promise<string> {
    return this.nativeEnvironment.getAppName();
  }

  getEnvName(): string {
    return environment.name;
  }

  async getVersions(): Promise<EnvironmentVersions> {
    let deviceNumber: string;
    let deviceCode: string;
    let appName: string;

    if (await this.isNative() === true) {
      try {
        appName = await this.nativeEnvironment.getPackageName();
        deviceNumber = await this.nativeEnvironment.getVersionNumber();
        deviceCode = await this.nativeEnvironment.getVersionCode();
      } catch (e) { }
    }

    return {
      appName,
      deviceNumber,
      deviceCode,
      packageVersion: environment.packageVersion,
      build: environment.build,
    };
  }

  isProduction(): boolean {
    return !!this.getConfig().production;
  }

  getConfig(): EnvironmentConfig {
    return environment;
  }

  getHost(): string {
    return location.host;
  }

  private async bindEventObservables(): Promise<void> {
    this.resumeSubject = new Subject();
    this.pauseSubject = new Subject();

    await this.onReady();

    if (await this.isNative() === true) {
      document.addEventListener('resume', () => this.resumeSubject.next());
      document.addEventListener('pause', () => this.pauseSubject.next());
    } else {
      window.addEventListener('focus', () => this.resumeSubject.next());
      window.addEventListener('blur', () => this.pauseSubject.next());
    }
  }

}
