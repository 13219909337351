import { MatDialog } from '@angular/material';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';
var ErrorDialogService = (function () {
    function ErrorDialogService(dialog) {
        this.dialog = dialog;
    }
    ErrorDialogService.prototype.show = function (options) {
        this.dismiss();
        ErrorDialogService.dialogRef = this.dialog.open(ErrorDialogComponent, { data: options, minWidth: '300px', maxWidth: '600px' });
    };
    ErrorDialogService.prototype.dismiss = function () {
        if (ErrorDialogService.dialogRef) {
            ErrorDialogService.dialogRef.close();
            ErrorDialogService.dialogRef = undefined;
        }
    };
    return ErrorDialogService;
}());
export { ErrorDialogService };
