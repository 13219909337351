import { OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var UserVerificationDialogComponent = (function () {
    function UserVerificationDialogComponent(data, dialogRef) {
        this.dialogRef = dialogRef;
        this.resendCode = new EventEmitter();
        if (data) {
            this.phoneNumber = data.phoneNumber;
            this.tryCount = data.tryCount;
        }
    }
    UserVerificationDialogComponent.prototype.ngOnInit = function () {
    };
    UserVerificationDialogComponent.prototype.onDone = function (code) {
        this.dialogRef.close(code);
    };
    UserVerificationDialogComponent.prototype.onResendCode = function () {
        this.resendCode.emit();
    };
    return UserVerificationDialogComponent;
}());
export { UserVerificationDialogComponent };
