import { OnInit } from '@angular/core';
var LoadingIndicatorComponent = (function () {
    function LoadingIndicatorComponent() {
        this.small = true;
    }
    LoadingIndicatorComponent.prototype.ngOnInit = function () {
    };
    LoadingIndicatorComponent.prototype.setLoadingProgress = function (progress) {
        this.loadingProgress = progress;
    };
    return LoadingIndicatorComponent;
}());
export { LoadingIndicatorComponent };
