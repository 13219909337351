import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { SocialShareBottomSheetComponent } from './components/social-share-bottom-sheet/social-share-bottom-sheet.component';

const entryComponents = [SocialShareBottomSheetComponent];

export const MATERIAL_IMPORTS = [
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    ...entryComponents,
  ],
  providers: [],
  exports: [],
  entryComponents,

})
export class SocialShareModule { }
