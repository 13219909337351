import { Injectable } from '@angular/core';
import { Big } from 'big.js';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { cloneDeep as _cloneDeep } from 'lodash';

import { OrderItem } from '../../models/order-item/order-item.class';
import { ApplicationError, ErrorNames } from 'src/app/shared/errors/application-error';
import { OrderPricingItemDto, OrderDto, MenuIterationDto } from 'src/gen/joeServerCore';
import { StoreMenuService } from '../store-menu/store-menu.service';
import { OrderItemOptionChoice } from '../../models/order-item-option-choice/order-item-option-choice.class';
import { v4 as uuidV4 } from 'uuid';

export interface FlatOrderItem {
  name: string;
  options: FlatOption[];
  discount?: string;
  fullPrice?: string;
  price?: string;
  note?: string;
}
export interface FlatOption {
  name: string;
  hidden: boolean;
}
export interface PriceData {
  name: string;
  price: string;
}

export interface CartCanAddItemResult {
  canAdd: boolean;
  reason?: 'limit'; // other reasons could be added as an enum
  limit?: number;
}

@Injectable()
export class StoreCartService {

  constructor(
    private storeMenuService: StoreMenuService,
  ) { }

  private carts: { [key: string]: OrderItem[] } = {};
  private idempotencyKeys: { [key: string]: string } = {};
  private tagLimitMap: { [tag: string]: number } = {};

  flattenOrderItem(orderItem: OrderItem, pricing: OrderPricingItemDto): FlatOrderItem {
    const name = orderItem.getName();
    const options = orderItem.getSelectedOptions();
    const flatOptions: FlatOption[] = [];
    const price = pricing ? pricing.total : '0';

    options.forEach(option => {
      const optionName = option.name;
      option.getSelectedChoices().forEach(choice => {
        const quantity = choice.quantityNames && choice.quantityNames.length > choice.quantity
          ? choice.quantityNames[choice.quantity] : choice.quantity;
        const displayQuantity = quantity === 1 ? '' : ` (${quantity})`;
        const flatOption = `${optionName}, ${choice.name}${displayQuantity}`;
        flatOptions.push({
          name: flatOption,
          hidden: choice.hidden,
        });
      });
    });

    const discount = pricing ? pricing.discount : '0';

    return {
      name: name,
      options: flatOptions,
      price: price,
      note: orderItem.note,
      discount,
      fullPrice: Big(price).plus(discount).toString(),
    } as FlatOrderItem;
  }

  addToCart(orderItem: OrderItem, storeId: string): void {
    if (!this.carts.hasOwnProperty(storeId)) {
      this.resetCart(storeId);
    }

    this.carts[storeId].push(orderItem);
  }

  updateCartItem(orderItem: OrderItem, storeId: string, cartItemId: number): void {
    if (this.carts.hasOwnProperty(storeId)) {
      this.carts[storeId].splice(cartItemId, 1, orderItem);
    }
  }

  /**
   * Sets the cart for the selected store (used by resetCart and reorder)
   */
  addCart(cart: OrderItem[], storeId: string): void {
    this.carts[storeId] = cart;
    this.generateCartIdempotencyKey(storeId);
  }

  getCart(storeId: string): Observable<OrderItem[]> {
    return of(this.carts[storeId] || []);
  }

  private getCartTagCounts(storeId: string): { [tag: string]: number } {
    const cart = this.carts[storeId] || [];
    const tagCountAccumulator: { [tag: string]: number } = {};
    cart.forEach(item => {
      const itemTags = item.getTags();
      if (itemTags && itemTags.length > 0) {
        itemTags.forEach(itemTag => {
          tagCountAccumulator[itemTag] = (tagCountAccumulator[itemTag] || 0) + 1;
        });
      }
    });
    return tagCountAccumulator;
  }

  canItemBeAddedToCart(storeId: string, orderItem: OrderItem): CartCanAddItemResult {
    const itemTags = orderItem.getTags();
    const tagCounts = this.getCartTagCounts(storeId);

    for (let i = 0, len = itemTags.length; i < len; i++) {
      const itemTag = itemTags[i];
      const tagLimit = this.tagLimitMap[itemTag];
      const itemTagCount = tagCounts[itemTag] || 0;
      if (tagLimit && tagLimit <= itemTagCount) {
        return { canAdd: false, reason: 'limit', limit: tagLimit };
      }
    }
    return { canAdd: true };
  }

  getCartOrderItemById(storeId: string, cartItemIndex: number): Observable<OrderItem> {
    const cart = this.carts[storeId];

    if (cart) {
      const item = cart[cartItemIndex];

      return of(item).pipe(catchError(error => throwError(new ApplicationError({
        name: ErrorNames.DATA_RESOLUTION,
        message: 'Cart item does not exist',
        details: error,
      }))));
    } else {
      return of(undefined);
    }
  }

  removeCartItemById(storeId: string, cartItemId: number): void {
    this.carts[storeId].splice(cartItemId, 1);
  }

  copyCartItem(storeId: string, cartItemId: number): void {
    const cart = this.carts[storeId];
    const clonedItem = _cloneDeep(cart[cartItemId]);
    cart.push(clonedItem);
  }

  resetCart(storeId: string): void {
    this.addCart([], storeId);
  }

  getQuantityName(choice: OrderItemOptionChoice): string {
    if (choice.quantityNames && choice.quantityNames.length > 0) {
      return choice.quantityNames[choice.quantity];
    } else {
      return choice.unit ? choice.quantity + choice.unit : choice.quantity + '';
    }
  }

  getOrderDto(storeId: string): OrderDto {
    const order = {
      storeId,
      items: this.carts[storeId].map((orderItem, index) => {
        const size = orderItem.getSize();
        const sectionCategoryIds = this.storeMenuService.getItemCategoryAndSectionIds(orderItem);
        const items = {
          menuItemId: orderItem.itemId,
          menuCategoryId: sectionCategoryIds.itemCategoryId,
          menuSectionId: sectionCategoryIds.itemSectionId,
          name: orderItem.getName(),
          price: orderItem.getBasePrice(),
          // todo: mark item as "use reward" if the customer chose to use a reward for this item
          notes: [
            {
              type: 'barista',
              value: orderItem.note,
            },
          ],
          size: {
            menuItemSizeId: size.id,
            name: size.name,
            price: size.price,
          },
          options: orderItem.getSelectedOptions().map(option => ({
            menuOptionId: option.id,
            name: option.name,
            choices: option.getSelectedChoices().map(choice => ({
              menuOptionChoiceId: choice.id,
              quantity: choice.quantity,
              quantityName: this.getQuantityName(choice),
              name: choice.name,
              price: choice.getPrice(orderItem.getSize().id),
            })),
          })),
        };
        return items;
      }),
    };

    return order;
  }

  getCartIdempotencyKey(storeId: string): string {
    return this.idempotencyKeys[storeId] || this.generateCartIdempotencyKey(storeId);
  }

  setCartMenuLimitations(menu: MenuIterationDto) {
    if (menu.metaData && menu.metaData.itemTagLimits) {
      menu.metaData.itemTagLimits.forEach(tagLimit => {
        this.tagLimitMap[tagLimit.name] = tagLimit.limit;
      });
    }
  }

  private generateCartIdempotencyKey(storeId: string): string {
    this.idempotencyKeys[storeId] = uuidV4();
    return this.idempotencyKeys[storeId];
  }
}
