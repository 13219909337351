import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
var NativeBackButtonService = (function () {
    function NativeBackButtonService(router, ngZone) {
        this.router = router;
        this.ngZone = ngZone;
        document.addEventListener('backbutton', this.handleBackKey.bind(this), false);
    }
    NativeBackButtonService.prototype.setBackUrl = function (url, extras) {
        this.clearBackAction();
        this.backUrl = url;
        this.backUrlExtras = extras;
    };
    NativeBackButtonService.prototype.setBackExit = function () {
        this.clearBackAction();
        this.backExit = true;
    };
    NativeBackButtonService.prototype.setBackCallback = function (callback) {
        this.clearBackAction();
        this.backCallback = callback;
    };
    NativeBackButtonService.prototype.clearBackAction = function () {
        this.backUrl = undefined;
        this.backUrlExtras = undefined;
        this.backExit = false;
        this.backCallback = undefined;
    };
    NativeBackButtonService.prototype.handleBackKey = function (event) {
        var _this = this;
        event.preventDefault();
        if (this.backExit) {
            var app = navigator.app;
            if (app && app.exitApp) {
                app.exitApp();
            }
        }
        else if (this.backUrl) {
            this.ngZone.run(function () {
                _this.router.navigate(_this.backUrl, _this.backUrlExtras);
                _this.backUrl = undefined;
            });
        }
        else if (this.backCallback) {
            this.ngZone.run(function () { return _this.backCallback(); });
        }
    };
    return NativeBackButtonService;
}());
export { NativeBackButtonService };
