import { MatDialogModule, MatExpansionModule, MatButtonModule, MatIconModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
];
var UiConfirmDialogModule = (function () {
    function UiConfirmDialogModule() {
    }
    return UiConfirmDialogModule;
}());
export { UiConfirmDialogModule };
