var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Honeybadger from '@honeybadger-io/js';
import logDNA from '@logdna/browser';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
var TelemetryService = (function () {
    function TelemetryService(environmentService) {
        this.environmentService = environmentService;
        this.telemetryConfig = environmentService.getConfig().telemetry;
    }
    TelemetryService.prototype.init = function (http) {
        this.isProduction = this.environmentService.isProduction();
        this.initProviders(http);
    };
    TelemetryService.prototype.setActiveUser = function (userName, userId) {
        if (!this.isProduction) {
            return;
        }
        this.currentUserId = userId;
        this.publishIdentityChange();
    };
    TelemetryService.prototype.setActiveDevice = function (storeName, storeId) {
        if (!this.isProduction) {
            return;
        }
        this.currentDeviceId = storeId;
        this.publishIdentityChange();
    };
    TelemetryService.prototype.publishIdentityChange = function () {
        if (!this.isProduction) {
            return;
        }
        this.setHoneyBadgerUser();
    };
    TelemetryService.prototype.logPageUrlChange = function (url) {
        if (!this.isProduction) {
            return;
        }
        this.currentUrl = url;
        this.logToLocalytics('tagScreen', url);
        this.logEventToLocalytics("page_load", { url: url });
        this.logGoogleAnalyticsPageChange(url);
    };
    TelemetryService.prototype.logError = function (description, fatal, shouldNotifyHoneyBadger) {
        if (fatal === void 0) { fatal = false; }
        if (shouldNotifyHoneyBadger === void 0) { shouldNotifyHoneyBadger = true; }
        if (!this.isProduction) {
            return;
        }
        if (shouldNotifyHoneyBadger) {
            Honeybadger.setContext({
                url: window.location.href,
            });
            Honeybadger.notify(description);
        }
        this.logEventToLocalytics("error", { description: description, fatal: fatal });
        this.logToLogDNA("error", description, { fatal: fatal });
    };
    TelemetryService.prototype.logEvent = function (name, data) {
        if (!this.isProduction) {
            return;
        }
        this.logEventToLocalytics(name, data);
        this.logGoogleAnalyticsEvent(name);
        this.logToLogDNA('log', name, data);
    };
    TelemetryService.prototype.logAddedToCartEvent = function (itemName, price, currency) {
        if (currency === void 0) { currency = 'USD'; }
        if (!this.isProduction) {
            return;
        }
        this.logEventToLocalytics("cart_add_item", { itemName: itemName, price: price, currency: currency });
    };
    TelemetryService.prototype.logInitiatedCheckoutEvent = function (itemNames, numItems, paymentInfoAvailable, totalPrice, currency) {
        if (currency === void 0) { currency = 'USD'; }
        if (!this.isProduction) {
            return;
        }
        this.logEventToLocalytics("checkout_start", { itemNames: itemNames, numItems: numItems, paymentInfoAvailable: paymentInfoAvailable, totalPrice: totalPrice, currency: currency });
    };
    TelemetryService.prototype.logPurchaseEvent = function (totalPrice, paymentMethod, currency) {
        if (currency === void 0) { currency = 'USD'; }
        if (!this.isProduction) {
            return;
        }
        this.logEventToLocalytics("checkout_finish", { totalPrice: totalPrice, currency: currency, paymentMethod: paymentMethod }, totalPrice);
    };
    TelemetryService.prototype.logEventToLocalytics = function (name, data, ltv) {
        this.logToLocalytics('tagEvent', name, data, ltv);
    };
    TelemetryService.prototype.logToLocalytics = function (event, name, data, ltv) {
        if (!this.localyticsEnabled) {
            return;
        }
        try {
            if (this.currentUserId) {
                ll('setCustomerId', this.currentUserId);
            }
            ll(event + '.' + this.getLocalyticsNamespace(), name, data, ltv ? parseInt(ltv, 10) : undefined);
        }
        catch (e) { }
    };
    TelemetryService.prototype.getLocalyticsNamespace = function () {
        if (this.isConsumerMode) {
            return 'consumer';
        }
        if (this.currentUrl.startsWith('/pos')) {
            return 'pos';
        }
        return 'merchant';
    };
    TelemetryService.prototype.initLocalytics = function () {
        return __awaiter(this, void 0, void 0, function () {
            var localyticsKeys, appVersion;
            return __generator(this, function (_a) {
                if (!this.telemetryConfig || !this.telemetryConfig.localyticsKeys) {
                    return [2];
                }
                localyticsKeys = this.telemetryConfig.localyticsKeys;
                appVersion = this.appVersion || 'unknown';
                if (localyticsKeys.consumer) {
                    ll('init.consumer', localyticsKeys.consumer, { appVersion: appVersion });
                }
                if (localyticsKeys.merchant) {
                    ll('init.merchant', localyticsKeys.merchant, { appVersion: appVersion });
                }
                if (localyticsKeys.pos) {
                    ll('init.pos', localyticsKeys.pos, { appVersion: appVersion });
                }
                this.localyticsEnabled = true;
                return [2];
            });
        });
    };
    TelemetryService.prototype.initHoneyBadger = function () {
        var _this = this;
        var currentEnv = this.environmentService.getConfig();
        Honeybadger.configure({
            apiKey: 'hbp_wKcTrVMQ2bRbmQIcs4vaIAhZqvlk9S2xPZI1',
            environment: (currentEnv.name || 'unknown_environment').toLowerCase(),
            revision: this.appVersion || 'unknown_build_number',
            reportData: false,
        });
        Honeybadger.beforeNotify(function (notice) {
            return _this.filterIgnoredErrors(notice);
        });
    };
    TelemetryService.prototype.initLogDNA = function () {
        if (!this.isProduction) {
            return;
        }
        var currentEnv = this.environmentService.getConfig();
        var logDNAKeys = currentEnv.telemetry.logDNAKeys;
        if (logDNAKeys) {
            this.logDNA = logDNA.init(logDNAKeys.merchant, {
                app: 'merchant-web-app',
                env: currentEnv.name || 'unknown_environment',
                tags: [this.appVersion, currentEnv.name],
                enableStacktrace: true,
            });
            this.logDNA.addContext({
                revision: this.appVersion || 'unknown_build_number',
                user_id: this.currentUserId || 'unknown_user_id',
                device_id: this.currentDeviceId || 'unknown_device_id',
            });
        }
    };
    TelemetryService.prototype.logToLogDNA = function (event, message, data) {
        if (this.logDNA) {
            this.logDNA[event](message, data);
        }
    };
    TelemetryService.prototype.initProviders = function (http) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, versions, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isProduction) {
                            return [2];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        _a = this;
                        return [4, this.environmentService.getMode()];
                    case 2:
                        _a.isConsumerMode = (_b.sent()) === 0;
                        return [4, this.environmentService.getVersions()];
                    case 3:
                        versions = _b.sent();
                        this.appVersion = versions && versions.build && versions.build.number;
                        return [4, this.initLocalytics()];
                    case 4:
                        _b.sent();
                        this.initHoneyBadger();
                        this.initLogDNA();
                        return [3, 6];
                    case 5:
                        e_1 = _b.sent();
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    TelemetryService.prototype.logGoogleAnalyticsPageChange = function (url) {
        if (!this.isProduction) {
            return;
        }
        gtag('config', 'UA-122461686-3', { page_path: url });
    };
    TelemetryService.prototype.logGoogleAnalyticsEvent = function (eventName, data) {
        if (!this.isProduction) {
            return;
        }
        gtag('event', eventName, data);
    };
    TelemetryService.prototype.setHoneyBadgerUser = function () {
        if (!this.isProduction) {
            return;
        }
        Honeybadger.setContext({
            user_id: this.currentUserId || 'unknown_user_id',
            device_id: this.currentDeviceId || 'unknown_device_id',
        });
    };
    TelemetryService.prototype.filterIgnoredErrors = function (notice) {
        if (/Audio unload error/.test(notice.message)) {
            return false;
        }
        if (notice.message === 'Audio Playback Error Unknown error') {
            return false;
        }
        return true;
    };
    return TelemetryService;
}());
export { TelemetryService };
