import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ErrorDialogService } from './services/error-dialog/error-dialog.service';
import { MatDialogModule, MatExpansionModule, MatButtonModule, MatIconModule } from '@angular/material';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatExpansionModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    ErrorDialogComponent,
  ],
  providers: [
    ErrorDialogService,
  ],
  exports: [
    ErrorDialogComponent,
    ...MATERIAL_IMPORTS,
  ],
  entryComponents: [
    ErrorDialogComponent,
  ],

})
export class UiErrorDialogModule { }
