import { Injectable } from '@angular/core';
import * as Honeybadger from '@honeybadger-io/js';
import logDNA, { LogDNABrowserLogger } from '@logdna/browser';
import { EnvironmentService, EnvironmentModes } from 'src/app/shared/services/environment/environment.service';
import { TelemetryEventName } from './telemetry-event-name';
import { TelemetryConfig } from 'src/environments/interfaces/environment-config.interface';
import { HttpClient } from '@angular/common/http';

interface GoogleGTagData {
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: number;
  page_path?: string;
}

type GoogleGTag = (action: string, value: string, data?: GoogleGTagData) => {};
type LogLevel = 'log' | 'error' | 'debug' | 'warn' | 'info';

declare const ll: (action: string, name: string, data?: { [key: string]: any }, ltv?: number) => void;
declare const gtag: GoogleGTag;

@Injectable()
export class TelemetryService {

  private currentUrl: string;
  private appVersion: string;
  private isConsumerMode: boolean;
  private localyticsEnabled: boolean;
  private telemetryConfig: TelemetryConfig;
  private currentUserId: string;
  private currentDeviceId: string;
  private logDNA: LogDNABrowserLogger;

  private isProduction: boolean;

  constructor(
    private environmentService: EnvironmentService,
  ) {
    this.telemetryConfig = environmentService.getConfig().telemetry;
  }

  init(http: HttpClient): void {
    this.isProduction = this.environmentService.isProduction();
    this.initProviders(http);
  }

  setActiveUser(userName: string, userId: string): void {
    if (!this.isProduction) {
      return;
    }

    this.currentUserId = userId;
    this.publishIdentityChange();
  }

  setActiveDevice(storeName: string, storeId: string): void {
    if (!this.isProduction) {
      return;
    }

    this.currentDeviceId = storeId;
    this.publishIdentityChange();
  }

  publishIdentityChange(): void {
    if (!this.isProduction) {
      return;
    }

    this.setHoneyBadgerUser();
  }

  logPageUrlChange(url: string): void {
    if (!this.isProduction) {
      return;
    }

    this.currentUrl = url;
    this.logToLocalytics('tagScreen', url);
    this.logEventToLocalytics(TelemetryEventName.PAGE_LOAD, { url });
    this.logGoogleAnalyticsPageChange(url);
  }

  logError(
    /** Error message or the description of the error */
    description: string,
    fatal = false,
    /** By default, logs this error on HoneyBadger, otherwise, set this to false */
    shouldNotifyHoneyBadger = true,
  ): void {
    if (!this.isProduction) {
      return;
    }

    if (shouldNotifyHoneyBadger) {
      Honeybadger.setContext({
        url: window.location.href,
      });
      Honeybadger.notify(description);
    }

    this.logEventToLocalytics(TelemetryEventName.ERROR, { description, fatal });
    this.logToLogDNA(TelemetryEventName.ERROR, description, { fatal });
  }

  logEvent(name: TelemetryEventName, data: { [key: string]: any }): void {
    if (!this.isProduction) {
      return;
    }

    this.logEventToLocalytics(name, data);
    this.logGoogleAnalyticsEvent(name);
    this.logToLogDNA('log', name, data);
  }

  logAddedToCartEvent(itemName: string, price: string, currency: string = 'USD'): void {
    if (!this.isProduction) {
      return;
    }

    this.logEventToLocalytics(TelemetryEventName.CART_ADD_ITEM, { itemName, price, currency });
  }

  logInitiatedCheckoutEvent(
    itemNames: string[], numItems: number, paymentInfoAvailable: boolean, totalPrice: string, currency: string = 'USD',
  ): void {
    if (!this.isProduction) {
      return;
    }

    this.logEventToLocalytics(
      TelemetryEventName.CHECKOUT_START,
      { itemNames, numItems, paymentInfoAvailable, totalPrice, currency },
    );
  }

  logPurchaseEvent(totalPrice: string, paymentMethod: string, currency: string = 'USD'): void {
    if (!this.isProduction) {
      return;
    }

    this.logEventToLocalytics(TelemetryEventName.CHECKOUT_FINISH, { totalPrice, currency, paymentMethod }, totalPrice);
  }

  private logEventToLocalytics(name: TelemetryEventName, data?: { [key: string]: any }, ltv?: string): void {
    this.logToLocalytics('tagEvent', name, data, ltv);
  }

  private logToLocalytics(event: string, name: string, data?: { [key: string]: any }, ltv?: string): void {
    if (!this.localyticsEnabled) {
      return;
    }

    try {
      if (this.currentUserId) {
        ll('setCustomerId', this.currentUserId);
      }

      ll(event + '.' + this.getLocalyticsNamespace(), name, data, ltv ? parseInt(ltv, 10) : undefined);
    } catch (e) { }
  }

  private getLocalyticsNamespace(): string {
    if (this.isConsumerMode) {
      return 'consumer';
    }

    if (this.currentUrl.startsWith('/pos')) {
      return 'pos';
    }

    return 'merchant';
  }

  private async initLocalytics(): Promise<void> {
    if (!this.telemetryConfig || !this.telemetryConfig.localyticsKeys) {
      return;
    }

    const localyticsKeys = this.telemetryConfig.localyticsKeys;
    const appVersion = this.appVersion || 'unknown';

    if (localyticsKeys.consumer) {
      ll('init.consumer', localyticsKeys.consumer, { appVersion });
    }

    if (localyticsKeys.merchant) {
      ll('init.merchant', localyticsKeys.merchant, { appVersion });
    }

    if (localyticsKeys.pos) {
      ll('init.pos', localyticsKeys.pos, { appVersion });
    }

    this.localyticsEnabled = true;
  }

  private initHoneyBadger() {
    const currentEnv = this.environmentService.getConfig();

    Honeybadger.configure({
      apiKey: 'hbp_wKcTrVMQ2bRbmQIcs4vaIAhZqvlk9S2xPZI1',
      environment: (currentEnv.name || 'unknown_environment').toLowerCase(),
      revision: this.appVersion || 'unknown_build_number',
      reportData: false,
    });

    Honeybadger.beforeNotify((notice) => {
      return this.filterIgnoredErrors(notice);
    });
  }

  private initLogDNA() {
    if (!this.isProduction) {
      return;
    }

    const currentEnv = this.environmentService.getConfig();
    const logDNAKeys = currentEnv.telemetry.logDNAKeys;

    if (logDNAKeys) {
      // Initialize the logger
      this.logDNA = logDNA.init(logDNAKeys.merchant, {
        app: 'merchant-web-app',
        env: currentEnv.name || 'unknown_environment',
        tags: [this.appVersion, currentEnv.name],
        enableStacktrace: true,
      });

      // Add any additional context
      this.logDNA.addContext({
        revision: this.appVersion || 'unknown_build_number',
        user_id: this.currentUserId || 'unknown_user_id',
        device_id: this.currentDeviceId || 'unknown_device_id',
      });
    }

  }

  private logToLogDNA(event: LogLevel, message: string, data?: { [key: string]: any; }) {
    if (this.logDNA) {
      this.logDNA[event](message, data);
    }
  }

  private async initProviders(http: HttpClient): Promise<void> {
    if (!this.isProduction) {
      return;
    }

    try {
      this.isConsumerMode = (await this.environmentService.getMode()) === EnvironmentModes.CONSUMER;
      const versions = await this.environmentService.getVersions();
      this.appVersion = versions && versions.build && versions.build.number;
      await this.initLocalytics();
      this.initHoneyBadger();
      this.initLogDNA();
    } catch (e) { }
  }

  private logGoogleAnalyticsPageChange(url: string): void {
    if (!this.isProduction) {
      return;
    }
    gtag('config', 'UA-122461686-3', { page_path: url });
  }
  private logGoogleAnalyticsEvent(eventName: string, data?: GoogleGTagData): void {
    if (!this.isProduction) {
      return;
    }
    gtag('event', eventName, data);
  }

  private setHoneyBadgerUser() {
    if (!this.isProduction) {
      return;
    }
    Honeybadger.setContext({
      user_id: this.currentUserId || 'unknown_user_id',
      device_id: this.currentDeviceId || 'unknown_device_id',
    });
  }

  private filterIgnoredErrors(notice: { name: string, message: string }) {

    // Ignore audio unload error in audio alert service
    // https://github.com/Joe-Coffee/data/issues/298#issuecomment-905743022
    if (/Audio unload error/.test(notice.message)) {
      return false;
    }

    // Ignore unknown audio playback error in audio alert service
    // This error is likely thrown due to Audio unload error which is nested in the same try catch block
    if (notice.message === 'Audio Playback Error Unknown error') {
      return false;
    }

    return true;
  }

}
