<h2 mat-dialog-title>
  {{editMode ? 'Edit' : 'Create'}} Option
</h2>
<mat-dialog-content>
  <div *ngIf="optionForm; else loadingOption" [formGroup]="optionForm">
    <div class="header-warning" *ngIf="editMode">Warning: You are editing an existing menu option. This change may be
      reflected on
      multiple menu items.</div>
    <div>
      <mat-form-field class="full-width">
        <input matInput placeholder="Option Name" type="text" formControlName="name">
        <mat-error *ngIf="getError('name'); let error">{{ error.message }}</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="section-header">
      <div fxLayout="row" fxLayoutAlign="start start">
        <h3>Quantity</h3>
      </div>
      <mat-button-toggle-group [value]="quantityTypeToggleValue" (change)="quantityTypeChange($event)" flat size="small"
        color="light" class="quantity-type-toggle">
        <mat-button-toggle value="one">
          One
        </mat-button-toggle>
        <mat-button-toggle value="finite">
          Finite
        </mat-button-toggle>
        <mat-button-toggle value="named">
          Named
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
    </div>
    <div *ngIf="showFiniteQty" fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="calc(20% - 10px)">
        <input matInput placeholder="Minimum Quantity" type="number" formControlName="minimumQuantity">
        <mat-error *ngIf="getError('minimumQuantity'); let error">{{ error.message }}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="calc(20% - 10px)">
        <input matInput placeholder="Maximum Quantity" type="number" formControlName="maximumQuantity">
        <mat-error *ngIf="getError('maximumQuantity'); let error">{{ error.message }}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="calc(20% - 10px)">
        <input matInput placeholder="Quantity Increment" type="number" formControlName="quantityIncrement">
        <mat-error *ngIf="getError('quantityIncrement'); let error">{{ error.message }}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="calc(20% - 10px)">
        <input matInput placeholder="Default Quantity" type="number" formControlName="defaultQuantity">
        <mat-error *ngIf="getError('defaultQuantity'); let error">{{ error.message }}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="calc(20% - 10px)">
        <input matInput placeholder="Quantity Unit" type="text" formControlName="quantityUnit">
        <mat-error *ngIf="getError('quantityUnit'); let error">{{ error.message }}</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!showFiniteQty">
      <mat-radio-group class="full-width" formControlName="defaultQuantity">
        <div *ngFor="let quantity of optionForm?.get('quantityNames')?.controls; let i = index" fxLayout="row"
          fxLayoutGap="10px" fxLayoutAlign="center center">
          <mat-radio-button [value]="i"></mat-radio-button>
          <mat-form-field fxFlex>
            <input matInput placeholder="Quantity Name" type="text" [formControl]="quantity">
            <mat-error *ngIf="getError('quantityNames', i); let error">{{ error.message }}</mat-error>
          </mat-form-field>
          <button mat-icon-button color="primary" size="small" (click)="deleteQuantity(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-radio-group>
      <div class="error-display" *ngIf="getError('quantityNames'); let error">{{ error.message }}</div>
      <div fxLayoutAlign="center">
        <button mat-flat-button color="primary" size="small" (click)="addQuantity()">
          <mat-icon>add</mat-icon>
          Add Quantity
        </button>
      </div>
    </div>
    <div class="section-header" fxLayout="row" fxLayoutAlign="space-between start">
      <h3>Choices</h3>
      <div fxLayoutAlign="center">
        <button mat-icon-button color="primary" [matMenuTriggerFor]="menuAddMulti">
          <mat-icon>add</mat-icon>
        </button>
        <mat-menu #menuAddMulti="matMenu">
          <ng-template matMenuContent>
            <button type="button" mat-menu-item (click)="addChoice()">Add 1</button>
            <button type="button" mat-menu-item (click)="addChoice(2)">Add 2</button>
            <button type="button" mat-menu-item (click)="addChoice(5)">Add 5</button>
            <button type="button" mat-menu-item (click)="addChoice(10)">Add 10</button>
            <button type="button" mat-menu-item (click)="addChoice(20)">Add 20</button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
    <div dragula="option_choice_modal_drag_id" [dragulaOptions]="dragulaOptions" (dragulaDrop)="onChoiceDrop($event)">
      <div *ngFor="let choice of optionForm?.get('choices')?.controls; let i = index" fxLayout="row" fxLayoutGap="10px"
        fxLayoutAlign="center center" [formGroup]="choice">
        <mat-form-field fxFlex>
          <input matInput placeholder="Choice Name" type="text" formControlName="name">
          <mat-error *ngIf="getError('choices', i, 'name'); let error">{{ error.message }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <mat-currency-input placeholder="Price" formControlName="price"></mat-currency-input>
          <mat-error *ngIf="getError('choices', i, 'price'); let error">{{ error.message }}</mat-error>
        </mat-form-field>
        <button mat-icon-button color="primary" size="small" (click)="deleteChoice(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div class="error-display" *ngIf="getError('choices'); let error">{{ error.message }}</div>
      <div fxLayoutAlign="center">
        <button mat-flat-button color="primary" size="small" (click)="addChoice()">
          <mat-icon>add</mat-icon>
          Add Choice
        </button>
      </div>
    </div>
  </div>
  <ng-template #loadingOption>
    Loading...
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="space-between">
  <button mat-flat-button color="primary" mat-dialog-close>
    <mat-icon>close</mat-icon> Cancel
  </button>
  <div class="error-display">{{errorMessage}}</div>
  <button mat-flat-button color="accent" (click)="save()"
    [disabled]="optionForm?.pristine || optionForm?.invalid || errorMessage">
    <mat-icon>save</mat-icon> Save
  </button>
</mat-dialog-actions>