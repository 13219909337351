import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerchantRegistrationSuccessDialogComponent } from './merchant-registration-success-dialog/merchant-registration-success-dialog.component';
import { MatDialogModule, MatButtonModule, MatCardModule, MatIconModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  // MatFormFieldModule,
  // MatInputModule,
  MatButtonModule,
  MatCardModule,
  // MatSelectModule,
  // MatProgressSpinnerModule,
  // MatTabsModule,
  MatIconModule,
  // MatRadioModule,
  // MatCheckboxModule,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    ...MATERIAL_IMPORTS,
  ],
  entryComponents: [
    MerchantRegistrationSuccessDialogComponent,
  ],
  declarations: [
    MerchantRegistrationSuccessDialogComponent,
  ],
})
export class MerchantEditDialogsModule { }
