import { Component, OnInit, Input } from '@angular/core';
import { OrderViewConsumerDto } from 'src/gen/joeServerCore';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { OrderService } from 'src/app/consumer/services/order/order.service';
import { StoreCartService } from 'src/app/consumer/services/store-cart/store-cart.service';
import { StringUtilService } from 'src/app/shared/services/string-util/string-util.service';
import { OrderItem } from 'src/app/consumer/models/order-item/order-item.class';
import { RecentOrderReorderComponent } from './recent-order-reorder/recent-order-reorder.component';

export enum OrderStatusStanding {
  POSITIVE = 'positive',
  PENDING = 'pending',
  NEGATIVE = 'negative',
}

export interface ReorderCartData {
  cart: OrderItem[];
}

@Component({
  selector: 'recent-order-card',
  templateUrl: './recent-order-card.component.html',
  styleUrls: ['./recent-order-card.component.scss'],
})
export class RecentOrderCardComponent implements OnInit {

  @Input()
  orders: OrderViewConsumerDto[];

  constructor(
    private router: Router,
    private orderService: OrderService,
    private storeCartService: StoreCartService,
    private stringUtilService: StringUtilService,
    private matDialog: MatDialog,
  ) { }

  public ngOnInit(): void {
  }

  checkStatusStanding(orderStatus: string): string {
    if (orderStatus === 'completed') {
      return OrderStatusStanding.POSITIVE;
    } else if (orderStatus === 'new' || orderStatus === 'accepted') {
      return OrderStatusStanding.PENDING;
    } else {
      return OrderStatusStanding.NEGATIVE;
    }
  }

  checkStatusOrReview(order: OrderViewConsumerDto) {
    this.router.navigateByUrl(`/order/${order.storeId}/${order.id}`);
  }

  async reorder(order: OrderViewConsumerDto): Promise<void> {
    const cart = await this.orderService.getCartFromOrderViewConsumerDto(order);

    if (cart && cart.length) {
      this.storeCartService.addCart(cart, order.storeId);
      const storeName = this.stringUtilService.urlify(order.storeName);
      this.router.navigateByUrl(`/stores/${storeName}/${order.storeId}/details/(store:cart)`);
    } else {
      this.matDialog.open(RecentOrderReorderComponent, {
        data: {
          order,
          cart,
        },
      });
    }
  }
}
