import { PipeTransform } from '@angular/core';
var TextLimitationPipe = (function () {
    function TextLimitationPipe() {
    }
    TextLimitationPipe.prototype.transform = function (text, limit) {
        if (limit === void 0) { limit = 100; }
        if (!text) {
            return;
        }
        if (text.length < limit) {
            return text;
        }
        var lastSpaceIndex = text.lastIndexOf(' ', limit);
        return text.slice(0, lastSpaceIndex === -1 ? limit : lastSpaceIndex) + '...';
    };
    return TextLimitationPipe;
}());
export { TextLimitationPipe };
