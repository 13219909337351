import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GetFirstPipe } from './array/get-first/get-first.pipe';
import { CentCurrencyPipe } from './currency/cent-currency/cent-currency.pipe';
import { NumberPadPipe } from './number-pad/number-pad.pipe';
import { MicrotimePipe } from './date/microtime/microtime.pipe';
import { MinutesToLocaleTimePipe } from './date/minutes-to-locale-time/minutes-to-locale-time.pipe';
import { WeightToOuncesPipe } from './weight/weight-to-ounces/weight-to-ounces.pipe';
import { DistanceToMilesPipe } from './distance-to-miles/distance-to-miles.pipe';
import { OrderStatusPipe } from './order-status/order-status.pipe';
import { PhoneNumberPipe } from './phone-number/phone-number.pipe';
import { TextLimitationPipe } from './text-limitation/text-limitation.pipe';
import { SecondsDurationToTextPipe } from './date/seconds-duration-to-text/seconds-duration-to-text.pipe';
import { DatePatchedPipe } from './date/date-patched/date-patched.pipe';
import { DateDifferencePipe } from './date/date-difference/date-difference.pipe';
import { BooleanNiceResponse } from './boolean/boolean-nice-response.pipe';

const EXPORTED_PIPES = [
  GetFirstPipe,
  CentCurrencyPipe,
  NumberPadPipe,
  MicrotimePipe,
  MinutesToLocaleTimePipe,
  DistanceToMilesPipe,
  WeightToOuncesPipe,
  OrderStatusPipe,
  PhoneNumberPipe,
  TextLimitationPipe,
  SecondsDurationToTextPipe,
  DatePatchedPipe,
  DateDifferencePipe,
  BooleanNiceResponse,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ...EXPORTED_PIPES,
  ],
  providers: [...EXPORTED_PIPES, DatePipe],
  exports: EXPORTED_PIPES,
  entryComponents: [],

})
export class DataTransformPipesModule { }
