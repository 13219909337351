import { Injectable, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as jwtDecode from 'jwt-decode';

import { StorageKeys } from '../../enums/storage-keys';
import { ApiService, AuthChangePasswordResponseDto, AuthResetPasswordResponseDto, AuthResponseDto, RewardDto } from 'src/gen/joeServerCore';
import { of } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { StorageService } from '../../packages/storage/storage/storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';

// if the jwt expires in less than this many seconds refresh it
const JWT_REFRESH_EXPIRATION_PADDING = 30;

// if this amount of time has elapsed since the last refresh do it again
// const JWT_REFRESH_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes (server expiration is 15 minutes)
// if lock is held for longer than this duration we ignore it because it's presumably stuck / wrong
const JWT_REFRESH_LOCK_EXPIRATION_MS = 1 * 60 * 1000; // 1 minute (must be less than jwt expiration time)

interface RefreshLockStorage {
  lockedAt: number;
}

export interface UserDataStorage {
  users: UserMap;
  activeUserId: string;
}

export interface ConsumerPhoneSignupData {
  phone: string;
  firstName: string;
  lastName: string;
}

export interface ConsumerVerificationLoginData {
  deviceId: string;
  userId: string;
  code: string;
}

export interface UserSignupData {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface User extends AuthResponseDto {
  lastRefresh?: number;
  globalRoles?: string[];
  nagCount?: number;
  lastUploadFundsAmount?: string;
}

export interface UserMap {
  [id: string]: User;
}

export interface UserInfo {
  id: string;
  firstName: string;
  hasEmail?: boolean;
  photo?: string;
  rewards?: RewardDto[];
}

interface JwtData {
  exp: number;
  iat: number;
  id: string;
  globalRoles: string[];
}

@Injectable()
export class IdentityService implements OnDestroy {

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private telemetryService: TelemetryService,
  ) {
    this.loadedPromise = new Promise(resolve => this.loadedPromiseResolver = resolve);
    this.jwtDecode = jwtDecode;
    this.activeUserSubject = new Subject();
    this.getUserData();
  }

  static authRefreshSubject: Subject<boolean>;

  private activeUserSubject: Subject<User>;
  private activeUserId: string;
  private users: UserMap = {};

  private loadedPromise: Promise<void>;
  private loadedPromiseResolver: Function;

  private jwtDecode: (token: string) => JwtData;

  ngOnDestroy(): void {
    this.activeUserSubject.complete();
  }

  getUsers(): UserMap {
    return this.users;
  }

  watchActiveUser(): Observable<User> {
    return this.activeUserSubject.asObservable();
  }

  getActiveUser(): User {
    if (!this.users.hasOwnProperty(this.activeUserId)) {
      return;
    }
    return this.users[this.activeUserId];
  }

  isLoaded(): Promise<void> {
    return this.loadedPromise;
  }

  initAuthRefreshSubject(): void {
    IdentityService.authRefreshSubject = new Subject<boolean>();
    // don't need to call next as we've provided a value
  }

  private sendAndKillAuthRefreshSubject(value: boolean): void {
    if (IdentityService.authRefreshSubject) {
      // send the value to subscribers
      IdentityService.authRefreshSubject.next(value);
      // close the subject
      IdentityService.authRefreshSubject.complete();
      // free the resources
      IdentityService.authRefreshSubject.unsubscribe();
      // let it die / use for state detection
      IdentityService.authRefreshSubject = undefined;
    }
  }

  refreshActiveUser(force: boolean = false): Observable<boolean> {
    return fromPromise(this.isLoaded()).pipe(
      switchMap(() => this.reloadActiveUserData()),
      switchMap(() => {
        if (IdentityService.authRefreshSubject) {
          // In the middle of a refresh, send the observable
          return IdentityService.authRefreshSubject.asObservable();
        }
        // not in the middle of a refresh, create subject
        this.initAuthRefreshSubject();
        const activeUser = this.getActiveUser();
        if (!activeUser || !activeUser.token || !activeUser.refreshToken) {
          this.sendAndKillAuthRefreshSubject(false);
          // user is not logged in return early (refresh failed)
          return of(false);
        }

        const { exp } = this.jwtDecode(activeUser.token);
        const expired = Date.now() / 1000 >= exp - JWT_REFRESH_EXPIRATION_PADDING;
        // const expired = !activeUser.lastRefresh || activeUser.lastRefresh + JWT_REFRESH_EXPIRATION_MS < Date.now();
        if (expired || force) {
          return fromPromise(this.isRefreshLocked()).pipe(switchMap(isLocked => {
            if (isLocked && !force) {
              this.sendAndKillAuthRefreshSubject(false);
              return of(false);
            }

            // return the observable value
            return fromPromise(this.lockRefresh())
              .pipe(
                switchMap(() => {
                  return this.apiService.userAuthRefresh({ refreshToken: activeUser.refreshToken, public: true });
                }),
                tap((user: User) => {
                  user.lastRefresh = Date.now();
                  this.updateActiveUser(user);
                  this.unlockRefresh();
                  // but also send a value to the refresh subject
                  this.sendAndKillAuthRefreshSubject(true);
                }),
                map(user => true),
                catchError(err => {
                  if (err.status === 401) {
                    this.logout();
                  }
                  this.sendAndKillAuthRefreshSubject(false);
                  return of(false);
                }),
              );
          }));
        }

        this.sendAndKillAuthRefreshSubject(false);
        return of(false);
      }));
  }

  setActiveUser(id: string): void {
    if (!this.users.hasOwnProperty(id)) {
      return;
    }

    this.activeUserId = id;
    this.storeUserData();
    this.publishActiveUserChange();
  }

  login({ email, password, phone, deviceId }: { phone?: string, email?: string, password: string, deviceId?: string }): Observable<string> {
    return this.apiService.authLogin({ email, password, phone, deviceId, public: true })
      .pipe(tap(user => this.addUser(user)))
      .pipe(map(user => user.id));
  }

  signup(userData: UserSignupData): Observable<string> {
    return this.apiService.authSignup({ ...userData, phone: userData.phone && '1' + userData.phone, public: true })
      .pipe(tap(user => this.addUser(user)))
      .pipe(map(user => user.id));
  }

  consumerSignupWithPhone(userData: ConsumerPhoneSignupData, storeId?: string): Observable<string> {
    return this.apiService.consumerSignup({ ...userData, phone: '1' + userData.phone, storeId })
      .pipe(map(user => user.id));
  }

  updateUserName(firstName: string, lastName: string): Observable<{ firstName: string, lastName: string }> {
    return this.apiService.userUpdateName({ firstName, lastName })
      .pipe(
        tap(result => this.updateActiveUser({ firstName: result.firstName })),
        map(result => ({ firstName: result.firstName, lastName: result.lastName })),
      );
  }

  addEmailPassword(email: string, password: string): Observable<boolean> {
    return this.apiService.userAddEmailPassword(({ email, password }))
      .pipe(
        tap(({ result }) => this.updateActiveUser({ hasEmail: result })),
        map(({ result }) => result),
      );
  }

  consumerLoginWithVerificationCode(verificationData: ConsumerVerificationLoginData): Observable<UserInfo> {
    return this.apiService.validateUser(verificationData)
      .pipe(
        tap(user => { this.addUser(user, true); }),
        map(user => ({ id: user.id, firstName: user.firstName, hasEmail: user.hasEmail, rewards: user.rewards, photo: user.photo })),
      );
  }

  logout(id?: string): Observable<void> {
    if (id === undefined) {
      this.users = {};
      this.activeUserId = undefined;
      this.publishActiveUserChange();
      this.deleteUserData();
    } else {
      delete this.users[id];
      if (id === this.activeUserId) {
        this.activeUserId = undefined;
        this.publishActiveUserChange();
      }
      this.storeUserData();
    }
    return of(null);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<AuthChangePasswordResponseDto> {
    return this.apiService.authChangePassword({ oldPassword, newPassword });
  }

  changePasswordWithResetCode(resetCode: string, newPassword: string): Observable<AuthChangePasswordResponseDto> {
    return this.apiService.authChangePasswordWithCode({ resetCode, newPassword });
  }

  requestPasswordReset(email: string, resetBaseUrl: string): Observable<AuthResetPasswordResponseDto> {
    return this.apiService.authResetPassword({ email, resetBaseUrl, public: true });
  }

  isActiveUserAdmin(): boolean {
    return this.isActiveUserRole('Admin');
  }

  isActiveUserRole(role: string): boolean {
    const user = this.getActiveUser();
    return user && user.globalRoles && user.globalRoles.indexOf(role) > -1;
  }

  setActiveUserPhoto(photo: string): void {
    this.updateActiveUser({ photo });
  }

  incrementNagCount(): number {
    const user = this.getActiveUser();

    if (!user) {
      return 1;
    }

    const nagCount = (user.nagCount || 0) + 1;

    this.updateActiveUser({ nagCount });
    return nagCount;
  }

  setLastUploadFundsAmount(lastUploadFundsAmount: string): void {
    const user = this.getActiveUser();

    if (!user) {
      return;
    }

    this.updateActiveUser({ lastUploadFundsAmount });
  }

  private isRefreshLocked(): Promise<boolean> {
    return this.storageService.get(StorageKeys.USER_REFRESH_LOCK)
      .then(({ lockedAt }) => !!(lockedAt && lockedAt + JWT_REFRESH_LOCK_EXPIRATION_MS > Date.now()));
  }

  private lockRefresh(): Promise<void> {
    return this.storageService.set<RefreshLockStorage>(StorageKeys.USER_REFRESH_LOCK, { lockedAt: Date.now() });
  }

  private unlockRefresh(): Promise<void> {
    return this.storageService.remove(StorageKeys.USER_REFRESH_LOCK);
  }

  private addUser(user: User, clear: boolean = false): void {
    if (clear) {
      this.users = {};
    }

    const jwtData = this.jwtDecode(user.token);
    user.globalRoles = (jwtData && jwtData.globalRoles) || [];

    this.users[user.id] = user;
    this.storeUserData();
  }

  private updateActiveUser(user: Partial<User>): void {
    const activeUser = this.users[this.activeUserId];
    this.users[this.activeUserId] = { ...activeUser, ...user };

    if (user.token) {
      const jwtData = this.jwtDecode(user.token);
      user.globalRoles = (jwtData && jwtData.globalRoles) || [];
    }

    this.storeUserData();
    this.publishActiveUserChange();
  }

  private publishActiveUserChange(): void {
    const activeUser = this.getActiveUser();
    this.activeUserSubject.next(activeUser);
    this.setTelemetryUserTag();
  }

  private setTelemetryUserTag(): void {
    const activeUser = this.getActiveUser();
    if (activeUser && activeUser.id) {
      this.telemetryService.setActiveUser(activeUser.firstName, activeUser.id);
    }
  }

  private async reloadActiveUserData(): Promise<void> {
    if (!this.activeUserId) {
      return;
    }

    const { users = {} } = await this.storageService.get<UserDataStorage>(StorageKeys.USER_DATA);
    this.users[this.activeUserId] = users[this.activeUserId];
  }

  private async getUserData(): Promise<void> {
    const data = await this.storageService.get<UserDataStorage>(StorageKeys.USER_DATA);
    this.activeUserId = data.activeUserId;
    this.users = data.users || {};
    this.publishActiveUserChange();
    this.loadedPromiseResolver();
    this.setTelemetryUserTag();
  }

  private async storeUserData(): Promise<void> {
    const data: UserDataStorage = {
      activeUserId: this.activeUserId,
      users: this.users,
    };
    return this.storageService.set(StorageKeys.USER_DATA, data);
  }

  private deleteUserData(): Promise<void> {
    return this.storageService.remove(StorageKeys.USER_DATA);
  }

}
