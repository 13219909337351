import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
var APP_UPDATE_URL = 'https://app-install.joe.coffee/merchant/joe-coffee-merchant-app.apk';
var APP_UPDATE_VERSION_INFO_URL = 'https://app-install.joe.coffee/merchant/joe-coffee-merchant-app-version.json';
var NativeAppUpdateService = (function () {
    function NativeAppUpdateService(http) {
        this.http = http;
        this.windowLocation = window.location;
    }
    NativeAppUpdateService.prototype.fetchUpdate = function () {
        this.windowLocation.href = APP_UPDATE_URL;
    };
    NativeAppUpdateService.prototype.getUpdateVersionCode = function () {
        return this.http.get(APP_UPDATE_VERSION_INFO_URL).pipe(map(function (result) { return result && result.code; })).toPromise();
    };
    return NativeAppUpdateService;
}());
export { NativeAppUpdateService };
