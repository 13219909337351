import { ApiService } from 'src/gen/joeServerCore';
var CheckoutService = (function () {
    function CheckoutService(apiService) {
        this.apiService = apiService;
    }
    CheckoutService.prototype.checkout = function (orderData) {
        return this.apiService.orderCreate(orderData);
    };
    CheckoutService.prototype.uploadJoeBucks = function (joebucksData) {
        return this.apiService.joebucksUploadFunds(joebucksData);
    };
    return CheckoutService;
}());
export { CheckoutService };
