import { MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatButtonToggleModule, MatCheckboxModule, MatRadioModule, MatCardModule, MatProgressBarModule, } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatProgressBarModule,
];
var UserVerificationModule = (function () {
    function UserVerificationModule() {
    }
    return UserVerificationModule;
}());
export { UserVerificationModule };
