import { Injectable } from '@angular/core';
import { Big } from 'big.js';
import { Stripe, StripeCardTokenParams, StripeBankAccountParams } from '@ionic-native/stripe';
import { EnvironmentService, EnvironmentPlatforms } from 'src/app/shared/services/environment/environment.service';

export interface StoredCardData {
  token: string;
  last4: string;
  type: CardTypes;
  fake?: boolean;
}

export type CardDetails = StripeCardTokenParams & { fake?: boolean };

// TODO: validate these are correct
export type CardTypes = 'visa' | 'master card' | 'amex' | 'discover';

export type BankAccountDetails = StripeBankAccountParams;

export interface InitOptions {
  iosMerchantId: string;
  publishableKey: string;
  publishableTestKey: string;
}

export const PaymentErrors = {
  NOT_INITIALIZED: 'NOT_INITIALIZED',
};

export interface MobilePaymentData {
  /** human readable label to show to the customer */
  label: string;
  /** amount in cents */
  amount: string;
}

interface StripeApplePayPaymentResult {
  stripeToken: string;
  paymentData: string;
  transactionIdentifier: string;
}
interface StripeApplePayOrderData {
  items: { label: string, amount: number }[];
  merchantIdentifier: string;
  currencyCode: string;
  countryCode: string;
  billingAddressRequirement: string;
  shippingAddressRequirement: string;
  shippingType: string;
}
interface StripeApplePay {
  setMerchantId: (merchantId: string) => Promise<void>;
  setPublishableKey: (publishableKey: string) => Promise<void>;
  canMakePayments: () => Promise<string>;
  makePaymentRequest: (order: StripeApplePayOrderData) => Promise<StripeApplePayPaymentResult>;
  completeLastTransaction: (key: string) => Promise<string>;
}

interface StripeGooglePay {
  initGooglePayClient: (publishableKey: string) => Promise<string>;
  isReadytoPay: () => Promise<string>;
  requestPayment: (totalPrice: number, currency: string) => Promise<string>;
}

export enum MobilePaymentProvider {
  apple,
  google,
}

@Injectable()
export class NativePaymentService {

  private didInit: boolean;
  private publishableKey: string;
  private publishableTestKey: string;
  private iosMerchantId: string;
  private stripeApplePay: StripeApplePay;
  private stripeGooglePay: StripeGooglePay;
  private devicePlatform: EnvironmentPlatforms;

  constructor(
    private stripe: Stripe,
    private environmentService: EnvironmentService,
  ) {
    const config = environmentService.getConfig();

    this.stripeApplePay = (<any>window)['ApplePay'];
    this.stripeGooglePay = (<any>window)['GooglePay'];

    this.init({
      iosMerchantId: config.payment.iosMerchantId,
      publishableKey: config.payment.stripePublishable,
      publishableTestKey: config.payment.stripePublishableTest,
    });
  }

  async init({ publishableKey, publishableTestKey, iosMerchantId }: InitOptions): Promise<void> {
    await this.environmentService.onReady();

    if (!await this.environmentService.isNative()) {
      return;
    }

    this.devicePlatform = await this.environmentService.getPlatform();

    this.publishableKey = publishableKey;
    this.publishableTestKey = publishableTestKey;

    await this.stripe.setPublishableKey(publishableKey);

    // apple pay init if available
    if (this.devicePlatform === EnvironmentPlatforms.IOS && this.stripeApplePay) {
      await this.stripeApplePay.setPublishableKey(publishableKey);
      await this.stripeApplePay.setMerchantId(iosMerchantId);
      this.iosMerchantId = iosMerchantId;
    }

    // google pay init if available
    if (this.devicePlatform === EnvironmentPlatforms.ANDROID && this.stripeGooglePay) {
      await this.stripeGooglePay.initGooglePayClient(publishableKey);
    }

    this.didInit = true;
  }

  /////////////////////////////// MOBILE PAY OPERATIONS

  async supportsMobilePay(): Promise<MobilePaymentProvider> {
    await this.environmentService.onReady();

    switch (this.devicePlatform) {
      case EnvironmentPlatforms.IOS:
        if (!this.stripeApplePay) {
          return;
        }

        try {
          await this.stripeApplePay.canMakePayments();
          return MobilePaymentProvider.apple;
        } catch (e) { }
        return;
      case EnvironmentPlatforms.ANDROID:
        // TODO: enable google pay
        if (!this.stripeGooglePay) {
          return;
        }

        try {
          await this.stripeGooglePay.isReadytoPay();
          return MobilePaymentProvider.google;
        } catch (e) { }
        break;
    }

  }

  /**
   * Creates a mobile pay charge token via stripe for the current platform
   *
   * @returns stripe token on success (undefined on failure)
   */
  async createMobilePayment({ label, amount }: MobilePaymentData): Promise<string> {
    await this.environmentService.onReady();
    const supportedPlatform = await this.supportsMobilePay();

    const dollarAmount = parseFloat(Big(amount).div(100).toFixed(2).toString());
    switch (this.devicePlatform) {
      case EnvironmentPlatforms.IOS:
        if (supportedPlatform !== MobilePaymentProvider.apple) {
          return;
        }

        try {
          const applePayResult = await this.stripeApplePay.makePaymentRequest({
            items: [
              {
                label,
                amount: dollarAmount,
              },
            ],
            merchantIdentifier: this.iosMerchantId,
            currencyCode: 'USD',
            countryCode: 'US',
            billingAddressRequirement: 'none',
            shippingAddressRequirement: 'none',
            shippingType: 'store',
          });
          await this.stripeApplePay.completeLastTransaction('success');

          // success - return token
          return applePayResult.stripeToken;
        } catch (e) {
        }

        // failure - return undefined
        return;
      case EnvironmentPlatforms.ANDROID:
        if (supportedPlatform !== MobilePaymentProvider.google) {
          return;
        }

        try {
          const googlePayResult = await this.stripeGooglePay.requestPayment(dollarAmount, 'USD');
          return googlePayResult;
        } catch (e) { }

        // failure - return undefined
        return;
    }

  }

  /////////////////////////////// CARD OPERATIONS

  validateCard(card: Partial<CardDetails>): Promise<string> {
    if (!this.didInit) {
      return Promise.reject(PaymentErrors.NOT_INITIALIZED);
    }
    return this.stripe.validateCardNumber(card.number);
  }

  tokenizeCard(card: CardDetails): Promise<StoredCardData> {
    if (!this.didInit) {
      return Promise.reject(PaymentErrors.NOT_INITIALIZED);
    }

    return this.runAsTestMaybe(
      () => this.stripe.createCardToken(card),
      card.fake,
    ).then(result => ({
      token: result.id,
      last4: result.card.last4,
      type: result.card.brand as any,
      fake: card.fake,
    }));
  }

  getCardType(cardNumber: string): Promise<CardTypes> {
    if (!this.didInit) {
      return Promise.reject(PaymentErrors.NOT_INITIALIZED);
    }

    return this.stripe.getCardType(cardNumber) as Promise<CardTypes>;
  }

  private async runAsTestMaybe<T>(action: () => Promise<T>, testMode: boolean): Promise<T> {
    if (testMode) {
      await this.stripe.setPublishableKey(this.publishableTestKey);
    }

    let result: any;
    try {
      result = await action();
    } catch (e) {
      result = Promise.reject(e);
    }

    await this.stripe.setPublishableKey(this.publishableKey);
    return result;
  }

  /////////////////////////////// BANK ACCOUNT OPERATIONS

  // validateBank(bank: BankAccountDetails) {
  //   if (!this.didInit) {
  //     return Promise.reject(PaymentErrors.NOT_INITIALIZED);
  //   }

  // }

  // tokenizeBank(bank: BankAccountDetails) {
  //   if (!this.didInit) {
  //     return Promise.reject(PaymentErrors.NOT_INITIALIZED);
  //   }

  // }

}
