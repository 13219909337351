import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface ConfirmDialogOptions {
  title?: string;
  content: TemplateRef<any>;
  cancellable?: boolean;
}

@Component({
  selector: 'ui-confirm-dialog',
  templateUrl: './ui-confirm-dialog.component.html',
  styleUrls: ['./ui-confirm-dialog.component.scss'],
})
export class UiConfirmDialogComponent implements OnInit {

  content: TemplateRef<any>;
  title: string;
  cancellable = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public confirmData: ConfirmDialogOptions,
  ) {
    this.content = confirmData.content;
    this.title = confirmData.title;

    if (confirmData.hasOwnProperty('cancellable')) {
      this.cancellable = confirmData.cancellable;
    }
  }

  public ngOnInit(): void {
  }

}
