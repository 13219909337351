import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { CanActivate } from '@angular/router';

import { IdentityService } from '../../services/identity/identity.service';
import { PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';

/**
 * This guard logs out the user if they're logged in when applied to a route
 */

@Injectable()
export class LogoutGuardService implements CanActivate {

  constructor(
    private identityService: IdentityService,
    private posDevice: PosDeviceService,
  ) { }

  public canActivate(): Observable<boolean> {
    this.identityService.logout();
    this.posDevice.unpair();

    return of(true);
  }

}
