var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { IdentityService } from '../../services/identity/identity.service';
import { EnvironmentService } from '../../services/environment/environment.service';
import { PasswordQualityValidators } from './password-quality-validators';
import { matchesValidator } from '../../form-validators/matches.validator';
import { MatDialog } from '@angular/material';
import { UserVerificationDialogComponent } from '../../packages/user-verification/components/user-verification-dialog/user-verification-dialog.component';
import { takeWhile } from 'rxjs/operators';
import { ScrollServiceService } from '../../services/scroll-service/scroll-service.service';
var RESET_PASSWORD_BASE_URL = location.protocol + "//" + location.host + "/reset-password";
var LoginComponent = (function () {
    function LoginComponent(identityService, router, route, formBuilder, environmentService, dialog, scrollService) {
        this.identityService = identityService;
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this.environmentService = environmentService;
        this.dialog = dialog;
        this.scrollService = scrollService;
        this.flipped = false;
        this.done = false;
        this.pageBaseHref = location.protocol + "//" + location.host;
    }
    LoginComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, url, params, queryParams, urlPath, _b, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.componentActive = true;
                        setTimeout(function () { return _this.flipped = true; }, 1000);
                        this.showSignup = true;
                        _a = this.route.snapshot, url = _a.url, params = _a.params, queryParams = _a.queryParams;
                        urlPath = url && url[0].path.toLowerCase();
                        this.returnUrl = queryParams['returnUrl'];
                        this.changePasswordMode = urlPath === 'reset-password' || urlPath === 'change-password';
                        this.resetCode = params['resetCode'] || '';
                        if (this.changePasswordMode && this.resetCode && this.resetCode.length < 20) {
                            this.errorMessage = 'Invalid reset password code';
                            this.changePasswordMode = false;
                        }
                        _b = this;
                        return [4, this.environmentService.isWeb()];
                    case 1:
                        _b.isWeb = _d.sent();
                        _c = this;
                        return [4, this.environmentService.getMode()];
                    case 2:
                        _c.isMerchant = (_d.sent()) === 1;
                        this.initForms();
                        if (urlPath === 'signup' && this.showSignup) {
                            this.selectedTab = 1;
                        }
                        this.iframeSignupMode = urlPath === 'signup-iframe';
                        if (this.iframeSignupMode) {
                            document.body.style.overflow = 'hidden';
                            this.scrollService.disable();
                        }
                        return [2];
                }
            });
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    LoginComponent.prototype.signup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var phoneField, phoneValue, phoneValueClean, signupData;
            var _this = this;
            return __generator(this, function (_a) {
                phoneField = this.signupForm.get('phone');
                phoneValue = phoneField.value;
                phoneValueClean = phoneValue.replace(/[^0-9]/g, '');
                if (phoneValue !== phoneValueClean) {
                    phoneField.setValue(phoneValueClean);
                }
                if (!this.signupForm.valid) {
                    return [2];
                }
                this.validatePhone = phoneValueClean;
                this.validateTryCount = 0;
                this.errorMessage = undefined;
                this.flipped = false;
                signupData = this.signupForm.getRawValue();
                if (this.validateCode) {
                    signupData.validationCode = this.validateCode;
                }
                this.identityService.signup(signupData)
                    .subscribe(function (userId) {
                    if (_this.iframeSignupMode) {
                        _this.identityService.setActiveUser(userId);
                        _this.iframeSignupComplete = true;
                        window.open(_this.pageBaseHref + '/m/company/create', '_top');
                        return;
                    }
                    _this.done = true;
                    setTimeout(function () { return _this.loginAndRedirect(userId); }, 1000);
                }, this.httpErrorHandler.bind(this));
                return [2];
            });
        });
    };
    LoginComponent.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            var email, password;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.loginForm.valid) {
                    return [2];
                }
                this.errorMessage = undefined;
                this.flipped = false;
                email = this.loginForm.get('email').value;
                password = this.loginForm.get('password').value;
                this.identityService.login({ email: email, password: password })
                    .subscribe(function (userId) {
                    _this.done = true;
                    setTimeout(function () { return _this.loginAndRedirect(userId); }, 1000);
                }, this.httpErrorHandler.bind(this));
                return [2];
            });
        });
    };
    LoginComponent.prototype.changePassword = function () {
        var _this = this;
        if (this.changePasswordForm.invalid) {
            return;
        }
        this.flipped = false;
        var _a = this.changePasswordForm.getRawValue(), oldPassword = _a.oldPassword, password = _a.password;
        (this.resetCode ?
            this.identityService.changePasswordWithResetCode(this.resetCode, password) :
            this.identityService.changePassword(oldPassword, password)).subscribe(function (_a) {
            var result = _a.result;
            if (result === true) {
                _this.router.navigateByUrl('/');
                _this.errorMessage = '';
            }
            _this.flipped = true;
        }, this.httpErrorHandler.bind(this));
    };
    LoginComponent.prototype.resetPassword = function () {
        var _this = this;
        var email = this.loginForm.get('email');
        if (email.invalid) {
            email.markAsDirty();
            email.markAsTouched();
            return;
        }
        this.flipped = false;
        this.toggleForgotPassword();
        this.identityService.requestPasswordReset(email.value, RESET_PASSWORD_BASE_URL).subscribe(function (_a) {
            var result = _a.result;
            if (result === true) {
                _this.errorMessage = 'Check your e-mail. Click the link. Get back in.';
            }
            else {
                _this.errorMessage = 'Unable to reset your password right now.';
            }
            _this.flipped = true;
        }, this.httpErrorHandler.bind(this));
    };
    LoginComponent.prototype.toggleForgotPassword = function () {
        this.forgotPasswordMode = !this.forgotPasswordMode;
        this.forgotPasswordMode ? this.loginForm.get('password').disable() : this.loginForm.get('password').enable();
    };
    LoginComponent.prototype.loginAndRedirect = function (userId) {
        this.identityService.setActiveUser(userId);
        this.router.navigateByUrl(this.returnUrl || (this.isMerchant ? '/m' : '/stores'));
    };
    LoginComponent.prototype.sendValidationCode = function () {
        this.identityService.consumerSignupWithPhone(this.signupForm.getRawValue()).toPromise();
    };
    LoginComponent.prototype.showPhoneValidationDialog = function () {
        var _this = this;
        this.sendValidationCode();
        var dialogOpen = true;
        var dialogRef = this.dialog.open(UserVerificationDialogComponent, {
            width: '500px',
            height: '200px',
            data: { phoneNumber: this.validatePhone, tryCount: this.validateTryCount },
        });
        dialogRef.componentInstance.resendCode.pipe(takeWhile(function () { return dialogOpen; })).subscribe(function () {
            _this.sendValidationCode();
        });
        dialogRef.afterClosed().pipe(takeWhile(function () { return dialogOpen; })).subscribe(function (code) {
            dialogOpen = false;
            if (code) {
                _this.validateCode = code;
                _this.signup();
            }
            else {
                _this.flipped = true;
            }
        });
    };
    LoginComponent.prototype.initForms = function () {
        var _this = this;
        var trimControlValueIfNeeded = function (val, control) {
            if (!val) {
                return;
            }
            var trimmedVal = val.trim();
            if (val !== trimmedVal) {
                control.patchValue(trimmedVal);
            }
        };
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
        var loginEmailControl = this.loginForm.get('email');
        loginEmailControl.valueChanges
            .pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (val) { return trimControlValueIfNeeded(val, loginEmailControl); });
        this.signupForm = this.formBuilder.group({
            lastName: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            password: ['', [
                    Validators.required,
                    Validators.minLength(8)
                ].concat(PasswordQualityValidators.all)],
            passwordConfirmation: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    matchesValidator(function () { return _this.signupForm && _this.signupForm.get('password').value; }),
                ],
            ],
        });
        var signupEmailControl = this.signupForm.get('email');
        this.signupForm.get('email').valueChanges
            .pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (val) { return trimControlValueIfNeeded(val, signupEmailControl); });
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', this.resetCode ? undefined : [Validators.required]],
            resetCode: this.resetCode,
            password: ['', [
                    Validators.required,
                    Validators.minLength(8)
                ].concat(PasswordQualityValidators.all)],
            passwordConfirmation: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    matchesValidator(function () { return _this.changePasswordForm && _this.changePasswordForm.get('password').value; }),
                ],
            ],
        });
    };
    LoginComponent.prototype.httpErrorHandler = function (result) {
        this.validateCode = undefined;
        this.validatePhone = undefined;
        switch (result.status) {
            case 0:
                this.errorMessage = 'Unable to reach Joe server. Please check your internet connection.';
                this.flipped = true;
                return;
            case 404:
                this.errorMessage = 'Incorrect validation code. Please try again.';
                this.flipped = true;
                return;
            case 409:
                this.errorMessage = '';
                this.showPhoneValidationDialog();
                return;
            default:
                console.log('unknown login error', result);
                var error = result.error;
                this.errorMessage = error && error.message ? error.message : 'Unknown error.';
                this.flipped = true;
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
