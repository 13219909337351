import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'menu-item-size-chip',
  templateUrl: './menu-item-size-chip.component.html',
  styleUrls: ['./menu-item-size-chip.component.scss'],
})
export class MenuItemSizeChipComponent implements OnInit {

  @Input()
  size: FormGroup;

  constructor() { }

  public ngOnInit(): void {
  }

  inputClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
