import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDifference',
})
export class DateDifferencePipe implements PipeTransform {

  constructor() { }

  transform(value1: string, value2?: string): number {
    const date1 = new Date(value1);
    const date2 = new Date(value2);
    return date1.getTime() - date2.getTime();
  }

}
