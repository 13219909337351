<div [class.drawer-open]="drawerOpen" class="e2e-pos-device-badge pos-device-badge" fxLayout="column"
  fxLayoutAlign="center top">
  <div fxLayout="row" fxLayoutAlign="end end">
    <div fxFlex="50px" class="orders-badge" *ngIf="showPosBadge" fxLayoutAlign="center center"
      [class.has-badge]="newOrders > 0" [class.alerting]="alerting">
      <a *ngIf="status==='error'" class="error-badge" routerLink="/pos/dashboard">
        <mat-icon>warning</mat-icon>
      </a>
      <button mat-icon-button color="light" class="pos-link" [matMenuTriggerFor]="posBadgeMenu">
        <mat-icon aria-label="Orders" [matBadge]="newOrders" [matBadgeHidden]="newOrders < 1"
          matBadgePosition="before above" matBadgeColor="accent" matBadgeSize="medium">menu</mat-icon>
      </button>

      <mat-menu #posBadgeMenu="matMenu">
        <ng-template matMenuContent>
          <button type="button" mat-menu-item routerLink="/pos/dashboard">
            <mat-icon>dvr</mat-icon>Orders
          </button>
          <button type="button" mat-menu-item routerLink="/pos/dashboard/report">
            <mat-icon>table_chart</mat-icon>Reports
          </button>
          <button type="button" mat-menu-item routerLink="/pos/settings">
            <mat-icon>settings</mat-icon>Settings
          </button>
          <mat-divider></mat-divider>
          <button type="button" mat-menu-item routerLink="/m/store/{{storeId}}">
            <mat-icon>dashboard</mat-icon>Store Dashboard
          </button>
        </ng-template>
      </mat-menu>
    </div>
    <div fxFlex>
      <div (click)="toggleStoreDrawer()" class="store" fxFlex fxLayout="row" fxLayoutAlign="center center">
        {{pairedDevice?.storeName}}
      </div>
      <div (click)="toggleDeviceDrawer()" class="device-status" fxFlex fxLayout="row" fxLayoutAlign="center center">
        <span class="status-indicator" [ngClass]="status"></span>
        <span>{{status}}</span>
        <span class="status-time-wrap">{{currentTime | datePatched : 'shortTime'}}</span>
      </div>
    </div>
  </div>
  <div class="drawer-wrap">
    <div class="drawer">
      <div class="store-drawer" fxLayout="row" *ngIf="storeOpen">
        <div fxFlex class="store-kpi" fxLayout="column" fxLayoutAlign="center center">
          <div class="label">Orders</div>
          <div class="value">{{ kpiOrderCount === undefined ? '--' : kpiOrderCount }}</div>
        </div>
        <div fxFlex class="store-kpi" fxLayout="column" fxLayoutAlign="center center">
          <div class="label">Sales</div>
          <div class="value">{{ kpiSalesTotal === undefined ? '--' : (kpiSalesTotal | centCurrency) }}</div>
        </div>
        <div fxFlex class="store-kpi" fxLayout="column" fxLayoutAlign="center center">
          <div class="label">Tips</div>
          <div class="value">{{ kipTipsTotal === undefined ? '--' : (kipTipsTotal | centCurrency) }}</div>
        </div>
      </div>
      <div class="device-drawer" *ngIf="deviceOpen" fxLayout="column" fxLayoutAlign="center center">
        <div class="message" *ngIf="status === 'online'">
          Good News! This device is connected to Joe and accepting orders!
        </div>
        <div class="message" *ngIf="status === 'error'">
          {{errorMessage}}
        </div>
        <div class="message" *ngIf="status === 'offline'">
          This device is not currently accepting orders.
        </div>
        <button mat-flat-button color="primary" size="small" (click)="setOnline(false)" *ngIf="status === 'online'">
          Go Offline
          <mat-icon>mobile_off</mat-icon>
        </button>
        <button mat-flat-button color="primary" size="small" (click)="setOnline(true)" *ngIf="status === 'offline'">
          Go Online
          <mat-icon>mobile_friendly</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>