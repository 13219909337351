import { ApiService } from 'src/gen/joeServerCore';
import { map } from 'rxjs/operators';
var MenuOptionService = (function () {
    function MenuOptionService(apiService) {
        this.apiService = apiService;
    }
    MenuOptionService.prototype.getCompanyMenuOptions = function (companyId, menuId) {
        return this.apiService.menuOptionList({ companyId: companyId, menuId: menuId });
    };
    MenuOptionService.prototype.get = function (companyId, menuId, menuOptionId) {
        return this.apiService.menuOptionGet({ companyId: companyId, menuId: menuId, menuOptionId: menuOptionId });
    };
    MenuOptionService.prototype.update = function (companyId, menuId, menuOptionId, menuOption) {
        return this.apiService.menuOptionUpdate({ companyId: companyId, menuId: menuId, menuOptionId: menuOptionId, MenuOptionCreateDto: menuOption });
    };
    MenuOptionService.prototype.create = function (companyId, menuId, menuOption) {
        return this.apiService.menuOptionCreate({ companyId: companyId, menuId: menuId, MenuOptionCreateDto: menuOption });
    };
    MenuOptionService.prototype.delete = function (companyId, menuId, menuOptionId) {
        return this.apiService.menuOptionDelete({ companyId: companyId, menuId: menuId, menuOptionId: menuOptionId }).pipe(map(function (_a) {
            var result = _a.result;
            return result;
        }));
    };
    MenuOptionService.prototype.new = function () {
        return {
            id: undefined,
            name: '',
            finiteQuantity: true,
            minimumQuantity: 1,
            maximumQuantity: 1,
            quantityUnit: '',
            quantityIncrement: 1,
            quantityNames: [],
            choices: [],
            defaultQuantity: 1,
        };
    };
    return MenuOptionService;
}());
export { MenuOptionService };
