import { ApiService } from 'src/gen/joeServerCore';
import { of } from 'rxjs';
import { IdentityService } from '../identity/identity.service';
var UserRewardService = (function () {
    function UserRewardService(apiService, identityService) {
        this.apiService = apiService;
        this.identityService = identityService;
    }
    UserRewardService.prototype.getRewardStatus = function (storeId) {
        if (!this.identityService.getActiveUser()) {
            return of(undefined);
        }
        return this.apiService.userGetRewardStatus(storeId);
    };
    return UserRewardService;
}());
export { UserRewardService };
