<div>
  <div class="error-message" *ngIf="tryCount > 0">Invalid code please try again</div>
  Sending a verification code to <b>{{ phoneNumberNormalized || 'you' }}</b> now!
  <div class="verification-input-wrap">
    <input class="verification-input" #verificationInput type="tel" pattern="[0-9]*" novalidate (input)="verificationInputKey($event)">
    <div fxLayout="row" fxLayoutAlign="space-between" (click)="userClick()">
      <div class="fake-form-field" *ngFor="let digit of digits; let i = index" fxFlex="15" [class.active]="activeInput === i">
        <span class="fake-cursor"></span>
        {{digit}}
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutGap="10px" *ngIf="sendCodeProgress === undefined">
  <button form="ngForm" fxFlex mat-flat-button color="primary" (click)="cancel()">
    <mat-icon>arrow_back_ios</mat-icon>
    Back
  </button>
  <button fxFlex mat-flat-button color="primary" (click)="onResendCode()">
    <mat-icon>refresh</mat-icon>
    Resend
  </button>
</div>
<div class="progress-wrap" *ngIf="sendCodeProgress !== undefined">
  <div>Sending...</div>
  <mat-progress-bar [value]="sendCodeProgress"></mat-progress-bar>
</div>