import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

import { EnvironmentService, EnvironmentModes } from '../../services/environment/environment.service';

@Injectable()
export class MerchantGuardService implements CanActivate {

  constructor(
    private environment: EnvironmentService,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return fromPromise(this.environment.getMode()).pipe(map(mode => {

      const isConsumer = mode === EnvironmentModes.MERCHANT;
      if (!isConsumer) {
        this.router.navigateByUrl('/');
      }
      return isConsumer;
    }));
  }
}
