import { TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
var BottombarService = (function () {
    function BottombarService() {
        this.templateSub = new Subject();
        this.templateObs = this.templateSub.asObservable();
    }
    BottombarService.prototype.setTemplate = function (template) {
        this.templateSub.next(template);
    };
    BottombarService.prototype.watchTemplate = function () {
        return this.templateObs;
    };
    return BottombarService;
}());
export { BottombarService };
