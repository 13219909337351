<ng-container *ngIf="!hideTopAndSideBar; else renderWithoutNavBar">
  <merchant-topbar [show]="showTopbar" [drawerOpen]="opened" [mobileMode]="mobileMode" (onLogout)="logout()">
  </merchant-topbar>

  <div *ngIf="mobileMode && showTopbar" class="mobile-menu-open-button" (click)="opened = !opened">
    <mat-icon>menu</mat-icon>
  </div>

  <mat-sidenav-container class="full-height" [class.topbar-hidden]="!showTopbar">

    <mat-sidenav hasBackdrop="false" mode="side" [class.opened]="opened" [class.hide]="!showTopbar" [opened]="opened"
      #sidenav>
      <div class="sidebar-wrap" [class.sidebar-topbar-offset]="showTopbar">
        <merchant-sidebar [isDrawerOpen]="opened" (onToggleMenu)="opened = !opened"></merchant-sidebar>
      </div>
    </mat-sidenav>

    <mat-sidenav-content [class.opened]="opened" class="full-height content-wrap">
      <div class="router-outlet-wrap" [class.content-topbar-offset]="showTopbar">
        <div class="outlet-inner-wrap">
          <router-outlet></router-outlet>
        </div>
        <div class="environment-data">{{displayVersionInfo}}</div>

      </div>
      <merchant-bottombar></merchant-bottombar>
    </mat-sidenav-content>

  </mat-sidenav-container>
</ng-container>

<ng-template #renderWithoutNavBar>
  <div class="full-height whiteBg overflow-y-scroll" [class.topbar-hidden]="!showTopbar">
    <div [class.opened]="opened" class="full-height content-wrap">
      <div class="router-outlet-wrap" [class.content-topbar-offset]="showTopbar">
        <div class="outlet-inner-wrap">
          <router-outlet></router-outlet>
        </div>
        <div class="environment-data">{{displayVersionInfo}}</div>

      </div>
      <merchant-bottombar></merchant-bottombar>
    </div>
  </div>
</ng-template>