import { ModuleWithProviders } from '@angular/core';
import { ConsumerSidebarComponent } from './components/consumer-sidebar/consumer-sidebar.component';
import { UiConsumerChromeComponent } from './components/ui-consumer-chrome/ui-consumer-chrome.component';
import { MatSidenavModule, MatListModule, MatIconModule, MatDividerModule, MatDialogModule, MatButtonModule, MatBottomSheetModule, MatSnackBarModule, MatProgressSpinnerModule, MatCardModule, MatBadgeModule, MatProgressBarModule } from '@angular/material';
import { TopbarService } from './services/topbar/topbar.service';
import { SidebarRewardProgressComponent } from './components/consumer-sidebar/components/sidebar-reward-progress/sidebar-reward-progress.component';
import { SidebarLastOrderCardComponent } from './components/consumer-sidebar/components/sidebar-last-order-card/sidebar-last-order-card.component';
import { SidebarLinksComponent } from './components/consumer-sidebar/components/sidebar-links/sidebar-links.component';
var EXPORTED_COMPONENTS = [
    ConsumerSidebarComponent,
    SidebarRewardProgressComponent,
    SidebarLastOrderCardComponent,
    SidebarLinksComponent,
    UiConsumerChromeComponent,
];
export var MATERIAL_IMPORTS = [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
    MatBadgeModule,
];
var UiConsumerChromeModule = (function () {
    function UiConsumerChromeModule() {
    }
    UiConsumerChromeModule.forRoot = function () {
        return {
            ngModule: UiConsumerChromeModule,
            providers: [TopbarService],
        };
    };
    return UiConsumerChromeModule;
}());
export { UiConsumerChromeModule };
