import * as i0 from "./user-verification-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-verification/user-verification.component.ngfactory";
import * as i3 from "../user-verification/user-verification.component";
import * as i4 from "./user-verification-dialog.component";
import * as i5 from "@angular/material/dialog";
var styles_UserVerificationDialogComponent = [i0.styles];
var RenderType_UserVerificationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserVerificationDialogComponent, data: {} });
export { RenderType_UserVerificationDialogComponent as RenderType_UserVerificationDialogComponent };
export function View_UserVerificationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "user-verification", [], null, [[null, "done"], [null, "resendCode"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("done" === en)) {
        var pd_0 = (_co.onDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("resendCode" === en)) {
        var pd_1 = (_co.onResendCode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_UserVerificationComponent_0, i2.RenderType_UserVerificationComponent)), i1.ɵdid(2, 4308992, null, 0, i3.UserVerificationComponent, [i1.ChangeDetectorRef], { phoneNumber: [0, "phoneNumber"], tryCount: [1, "tryCount"] }, { done: "done", resendCode: "resendCode" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.phoneNumber; var currVal_1 = _co.tryCount; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_UserVerificationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-verification-dialog", [], null, null, null, View_UserVerificationDialogComponent_0, RenderType_UserVerificationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserVerificationDialogComponent, [i5.MAT_DIALOG_DATA, i5.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserVerificationDialogComponentNgFactory = i1.ɵccf("user-verification-dialog", i4.UserVerificationDialogComponent, View_UserVerificationDialogComponent_Host_0, {}, {}, []);
export { UserVerificationDialogComponentNgFactory as UserVerificationDialogComponentNgFactory };
