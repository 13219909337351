import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import { Chcp, ChcpVersionInfo } from '../../packages/3rd-party-support/interfaces/cordova-hot-code-push';

declare var chcp: Chcp;

export const enum HotUpdateStatus {
  UNKNOWN,
  UP_TO_DATE,
  READY_TO_INSTALL,
  FAILURE_DISK,
  BUILD_VERSION_TOO_LOW,
}

@Injectable()
export class UpdateService {

  constructor(
    private environmentService: EnvironmentService,
  ) { }

  fetchHotUpdate(): Promise<HotUpdateStatus> {

    return new Promise((resolve, reject) => {
      if (!chcp) { return reject('chcp not loaded'); }

      const environmentConfig = this.environmentService.getConfig();
      const options = {
        'config-file': environmentConfig.hotUpdateUrl + '/chcp.json',
      };

      chcp.fetchUpdate((error, data) => {
        if (error) {
          switch (error.code) {
            case chcp.error.APPLICATION_BUILD_VERSION_TOO_LOW:
              return resolve(HotUpdateStatus.BUILD_VERSION_TOO_LOW);
            case chcp.error.NOTHING_TO_UPDATE:
            case chcp.error.NOTHING_TO_INSTALL:
              return resolve(HotUpdateStatus.UP_TO_DATE);
            case chcp.error.FAILED_TO_DOWNLOAD_APPLICATION_CONFIG:
            case chcp.error.FAILED_TO_DOWNLOAD_CONTENT_MANIFEST:
            case chcp.error.FAILED_TO_DOWNLOAD_UPDATE_FILES:
              // TODO: Log here, would be due to our HCP server issue.
              return resolve(HotUpdateStatus.UNKNOWN);
            case chcp.error.FAILED_TO_MOVE_LOADED_FILES_TO_INSTALLATION_FOLDER:
            case chcp.error.FAILED_TO_COPY_FILES_FROM_PREVIOUS_RELEASE:
            case chcp.error.FAILED_TO_COPY_NEW_CONTENT_FILES:
            case chcp.error.FAILED_TO_INSTALL_ASSETS_ON_EXTERNAL_STORAGE:
            case chcp.error.LOCAL_VERSION_OF_APPLICATION_CONFIG_NOT_FOUND:
            case chcp.error.LOCAL_VERSION_OF_MANIFEST_NOT_FOUND:
              return resolve(HotUpdateStatus.FAILURE_DISK);
            default:
              return reject(error.code);
          }
        } else {
          resolve(HotUpdateStatus.READY_TO_INSTALL);
        }
      }, options);
    });
  }

  installHotUpdate(): Promise<HotUpdateStatus> {
    return new Promise((resolve, reject) => {
      if (!chcp) { return reject('chcp not loaded'); }

      chcp.installUpdate((error, data) => {
        if (error) {
          switch (error.code) {
            case chcp.error.NOTHING_TO_INSTALL:
              resolve(HotUpdateStatus.UP_TO_DATE);
              break;
            default:
              reject(error);
          }
        } else {
          resolve(HotUpdateStatus.UP_TO_DATE);
        }
      });
    });
  }

  getVersionInfo(): Promise<ChcpVersionInfo> {
    return new Promise((resolve, reject) => {
      chcp.getVersionInfo((err, versionInfo) => {
        if (err) {
          reject(err);
        } else {
          resolve(versionInfo);
        }
      });
    });
  }

  requestApplicationUpdate(message: string): void {
    chcp.requestApplicationUpdate(
      message,
      (e, d) => {
        // TODO: user is taken to play/app store, Log here
      },
      (e, d) => {
        // TODO: user refuses link to play/app store,  Log here
      },
    );
  }
}
