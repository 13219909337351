<h2 mat-dialog-title class="error-title">Error - {{errorData.title}}</h2>
<mat-dialog-content class="error-content">
  <p class="error-message">
    {{errorData.message}}
  </p>

  <p *ngIf="errorData?.details?.status === 0" class="error-no-connection">
    Unable to contact Joe server. Please check your internet connection.
  </p>

  <div class="error-details">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Technical details
        </mat-panel-title>
      </mat-expansion-panel-header>

      <pre class="error-details-content">{{errorData.details}}</pre>

    </mat-expansion-panel>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="error-actions">
  <button mat-flat-button color="primary" *ngIf="!errorData.retryButtonAction" mat-dialog-close>
    <mat-icon>close</mat-icon> Close
  </button>
  <button mat-flat-button color="primary" *ngIf="errorData.retryButtonAction" (click)="errorData.retryButtonAction()">
    <mat-icon>refresh</mat-icon> Retry
  </button>
</mat-dialog-actions>