export interface OrderItemOptionChoiceDescriptor {
  id: string;
  flags: string[];
  hidden: boolean;
  name: string;
  index: number;
  quantity: number;
  unit: string;
  quantityNames?: string[];
  minQuantity: number;
  maxQuantity: number;
  increment: number;
  price: string;
  // priceEach?: boolean;
}

export interface SizePricesMap {
  [sizeId: string]: string;
}

export class OrderItemOptionChoice {
  constructor(private readonly data: OrderItemOptionChoiceDescriptor, private readonly sizePrices: SizePricesMap) {
  }

  get id(): string {
    return this.data.id;
  }

  get flags(): string[] {
    return this.data.flags;
  }

  get hidden(): boolean {
    return this.data.hidden;
  }

  get name(): string {
    return this.data.name;
  }

  get index(): number {
    return this.data.index;
  }

  get quantity(): number {
    return this.data.quantity;
  }

  set quantity(quantity: number) {
    this.data.quantity = quantity;
  }

  get unit(): string {
    return this.data.unit;
  }

  get quantityNames(): string[] {
    return this.data.quantityNames;
  }

  get minQuantity(): number {
    return this.data.minQuantity;
  }

  get maxQuantity(): number {
    return this.data.maxQuantity;
  }

  get increment(): number {
    return this.data.increment;
  }

  getPrice(sizeId: string): string {
    return this.sizePrices.hasOwnProperty(sizeId) ? this.sizePrices[sizeId] : this.data.price;
  }

  // get priceEach(): boolean {
  //   return this.data.priceEach;
  // }

  setQuantity(quantityIndex: number): number {
    if (this.quantityNames !== undefined && quantityIndex < this.quantityNames.length) {
      this.quantity = quantityIndex;
      return this.quantity;
    } else {
      return undefined;
    }
  }

  incrementChoice(): void {
    this.quantity += this.increment;
    this.clampChoiceQuantity();
  }

  decrementChoice(): void {
    this.quantity -= this.increment;
    this.clampChoiceQuantity();
  }

  private clampChoiceQuantity(): void {
    if (this.maxQuantity !== -1 && this.quantity > this.maxQuantity) {
      this.quantity = this.maxQuantity;
    }

    if (this.quantity < this.minQuantity) {
      this.quantity = this.minQuantity;
    }
  }
}
