import { ModuleWithProviders } from '@angular/core';
import { MerchantSidebarComponent } from './components/merchant-sidebar/merchant-sidebar.component';
import { UiMerchantChromeComponent } from './components/ui-merchant-chrome/ui-merchant-chrome.component';
import { MatSidenavModule, MatListModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatMenuModule } from '@angular/material';
import { MerchantTopbarOptionsComponent } from './components/merchant-topbar-options/merchant-topbar-options.component';
import { MerchantTopbarService } from './services/merchant-topbar/merchant-topbar.service';
import { MerchantBottombarTemplateComponent, } from './components/merchant-bottombar/merchant-bottombar-template/merchant-bottombar-template.component';
import { MerchantBottombarService } from './services/merchant-bottombar/merchant-bottombar.service';
import { ActiveCompanyService } from './services/active-company/active-company.service';
var EXPORTED_COMPONENTS = [
    MerchantSidebarComponent,
    MerchantBottombarTemplateComponent,
    MerchantTopbarOptionsComponent,
    UiMerchantChromeComponent,
];
export var MATERIAL_IMPORTS = [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
];
var UiMerchantChromeModule = (function () {
    function UiMerchantChromeModule() {
    }
    UiMerchantChromeModule.forRoot = function () {
        return {
            ngModule: UiMerchantChromeModule,
            providers: [MerchantTopbarService, MerchantBottombarService, ActiveCompanyService],
        };
    };
    return UiMerchantChromeModule;
}());
export { UiMerchantChromeModule };
