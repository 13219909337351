import { Injectable } from '@angular/core';
import { ApiService, UserRewardStatusDto } from 'src/gen/joeServerCore';
import { Observable, of } from 'rxjs';
import { IdentityService } from '../identity/identity.service';

@Injectable()
export class UserRewardService {

  constructor(
    private readonly apiService: ApiService,
    private readonly identityService: IdentityService,
  ) { }

  getRewardStatus(storeId?: string): Observable<UserRewardStatusDto> {
    if (!this.identityService.getActiveUser()) {
      return of(undefined);
    }
    return this.apiService.userGetRewardStatus(storeId);
  }

}
