<div class="sidebar-links-wrapper" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around stretch"
  fxLayoutGap="5%">
  <div fxLayout="row" fxLayout.gt-sm="column" fxLayoutAlign="space-between center">
    <div class="icon-link-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center" routerLink="/favorites"
      (click)="close.emit()">
      <div class="sidebar-link-icon faves-icon" fxLayout="column" fxLayoutAlign="center center">
      </div>
      <p class="sidebar-link-text">my faves</p>
    </div>
    <div class="icon-link-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center" (click)="showAddFunds.emit()">
      <div class="sidebar-link-icon" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-spinner *ngIf="!floorBalance" diameter="30" mode="indeterminate" class="balance-loading-spinner">
        </mat-progress-spinner>
        <p *ngIf="floorBalance" class="joebucks-balance">{{ floorBalance }}</p>
      </div>
      <p class="sidebar-link-text">balance</p>
    </div>
    <div class="icon-link-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center" routerLink="/stores"
      (click)="close.emit()">
      <div class="sidebar-link-icon" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>search</mat-icon>
      </div>
      <p class="sidebar-link-text">nearby stores</p>
    </div>
  </div>
  <div fxLayout="row" fxLayout.gt-sm="column" fxLayoutAlign="space-between center">
    <div class="icon-link-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center" (click)="share()">
      <div class="sidebar-link-icon" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>share</mat-icon>
      </div>
      <p class="sidebar-link-text">share</p>
    </div>
    <div class="icon-link-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center" routerLink="/feedback"
      (click)="close.emit()">
      <div class="sidebar-link-icon" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>volume_up</mat-icon>
      </div>
      <p class="sidebar-link-text">feedback</p>
    </div>
    <div class="icon-link-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center"
      (click)="showRewardInfo.emit()">
      <div class="sidebar-link-icon reward-icon" fxLayout="column" fxLayoutAlign="center center"
        [matBadge]="rewardsRedeemable" [matBadgeHidden]="rewardsRedeemable === 0" matBadgePosition="below after">
      </div>
      <p class="sidebar-link-text">rewards</p>
    </div>
  </div>
</div>