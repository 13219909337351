import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface TopbarOptions {
  title: string;
  companyId: string;
}

@Injectable()
export class MerchantTopbarService {

  private optionChangeSubject: Subject<TopbarOptions> = new Subject();

  private topbarOptions: TopbarOptions = {
    title: '',
    companyId: undefined,
  };

  constructor() { }

  setTitle(title: string): void {
    this.topbarOptions.title = title;
    this.emitOptionChanges();
  }

  setOptions(options: Partial<TopbarOptions>): void {
    Object.assign(this.topbarOptions, options);
    this.emitOptionChanges();
  }

  watchOptions(): Observable<TopbarOptions> {
    return this.optionChangeSubject.asObservable();
  }

  private emitOptionChanges(): void {
    this.optionChangeSubject.next(this.topbarOptions);
  }
}
