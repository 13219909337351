import { MatButtonModule, MatIconModule } from '@angular/material';
import { SocialShareBottomSheetComponent } from './components/social-share-bottom-sheet/social-share-bottom-sheet.component';
var entryComponents = [SocialShareBottomSheetComponent];
export var MATERIAL_IMPORTS = [
    MatButtonModule,
    MatIconModule,
];
var SocialShareModule = (function () {
    function SocialShareModule() {
    }
    return SocialShareModule;
}());
export { SocialShareModule };
