import { OnInit, TemplateRef } from '@angular/core';
var UiConfirmDialogComponent = (function () {
    function UiConfirmDialogComponent(confirmData) {
        this.confirmData = confirmData;
        this.cancellable = true;
        this.content = confirmData.content;
        this.title = confirmData.title;
        if (confirmData.hasOwnProperty('cancellable')) {
            this.cancellable = confirmData.cancellable;
        }
    }
    UiConfirmDialogComponent.prototype.ngOnInit = function () {
    };
    return UiConfirmDialogComponent;
}());
export { UiConfirmDialogComponent };
