import { Injectable } from '@angular/core';
import { ApiService, OrderDto, OrderPricingDto, OrderViewDto, OrderViewConsumerDto, OrderItemChoiceViewDto, OrderItemViewDto } from 'src/gen/joeServerCore';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { NativePushService } from 'src/app/shared/packages/native-support/services/native-push/native-push.service';
import { map, filter, switchMap } from 'rxjs/operators';
import { OrderStatusData, RewardProgressData } from 'src/app/shared/packages/native-support/services/native-push/push.interface';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { StoreMenuService } from '../store-menu/store-menu.service';
import { OrderItem } from '../../models/order-item/order-item.class';
import { MergeMenu } from 'src/app/shared/models/merge-menu/merge-menu';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { fromPromise } from 'rxjs/internal-compatibility';

export interface OrderReview {
  message: string;
  rating: number;
  feedbackOrderCorrect: number;
  feedbackOrderReady: number;
}

const PREVIOUS_STORE_CACHE_DURATION_MS = 10 * 24 * 60 * 60 * 1000;

interface PreviousStoreStorage {
  storeUrl: string;
  orderTime: number;
}

export type PaymentSourceType = 'card_new' | 'card_existing' | 'mobile_pay' | 'joebucks';

@Injectable()
export class OrderService {

  private static pendingRewardData: BehaviorSubject<RewardProgressData>;

  constructor(
    private apiService: ApiService,
    private nativePushService: NativePushService,
    private identityService: IdentityService,
    private storeMenuService: StoreMenuService,
    private storageService: StorageService,
  ) { }

  sendReview({ id: orderId }: OrderViewDto, review: OrderReview): Observable<boolean> {
    return this.apiService.orderReview({ orderId, OrderReviewDto: review })
      .pipe(map(({ result = false }) => result));
  }

  getOrderPricing(order: OrderDto, paymentSourceType: PaymentSourceType = 'card_new'): Observable<OrderPricingDto> {
    return this.apiService.orderPricingComputeWithSourceType({ OrderDto: order, paymentSourceType });
  }

  watchOrder(orderId?: string): Observable<OrderStatusData> {
    if (orderId) {
      return this.nativePushService.orderStatusUpdateListener.pipe(filter(o => o.orderId === orderId));
    }

    return this.nativePushService.orderStatusUpdateListener;
  }

  watchOrderRewards(): Observable<RewardProgressData> {
    if (!OrderService.pendingRewardData) {
      OrderService.pendingRewardData = new BehaviorSubject(undefined);
      this.nativePushService.orderStatusUpdateListener.subscribe(orderData => {
        if (orderData.rewardProgress) {
          OrderService.pendingRewardData.next(orderData.rewardProgress);
        }
      });
    }

    return OrderService.pendingRewardData.asObservable();
  }

  clearOrderRewards(): void {
    OrderService.pendingRewardData.next(undefined);
  }

  getOrder(orderId: string): Observable<OrderViewDto> {
    return this.apiService.orderGet(orderId);
  }

  getUserOrders(storeId?: string): Observable<OrderViewConsumerDto[]> {
    return fromPromise(this.identityService.isLoaded()).pipe(switchMap(() => {
      if (this.identityService.getActiveUser()) {
        return this.apiService.orderList(storeId);
      } else {
        return of([]);
      }
    }));
  }

  async getCartFromOrderViewConsumerDto(order: OrderViewConsumerDto): Promise<OrderItem[]> {
    // TODO: make this smarter. Only use a partial menu. Create endpoint that returns only the menu data needed based on order id
    // Task #90 - https://dev.azure.com/joe-coffee/Joe%20Coffee%20App/_workitems/edit/90
    const storeMenu = await this.storeMenuService.getStoreMenuById(order.storeId).toPromise();
    if (storeMenu) {
      const cart = order.items.map(item => {
        return this.getOrderItemFromDto(
          storeMenu,
          item,
          item.choices,
        );
      });

      if (cart.indexOf(undefined) !== -1) {
        return [];
      } else {
        return cart;
      }
    } else {
      return [];
    }
  }

  getOrderItemFromDto(
    storeMenu: MergeMenu,
    itemDto: OrderItemViewDto,
    choices: OrderItemChoiceViewDto[],
  ): OrderItem {
    let selectedCategoryIndex: number;
    const selectedCategory = storeMenu.categories.find((category, index) => {
      if (category.id === itemDto.menuCategoryId) {
        selectedCategoryIndex = index;
        return category.id === itemDto.menuCategoryId;
      }
    });

    if (selectedCategory && selectedCategoryIndex !== undefined) {

      let selectedSectionIndex: number;
      const selectedSection = selectedCategory.sections.find((section, index) => {
        if (section.id === itemDto.menuSectionId) {
          selectedSectionIndex = index;
          return section.id === itemDto.menuSectionId;
        }
      });

      if (selectedSection && selectedSectionIndex !== undefined) {
        const selectedItem = selectedSection.items.find(item => item.id === itemDto.menuItemId);

        if (selectedItem && selectedItem.enabled) {
          const orderItem = new OrderItem(selectedItem, selectedCategoryIndex, selectedSectionIndex, true);

          if (itemDto.size !== '') {
            let sizeIndex = 0;

            selectedItem.sizes.forEach((size, index) => {
              if (size.name === itemDto.size) {
                sizeIndex = index;
              } else if ((size.quantity + size.quantityUnit) === itemDto.size) {
                sizeIndex = index;
              }
            });

            if (
              selectedItem.sizes[sizeIndex].inventory &&
              selectedItem.sizes[sizeIndex].inventory.length > 0 &&
              selectedItem.sizes[sizeIndex].inventory[0].quantity === 0
            ) {
              return;
            }

            if (sizeIndex !== -1) {
              orderItem.setSize(sizeIndex);
            } else {
              orderItem.setSize(0);
            }
          } else if (itemDto.sizeId !== null) {
            const sizeIndex = selectedItem.sizes.findIndex(size => size.id === itemDto.sizeId);

            if (sizeIndex !== -1) {
              orderItem.setSize(sizeIndex);
            } else {
              orderItem.setSize(0);
            }
          } else {
            orderItem.setSize(0);
          }

          if (itemDto.notes.length > 0) {
            const noteIndex = itemDto.notes.findIndex(note => note.type === 'barista');
            {
              orderItem.setNote(itemDto.notes[noteIndex].value);
            }
          }

          let optionsVerified: boolean;
          let choicesVerified: boolean;

          if (choices.length > 0) {
            const availableOptions = orderItem.getAvailableOptions();
            choices.forEach(choice => {
              const optionIndex = availableOptions.findIndex(option => option.id === choice.optionId);
              if (optionIndex !== -1) {
                optionsVerified = true;

                const addedChoice = availableOptions[optionIndex].getAvailableChoices()
                  .find(availableChoice => availableChoice.id === choice.id);

                const defaultChoice = availableOptions[optionIndex].getSelectedChoices()
                  .find(availableChoice => availableChoice.id === choice.id);

                if (addedChoice) {
                  choicesVerified = true;
                  const optionWasAdded = orderItem.addOption(optionIndex, addedChoice.index);

                  if (optionWasAdded) {
                    const addedChoiceToUpdate = orderItem.options[optionIndex].getSelectedChoices().find(selectedChoice => {
                      return selectedChoice.index === addedChoice.index;
                    });

                    if (choice.quantity !== '') {
                      addedChoiceToUpdate.setQuantity(parseInt(choice.quantity, 10));
                    }
                  }
                } else if (defaultChoice) {
                  optionsVerified = true;
                  choicesVerified = true;
                } else {
                  choicesVerified = false;
                }
              } else {
                optionsVerified = false;
              }
            });
          } else {
            optionsVerified = true;
            choicesVerified = true;
          }

          if (choices.length === 0 || (optionsVerified && choicesVerified)) {
            return orderItem;
          }
        }
      }
    }
  }

  setPreviousOrderStoreUrl(storeUrl: string): Promise<void> {
    return this.storageService.set<PreviousStoreStorage>(StorageKeys.PREVIOUS_STORE_STORAGE_KEY, {
      storeUrl,
      orderTime: Date.now(),
    });
  }

  async getPreviousOrderStoreUrl(): Promise<string> {
    const data = await this.storageService.get<PreviousStoreStorage>(StorageKeys.PREVIOUS_STORE_STORAGE_KEY);
    if (!data || !data.orderTime || !data.storeUrl) {
      return;
    }

    if (Date.now() - data.orderTime > PREVIOUS_STORE_CACHE_DURATION_MS) {
      await this.storageService.remove(StorageKeys.PREVIOUS_STORE_STORAGE_KEY);
      return;
    }

    return data.storeUrl;
  }
}
