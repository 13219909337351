import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatButtonToggleModule,
  MatCheckboxModule, MatRadioModule, MatCardModule, MatOptionModule, MatSelectModule,
  MatProgressBarModule, MatDividerModule, MatSnackBarModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { StoreCheckoutBottomsheetComponent } from './store-checkout-bottomsheet/store-checkout-bottomsheet.component';
import { DataTransformPipesModule } from 'src/app/shared/packages/data-transform-pipes';
import { CheckoutService } from 'src/app/consumer/services/checkout/checkout.service';
import { MatCurrencyInputModule } from 'src/app/shared/packages/mat-currency-input';
import { UserVerificationModule } from 'src/app/shared/packages/user-verification/user-verification.module';
import { NewUserWelcomeDialogComponent } from './new-user-welcome-dialog/new-user-welcome-dialog.component';
import { OrderService } from 'src/app/consumer/services/order/order.service';
import { StoreMenuService } from 'src/app/consumer/services/store-menu/store-menu.service';
import { CardEntryStripeElementComponent } from './card-entry-stripe-element/card-entry-stripe-element.component';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatCardModule,
  MatOptionModule,
  MatSelectModule,
  MatDividerModule,
  MatProgressBarModule,
  MatSnackBarModule,

  // custom
  MatCurrencyInputModule,
];

const entryComponents = [
  StoreCheckoutBottomsheetComponent,
  NewUserWelcomeDialogComponent,
];

@NgModule({
  imports: [
    ...MATERIAL_IMPORTS,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CommonModule,
    DataTransformPipesModule,
    UserVerificationModule,
  ],
  declarations: [
    ...entryComponents,
    CardEntryStripeElementComponent,
  ],
  providers: [
    CheckoutService,
    OrderService,
    StoreMenuService,
  ],
  entryComponents,
})
export class StoreCheckoutModule { }
