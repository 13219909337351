import { OnInit, TemplateRef } from '@angular/core';
import { MerchantBottombarService } from '../../../services/merchant-bottombar/merchant-bottombar.service';
var MerchantBottombarTemplateComponent = (function () {
    function MerchantBottombarTemplateComponent(bottombarService) {
        this.bottombarService = bottombarService;
    }
    Object.defineProperty(MerchantBottombarTemplateComponent.prototype, "template", {
        set: function (template) {
            this.bottombarService.setTemplate(template);
        },
        enumerable: true,
        configurable: true
    });
    MerchantBottombarTemplateComponent.prototype.ngOnInit = function () {
    };
    return MerchantBottombarTemplateComponent;
}());
export { MerchantBottombarTemplateComponent };
