import { Injectable } from '@angular/core';
import { merge as _merge, omit as _omit } from 'lodash';

import { JOE_ASCII_LOGO } from './joe-logo';
import { NativeInitializationService } from '../../packages/native-support/services/native-initialization/native-initialization.service';
import { EnvironmentService, EnvironmentModes } from '../environment/environment.service';

@Injectable()
export class AppInitializationService {

  constructor(
    private readonly nativeInitialization: NativeInitializationService,
    private readonly environmentService: EnvironmentService,
  ) { }

  /**
   * Initializes the environment.
   *
   * TODO:
   *  - maybe break this into platform specific sub-services
   *  - all public methods should be async and await onReady to avoid false data
   */
  async init(): Promise<void> {
    await this.environmentService.onReady();

    const mode: EnvironmentModes = await this.environmentService.getMode();
    const native: boolean = await this.environmentService.isNative();

    if (native) {
      this.nativeInitialization.init({ mode });
    } else {
      // TODO: any web init stuff here
    }

    this.outputConsoleEnv();
    return;
  }

  private async outputConsoleEnv(): Promise<void> {
    const mode = (await this.environmentService.getMode()) === EnvironmentModes.MERCHANT ? 'merchant' : 'consumer';
    const platform = await this.environmentService.getPlatformName();
    const versions = await this.environmentService.getVersions();
    const host = this.environmentService.getHost();
    const envInfo: { [key: string]: string } = _merge({ platform, mode, host }, _omit(versions, 'build'), versions.build);
    const outLines = JOE_ASCII_LOGO.map(line => ('             ' + line));

    Object.keys(envInfo).forEach(key => {
      const value = envInfo[key];
      if (!value) {
        return;
      }
      // tslint:disable-next-line
      outLines.push(':::  ' + padItUp(key, 35, '.') + ' ' + value)
    });

    // replace space with nbsp
    // tslint:disable-next-line
    console.log(outLines.join('\r\n').replace(/ /g, '\xa0'));

    function padItUp(value: string, pad: number, char: string = ' ') {
      const spaces = pad - value.length;
      if (spaces < 1) {
        return value;
      }
      return value + new Array(spaces).fill(char).join('');
    }
  }
}
