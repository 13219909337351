import { Injectable } from '@angular/core';
import { NativeEnvironmentService } from '../native-environment/native-environment.service';

interface CordovaAndroidVolume {
  getAlarm(success: Function, error: Function): void;
  getDTMF(success: Function, error: Function): void;
  getMusic(success: Function, error: Function): void;
  getNotification(success: Function, error: Function): void;
  getRinger(success: Function, error: Function): void;
  getSystem(success: Function, error: Function): void;
  getVoiceCall(success: Function, error: Function): void;

  setAlarm(volume: number, showToast: boolean, success: Function, error: Function): void;
  setAll(volume: number, showToast: boolean, success: Function, error: Function): void;
  setDTMF(volume: number, showToast: boolean, success: Function, error: Function): void;
  setMusic(volume: number, showToast: boolean, success: Function, error: Function): void;
  setNotification(volume: number, showToast: boolean, success: Function, error: Function): void;
  setRinger(volume: number, showToast: boolean, success: Function, error: Function): void;
  setSystem(volume: number, showToast: boolean, success: Function, error: Function): void;
  setVoiceCall(volume: number, showToast: boolean, success: Function, error: Function): void;
}

@Injectable()
export class NativeAudioVolumeService {

  private cordovaAndroidVolume: CordovaAndroidVolume;

  constructor(
    private nativeEnvironment: NativeEnvironmentService,
  ) {
    this.cordovaAndroidVolume = (<any>window)['androidVolume'];
  }

  async volumeSupported(): Promise<boolean> {
    await this.nativeEnvironment.ready();
    const platform = await this.nativeEnvironment.getPlatform();
    return platform === 'android' && !!(this.cordovaAndroidVolume && this.cordovaAndroidVolume.setMusic);
  }

  async setMediaVolume(volume: number, showToast: boolean = false): Promise<boolean> {
    if (!await this.volumeSupported()) {
      return false;
    }

    return new Promise((resolve, _reject) => {
      this.cordovaAndroidVolume.setMusic(volume, showToast, () => resolve(true), () => resolve(false));
    });
  }

}
