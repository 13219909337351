import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCurrencyInputComponent } from './components/mat-currency-input/mat-currency-input.component';
import { MatInputModule } from '@angular/material';

export const MATERIAL_IMPORTS = [
  MatInputModule,
];

@NgModule({
  imports: [
    CommonModule,
    MATERIAL_IMPORTS,
  ],
  declarations: [
    MatCurrencyInputComponent,
  ],
  providers: [],
  exports: [
    MatCurrencyInputComponent,
  ],
  entryComponents: [],

})
export class MatCurrencyInputModule { }
