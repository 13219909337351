import { Component, OnInit, OnDestroy } from '@angular/core';

import { PosDeviceService, PairedDeviceDetails } from 'src/app/pos/services/pos-devices/pos-device.service';
import { takeWhile } from 'rxjs/operators';
import { AudioAlertService } from '../../services/audio-alert/audio-alert.service';
import { ApplicationError } from 'src/app/shared/errors/application-error';
import { Order } from 'src/app/pos/pages/pos-dashboard/components/pos-order-card/pos-order-card.component';

const ALERT_INTERVAL = 15000;
const ERROR_INTERVAL = 1000; // increment time to alarm timer
const ERROR_ALERT_DELAY = 600000; // 600000 = 10 minutes - sound alarm every 10 minutes
@Component({
  selector: 'ui-pos-chrome',
  templateUrl: './ui-pos-chrome.component.html',
  styleUrls: ['./ui-pos-chrome.component.scss'],
})
export class UiPosChromeComponent implements OnInit, OnDestroy {

  private componentActive: boolean;
  newOrders: number;
  pairedDevice: PairedDeviceDetails;
  alertInterval: NodeJS.Timer;
  errorInterval: NodeJS.Timer;
  alerting: boolean;
  errorMessage: string;
  displayTimeUntilAlarm: string;
  private unpluggedSince: number;
  private errorType: 'unplugged' | 'network';

  constructor(
    private posDevice: PosDeviceService,
    private audioAlert: AudioAlertService,
  ) { }

  ngOnInit(): void {
    this.componentActive = true;

    this.posDevice.pairedDevice
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(pairedDevice => { this.pairedDevice = pairedDevice; });

    this.posDevice.newOrders
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((orders: Order[]) => { this.updateOrders(orders); });

    this.posDevice.activeOrdersError
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(error => { this.updateError(error); });

  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private updateOrders(orders: Order[]): void {
    this.newOrders = orders.length;
    this.toggleAlert();
  }

  private updateError(error: Error): void {
    this.errorType = error instanceof ApplicationError ? 'network' : 'unplugged';
    this.errorMessage = error && error.message;
    this.toggleError();
  }

  private toggleError(): void {
    if (this.errorMessage) {
      if (!this.errorInterval) {
        this.unpluggedSince = Date.now();
        this.errorInterval = setInterval(() => this.doError(), ERROR_INTERVAL);
      }
    } else {
      if (this.errorInterval) {
        clearInterval(this.errorInterval);
        this.errorInterval = undefined;
        this.unpluggedSince = undefined;
        this.displayTimeUntilAlarm = undefined;
      }
    }

  }

  private toggleAlert(): void {
    if (this.newOrders > 0) {
      if (!this.alertInterval) {
        this.doAlert();
        this.alertInterval = setInterval(() => {
          // if the interval was cleared don't play a sound
          if (this.alertInterval) {
            this.doAlert();
          }
        }, ALERT_INTERVAL);
      }
    } else {
      if (this.alertInterval) {
        this.alerting = false;
        clearInterval(this.alertInterval);
        this.alertInterval = undefined;
      }
    }
  }

  private doAlert(): void {
    if (!this.isStorePaired()) {
      return;
    }

    this.alerting = true;
    this.audioAlert.playOrderAlert();
    setTimeout(() => this.alerting = false, 1000);
  }

  private doError(): void {
    if (!this.isStorePaired() || !this.unpluggedSince) {
      return;
    }
    if (this.errorType === 'unplugged') {
      const unpluggedDuration = Date.now() - this.unpluggedSince;
      const timeUntilAlarm = Math.floor((ERROR_ALERT_DELAY - unpluggedDuration) / 60000) + 1;
      this.displayTimeUntilAlarm = `alarm in ${timeUntilAlarm}m`;
      if (this.errorMessage && unpluggedDuration > ERROR_ALERT_DELAY) {
        this.unpluggedSince = Date.now();
        this.audioAlert.playErrorAlert();
      }
    } else {
      this.unpluggedSince = undefined;
      this.displayTimeUntilAlarm = undefined;
    }
  }

  private isStorePaired(): boolean {
    return this.pairedDevice && this.pairedDevice.active;
  }
}
