import { Component, OnDestroy, HostBinding, Input, Optional, Self, ElementRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Big } from 'big.js';
import { MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

/**
 * Custom material control that takes user input in dollars and stores it as cents in the model
 */

@Component({
  selector: 'mat-currency-input',
  templateUrl: './mat-currency-input.component.html',
  styleUrls: ['./mat-currency-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MatCurrencyInputComponent },
  ],
})
export class MatCurrencyInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
  static nextId = 0;

  dollarValue: string;
  stateChanges: Subject<void> = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'mat-currency-input';

  @HostBinding('attr.aria-describedby')
  describedBy = '';

  @HostBinding()
  id = `mat-currency-input-${MatCurrencyInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  set value(value: string) {
    this.stateChanges.next();
  }

  get empty(): boolean {
    return false;
  }

  @Input()
  selectOnFocus = false;

  @Input()
  get placeholder(): string {
    return this.placeholderInternal;
  }
  set placeholder(plh) {
    this.placeholderInternal = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.requiredInternal;
  }
  set required(req) {
    this.requiredInternal = !!req;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.disabledInternal;
  }
  set disabled(dis) {
    this.disabledInternal = !!dis;
    this.stateChanges.next();
  }

  private placeholderInternal: string;
  private requiredInternal = false;
  private disabledInternal = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      // format on blur
      if (!origin) {
        this.writeValue(this.ngControl.value);
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  // fired on text input change - emits cents from dollar input
  onChange(value: string): void {
    this.propagateChange(Big(value || '0').times(100).toString());
  }

  // called by ngControl - provides cent value and updates input to dollar value
  writeValue(value: string): void {
    this.dollarValue = Big(value || '0').div(100).toFixed(2);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if (!event || !event.target) {
      return;
    }

    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input').focus();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(): void { }

  private propagateChange = (_: any) => { };

}
