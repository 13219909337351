import { Subject } from 'rxjs';
var MerchantTopbarService = (function () {
    function MerchantTopbarService() {
        this.optionChangeSubject = new Subject();
        this.topbarOptions = {
            title: '',
            companyId: undefined,
        };
    }
    MerchantTopbarService.prototype.setTitle = function (title) {
        this.topbarOptions.title = title;
        this.emitOptionChanges();
    };
    MerchantTopbarService.prototype.setOptions = function (options) {
        Object.assign(this.topbarOptions, options);
        this.emitOptionChanges();
    };
    MerchantTopbarService.prototype.watchOptions = function () {
        return this.optionChangeSubject.asObservable();
    };
    MerchantTopbarService.prototype.emitOptionChanges = function () {
        this.optionChangeSubject.next(this.topbarOptions);
    };
    return MerchantTopbarService;
}());
export { MerchantTopbarService };
