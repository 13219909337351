import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

// TODO: maybe move these to environment.ts
const APP_UPDATE_URL = 'https://app-install.joe.coffee/merchant/joe-coffee-merchant-app.apk';
const APP_UPDATE_VERSION_INFO_URL = 'https://app-install.joe.coffee/merchant/joe-coffee-merchant-app-version.json';

interface UpdateVersionInfo {
  code: number;
}

@Injectable()
export class NativeAppUpdateService {

  // for testing
  private windowLocation: { href: string } = window.location;

  constructor(
    private http: HttpClient,
  ) { }

  fetchUpdate(): void {
    this.windowLocation.href = APP_UPDATE_URL;
  }

  getUpdateVersionCode(): Promise<number> {
    return this.http.get<UpdateVersionInfo>(APP_UPDATE_VERSION_INFO_URL).pipe(map(result => result && result.code)).toPromise();
  }

}
