import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
];
var MerchantPlusNagDialogModule = (function () {
    function MerchantPlusNagDialogModule() {
    }
    return MerchantPlusNagDialogModule;
}());
export { MerchantPlusNagDialogModule };
