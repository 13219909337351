import { EnvironmentService } from '../environment/environment.service';
var PaymentService = (function () {
    function PaymentService(environmentService) {
        var config = environmentService.getConfig();
        this.stripe = Stripe(config.payment.stripePublishable);
        this.stripeTest = Stripe(config.payment.stripePublishableTest);
    }
    PaymentService.prototype.createSecureInputElement = function (_a, style, fake) {
        var cardNumber = _a.cardNumber, cardExpiry = _a.cardExpiry, cardCvc = _a.cardCvc, postalCode = _a.postalCode;
        if (style === void 0) { style = {}; }
        var stripe = fake ? this.stripeTest : this.stripe;
        var elements = stripe.elements();
        var cardNumberElement = elements.create('cardNumber', { style: style });
        var cardExpiryElement = elements.create('cardExpiry', { style: style });
        var cardCvcElement = elements.create('cardCvc', { style: style });
        var cardPostalElement = elements.create('postalCode', { style: style });
        cardNumberElement.mount(cardNumber);
        cardExpiryElement.mount(cardExpiry);
        cardCvcElement.mount(cardCvc);
        cardPostalElement.mount(postalCode);
        return {
            cardNumber: { complete: false, element: cardNumberElement },
            cardExpiry: { complete: false, element: cardExpiryElement },
            cardCvc: { complete: false, element: cardCvcElement },
            postalCode: { complete: false, element: cardPostalElement },
        };
    };
    PaymentService.prototype.tokenizeCardFromInputElement = function (element, fake) {
        var stripe = fake ? this.stripeTest : this.stripe;
        return stripe.createToken(element);
    };
    PaymentService.prototype.tokenizeBankAccount = function (_a) {
        var routing = _a.routing, account = _a.account, holderName = _a.holderName, holderType = _a.holderType;
        return this.stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: routing,
            account_number: account,
            account_holder_name: holderName,
            account_holder_type: holderType,
        }).then(this.handleStripeCreateTokenResponse);
    };
    PaymentService.prototype.tokenizePII = function (pii) {
        return this.stripe.createToken('pii', {
            personal_id_number: pii,
        }).then(this.handleStripeCreateTokenResponse);
    };
    PaymentService.prototype.handleStripeCreateTokenResponse = function (data) {
        if (!data) {
            return;
        }
        if (data.error) {
            throw new Error(data.error.message);
        }
        return data.token && data.token.id;
    };
    return PaymentService;
}());
export { PaymentService };
