import { OnInit, AfterViewInit, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
var UserVerificationComponent = (function () {
    function UserVerificationComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.done = new EventEmitter();
        this.resendCode = new EventEmitter();
        this.digits = new Array(6).fill('');
    }
    Object.defineProperty(UserVerificationComponent.prototype, "code", {
        set: function (code) {
            if (code && code.length === 6) {
                this.verificationInputKey({ data: code, preventDefault: function () { }, target: null, inputType: '' });
            }
        },
        enumerable: true,
        configurable: true
    });
    UserVerificationComponent.prototype.cancel = function () {
        this.done.emit();
    };
    UserVerificationComponent.prototype.codeEntered = function (formValue) {
        this.done.emit(formValue);
        this.changeDetectorRef.detectChanges();
    };
    UserVerificationComponent.prototype.onResendCode = function () {
        var _this = this;
        this.resendCode.emit();
        this.sendCodeProgress = 0;
        var progressInterval = setInterval(function () {
            _this.sendCodeProgress += 5;
            if (_this.sendCodeProgress > 100) {
                clearInterval(progressInterval);
                _this.sendCodeProgress = undefined;
                progressInterval = undefined;
            }
            _this.changeDetectorRef.detectChanges();
        }, 100);
    };
    UserVerificationComponent.prototype.verificationInputKey = function (event) {
        var _this = this;
        event.preventDefault();
        var key = event.data;
        if (event.inputType === 'deleteContentBackward') {
            this.removeDigit();
        }
        else {
            if (key.length > 1) {
                this.clearInput();
                key.split('').forEach(function (digitString) {
                    var input = parseInt(digitString, 10);
                    _this.addDigit(input);
                });
            }
            else {
                var input = parseInt(key, 10);
                this.addDigit(input);
            }
            if (this.activeInput >= this.digits.length) {
                this.codeEntered(this.digits.join(''));
            }
        }
    };
    UserVerificationComponent.prototype.userClick = function () {
        if (this.inputField && this.inputField.nativeElement) {
            this.inputField.nativeElement.focus();
            this.setActiveInput();
        }
    };
    UserVerificationComponent.prototype.setActiveInput = function () {
        for (var i = 0; i < this.digits.length; i++) {
            if (this.digits[i] === '') {
                this.activeInput = i;
                break;
            }
        }
    };
    UserVerificationComponent.prototype.clearInput = function () {
        for (var i = 0; i < this.digits.length; i++) {
            this.digits[i] = '';
            this.activeInput = 0;
        }
    };
    UserVerificationComponent.prototype.addDigit = function (value) {
        if (!isNaN(value)) {
            for (var i = 0; i < this.digits.length; i++) {
                if (this.digits[i] === '') {
                    this.digits[i] = value + '';
                    this.activeInput = i + 1;
                    break;
                }
            }
        }
    };
    UserVerificationComponent.prototype.removeDigit = function () {
        for (var i = this.digits.length - 1; i >= 0; i--) {
            if (this.digits[i] !== '') {
                this.digits[i] = '';
                this.activeInput = i;
                break;
            }
        }
    };
    UserVerificationComponent.prototype.ngOnInit = function () {
        if (this.phoneNumber) {
            this.phoneNumberNormalized = this.phoneNumber.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
    };
    UserVerificationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.userClick(); });
    };
    return UserVerificationComponent;
}());
export { UserVerificationComponent };
