import { Pipe, PipeTransform } from '@angular/core';
import { Big } from 'big.js';

type SourceWeightUnits = 'oz' | 'g' | 'lb';

const OUNCES_PER_GRAM = 28.3495;
const OUNCES_PER_LB = 0.0625;

@Pipe({
  name: 'weightToOunces',
})
export class WeightToOuncesPipe implements PipeTransform {

  transform(value: number, fromUnit: SourceWeightUnits): number {
    switch (fromUnit) {
      case 'oz':
        return value;
      case 'g':
        return parseFloat(Big(value).div(OUNCES_PER_GRAM).toString());
      case 'lb':
        return parseFloat(Big(value).div(OUNCES_PER_LB).toString());
    }
    throw new Error(`Unknown unit ${fromUnit}`);
  }
}
