import { Injectable } from '@angular/core';
import { NativeAudio } from '@ionic-native/native-audio';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
// import { v4 as uuidV4 } from 'uuid';

const ORDER_HONK_SOUND = 'public-web-assets/audio/order-honk.mp3';
const ORDER_BELL_SOUND = 'public-web-assets/audio/order-bell.mp3';
const ERROR_HORN_SOUND = 'public-web-assets/audio/error-buzzer-2.mp3';

@Injectable()
export class AudioAlertService {

  private audioCache: { [key: string]: HTMLAudioElement } = {};
  // private nativeAudioIds: { [key: string]: string } = {};

  constructor(
    private readonly nativeAudio: NativeAudio,
    private readonly environmentService: EnvironmentService,
    private telemetryService: TelemetryService,
  ) { }

  async playOrderAlert(): Promise<void> {
    return this.playSound(ORDER_BELL_SOUND);
  }

  async playArrivedAlert(): Promise<void> {
    return this.playSound(ORDER_HONK_SOUND);
  }

  async playErrorAlert(): Promise<void> {
    return this.playSound(ERROR_HORN_SOUND);
  }

  initOrderAlert(): void {
    this.initSound(ORDER_BELL_SOUND);
  }

  initErrorAlert(): void {
    this.initSound(ERROR_HORN_SOUND);
  }

  /**
   * Loads a sound using native if available then falls back to html5
   */
  private async initSound(file: string): Promise<void> {
    const native = await this.environmentService.isNative();
    if (native) {
      // no longer preloading native audio files.
      // this is an attempt to fix a bug where the tablet stops playing audio
      // after sitting for a while. I can't repro it, but my theory is that the
      // preloaded audio cache expires or gets GC'd
      return;
      // try {
      //   const audioId = uuidV4();
      //   await this.nativeAudio.preloadComplex(audioId, file, 1, 2, 0);
      //   this.nativeAudioIds[file] = audioId;
      //   return;
      // } catch (e) {

      // }
    }

    // fallback to html 5 audio
    this.audioCache[file] = new Audio(file);
  }

  /**
   * Plays a sound using native if available then falls back to html5
   */
  private async playSound(file: string): Promise<void> {
    const native = await this.environmentService.isNative();
    if (native) {
      try {
        // unload / reload audio file (use file as id for simplicity)
        try {
          await this.nativeAudio.unload(file);
        } catch (error) {
          const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
          this.telemetryService.logError('Audio unload error ' + errorMessage);
        }

        await this.nativeAudio.preloadComplex(file, file, 1, 5, 0);

        await this.nativeAudio.play(file).catch(err =>
          this.telemetryService.logError(err.message, false, false));  // When users block audio use from the browser, logs this error

        return;
      } catch (error) {
        const errorMessage = error && error.message ? error.message : 'Unknown error';
        this.telemetryService.logError('Audio Playback Error ' + errorMessage);
      }
    }

    return new Promise<void>((resolve, reject) => {
      try {
        const audio = this.audioCache[file] || new Audio(file);
        audio.onended = () => resolve();
        audio.onerror = () => resolve();
        audio.play().catch(err =>
           this.telemetryService.logError(err.message, false, false)); // When users block audio use from the browser, logs this error
      } catch (error) {
        // if it fails ¯\_(ツ)_/¯
        const errorMessage = error && error.message ? error.message : 'Unknown error';
        this.telemetryService.logError('Audio Playback Error ' + errorMessage);
        resolve();
      }
    });
  }
}
