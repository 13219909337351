import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage';
import { File } from '@ionic-native/file';
import { EnvironmentService, EnvironmentModes } from 'src/app/shared/services/environment/environment.service';
import { TelemetryService } from '../../telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';

const JOE_BACKUP_FILE_PATH = 'joe-coffee-merchant-app-local-storage-backup.json';
@Injectable()
export class StorageService {
  // to allow mocking for testing
  private localStorage = localStorage;
  // used to facilitate backing up local storage to a json file for development
  private backupPersistencePath: string;
  private backupEnabled: boolean;
  private backupReady: Promise<void>;
  private backupReadyResolver: Function;

  constructor(
    private nativeStorage: NativeStorage,
    private environmentService: EnvironmentService,
    private nativeFile: File,
    private telemetryService: TelemetryService,
  ) {
    this.backupReady = new Promise(resolve => this.backupReadyResolver = resolve);
    this.initStorageBackup();
  }

  /**
   * Set value in device storage (should be an object with an interface)
   */
  async set<T>(key: string, value: T, bypassBackupReady: boolean = false): Promise<void> {
    if (!bypassBackupReady) {
      await this.backupReady;
    }

    if (await this.environmentService.isNative()) {

      try {
        await this.environmentService.onReady();
        await this.nativeStorage.setItem(key, value);

        // backups up local storage to a file for development
        this.backupLocalStorage();
      } catch (error) {
        const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
        this.telemetryService.logError(errorMessage);
      }
      return;
    }

    return this.localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Get value from device storage (should be an object with an interface)
   *
   * @returns value from storage or empty object if undefined.
   */
  async get<T>(key: string): Promise<T> {
    await this.backupReady;

    if (await this.environmentService.isNative()) {

      try {
        await this.environmentService.onReady();
        return (await this.nativeStorage.getItem(key)) || {} as any;
      } catch (error) {
        const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
        this.telemetryService.logError(errorMessage);
      }
      return {} as any;
    }

    try {
      return JSON.parse(this.localStorage.getItem(key)) || {};
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
    }
    return {} as any;
  }

  /**
   * Removes value from device storage
   */
  async remove(key: string): Promise<void> {
    await this.backupReady;

    if (await this.environmentService.isNative()) {
      try {
        await this.environmentService.onReady();
        await this.nativeStorage.remove(key);

        // backups up local storage to a file for development
        this.backupLocalStorage();
      } catch (error) {
        const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
        this.telemetryService.logError(errorMessage);
        // probably trying to remove a key that doesn't exist
      }
      return;
    }

    return this.localStorage.removeItem(key);
  }

  async getKeys(bypassBackupReady: boolean = false): Promise<string[]> {
    if (!bypassBackupReady) {
      await this.backupReady;
    }

    if (await this.environmentService.isNative()) {
      try {
        await this.environmentService.onReady();
        return await this.nativeStorage.keys();
      } catch (error) {
        const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
        this.telemetryService.logError(errorMessage);
        // probably trying to remove a key that doesn't exist
      }
    }

    return new Array(this.localStorage.length).fill(0).map((_, i) => {
      return this.localStorage.key(i);
    });
  }

  private async initStorageBackup() {
    const config = this.environmentService.getConfig();
    const isNative = await this.environmentService.isNative();

    if (isNative) {

      // in development mode load json file of previously backed up local storage
      this.backupPersistencePath = this.nativeFile && this.nativeFile.externalRootDirectory;

      if (!!this.backupPersistencePath) {
        // if we got a path, remove trailing slash (if there is one)
        this.backupPersistencePath = this.backupPersistencePath.replace(/\/$/, '');

        if (config.name === 'Development') {

          // path found / cordova plugin installed

          this.backupEnabled = true;

          // tslint:disable-next-line
          console.log('restoring local storage from json file', this.backupPersistencePath + '/' + JOE_BACKUP_FILE_PATH);

          // restore data from json file in development
          try {
            const rawData = await this.nativeFile.readAsText(this.backupPersistencePath, JOE_BACKUP_FILE_PATH);

            const data = JSON.parse(rawData);
            const keys = Object.keys(data);

            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              await this.set(key, data[key], true);
            }
          } catch (error) {
            const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
            this.telemetryService.logError(errorMessage);
          }

        } else { // else of: config.name === 'Development'
          const isMerchant = (await this.environmentService.getMode()) === EnvironmentModes.MERCHANT;
          if (isMerchant) {
            const existingKeys = await this.getKeys(true);
            if (existingKeys.length === 0) {
              // purge data in non-development if it is a fresh install (there will be nothing in storage)
              try {
                await this.nativeFile.writeFile(this.backupPersistencePath, JOE_BACKUP_FILE_PATH, '{}', { replace: true });
              } catch (error) {
                const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
                this.telemetryService.logError(errorMessage);
              }
            }
          }
        }
      }
    }

    this.backupReadyResolver();
  }

  private async backupLocalStorage() {
    if (!this.backupEnabled) {
      return;
    }

    try {
      const keys = await this.getKeys();

      const output: any = {};

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const data = await this.get(key);
        output[key] = data;
      }
      const saveData = JSON.stringify(output);

      await this.nativeFile.writeFile(this.backupPersistencePath, JOE_BACKUP_FILE_PATH, saveData, { replace: true });
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
    }
  }

}
