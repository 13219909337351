import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface VerificationDialogData {
  phoneNumber: string;
  tryCount: number;
}

@Component({
  selector: 'user-verification-dialog',
  templateUrl: './user-verification-dialog.component.html',
  styleUrls: ['./user-verification-dialog.component.scss'],
})
export class UserVerificationDialogComponent implements OnInit {

  resendCode: EventEmitter<void> = new EventEmitter();
  phoneNumber: string;
  tryCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: VerificationDialogData,
    private dialogRef: MatDialogRef<string>,
  ) {
    if (data) {
      this.phoneNumber = data.phoneNumber;
      this.tryCount = data.tryCount;
    }
  }

  public ngOnInit(): void {
  }

  onDone(code: string): void {
    this.dialogRef.close(code);
  }

  onResendCode(): void {
    this.resendCode.emit();
  }

}
