import { PipeTransform } from '@angular/core';
var MicrotimePipe = (function () {
    function MicrotimePipe() {
    }
    MicrotimePipe.prototype.transform = function (microtime) {
        var totalSeconds = Math.floor(microtime / 1000);
        var seconds = totalSeconds % 60;
        var minutes = Math.floor(totalSeconds / 60) % 60;
        var hours = Math.floor(totalSeconds / 3600) % 3600;
        return this.formatTime(seconds, minutes, hours);
    };
    MicrotimePipe.prototype.formatTime = function (s, m, h) {
        var format = function (show, value, suffix) {
            if (suffix === void 0) { suffix = ''; }
            return show ? ('00' + value).slice(-2) + suffix : '';
        };
        var hours = format(!!h, h, ':');
        var minutes = format(!!(m || h), m, ':');
        var seconds = format(true, s, ((m || h) ? '' : ' seconds'));
        return hours + minutes + seconds;
    };
    return MicrotimePipe;
}());
export { MicrotimePipe };
