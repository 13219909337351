import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OrderViewConsumerDto } from 'src/gen/joeServerCore';
import { OrderItem } from 'src/app/pos/services/pos-devices/pos.service.interfaces';
import { Router } from '@angular/router';
import { StringUtilService } from 'src/app/shared/services/string-util/string-util.service';

export interface ReorderData {
  order: OrderViewConsumerDto;
  cart: OrderItem[];
}

@Component({
  selector: 'recent-order-reorder',
  templateUrl: './recent-order-reorder.component.html',
  styleUrls: ['./recent-order-reorder.component.scss'],
})
export class RecentOrderReorderComponent implements OnInit {
  order: OrderViewConsumerDto;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<RecentOrderReorderComponent>,
    private stringUtilService: StringUtilService,
    @Inject(MAT_DIALOG_DATA) private data: ReorderData,
  ) { }

  public ngOnInit(): void {
    this.order = this.data.order;
  }

  public orderFrom(storeName: string, storeId: string) {
    this.router.navigateByUrl(`/stores/${this.stringUtilService.urlify(storeName)}/${storeId}/details/(store:order)`);
    this.dialogRef.close();
  }

}
