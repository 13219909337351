import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Big } from 'big.js';

@Pipe({
  name: 'centCurrency',
})
/**
 * Exactly the same as the default angular currency pipe,
 * but it takes cents instead of dollars for input
 */
export class CentCurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const dollarValue = isNaN(parseFloat(value)) ? 0 : Big(value).div(100).toFixed(2);
    return super.transform(dollarValue, ...args);
  }

}
