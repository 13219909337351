import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { IdentityService } from '../../services/identity/identity.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private identityService: IdentityService,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable(resolver => {
      this.identityService.isLoaded().then(() => {

        const isAuthenticated = !!this.identityService.getActiveUser();

        if (!isAuthenticated) {
          const returnUrl = state.url && state.url.length > 3 ? state.url : undefined;
          this.router.navigate(['/login'], { queryParams: { returnUrl } });
        }

        resolver.next(isAuthenticated);
        resolver.complete();
      });
    });
  }

}
