<h2 mat-dialog-title>{{itemName}} - {{option?.get('name').value}}</h2>
<mat-dialog-content>
  <div [formGroup]="option">
    <div fxFlexLayout="row" fxLayoutGap="10px">
      <mat-form-field>
        <input matInput type="number" formControlName="minChoices" placeholder="Min Choices">
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" formControlName="maxChoices" placeholder="Max Choices">
      </mat-form-field>
    </div>

    <div style="margin-bottom: 10px;">
      <div class="choices-choice-row">
        <div fxLayout="row">
          <div fxFlex>&nbsp;</div>
          <div fxFlex="200px">
            <span class="choice-price">
              <mat-button-toggle-group size="smaller" (change)="setAllChoicesValue($event.value)">
                <mat-button-toggle [value]="false">
                  <span>ø</span>
                </mat-button-toggle>
                <mat-button-toggle [value]="true">Custom</mat-button-toggle>
              </mat-button-toggle-group>
            </span>
          </div>
          <div fxFlex="60px" fxLayoutAlign="center">
            &nbsp;
            <!-- <mat-checkbox></mat-checkbox> -->
          </div>
          <div fxFlex="60px" fxLayoutAlign="center">
            &nbsp;
            <!-- <mat-checkbox></mat-checkbox> -->
          </div>
          <div fxFlex="60px" fxLayoutAlign="center">
            &nbsp;
            <!-- <mat-checkbox></mat-checkbox> -->
          </div>
          <div fxFlex="60px" fxLayoutAlign="center">
            &nbsp;
            <!-- <mat-checkbox></mat-checkbox> -->
          </div>
        </div>
        <div *ngIf="allSizesControls" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start"
          class="choice-size-chips-wrap" style="padding-top: 10px;">
          <div *ngFor="let size of allSizesControls">
            <menu-item-size-chip [size]="size"></menu-item-size-chip>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="choices" class="choices-table">
      <div fxLayout="row" class="choices-title-row">
        <div fxFlex>Choice</div>
        <div fxFlex="200px">Price</div>
        <div fxFlex="60px" fxLayoutAlign="center">Free</div>
        <div fxFlex="60px" fxLayoutAlign="center">Default</div>
        <div fxFlex="60px" fxLayoutAlign="center">Required</div>
        <div fxFlex="60px" fxLayoutAlign="center">Hidden</div>
      </div>
      <div>
        <div *ngFor="let choice of getOptionChoices().controls; let i = index" [formGroup]="choice"
          class="choices-choice-row">
          <div fxLayout="row">
            <div fxFlex>
              {{choice?.get('name').value}}
            </div>
            <div fxFlex="200px">
              <span class="choice-price" *ngIf="choice?.get('price')?.value; let choicePrice;">
                <mat-button-toggle-group size="small" [value]="!!(choice?.get('sizePrices')?.controls)"
                  (change)="choiceSizeCustomChange(choice, $event.value)" [disabled]="choice?.get('free')?.value">
                  <mat-button-toggle [value]="false">
                    <span *ngIf="choicePrice > 0; else freeChoicePriceTemplate"
                      [class.choice-price-no-charge]="choice?.get('free')?.value">
                      +{{choicePrice | centCurrency}}
                    </span>
                  </mat-button-toggle>
                  <mat-button-toggle [value]="true">Custom</mat-button-toggle>
                </mat-button-toggle-group>
              </span>
            </div>
            <div fxFlex="60px" fxLayoutAlign="center">
              <mat-checkbox formControlName="free" (change)="freeCheckChange(choice)"
                *ngIf="choice?.get('price')?.value > 0; else checkNotApplicable">
              </mat-checkbox>
            </div>
            <div fxFlex="60px" fxLayoutAlign="center">
              <mat-checkbox formControlName="default"></mat-checkbox>
            </div>
            <div fxFlex="60px" fxLayoutAlign="center">
              <mat-checkbox formControlName="required" (change)="requiredCheckChange(choice)"></mat-checkbox>
            </div>
            <div fxFlex="60px" fxLayoutAlign="center">
              <mat-checkbox formControlName="hidden"></mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start" class="choice-size-chips-wrap"
            *ngIf="choice?.get('sizePrices')?.controls; let sizePrices">
            <div *ngFor="let size of sizePrices;">
              <menu-item-size-chip [size]="size"></menu-item-size-chip>
            </div>
          </div>
        </div>
      </div>

      <ng-template #checkNotApplicable><span class="check-not-applicable">x</span></ng-template>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
  <div *ngIf="errorMessage" class="error-message-text">{{errorMessage}}</div>
  <button mat-flat-button color="accent" (click)="save()">
    <mat-icon>save</mat-icon> Save
  </button>
</mat-dialog-actions>

<ng-template #freeChoicePriceTemplate>free</ng-template>