<h2 mat-dialog-title class="confirm-title" *ngIf="title">{{title}}</h2>
<mat-dialog-content class="confirm-content">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-dialog-content>
<mat-dialog-actions class="confirm-actions" fxLayout="row" fxLayoutAlign="space-around center">
  <button mat-flat-button color="primary" mat-dialog-close *ngIf="cancellable">
    <!-- <button mat-flat-button color="primary" mat-dialog-close> -->
    <mat-icon>close</mat-icon> Cancel
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    <ng-container *ngIf="cancellable">
      <mat-icon>checkmark</mat-icon> Confirm
    </ng-container>

    <ng-container *ngIf="!cancellable">
      <mat-icon>close</mat-icon> Exit
    </ng-container>
  </button>
</mat-dialog-actions>