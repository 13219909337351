<div class="app-download-badge" *ngIf="mobileWebType">
  <a href="https://get.joe.coffee">
    <img *ngIf="mobileWebType === 'android'" class="app-store-download-badge-img-android"
      src="public-web-assets/images/3rd-party/google-play-download-app.png">
    <img *ngIf="mobileWebType === 'iphone'" class="app-store-download-badge-img-apple"
      src="public-web-assets/images/3rd-party/apple-app-store-download.svg">
  </a>
</div>
<div class="consumer-topbar" [class.drawer-open]="drawerOpen">
  <div class="joe-fab" [class.hidden]="topbarHidden" [class.bounce]="topbarBounce"
    [class.bounce-prepare]="topbarBouncePrepare">
    <ui-flip-fab [flipped]="drawerOpen" (flippedChange)="menuClick()" [front]="joeFabFront" [back]="joeFabBack"
      [shadowBack]="false" colorBack="light"></ui-flip-fab>
    <ng-template #joeFabFront>
      <img src="public-web-assets/images/logo-inverted.png" class="topbar-logo">
    </ng-template>
    <ng-template #joeFabBack>
      <mat-icon class="topbar-user-icon">close</mat-icon>
    </ng-template>
  </div>
</div>