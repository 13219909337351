<div class="reward-progress-wrap">
  <div class="reward-progress-wheel-wrap" #rewardProgressWheelWrap>
    <!-- <mat-progress-spinner @.disabled color="warn" mode="determinate" [value]="rewardAnimationValue" strokeWidth="50"
    diameter="200"> -->
    <mat-progress-spinner *ngIf="rewardProgressValue && !loading" color="warn" mode="determinate"
      [value]="rewardAnimationValue" strokeWidth="50" diameter="200">
    </mat-progress-spinner>
    <mat-progress-spinner *ngIf="loading" color="warn" mode="indeterminate" strokeWidth="50" diameter="200">
    </mat-progress-spinner>
    <div class="section-marker-container">
      <div [class.marked]="rewardAnimationValue >= 100" class="section-marker one"></div>
      <div [class.marked]="rewardAnimationValue > 10" class="section-marker two"></div>
      <div [class.marked]="rewardAnimationValue > 20" class="section-marker three"></div>
      <div [class.marked]="rewardAnimationValue > 30" class="section-marker four"></div>
      <div [class.marked]="rewardAnimationValue > 40" class="section-marker five"></div>
      <div [class.marked]="rewardAnimationValue > 50" class="section-marker six"></div>
      <div [class.marked]="rewardAnimationValue > 60" class="section-marker seven"></div>
      <div [class.marked]="rewardAnimationValue > 70" class="section-marker eight"></div>
      <div [class.marked]="rewardAnimationValue > 80" class="section-marker nine"></div>
      <div [class.marked]="rewardAnimationValue > 90" class="section-marker ten"></div>
    </div>
    <div>
      <div class="user-image image-wrapper" [style.backgroundImage]="imgUrl && !loading ? 'url('+ imgUrl  +')' : ''">
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!loading && imgUrl === null"
      class="user-image no-photo">
      <div fxLayout="row" class="icon-wrapper">
        <mat-icon>camera_alt</mat-icon>
        <mat-icon>add</mat-icon>
      </div>
    </div>
  </div>
</div>