import { MatInputModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatInputModule,
];
var MatCurrencyInputModule = (function () {
    function MatCurrencyInputModule() {
    }
    return MatCurrencyInputModule;
}());
export { MatCurrencyInputModule };
