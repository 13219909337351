import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { MenuOptionService } from '../../../../services/menu-option/menu-option.service';
import { LoadingIndicatorService } from 'src/app/shared/packages/ui-loading-indicator';
import { ErrorDialogService } from 'src/app/shared/packages/ui-error-dialog';
import { ReactiveFormService, ErrorAccessor, FieldErrorMap } from 'src/app/shared/services/reactive-form/reactive-form.service';
import { SWAGGER_SCHEMA_DEFINITIONS } from 'src/gen/joeServerCore/schema';
import { MenuOptionCreateDto, MenuOptionWithChoicesDto } from 'src/gen/joeServerCore';
import { DragulaOptions } from 'dragula-reborn';
import { DragulaEvent } from '../../../../../shared/packages/ng2-dragula-reborn/services/dragula/dragula.service';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';

export interface EditOptionData {
  id?: string;
  menuId: string;
  companyId: string;
  tags: string[];
}

export interface EditOptionResult {
  option: MenuOptionWithChoicesDto;
  new: boolean;
}

type QuantityTypes = 'one' | 'finite' | 'named';

@Component({
  selector: 'merchant-menu-option-edit',
  templateUrl: './merchant-menu-option-edit.component.html',
  styleUrls: ['./merchant-menu-option-edit.component.scss'],
})
export class MerchantMenuOptionEditComponent implements OnInit {

  editMode: boolean;
  optionForm: FormGroup;
  getError: ErrorAccessor;
  errorMessage: string;
  quantityTypeToggleValue: QuantityTypes;

  dragulaOptions: DragulaOptions = {
    direction: 'vertical',
    axis: 'y',
  };

  showFiniteQty: boolean;
  private finiteQty: number;
  private namedQty: number;
  private option: MenuOptionWithChoicesDto;
  private tags: string[];

  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private formBuilder: FormBuilder,
    private menuOptionService: MenuOptionService,
    private dialogRef: MatDialogRef<EditOptionResult>,
    private errorDialogService: ErrorDialogService,
    private reactiveFormService: ReactiveFormService,
    private telemetryService: TelemetryService,
    @Inject(MAT_DIALOG_DATA) private loadOption: EditOptionData,
  ) { }

  public async ngOnInit(): Promise<void> {
    const { id, menuId, companyId, tags } = this.loadOption;
    this.tags = tags;
    if (id) {
      this.option = await this.menuOptionService.get(companyId, menuId, id).toPromise();
      this.editMode = true;
    } else {
      this.option = this.menuOptionService.new();
      this.editMode = false;
    }
    this.initForm();
  }

  addQuantity(): void {
    const quantityNames = this.optionForm.get('quantityNames') as FormArray;
    quantityNames.push(new FormControl(''));
    this.clampDefaultQuantity();
    quantityNames.markAsDirty();
  }

  deleteQuantity(index: number): void {
    const quantityNames = this.optionForm.get('quantityNames') as FormArray;
    quantityNames.removeAt(index);
    this.clampDefaultQuantity();
    quantityNames.markAsDirty();
  }

  onChoiceDrop({ sourceIndex, targetIndex }: DragulaEvent): void {
    const options = this.optionForm.get('choices') as FormArray;
    const item = options.controls[sourceIndex];

    options.removeAt(sourceIndex);
    options.insert(targetIndex, item);

    options.markAsDirty();
  }

  /**
   * Caches the finite default value so if someone clicks to named they don't lose the value
   */
  quantityTypeChange({ value }: { value: QuantityTypes }): void {
    const finiteQuantity = value !== 'named';
    const defaultQuantity = this.optionForm.get('defaultQuantity') as FormControl;
    this.optionForm.get('finiteQuantity').setValue(finiteQuantity);

    if (finiteQuantity) {
      const minimumQuantity = this.optionForm.get('minimumQuantity') as FormControl;
      const maximumQuantity = this.optionForm.get('maximumQuantity') as FormControl;
      const quantityIncrement = this.optionForm.get('quantityIncrement') as FormControl;
      const quantityUnit = this.optionForm.get('quantityUnit') as FormControl;

      this.namedQty = defaultQuantity.value;
      if (value === 'one') {
        this.finiteQty = 1;
        minimumQuantity.setValue(1);
        maximumQuantity.setValue(1);
        quantityIncrement.setValue(1);
        defaultQuantity.setValue(1);
        quantityUnit.setValue('');

        minimumQuantity.disable();
        maximumQuantity.disable();
        quantityIncrement.disable();
        defaultQuantity.disable();
        quantityUnit.disable();

      } else if (this.finiteQty !== undefined) {
        defaultQuantity.setValue(this.finiteQty);

        minimumQuantity.enable();
        maximumQuantity.enable();
        quantityIncrement.enable();
        defaultQuantity.enable();
        quantityUnit.enable();
      }
    } else {
      defaultQuantity.enable();
      this.finiteQty = defaultQuantity.value;
      this.clampDefaultQuantity(this.namedQty);
    }
    this.showFiniteQty = finiteQuantity;
    this.optionForm.markAsDirty();
  }

  addChoice(count = 1): void {
    const choices = this.optionForm.get('choices') as FormArray;
    for (let i = 0; i < count; i++) {
      choices.push(this.formBuilder.group({ name: '', price: '0' }));
    }
    choices.markAsDirty();
  }

  deleteChoice(index: number): void {
    const choices = this.optionForm.get('choices') as FormArray;
    choices.removeAt(index);
    choices.markAsDirty();
  }

  save(): void {
    this.loadingIndicatorService.show();

    const menuOptionData: MenuOptionCreateDto = this.optionForm.getRawValue();
    if (!menuOptionData.finiteQuantity) {
      menuOptionData.minimumQuantity = 0;
      menuOptionData.maximumQuantity = menuOptionData.quantityNames.length - 1;
    }

    const { id, menuId, companyId } = this.loadOption;

    (id === undefined ?
      this.menuOptionService.create(companyId, menuId, { ...menuOptionData, tags: this.tags }) :
      this.menuOptionService.update(companyId, menuId, id, { ...menuOptionData, tags: this.tags })
    ).subscribe(
      data => {
        const result: EditOptionResult = {
          option: data,
          new: id === undefined,
        };
        this.loadingIndicatorService.dismiss();
        this.dialogRef.close(result);
      },
      error => {
        this.loadingIndicatorService.dismiss();
        let details: string = error;

        try {
          details = JSON.stringify(error, null, 2);
        } catch (error) {
          const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
          this.telemetryService.logError(errorMessage);
        }

        this.errorDialogService.show({
          title: 'Failed to create menu option',
          message: 'Unable to create a menu option at this time. Please try again later. If this problem persists please let us know.',
          details,
        });
      });
  }

  private initForm(): void {
    const option = this.option;
    const menuOptionData = {
      id: option.id,
      name: option.name,
      finiteQuantity: option.finiteQuantity,
      minimumQuantity: option.minimumQuantity,
      maximumQuantity: option.maximumQuantity,
      defaultQuantity: option.defaultQuantity,
      quantityUnit: option.quantityUnit || '',
      quantityIncrement: option.quantityIncrement,
      quantityNames: this.formBuilder.array(option.quantityNames ? option.quantityNames : []),
      choices: this.formBuilder.array(option.choices ? option.choices.map(choice => this.formBuilder.group({
        id: choice.id,
        name: choice.name,
        price: choice.price,
      })) : []),
    };
    this.optionForm = this.reactiveFormService.createModelValidatedForm(
      SWAGGER_SCHEMA_DEFINITIONS.MenuOptionCreateDto, menuOptionData, [this.formValidator.bind(this)],
    );
    this.getError = this.reactiveFormService.createErrorAccessor(this.optionForm);

    this.showFiniteQty = option.finiteQuantity;
    if (option.finiteQuantity) {
      this.finiteQty = option.defaultQuantity;
      this.quantityTypeToggleValue =
        option.minimumQuantity === 1 && option.maximumQuantity === 1 && option.defaultQuantity === 1 ?
          'one' : 'finite';
      if (this.quantityTypeToggleValue === 'one') {
        setTimeout(() => this.quantityTypeChange({ value: 'one' }), 0);
      }
    } else {
      this.namedQty = option.defaultQuantity;
      this.quantityTypeToggleValue = 'named';
    }

  }

  private formValidator(control: FormGroup): FieldErrorMap {
    const fieldErrorMap: FieldErrorMap = {};

    // const finiteQty = control.get('finiteQuantity').value;

    // const choses = control.get('choices') as FormArray;
    // if (choses.length < 1) {
    //   addFieldError(choses, 'choices', [{ name: 'invalid_choices', message: 'Must have at least 1 choice' }], fieldErrorMap);
    //   return {};
    // }

    // if (finiteQty) {
    //   const minQty = control.get('minimumQuantity');
    //   const maxQty = control.get('maximumQuantity');
    //   if (minQty.value > maxQty.value) {
    //     addFieldError(minQty, 'minimumQuantity', [{ name: 'invalid_qty', message: 'Min greater than max' }], fieldErrorMap);
    //   }

    //   const defaultQty = control.get('defaultQuantity');
    //   if (defaultQty.value < 1) {
    //     addFieldError(defaultQty, 'defaultQuantity',
    //       [{ name: 'invalid_default_qty', message: 'Default quantity cannot be less than 1.' }], fieldErrorMap);
    //   }
    // } else {
    //   const qtyNames = control.get('quantityNames') as FormArray;
    //   if (qtyNames.length < 1) {
    //     addFieldError(qtyNames, `quantityNames`,
    //       [{ name: 'invalid_default_qty', message: 'Must have at least 1 quantity name.' }], fieldErrorMap);
    //   }

    //   const qtyNameErrors = qtyNames.value.forEach((qty, i) => {
    //     if (qty.length < 1) {
    //       const qtyField = qtyNames.at(i);
    //       addFieldError(qtyField, `quantityNames.${i}`,
    //         [{ name: 'invalid_qty_name', message: 'Quantity names cannot be blank.' }], fieldErrorMap);
    //     }
    //   });
    // }
    return fieldErrorMap;
    // this.errorMessage = undefined;
  }

  private clampDefaultQuantity(value?: number): void {
    const quantityNames = this.optionForm.get('quantityNames') as FormArray;
    const defaultQuantity = this.optionForm.get('defaultQuantity') as FormControl;
    const defaultValue = value === undefined ? defaultQuantity.value : value;
    defaultQuantity.setValue(Math.min(defaultValue, Math.max(0, quantityNames.length - 1)));
  }

}
