import { Injectable, Injector, NgZone } from '@angular/core';
import { merge as _merge } from 'lodash';

import { ErrorDialogService, ErrorOptions } from '../../packages/ui-error-dialog';
import { ApplicationError, ErrorNames } from '../../errors/application-error';
import { EnvironmentService } from '../environment/environment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IdentityService } from '../identity/identity.service';
import { Router } from '@angular/router';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';

@Injectable()
export class GlobalErrorHandlerService {
  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private telemetryService: TelemetryService,
  ) {
  }

  handleError(error: any): void {
    const errorDialogService = this.injector.get(ErrorDialogService);
    const environmentService = this.injector.get(EnvironmentService);
    const identityService = this.injector.get(IdentityService);
    const router = this.injector.get(Router);

    const isMerchantMode = router.url.startsWith('/m');

    // unwrap promise rejection errors (resolver errors get wrapped like this)
    if (error.rejection) {
      error = error.rejection;
    }

    const dialogData: ErrorOptions = {
      title: 'Unknown Error',
      message: error && error.message || 'An unknown error occurred',
      details: error && error.stack,
    };

    let showErrorDialog = true;

    let errorData: any = {};

    // unwrap ApplicationError
    if (error instanceof ApplicationError) {
      errorData = error.getData();
      error = errorData.details;

      showErrorDialog = !errorData.suppressDialog;
    }

    // handle Angular HttpErrorResponse
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {

        case 401: // probably an invalid or expired token
          identityService.logout();

          // MERCHANT ONLY - redirect to login page
          if (isMerchantMode) {
            // must be in zone to navigate
            this.ngZone.run(() => router.navigate(['/login']));
          }
          return;

        case 403: // user is logged in, but lacks the role for this action
          errorData.name = ErrorNames.DATA_ACCESS;
          errorData.message = 'You do not have access to this resource.';
          errorData.retryAction = () => history.back();
          break;

        default:
          errorData.name = ErrorNames.DATA_ACCESS;
          errorData.message = error.status + ' - ' + error.statusText;
          errorData.details = {
            message: error.message,
            headers: error.headers.keys().map(k => error.headers.get(k)),
            error: error.error,
            status: error.status,
            url: error.url,
          };
      }
    }

    this.telemetryService.logError('Global Error: ' + (errorData.message || error), true);

    // suppress error dialog for consumer mode
    if (isMerchantMode && showErrorDialog) {
      _merge(dialogData, {
        title: errorData.name,
        message: errorData.message,
        details: safeStringify(errorData.details, 2),
        retryButtonAction: errorData.retryAction,
      });

      // must be run in explicit zone to update view
      this.ngZone.run(() => errorDialogService.show(dialogData));
    }

    // re-throw errors for debuging in non-production environments
    if (!environmentService.isProduction()) {
      throw error;
    } else {
      // tslint:disable-next-line
      console.log('dialog error', error);
    }
  }
}

// TODO: maybe move this somewhere shared

/**
 * Stringify an object and ignore circular dependencies
 */
function safeStringify(input: object, spaces?: string | number): string {
  const references = new Set();
  return JSON.stringify(input, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (references.has(value)) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our set
      references.add(value);
    }
    return value;
  }, spaces);
}
