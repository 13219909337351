import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemSizeChipComponent } from './components/menu-item-size-chip/menu-item-size-chip.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCurrencyInputModule } from 'src/app/shared/packages/mat-currency-input';
import { MatInputModule } from '@angular/material';

export const MATERIAL_IMPORTS = [
  MatInputModule,
  // custom
  MatCurrencyInputModule,
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    MenuItemSizeChipComponent,
  ],
  exports: [
    MenuItemSizeChipComponent,
  ],
})
export class MenuItemSizeChipModule { }
