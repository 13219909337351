import { Injectable } from '@angular/core';
import { Sim } from '@ionic-native/sim';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { TelemetryService } from '../../../telemetry/services/telemetry/telemetry.service';
import { NativeEnvironmentService } from '../native-environment/native-environment.service';

interface SimInfo {
  phoneNumber: string;
}

@Injectable()
export class NativeSimInfoService {

  constructor(
    private readonly sim: Sim,
    private readonly nativeEnvironmentService: NativeEnvironmentService,
    private telemetryService: TelemetryService,
  ) { }

  async tryGetPhoneNumber(): Promise<string> {
    if (!this.sim || !(<any>window).plugins || !(<any>window).plugins.sim) {
      return Promise.resolve(undefined);
    }

    try {
      // iOS does not expose device phone number
      const platform = await this.nativeEnvironmentService.getPlatform();
      if (platform !== 'android') {
        return Promise.resolve(undefined);
      }

      const hasPermission = await this.sim.hasReadPermission();
      if (!hasPermission) {
        await this.sim.requestReadPermission();
      }
      const info: SimInfo = await this.sim.getSimInfo();
      return info && info.phoneNumber;
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
      return;
    }
  }
}
