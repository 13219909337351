import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLimitation',
})
export class TextLimitationPipe implements PipeTransform {

  transform(text: string, limit = 100): string {
    if (!text) { return; }
    if (text.length < limit) { return text; }

    const lastSpaceIndex = text.lastIndexOf(' ', limit);
    return text.slice(0, lastSpaceIndex === -1 ? limit : lastSpaceIndex) + '...';
  }

}
