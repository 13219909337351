import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { BottombarService } from '../../services/bottombar/bottombar.service';

@Component({
  selector: 'bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
})
export class BottombarComponent implements OnInit, OnDestroy {
  bottombar: TemplateRef<any>;
  componentActive: boolean;
  show: boolean;

  constructor(
    private bottombarService: BottombarService,
  ) { }

  public ngOnInit(): void {
    this.componentActive = true;
    this.bottombarService.watchTemplate().pipe(takeWhile(() => this.componentActive))
      .subscribe(templateRef => {
        this.bottombar = templateRef;
        this.show = this.bottombar === undefined ? false : true;
      });
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }
}
