var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InjectionToken } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { of as __of } from 'rxjs';
import { map as __map, filter as __filter, switchMap as __switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var ApiServiceTokenProviderInjectionToken = new InjectionToken('ApiServiceTokenProviderInjectionToken');
var ApiService = (function (_super) {
    __extends(ApiService, _super);
    function ApiService(config, http, tokenProvider) {
        var _this = _super.call(this, config, http) || this;
        _this.tokenProvider = function (_) { return __of(null); };
        if (tokenProvider) {
            _this.tokenProvider = function (v) { return tokenProvider.getToken(v); };
        }
        return _this;
    }
    ApiService.prototype.statusGetResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.statusGet = function () {
        return this.statusGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storesGetNearbyPaginatedResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.storeName != null)
            __params = __params.set('storeName', params.storeName.toString());
        if (params.resultCount != null)
            __params = __params.set('resultCount', params.resultCount.toString());
        if (params.postalCode != null)
            __params = __params.set('postalCode', params.postalCode.toString());
        if (params.pageKey != null)
            __params = __params.set('pageKey', params.pageKey.toString());
        if (params.maxDistance != null)
            __params = __params.set('maxDistance', params.maxDistance.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/nearby/paginated/" + encodeURIComponent(params.lat) + "/" + encodeURIComponent(params.lon)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storesGetNearbyPaginated = function (params) {
        return this.storesGetNearbyPaginatedResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storesGetNearbyResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.storeName != null)
            __params = __params.set('storeName', params.storeName.toString());
        if (params.resultOffset != null)
            __params = __params.set('resultOffset', params.resultOffset.toString());
        if (params.postalCode != null)
            __params = __params.set('postalCode', params.postalCode.toString());
        if (params.maxResults != null)
            __params = __params.set('maxResults', params.maxResults.toString());
        if (params.maxDistance != null)
            __params = __params.set('maxDistance', params.maxDistance.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/nearby/" + encodeURIComponent(params.lat) + "/" + encodeURIComponent(params.lon)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storesGetNearby = function (params) {
        return this.storesGetNearbyResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeIsAvailableForOrdersResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/available-for-order"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeIsAvailableForOrders = function (storeId) {
        return this.storeIsAvailableForOrdersResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetDealsResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/deal"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetDeals = function (storeId) {
        return this.storeGetDealsResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storePutDealResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.ItemDealDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/deal"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storePutDeal = function (params) {
        return this.storePutDealResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeDeleteDealResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/deal/" + encodeURIComponent(params.dealId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeDeleteDeal = function (params) {
        return this.storeDeleteDealResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetDetailsResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/details"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetDetails = function (storeId) {
        return this.storeGetDetailsResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetMenuCategorySectionsWithInventoryResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/menu-category-sections-with-inventory/" + encodeURIComponent(params.categoryId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetMenuCategorySectionsWithInventory = function (params) {
        return this.storeGetMenuCategorySectionsWithInventoryResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetMenuV2Response = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.includeSections != null)
            __params = __params.set('includeSections', params.includeSections.toString());
        if (params.includeMenuOptions != null)
            __params = __params.set('includeMenuOptions', params.includeMenuOptions.toString());
        if (params.includeItemOptions != null)
            __params = __params.set('includeItemOptions', params.includeItemOptions.toString());
        if (params.includeInventory != null)
            __params = __params.set('includeInventory', params.includeInventory.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/menu/v2"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetMenuV2 = function (params) {
        return this.storeGetMenuV2Response(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetMenuV3Response = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.includeStoreHidden != null)
            __params = __params.set('includeStoreHidden', params.includeStoreHidden.toString());
        if (params.includeSections != null)
            __params = __params.set('includeSections', params.includeSections.toString());
        if (params.includeMenuOptions != null)
            __params = __params.set('includeMenuOptions', params.includeMenuOptions.toString());
        if (params.includeItemOptions != null)
            __params = __params.set('includeItemOptions', params.includeItemOptions.toString());
        if (params.includeInventory != null)
            __params = __params.set('includeInventory', params.includeInventory.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/menu/v3"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetMenuV3 = function (params) {
        return this.storeGetMenuV3Response(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetMenuResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/menu"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetMenu = function (storeId) {
        return this.storeGetMenuResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetSeoListResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/store/list-for-blog-seo", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetSeoList = function () {
        return this.storeGetSeoListResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeListResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/store", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeList = function () {
        return this.storeListResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeCreateResponse = function (StoreDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = StoreDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/store", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeCreate = function (StoreDto) {
        return this.storeCreateResponse(StoreDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetOfflineReportResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreOfflineReportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/offline-report"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetOfflineReport = function (params) {
        return this.storeGetOfflineReportResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeDashboardResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.date != null)
            __params = __params.set('date', params.date.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/dashboard"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeDashboard = function (params) {
        return this.storeDashboardResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storePutAmenitiesResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreAmenitiesDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/amenities"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storePutAmenities = function (params) {
        return this.storePutAmenitiesResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeFindDetailsResponse = function (GPlacesQueryParamsDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = GPlacesQueryParamsDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/store/find", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeFindDetails = function (GPlacesQueryParamsDto) {
        return this.storeFindDetailsResponse(GPlacesQueryParamsDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeFindHoursByPlaceIdResponse = function (GPlacesPlaceIdDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = GPlacesPlaceIdDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/store/find/hours", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeFindHoursByPlaceId = function (GPlacesPlaceIdDto) {
        return this.storeFindHoursByPlaceIdResponse(GPlacesPlaceIdDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.updateStoreHoursResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreHoursRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/set-hours"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.updateStoreHours = function (params) {
        return this.updateStoreHoursResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeValidatePhoneResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/validate-phone"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeValidatePhone = function (storeId) {
        return this.storeValidatePhoneResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeValidatePhoneCheckStatusResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/validate-phone"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeValidatePhoneCheckStatus = function (storeId) {
        return this.storeValidatePhoneCheckStatusResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeSetAlwaysOnlineResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreSetAlwaysOnlineDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/set-always-online"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeSetAlwaysOnline = function (params) {
        return this.storeSetAlwaysOnlineResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeSetSquareLocationIdResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreSquareLocationIdDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/set-location-id"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeSetSquareLocationId = function (params) {
        return this.storeSetSquareLocationIdResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeSetMinimumWaitTimeResponse = function (StoreSetMinimumWaitTimeDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = StoreSetMinimumWaitTimeDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/store/set-minimum-wait-time", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeSetMinimumWaitTime = function (StoreSetMinimumWaitTimeDto) {
        return this.storeSetMinimumWaitTimeResponse(StoreSetMinimumWaitTimeDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeClearSquareLocationIdResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/clear-location-id"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeClearSquareLocationId = function (storeId) {
        return this.storeClearSquareLocationIdResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeDisconnectSquareIntegrationResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/disconnect-square-integration"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeDisconnectSquareIntegration = function (storeId) {
        return this.storeDisconnectSquareIntegrationResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeValidatePhoneTwilioInboundWebHookResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.Digits != null)
            __params = __params.set('Digits', params.Digits.toString());
        if (params.Called != null)
            __params = __params.set('Called', params.Called.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/store/validate-phone-twilio-hook", __body, {
                headers: __headers,
                params: __params,
                responseType: 'text'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeValidatePhoneTwilioInboundWebHook = function (params) {
        return this.storeValidatePhoneTwilioInboundWebHookResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeUpdateV2Response = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreUpdateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/v2"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeUpdateV2 = function (params) {
        return this.storeUpdateV2Response(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetV2Response = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/v2"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGetV2 = function (storeId) {
        return this.storeGetV2Response(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeGetResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeGet = function (storeId) {
        return this.storeGetResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeUpdateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreUpdateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeUpdate = function (params) {
        return this.storeUpdateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.getOrdersByStoreResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.status != null)
            __params = __params.set('status', params.status.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/orders"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.getOrdersByStore = function (params) {
        return this.getOrdersByStoreResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeInviteUserResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreCreateInviteRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/invite"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeInviteUser = function (params) {
        return this.storeInviteUserResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeAddImageFromIdResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreAddImageRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/add-image-from-id"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeAddImageFromId = function (params) {
        return this.storeAddImageFromIdResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeAddPickupLocationImageFromIdResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StorePickupInstructionsLocationImageDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/add-pickup-location-image-from-id"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeAddPickupLocationImageFromId = function (params) {
        return this.storeAddPickupLocationImageFromIdResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeAddImageResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var __formData = new FormData();
        __body = __formData;
        if (params.y != null)
            __params = __params.set('y', params.y.toString());
        if (params.x != null)
            __params = __params.set('x', params.x.toString());
        if (params.width != null)
            __params = __params.set('width', params.width.toString());
        if (params.scale != null)
            __params = __params.set('scale', params.scale.toString());
        if (params.height != null)
            __params = __params.set('height', params.height.toString());
        if (params.file != null) {
            __formData.append('file', params.file);
        }
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/add-image"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeAddImage = function (params) {
        return this.storeAddImageResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeReportSubscriberListResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/store/" + encodeURIComponent(storeId) + "/report-subscriber"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeReportSubscriberList = function (storeId) {
        return this.storeReportSubscriberListResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeReportSubscriberCreateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreReportSubscriberDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/report-subscriber"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeReportSubscriberCreate = function (params) {
        return this.storeReportSubscriberCreateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeReportSubscriberDeleteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/store/" + encodeURIComponent(params.storeId) + "/report-subscriber/" + encodeURIComponent(params.subscriberId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeReportSubscriberDelete = function (params) {
        return this.storeReportSubscriberDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeItemInventoryGetOutOfStockCountResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/store-item-inventory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeItemInventoryGetOutOfStockCount = function () {
        return this.storeItemInventoryGetOutOfStockCountResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeSetItemInventoryResponse = function (StoreItemInventoryStatusDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = StoreItemInventoryStatusDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/store-item-inventory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeSetItemInventory = function (StoreItemInventoryStatusDto) {
        return this.storeSetItemInventoryResponse(StoreItemInventoryStatusDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeAddItemInventoryResponse = function (StoreItemInventoryRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = StoreItemInventoryRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/store-item-inventory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeAddItemInventory = function (StoreItemInventoryRequestDto) {
        return this.storeAddItemInventoryResponse(StoreItemInventoryRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeDeleteItemInventoryResponse = function (itemInventoryId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/store-item-inventory/" + encodeURIComponent(itemInventoryId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeDeleteItemInventory = function (itemInventoryId) {
        return this.storeDeleteItemInventoryResponse(itemInventoryId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeOptionInventoryGetOutOfStockCountResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/store-option-inventory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeOptionInventoryGetOutOfStockCount = function () {
        return this.storeOptionInventoryGetOutOfStockCountResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeSetOptionInventoryResponse = function (StoreOptionInventoryStatusDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = StoreOptionInventoryStatusDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/store-option-inventory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeSetOptionInventory = function (StoreOptionInventoryStatusDto) {
        return this.storeSetOptionInventoryResponse(StoreOptionInventoryStatusDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderListPaginatedResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        if (params.storeId != null)
            __params = __params.set('storeId', params.storeId.toString());
        if (params.pageKey != null)
            __params = __params.set('pageKey', params.pageKey.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/order/paginated", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderListPaginated = function (params) {
        return this.orderListPaginatedResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderGetResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/order/" + encodeURIComponent(orderId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderGet = function (orderId) {
        return this.orderGetResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderGetDetailedResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/order/detailed/" + encodeURIComponent(orderId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderGetDetailed = function (orderId) {
        return this.orderGetDetailedResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderGetReorderResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/order/reorder/" + encodeURIComponent(orderId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderGetReorder = function (orderId) {
        return this.orderGetReorderResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderListResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (storeId != null)
            __params = __params.set('storeId', storeId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/order", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderList = function (storeId) {
        return this.orderListResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderCreateResponse = function (OrderCreateRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = OrderCreateRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/order", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderCreate = function (OrderCreateRequestDto) {
        return this.orderCreateResponse(OrderCreateRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderCreateV2Response = function (OrderCreateRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = OrderCreateRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/order/v2", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderCreateV2 = function (OrderCreateRequestDto) {
        return this.orderCreateV2Response(OrderCreateRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderCreateV3Response = function (OrderCreateRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = OrderCreateRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/order/v3", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderCreateV3 = function (OrderCreateRequestDto) {
        return this.orderCreateV3Response(OrderCreateRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderCustomerArrivedResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/order/" + encodeURIComponent(orderId) + "/customer-arrived"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderCustomerArrived = function (orderId) {
        return this.orderCustomerArrivedResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderReviewResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.OrderReviewDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/order/" + encodeURIComponent(params.orderId) + "/review"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderReview = function (params) {
        return this.orderReviewResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderPricingComputeResponse = function (OrderDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = OrderDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/order/pricing", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderPricingCompute = function (OrderDto) {
        return this.orderPricingComputeResponse(OrderDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderPricingComputeWithSourceTypeResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.OrderDto;
        if (params.paymentSourceType != null)
            __params = __params.set('paymentSourceType', params.paymentSourceType.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/order/pricing-with-source-type", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderPricingComputeWithSourceType = function (params) {
        return this.orderPricingComputeWithSourceTypeResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderCustomerPickupOrderResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/order/" + encodeURIComponent(orderId) + "/customer-pickup-order"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderCustomerPickupOrder = function (orderId) {
        return this.orderCustomerPickupOrderResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderRefundResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.RefundOrderRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/order/" + encodeURIComponent(params.orderId) + "/refund"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderRefund = function (params) {
        return this.orderRefundResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderArchiveResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/order/" + encodeURIComponent(orderId) + "/archive"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderArchive = function (orderId) {
        return this.orderArchiveResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderAcknowledgeResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.AcknowledgeOrderRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/order/" + encodeURIComponent(params.orderId) + "/acknowledge"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderAcknowledge = function (params) {
        return this.orderAcknowledgeResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderSetWaitTimeResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.OrderSetWaitTimeRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/order/" + encodeURIComponent(params.orderId) + "/wait-time"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderSetWaitTime = function (params) {
        return this.orderSetWaitTimeResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderScheduleAlertAcknowledgedResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/order/" + encodeURIComponent(orderId) + "/acknowledge-schedule-alert"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderScheduleAlertAcknowledged = function (orderId) {
        return this.orderScheduleAlertAcknowledgedResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderGetDetailedPosResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/order/detailed/" + encodeURIComponent(orderId) + "/for-pos"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderGetDetailedPos = function (orderId) {
        return this.orderGetDetailedPosResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderCreateForPosResponse = function (OrderCreateRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = OrderCreateRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/order/for-pos", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderCreateForPos = function (OrderCreateRequestDto) {
        return this.orderCreateForPosResponse(OrderCreateRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderItemSetStatusResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.OrderItemSetStatusRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/order/" + encodeURIComponent(params.orderId) + "/item/" + encodeURIComponent(params.orderItemId) + "/status"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderItemSetStatus = function (params) {
        return this.orderItemSetStatusResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.orderUserPendingOrderClearResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/order/pending-order-at-store/" + encodeURIComponent(storeId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.orderUserPendingOrderClear = function (storeId) {
        return this.orderUserPendingOrderClearResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.createJoeGiftCardResponse = function (JoeGiftCardCreateRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = JoeGiftCardCreateRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/payment/joe-gift-card", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.createJoeGiftCard = function (JoeGiftCardCreateRequestDto) {
        return this.createJoeGiftCardResponse(JoeGiftCardCreateRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.joebucksUploadFundsResponse = function (UploadJoebucksRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UploadJoebucksRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/payment/joebucks/upload-funds", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.joebucksUploadFunds = function (UploadJoebucksRequestDto) {
        return this.joebucksUploadFundsResponse(UploadJoebucksRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.joebucksCheckBalanceResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/payment/joebucks/balance", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.joebucksCheckBalance = function () {
        return this.joebucksCheckBalanceResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.joebucksUploadsHistoryResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        if (params.pageKey != null)
            __params = __params.set('pageKey', params.pageKey.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/payment/joebucks/history", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.joebucksUploadsHistory = function (params) {
        return this.joebucksUploadsHistoryResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.authorizeBankAccountResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (userId != null)
            __params = __params.set('userId', userId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/payment/bank-accounts/authorize", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.authorizeBankAccount = function (userId) {
        return this.authorizeBankAccountResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.storeUserBankAccountResponse = function (UserBankAccountDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserBankAccountDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/payment/bank-accounts/user-bank-account", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.storeUserBankAccount = function (UserBankAccountDto) {
        return this.storeUserBankAccountResponse(UserBankAccountDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.deleteBankAccountResponse = function (PaymentMethodDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PaymentMethodDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/payment/bank-accounts/delete", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.deleteBankAccount = function (PaymentMethodDto) {
        return this.deleteBankAccountResponse(PaymentMethodDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.cloverInventoryListResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/clover-inventory/store/" + encodeURIComponent(storeId) + "/inventory"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.cloverInventoryList = function (storeId) {
        return this.cloverInventoryListResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.cloverInventoryAssociationResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.StoreItemInventoryRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/clover-inventory/store/" + encodeURIComponent(params.storeId) + "/inventory/" + encodeURIComponent(params.cloverItemId) + "/associate"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.cloverInventoryAssociation = function (params) {
        return this.cloverInventoryAssociationResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.cloverInventoryWebhookResponse = function (xCloverAuth) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (xCloverAuth != null)
            __headers = __headers.set('x-clover-auth', xCloverAuth.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/clover-inventory/webhook", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.cloverInventoryWebhook = function (xCloverAuth) {
        return this.cloverInventoryWebhookResponse(xCloverAuth).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.cloverAuthAuthorizationResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CloverAuthorizationRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/clover-auth/store/" + encodeURIComponent(params.storeId) + "/authorization"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.cloverAuthAuthorization = function (params) {
        return this.cloverAuthAuthorizationResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.cloverAuthRevokeResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/clover-auth/store/" + encodeURIComponent(storeId) + "/revoke"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.cloverAuthRevoke = function (storeId) {
        return this.cloverAuthRevokeResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.squareAuthorizationRedirectEndpointResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.state != null)
            __params = __params.set('state', params.state.toString());
        if (params.code != null)
            __params = __params.set('code', params.code.toString());
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('GET', _this.rootUrl + "/square/authorization", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.squareAuthorizationRedirectEndpoint = function (params) {
        return this.squareAuthorizationRedirectEndpointResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.squareGetAllLocationsResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/square/" + encodeURIComponent(storeId) + "/get-square-locations"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.squareGetAllLocations = function (storeId) {
        return this.squareGetAllLocationsResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.authLoginResponse = function (AuthLoginRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthLoginRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/login", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.authLogin = function (AuthLoginRequestDto) {
        return this.authLoginResponse(AuthLoginRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.authSignupResponse = function (AuthSignupRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthSignupRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/signup", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.authSignup = function (AuthSignupRequestDto) {
        return this.authSignupResponse(AuthSignupRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userAuthRefreshResponse = function (AuthRefreshRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthRefreshRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/refresh", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userAuthRefresh = function (AuthRefreshRequestDto) {
        return this.userAuthRefreshResponse(AuthRefreshRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceRefreshResponse = function (AuthRefreshRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthRefreshRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/refresh-merchant", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceRefresh = function (AuthRefreshRequestDto) {
        return this.merchantDeviceRefreshResponse(AuthRefreshRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.consumerSignupResponse = function (ConsumerSignupRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ConsumerSignupRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/consumerSignup", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.consumerSignup = function (ConsumerSignupRequestDto) {
        return this.consumerSignupResponse(ConsumerSignupRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.validateUserResponse = function (ValidateUserRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ValidateUserRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/validateUser", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.validateUser = function (ValidateUserRequestDto) {
        return this.validateUserResponse(ValidateUserRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.authChangePasswordResponse = function (AuthChangePasswordRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthChangePasswordRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/change-password", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.authChangePassword = function (AuthChangePasswordRequestDto) {
        return this.authChangePasswordResponse(AuthChangePasswordRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.authChangePasswordWithCodeResponse = function (AuthChangePasswordWithCodeRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthChangePasswordWithCodeRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/change-password-with-code", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.authChangePasswordWithCode = function (AuthChangePasswordWithCodeRequestDto) {
        return this.authChangePasswordWithCodeResponse(AuthChangePasswordWithCodeRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.authResetPasswordResponse = function (AuthResetPasswordRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthResetPasswordRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/reset-password", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.authResetPassword = function (AuthResetPasswordRequestDto) {
        return this.authResetPasswordResponse(AuthResetPasswordRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.createAppCodeResponse = function (AppCodeCreateDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AppCodeCreateDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/app-code", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.createAppCode = function (AppCodeCreateDto) {
        return this.createAppCodeResponse(AppCodeCreateDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.redeemAppCodeResponse = function (code) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/auth/app-code/" + encodeURIComponent(code) + "/redeem"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.redeemAppCode = function (code) {
        return this.redeemAppCodeResponse(code).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.checkAppCodeRedeemedResponse = function (code) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('GET', _this.rootUrl + ("/auth/app-code/" + encodeURIComponent(code) + "/status"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.checkAppCodeRedeemed = function (code) {
        return this.checkAppCodeRedeemedResponse(code).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.appCodeAuthenticateResponse = function (AppCodeAuthenticateRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AppCodeAuthenticateRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/app-code/authenticate", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.appCodeAuthenticate = function (AppCodeAuthenticateRequestDto) {
        return this.appCodeAuthenticateResponse(AppCodeAuthenticateRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.oauthAuthObtainTokenResponse = function (AuthTokenObtainRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthTokenObtainRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/oauth/token", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.oauthAuthObtainToken = function (AuthTokenObtainRequestDto) {
        return this.oauthAuthObtainTokenResponse(AuthTokenObtainRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.oauthAuthRevokeTokenResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + "/auth/oauth/token", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.oauthAuthRevokeToken = function () {
        return this.oauthAuthRevokeTokenResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.oauthAuthAuthorizeDirectResponse = function (AuthTokenAuthorizeDirectRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AuthTokenAuthorizeDirectRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/auth/oauth/authorize/direct", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.oauthAuthAuthorizeDirect = function (AuthTokenAuthorizeDirectRequestDto) {
        return this.oauthAuthAuthorizeDirectResponse(AuthTokenAuthorizeDirectRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.oauthAuthAuthorizeDirectAuthenticateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.AuthTokenAuthorizeDirectAuthenticateRequestDto;
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + ("/auth/oauth/authorize/direct/" + encodeURIComponent(params.authenticationRequestToken) + "/authenticate"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.oauthAuthAuthorizeDirectAuthenticate = function (params) {
        return this.oauthAuthAuthorizeDirectAuthenticateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetProfileBasicResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/profile-basic", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetProfileBasic = function () {
        return this.userGetProfileBasicResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetProfileResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/profile", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetProfile = function () {
        return this.userGetProfileResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userUpdateNameResponse = function (UserUpdateNameRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserUpdateNameRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/user/name", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userUpdateName = function (UserUpdateNameRequestDto) {
        return this.userUpdateNameResponse(UserUpdateNameRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userAddEmailPasswordResponse = function (UserAddEmailPasswordDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserAddEmailPasswordDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/user/add-email-password", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userAddEmailPassword = function (UserAddEmailPasswordDto) {
        return this.userAddEmailPasswordResponse(UserAddEmailPasswordDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userSetPhotoResponse = function (UserAddPhotoDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserAddPhotoDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/user/add-photo", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userSetPhoto = function (UserAddPhotoDto) {
        return this.userSetPhotoResponse(UserAddPhotoDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userHasMerchantRolesResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/has-merchant-roles", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userHasMerchantRoles = function () {
        return this.userHasMerchantRolesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userAddCardResponse = function (UserAddPaymentCardDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserAddPaymentCardDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/user/cards", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userAddCard = function (UserAddPaymentCardDto) {
        return this.userAddCardResponse(UserAddPaymentCardDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetCardsResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/cards", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetCards = function () {
        return this.userGetCardsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userRemoveOneCardResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.fake != null)
            __params = __params.set('fake', params.fake.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/user/cards/" + encodeURIComponent(params.cardId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userRemoveOneCard = function (params) {
        return this.userRemoveOneCardResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetReferralShortUrlResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/referral-link", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetReferralShortUrl = function () {
        return this.userGetReferralShortUrlResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userSetReferralShortUrlResponse = function (UserReferralLinkDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserReferralLinkDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/user/referral-link", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userSetReferralShortUrl = function (UserReferralLinkDto) {
        return this.userSetReferralShortUrlResponse(UserReferralLinkDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetReferralStatusesResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/referral-status", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetReferralStatuses = function () {
        return this.userGetReferralStatusesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetByReferralCodeResponse = function (code) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/user/referred-user/" + encodeURIComponent(code)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetByReferralCode = function (code) {
        return this.userGetByReferralCodeResponse(code).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetReferralCodeResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/user/referral-code", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetReferralCode = function () {
        return this.userGetReferralCodeResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetFavoriteStoresResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/favorites/stores", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetFavoriteStores = function () {
        return this.userGetFavoriteStoresResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetInvitesResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/invites", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetInvites = function () {
        return this.userGetInvitesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetReactivationPromoStatusResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/reactivation-status", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetReactivationPromoStatus = function () {
        return this.userGetReactivationPromoStatusResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userGetRewardStatusResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (storeId != null)
            __params = __params.set('storeId', storeId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/user/rewards", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userGetRewardStatus = function (storeId) {
        return this.userGetRewardStatusResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.userAcknowledgeMerchantInviteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.UserAcknowledgeMerchantInviteRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/user/invite/" + encodeURIComponent(params.inviteId) + "/acknowledge"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.userAcknowledgeMerchantInvite = function (params) {
        return this.userAcknowledgeMerchantInviteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDevicePairWithUserLoginResponse = function (PosDevicePairWithUserLoginRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDevicePairWithUserLoginRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/pair-with-user-login", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDevicePairWithUserLogin = function (PosDevicePairWithUserLoginRequestDto) {
        return this.posDevicePairWithUserLoginResponse(PosDevicePairWithUserLoginRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceGetAllowedStoresFromUserLoginResponse = function (PosDeviceGetAllowedStoresRequest) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceGetAllowedStoresRequest;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/stores-from-user-login", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceGetAllowedStoresFromUserLogin = function (PosDeviceGetAllowedStoresRequest) {
        return this.posDeviceGetAllowedStoresFromUserLoginResponse(PosDeviceGetAllowedStoresRequest).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDevicePairResponse = function (PosDevicePairRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDevicePairRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/pair", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDevicePair = function (PosDevicePairRequestDto) {
        return this.posDevicePairResponse(PosDevicePairRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceSetModeResponse = function (PosDeviceSetModeRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceSetModeRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/pos-device/set-mode", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceSetMode = function (PosDeviceSetModeRequestDto) {
        return this.posDeviceSetModeResponse(PosDeviceSetModeRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceListResponse = function (deviceMode) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (deviceMode != null)
            __params = __params.set('deviceMode', deviceMode.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/pos-device", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceList = function (deviceMode) {
        return this.posDeviceListResponse(deviceMode).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceCreateCardReaderConnectionTokenResponse = function (PosDeviceGetCardReaderTokenRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceGetCardReaderTokenRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/card-reader/connection-token", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceCreateCardReaderConnectionToken = function (PosDeviceGetCardReaderTokenRequestDto) {
        return this.posDeviceCreateCardReaderConnectionTokenResponse(PosDeviceGetCardReaderTokenRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceCreateCardReaderRegisterResponse = function (PosDeviceCardReaderRegisterRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceCardReaderRegisterRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/card-reader/register", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceCreateCardReaderRegister = function (PosDeviceCardReaderRegisterRequestDto) {
        return this.posDeviceCreateCardReaderRegisterResponse(PosDeviceCardReaderRegisterRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceCreateCardReaderPaymentIntentResponse = function (PosDeviceCreatePaymentIntentRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceCreatePaymentIntentRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/card-reader/payment-intent", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceCreateCardReaderPaymentIntent = function (PosDeviceCreatePaymentIntentRequestDto) {
        return this.posDeviceCreateCardReaderPaymentIntentResponse(PosDeviceCreatePaymentIntentRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionSetCurrentOrderResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.PosSessionOrderPutDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/current-order"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionSetCurrentOrder = function (params) {
        return this.posSessionSetCurrentOrderResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionGetCurrentOrderResponse = function (deviceId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/pos-device/" + encodeURIComponent(deviceId) + "/customer-session/current-order"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionGetCurrentOrder = function (deviceId) {
        return this.posSessionGetCurrentOrderResponse(deviceId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionSetCurrentOrderTipAmountResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.PosDeviceSessionSetCurrentOrderTipAmountRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/current-order-tip-amount"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionSetCurrentOrderTipAmount = function (params) {
        return this.posSessionSetCurrentOrderTipAmountResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionClearResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.clearSession != null)
            __params = __params.set('clearSession', params.clearSession.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/clear"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionClear = function (params) {
        return this.posSessionClearResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionGetOrderSessionResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/order-session/" + encodeURIComponent(params.orderSessionId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionGetOrderSession = function (params) {
        return this.posSessionGetOrderSessionResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionDeleteOrderSessionResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/order-session/" + encodeURIComponent(params.orderSessionId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionDeleteOrderSession = function (params) {
        return this.posSessionDeleteOrderSessionResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionSetCurrentCustomerFromPhoneResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.PosDeviceSessionSetCustomerFromPhoneRequestDto;
        if (params.sessionId != null)
            __params = __params.set('sessionId', params.sessionId.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/current-customer-from-phone"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionSetCurrentCustomerFromPhone = function (params) {
        return this.posSessionSetCurrentCustomerFromPhoneResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionGetCurrentCustomerResponse = function (deviceId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/pos-device/" + encodeURIComponent(deviceId) + "/customer-session/current-customer"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionGetCurrentCustomer = function (deviceId) {
        return this.posSessionGetCurrentCustomerResponse(deviceId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionSetCurrentCustomerFromMobileDeviceResponse = function (deviceId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(deviceId) + "/customer-session/current-customer-from-mobile-device"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionSetCurrentCustomerFromMobileDevice = function (deviceId) {
        return this.posSessionSetCurrentCustomerFromMobileDeviceResponse(deviceId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionCustomerGetCurrentOrderResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/store/" + encodeURIComponent(params.storeId) + "/customer-current-order"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionCustomerGetCurrentOrder = function (params) {
        return this.posSessionCustomerGetCurrentOrderResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionCurrentOrderCompleteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.PosSessionOrderCompleteRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/current-order/complete"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionCurrentOrderComplete = function (params) {
        return this.posSessionCurrentOrderCompleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionCustomerTransferOrderToMobileResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/store/" + encodeURIComponent(params.storeId) + "/transfer-order-to-mobile"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionCustomerTransferOrderToMobile = function (params) {
        return this.posSessionCustomerTransferOrderToMobileResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posSessionDevicePendingOrderClearResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/pos-device/" + encodeURIComponent(params.deviceId) + "/customer-session/pending-order-for-user/" + encodeURIComponent(params.userId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posSessionDevicePendingOrderClear = function (params) {
        return this.posSessionDevicePendingOrderClearResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceCashTillOpenResponse = function (PosDeviceCashTillOpenRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceCashTillOpenRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/cash-till/open", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceCashTillOpen = function (PosDeviceCashTillOpenRequestDto) {
        return this.posDeviceCashTillOpenResponse(PosDeviceCashTillOpenRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceCashTillCloseResponse = function (PosDeviceCashTillCloseRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PosDeviceCashTillCloseRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/pos-device/cash-till/close", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceCashTillClose = function (PosDeviceCashTillCloseRequestDto) {
        return this.posDeviceCashTillCloseResponse(PosDeviceCashTillCloseRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceCashTillTransactionSummaryResponse = function (tillId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/pos-device/cash-till/transactions/" + encodeURIComponent(tillId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceCashTillTransactionSummary = function (tillId) {
        return this.posDeviceCashTillTransactionSummaryResponse(tillId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.posDeviceAllTillTransactionSummaryResponse = function (tillId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/pos-device/cash-till/transactions-all/" + encodeURIComponent(tillId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.posDeviceAllTillTransactionSummary = function (tillId) {
        return this.posDeviceAllTillTransactionSummaryResponse(tillId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.reportCreateResponse = function (ReportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ReportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.reportCreate = function (ReportRequestDto) {
        return this.reportCreateResponse(ReportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.reportGetDailySummaryDeviceV2Response = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/report/get-daily-summary-device/" + encodeURIComponent(params.storeId) + "/" + encodeURIComponent(params.date) + "/v2"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.reportGetDailySummaryDeviceV2 = function (params) {
        return this.reportGetDailySummaryDeviceV2Response(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.reportGetDailySummaryDeviceResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/report/get-daily-summary-device/" + encodeURIComponent(params.storeId) + "/" + encodeURIComponent(params.date)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.reportGetDailySummaryDevice = function (params) {
        return this.reportGetDailySummaryDeviceResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.reportGetInternalReportsResponse = function (InternalReportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = InternalReportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/report/internal", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.reportGetInternalReports = function (InternalReportRequestDto) {
        return this.reportGetInternalReportsResponse(InternalReportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDashboardV2Response = function (MerchantDashboardReportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = MerchantDashboardReportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/report/merchant-dashboard-v2", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDashboardV2 = function (MerchantDashboardReportRequestDto) {
        return this.merchantDashboardV2Response(MerchantDashboardReportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminRefundOrderHackThisIsBadResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/refund-order-hack-bad/" + encodeURIComponent(orderId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminRefundOrderHackThisIsBad = function (orderId) {
        return this.adminRefundOrderHackThisIsBadResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetOrderResponse = function (orderId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/get-order/" + encodeURIComponent(orderId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetOrder = function (orderId) {
        return this.adminGetOrderResponse(orderId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetActiveUsersResponse = function (period) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (period != null)
            __params = __params.set('period', period.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/get-active-users", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetActiveUsers = function (period) {
        return this.adminGetActiveUsersResponse(period).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminRefundOrderResponse = function (AdminOrderRefundDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminOrderRefundDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/admin/joe-admin/refund-order", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminRefundOrder = function (AdminOrderRefundDto) {
        return this.adminRefundOrderResponse(AdminOrderRefundDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminFindUsersResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.phone != null)
            __params = __params.set('phone', params.phone.toString());
        if (params.lastName != null)
            __params = __params.set('lastName', params.lastName.toString());
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.firstName != null)
            __params = __params.set('firstName', params.firstName.toString());
        if (params.email != null)
            __params = __params.set('email', params.email.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/find-users", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminFindUsers = function (params) {
        return this.adminFindUsersResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminUpdateUserEmailResponse = function (AdminUserEmailRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminUserEmailRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/update-user-email", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminUpdateUserEmail = function (AdminUserEmailRequestDto) {
        return this.adminUpdateUserEmailResponse(AdminUserEmailRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminFindGiftCardResponse = function (email) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (email != null)
            __params = __params.set('email', email.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/find-gift-card", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminFindGiftCard = function (email) {
        return this.adminFindGiftCardResponse(email).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminFindOrdersResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.timeStart != null)
            __params = __params.set('timeStart', params.timeStart.toString());
        if (params.timeEnd != null)
            __params = __params.set('timeEnd', params.timeEnd.toString());
        if (params.storeId != null)
            __params = __params.set('storeId', params.storeId.toString());
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.customerId != null)
            __params = __params.set('customerId', params.customerId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/find-orders", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminFindOrders = function (params) {
        return this.adminFindOrdersResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetActiveStoresResponse = function (period) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (period != null)
            __params = __params.set('period', period.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/get-active-stores", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetActiveStores = function (period) {
        return this.adminGetActiveStoresResponse(period).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetJoeBucksActivityResponse = function (period) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (period != null)
            __params = __params.set('period', period.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/get-joe-bucks-activity", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetJoeBucksActivity = function (period) {
        return this.adminGetJoeBucksActivityResponse(period).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetReferralHistoryResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/get-referral-history/" + encodeURIComponent(userId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetReferralHistory = function (userId) {
        return this.adminGetReferralHistoryResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSetReferrerResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/set-referrer/" + encodeURIComponent(params.userId) + "/" + encodeURIComponent(params.referringUserId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSetReferrer = function (params) {
        return this.adminSetReferrerResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminBypassStorePhoneVerificationResponse = function (storeId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/store/" + encodeURIComponent(storeId) + "/bypass-phone-verification"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminBypassStorePhoneVerification = function (storeId) {
        return this.adminBypassStorePhoneVerificationResponse(storeId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetOrderTotalsResponse = function (period) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (period != null)
            __params = __params.set('period', period.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/order-totals", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetOrderTotals = function (period) {
        return this.adminGetOrderTotalsResponse(period).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetUserWeeklyCohortTotalsResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.period != null)
            __params = __params.set('period', params.period.toString());
        if (params.endWeek != null)
            __params = __params.set('endWeek', params.endWeek.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/user-weekly-cohort-totals-v2", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetUserWeeklyCohortTotals = function (params) {
        return this.adminGetUserWeeklyCohortTotalsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetStoresWithStatusResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/get-stores-with-status", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetStoresWithStatus = function () {
        return this.adminGetStoresWithStatusResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSearchStoresWithStatusResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.name != null)
            __params = __params.set('name', params.name.toString());
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/search-stores-with-status", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSearchStoresWithStatus = function (params) {
        return this.adminSearchStoresWithStatusResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSendConsumerMarketingUserPushResponse = function (AdminMarketingPushDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminMarketingPushDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/send-consumer-marketing-push", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSendConsumerMarketingUserPush = function (AdminMarketingPushDto) {
        return this.adminSendConsumerMarketingUserPushResponse(AdminMarketingPushDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSendConsumerMarketingOrphanPushResponse = function (AdminMarketingPushDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminMarketingPushDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/send-consumer-marketing-orphan", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSendConsumerMarketingOrphanPush = function (AdminMarketingPushDto) {
        return this.adminSendConsumerMarketingOrphanPushResponse(AdminMarketingPushDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSendConsumerMarketingSinglePushResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.AdminMarketingPushDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/send-consumer-marketing-single/" + encodeURIComponent(params.userId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSendConsumerMarketingSinglePush = function (params) {
        return this.adminSendConsumerMarketingSinglePushResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetMerchantTransfersOldResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/get-merchant-transfers", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetMerchantTransfersOld = function () {
        return this.adminGetMerchantTransfersOldResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetMerchantTransfersResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/get-merchant-transfers/v2", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetMerchantTransfers = function () {
        return this.adminGetMerchantTransfersResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSendMerchantTransferTransferResponse = function (transferId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/send-merchant-transfer-transfer/" + encodeURIComponent(transferId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSendMerchantTransferTransfer = function (transferId) {
        return this.adminSendMerchantTransferTransferResponse(transferId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSendMerchantTransferEmailResponse = function (transferId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/send-merchant-transfer-email/" + encodeURIComponent(transferId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSendMerchantTransferEmail = function (transferId) {
        return this.adminSendMerchantTransferEmailResponse(transferId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSendMerchantTransferEmailTestResponse = function (transferId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/send-merchant-transfer-email-test/" + encodeURIComponent(transferId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSendMerchantTransferEmailTest = function (transferId) {
        return this.adminSendMerchantTransferEmailTestResponse(transferId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminUserRewardStatusGetResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/user/" + encodeURIComponent(userId) + "/rewards"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminUserRewardStatusGet = function (userId) {
        return this.adminUserRewardStatusGetResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminUserBalanceGetResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/user/" + encodeURIComponent(userId) + "/balance"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminUserBalanceGet = function (userId) {
        return this.adminUserBalanceGetResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryCreateResponse = function (AdminCreateNetworkStatusTerritoryDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminCreateNetworkStatusTerritoryDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/network-status-territory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryCreate = function (AdminCreateNetworkStatusTerritoryDto) {
        return this.adminNetworkStatusTerritoryCreateResponse(AdminCreateNetworkStatusTerritoryDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryListResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/network-status-territory", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryList = function () {
        return this.adminNetworkStatusTerritoryListResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryUpdateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.AdminCreateNetworkStatusTerritoryDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/admin/joe-admin/network-status-territory/" + encodeURIComponent(params.territoryId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryUpdate = function (params) {
        return this.adminNetworkStatusTerritoryUpdateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryGetResponse = function (territoryId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/network-status-territory/" + encodeURIComponent(territoryId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryGet = function (territoryId) {
        return this.adminNetworkStatusTerritoryGetResponse(territoryId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryDeleteResponse = function (territoryId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/admin/joe-admin/network-status-territory/" + encodeURIComponent(territoryId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryDelete = function (territoryId) {
        return this.adminNetworkStatusTerritoryDeleteResponse(territoryId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryGetDetailsResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.periodStart != null)
            __params = __params.set('periodStart', params.periodStart.toString());
        if (params.periodEnd != null)
            __params = __params.set('periodEnd', params.periodEnd.toString());
        if (params.includeStores != null)
            __params = __params.set('includeStores', params.includeStores.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/admin/joe-admin/network-status-territory-details/" + encodeURIComponent(params.territoryId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryGetDetails = function (params) {
        return this.adminNetworkStatusTerritoryGetDetailsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryListWithDetailsResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.periodStart != null)
            __params = __params.set('periodStart', params.periodStart.toString());
        if (params.periodEnd != null)
            __params = __params.set('periodEnd', params.periodEnd.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/network-status-territory-details", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminNetworkStatusTerritoryListWithDetails = function (params) {
        return this.adminNetworkStatusTerritoryListWithDetailsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSetStorePosPaymentEnabledResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.AdminSetPosPaymentEnabledDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/store/" + encodeURIComponent(params.storeId) + "/set-pos-payment-enabled"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSetStorePosPaymentEnabled = function (params) {
        return this.adminSetStorePosPaymentEnabledResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSetStoreFeeOverrideResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.AdminSetStoreFeeOverrideDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/store/" + encodeURIComponent(params.storeId) + "/set-fee-override"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSetStoreFeeOverride = function (params) {
        return this.adminSetStoreFeeOverrideResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminSetCompanyApprovalResponse = function (AdminSetCompanyApprovalDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminSetCompanyApprovalDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/set-company-approval", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminSetCompanyApproval = function (AdminSetCompanyApprovalDto) {
        return this.adminSetCompanyApprovalResponse(AdminSetCompanyApprovalDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminMenuTransferToCompanyResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/admin/joe-admin/" + encodeURIComponent(params.menuId) + "/transfer-to/" + encodeURIComponent(params.toCompanyId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminMenuTransferToCompany = function (params) {
        return this.adminMenuTransferToCompanyResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetStoreOfflineReportResponse = function (AdminStoreOfflineReportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminStoreOfflineReportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/store-offline-report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetStoreOfflineReport = function (AdminStoreOfflineReportRequestDto) {
        return this.adminGetStoreOfflineReportResponse(AdminStoreOfflineReportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminMergeUsersResponse = function (AdminMergeUsersRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminMergeUsersRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/user-merge", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminMergeUsers = function (AdminMergeUsersRequestDto) {
        return this.adminMergeUsersResponse(AdminMergeUsersRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminResetUserPasswordResponse = function (AdminResetUserPasswordDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminResetUserPasswordDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/user-reset-password", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminResetUserPassword = function (AdminResetUserPasswordDto) {
        return this.adminResetUserPasswordResponse(AdminResetUserPasswordDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminResetUserResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/user-reset/" + encodeURIComponent(userId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminResetUser = function (userId) {
        return this.adminResetUserResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminRefundRemainingUserBalanceResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/admin/joe-admin/user-refund-remaining-joebucks/" + encodeURIComponent(userId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminRefundRemainingUserBalance = function (userId) {
        return this.adminRefundRemainingUserBalanceResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetGlobalPromosResponse = function (targetType) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (targetType != null)
            __params = __params.set('targetType', targetType.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/joe-admin/get-global-promos", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetGlobalPromos = function (targetType) {
        return this.adminGetGlobalPromosResponse(targetType).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetUserWeeklyCohortTotalsDeprecatedResponse = function (endWeek) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (endWeek != null)
            __params = __params.set('endWeek', endWeek.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/user-weekly-cohort-totals", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetUserWeeklyCohortTotalsDeprecated = function (endWeek) {
        return this.adminGetUserWeeklyCohortTotalsDeprecatedResponse(endWeek).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetUserReactivationCohortsDeprecatedResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/admin/joe-admin/user-reactivation-cohorts", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetUserReactivationCohortsDeprecated = function () {
        return this.adminGetUserReactivationCohortsDeprecatedResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetStoreStatusReportResponse = function (AdminStoreStatusExportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminStoreStatusExportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/store-status-report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetStoreStatusReport = function (AdminStoreStatusExportRequestDto) {
        return this.adminGetStoreStatusReportResponse(AdminStoreStatusExportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetMerchantDeviceReportResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/merchant-device-report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetMerchantDeviceReport = function () {
        return this.adminGetMerchantDeviceReportResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetSquareIntegrationReportResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/square-integration-report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetSquareIntegrationReport = function () {
        return this.adminGetSquareIntegrationReportResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetPromoStatusReportResponse = function (AdminPromoStatusExportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminPromoStatusExportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/promo-status-report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetPromoStatusReport = function (AdminPromoStatusExportRequestDto) {
        return this.adminGetPromoStatusReportResponse(AdminPromoStatusExportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetStoreCustomerFeedbackReportResponse = function (AdminStoreCustomerFeedbackExportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminStoreCustomerFeedbackExportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/store-customer-feedback-report", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetStoreCustomerFeedbackReport = function (AdminStoreCustomerFeedbackExportRequestDto) {
        return this.adminGetStoreCustomerFeedbackReportResponse(AdminStoreCustomerFeedbackExportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetAccountingReportCsvResponse = function (AdminAccountingReportExportRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = AdminAccountingReportExportRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/joe-admin/accounting-report-csv", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetAccountingReportCsv = function (AdminAccountingReportExportRequestDto) {
        return this.adminGetAccountingReportCsvResponse(AdminAccountingReportExportRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminStoreExportResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/admin/export/store-export", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminStoreExport = function () {
        return this.adminStoreExportResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetCurrentStatusV2Response = function (date) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (date != null)
            __params = __params.set('date', date.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/bi-role/joe-admin/get-current-status/v2", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetCurrentStatusV2 = function (date) {
        return this.adminGetCurrentStatusV2Response(date).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetRecentOrdersResponse = function (since) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (since != null)
            __params = __params.set('since', since.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/bi-role/joe-admin/recent-orders", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetRecentOrders = function (since) {
        return this.adminGetRecentOrdersResponse(since).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetStoresNearbyResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/bi-role/joe-admin/stores-nearby", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetStoresNearby = function () {
        return this.adminGetStoresNearbyResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.adminGetCurrentStatusResponse = function (date) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (date != null)
            __params = __params.set('date', date.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/bi-role/joe-admin/get-current-status", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.adminGetCurrentStatus = function (date) {
        return this.adminGetCurrentStatusResponse(date).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyDashboardResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.date != null)
            __params = __params.set('date', params.date.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/dashboard"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyDashboard = function (params) {
        return this.companyDashboardResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyListResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/company", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyList = function () {
        return this.companyListResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyCreateResponse = function (CompanyDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = CompanyDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/company", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyCreate = function (CompanyDto) {
        return this.companyCreateResponse(CompanyDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetLandingPageFromSlugResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.lon != null)
            __params = __params.set('lon', params.lon.toString());
        if (params.lat != null)
            __params = __params.set('lat', params.lat.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companySlug) + "/landing-page-data"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetLandingPageFromSlug = function (params) {
        return this.companyGetLandingPageFromSlugResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyLandingAddHeroBackgroundResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var __formData = new FormData();
        __body = __formData;
        if (params.y != null)
            __params = __params.set('y', params.y.toString());
        if (params.x != null)
            __params = __params.set('x', params.x.toString());
        if (params.width != null)
            __params = __params.set('width', params.width.toString());
        if (params.scale != null)
            __params = __params.set('scale', params.scale.toString());
        if (params.height != null)
            __params = __params.set('height', params.height.toString());
        if (params.file != null) {
            __formData.append('file', params.file);
        }
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/add-landing-hero-background"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyLandingAddHeroBackground = function (params) {
        return this.companyLandingAddHeroBackgroundResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyLandingAddLogoResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var __formData = new FormData();
        __body = __formData;
        if (params.file != null) {
            __formData.append('file', params.file);
        }
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/add-landing-logo"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyLandingAddLogo = function (params) {
        return this.companyLandingAddLogoResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyUpdateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CompanyUpdateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyUpdate = function (params) {
        return this.companyUpdateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGet = function (companyId) {
        return this.companyGetResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyVerificationResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CompanyVerificationDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/verification"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyVerification = function (params) {
        return this.companyVerificationResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyVerificationV2Response = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CompanyVerificationDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/verification/v2"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyVerificationV2 = function (params) {
        return this.companyVerificationV2Response(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetTransfersResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/transfers"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetTransfers = function (companyId) {
        return this.companyGetTransfersResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyDownloadTransferReportResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.type != null)
            __params = __params.set('type', params.type.toString());
        if (params.format != null)
            __params = __params.set('format', params.format.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/download-transfer/" + encodeURIComponent(params.transferBatchId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyDownloadTransferReport = function (params) {
        return this.companyDownloadTransferReportResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetStoresResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/stores"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetStores = function (companyId) {
        return this.companyGetStoresResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetUsersResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/users"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetUsers = function (companyId) {
        return this.companyGetUsersResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetMenusResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/menus"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetMenus = function (companyId) {
        return this.companyGetMenusResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetLandingPageResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/landing-page"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetLandingPage = function (companyId) {
        return this.companyGetLandingPageResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyInviteUserResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CompanyCreateInviteRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/invite"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyInviteUser = function (params) {
        return this.companyInviteUserResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyGetInvitesResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/invite"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyGetInvites = function (companyId) {
        return this.companyGetInvitesResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyDeleteUserRoleResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/role/" + encodeURIComponent(params.userId) + "/" + encodeURIComponent(params.roleName)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyDeleteUserRole = function (params) {
        return this.companyDeleteUserRoleResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyCancelInviteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/invite/" + encodeURIComponent(params.inviteId) + "/cancel"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyCancelInvite = function (params) {
        return this.companyCancelInviteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyReportSubscriberListResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/report-subscriber"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyReportSubscriberList = function (companyId) {
        return this.companyReportSubscriberListResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyReportSubscriberCreateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CompanyReportSubscriberDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/report-subscriber"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyReportSubscriberCreate = function (params) {
        return this.companyReportSubscriberCreateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyReportSubscriberDeleteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/report-subscriber/" + encodeURIComponent(params.subscriberId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyReportSubscriberDelete = function (params) {
        return this.companyReportSubscriberDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuOptionGetResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/menu-option/" + encodeURIComponent(params.menuOptionId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuOptionGet = function (params) {
        return this.menuOptionGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuOptionUpdateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.MenuOptionCreateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/menu-option/" + encodeURIComponent(params.menuOptionId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuOptionUpdate = function (params) {
        return this.menuOptionUpdateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuOptionDeleteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/menu-option/" + encodeURIComponent(params.menuOptionId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuOptionDelete = function (params) {
        return this.menuOptionDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuOptionListResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/menu-option"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuOptionList = function (params) {
        return this.menuOptionListResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuOptionCreateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.MenuOptionCreateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/menu-option"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuOptionCreate = function (params) {
        return this.menuOptionCreateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuGetResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuGet = function (params) {
        return this.menuGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuDeleteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuDelete = function (params) {
        return this.menuDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuExportEncodedResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/export/encoded"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuExportEncoded = function (params) {
        return this.menuExportEncodedResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuExportResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/" + encodeURIComponent(params.menuId) + "/export"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuExport = function (params) {
        return this.menuExportResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuImportEncodedResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.MenuExportEncodedDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/import/encoded"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuImportEncoded = function (params) {
        return this.menuImportEncodedResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuImportResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.MenuExportDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/import"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuImport = function (params) {
        return this.menuImportResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuCreateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.MenuIterationDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu/v2"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuCreate = function (params) {
        return this.menuCreateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.menuCreateLegacyResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.MenuIterationDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/menu"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.menuCreateLegacy = function (params) {
        return this.menuCreateLegacyResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyBankAccountListResponse = function (companyId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(companyId) + "/bank-account/list"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyBankAccountList = function (companyId) {
        return this.companyBankAccountListResponse(companyId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyBankAccountAddResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CompanyBankAccountAddDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/bank-account"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyBankAccountAdd = function (params) {
        return this.companyBankAccountAddResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyBankAccountGetResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/bank-account/" + encodeURIComponent(params.bankId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyBankAccountGet = function (params) {
        return this.companyBankAccountGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyBankAccountDeleteResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/bank-account/" + encodeURIComponent(params.bankId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyBankAccountDelete = function (params) {
        return this.companyBankAccountDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.companyBankAccountSetDefaultResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/bank-account/" + encodeURIComponent(params.bankId) + "/default"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.companyBankAccountSetDefault = function (params) {
        return this.companyBankAccountSetDefaultResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.devicePairFromIdResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.DevicePairFromCodeRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/pair-from-code"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.devicePairFromId = function (params) {
        return this.devicePairFromIdResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.deviceUnpairResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.storeId != null)
            __params = __params.set('storeId', params.storeId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/" + encodeURIComponent(params.deviceId) + "/unpair"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.deviceUnpair = function (params) {
        return this.deviceUnpairResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.deviceListResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.storeId != null)
            __params = __params.set('storeId', params.storeId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.deviceList = function (params) {
        return this.deviceListResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.devicePairCreateResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.CreatePairDeviceRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/pair"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.devicePairCreate = function (params) {
        return this.devicePairCreateResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceSetActiveResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/set-active/" + encodeURIComponent(params.isActive)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceSetActive = function (params) {
        return this.merchantDeviceSetActiveResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceCheckinResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.status != null)
            __params = __params.set('status', params.status.toString());
        if (params.nativeVersionCode != null)
            __params = __params.set('nativeVersionCode', params.nativeVersionCode.toString());
        if (params.nativeVersion != null)
            __params = __params.set('nativeVersion', params.nativeVersion.toString());
        if (params.clientTimestamp != null)
            __params = __params.set('clientTimestamp', params.clientTimestamp.toString());
        if (params.buildNumber != null)
            __params = __params.set('buildNumber', params.buildNumber.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/checkin"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceCheckin = function (params) {
        return this.merchantDeviceCheckinResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceCheckinOnlyPutResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.ifNoneMatch != null)
            __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());
        __body = params.CheckinRequestDto;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/check-in"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceCheckinOnlyPut = function (params) {
        return this.merchantDeviceCheckinOnlyPutResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceCheckinOnlyResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.ifNoneMatch != null)
            __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());
        if (params.status != null)
            __params = __params.set('status', params.status.toString());
        if (params.nativeVersionCode != null)
            __params = __params.set('nativeVersionCode', params.nativeVersionCode.toString());
        if (params.nativeVersion != null)
            __params = __params.set('nativeVersion', params.nativeVersion.toString());
        if (params.clientTimestamp != null)
            __params = __params.set('clientTimestamp', params.clientTimestamp.toString());
        if (params.buildNumber != null)
            __params = __params.set('buildNumber', params.buildNumber.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/check-in"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceCheckinOnly = function (params) {
        return this.merchantDeviceCheckinOnlyResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceOrderStatsResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.ifNoneMatch != null)
            __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/order-stats"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceOrderStats = function (params) {
        return this.merchantDeviceOrderStatsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceActiveOrdersResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.ifNoneMatch != null)
            __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());
        if (params.includeCurbsidePendingPickup != null)
            __params = __params.set('includeCurbsidePendingPickup', params.includeCurbsidePendingPickup.toString());
        if (params.includeComplete != null)
            __params = __params.set('includeComplete', params.includeComplete.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/active-orders"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceActiveOrders = function (params) {
        return this.merchantDeviceActiveOrdersResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceArchivedOrdersResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.ifNoneMatch != null)
            __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/archived-orders"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceArchivedOrders = function (params) {
        return this.merchantDeviceArchivedOrdersResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.merchantDeviceInactiveOrdersResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.ifNoneMatch != null)
            __headers = __headers.set('if-none-match', params.ifNoneMatch.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/company/" + encodeURIComponent(params.companyId) + "/devices/inactive-orders"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.merchantDeviceInactiveOrders = function (params) {
        return this.merchantDeviceInactiveOrdersResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.customerListPaginatedResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.sortField != null)
            __params = __params.set('sortField', params.sortField.toString());
        if (params.sortDirection != null)
            __params = __params.set('sortDirection', params.sortDirection.toString());
        if (params.search != null)
            __params = __params.set('search', params.search.toString());
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/customer", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.customerListPaginated = function (params) {
        return this.customerListPaginatedResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.customerGetResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/customer/" + encodeURIComponent(userId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.customerGet = function (userId) {
        return this.customerGetResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.customerGetRecentOrdersResponse = function (userId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('device-authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('device-authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/customer/" + encodeURIComponent(userId) + "/recent-orders"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.customerGetRecentOrders = function (userId) {
        return this.customerGetRecentOrdersResponse(userId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.landingPageLoadedResponse = function (slug) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/landing-page/" + encodeURIComponent(slug) + "/load"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.landingPageLoaded = function (slug) {
        return this.landingPageLoadedResponse(slug).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.landingPageLinkClickedResponse = function (slug) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + ("/landing-page/" + encodeURIComponent(slug) + "/click"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.landingPageLinkClicked = function (slug) {
        return this.landingPageLinkClickedResponse(slug).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.promoCreateResponse = function (PromoCreateDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PromoCreateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/promo", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.promoCreate = function (PromoCreateDto) {
        return this.promoCreateResponse(PromoCreateDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.promoUpdateResponse = function (PromoUpdateDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = PromoUpdateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('PUT', _this.rootUrl + "/promo", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.promoUpdate = function (PromoUpdateDto) {
        return this.promoUpdateResponse(PromoUpdateDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.promoGetResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.storeId != null)
            __params = __params.set('storeId', params.storeId.toString());
        if (params.companyId != null)
            __params = __params.set('companyId', params.companyId.toString());
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/promo", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.promoGet = function (params) {
        return this.promoGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.consumerLaunchResponse = function (ConsumerLaunchRequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ConsumerLaunchRequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/client/consumer-launch", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.consumerLaunch = function (ConsumerLaunchRequestDto) {
        return this.consumerLaunchResponse(ConsumerLaunchRequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.consumerLaunchV2Response = function (ConsumerLaunchV2RequestDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ConsumerLaunchV2RequestDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/client/consumer-launch/v2", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.consumerLaunchV2 = function (ConsumerLaunchV2RequestDto) {
        return this.consumerLaunchV2Response(ConsumerLaunchV2RequestDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.feedbackSendResponse = function (UserFeedbackDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = UserFeedbackDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/feedback", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.feedbackSend = function (UserFeedbackDto) {
        return this.feedbackSendResponse(UserFeedbackDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppListResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + "/api-app", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppList = function () {
        return this.apiAppListResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppCreateResponse = function () {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/api-app", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppCreate = function () {
        return this.apiAppCreateResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppCredentialListResponse = function (apiAppId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/api-app/" + encodeURIComponent(apiAppId) + "/credentials"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppCredentialList = function (apiAppId) {
        return this.apiAppCredentialListResponse(apiAppId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppGetResponse = function (apiAppId) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('GET', _this.rootUrl + ("/api-app/" + encodeURIComponent(apiAppId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppGet = function (apiAppId) {
        return this.apiAppGetResponse(apiAppId).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppCreateCredentialResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.ApiAppCredentialCreateDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/api-app/" + encodeURIComponent(params.apiAppId) + "/credential"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppCreateCredential = function (params) {
        return this.apiAppCreateCredentialResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppRevokeCredentialResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('DELETE', _this.rootUrl + ("/api-app/" + encodeURIComponent(params.apiAppId) + "/credential/" + encodeURIComponent(params.apiCredentialId)), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppRevokeCredential = function (params) {
        return this.apiAppRevokeCredentialResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.apiAppRollCredentialSecretResponse = function (params) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + ("/api-app/" + encodeURIComponent(params.apiAppId) + "/credential/" + encodeURIComponent(params.apiCredentialId) + "/roll"), __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.apiAppRollCredentialSecret = function (params) {
        return this.apiAppRollCredentialSecretResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.consumerActivityCreateResponse = function (ConsumerActivityDto) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ConsumerActivityDto;
        return this.tokenProvider('authorization').pipe(__switchMap(function (_token) {
            if (_token != null)
                __headers = __headers.set('authorization', _token.toString());
            var req = new HttpRequest('POST', _this.rootUrl + "/analytics/consumer-activity", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.consumerActivityCreate = function (ConsumerActivityDto) {
        return this.consumerActivityCreateResponse(ConsumerActivityDto).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.squareWebhookEndpointResponse = function (xSquareSignature) {
        var _this = this;
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (xSquareSignature != null)
            __headers = __headers.set('x-square-signature', xSquareSignature.toString());
        return __of(null).pipe(__switchMap(function (_token) {
            var req = new HttpRequest('POST', _this.rootUrl + "/square-webhook", __body, {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });
            return _this.http.request(req);
        }), __filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.squareWebhookEndpoint = function (xSquareSignature) {
        return this.squareWebhookEndpointResponse(xSquareSignature).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.statusGetPath = '/';
    ApiService.storesGetNearbyPaginatedPath = '/store/nearby/paginated/{lat}/{lon}';
    ApiService.storesGetNearbyPath = '/store/nearby/{lat}/{lon}';
    ApiService.storeIsAvailableForOrdersPath = '/store/{storeId}/available-for-order';
    ApiService.storeGetDealsPath = '/store/{storeId}/deal';
    ApiService.storePutDealPath = '/store/{storeId}/deal';
    ApiService.storeDeleteDealPath = '/store/{storeId}/deal/{dealId}';
    ApiService.storeGetDetailsPath = '/store/{storeId}/details';
    ApiService.storeGetMenuCategorySectionsWithInventoryPath = '/store/{storeId}/menu-category-sections-with-inventory/{categoryId}';
    ApiService.storeGetMenuV2Path = '/store/{storeId}/menu/v2';
    ApiService.storeGetMenuV3Path = '/store/{storeId}/menu/v3';
    ApiService.storeGetMenuPath = '/store/{storeId}/menu';
    ApiService.storeGetSeoListPath = '/store/list-for-blog-seo';
    ApiService.storeListPath = '/store';
    ApiService.storeCreatePath = '/store';
    ApiService.storeGetOfflineReportPath = '/store/{storeId}/offline-report';
    ApiService.storeDashboardPath = '/store/{storeId}/dashboard';
    ApiService.storePutAmenitiesPath = '/store/{storeId}/amenities';
    ApiService.storeFindDetailsPath = '/store/find';
    ApiService.storeFindHoursByPlaceIdPath = '/store/find/hours';
    ApiService.updateStoreHoursPath = '/store/{storeId}/set-hours';
    ApiService.storeValidatePhonePath = '/store/{storeId}/validate-phone';
    ApiService.storeValidatePhoneCheckStatusPath = '/store/{storeId}/validate-phone';
    ApiService.storeSetAlwaysOnlinePath = '/store/{storeId}/set-always-online';
    ApiService.storeSetSquareLocationIdPath = '/store/{storeId}/set-location-id';
    ApiService.storeSetMinimumWaitTimePath = '/store/set-minimum-wait-time';
    ApiService.storeClearSquareLocationIdPath = '/store/{storeId}/clear-location-id';
    ApiService.storeDisconnectSquareIntegrationPath = '/store/{storeId}/disconnect-square-integration';
    ApiService.storeValidatePhoneTwilioInboundWebHookPath = '/store/validate-phone-twilio-hook';
    ApiService.storeUpdateV2Path = '/store/{storeId}/v2';
    ApiService.storeGetV2Path = '/store/{storeId}/v2';
    ApiService.storeGetPath = '/store/{storeId}';
    ApiService.storeUpdatePath = '/store/{storeId}';
    ApiService.getOrdersByStorePath = '/store/{storeId}/orders';
    ApiService.storeInviteUserPath = '/store/{storeId}/invite';
    ApiService.storeAddImageFromIdPath = '/store/{storeId}/add-image-from-id';
    ApiService.storeAddPickupLocationImageFromIdPath = '/store/{storeId}/add-pickup-location-image-from-id';
    ApiService.storeAddImagePath = '/store/{storeId}/add-image';
    ApiService.storeReportSubscriberListPath = '/store/{storeId}/report-subscriber';
    ApiService.storeReportSubscriberCreatePath = '/store/{storeId}/report-subscriber';
    ApiService.storeReportSubscriberDeletePath = '/store/{storeId}/report-subscriber/{subscriberId}';
    ApiService.storeItemInventoryGetOutOfStockCountPath = '/store-item-inventory';
    ApiService.storeSetItemInventoryPath = '/store-item-inventory';
    ApiService.storeAddItemInventoryPath = '/store-item-inventory';
    ApiService.storeDeleteItemInventoryPath = '/store-item-inventory/{itemInventoryId}';
    ApiService.storeOptionInventoryGetOutOfStockCountPath = '/store-option-inventory';
    ApiService.storeSetOptionInventoryPath = '/store-option-inventory';
    ApiService.orderListPaginatedPath = '/order/paginated';
    ApiService.orderGetPath = '/order/{orderId}';
    ApiService.orderGetDetailedPath = '/order/detailed/{orderId}';
    ApiService.orderGetReorderPath = '/order/reorder/{orderId}';
    ApiService.orderListPath = '/order';
    ApiService.orderCreatePath = '/order';
    ApiService.orderCreateV2Path = '/order/v2';
    ApiService.orderCreateV3Path = '/order/v3';
    ApiService.orderCustomerArrivedPath = '/order/{orderId}/customer-arrived';
    ApiService.orderReviewPath = '/order/{orderId}/review';
    ApiService.orderPricingComputePath = '/order/pricing';
    ApiService.orderPricingComputeWithSourceTypePath = '/order/pricing-with-source-type';
    ApiService.orderCustomerPickupOrderPath = '/order/{orderId}/customer-pickup-order';
    ApiService.orderRefundPath = '/order/{orderId}/refund';
    ApiService.orderArchivePath = '/order/{orderId}/archive';
    ApiService.orderAcknowledgePath = '/order/{orderId}/acknowledge';
    ApiService.orderSetWaitTimePath = '/order/{orderId}/wait-time';
    ApiService.orderScheduleAlertAcknowledgedPath = '/order/{orderId}/acknowledge-schedule-alert';
    ApiService.orderGetDetailedPosPath = '/order/detailed/{orderId}/for-pos';
    ApiService.orderCreateForPosPath = '/order/for-pos';
    ApiService.orderItemSetStatusPath = '/order/{orderId}/item/{orderItemId}/status';
    ApiService.orderUserPendingOrderClearPath = '/order/pending-order-at-store/{storeId}';
    ApiService.createJoeGiftCardPath = '/payment/joe-gift-card';
    ApiService.joebucksUploadFundsPath = '/payment/joebucks/upload-funds';
    ApiService.joebucksCheckBalancePath = '/payment/joebucks/balance';
    ApiService.joebucksUploadsHistoryPath = '/payment/joebucks/history';
    ApiService.authorizeBankAccountPath = '/payment/bank-accounts/authorize';
    ApiService.storeUserBankAccountPath = '/payment/bank-accounts/user-bank-account';
    ApiService.deleteBankAccountPath = '/payment/bank-accounts/delete';
    ApiService.cloverInventoryListPath = '/clover-inventory/store/{storeId}/inventory';
    ApiService.cloverInventoryAssociationPath = '/clover-inventory/store/{storeId}/inventory/{cloverItemId}/associate';
    ApiService.cloverInventoryWebhookPath = '/clover-inventory/webhook';
    ApiService.cloverAuthAuthorizationPath = '/clover-auth/store/{storeId}/authorization';
    ApiService.cloverAuthRevokePath = '/clover-auth/store/{storeId}/revoke';
    ApiService.squareAuthorizationRedirectEndpointPath = '/square/authorization';
    ApiService.squareGetAllLocationsPath = '/square/{storeId}/get-square-locations';
    ApiService.authLoginPath = '/auth/login';
    ApiService.authSignupPath = '/auth/signup';
    ApiService.userAuthRefreshPath = '/auth/refresh';
    ApiService.merchantDeviceRefreshPath = '/auth/refresh-merchant';
    ApiService.consumerSignupPath = '/auth/consumerSignup';
    ApiService.validateUserPath = '/auth/validateUser';
    ApiService.authChangePasswordPath = '/auth/change-password';
    ApiService.authChangePasswordWithCodePath = '/auth/change-password-with-code';
    ApiService.authResetPasswordPath = '/auth/reset-password';
    ApiService.createAppCodePath = '/auth/app-code';
    ApiService.redeemAppCodePath = '/auth/app-code/{code}/redeem';
    ApiService.checkAppCodeRedeemedPath = '/auth/app-code/{code}/status';
    ApiService.appCodeAuthenticatePath = '/auth/app-code/authenticate';
    ApiService.oauthAuthObtainTokenPath = '/auth/oauth/token';
    ApiService.oauthAuthRevokeTokenPath = '/auth/oauth/token';
    ApiService.oauthAuthAuthorizeDirectPath = '/auth/oauth/authorize/direct';
    ApiService.oauthAuthAuthorizeDirectAuthenticatePath = '/auth/oauth/authorize/direct/{authenticationRequestToken}/authenticate';
    ApiService.userGetProfileBasicPath = '/user/profile-basic';
    ApiService.userGetProfilePath = '/user/profile';
    ApiService.userUpdateNamePath = '/user/name';
    ApiService.userAddEmailPasswordPath = '/user/add-email-password';
    ApiService.userSetPhotoPath = '/user/add-photo';
    ApiService.userHasMerchantRolesPath = '/user/has-merchant-roles';
    ApiService.userAddCardPath = '/user/cards';
    ApiService.userGetCardsPath = '/user/cards';
    ApiService.userRemoveOneCardPath = '/user/cards/{cardId}';
    ApiService.userGetReferralShortUrlPath = '/user/referral-link';
    ApiService.userSetReferralShortUrlPath = '/user/referral-link';
    ApiService.userGetReferralStatusesPath = '/user/referral-status';
    ApiService.userGetByReferralCodePath = '/user/referred-user/{code}';
    ApiService.userGetReferralCodePath = '/user/referral-code';
    ApiService.userGetFavoriteStoresPath = '/user/favorites/stores';
    ApiService.userGetInvitesPath = '/user/invites';
    ApiService.userGetReactivationPromoStatusPath = '/user/reactivation-status';
    ApiService.userGetRewardStatusPath = '/user/rewards';
    ApiService.userAcknowledgeMerchantInvitePath = '/user/invite/{inviteId}/acknowledge';
    ApiService.posDevicePairWithUserLoginPath = '/pos-device/pair-with-user-login';
    ApiService.posDeviceGetAllowedStoresFromUserLoginPath = '/pos-device/stores-from-user-login';
    ApiService.posDevicePairPath = '/pos-device/pair';
    ApiService.posDeviceSetModePath = '/pos-device/set-mode';
    ApiService.posDeviceListPath = '/pos-device';
    ApiService.posDeviceCreateCardReaderConnectionTokenPath = '/pos-device/card-reader/connection-token';
    ApiService.posDeviceCreateCardReaderRegisterPath = '/pos-device/card-reader/register';
    ApiService.posDeviceCreateCardReaderPaymentIntentPath = '/pos-device/card-reader/payment-intent';
    ApiService.posSessionSetCurrentOrderPath = '/pos-device/{deviceId}/customer-session/current-order';
    ApiService.posSessionGetCurrentOrderPath = '/pos-device/{deviceId}/customer-session/current-order';
    ApiService.posSessionSetCurrentOrderTipAmountPath = '/pos-device/{deviceId}/customer-session/current-order-tip-amount';
    ApiService.posSessionClearPath = '/pos-device/{deviceId}/customer-session/clear';
    ApiService.posSessionGetOrderSessionPath = '/pos-device/{deviceId}/customer-session/order-session/{orderSessionId}';
    ApiService.posSessionDeleteOrderSessionPath = '/pos-device/{deviceId}/customer-session/order-session/{orderSessionId}';
    ApiService.posSessionSetCurrentCustomerFromPhonePath = '/pos-device/{deviceId}/customer-session/current-customer-from-phone';
    ApiService.posSessionGetCurrentCustomerPath = '/pos-device/{deviceId}/customer-session/current-customer';
    ApiService.posSessionSetCurrentCustomerFromMobileDevicePath = '/pos-device/{deviceId}/customer-session/current-customer-from-mobile-device';
    ApiService.posSessionCustomerGetCurrentOrderPath = '/pos-device/{deviceId}/customer-session/store/{storeId}/customer-current-order';
    ApiService.posSessionCurrentOrderCompletePath = '/pos-device/{deviceId}/customer-session/current-order/complete';
    ApiService.posSessionCustomerTransferOrderToMobilePath = '/pos-device/{deviceId}/customer-session/store/{storeId}/transfer-order-to-mobile';
    ApiService.posSessionDevicePendingOrderClearPath = '/pos-device/{deviceId}/customer-session/pending-order-for-user/{userId}';
    ApiService.posDeviceCashTillOpenPath = '/pos-device/cash-till/open';
    ApiService.posDeviceCashTillClosePath = '/pos-device/cash-till/close';
    ApiService.posDeviceCashTillTransactionSummaryPath = '/pos-device/cash-till/transactions/{tillId}';
    ApiService.posDeviceAllTillTransactionSummaryPath = '/pos-device/cash-till/transactions-all/{tillId}';
    ApiService.reportCreatePath = '/report';
    ApiService.reportGetDailySummaryDeviceV2Path = '/report/get-daily-summary-device/{storeId}/{date}/v2';
    ApiService.reportGetDailySummaryDevicePath = '/report/get-daily-summary-device/{storeId}/{date}';
    ApiService.reportGetInternalReportsPath = '/report/internal';
    ApiService.merchantDashboardV2Path = '/report/merchant-dashboard-v2';
    ApiService.adminRefundOrderHackThisIsBadPath = '/admin/joe-admin/refund-order-hack-bad/{orderId}';
    ApiService.adminGetOrderPath = '/admin/joe-admin/get-order/{orderId}';
    ApiService.adminGetActiveUsersPath = '/admin/joe-admin/get-active-users';
    ApiService.adminRefundOrderPath = '/admin/joe-admin/refund-order';
    ApiService.adminFindUsersPath = '/admin/joe-admin/find-users';
    ApiService.adminUpdateUserEmailPath = '/admin/joe-admin/update-user-email';
    ApiService.adminFindGiftCardPath = '/admin/joe-admin/find-gift-card';
    ApiService.adminFindOrdersPath = '/admin/joe-admin/find-orders';
    ApiService.adminGetActiveStoresPath = '/admin/joe-admin/get-active-stores';
    ApiService.adminGetJoeBucksActivityPath = '/admin/joe-admin/get-joe-bucks-activity';
    ApiService.adminGetReferralHistoryPath = '/admin/joe-admin/get-referral-history/{userId}';
    ApiService.adminSetReferrerPath = '/admin/joe-admin/set-referrer/{userId}/{referringUserId}';
    ApiService.adminBypassStorePhoneVerificationPath = '/admin/joe-admin/store/{storeId}/bypass-phone-verification';
    ApiService.adminGetOrderTotalsPath = '/admin/joe-admin/order-totals';
    ApiService.adminGetUserWeeklyCohortTotalsPath = '/admin/joe-admin/user-weekly-cohort-totals-v2';
    ApiService.adminGetStoresWithStatusPath = '/admin/joe-admin/get-stores-with-status';
    ApiService.adminSearchStoresWithStatusPath = '/admin/joe-admin/search-stores-with-status';
    ApiService.adminSendConsumerMarketingUserPushPath = '/admin/joe-admin/send-consumer-marketing-push';
    ApiService.adminSendConsumerMarketingOrphanPushPath = '/admin/joe-admin/send-consumer-marketing-orphan';
    ApiService.adminSendConsumerMarketingSinglePushPath = '/admin/joe-admin/send-consumer-marketing-single/{userId}';
    ApiService.adminGetMerchantTransfersOldPath = '/admin/joe-admin/get-merchant-transfers';
    ApiService.adminGetMerchantTransfersPath = '/admin/joe-admin/get-merchant-transfers/v2';
    ApiService.adminSendMerchantTransferTransferPath = '/admin/joe-admin/send-merchant-transfer-transfer/{transferId}';
    ApiService.adminSendMerchantTransferEmailPath = '/admin/joe-admin/send-merchant-transfer-email/{transferId}';
    ApiService.adminSendMerchantTransferEmailTestPath = '/admin/joe-admin/send-merchant-transfer-email-test/{transferId}';
    ApiService.adminUserRewardStatusGetPath = '/admin/joe-admin/user/{userId}/rewards';
    ApiService.adminUserBalanceGetPath = '/admin/joe-admin/user/{userId}/balance';
    ApiService.adminNetworkStatusTerritoryCreatePath = '/admin/joe-admin/network-status-territory';
    ApiService.adminNetworkStatusTerritoryListPath = '/admin/joe-admin/network-status-territory';
    ApiService.adminNetworkStatusTerritoryUpdatePath = '/admin/joe-admin/network-status-territory/{territoryId}';
    ApiService.adminNetworkStatusTerritoryGetPath = '/admin/joe-admin/network-status-territory/{territoryId}';
    ApiService.adminNetworkStatusTerritoryDeletePath = '/admin/joe-admin/network-status-territory/{territoryId}';
    ApiService.adminNetworkStatusTerritoryGetDetailsPath = '/admin/joe-admin/network-status-territory-details/{territoryId}';
    ApiService.adminNetworkStatusTerritoryListWithDetailsPath = '/admin/joe-admin/network-status-territory-details';
    ApiService.adminSetStorePosPaymentEnabledPath = '/admin/joe-admin/store/{storeId}/set-pos-payment-enabled';
    ApiService.adminSetStoreFeeOverridePath = '/admin/joe-admin/store/{storeId}/set-fee-override';
    ApiService.adminSetCompanyApprovalPath = '/admin/joe-admin/set-company-approval';
    ApiService.adminMenuTransferToCompanyPath = '/admin/joe-admin/{menuId}/transfer-to/{toCompanyId}';
    ApiService.adminGetStoreOfflineReportPath = '/admin/joe-admin/store-offline-report';
    ApiService.adminMergeUsersPath = '/admin/joe-admin/user-merge';
    ApiService.adminResetUserPasswordPath = '/admin/joe-admin/user-reset-password';
    ApiService.adminResetUserPath = '/admin/joe-admin/user-reset/{userId}';
    ApiService.adminRefundRemainingUserBalancePath = '/admin/joe-admin/user-refund-remaining-joebucks/{userId}';
    ApiService.adminGetGlobalPromosPath = '/admin/joe-admin/get-global-promos';
    ApiService.adminGetUserWeeklyCohortTotalsDeprecatedPath = '/admin/joe-admin/user-weekly-cohort-totals';
    ApiService.adminGetUserReactivationCohortsDeprecatedPath = '/admin/joe-admin/user-reactivation-cohorts';
    ApiService.adminGetStoreStatusReportPath = '/admin/export/store-status-report';
    ApiService.adminGetMerchantDeviceReportPath = '/admin/export/merchant-device-report';
    ApiService.adminGetSquareIntegrationReportPath = '/admin/export/square-integration-report';
    ApiService.adminGetPromoStatusReportPath = '/admin/export/promo-status-report';
    ApiService.adminGetStoreCustomerFeedbackReportPath = '/admin/export/store-customer-feedback-report';
    ApiService.adminGetAccountingReportCsvPath = '/admin/export/joe-admin/accounting-report-csv';
    ApiService.adminStoreExportPath = '/admin/export/store-export';
    ApiService.adminGetCurrentStatusV2Path = '/bi-role/joe-admin/get-current-status/v2';
    ApiService.adminGetRecentOrdersPath = '/bi-role/joe-admin/recent-orders';
    ApiService.adminGetStoresNearbyPath = '/bi-role/joe-admin/stores-nearby';
    ApiService.adminGetCurrentStatusPath = '/bi-role/joe-admin/get-current-status';
    ApiService.companyDashboardPath = '/company/{companyId}/dashboard';
    ApiService.companyListPath = '/company';
    ApiService.companyCreatePath = '/company';
    ApiService.companyGetLandingPageFromSlugPath = '/company/{companySlug}/landing-page-data';
    ApiService.companyLandingAddHeroBackgroundPath = '/company/{companyId}/add-landing-hero-background';
    ApiService.companyLandingAddLogoPath = '/company/{companyId}/add-landing-logo';
    ApiService.companyUpdatePath = '/company/{companyId}';
    ApiService.companyGetPath = '/company/{companyId}';
    ApiService.companyVerificationPath = '/company/{companyId}/verification';
    ApiService.companyVerificationV2Path = '/company/{companyId}/verification/v2';
    ApiService.companyGetTransfersPath = '/company/{companyId}/transfers';
    ApiService.companyDownloadTransferReportPath = '/company/{companyId}/download-transfer/{transferBatchId}';
    ApiService.companyGetStoresPath = '/company/{companyId}/stores';
    ApiService.companyGetUsersPath = '/company/{companyId}/users';
    ApiService.companyGetMenusPath = '/company/{companyId}/menus';
    ApiService.companyGetLandingPagePath = '/company/{companyId}/landing-page';
    ApiService.companyInviteUserPath = '/company/{companyId}/invite';
    ApiService.companyGetInvitesPath = '/company/{companyId}/invite';
    ApiService.companyDeleteUserRolePath = '/company/{companyId}/role/{userId}/{roleName}';
    ApiService.companyCancelInvitePath = '/company/{companyId}/invite/{inviteId}/cancel';
    ApiService.companyReportSubscriberListPath = '/company/{companyId}/report-subscriber';
    ApiService.companyReportSubscriberCreatePath = '/company/{companyId}/report-subscriber';
    ApiService.companyReportSubscriberDeletePath = '/company/{companyId}/report-subscriber/{subscriberId}';
    ApiService.menuOptionGetPath = '/company/{companyId}/menu/{menuId}/menu-option/{menuOptionId}';
    ApiService.menuOptionUpdatePath = '/company/{companyId}/menu/{menuId}/menu-option/{menuOptionId}';
    ApiService.menuOptionDeletePath = '/company/{companyId}/menu/{menuId}/menu-option/{menuOptionId}';
    ApiService.menuOptionListPath = '/company/{companyId}/menu/{menuId}/menu-option';
    ApiService.menuOptionCreatePath = '/company/{companyId}/menu/{menuId}/menu-option';
    ApiService.menuGetPath = '/company/{companyId}/menu/{menuId}';
    ApiService.menuDeletePath = '/company/{companyId}/menu/{menuId}';
    ApiService.menuExportEncodedPath = '/company/{companyId}/menu/{menuId}/export/encoded';
    ApiService.menuExportPath = '/company/{companyId}/menu/{menuId}/export';
    ApiService.menuImportEncodedPath = '/company/{companyId}/menu/import/encoded';
    ApiService.menuImportPath = '/company/{companyId}/menu/import';
    ApiService.menuCreatePath = '/company/{companyId}/menu/v2';
    ApiService.menuCreateLegacyPath = '/company/{companyId}/menu';
    ApiService.companyBankAccountListPath = '/company/{companyId}/bank-account/list';
    ApiService.companyBankAccountAddPath = '/company/{companyId}/bank-account';
    ApiService.companyBankAccountGetPath = '/company/{companyId}/bank-account/{bankId}';
    ApiService.companyBankAccountDeletePath = '/company/{companyId}/bank-account/{bankId}';
    ApiService.companyBankAccountSetDefaultPath = '/company/{companyId}/bank-account/{bankId}/default';
    ApiService.devicePairFromIdPath = '/company/{companyId}/devices/pair-from-code';
    ApiService.deviceUnpairPath = '/company/{companyId}/devices/{deviceId}/unpair';
    ApiService.deviceListPath = '/company/{companyId}/devices';
    ApiService.devicePairCreatePath = '/company/{companyId}/devices/pair';
    ApiService.merchantDeviceSetActivePath = '/company/{companyId}/devices/set-active/{isActive}';
    ApiService.merchantDeviceCheckinPath = '/company/{companyId}/devices/checkin';
    ApiService.merchantDeviceCheckinOnlyPutPath = '/company/{companyId}/devices/check-in';
    ApiService.merchantDeviceCheckinOnlyPath = '/company/{companyId}/devices/check-in';
    ApiService.merchantDeviceOrderStatsPath = '/company/{companyId}/devices/order-stats';
    ApiService.merchantDeviceActiveOrdersPath = '/company/{companyId}/devices/active-orders';
    ApiService.merchantDeviceArchivedOrdersPath = '/company/{companyId}/devices/archived-orders';
    ApiService.merchantDeviceInactiveOrdersPath = '/company/{companyId}/devices/inactive-orders';
    ApiService.customerListPaginatedPath = '/customer';
    ApiService.customerGetPath = '/customer/{userId}';
    ApiService.customerGetRecentOrdersPath = '/customer/{userId}/recent-orders';
    ApiService.landingPageLoadedPath = '/landing-page/{slug}/load';
    ApiService.landingPageLinkClickedPath = '/landing-page/{slug}/click';
    ApiService.promoCreatePath = '/promo';
    ApiService.promoUpdatePath = '/promo';
    ApiService.promoGetPath = '/promo';
    ApiService.consumerLaunchPath = '/client/consumer-launch';
    ApiService.consumerLaunchV2Path = '/client/consumer-launch/v2';
    ApiService.feedbackSendPath = '/feedback';
    ApiService.apiAppListPath = '/api-app';
    ApiService.apiAppCreatePath = '/api-app';
    ApiService.apiAppCredentialListPath = '/api-app/{apiAppId}/credentials';
    ApiService.apiAppGetPath = '/api-app/{apiAppId}';
    ApiService.apiAppCreateCredentialPath = '/api-app/{apiAppId}/credential';
    ApiService.apiAppRevokeCredentialPath = '/api-app/{apiAppId}/credential/{apiCredentialId}';
    ApiService.apiAppRollCredentialSecretPath = '/api-app/{apiAppId}/credential/{apiCredentialId}/roll';
    ApiService.consumerActivityCreatePath = '/analytics/consumer-activity';
    ApiService.squareWebhookEndpointPath = '/square-webhook';
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient), i0.inject(ApiServiceTokenProviderInjectionToken, 8)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}(__BaseService));
export { ApiService, ApiServiceTokenProviderInjectionToken };
