import { Directive, Input, Output, OnInit, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import { DragulaService, DragulaEventType, DragulaEvent } from '../../services/dragula/dragula.service';
import { DragulaOptions } from 'dragula-reborn';
import { takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[dragula]',
})
export class DragulaDirective implements OnInit, OnDestroy {

  constructor(
    private dragulaService: DragulaService,
    private elementRef: ElementRef,
  ) { }

  @Input()
  dragula: string;

  @Input()
  dragulaOptions: DragulaOptions;

  @Output()
  dragulaDrop = new EventEmitter<DragulaEvent>();

  @Output()
  dragulaRemove = new EventEmitter<DragulaEvent>();

  private directiveActive: boolean;
  private internalDragulaId: string;

  @Input()
  set dragulaId(dragulaId: string) {
    this.internalDragulaId = dragulaId;
    if (this.directiveActive) {
      this.dragulaService.updateContainerId(this.dragula, this.elementRef.nativeElement, dragulaId);
    }
  }

  ngOnInit() {
    this.directiveActive = true;
    const containerId = this.dragulaService.addContainer(
      this.dragula, this.elementRef.nativeElement, this.internalDragulaId, this.dragulaOptions,
    );

    this.dragulaService.on(DragulaEventType.drop, this.dragula, containerId)
      .pipe(takeWhile(() => this.directiveActive)).subscribe(event => this.dragulaDrop.emit(event));

    this.dragulaService.on(DragulaEventType.remove, this.dragula, containerId)
      .pipe(takeWhile(() => this.directiveActive)).subscribe(event => this.dragulaRemove.emit(event));
  }

  ngOnDestroy() {
    this.directiveActive = false;
    this.dragulaService.removeContainer(this.dragula, this.elementRef.nativeElement);
  }

}
