import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormArray, FormBuilder } from '@angular/forms';
import { keyBy as _keyBy } from 'lodash';
import { WeightToOuncesPipe } from 'src/app/shared/packages/data-transform-pipes/weight/weight-to-ounces/weight-to-ounces.pipe';
var MerchantMenuItemOptionDialogComponent = (function () {
    function MerchantMenuItemOptionDialogComponent(formBuilder, dialogRef, _a, weightToOunces) {
        var option = _a.option, itemName = _a.itemName, itemSizes = _a.itemSizes;
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.weightToOunces = weightToOunces;
        this.allSizesSubscriptions = [];
        this.option = option;
        this.itemName = itemName;
        this.itemSizes = itemSizes;
    }
    MerchantMenuItemOptionDialogComponent.prototype.ngOnInit = function () {
        this.syncItemSizePricesToSizes();
    };
    MerchantMenuItemOptionDialogComponent.prototype.ngOnDestroy = function () {
        this.clearSizeSubscriptions();
    };
    MerchantMenuItemOptionDialogComponent.prototype.save = function () {
        this.errorMessage = '';
        var _a = this.option.getRawValue(), minChoices = _a.minChoices, maxChoices = _a.maxChoices, choices = _a.choices;
        var defaultCount = choices.reduce(function (acc, choice) { return choice.default ? acc + 1 : acc; }, 0);
        if (minChoices > maxChoices) {
            this.errorMessage = 'Min choices cannot be greater than max choices.';
            return;
        }
        if (defaultCount < minChoices) {
            this.errorMessage = 'The number of defaults must be at least the number of min choices.';
            return;
        }
        if (maxChoices < defaultCount) {
            this.errorMessage = 'The number of defaults cannot exceed the max choices';
            return;
        }
        this.dialogRef.close();
    };
    MerchantMenuItemOptionDialogComponent.prototype.getOptionChoices = function () {
        return this.option.get('choices');
    };
    MerchantMenuItemOptionDialogComponent.prototype.setAllChoicesValue = function (value) {
        var _this = this;
        var choices = this.getOptionChoices();
        choices.controls.forEach(function (_, i) { return _this.choiceSizeCustomChange(choices.at(i), value); });
        this.clearSizeSubscriptions();
        if (value) {
            this.allSizesControls = this.itemSizes.map(function (size, i) {
                var sizeGroup = _this.formBuilder.group({
                    menuSizeId: '',
                    menuChoiceId: '',
                    price: '0',
                    name: size.name,
                    quantity: size.quantity,
                    quantityUnit: size.quantityUnit,
                });
                var subscriber = sizeGroup.get('price').valueChanges.subscribe(function (newValue) {
                    _this.getOptionChoices().controls.forEach(function (control) {
                        var targetSizePrices = control.get('sizePrices');
                        if (!targetSizePrices || targetSizePrices.length < i) {
                            return;
                        }
                        var targetControl = targetSizePrices.at(i);
                        targetControl.get('price').setValue(newValue);
                    });
                });
                _this.allSizesSubscriptions.push(subscriber);
                return sizeGroup;
            });
        }
        else {
            this.allSizesControls = undefined;
        }
    };
    MerchantMenuItemOptionDialogComponent.prototype.clearSizeSubscriptions = function () {
        var subscription = this.allSizesSubscriptions.pop();
        while (subscription) {
            subscription.unsubscribe();
            subscription = this.allSizesSubscriptions.pop();
        }
    };
    MerchantMenuItemOptionDialogComponent.prototype.syncItemSizePricesToSizes = function () {
        var _this = this;
        var choices = this.option.get('choices');
        if (!choices || !choices.controls) {
            return;
        }
        choices.controls.forEach(function (choice) {
            var sizePrices = choice.get('sizePrices');
            if (sizePrices && sizePrices.controls) {
                var sizePriceIds_1 = _keyBy(sizePrices.getRawValue(), 'menuSizeId');
                var sizeIds = _keyBy(_this.itemSizes, 'id');
                for (var i = sizePrices.controls.length - 1; i >= 0; i--) {
                    var sizePrice = sizePrices.at(i);
                    var sizePriceId = sizePrice.get('menuSizeId').value;
                    if (sizeIds.hasOwnProperty(sizePriceId)) {
                        var size = sizeIds[sizePriceId];
                        sizePrice.patchValue({ name: size.name, quantity: size.quantity, quantityUnit: size.quantityUnit });
                    }
                    else {
                        sizePrices.removeAt(i);
                    }
                }
                _this.itemSizes.forEach(function (size) {
                    if (!sizePriceIds_1.hasOwnProperty(size.id)) {
                        sizePrices.push(_this.createItemSizePrice(choice, size));
                    }
                });
                sizePrices.controls.sort(function (a, b) {
                    return _this.weightToOunces.transform(a.get('quantity').value, a.get('quantityUnit').value) -
                        _this.weightToOunces.transform(b.get('quantity').value, b.get('quantityUnit').value);
                });
            }
        });
    };
    MerchantMenuItemOptionDialogComponent.prototype.createItemSizePrice = function (choice, size) {
        return this.formBuilder.group({
            menuSizeId: size.id,
            menuChoiceId: choice.get('id').value,
            price: choice.get('price').value,
            name: size.name,
            quantity: size.quantity,
            quantityUnit: size.quantityUnit,
        });
    };
    MerchantMenuItemOptionDialogComponent.prototype.choiceSizeCustomChange = function (choice, value) {
        var _this = this;
        if (value) {
            choice.addControl('sizePrices', new FormArray(this.itemSizes.map(function (size) { return _this.createItemSizePrice(choice, size); })));
        }
        else {
            choice.removeControl('sizePrices');
        }
    };
    MerchantMenuItemOptionDialogComponent.prototype.freeCheckChange = function (choice) {
        var freeCheck = choice.get('free');
        if (freeCheck.value) {
            this.choiceSizeCustomChange(choice, false);
        }
    };
    MerchantMenuItemOptionDialogComponent.prototype.requiredCheckChange = function (choice) {
        var requiredCheck = choice.get('required');
        var defaultCheck = choice.get('default');
        var freeCheck = choice.get('free');
        if (requiredCheck.value) {
            defaultCheck.setValue(true);
            defaultCheck.disable();
            freeCheck.setValue(true);
            freeCheck.disable();
        }
        else {
            defaultCheck.enable();
            defaultCheck.setValue(false);
            freeCheck.enable();
            freeCheck.setValue(false);
        }
        this.freeCheckChange(choice);
    };
    return MerchantMenuItemOptionDialogComponent;
}());
export { MerchantMenuItemOptionDialogComponent };
