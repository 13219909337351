import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPad',
})
export class NumberPadPipe implements PipeTransform {

  transform(value: number, left: number, decimal: number = 0): any {
    if (isNaN(parseFloat(<any>value))) {
      value = 0;
    }
    const decimalLength = decimal > 0 ? decimal + 1 : 0;
    const displayNumber = value.toFixed(decimal);
    const zeros = '0'.repeat(Math.max(0, left - displayNumber.length + decimalLength));
    return zeros + displayNumber;
  }

}
