import { ApiService, CompanyBankAccountAddDto, CompanyCreateInviteRequestDto, CompanyDto, CompanyLandingDataDto, CompanyReportSubscriberDto, CompanyUpdateDto, CompanyVerificationDto, CompanyViewDto, MenuMinimalDto, MerchantDashboardReportConfigDto, MerchantReportV2Dto, MerchantTransferDto, StoreMinimalDto, UserMinimalWithRolesDto } from 'src/gen/joeServerCore';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserMerchantInviteResponseDto } from 'src/gen/joeServerCore/lib/models/user-merchant-invite-response-dto';
import { downloadStringToFile } from 'src/app/shared/helpers/file/download-string-to-file';
import { getDateMMDDYYYY } from 'src/app/shared/helpers/date/get-date-mmddyyyy';
import { map } from 'rxjs/operators';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';

export type DownloadTransferType = 'summary' | 'itemized';
export type DownloadTransferFormat = 'csv' | 'json';

export { CompanyDto, CompanyViewDto };

export interface ImageCropOptions {
  x: number;
  y: number;
  width: number;
  height: number;
  scale: number;
}

export interface GroupedMerchantTransfer {
  batchId: string;
  start: Date;
  end: Date;
  emailed: number;
  transferred: number;
  total: number;
  stores: Set<string>;
  transfers: MerchantTransferDto[];
}

export type StoreReportType = 'weekly_sales' | 'daily_sales' | 'bank_transfer';
export type CompanyTransferCadence = 'daily' | 'weekly' | 'bimonthly' | 'monthly';

@Injectable()
export class CompanyService {

  constructor(
    private apiService: ApiService,
    private telemetryService: TelemetryService,
  ) { }

  getDashboardData(
    companyId: string,
    start: string,
    end: string,
    granularity: string,
    reports: MerchantDashboardReportConfigDto,
    storeIds: Array<string>,
  ): Observable<MerchantReportV2Dto> {
    return this.apiService.merchantDashboardV2({ companyId, start, end, granularity, reports, storeIds });
  }

  getLandingPage(companyId: string): Observable<CompanyLandingDataDto> {
    return this.apiService.companyGetLandingPage(companyId);
  }

  getUserCompanies(): Observable<CompanyDto[]> {
    return this.apiService.companyList();
  }

  create(company: CompanyDto): Observable<CompanyDto> {
    return this.apiService.companyCreate(company);
  }

  update(companyId: string, company: CompanyUpdateDto): Observable<CompanyDto> {
    return this.apiService.companyUpdate({ companyId, CompanyUpdateDto: company });
  }

  verify(companyId: string, verification: CompanyVerificationDto): Observable<boolean> {
    return this.apiService.companyVerificationV2({ companyId, CompanyVerificationDto: verification })
      .pipe(map(({ result }) => !!result));
  }

  setCompanyApproval(companyId: string, approved: boolean): Observable<boolean> {
    return this.apiService.adminSetCompanyApproval({ companyId, approved })
      .pipe(map(({ result = false }) => result));
  }

  get(companyId: string): Observable<CompanyViewDto> {
    return this.apiService.companyGet(companyId);
  }

  getStores(companyId: string): Observable<StoreMinimalDto[]> {
    return this.apiService.companyGetStores(companyId);
  }

  getMenus(companyId: string): Observable<MenuMinimalDto[]> {
    return this.apiService.companyGetMenus(companyId);
  }

  downloadTransferReport(
    transferBatch: GroupedMerchantTransfer, type: DownloadTransferType, format: DownloadTransferFormat,
  ): Observable<boolean> {
    const companyId = safeGet(transferBatch, t => t.transfers[0].companyId);
    const transferBatchId = safeGet(transferBatch, t => t.batchId);

    return this.apiService.companyDownloadTransferReport({ companyId, type, transferBatchId, format })
      .pipe(map(result => {
        const data = safeGet(result, r => r.data);
        const dateStart = safeGet(transferBatch, t => getDateMMDDYYYY(t.start));
        const dateEnd = safeGet(transferBatch, t => getDateMMDDYYYY(t.end));
        const companyName = safeGet(transferBatch, t => t.transfers[0].companyName, companyId || '');
        const safeCompanyName = companyName.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/-{2,}/g, '');
        const downloadFilename = `${safeCompanyName}-${dateStart}-${dateEnd}-${type}.${format}`;
        try {
          downloadStringToFile(downloadFilename, data);
          return true;
        } catch (error) {
          const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
          this.telemetryService.logError(errorMessage);
          return false;
        }
      }));
  }

  getTransfers(companyId: string): Observable<MerchantTransferDto[]> {
    return this.apiService.companyGetTransfers(companyId);
  }

  getTransfersGroupedByBatch(companyId: string): Observable<GroupedMerchantTransfer[]> {
    return this.getTransfers(companyId).pipe(map(transfers => {
      const transferBatchMap: { [id: string]: GroupedMerchantTransfer } = {};

      (transfers || []).forEach(transfer => {
        const start = new Date(transfer.start);
        const end = new Date(transfer.end);
        const batchId = transfer.transferStatus === 'new' ? 'pending' : transfer.transferBatchIdentifier || transfer.id;

        if (!transferBatchMap[batchId]) {
          transferBatchMap[batchId] = {
            batchId,
            start,
            end,
            emailed: 0,
            transferred: 0,
            total: 0,
            stores: new Set(),
            transfers: [],
          };
        }

        const targetBatch = transferBatchMap[batchId];

        if (targetBatch.start > start) {
          targetBatch.start = start;
        }
        if (targetBatch.end < end) {
          targetBatch.end = end;
        }
        if (transfer.emailTime) {
          targetBatch.emailed++;
        }
        if (transfer.transferTime) {
          targetBatch.transferred++;
        }
        targetBatch.total += parseInt(transfer.total, 10);
        targetBatch.stores.add(transfer.storeId);
        targetBatch.transfers.push(transfer);
      });

      return Object.keys(transferBatchMap).map(transferId => {
        return transferBatchMap[transferId];
      }).sort((a, b) => b.start.getTime() - a.start.getTime());
    }));
  }

  getReportSubscribers(companyId: string): Observable<CompanyReportSubscriberDto[]> {
    return this.apiService.companyReportSubscriberList(companyId);
  }

  addReportSubscriber(companyId: string, email: string, type: StoreReportType): Observable<CompanyReportSubscriberDto> {
    return this.apiService.companyReportSubscriberCreate({ companyId, CompanyReportSubscriberDto: { email, type } });
  }

  removeReportSubscriber(companyId: string, subscriberId: string): Observable<boolean> {
    return this.apiService.companyReportSubscriberDelete({
      companyId, subscriberId,
    }).pipe(map(({ result }) => result));
  }

  getUsers(companyId: string): Observable<UserMinimalWithRolesDto[]> {
    return this.apiService.companyGetUsers(companyId);
  }

  getInvites(companyId: string): Observable<UserMerchantInviteResponseDto[]> {
    return this.apiService.companyGetInvites(companyId);
  }

  cancelInvite(companyId: string, inviteId: string): Observable<boolean> {
    return this.apiService.companyCancelInvite({ companyId, inviteId })
      .pipe(map(({ result = false }) => result));
  }

  removeRole(companyId: string, userId: string, roleName: string): Observable<boolean> {
    return this.apiService.companyDeleteUserRole({ userId, companyId, roleName })
      .pipe(map(({ result = false }) => result));
  }

  addBankAccount(companyId: string, bankAccount: CompanyBankAccountAddDto) {
    return this.apiService.companyBankAccountAdd({ companyId, CompanyBankAccountAddDto: bankAccount });
  }

  addLandingHeroBackground(companyId: string, file: File, options: ImageCropOptions): Observable<string> {
    return this.apiService.companyLandingAddHeroBackground({ companyId, file, ...options }).pipe(map(({ url }) => url));
  }

  addLandingLogo(companyId: string, file: File): Observable<string> {
    return this.apiService.companyLandingAddLogo({ companyId, file }).pipe(map(({ url }) => url));
  }

  sendUserInvite(companyId: string, userData: CompanyCreateInviteRequestDto): Observable<boolean> {
    return this.apiService.companyInviteUser({ companyId, CompanyCreateInviteRequestDto: userData })
      .pipe(map(({ result = false }) => result));
  }

  new(): CompanyDto {
    return {
      name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      taxId: '',
    };
  }

}
