<div [hidden]="loading">

  <div class="user-info-info-message" *ngIf="infoMessage">
    {{infoMessage}}
  </div>

  <!-- USER LOGGED IN BUT NO PHOTO (photo upload) -->
  <div *ngIf="userInfoMode === 'photo'" fxLayout="column" fxLayoutAlign="center center">
    <h1 class="login-title">Let's see your pretty face</h1>
    <div class="no-photo-placeholder" *ngIf="!userProfilePhotoSaving && !profile?.photo" (click)="updatePhoto()">
      <mat-icon>person</mat-icon>
      Upload a photo
    </div>
    <div class="photo-explanation">
      This helps the barista recognize you when you arrive
    </div>
    <div>
      <a class="skip-step-link" (click)="skipForNow()">skip for now</a>
    </div>
  </div>

  <!-- USER LOGGED IN BUT NO EMAIL YET (prompt for email/password) -->
  <div *ngIf="userInfoMode === 'email'">
    <h1 class="login-title">Enter an email / password</h1>
    <form #updateEmailForm="ngForm" (submit)="onEmailSubmit(updateEmailForm.value, $event)">
      <mat-form-field class="full-width">
        <input matInput type="email" name="email" placeholder="Email" required ngModel>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput type="password" name="password" placeholder="Password" required ngModel>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput type="password" name="passwordConfirm" placeholder="Confirm Password" required ngModel>
      </mat-form-field>
      <div>
        <button mat-flat-button color="primary" fxFlex type="submit" [disabled]="updateEmailForm.invalid">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
      <div fxLayoutAlign="center center">
        <a class="skip-step-link" (click)="skipForNow()">skip for now</a>
      </div>
    </form>
  </div>

</div>

<!-- PROGRESS BAR -->
<div class="code-verification-progress" *ngIf="loading">
  <p>Processing...</p>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>