import { ModuleWithProviders } from '@angular/core';
import { LoadingIndicatorService } from './services/loading-indicator/loading-indicator.service';
import { MatDialogModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
];
var UiLoadingIndicatorModule = (function () {
    function UiLoadingIndicatorModule() {
    }
    UiLoadingIndicatorModule.forRoot = function () {
        return {
            ngModule: UiLoadingIndicatorModule,
            providers: [LoadingIndicatorService],
        };
    };
    return UiLoadingIndicatorModule;
}());
export { UiLoadingIndicatorModule };
