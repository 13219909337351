var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { MatBottomSheetRef, MatSnackBar } from '@angular/material';
import { Big } from 'big.js';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { NativePaymentService, MobilePaymentProvider } from 'src/app/shared/packages/native-support/services/native-payment/native-payment.service';
import { CheckoutService } from 'src/app/consumer/services/checkout/checkout.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { OrderService } from 'src/app/consumer/services/order/order.service';
var DEFAULT_TIP_RATES = [0.15, 0.20, 0.25];
var StoreCheckoutBottomsheetComponent = (function () {
    function StoreCheckoutBottomsheetComponent(data, bottomSheetRef, identityService, checkoutService, orderService, userService, changeDetectorRef, snackbar, nativePayment) {
        this.data = data;
        this.bottomSheetRef = bottomSheetRef;
        this.identityService = identityService;
        this.checkoutService = checkoutService;
        this.orderService = orderService;
        this.userService = userService;
        this.changeDetectorRef = changeDetectorRef;
        this.snackbar = snackbar;
        this.nativePayment = nativePayment;
        this.otherTip = '400';
        this.verificationTryCount = 0;
        this.paymentIcons = {
            'Visa': 'visa',
            'MasterCard': 'mastercard',
            'American Express': 'amex',
            'Discover': 'discover',
            'JCB': 'jcb',
            'Diners Club': 'diners',
            'bitcoin': 'bitcoin',
            'gpay': 'gpay',
            'apay': 'apay',
        };
        this.tip = '0';
        this.currentTime = Date.now();
        this.selectedScheduleTime = 0;
    }
    Object.defineProperty(StoreCheckoutBottomsheetComponent.prototype, "chargeButtonElementRef", {
        set: function (el) {
            this.chargeButtonRef = el;
        },
        enumerable: true,
        configurable: true
    });
    StoreCheckoutBottomsheetComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.joeBucksUploadMode = this.data.joeBucksOnly;
                        this.todayStoreHours = this.data.todayStoreHours;
                        this.loading = true;
                        user = this.identityService.getActiveUser();
                        if (user && user.id) {
                            this.userId = user.id;
                            this.joeBucksQuickUploadAmount = user.lastUploadFundsAmount;
                        }
                        this.tipDisabledMessage = this.data.tipDisabledMessage;
                        this.populateTipAmounts();
                        this.tipChange();
                        return [4, this.populatePaymentMethods()];
                    case 1:
                        _a.sent();
                        return [4, this.paymentMethodChange()];
                    case 2:
                        _a.sent();
                        this.updateTipVisibility();
                        this.loading = false;
                        this.changeDetectorRef.markForCheck();
                        this.currentTimeInterval = setInterval(this.updateCurrentTime.bind(this), 1000);
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.ngOnDestroy = function () {
        this.setErrorMessage();
        clearInterval(this.currentTimeInterval);
    };
    StoreCheckoutBottomsheetComponent.prototype.tipChange = function () {
        var tip = this.tipSelection === '+' ? this.otherTip : this.tipSelection;
        this.tip = isNaN(parseInt(tip, 10)) ? '0' : tip;
        this.invalidTip = Big(this.tip).gt(0) && Big(this.tip).lt(50);
        this.updateChargeTotal();
    };
    StoreCheckoutBottomsheetComponent.prototype.getSelectedPaymentSourceType = function () {
        var paymentMethod = 'card_existing';
        if (typeof this.selectedPaymentMethod === 'string') {
            if (this.selectedPaymentMethod === 'joebucks') {
                paymentMethod = 'joebucks';
            }
            else if (this.selectedPaymentMethod === 'new' || this.selectedPaymentMethod === 'new-fake') {
                paymentMethod = 'card_new';
            }
        }
        else {
            if (this.selectedPaymentMethod.type === 'apay' || this.selectedPaymentMethod.type === 'gpay') {
                paymentMethod = 'mobile_pay';
            }
        }
        return paymentMethod;
    };
    StoreCheckoutBottomsheetComponent.prototype.paymentMethodChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var paymentMethod, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.data || !this.data.order || !this.selectedPaymentMethod) {
                            return [2];
                        }
                        paymentMethod = this.getSelectedPaymentSourceType();
                        _a = this.data;
                        return [4, this.orderService.getOrderPricing(this.data.order, paymentMethod).toPromise()];
                    case 1:
                        _a.orderPricing = _b.sent();
                        this.canUseFakeCard = this.data.orderPricing.storeRoles && this.data.orderPricing.storeRoles.length > 0;
                        this.updateServiceFeeDisplay();
                        this.updateChargeTotal();
                        this.updateTipVisibility();
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.verifyNumberOnlyInput = function (event) {
        var elem = event.target;
        elem.value = elem.value.replace(/[^0-9]+/g, '');
        return elem.value === '' ? false : true;
    };
    StoreCheckoutBottomsheetComponent.prototype.cancel = function () {
        if (this.joeBucksUploadMode && !this.data.joeBucksOnly) {
            this.joeBucksUploadMode = false;
            this.joeBucksUploadAmount = undefined;
            this.selectedPaymentMethod = 'joebucks';
            this.updateChargeTotal();
            this.changeDetectorRef.markForCheck();
            return;
        }
        this.bottomSheetRef.dismiss();
        this.setErrorMessage();
    };
    StoreCheckoutBottomsheetComponent.prototype.onFocusField = function (event) {
        var _this = this;
        if (this.chargeButtonRef && this.chargeButtonRef.nativeElement && this.chargeButtonRef.nativeElement.scrollIntoView) {
            setTimeout(function () { return _this.chargeButtonRef.nativeElement.scrollIntoView(); }, 500);
        }
    };
    StoreCheckoutBottomsheetComponent.prototype.checkout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var bigTip, token, newDefaultPaymentId, fake, cardType, paymentType, result, wasJoeBucksVirgin, _a, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.setErrorMessage();
                        bigTip = Big(this.tip);
                        if (bigTip.gt(400) && bigTip.gt(this.data.orderPricing.total)) {
                            this.setErrorMessage('Tip cannot exceed 100% of total');
                            return [2];
                        }
                        if (typeof this.selectedPaymentMethod === 'object') {
                            token = this.selectedPaymentMethod.id;
                            fake = this.selectedPaymentMethod.fake;
                            cardType = this.selectedPaymentMethod.type;
                        }
                        else {
                            cardType = this.selectedPaymentMethod;
                        }
                        paymentType = 'card_existing';
                        if (!token && cardType !== 'joebucks') {
                            if (!this.newCardToken) {
                                this.setErrorMessage('Invalid credit card data.');
                                return [2];
                            }
                            fake = cardType === 'new-fake';
                            if (fake && this.joeBucksUploadMode) {
                                this.setErrorMessage('Cannot use fake cards to preload funds.');
                                return [2];
                            }
                            if (fake && !confirm('You are adding a fake card. Purchases made with this card will not be filled.')) {
                                return [2];
                            }
                        }
                        this.processingCheckout = true;
                        newDefaultPaymentId = token;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 14, , 15]);
                        if (!(cardType === 'apay' || cardType === 'gpay')) return [3, 4];
                        if (!Big(this.chargeTotal || '0').gt(0)) return [3, 3];
                        return [4, this.nativePayment.createMobilePayment({ label: 'Joe Coffee Order', amount: this.chargeTotal })];
                    case 2:
                        token = _c.sent();
                        _c.label = 3;
                    case 3:
                        paymentType = 'mobile_pay';
                        return [3, 5];
                    case 4:
                        if (cardType === 'new' || cardType === 'new-fake') {
                            token = this.newCardToken;
                            if (!fake) {
                                newDefaultPaymentId = token;
                            }
                            paymentType = 'card_new';
                        }
                        else if (cardType === 'joebucks' && !this.joeBucksUploadMode) {
                            paymentType = 'joebucks';
                        }
                        _c.label = 5;
                    case 5:
                        if (!token && paymentType !== 'joebucks') {
                            this.setErrorMessage('Payment method failed.');
                            this.processingCheckout = false;
                            this.changeDetectorRef.markForCheck();
                            return [2];
                        }
                        result = {};
                        wasJoeBucksVirgin = this.joeBucksVirgin;
                        if (!this.joeBucksUploadMode) return [3, 11];
                        if (!(paymentType === 'card_new' || paymentType === 'card_existing' || paymentType === 'mobile_pay')) return [3, 9];
                        _a = result;
                        return [4, this.checkoutService.uploadJoeBucks({
                                amount: this.chargeTotal,
                                paymentSource: { type: paymentType, token: token },
                            }).toPromise()];
                    case 6:
                        _a.joebucksBalance = _c.sent();
                        this.identityService.setLastUploadFundsAmount(this.chargeTotal);
                        if (!(paymentType === 'card_new')) return [3, 8];
                        return [4, this.populatePaymentMethods()];
                    case 7:
                        _c.sent();
                        _c.label = 8;
                    case 8: return [3, 10];
                    case 9:
                        this.setErrorMessage('Invalid payment method for adding funds.');
                        this.processingCheckout = false;
                        this.changeDetectorRef.markForCheck();
                        return [2];
                    case 10: return [3, 13];
                    case 11:
                        _b = result;
                        return [4, this.checkoutService.checkout({
                                order: __assign({}, this.data.order, { tip: this.tip, fake: fake }),
                                paymentSource: { type: paymentType, token: token },
                                pickupDelayMinutes: this.selectedScheduleTime,
                                orderIdempotencyKey: this.data.orderIdempotencyKey,
                            }).toPromise()];
                    case 12:
                        _b.order = _c.sent();
                        _c.label = 13;
                    case 13:
                        result.paymentMethod = paymentType;
                        if (!fake && newDefaultPaymentId) {
                            this.userService.setDefaultPaymentMethod(newDefaultPaymentId);
                        }
                        if (this.joeBucksUploadMode && !this.data.joeBucksOnly && this.data.order && this.data.orderPricing && !wasJoeBucksVirgin) {
                            this.joeBucksBalance = result.joebucksBalance.balance;
                            this.joeBucksUploadMode = false;
                            this.joeBucksUploadAmount = undefined;
                            this.selectedPaymentMethod = 'joebucks';
                            this.updateChargeTotal();
                            this.snackbar.open('Funds added successfully.', '', { duration: 5000, verticalPosition: 'top' });
                        }
                        else {
                            this.bottomSheetRef.dismiss(result);
                        }
                        return [3, 15];
                    case 14:
                        e_1 = _c.sent();
                        this.apiErrorHandler(e_1);
                        return [3, 15];
                    case 15:
                        this.processingCheckout = false;
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.onStripeTokenUpdate = function (token) {
        this.newCardToken = token;
    };
    StoreCheckoutBottomsheetComponent.prototype.updateCurrentTime = function () {
        if (this.joeBucksUploadMode || this.tipSelection === undefined) {
            return;
        }
        this.currentTime = Date.now();
        this.changeDetectorRef.markForCheck();
    };
    StoreCheckoutBottomsheetComponent.prototype.updateTipVisibility = function () {
        if (this.tipDisabledMessage ||
            !this.data || !this.data.orderPricing ||
            (Big(this.data.orderPricing.total || '0').lt(1) && this.selectedPaymentMethod !== 'joebucks')) {
            this.hideTip = true;
            this.tipSelection = '0';
            this.tipChange();
        }
        else {
            this.hideTip = false;
        }
    };
    StoreCheckoutBottomsheetComponent.prototype.updateChargeTotal = function () {
        if (this.joeBucksUploadMode) {
            this.chargeTotal = this.joeBucksUploadAmount;
            this.joeBucksBalanceAfterUpload = parseInt(Big(this.joeBucksBalance || '0').plus(this.chargeTotal || '0').toString(), 10);
        }
        else {
            this.chargeTotal = Big(this.data.orderPricing.total).plus(this.tip).toString();
            this.remainingJoeBucksAfterPurchase = Big(this.joeBucksBalance || '0').minus(this.chargeTotal || '0').toString();
        }
        this.changeDetectorRef.markForCheck();
    };
    StoreCheckoutBottomsheetComponent.prototype.updateServiceFeeDisplay = function () {
        var serviceFee = this.data.orderPricing.charges && this.data.orderPricing.charges.find(function (charge) { return charge.category === 'service-fee'; });
        this.orderServiceFee = serviceFee && serviceFee.amount;
    };
    StoreCheckoutBottomsheetComponent.prototype.populateTipAmounts = function () {
        var _this = this;
        if (!this.data.orderPricing) {
            return;
        }
        var total = Big(this.data.orderPricing.total);
        this.tipAmounts = [];
        DEFAULT_TIP_RATES.forEach(function (rate, i) {
            var lastTip = i > 0 ? _this.tipAmounts[i - 1] : '0';
            var tipAmountBig = total.times(rate).div(50).round(0).times(50);
            var tipAmount = tipAmountBig.lt(50) ? '50' : tipAmountBig.toString();
            _this.tipAmounts[i] = Big(tipAmount).lte(lastTip) ? Big(lastTip).plus(50).toString() : tipAmount;
        });
        this.otherTip = Big(this.tipAmounts[this.tipAmounts.length - 1]).plus(100).toString();
    };
    StoreCheckoutBottomsheetComponent.prototype.populatePaymentMethods = function () {
        return __awaiter(this, void 0, void 0, function () {
            var joeBucksBalanceStatus, defaultPaymentMethod, paymentMethods, e_2, realCards, fakeCards;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.unableToLoadPaymentMethods = false;
                        if (!this.userId) {
                            this.paymentMethods = undefined;
                            this.fakePaymentMethods = undefined;
                            return [2];
                        }
                        return [4, this.userService.getJoeBucksBalanceStatus().toPromise()];
                    case 1:
                        joeBucksBalanceStatus = _a.sent();
                        this.joeBucksBalance = joeBucksBalanceStatus.balance;
                        this.joeBucksVirgin = joeBucksBalanceStatus.virgin;
                        return [4, this.userService.getDefaultPaymentMethod()];
                    case 2:
                        defaultPaymentMethod = _a.sent();
                        paymentMethods = { realCards: [], fakeCards: [] };
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4, this.userService.getPaymentCards().toPromise()];
                    case 4:
                        paymentMethods = _a.sent();
                        return [3, 6];
                    case 5:
                        e_2 = _a.sent();
                        this.unableToLoadPaymentMethods = true;
                        return [3, 6];
                    case 6:
                        realCards = paymentMethods.realCards, fakeCards = paymentMethods.fakeCards;
                        this.paymentMethods = realCards;
                        return [4, this.nativePayment.supportsMobilePay()];
                    case 7:
                        switch (_a.sent()) {
                            case MobilePaymentProvider.apple:
                                this.paymentMethods.unshift({
                                    type: 'apay',
                                    last4: 'Apple Pay',
                                    defaultCard: true,
                                    id: 'apple',
                                    country: 'US',
                                });
                                break;
                            case MobilePaymentProvider.google:
                                this.paymentMethods.unshift({
                                    type: 'gpay',
                                    last4: 'Google Pay',
                                    defaultCard: true,
                                    id: 'google',
                                    country: 'US',
                                });
                                break;
                        }
                        this.fakePaymentMethods = fakeCards.map(function (card) { return (__assign({}, card, { fake: true })); });
                        this.selectedPaymentMethod = undefined;
                        if (defaultPaymentMethod !== undefined) {
                            this.selectedPaymentMethod = this.paymentMethods.find(function (p) { return p.id === defaultPaymentMethod; });
                        }
                        if (!this.joeBucksUploadMode && (Big(this.joeBucksBalance || '0').gt(0) || Big(this.chargeTotal).eq(0))) {
                            this.selectedPaymentMethod = 'joebucks';
                        }
                        if (!this.selectedPaymentMethod) {
                            if (this.paymentMethods && this.paymentMethods.length > 0) {
                                this.selectedPaymentMethod = this.paymentMethods[this.paymentMethods.length > 1 ? 1 : 0];
                            }
                            else if (this.fakePaymentMethods && this.fakePaymentMethods.length > 0) {
                                this.selectedPaymentMethod = this.fakePaymentMethods[0];
                            }
                            else {
                                this.selectedPaymentMethod = 'new';
                            }
                        }
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.switchToJoeBalance = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.selectedPaymentMethod = 'joebucks';
                        return [4, this.paymentMethodChange()];
                    case 1:
                        _a.sent();
                        this.changeDetectorRef.markForCheck();
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.showAddFunds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var activeUser;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        activeUser = this.identityService.getActiveUser();
                        if (!activeUser.hasEmail) {
                            this.bottomSheetRef.dismiss({ needEmail: true });
                            return [2];
                        }
                        this.joeBucksUploadMode = true;
                        this.updateChargeTotal();
                        return [4, this.populatePaymentMethods()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.quickAddFunds = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.showAddFunds()];
                    case 1:
                        _a.sent();
                        this.joeBucksUploadAmount = this.joeBucksQuickUploadAmount;
                        this.updateChargeTotal();
                        this.checkout();
                        return [2];
                }
            });
        });
    };
    StoreCheckoutBottomsheetComponent.prototype.joeBucksUploadAmountChange = function () {
        this.updateChargeTotal();
    };
    StoreCheckoutBottomsheetComponent.prototype.isCheckoutDisabled = function () {
        return (!this.newCardToken && (this.selectedPaymentMethod === 'new' || this.selectedPaymentMethod === 'new-fake')) ||
            this.invalidTip ||
            (!this.joeBucksUploadMode && this.tipSelection === undefined) ||
            (this.joeBucksUploadMode && this.joeBucksUploadAmount === undefined) ||
            (this.selectedPaymentMethod === 'joebucks' && Big(this.remainingJoeBucksAfterPurchase || '0').lt(0)) ||
            (this.joeBucksUploadMode && Big(this.joeBucksBalanceAfterUpload).gte(15000));
    };
    StoreCheckoutBottomsheetComponent.prototype.setErrorMessage = function (message) {
        if (message) {
            this.snackbar.open("Error: " + message, '', { verticalPosition: 'top', duration: 10000, panelClass: 'error-toast' });
        }
        else {
            this.snackbar.dismiss();
        }
        this.changeDetectorRef.markForCheck();
    };
    StoreCheckoutBottomsheetComponent.prototype.apiErrorHandler = function (error) {
        if (typeof error === 'string') {
            return this.setErrorMessage(error);
        }
        if (error && error.error && error.error.message && error.error.message.length > 0) {
            return this.setErrorMessage(Array.isArray(error.error.message) ? error.error.message[0] : error.error.message);
        }
        this.setErrorMessage('Unknown error. Please try again.');
    };
    return StoreCheckoutBottomsheetComponent;
}());
export { StoreCheckoutBottomsheetComponent };
