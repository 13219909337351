import { OnDestroy, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Big } from 'big.js';
import { MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
var MatCurrencyInputComponent = (function () {
    function MatCurrencyInputComponent(ngControl, focusMonitor, elementRef) {
        var _this = this;
        this.ngControl = ngControl;
        this.focusMonitor = focusMonitor;
        this.elementRef = elementRef;
        this.stateChanges = new Subject();
        this.focused = false;
        this.errorState = false;
        this.controlType = 'mat-currency-input';
        this.describedBy = '';
        this.id = "mat-currency-input-" + MatCurrencyInputComponent.nextId++;
        this.selectOnFocus = false;
        this.requiredInternal = false;
        this.disabledInternal = false;
        this.propagateChange = function (_) { };
        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
        focusMonitor.monitor(elementRef.nativeElement, true).subscribe(function (origin) {
            if (!origin) {
                _this.writeValue(_this.ngControl.value);
            }
            _this.focused = !!origin;
            _this.stateChanges.next();
        });
    }
    Object.defineProperty(MatCurrencyInputComponent.prototype, "shouldLabelFloat", {
        get: function () {
            return this.focused || !this.empty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatCurrencyInputComponent.prototype, "value", {
        set: function (value) {
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatCurrencyInputComponent.prototype, "empty", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatCurrencyInputComponent.prototype, "placeholder", {
        get: function () {
            return this.placeholderInternal;
        },
        set: function (plh) {
            this.placeholderInternal = plh;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatCurrencyInputComponent.prototype, "required", {
        get: function () {
            return this.requiredInternal;
        },
        set: function (req) {
            this.requiredInternal = !!req;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatCurrencyInputComponent.prototype, "disabled", {
        get: function () {
            return this.disabledInternal;
        },
        set: function (dis) {
            this.disabledInternal = !!dis;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    MatCurrencyInputComponent.prototype.onChange = function (value) {
        this.propagateChange(Big(value || '0').times(100).toString());
    };
    MatCurrencyInputComponent.prototype.writeValue = function (value) {
        this.dollarValue = Big(value || '0').div(100).toFixed(2);
    };
    MatCurrencyInputComponent.prototype.ngOnDestroy = function () {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    };
    MatCurrencyInputComponent.prototype.setDescribedByIds = function (ids) {
        this.describedBy = ids.join(' ');
    };
    MatCurrencyInputComponent.prototype.onContainerClick = function (event) {
        if (!event || !event.target) {
            return;
        }
        if (event.target.tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    };
    MatCurrencyInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    MatCurrencyInputComponent.prototype.registerOnTouched = function () { };
    MatCurrencyInputComponent.nextId = 0;
    return MatCurrencyInputComponent;
}());
export { MatCurrencyInputComponent };
