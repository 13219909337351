import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'microtime',
})
export class MicrotimePipe implements PipeTransform {

  transform(microtime: number): string {
    const totalSeconds = Math.floor(microtime / 1000);
    const seconds = totalSeconds % 60;
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const hours = Math.floor(totalSeconds / 3600) % 3600;

    return this.formatTime(seconds, minutes, hours);
  }

  private formatTime(s: number, m: number, h: number): string {
    const format = (show: boolean, value: number, suffix = '') => show ? ('00' + value).slice(-2) + suffix : '';

    const hours = format(!!h, h, ':');
    const minutes = format(!!(m || h), m, ':');
    const seconds = format(true, s, ((m || h) ? '' : ' seconds'));
    return hours + minutes + seconds;
  }

}
