import { MatDialogModule, MatExpansionModule, MatButtonModule, MatIconModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
];
var UiErrorDialogModule = (function () {
    function UiErrorDialogModule() {
    }
    return UiErrorDialogModule;
}());
export { UiErrorDialogModule };
