<div class="consumer-viewport">
  <div class="consumer-alert-overlay" *ngIf="showAlertOverlay && alertOverlayType">
    <div class="consumer-alert-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
      <ng-container
        *ngTemplateOutlet="alertOverlayType === 'reactivation' ? reactivateUserAlertOverlay : uploadFundsAlertOverlay">
      </ng-container>
    </div>
  </div>
  <consumer-topbar [show]="showTopbar" [drawerOpen]="opened" (onLogout)="logout()" (onToggleMenu)="opened = !opened">
  </consumer-topbar>

  <mat-sidenav-container class="full-height">
    <mat-sidenav mode="over" [(opened)]="opened" #sidenav>
      <div class="sidebar-wrap">
        <consumer-sidebar [sideNavRef]="sidenav"></consumer-sidebar>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="full-height content-wrap">
      <div class="router-outlet-wrap">
        <router-outlet></router-outlet>
        <div></div>
      </div>
      <bottombar></bottombar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #reactivateUserAlertOverlay>
  <h1 class="alert-title">{{reactivationDiscount.amount | centCurrency}} PROMO!</h1>
  <p class="alert-paragraph">
    Place an order now and you'll automatically get {{reactivationDiscount.amount | centCurrency}} off at checkout.<br>
    <span class="paragraph-subtext">(valid on orders of $3 or more)</span>
  </p>
  <img src="public-web-assets/images/joe-reward-wreath-cream.png" class="alert-reward-image">
  <p class="alert-paragraph" *ngIf="reactivationExpiration && currentTime">
    Expires in <strong>{{ reactivationExpiration - currentTime | microtime }}</strong>
  </p>
  <button mat-flat-button color="light" class="add-funds-button e2e-alert-dismiss"
    (click)="dismissAlert()">Close</button>
</ng-template>

<ng-template #uploadFundsAlertOverlay>
  <h1 class="alert-title">SAVE MORE WHEN YOU PAY AHEAD!</h1>
  <p class="alert-paragraph">Now you can pre-load funds to your joe account, and <strong>skip the service
      fee</strong>.
  </p>
  <img src="public-web-assets/images/joe-reward-wreath-cream.png" class="alert-reward-image">
  <p class="alert-paragraph">Also, for a limited time, you'll instantly earn a reward the first time you add
    funds.<br><span class="paragraph-subtext">(up to $6 off any item)</span>
  </p>
  <button mat-flat-button color="light" class="add-funds-button" (click)="showAddFunds()">Add Funds Now</button>
  <a (click)="dismissAlert()" class="nah-maybe-later-link e2e-alert-dismiss">Nah, maybe later</a>
</ng-template>