import { Injectable } from '@angular/core';
import { merge, cloneDeep } from 'lodash';
import { MenuModel, MenuItemChoiceFlags } from 'src/app/merchant/services/menu/menu.service';
import { MenuSectionDto, MenuOptionWithChoicesDto, MenuExportDto, MenuIterationDto, MenuItemOptionSizePricesDto } from 'src/gen/joeServerCore';
import { MergeMenuSection, MergeMenu } from '../../models/merge-menu/merge-menu';

// temporarily allow free items for coffee fest (revert after)
const MINIMUM_ITEM_PRICE = 15;

@Injectable()
export class MenuSerializerService {

  constructor() { }

  public filterMenuSection(section: MenuSectionDto, options: MenuOptionWithChoicesDto[]): MergeMenuSection {
    const mergeSection = Object.assign({}, section) as MergeMenuSection;

    mergeSection.items = (mergeSection.items || []).filter(item => {
      let outOfStockCounter = 0;
      item.sizes = item.sizes.filter(size => {
        if (size.inventory && size.inventory.length > 0 && size.inventory[0].quantity === 0) {
          outOfStockCounter++;
        }
        return parseInt(size.price, 10) >= MINIMUM_ITEM_PRICE;
      });

      const isValidItem = !!item.sizes && (item.sizes.length > 0) && item.enabled;

      if (outOfStockCounter === item.sizes.length) {
        item.outOfStock = true;
      }

      if (isValidItem) {
        item.options = item.options.map(option => {
          const optionIndex = options.findIndex(opt => opt.id === option.id);

          if (optionIndex === -1) {
            return;
          }

          const mergedChoices = options[optionIndex].choices.map(choice => {
            const choiceIndex = option.choices.findIndex(chc => chc.id === choice.id);
            if (choiceIndex !== -1) {
              return {
                id: choice.id,
                flags: option.choices[choiceIndex].flags,
                name: choice.name,
                price: choice.price,
              };
            } else {
              return {
                id: choice.id,
                flags: [],
                name: choice.name,
                price: choice.price,
              };
            }
          }).filter(choice => !!choice);
          const mergedOptions = merge(option, options[optionIndex]);
          mergedOptions.choices = mergedChoices;
          return mergedOptions;
        });
      }
      return isValidItem;
    });

    return mergeSection;
  }

  public filterMenu(menuExport: MenuExportDto): MergeMenu {
    const menu = menuExport.menu as MergeMenu;
    const options = menuExport.options;
    const filteredMenu: MergeMenu = Object.assign({}, menu);

    filteredMenu.options = menuExport.options;

    filteredMenu.categories = (menu.categories || []).map(category => {
      category.sections = (category.sections || []).map(section => this.filterMenuSection(section, options));
      return category;
    });

    return filteredMenu;

  }

  public createMenuDtoFromMenuModel(menuData: MenuModel): MenuIterationDto {
    const choiceFlagNames = new Set(['free', 'default', 'hidden', 'required']);

    return {
      id: menuData.id || '',
      name: menuData.name,
      metaData: { itemTagLimits: menuData.itemTagLimits || [] },
      // fix null value
      parentMenuId: menuData.parentMenuId || undefined,
      categories: menuData.categories.map(category => ({
        id: category.id || '',
        name: category.name,
        sections: category.sections.map(section => ({
          id: section.id || '',
          name: section.name,
          items: section.items.map(item => ({
            id: item.id || '',
            name: item.name,
            description: item.description || '',
            enabled: !!item.enabled,
            taxExempt: item.taxExempt,
            accountingId: item.accountingId,
            metaData: { tags: item.tags || [] },
            image: item.image,
            sizes: item.sizes.map(size => (cloneDeep(size))),
            options: item.options.map(option => {

              const mappedChoices: { id: string, flags: string[] }[] = [];
              let sizePrices: MenuItemOptionSizePricesDto[] = [];
              const sizePriceMap: { [uniqueKey: string]: MenuItemOptionSizePricesDto } = {};

              option.choices.forEach(choice => {

                if (choice.sizePrices && choice.sizePrices.length > 0) {
                  choice.sizePrices.forEach(sizePrice => {
                    const uniqueKey = sizePrice.menuSizeId + '--' + sizePrice.price;

                    if (sizePriceMap.hasOwnProperty(uniqueKey)) {
                      sizePriceMap[uniqueKey].menuChoiceId.push(sizePrice.menuChoiceId);
                    } else {
                      sizePriceMap[uniqueKey] = {
                        menuChoiceId: [sizePrice.menuChoiceId],
                        menuSizeId: sizePrice.menuSizeId,
                        menuOptionId: option.id,
                        price: sizePrice.price,
                      };
                    }
                  });

                  sizePrices = Object.keys(sizePriceMap).map(sizePriceKey => sizePriceMap[sizePriceKey]);
                }

                // map choice flags
                const flags = Object.keys(choice).filter((flag: MenuItemChoiceFlags) => choiceFlagNames.has(flag) && choice[flag] === true);
                if (flags.length > 0) {
                  mappedChoices.push({
                    id: choice.id,
                    flags,
                  });
                }
              });

              return {
                id: option.id,
                minimumChoices: option.minChoices,
                maximumChoices: option.maxChoices,
                choices: mappedChoices,
                sizePrices,
              };
            }),
          })),
        })),
      })),
    };

  }

}
