import { Injectable } from '@angular/core';

const VALID_CHARS_REGEXP = /[^a-z0-9]/ig;

@Injectable()
export class StringUtilService {

  urlify(str: string): string {
    return str.replace(VALID_CHARS_REGEXP, '-').toLowerCase();
  }
}
