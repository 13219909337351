import * as i0 from "./user-verification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i13 from "@angular/material/progress-bar";
import * as i14 from "@angular/common";
import * as i15 from "./user-verification.component";
var styles_UserVerificationComponent = [i0.styles];
var RenderType_UserVerificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserVerificationComponent, data: {} });
export { RenderType_UserVerificationComponent as RenderType_UserVerificationComponent };
function View_UserVerificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invalid code please try again"]))], null, null); }
function View_UserVerificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "fake-form-field"], ["fxFlex", "15"]], [[2, "active", null]], null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "fake-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = "15"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeInput === _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
function View_UserVerificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["fxLayout", "row"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "button", [["color", "primary"], ["form", "ngForm"], ["fxFlex", ""], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(4, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(5, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back_ios"])), (_l()(), i1.ɵted(-1, 0, [" Back "])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "button", [["color", "primary"], ["fxFlex", ""], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onResendCode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(12, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["refresh"])), (_l()(), i1.ɵted(-1, 0, [" Resend "]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "10px"; _ck(_v, 2, 0, currVal_1); var currVal_4 = ""; _ck(_v, 4, 0, currVal_4); var currVal_5 = "primary"; _ck(_v, 5, 0, currVal_5); _ck(_v, 7, 0); var currVal_10 = ""; _ck(_v, 11, 0, currVal_10); var currVal_11 = "primary"; _ck(_v, 12, 0, currVal_11); _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 5).disabled || null); var currVal_3 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_6 = i1.ɵnov(_v, 7).inline; var currVal_7 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_6, currVal_7); var currVal_8 = (i1.ɵnov(_v, 12).disabled || null); var currVal_9 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵnov(_v, 14).inline; var currVal_13 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_12, currVal_13); }); }
function View_UserVerificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "progress-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sending..."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i12.View_MatProgressBar_0, i12.RenderType_MatProgressBar)), i1.ɵdid(4, 4374528, null, 0, i13.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i9.ANIMATION_MODULE_TYPE], [2, i13.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.sendCodeProgress; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).value; var currVal_1 = i1.ɵnov(_v, 4).mode; var currVal_2 = i1.ɵnov(_v, 4)._isNoopAnimation; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UserVerificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inputField: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserVerificationComponent_1)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" Sending a verification code to "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" now! "])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "verification-input-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["verificationInput", 1]], null, 0, "input", [["class", "verification-input"], ["novalidate", ""], ["pattern", "[0-9]*"], ["type", "tel"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.verificationInputKey($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "space-between"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(12, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserVerificationComponent_2)), i1.ɵdid(14, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserVerificationComponent_3)), i1.ɵdid(16, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserVerificationComponent_4)), i1.ɵdid(18, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tryCount > 0); _ck(_v, 3, 0, currVal_0); var currVal_2 = "row"; _ck(_v, 11, 0, currVal_2); var currVal_3 = "space-between"; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.digits; _ck(_v, 14, 0, currVal_4); var currVal_5 = (_co.sendCodeProgress === undefined); _ck(_v, 16, 0, currVal_5); var currVal_6 = (_co.sendCodeProgress !== undefined); _ck(_v, 18, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.phoneNumberNormalized || "you"); _ck(_v, 6, 0, currVal_1); }); }
export function View_UserVerificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-verification", [], null, null, null, View_UserVerificationComponent_0, RenderType_UserVerificationComponent)), i1.ɵdid(1, 4308992, null, 0, i15.UserVerificationComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserVerificationComponentNgFactory = i1.ɵccf("user-verification", i15.UserVerificationComponent, View_UserVerificationComponent_Host_0, { phoneNumber: "phoneNumber", tryCount: "tryCount", code: "code" }, { done: "done", resendCode: "resendCode" }, []);
export { UserVerificationComponentNgFactory as UserVerificationComponentNgFactory };
