import { Injectable } from '@angular/core';
import { NativeSocialShareService, NativeSharePayload } from '../../packages/native-support/services/native-social-share/native-social-share.service';

// smart link will redirect based off of platform
const JOE_APP_STORE_SMART_LINK = 'https://get.joe.coffee';

export const SOCIAL_SHARE_DEFAULT_MESSAGE = 'Get the coffee you love without the wait! Download the joe coffee app.';
export const SOCIAL_SHARE_DEFAULT_SUBJECT = 'Joe Coffee Mobile Order Ahead';
export const SOCIAL_SHARE_FAILED = 'Sharing is not supported on this app version. Please update via the app store.';

export interface WebSharePayload {
  title?: string;
  text?: string;
  url?: string;
}

export type PayloadType = 'web' | 'native';

export interface SharePayload extends WebSharePayload, NativeSharePayload {
  payloadType?: PayloadType;
}

@Injectable()
export class SocialShareService {

  constructor(
    private nativeSocialShare: NativeSocialShareService,
  ) { }

  public async openShareSheet(
    message?: string,
    subject?: string,
    url?: string,
  ): Promise<SharePayload> {
    const nativeShareAvailable = await this.nativeSocialShare.nativeShareAvailable();

    if (nativeShareAvailable) {
      const nativeShare = await this.useNativeShare(message, subject, url) as SharePayload;
      nativeShare.payloadType = 'native';
      return nativeShare;
    } else {
      const webShare = this.useWebShare(message, subject, url) as SharePayload;
      webShare.payloadType = 'web';
      return webShare;
    }
  }

  private useNativeShare(
    message?: string,
    subject?: string,
    url?: string,
  ): Promise<NativeSharePayload> {
    const sharePayload: NativeSharePayload = {
      message: message || SOCIAL_SHARE_DEFAULT_MESSAGE,
      subject: subject || SOCIAL_SHARE_DEFAULT_SUBJECT,
      url: url || JOE_APP_STORE_SMART_LINK,
    };

    return this.nativeSocialShare.share(sharePayload);
  }

  private useWebShare(text: string, title: string, url: string): Promise<WebSharePayload> {
    if (!(<any>navigator).share) {
      return Promise.reject(SOCIAL_SHARE_FAILED);
    }

    return (<any>navigator).share({
      title: title || SOCIAL_SHARE_DEFAULT_SUBJECT,
      text: text || SOCIAL_SHARE_DEFAULT_MESSAGE,
      url: url || JOE_APP_STORE_SMART_LINK,
    });
  }

}
