import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';

export type StripeElementChangeHandler = (event: stripe.elements.ElementChangeResponse) => void;

/**
 * Adds missing addEventListener to element interface
 */
export interface PatchedStripeElement extends stripe.elements.Element {
  addEventListener: (event: string, handler: StripeElementChangeHandler) => void;
  removeEventListener: (event: string, handler: StripeElementChangeHandler) => void;
  removeAllListeners: () => void;
}

export interface StripeElementMap {
  [key: string]: { complete: boolean, error?: string, element: PatchedStripeElement };
}

export interface BankAccountData {
  routing: string;
  account: string;
  holderName: string;
  holderType: 'company' | 'individual';
}

export interface CardElementTargets {
  cardNumber: HTMLElement;
  cardExpiry: HTMLElement;
  cardCvc: HTMLElement;
  postalCode: HTMLElement;
}

@Injectable()
export class PaymentService {

  private stripe: stripe.Stripe;
  private stripeTest: stripe.Stripe;

  constructor(
    environmentService: EnvironmentService,
  ) {
    const config = environmentService.getConfig();
    this.stripe = Stripe(config.payment.stripePublishable);
    this.stripeTest = Stripe(config.payment.stripePublishableTest);
  }

  createSecureInputElement(
    { cardNumber, cardExpiry, cardCvc, postalCode }: CardElementTargets,
    style: any = {},
    fake?: boolean,
  ): StripeElementMap {
    const stripe = fake ? this.stripeTest : this.stripe;
    const elements = stripe.elements();

    const cardNumberElement = elements.create('cardNumber', { style }) as PatchedStripeElement;
    const cardExpiryElement = elements.create('cardExpiry', { style }) as PatchedStripeElement;
    const cardCvcElement = elements.create('cardCvc', { style }) as PatchedStripeElement;
    const cardPostalElement = elements.create('postalCode', { style }) as PatchedStripeElement;

    cardNumberElement.mount(cardNumber);
    cardExpiryElement.mount(cardExpiry);
    cardCvcElement.mount(cardCvc);
    cardPostalElement.mount(postalCode);

    return {
      cardNumber: { complete: false, element: cardNumberElement },
      cardExpiry: { complete: false, element: cardExpiryElement },
      cardCvc: { complete: false, element: cardCvcElement },
      postalCode: { complete: false, element: cardPostalElement },
    };
  }

  tokenizeCardFromInputElement(element: PatchedStripeElement, fake?: boolean): Promise<stripe.TokenResponse> {
    const stripe = fake ? this.stripeTest : this.stripe;
    return stripe.createToken(element);
  }

  tokenizeBankAccount({ routing, account, holderName, holderType }: BankAccountData) {
    return this.stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: routing,
      account_number: account,
      account_holder_name: holderName,
      account_holder_type: holderType,
    }).then(this.handleStripeCreateTokenResponse);
  }

  tokenizePII(pii: string) {
    return this.stripe.createToken('pii', {
      personal_id_number: pii,
    }).then(this.handleStripeCreateTokenResponse);
  }

  private handleStripeCreateTokenResponse(data: any): string {
    if (!data) {
      return;
    }

    if (data.error) {
      throw new Error(data.error.message);
    }

    return data.token && data.token.id;
  }

}
