import { PipeTransform } from '@angular/core';
import { Big } from 'big.js';
var OUNCES_PER_GRAM = 28.3495;
var OUNCES_PER_LB = 0.0625;
var WeightToOuncesPipe = (function () {
    function WeightToOuncesPipe() {
    }
    WeightToOuncesPipe.prototype.transform = function (value, fromUnit) {
        switch (fromUnit) {
            case 'oz':
                return value;
            case 'g':
                return parseFloat(Big(value).div(OUNCES_PER_GRAM).toString());
            case 'lb':
                return parseFloat(Big(value).div(OUNCES_PER_LB).toString());
        }
        throw new Error("Unknown unit " + fromUnit);
    };
    return WeightToOuncesPipe;
}());
export { WeightToOuncesPipe };
