var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NativeEnvironmentService } from '../native-environment/native-environment.service';
import { DeviceRegistrationService } from 'src/app/shared/services/device-registration/device-registration.service';
import { NativeDisplayService } from '../native-display/native-display.service';
import { NativePushService } from '../native-push/native-push.service';
import { Insomnia } from '@ionic-native/insomnia';
var NativeInitializationService = (function () {
    function NativeInitializationService(nativeEnvironment, deviceRegistration, nativeDisplay, nativePush, insomnia) {
        this.nativeEnvironment = nativeEnvironment;
        this.deviceRegistration = deviceRegistration;
        this.nativeDisplay = nativeDisplay;
        this.nativePush = nativePush;
        this.insomnia = insomnia;
    }
    NativeInitializationService.prototype.init = function (_a) {
        var mode = _a.mode;
        return __awaiter(this, void 0, void 0, function () {
            var pushToken, deviceType, _b, uuid, platform, model, version, manufacturer;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.nativeEnvironment.ready()];
                    case 1:
                        _c.sent();
                        if (!this.nativeEnvironment.isNative()) {
                            return [2];
                        }
                        return [4, this.nativePush.init()];
                    case 2:
                        pushToken = _c.sent();
                        return [4, this.nativeEnvironment.getPlatform()];
                    case 3:
                        deviceType = _c.sent();
                        return [4, this.nativeEnvironment.getDeviceData()];
                    case 4:
                        _b = _c.sent(), uuid = _b.uuid, platform = _b.platform, model = _b.model, version = _b.version, manufacturer = _b.manufacturer;
                        this.deviceRegistration.registerDevice({ pushToken: pushToken, deviceType: deviceType, uuid: uuid, model: model, platform: platform, deviceOs: version, manufacturer: manufacturer });
                        if (!(mode === 1)) return [3, 8];
                        if (!(deviceType === 'android')) return [3, 6];
                        return [4, this.nativeDisplay.startFullscreen()];
                    case 5:
                        _c.sent();
                        _c.label = 6;
                    case 6: return [4, this.insomnia.keepAwake()];
                    case 7:
                        _c.sent();
                        return [3, 9];
                    case 8:
                        this.deviceRegistration.consumerDeviceLaunch();
                        _c.label = 9;
                    case 9: return [2];
                }
            });
        });
    };
    return NativeInitializationService;
}());
export { NativeInitializationService };
