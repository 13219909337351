import { dragula } from 'dragula-reborn';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
var DragulaService = (function () {
    function DragulaService() {
        this.dragula = dragula;
        this.drakes = {};
        this.drakeEventSubject = new Subject();
    }
    DragulaService.prototype.removeContainer = function (name, container) {
        var drake = this.getDrake(name);
        var containerIndex = drake.containers.indexOf(container);
        if (containerIndex > -1) {
            drake.containers.splice(containerIndex, 1);
            drake.containerIds.splice(containerIndex, 1);
        }
        if (drake.containers.length < 1) {
            drake.destroy();
            this.drakes[name] = undefined;
        }
    };
    DragulaService.prototype.addContainer = function (name, container, id, options) {
        var drake = this.getDrake(name, options);
        var containerId = id || drake.containers.length;
        drake.containers.push(container);
        drake.containerIds.push(containerId);
        return containerId;
    };
    DragulaService.prototype.updateContainerId = function (name, container, id) {
        var drake = this.getDrake(name);
        var containerIdx = drake.containers.indexOf(container);
        if (containerIdx !== -1) {
            drake.containerIds[containerIdx] = id;
        }
    };
    DragulaService.prototype.on = function (eventType, groupName, containerId) {
        return this.drakeEventSubject.asObservable().pipe(filter(function (event) {
            if (eventType !== event.type || groupName !== event.groupName) {
                return false;
            }
            var eventContainerId = event.type === 3 ? event.targetContainer : event.sourceContainer;
            return containerId !== undefined ? eventContainerId === containerId : true;
        }));
    };
    DragulaService.prototype.destroy = function () {
        var _this = this;
        Object.keys(this.drakes).forEach(function (name) { return _this.drakes[name].destroy(); });
        this.drakes = {};
    };
    DragulaService.prototype.getDrake = function (name, options) {
        if (options === void 0) { options = {}; }
        return this.drakes[name] || this.createDrake(name, options);
    };
    DragulaService.prototype.createDrake = function (name, options) {
        var drake = this.dragula([], options);
        drake.containerIds = [];
        this.drakes[name] = drake;
        this.addListeners(name, drake);
        return drake;
    };
    DragulaService.prototype.handleDrakeEvent = function (event) {
        var drake = this.drakes[event.drakeName];
        switch (event.type) {
            case 1:
                this.sourceDragIndex = this.domIndexOf(event.element, event.source);
                var sourceContainerIndex = drake.containers.indexOf(event.source);
                this.sourceContainerId = drake.containerIds[sourceContainerIndex];
                this.drakeEventSubject.next({
                    type: event.type,
                    groupName: event.drakeName,
                    sourceContainer: this.sourceContainerId,
                    sourceIndex: this.sourceDragIndex,
                    sourceEvent: event,
                });
                break;
            case 3:
                var dropIndex = this.domIndexOf(event.element, event.target);
                var targetContainerIndex = drake.containers.indexOf(event.target);
                var targetContainerId = drake.containerIds[targetContainerIndex];
                this.drakeEventSubject.next({
                    type: event.type,
                    groupName: event.drakeName,
                    sourceContainer: this.sourceContainerId,
                    targetContainer: targetContainerId,
                    sourceIndex: this.sourceDragIndex,
                    targetIndex: dropIndex,
                    sourceEvent: event,
                });
                if (this.sourceContainerId !== targetContainerId) {
                    this.handleDrakeEvent(Object.assign({}, event, { type: 0 }));
                }
                break;
            case 0:
            case 2:
                this.drakeEventSubject.next({
                    type: event.type,
                    groupName: event.drakeName,
                    sourceContainer: this.sourceContainerId,
                    sourceIndex: this.sourceDragIndex,
                    sourceEvent: event,
                });
                break;
        }
    };
    DragulaService.prototype.addListeners = function (drakeName, drake) {
        var _this = this;
        drake.on('remove', function (element, container, source) {
            return _this.handleDrakeEvent({ type: 0, drakeName: drakeName, element: element, container: container, source: source });
        });
        drake.on('drag', function (element, source) {
            return _this.handleDrakeEvent({ type: 1, drakeName: drakeName, element: element, source: source });
        });
        drake.on('cancel', function () {
            return _this.handleDrakeEvent({ type: 2, drakeName: drakeName });
        });
        drake.on('drop', function (element, target, source) {
            return _this.handleDrakeEvent({ type: 3, drakeName: drakeName, element: element, target: target, source: source });
        });
    };
    DragulaService.prototype.domIndexOf = function (child, parent) {
        return Array.prototype.indexOf.call(parent.children, child);
    };
    return DragulaService;
}());
export { DragulaService };
