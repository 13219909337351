import { MatDialogModule, MatButtonModule, MatCardModule, MatIconModule } from '@angular/material';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
];
var MerchantEditDialogsModule = (function () {
    function MerchantEditDialogsModule() {
    }
    return MerchantEditDialogsModule;
}());
export { MerchantEditDialogsModule };
