import { Injector, NgZone } from '@angular/core';
import { merge as _merge } from 'lodash';
import { ErrorDialogService } from '../../packages/ui-error-dialog';
import { ApplicationError, ErrorNames } from '../../errors/application-error';
import { EnvironmentService } from '../environment/environment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IdentityService } from '../identity/identity.service';
import { Router } from '@angular/router';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';
var GlobalErrorHandlerService = (function () {
    function GlobalErrorHandlerService(injector, ngZone, telemetryService) {
        this.injector = injector;
        this.ngZone = ngZone;
        this.telemetryService = telemetryService;
    }
    GlobalErrorHandlerService.prototype.handleError = function (error) {
        var errorDialogService = this.injector.get(ErrorDialogService);
        var environmentService = this.injector.get(EnvironmentService);
        var identityService = this.injector.get(IdentityService);
        var router = this.injector.get(Router);
        var isMerchantMode = router.url.startsWith('/m');
        if (error.rejection) {
            error = error.rejection;
        }
        var dialogData = {
            title: 'Unknown Error',
            message: error && error.message || 'An unknown error occurred',
            details: error && error.stack,
        };
        var showErrorDialog = true;
        var errorData = {};
        if (error instanceof ApplicationError) {
            errorData = error.getData();
            error = errorData.details;
            showErrorDialog = !errorData.suppressDialog;
        }
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case 401:
                    identityService.logout();
                    if (isMerchantMode) {
                        this.ngZone.run(function () { return router.navigate(['/login']); });
                    }
                    return;
                case 403:
                    errorData.name = ErrorNames.DATA_ACCESS;
                    errorData.message = 'You do not have access to this resource.';
                    errorData.retryAction = function () { return history.back(); };
                    break;
                default:
                    errorData.name = ErrorNames.DATA_ACCESS;
                    errorData.message = error.status + ' - ' + error.statusText;
                    errorData.details = {
                        message: error.message,
                        headers: error.headers.keys().map(function (k) { return error.headers.get(k); }),
                        error: error.error,
                        status: error.status,
                        url: error.url,
                    };
            }
        }
        this.telemetryService.logError('Global Error: ' + (errorData.message || error), true);
        if (isMerchantMode && showErrorDialog) {
            _merge(dialogData, {
                title: errorData.name,
                message: errorData.message,
                details: safeStringify(errorData.details, 2),
                retryButtonAction: errorData.retryAction,
            });
            this.ngZone.run(function () { return errorDialogService.show(dialogData); });
        }
        if (!environmentService.isProduction()) {
            throw error;
        }
        else {
            console.log('dialog error', error);
        }
    };
    return GlobalErrorHandlerService;
}());
export { GlobalErrorHandlerService };
function safeStringify(input, spaces) {
    var references = new Set();
    return JSON.stringify(input, function (key, value) {
        if (typeof value === 'object' && value !== null) {
            if (references.has(value)) {
                return;
            }
            references.add(value);
        }
        return value;
    }, spaces);
}
