import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { PairedDeviceDetails, PosDeviceService } from 'src/app/pos/services/pos-devices/pos-device.service';
import { AudioAlertService } from '../../services/audio-alert/audio-alert.service';
import { takeWhile } from 'rxjs/operators';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';

export type DeviceStatus = 'online' | 'error' | 'offline';

@Component({
  selector: 'pos-device-badge',
  templateUrl: './pos-device-badge.component.html',
  styleUrls: ['./pos-device-badge.component.scss'],
})
export class PosDeviceBadgeComponent implements OnInit, OnDestroy {

  @Input()
  pairedDevice: PairedDeviceDetails;

  @Input()
  showPosBadge: boolean;

  @Input()
  newOrders: number;

  @Input()
  alerting: boolean;

  @Input()
  set error(error: string) {
    this.errorMessage = error;
    this.updateStatus();
  }

  @Output()
  statusChange = new EventEmitter();

  status: DeviceStatus = 'online';
  deviceActive: boolean;
  deviceOpen: boolean;
  storeOpen: boolean;
  drawerOpen: boolean;

  kpiOrderCount: number;
  kpiSalesTotal: string;
  kipTipsTotal: string;
  storeId: string;
  errorMessage: string;

  private componentActive: boolean;

  currentTime: number;
  private timeInterval: NodeJS.Timer;

  constructor(
    private audioAlert: AudioAlertService,
    private posDeviceService: PosDeviceService,
  ) {
    this.updateCurrentTime();
  }

  public ngOnInit(): void {
    this.componentActive = true;
    this.audioAlert.initOrderAlert();
    this.audioAlert.initErrorAlert();
    this.watchDeviceStatus();

    this.posDeviceService.storeStats
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(storeStats => {
        this.kpiOrderCount = safeGet(storeStats, s => s.orderCount);
        this.kpiSalesTotal = safeGet(storeStats, s => s.totalSales);
        this.kipTipsTotal = safeGet(storeStats, s => s.totalTips);
      });

    this.timeInterval = setInterval(() => this.updateCurrentTime(), 1000);
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    clearInterval(this.timeInterval);
  }

  toggleStoreDrawer(): void {
    this.deviceOpen = false;
    if (this.storeOpen) {
      this.drawerOpen = false;
      setTimeout(() => this.resetDrawerState(), 500);
    } else {
      this.storeOpen = true;
      this.drawerOpen = true;
    }

  }

  toggleDeviceDrawer(): void {
    this.storeOpen = false;
    if (this.deviceOpen) {
      this.drawerOpen = false;
      setTimeout(() => this.resetDrawerState(), 500);
    } else {
      this.deviceOpen = true;
      this.drawerOpen = true;
    }
  }

  resetDrawerState(): void {
    if (!this.drawerOpen) {
      this.storeOpen = false;
      this.deviceOpen = false;
    }
  }

  async setOnline(online: boolean): Promise<void> {
    if (online) {
      const result = await this.posDeviceService.connect();
      this.deviceActive = result;
    } else {
      const result = await this.posDeviceService.disconnect();
      if (result) {
        this.deviceActive = !result;
      }
    }
    this.updateStatus();
  }

  private async watchDeviceStatus(): Promise<void> {
    await this.posDeviceService.isLoaded();

    this.posDeviceService.pairedDevice
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(this.updateActiveStoreProperties.bind(this));
  }

  private updateActiveStoreProperties(pairedDevice: PairedDeviceDetails): void {
    this.deviceActive = safeGet(pairedDevice, d => d.active);
    this.storeId = safeGet(pairedDevice, d => d.storeId);
    this.updateStatus();
  }

  private updateStatus(): void {
    if (this.errorMessage) {
      this.status = 'error';
      return;
    }
    this.status = this.deviceActive ? 'online' : 'offline';
  }

  private updateCurrentTime(): void {
    this.currentTime = Date.now();
  }

}
