import { UserConsumerLoginBottomsheetComponent } from './components/user-consumer-login-bottomsheet/user-consumer-login-bottomsheet.component';
import { MatButtonModule, MatIconModule, MatInputModule, MatProgressBarModule, MatSnackBarModule } from '@angular/material';
import { UserConsumerUserInfoBottomsheetComponent } from './components/user-consumer-user-info-bottomsheet/user-consumer-user-info-bottomsheet.component';
var entryComponents = [UserConsumerLoginBottomsheetComponent, UserConsumerUserInfoBottomsheetComponent];
export var MATERIAL_IMPORTS = [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSnackBarModule,
];
var UserConsumerLoginModule = (function () {
    function UserConsumerLoginModule() {
    }
    return UserConsumerLoginModule;
}());
export { UserConsumerLoginModule };
