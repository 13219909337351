import * as i0 from "@angular/core";
import * as i1 from "./home.component";
import * as i2 from "../../services/environment/environment.service";
import * as i3 from "../../../pos/services/pos-devices/pos-device.service";
import * as i4 from "@angular/router";
var styles_HomeComponent = [];
var RenderType_HomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_HomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i0.ɵdid(1, 114688, null, 0, i1.HomeComponent, [i2.EnvironmentService, i3.PosDeviceService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i0.ɵccf("page-home", i1.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
