import { OrderItemOptionChoice } from '../order-item-option-choice/order-item-option-choice.class';

export interface OrderItemOptionDescriptor {
  id: string;
  name: string;
  index: number;
  minChoices: number;
  maxChoices: number;
  choices: OrderItemOptionChoice[];
}

export class OrderItemOption {
  constructor(private readonly data: OrderItemOptionDescriptor) {
    this.resetChoices();
  }

  public availableChoices: Set<number>;
  public selectedChoices: Set<number>;

  get id(): string {
    return this.data.id;
  }

  get name(): string {
    return this.data.name;
  }

  get index(): number {
    return this.data.index;
  }

  get minChoices(): number {
    return this.data.minChoices;
  }

  get maxChoices(): number {
    return this.data.maxChoices;
  }

  get choices(): OrderItemOptionChoice[] {
    return this.data.choices;
  }

  get hiddenSelectedChoiceCount(): number {
    return this.getSelectedChoices().filter(choice => choice.hidden).length;
  }

  resetChoices(): void {
    const availableChoicesArr = this.choices.map((_, i) => i);
    this.availableChoices = new Set(availableChoicesArr);
    this.selectedChoices = new Set([]);
  }

  addChoice(choiceIndex: number): boolean {
    const validatedAddition =
      this.maxChoices === -1 || this.selectedChoices.size < this.maxChoices && !this.selectedChoices.has(choiceIndex);
    if (validatedAddition) {
      this.availableChoices.delete(choiceIndex);
      this.selectedChoices.add(choiceIndex);
      return true;
    } else {
      return false;
    }
  }

  removeChoice(choiceIndex: number): boolean {
    if (this.selectedChoices.has(choiceIndex)) {
      this.selectedChoices.delete(choiceIndex);
      this.availableChoices.add(choiceIndex);
      this.availableChoices = new Set(Array.from(this.availableChoices).sort((a, b) => {
        return a - b;
      }));
      return true;
    } else {
      return false;
    }
  }

  getAvailableChoices(): OrderItemOptionChoice[] {
    const availableChoicesArr = Array.from(this.availableChoices);
    return availableChoicesArr.map(choiceIndex => this.choices[choiceIndex]);
  }

  getSelectedChoices(): OrderItemOptionChoice[] {
    const selectedChoicesArr = Array.from(this.selectedChoices);
    return selectedChoicesArr.map(choiceIndex => this.choices[choiceIndex]);
  }
}
