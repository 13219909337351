import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class BottombarService {

  private templateSub: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();

  private templateObs: Observable<TemplateRef<any>> = this.templateSub.asObservable();

  constructor() { }

  setTemplate(template?: TemplateRef<any>): void {
    this.templateSub.next(template);
  }

  watchTemplate(): Observable<TemplateRef<any>> {
    return this.templateObs;
  }
}
