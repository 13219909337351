import { Injectable } from '@angular/core';
import { NativeEnvironmentService } from '../native-environment/native-environment.service';
import { DeviceRegistrationService } from 'src/app/shared/services/device-registration/device-registration.service';
import { NativeDisplayService } from '../native-display/native-display.service';
import { NativePushService } from '../native-push/native-push.service';
import { EnvironmentModes } from 'src/app/shared/services/environment/environment.enums';
import { Insomnia } from '@ionic-native/insomnia';

export interface AppInfo {
  mode: EnvironmentModes;
}

@Injectable()
export class NativeInitializationService {

  constructor(
    private nativeEnvironment: NativeEnvironmentService,
    private deviceRegistration: DeviceRegistrationService,
    private nativeDisplay: NativeDisplayService,
    private nativePush: NativePushService,
    private insomnia: Insomnia,
  ) { }

  async init({ mode }: AppInfo): Promise<void> {
    await this.nativeEnvironment.ready();

    if (!this.nativeEnvironment.isNative()) {
      return;
    }

    const pushToken = await this.nativePush.init();
    const deviceType = await this.nativeEnvironment.getPlatform();
    const { uuid, platform, model, version, manufacturer } = await this.nativeEnvironment.getDeviceData();

    // register device (cache push token and device info)
    // TODO: - Check that we have a push token on resume
    //       - Refactor native resume / pause from environment service)
    this.deviceRegistration.registerDevice({ pushToken, deviceType, uuid, model, platform, deviceOs: version, manufacturer });

    if (mode === EnvironmentModes.MERCHANT) {
      if (deviceType === 'android') {
        // merchant is an immersive / kiosk landscape pos application - hide back,home,etc
        await this.nativeDisplay.startFullscreen();
      }
      await this.insomnia.keepAwake();
    } else {
      // register consumer push token with joe service
      this.deviceRegistration.consumerDeviceLaunch();
    }
  }
}
