import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

// in milliseconds obvi
export const REWARD_PROGRESS_ANIMATION_LENGTH = 1000;

@Component({
  selector: 'sidebar-reward-progress',
  templateUrl: './sidebar-reward-progress.component.html',
  styleUrls: ['./sidebar-reward-progress.component.scss'],
})
export class SidebarRewardProgressComponent implements OnInit, OnChanges {

  @Input()
  rewardProgress: number;

  @Input()
  imgUrl: string;

  loading: boolean;
  rewardProgressValue: number;
  rewardAnimationValue = 0;

  animationId: any;
  time = {
    start: performance.now(),
    total: REWARD_PROGRESS_ANIMATION_LENGTH,
    elapsed: 0,
  };

  constructor() { }

  public ngOnInit(): void {
    this.loading = true;
    this.rewardAnimationValue = 0;
    if ((this.imgUrl || this.imgUrl === null) && this.rewardProgress !== undefined) {
      this.rewardProgressValue = Math.floor(this.rewardProgress / 10);
      this.loading = false;
      this.time.start = performance.now();
      requestAnimationFrame((progress: number) => {
        this.animateRewardProgress(progress);
      });

    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    this.rewardAnimationValue = 0;
    if (changes.imgUrl && !this.imgUrl) {
      this.imgUrl = changes.imgUrl.currentValue;
    }

    if ((this.imgUrl || this.imgUrl === null) && this.rewardProgress !== undefined && changes.rewardProgress) {
      this.rewardProgress = changes.rewardProgress.currentValue;
      this.rewardProgressValue = Math.floor(this.rewardProgress / 10);
      this.loading = false;
      this.time.start = performance.now();
      requestAnimationFrame((progress: number) => {
        this.animateRewardProgress(progress);
      });
    }
  }

  public animateRewardProgress = (now: number) => {
    this.time.elapsed = now - this.time.start;

    const currentProgress = this.time.elapsed / this.time.total;
    const possiblePosition = currentProgress * this.rewardProgressValue;
    const position = possiblePosition > this.rewardProgressValue ? this.rewardProgressValue : possiblePosition;

    if (this.rewardAnimationValue < this.rewardProgressValue) {
      this.rewardAnimationValue = position;
    }

    if (currentProgress < 1) {
      requestAnimationFrame((progress: number) => {
        this.animateRewardProgress(progress);
      });
    }
  }
}
