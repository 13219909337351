import * as i0 from "./ui-pos-chrome.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../pos-device-badge/pos-device-badge.component.ngfactory";
import * as i3 from "../pos-device-badge/pos-device-badge.component";
import * as i4 from "../../services/audio-alert/audio-alert.service";
import * as i5 from "../../../../services/pos-devices/pos-device.service";
import * as i6 from "@angular/common";
import * as i7 from "./ui-pos-chrome.component";
var styles_UiPosChromeComponent = [i0.styles];
var RenderType_UiPosChromeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UiPosChromeComponent, data: {} });
export { RenderType_UiPosChromeComponent as RenderType_UiPosChromeComponent };
function View_UiPosChromeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pos-device-badge", [], null, null, null, i2.View_PosDeviceBadgeComponent_0, i2.RenderType_PosDeviceBadgeComponent)), i1.ɵdid(1, 245760, null, 0, i3.PosDeviceBadgeComponent, [i4.AudioAlertService, i5.PosDeviceService], { pairedDevice: [0, "pairedDevice"], showPosBadge: [1, "showPosBadge"], newOrders: [2, "newOrders"], alerting: [3, "alerting"], error: [4, "error"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pairedDevice; var currVal_1 = true; var currVal_2 = _co.newOrders; var currVal_3 = _co.alerting; var currVal_4 = _co.errorMessage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_UiPosChromeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-alert-timer"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayTimeUntilAlarm; _ck(_v, 1, 0, currVal_0); }); }
export function View_UiPosChromeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UiPosChromeComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UiPosChromeComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.pairedDevice == null) ? null : _co.pairedDevice.storeId); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.displayTimeUntilAlarm; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UiPosChromeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-pos-chrome", [], null, null, null, View_UiPosChromeComponent_0, RenderType_UiPosChromeComponent)), i1.ɵdid(1, 245760, null, 0, i7.UiPosChromeComponent, [i5.PosDeviceService, i4.AudioAlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UiPosChromeComponentNgFactory = i1.ɵccf("ui-pos-chrome", i7.UiPosChromeComponent, View_UiPosChromeComponent_Host_0, {}, {}, []);
export { UiPosChromeComponentNgFactory as UiPosChromeComponentNgFactory };
