import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device';
import { AppVersion } from '@ionic-native/app-version';

// declare const cordova: Cordova;
export type NativePlatforms = 'android' | 'ios';

/**
 * Allows getting and setting information about the apps environment on a native device
 * This includes device hardware and software info / settings
 */
@Injectable()
export class NativeEnvironmentService {

  private readyPromise: Promise<void>;

  constructor(
    private device: Device,
    private appVersion: AppVersion,
  ) {
    this.initReadyPromise();
  }

  ready(): Promise<void> {
    return this.readyPromise;
  }

  isNative(): boolean {
    return !!(<any>window)['cordova'];
  }

  async getPackageName(): Promise<string> {
    await this.ready();
    return this.isNative() ? this.appVersion.getPackageName() : Promise.resolve(undefined);
  }

  async getAppName(): Promise<string> {
    await this.ready();
    return this.isNative() ? this.appVersion.getAppName() : Promise.resolve(undefined);
  }

  async getVersionNumber(): Promise<string> {
    await this.ready();
    return this.isNative() ? this.appVersion.getVersionNumber() : Promise.resolve(undefined);
  }

  async getVersionCode(): Promise<string> {
    await this.ready();
    return this.isNative() ? this.appVersion.getVersionCode().then(v => v + '') : Promise.resolve(undefined);
  }

  async getPlatform(): Promise<NativePlatforms> {
    await this.ready();
    // any because string cannot be assigned to android | ios
    return this.isNative() ? this.device.platform.toLowerCase() as any : Promise.resolve(undefined);
  }

  async getDeviceData(): Promise<Device> {
    await this.ready();
    // any because string cannot be assigned to android | ios
    return this.device;
  }

  private initReadyPromise(): void {
    let readyResolve: () => void;
    this.readyPromise = new Promise(resolve => { readyResolve = resolve; });
    document.addEventListener(this.isNative() ? 'deviceready' : 'DOMContentLoaded', () => {
      readyResolve();
    }, { once: true });
  }

}
