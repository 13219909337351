import { Component, ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { IdentityService, User } from 'src/app/shared/services/identity/identity.service';
import { MatSnackBar, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { CloudinaryUploadService } from 'src/app/shared/services/cloudinary-upload/cloudinary-upload.service';
import { UserService } from 'src/app/shared/services/user/user.service';

interface LoginInfo {
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface UserInfoBottomsheetData {
  mode: UserInfoStep;
  message?: string;
}

export type UserInfoStep = 'photo' | 'email';

@Component({
  selector: 'user-consumer-user-info-bottomsheet',
  templateUrl: './user-consumer-user-info-bottomsheet.component.html',
  styleUrls: ['./user-consumer-user-info-bottomsheet.component.scss'],
})
export class UserConsumerUserInfoBottomsheetComponent implements OnInit {

  userInfoMode: UserInfoStep;
  loading: boolean;
  infoMessage: string;

  private currentUser: User;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) readonly data: UserInfoBottomsheetData,
    private readonly bottomSheetRef: MatBottomSheetRef<any, void>,
    private readonly identityService: IdentityService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly cloudinaryUploadService: CloudinaryUploadService,
    private readonly userService: UserService,
    private readonly snackbar: MatSnackBar,
  ) {
    this.userInfoMode = data.mode;
    this.infoMessage = data.message;
    this.currentUser = identityService.getActiveUser();
  }

  ngOnInit(): void {
    this.dismissIfInfoNotNeeded();
    this.cloudinaryUploadService.initCloudinaryWidget('USER_PROFILE');
  }

  async onEmailSubmit({ email, password, passwordConfirm }: LoginInfo): Promise<void> {
    const unknownErrorMessage = 'Unable to add an e-mail / password at this time. Please try again later';
    this.setErrorMessage();

    if (password !== passwordConfirm) {
      this.setErrorMessage('Password confirmation does not match.');
      // bottom sheet requires manual change detection
      this.changeDetectorRef.detectChanges();
      return;
    }

    if (!password || password.length < 8) {
      this.setErrorMessage('Password must be at least 8 characters.');
      // bottom sheet requires manual change detection
      this.changeDetectorRef.detectChanges();
      return;
    }

    let updateResult: boolean;

    this.loading = true;
    try {
      updateResult = await this.identityService.addEmailPassword(email, password).toPromise();

      if (!updateResult) {
        this.setErrorMessage(unknownErrorMessage);
      }

    } catch (e) {
      const errorMessage = e && e.status === 400 ? 'Invalid e-mail address or password.' : e && e.error && e.error.message;
      this.setErrorMessage(errorMessage || unknownErrorMessage);
    }
    this.loading = false;

    if (updateResult) {
      this.dismiss();
    }

    // bottom sheet requires manual change detection
    this.changeDetectorRef.detectChanges();
  }

  async updatePhoto(): Promise<void> {
    this.setErrorMessage();
    const result = await this.cloudinaryUploadService.open('USER_PROFILE');
    if (result) {
      this.loading = true;
      const photoUrl = await this.userService.addImage(result.id).toPromise();
      this.identityService.setActiveUserPhoto(photoUrl);
      this.loading = false;
    }
    this.skipForNow();
  }

  /**
   * If photo is skipped try to get the e-mail
   * If email is skipped we're done
   */
  skipForNow(): void {
    if (this.userInfoMode === 'photo' && !this.currentUser.hasEmail) {
      this.userInfoMode = 'email';
    } else {
      this.dismiss();
    }

    // bottom sheet requires manual change detection
    this.changeDetectorRef.detectChanges();
  }

  private dismissIfInfoNotNeeded(): void {
    switch (this.userInfoMode) {
      case 'email':
        if (this.currentUser.hasEmail) {
          this.dismiss();
        }
        return;
      case 'photo':
        if (this.currentUser.photo) {
          this.dismiss();
        }
        return;
    }
  }

  private setErrorMessage(message?: string): void {
    if (message) {
      this.snackbar.open(`Error: ${message}`, '', { verticalPosition: 'top', duration: 10000, panelClass: 'error-toast' });
    } else {
      this.snackbar.dismiss();
    }
  }

  private dismiss() {
    this.bottomSheetRef.dismiss();
  }

}
