import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { MenuItemSizeModel, MenuItemOption } from 'src/app/merchant/services/menu/menu.service';
import { keyBy as _keyBy } from 'lodash';
import { WeightToOuncesPipe } from 'src/app/shared/packages/data-transform-pipes/weight/weight-to-ounces/weight-to-ounces.pipe';
import { Subscription } from 'rxjs';

export interface EditMenuItemOptionData {
  option: FormGroup;
  itemName: string;
  itemSizes: MenuItemSizeModel[];
}

@Component({
  selector: 'merchant-menu-item-option-dialog',
  templateUrl: './merchant-menu-item-option-dialog.component.html',
  styleUrls: ['./merchant-menu-item-option-dialog.component.scss'],
})
export class MerchantMenuItemOptionDialogComponent implements OnInit, OnDestroy {

  option: FormGroup;
  itemName: string;
  choiceSizeForms: FormArray[];
  itemSizes: MenuItemSizeModel[];
  errorMessage: string;
  allSizesControls: FormGroup[];
  allSizesSubscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<EditMenuItemOptionData>,
    @Inject(MAT_DIALOG_DATA) { option, itemName, itemSizes }: EditMenuItemOptionData,
    private weightToOunces: WeightToOuncesPipe,
  ) {
    this.option = option;
    this.itemName = itemName;
    this.itemSizes = itemSizes;
  }

  public ngOnInit(): void {
    this.syncItemSizePricesToSizes();
  }

  public ngOnDestroy(): void {
    this.clearSizeSubscriptions();
  }

  save() {
    this.errorMessage = '';
    const { minChoices, maxChoices, choices } = this.option.getRawValue() as MenuItemOption;
    const defaultCount = choices.reduce((acc, choice) => choice.default ? acc + 1 : acc, 0);

    if (minChoices > maxChoices) {
      this.errorMessage = 'Min choices cannot be greater than max choices.';
      return;
    }

    if (defaultCount < minChoices) {
      this.errorMessage = 'The number of defaults must be at least the number of min choices.';
      return;
    }

    if (maxChoices < defaultCount) {
      this.errorMessage = 'The number of defaults cannot exceed the max choices';
      return;
    }

    this.dialogRef.close();
  }

  getOptionChoices(): FormArray {
    return this.option.get('choices') as FormArray;
  }

  setAllChoicesValue(value: boolean): void {
    const choices = this.getOptionChoices();
    choices.controls.forEach((_, i) => this.choiceSizeCustomChange(choices.at(i) as FormGroup, value));

    this.clearSizeSubscriptions();

    if (value) {
      this.allSizesControls = this.itemSizes.map((size, i) => {
        const sizeGroup = this.formBuilder.group({
          menuSizeId: '',
          menuChoiceId: '',
          price: '0',
          name: size.name,
          quantity: size.quantity,
          quantityUnit: size.quantityUnit,
        });

        const subscriber = sizeGroup.get('price').valueChanges.subscribe(newValue => {
          this.getOptionChoices().controls.forEach(control => {
            const targetSizePrices = control.get('sizePrices') as FormArray;
            if (!targetSizePrices || targetSizePrices.length < i) {
              return;
            }
            const targetControl = targetSizePrices.at(i);
            targetControl.get('price').setValue(newValue);
          });
        });
        this.allSizesSubscriptions.push(subscriber);
        return sizeGroup;
      });
    } else {
      this.allSizesControls = undefined;
    }
  }

  private clearSizeSubscriptions(): void {
    let subscription = this.allSizesSubscriptions.pop();
    while (subscription) {
      subscription.unsubscribe();
      subscription = this.allSizesSubscriptions.pop();
    }
  }

  /**
   * Updates item option sizes to match item sizes in case any sizes were added/removed/edited
   */
  private syncItemSizePricesToSizes(): void {
    const choices = this.option.get('choices') as FormArray;

    if (!choices || !choices.controls) {
      return;
    }

    choices.controls.forEach((choice: FormGroup) => {
      const sizePrices = choice.get('sizePrices') as FormArray;

      if (sizePrices && sizePrices.controls) {
        const sizePriceIds = _keyBy(sizePrices.getRawValue(), 'menuSizeId');
        const sizeIds = _keyBy(this.itemSizes, 'id');

        for (let i = sizePrices.controls.length - 1; i >= 0; i--) {
          const sizePrice = sizePrices.at(i);
          const sizePriceId = sizePrice.get('menuSizeId').value;

          if (sizeIds.hasOwnProperty(sizePriceId)) {
            // update value if size exists in item
            const size = sizeIds[sizePriceId];
            sizePrice.patchValue({ name: size.name, quantity: size.quantity, quantityUnit: size.quantityUnit });
          } else {
            // update remove pricing if size no longer exists
            sizePrices.removeAt(i);
          }
        }

        // add any new sizes to the list
        this.itemSizes.forEach(size => {
          if (!sizePriceIds.hasOwnProperty(size.id)) {
            sizePrices.push(this.createItemSizePrice(choice, size));
          }
        });

        // sort sizes
        sizePrices.controls.sort((a, b) =>
          this.weightToOunces.transform(a.get('quantity').value, a.get('quantityUnit').value) -
          this.weightToOunces.transform(b.get('quantity').value, b.get('quantityUnit').value));
      }
    });
  }

  private createItemSizePrice(choice: FormGroup, size: MenuItemSizeModel): FormGroup {
    return this.formBuilder.group({
      menuSizeId: size.id,
      menuChoiceId: choice.get('id').value,
      price: choice.get('price').value,
      name: size.name,
      quantity: size.quantity,
      quantityUnit: size.quantityUnit,
    });
  }

  choiceSizeCustomChange(choice: FormGroup, value: boolean): void {

    if (value) {
      choice.addControl('sizePrices', new FormArray(this.itemSizes.map(size => this.createItemSizePrice(choice, size))));
    } else {
      choice.removeControl('sizePrices');
    }
  }

  freeCheckChange(choice: FormGroup): void {
    const freeCheck = choice.get('free') as FormControl;
    if (freeCheck.value) {
      this.choiceSizeCustomChange(choice, false);
    }
  }

  requiredCheckChange(choice: FormGroup): void {
    const requiredCheck = choice.get('required') as FormControl;
    const defaultCheck = choice.get('default') as FormControl;
    const freeCheck = choice.get('free') as FormControl;

    if (requiredCheck.value) {
      defaultCheck.setValue(true);
      defaultCheck.disable();
      freeCheck.setValue(true);
      freeCheck.disable();
    } else {
      defaultCheck.enable();
      defaultCheck.setValue(false);
      freeCheck.enable();
      freeCheck.setValue(false);
    }

    this.freeCheckChange(choice);
  }

}
