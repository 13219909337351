import { safeGet } from '../../helpers/object/safe-get';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';
var WIDGET_TYPE_OPTIONS = {
    USER_PROFILE: {
        cloudName: 'joe-coffee',
        uploadPreset: 'jjaewamq_joe_profile_upload',
        sources: ['local'],
        multiple: false,
        maxFiles: 1,
        cropping: false,
        croppingAspectRatio: 1,
        croppingDefaultSelectionRatio: 0.8,
        resourceType: 'image',
        minImageWidth: 400,
        minImageHeight: 400,
    },
    MENU_ITEM: {
        cloudName: 'joe-coffee',
        uploadPreset: 'e2g59vxa_joe_menu_item',
        sources: ['local'],
        multiple: false,
        maxFiles: 1,
        cropping: true,
        croppingAspectRatio: 1,
        croppingDefaultSelectionRatio: 1,
        resourceType: 'image',
        minImageWidth: 1000,
        minImageHeight: 1000,
        showSkipCropButton: false,
        croppingValidateDimensions: true,
        showPoweredBy: false,
    },
    STORE_BANNER: {
        cloudName: 'joe-coffee',
        uploadPreset: 'twcawn1m_joe_store_banner',
        sources: ['local'],
        multiple: false,
        maxFiles: 1,
        cropping: true,
        croppingAspectRatio: 2.5,
        croppingCoordinatesMode: 'custom',
        croppingDefaultSelectionRatio: 2.5,
        resourceType: 'image',
        minImageWidth: 1536,
        minImageHeight: 615,
        showSkipCropButton: false,
        croppingValidateDimensions: true,
        showPoweredBy: false,
    },
    PICKUP_LOCATION: {
        cloudName: 'joe-coffee',
        uploadPreset: 'yafwrdzk_store_pickup_location',
        sources: ['local'],
        multiple: false,
        maxFiles: 1,
        cropping: true,
        croppingAspectRatio: 1,
        croppingDefaultSelectionRatio: 0.8,
        croppingCoordinatesMode: 'custom',
        resourceType: 'image',
        minImageWidth: 400,
        minImageHeight: 400,
        showSkipCropButton: true,
        croppingValidateDimensions: true,
        showPoweredBy: false,
        debug: true,
    },
};
var CloudinaryUploadService = (function () {
    function CloudinaryUploadService(telemetryService) {
        this.telemetryService = telemetryService;
        this.widgets = {};
        this.widgetCallbacks = {};
    }
    CloudinaryUploadService.prototype.initCloudinaryWidget = function (widgetTypeId) {
        var _this = this;
        if (!WIDGET_TYPE_OPTIONS[widgetTypeId]) {
            throw new Error('invalid cloudinary widget type');
        }
        if (this.widgets.hasOwnProperty(widgetTypeId)) {
            return;
        }
        this.widgets[widgetTypeId] = cloudinary.createUploadWidget(WIDGET_TYPE_OPTIONS[widgetTypeId], function (error, result) { return _this.handleCloudinaryCallback(widgetTypeId, error, result); });
    };
    CloudinaryUploadService.prototype.open = function (widgetTypeId) {
        if (!this.widgets.hasOwnProperty(widgetTypeId)) {
            throw new Error('cloudinary widget not initialized');
        }
        try {
            this.widgets[widgetTypeId].open();
            return this.registerWidgetCallback(widgetTypeId);
        }
        catch (error) {
            var errorMessage = safeGet(error, function (e) { return e.message || e.toString(); }) || 'Unknown error';
            this.telemetryService.logError(errorMessage);
        }
    };
    CloudinaryUploadService.prototype.handleCloudinaryCallback = function (widgetId, error, result) {
        if (!this.widgetCallbacks[widgetId]) {
            return;
        }
        var _a = this.widgetCallbacks[widgetId], success = _a.success, failure = _a.failure;
        if (error) {
            failure(error);
            this.clearWidgetCallback(widgetId);
            return;
        }
        if (result) {
            if (result.event === 'close') {
                success();
                this.clearWidgetCallback(widgetId);
            }
            else if (result.event === 'success' && result.info) {
                var info = result.info;
                success({
                    id: info.public_id,
                    url: info.secure_url,
                    width: info.width,
                    height: info.height,
                    format: info.format,
                });
                this.clearWidgetCallback(widgetId);
            }
        }
    };
    CloudinaryUploadService.prototype.clearWidgetCallback = function (widgetId) {
        this.widgetCallbacks[widgetId] = undefined;
    };
    CloudinaryUploadService.prototype.registerWidgetCallback = function (widgetId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.widgetCallbacks[widgetId] = { success: resolve, failure: reject };
        });
    };
    return CloudinaryUploadService;
}());
export { CloudinaryUploadService };
