export function downloadStringToFile(filename: string, data: string) {
  let blob = new Blob([data], { type: 'text/csv' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);

    setTimeout(() => {
      // encourage some GC
      window.URL.revokeObjectURL(elem.href);
      elem = undefined;
      blob = undefined;
    }, 3000);
  }
}
