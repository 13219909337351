import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerchantMenuOptionEditComponent } from './components/merchant-menu-option-edit/merchant-menu-option-edit.component';
import {
  MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatButtonToggleModule,
  MatCheckboxModule, MatRadioModule, MatMenuModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuOptionService } from '../../services/menu-option/menu-option.service';
import { MatCurrencyInputModule } from '../../../shared/packages/mat-currency-input';
import { Ng2DragulaRebornModule } from '../../../shared/packages/ng2-dragula-reborn';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatMenuModule,

  // custom
  MatCurrencyInputModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    Ng2DragulaRebornModule,
    ...MATERIAL_IMPORTS,
  ],
  providers: [
    MenuOptionService,
  ],
  declarations: [
    MerchantMenuOptionEditComponent,
  ],
  entryComponents: [
    MerchantMenuOptionEditComponent,
  ],
  exports: [
    MerchantMenuOptionEditComponent,
  ],
})
export class MerchantMenuOptionEditModule { }
