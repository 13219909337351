import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerchantSidebarComponent } from './components/merchant-sidebar/merchant-sidebar.component';
import { MerchantTopbarComponent } from './components/merchant-topbar/merchant-topbar.component';
import { UiMerchantChromeComponent } from './components/ui-merchant-chrome/ui-merchant-chrome.component';
import { MatSidenavModule, MatListModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatMenuModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EnvironmentService } from '../../../shared/services/environment/environment.service';
import { MerchantTopbarOptionsComponent } from './components/merchant-topbar-options/merchant-topbar-options.component';
import { MerchantTopbarService } from './services/merchant-topbar/merchant-topbar.service';
import {
  MerchantBottombarTemplateComponent,
} from './components/merchant-bottombar/merchant-bottombar-template/merchant-bottombar-template.component';
import { MerchantBottombarComponent } from './components/merchant-bottombar/merchant-bottombar.component';
import { MerchantBottombarService } from './services/merchant-bottombar/merchant-bottombar.service';
import { CompanyService } from '../../services/company/company.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActiveCompanyService } from './services/active-company/active-company.service';

const EXPORTED_COMPONENTS = [
  MerchantSidebarComponent,
  MerchantBottombarTemplateComponent,
  MerchantTopbarOptionsComponent,
  UiMerchantChromeComponent,
];

export const MATERIAL_IMPORTS = [
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonModule,
  MatMenuModule,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    MerchantTopbarComponent,
    MerchantBottombarComponent,
    ...EXPORTED_COMPONENTS,
  ],
  providers: [
    EnvironmentService,
    CompanyService,
  ],
  exports: [
    ...EXPORTED_COMPONENTS,
    ...MATERIAL_IMPORTS,
  ],
  entryComponents: [],

})
export class UiMerchantChromeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UiMerchantChromeModule,
      providers: [MerchantTopbarService, MerchantBottombarService, ActiveCompanyService],
    };
  }
}
