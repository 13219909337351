export class Timer {
  private timerObj: number;
  private interval: number;
  private fn: Function;
  private runOnStart: boolean;

  constructor(fn?: Function, interval?: number, runOnStart: boolean = true) {
    this.interval = interval;
    this.fn = fn;
    this.runOnStart = runOnStart;
  }

  start(): Timer {
    if (!this.fn || !this.interval) {
      throw new Error('Timer needs a function and an interval in order to start');
    }

    if (!this.timerObj) {
      this.stop();

      if (this.runOnStart) {
        this.fn();
      }
      this.timerObj = setInterval(this.fn, this.interval);
    }
    return this;
  }

  stop(): Timer {
    if (this.timerObj) {
      clearInterval(this.timerObj);
      this.timerObj = undefined;
    }
    return this;
  }

  reset(newInterval: number = this.interval): Timer {
    this.interval = newInterval;
    return this.stop().start();
  }

  isRunning(): boolean {
    return Boolean(this.timerObj);
  }
}
