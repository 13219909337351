import { Injectable } from '@angular/core';
import { ApiService, UserMerchantInviteResponseDto, UserProfileDto, JoebucksBalanceResponseDto, UserFavoriteStoreDto, OrderPricingDiscountDto } from 'src/gen/joeServerCore';
import { Observable } from 'rxjs';
import { UserGetPaymentCardsResponseDto } from 'src/gen/joeServerCore/lib/models/user-get-payment-cards-response-dto';
import { map } from 'rxjs/operators';
import { StorageService } from '../../packages/storage/storage/storage.service';
import { StorageKeys } from '../../enums/storage-keys';

interface UserPaymentMethod {
  id: string;
}

@Injectable()
export class UserService {

  constructor(
    private apiService: ApiService,
    private storage: StorageService,
  ) { }

  getProfile(): Observable<UserProfileDto> {
    return this.apiService.userGetProfile();
  }

  addImage(imageId: string): Observable<string> {
    return this.apiService.userSetPhoto({ imageId }).pipe(map(({ photo }) => photo));
  }

  getPaymentCards(): Observable<UserGetPaymentCardsResponseDto> {
    return this.apiService.userGetCards();
  }

  getInvitations(): Observable<UserMerchantInviteResponseDto[]> {
    return this.apiService.userGetInvites();
  }

  getJoeBucksBalance(): Observable<string> {
    return this.getJoeBucksBalanceStatus().pipe(map(({ balance }) => balance || '0'));
  }

  getJoeBucksBalanceStatus(): Observable<JoebucksBalanceResponseDto> {
    return this.apiService.joebucksCheckBalance();
  }

  acceptInvitation(inviteId: string): Observable<boolean> {
    const status = 'accepted';
    return this.apiService.userAcknowledgeMerchantInvite({ inviteId, UserAcknowledgeMerchantInviteRequestDto: { status } })
      .pipe(map(result => result && !!result.inviteId));
  }

  rejectInvitation(inviteId: string): Observable<boolean> {
    const status = 'rejected';
    return this.apiService.userAcknowledgeMerchantInvite({ inviteId, UserAcknowledgeMerchantInviteRequestDto: { status } })
      .pipe(map(result => result && !!result.inviteId));
  }

  async getDefaultPaymentMethod(): Promise<string> {
    const { id } = await this.storage.get<UserPaymentMethod>(StorageKeys.USER_DEFAULT_PAYMENT_METHOD);
    return id;
  }

  setDefaultPaymentMethod(id: string): Promise<void> {
    return this.storage.set(StorageKeys.USER_DEFAULT_PAYMENT_METHOD, { id });
  }

  getFavoriteStores(): Observable<UserFavoriteStoreDto[]> {
    return this.apiService.userGetFavoriteStores();
  }

  getReactivationPromoStatus(): Observable<OrderPricingDiscountDto> {
    return this.apiService.userGetReactivationPromoStatus();
  }

}
