import { MatIconModule, MatButtonModule, MatDialogModule, MatInputModule, MatCheckboxModule, MatTableModule, MatButtonToggleModule } from '@angular/material';
import { MatCurrencyInputModule } from 'src/app/shared/packages/mat-currency-input';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatButtonToggleModule,
    MatCurrencyInputModule,
];
var MerchantMenuItemOptionDialogModule = (function () {
    function MerchantMenuItemOptionDialogModule() {
    }
    return MerchantMenuItemOptionDialogModule;
}());
export { MerchantMenuItemOptionDialogModule };
