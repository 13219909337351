import { Injectable } from '@angular/core';
import { ApiService, DeviceInfoRequestDto, DeviceMetaDataDto } from 'src/gen/joeServerCore';
import { StorageService } from '../../packages/storage/storage/storage.service';
import { EnvironmentService } from '../environment/environment.service';
import { getLocalTimezone } from '../../helpers/date/get-local-timezone';
import { getTimezoneOffset } from '../../helpers/date/get-timezone-offset';
import { TelemetryService } from '../../packages/telemetry/services/telemetry/telemetry.service';
import { safeGet } from '../../helpers/object/safe-get';

const DEVICE_DATA_STORAGE_KEY = 'DEVICE_DATA_STORAGE_KEY';

const CONSUMER_SESSION_DEVICE_STORAGE_KEY = 'CONSUMER_DEVICE_ID_STORAGE_KEY';
// const MERCHANT_PAIRED_DEVICE_STORAGE_KEY = 'MERCHANT_DEVICE_ID_STORAGE_KEY';

export interface DeviceRegistrationStorage {
  id: string;
  pushToken: string;
}

// Types are currently identical. Define RegisterDeviceInfo interface if the diverge
export type RegisterDeviceInfo = DeviceInfoRequestDto;

@Injectable()
export class DeviceRegistrationService {

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private environmentService: EnvironmentService,
    private telemetryService: TelemetryService,
  ) { }

  ///////////////// GENERAL //////////////////////////////////

  async registerDevice(deviceInfo: RegisterDeviceInfo): Promise<void> {
    await this.storageService.set<RegisterDeviceInfo>(DEVICE_DATA_STORAGE_KEY, deviceInfo);
  }

  getDeviceInfo(): Promise<RegisterDeviceInfo> {
    return this.storageService.get<RegisterDeviceInfo>(DEVICE_DATA_STORAGE_KEY);
  }

  ///////////////// CONSUMER //////////////////////////////////

  async consumerDeviceLaunch(): Promise<string> {
    try {
      const deviceInfo = await this.getDeviceInfo();
      const sessionDeviceData = await this.storageService.get<DeviceRegistrationStorage>(CONSUMER_SESSION_DEVICE_STORAGE_KEY);
      const versionInfo = await this.environmentService.getVersions();
      const timezone = getLocalTimezone();
      const metaData: DeviceMetaDataDto = {
        clientTimezone: timezone,
        clientTimestamp: Date.now().toString(),
        clientTimestampOffset: parseInt(getTimezoneOffset(), 10),
        buildNumber: versionInfo && versionInfo.build && versionInfo.build.number,
        nativeVersionCode: versionInfo && versionInfo.deviceCode,
        nativeVersion: versionInfo && versionInfo.deviceNumber,
      };

      const pushToken = deviceInfo.pushToken || sessionDeviceData.pushToken;

      if (pushToken) {
        const { id } = await this.apiService.consumerLaunch({ id: sessionDeviceData.id, deviceInfo, metaData }).toPromise();

        await this.storageService.set<DeviceRegistrationStorage>(CONSUMER_SESSION_DEVICE_STORAGE_KEY, { id, pushToken });
        return id;
      }

      return;
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
      return Promise.resolve(undefined);
    }
  }

  getConsumerDevice(): Promise<DeviceRegistrationStorage> {
    return this.storageService.get<DeviceRegistrationStorage>(CONSUMER_SESSION_DEVICE_STORAGE_KEY);
  }

}
