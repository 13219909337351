var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import { PaymentService } from 'src/app/shared/services/payment/payment.service';
var CardEntryStripeElementComponent = (function () {
    function CardEntryStripeElementComponent(paymentService) {
        this.paymentService = paymentService;
        this.tokenUpdate = new EventEmitter();
    }
    CardEntryStripeElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        var style = {
            base: {
                fontSize: '18px',
            },
        };
        this.cardElements = this.paymentService.createSecureInputElement({
            cardNumber: this.paymentCardInputTargetRef.nativeElement,
            cardExpiry: this.paymentExpInputTargetRef.nativeElement,
            cardCvc: this.paymentCvcInputTargetRef.nativeElement,
            postalCode: this.paymentPostalInputTargetRef.nativeElement,
        }, style, this.fake);
        Object.keys(this.cardElements).forEach(function (key) {
            _this.cardElements[key].element.addEventListener('change', _this.cardElementChangeListener.bind(_this));
        });
    };
    CardEntryStripeElementComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (!this.cardElements) {
            return;
        }
        Object.keys(this.cardElements).forEach(function (key) {
            var element = _this.cardElements[key].element;
            element.removeAllListeners();
            element.unmount();
        });
        this.cardElements = undefined;
    };
    CardEntryStripeElementComponent.prototype.cardElementChangeListener = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var element;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        element = this.cardElements[event.elementType];
                        element.error = event.error ? event.error.message : undefined;
                        element.complete = event.complete;
                        if (!this.allElementsComplete()) return [3, 2];
                        return [4, this.tokenizeCard()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    CardEntryStripeElementComponent.prototype.allElementsComplete = function () {
        var _this = this;
        var keys = Object.keys(this.cardElements);
        return keys.filter(function (key) { return _this.cardElements[key].complete; }).length === keys.length;
    };
    CardEntryStripeElementComponent.prototype.tokenizeCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.paymentService.tokenizeCardFromInputElement(this.cardElements['cardNumber'].element, this.fake)];
                    case 1:
                        result = _a.sent();
                        if (result.error) {
                            this.cardElements['cardNumber'].error = result.error.message;
                        }
                        else {
                            this.tokenUpdate.emit(result.token.id);
                        }
                        return [2];
                }
            });
        });
    };
    return CardEntryStripeElementComponent;
}());
export { CardEntryStripeElementComponent };
