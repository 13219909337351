import { OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { MerchantBottombarService } from '../../services/merchant-bottombar/merchant-bottombar.service';
import { takeWhile } from 'rxjs/operators';
var MerchantBottombarComponent = (function () {
    function MerchantBottombarComponent(bottombarService) {
        this.bottombarService = bottombarService;
    }
    MerchantBottombarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.bottombarService.watchTemplate().pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (templateRef) {
            _this.bottombar = templateRef;
            _this.show = _this.bottombar === undefined ? false : true;
        });
    };
    MerchantBottombarComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    return MerchantBottombarComponent;
}());
export { MerchantBottombarComponent };
