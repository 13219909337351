import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): any {
    if (!value || value.length < 10) {
      return value;
    }

    return value.replace(/[^0-9]/g, '').slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

}
