var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Subject, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ApiService } from 'src/gen/joeServerCore';
import { MenuSerializerService } from 'src/app/shared/services/menu-serializer/menu-serializer.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { filterMap } from 'src/app/shared/helpers/array/filter-map';
import { retryBackoff } from 'backoff-rxjs';
var StoreMenuService = (function () {
    function StoreMenuService(apiService, menuSerializerService) {
        this.apiService = apiService;
        this.menuSerializerService = menuSerializerService;
        this.orderItemSubject = new Subject();
        this.storeMenuOptions = [];
        this.orderItemObservable = this.orderItemSubject.asObservable();
    }
    StoreMenuService.prototype.setOrderItem = function (orderItem) {
        this.orderItem = orderItem;
        this.updateOrderItem(this.orderItem);
    };
    StoreMenuService.prototype.getOrderItem = function () {
        return this.orderItem;
    };
    StoreMenuService.prototype.updateOrderItem = function (orderItem) {
        this.orderItemSubject.next(orderItem);
    };
    StoreMenuService.prototype.getStoreMenuCategorySectionsWithInventory = function (categoryId) {
        var _this = this;
        if (!this.storeMenu || !this.storeMenu.categories) {
            return of([]);
        }
        var targetCategory = this.storeMenu.categories.find(function (_a) {
            var id = _a.id;
            return id === categoryId;
        });
        if (!targetCategory) {
            return of([]);
        }
        if (targetCategory.sections && targetCategory.sections.length > 0) {
            return of(targetCategory.sections);
        }
        return this.apiService.storeGetMenuCategorySectionsWithInventory({ storeId: this.storeId, categoryId: categoryId })
            .pipe(take(1), map(function (menuSections) {
            var filteredSections = menuSections.map(function (menuSection) {
                return _this.menuSerializerService.filterMenuSection(menuSection, _this.storeMenuOptions);
            });
            targetCategory.sections = filteredSections;
            _this.updateMenuCache();
            return filteredSections;
        }));
    };
    StoreMenuService.prototype.getStoreMenuById = function (storeId, _a) {
        var _this = this;
        var _b = _a === void 0 ? {} : _a, _c = _b.includeSections, includeSections = _c === void 0 ? true : _c, _d = _b.includeItemOptions, includeItemOptions = _d === void 0 ? true : _d, _e = _b.includeMenuOptions, includeMenuOptions = _e === void 0 ? true : _e, _f = _b.includeInventory, includeInventory = _f === void 0 ? true : _f, _g = _b.includeStoreHidden, includeStoreHidden = _g === void 0 ? false : _g;
        return this.apiService.storeGetMenuV3({ storeId: storeId, includeSections: includeSections, includeItemOptions: includeItemOptions, includeMenuOptions: includeMenuOptions, includeInventory: includeInventory, includeStoreHidden: includeStoreHidden })
            .pipe(retryBackoff({ maxRetries: 5, initialInterval: 300, resetOnSuccess: true }), take(1), map(function (menuExport) { return _this.menuSerializerService.filterMenu(menuExport); }));
    };
    StoreMenuService.prototype.getCheckoutFeaturedItems = function (storeId, cartItemsIds, limit) {
        if (cartItemsIds === void 0) { cartItemsIds = []; }
        if (limit === void 0) { limit = 1; }
        var menu = safeGet(StoreMenuService.storeMenuCache, function (c) { return c[storeId].menu; });
        var featuredItemIds = safeGet(menu, function (m) { return m.checkoutFeaturedItems.map(function (i) { return i.itemId; }); }, []);
        if (featuredItemIds.length < 1) {
            return [];
        }
        var itemsInCart = new Set(cartItemsIds);
        var itemMap = this.getMenuItemIdMap(storeId);
        var featuredItems = filterMap(featuredItemIds, function (itemId) { return itemsInCart.has(itemId) ? undefined : itemMap[itemId]; });
        while (featuredItems.length > limit) {
            var removeIndex = Math.floor(Math.random() * featuredItems.length);
            featuredItems.splice(removeIndex, 1);
        }
        return featuredItems;
    };
    StoreMenuService.prototype.getItemAndIndexById = function (storeId, menuItemId) {
        var categories = safeGet(StoreMenuService.storeMenuCache, function (c) { return c[storeId].menu.categories; });
        for (var cIdx = 0, cLen = categories.length; cIdx < cLen; cIdx++) {
            var category = categories[cIdx];
            var sections = safeGet(category, function (c) { return c.sections; }, []);
            for (var sIdx = 0, sLen = sections.length; sIdx < sLen; sIdx++) {
                var section = sections[sIdx];
                var items = safeGet(section, function (s) { return s.items; }, []);
                var _loop_1 = function (iIdx, iLen) {
                    if (safeGet(items, function (i) { return i[iIdx].id; }) === menuItemId) {
                        var item = items[iIdx];
                        return { value: {
                                item: item,
                                itemIndex: iIdx,
                                categoryIndex: cIdx,
                                sectionIndex: sIdx,
                            } };
                    }
                };
                for (var iIdx = 0, iLen = sections.length; iIdx < iLen; iIdx++) {
                    var state_1 = _loop_1(iIdx, iLen);
                    if (typeof state_1 === "object")
                        return state_1.value;
                }
            }
        }
    };
    StoreMenuService.prototype.getMenuItemIdMap = function (storeId) {
        var menu = safeGet(StoreMenuService.storeMenuCache, function (c) { return c[storeId].menu; });
        var itemMap = {};
        (menu.categories || []).forEach(function (category) {
            (category.sections || []).forEach(function (section) {
                (section.items || []).forEach(function (item) {
                    itemMap[item.id] = item;
                });
            });
        });
        return itemMap;
    };
    StoreMenuService.prototype.getItem = function (storeId, categoryIndex, sectionIndex, itemIndex) {
        if (this.storeMenu && this.storeId === storeId) {
            var selectedCategory = this.storeMenu.categories[categoryIndex];
            var item = selectedCategory.sections[sectionIndex].items[itemIndex];
            return of(item);
        }
        return this.getStoreMenuById(storeId).pipe(map(function (storeMenu) {
            var selectedCategory = storeMenu.categories[categoryIndex];
            var item = selectedCategory.sections[sectionIndex].items[itemIndex];
            return item;
        }));
    };
    StoreMenuService.prototype.getItemCategoryAndSectionIds = function (orderItem) {
        var categoryIndex = orderItem.categoryId;
        var sectionIndex = orderItem.sectionId;
        var categories = safeGet(this.storeMenu, function (m) { return m.categories; }, []);
        var categoryFromIndex = safeGet(categories, function (c) { return c[categoryIndex]; });
        var sectionFromIndex = safeGet(categoryFromIndex, function (c) { return c.sections[sectionIndex]; });
        if (categoryFromIndex && sectionFromIndex) {
            return {
                itemCategoryId: categoryFromIndex.id,
                itemSectionId: sectionFromIndex.id,
            };
        }
        else {
            var orderItemId = orderItem.itemId;
            for (var cIdx = 0, cLen = categories.length; cIdx < cLen; cIdx++) {
                var category = categories[cIdx];
                var sections = safeGet(category, function (c) { return c.sections; }, []);
                for (var sIdx = 0, sLen = sections.length; sIdx < sLen; sIdx++) {
                    var section = sections[sIdx];
                    var items = safeGet(section, function (s) { return s.items; }, []);
                    var _loop_2 = function (iIdx, iLen) {
                        if (safeGet(items, function (i) { return i[iIdx].id; }) === orderItemId) {
                            return { value: {
                                    itemCategoryId: category.id,
                                    itemSectionId: section.id,
                                } };
                        }
                    };
                    for (var iIdx = 0, iLen = items.length; iIdx < iLen; iIdx++) {
                        var state_2 = _loop_2(iIdx, iLen);
                        if (typeof state_2 === "object")
                            return state_2.value;
                    }
                }
            }
        }
    };
    StoreMenuService.prototype.setStoreItemInventory = function (menuItemSizeId, quantityAvailable, hidden) {
        return this.apiService.storeSetItemInventory({
            menuItemSizeId: menuItemSizeId,
            quantityAvailable: quantityAvailable,
            hidden: hidden,
        }).toPromise();
    };
    StoreMenuService.prototype.setStoreOptionInventory = function (menuOptionChoiceId, quantityAvailable, hidden) {
        return this.apiService.storeSetOptionInventory({
            menuOptionChoiceId: menuOptionChoiceId,
            quantityAvailable: quantityAvailable,
            hidden: hidden,
        }).toPromise();
    };
    StoreMenuService.prototype.deleteStoreItemInventory = function (itemInventoryId) {
        return this.apiService.storeDeleteItemInventory(itemInventoryId).toPromise();
    };
    StoreMenuService.prototype.getStoreOutOfStockCount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, items, options, itemsCount, optionsCount;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, Promise.all([
                            this.apiService.storeItemInventoryGetOutOfStockCount().toPromise(),
                            this.apiService.storeOptionInventoryGetOutOfStockCount().toPromise(),
                        ])];
                    case 1:
                        _a = _b.sent(), items = _a[0], options = _a[1];
                        itemsCount = safeGet(items, function (i) { return i.outOfStockItemsCount; }, 0);
                        optionsCount = safeGet(options, function (o) { return o.outOfStockOptionsCount; }, 0);
                        return [2, itemsCount + optionsCount];
                }
            });
        });
    };
    StoreMenuService.prototype.updateMenuCache = function () {
        var _a;
        StoreMenuService.storeMenuCache = (_a = {},
            _a[this.storeId] = {
                created: Date.now(),
                menu: this.storeMenu,
                flags: this.storeMenuFlags,
            },
            _a);
    };
    StoreMenuService.storeMenuCache = {};
    return StoreMenuService;
}());
export { StoreMenuService };
