var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Big } from 'big.js';
import { Stripe } from '@ionic-native/stripe';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
export var PaymentErrors = {
    NOT_INITIALIZED: 'NOT_INITIALIZED',
};
export var MobilePaymentProvider;
(function (MobilePaymentProvider) {
    MobilePaymentProvider[MobilePaymentProvider["apple"] = 0] = "apple";
    MobilePaymentProvider[MobilePaymentProvider["google"] = 1] = "google";
})(MobilePaymentProvider || (MobilePaymentProvider = {}));
var NativePaymentService = (function () {
    function NativePaymentService(stripe, environmentService) {
        this.stripe = stripe;
        this.environmentService = environmentService;
        var config = environmentService.getConfig();
        this.stripeApplePay = window['ApplePay'];
        this.stripeGooglePay = window['GooglePay'];
        this.init({
            iosMerchantId: config.payment.iosMerchantId,
            publishableKey: config.payment.stripePublishable,
            publishableTestKey: config.payment.stripePublishableTest,
        });
    }
    NativePaymentService.prototype.init = function (_a) {
        var publishableKey = _a.publishableKey, publishableTestKey = _a.publishableTestKey, iosMerchantId = _a.iosMerchantId;
        return __awaiter(this, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.environmentService.onReady()];
                    case 1:
                        _c.sent();
                        return [4, this.environmentService.isNative()];
                    case 2:
                        if (!(_c.sent())) {
                            return [2];
                        }
                        _b = this;
                        return [4, this.environmentService.getPlatform()];
                    case 3:
                        _b.devicePlatform = _c.sent();
                        this.publishableKey = publishableKey;
                        this.publishableTestKey = publishableTestKey;
                        return [4, this.stripe.setPublishableKey(publishableKey)];
                    case 4:
                        _c.sent();
                        if (!(this.devicePlatform === 2 && this.stripeApplePay)) return [3, 7];
                        return [4, this.stripeApplePay.setPublishableKey(publishableKey)];
                    case 5:
                        _c.sent();
                        return [4, this.stripeApplePay.setMerchantId(iosMerchantId)];
                    case 6:
                        _c.sent();
                        this.iosMerchantId = iosMerchantId;
                        _c.label = 7;
                    case 7:
                        if (!(this.devicePlatform === 3 && this.stripeGooglePay)) return [3, 9];
                        return [4, this.stripeGooglePay.initGooglePayClient(publishableKey)];
                    case 8:
                        _c.sent();
                        _c.label = 9;
                    case 9:
                        this.didInit = true;
                        return [2];
                }
            });
        });
    };
    NativePaymentService.prototype.supportsMobilePay = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.environmentService.onReady()];
                    case 1:
                        _b.sent();
                        _a = this.devicePlatform;
                        switch (_a) {
                            case 2: return [3, 2];
                            case 3: return [3, 7];
                        }
                        return [3, 12];
                    case 2:
                        if (!this.stripeApplePay) {
                            return [2];
                        }
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 6]);
                        return [4, this.stripeApplePay.canMakePayments()];
                    case 4:
                        _b.sent();
                        return [2, MobilePaymentProvider.apple];
                    case 5:
                        e_1 = _b.sent();
                        return [3, 6];
                    case 6: return [2];
                    case 7:
                        if (!this.stripeGooglePay) {
                            return [2];
                        }
                        _b.label = 8;
                    case 8:
                        _b.trys.push([8, 10, , 11]);
                        return [4, this.stripeGooglePay.isReadytoPay()];
                    case 9:
                        _b.sent();
                        return [2, MobilePaymentProvider.google];
                    case 10:
                        e_2 = _b.sent();
                        return [3, 11];
                    case 11: return [3, 12];
                    case 12: return [2];
                }
            });
        });
    };
    NativePaymentService.prototype.createMobilePayment = function (_a) {
        var label = _a.label, amount = _a.amount;
        return __awaiter(this, void 0, void 0, function () {
            var supportedPlatform, dollarAmount, _b, applePayResult, e_3, googlePayResult, e_4;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.environmentService.onReady()];
                    case 1:
                        _c.sent();
                        return [4, this.supportsMobilePay()];
                    case 2:
                        supportedPlatform = _c.sent();
                        dollarAmount = parseFloat(Big(amount).div(100).toFixed(2).toString());
                        _b = this.devicePlatform;
                        switch (_b) {
                            case 2: return [3, 3];
                            case 3: return [3, 9];
                        }
                        return [3, 14];
                    case 3:
                        if (supportedPlatform !== MobilePaymentProvider.apple) {
                            return [2];
                        }
                        _c.label = 4;
                    case 4:
                        _c.trys.push([4, 7, , 8]);
                        return [4, this.stripeApplePay.makePaymentRequest({
                                items: [
                                    {
                                        label: label,
                                        amount: dollarAmount,
                                    },
                                ],
                                merchantIdentifier: this.iosMerchantId,
                                currencyCode: 'USD',
                                countryCode: 'US',
                                billingAddressRequirement: 'none',
                                shippingAddressRequirement: 'none',
                                shippingType: 'store',
                            })];
                    case 5:
                        applePayResult = _c.sent();
                        return [4, this.stripeApplePay.completeLastTransaction('success')];
                    case 6:
                        _c.sent();
                        return [2, applePayResult.stripeToken];
                    case 7:
                        e_3 = _c.sent();
                        return [3, 8];
                    case 8: return [2];
                    case 9:
                        if (supportedPlatform !== MobilePaymentProvider.google) {
                            return [2];
                        }
                        _c.label = 10;
                    case 10:
                        _c.trys.push([10, 12, , 13]);
                        return [4, this.stripeGooglePay.requestPayment(dollarAmount, 'USD')];
                    case 11:
                        googlePayResult = _c.sent();
                        return [2, googlePayResult];
                    case 12:
                        e_4 = _c.sent();
                        return [3, 13];
                    case 13: return [2];
                    case 14: return [2];
                }
            });
        });
    };
    NativePaymentService.prototype.validateCard = function (card) {
        if (!this.didInit) {
            return Promise.reject(PaymentErrors.NOT_INITIALIZED);
        }
        return this.stripe.validateCardNumber(card.number);
    };
    NativePaymentService.prototype.tokenizeCard = function (card) {
        var _this = this;
        if (!this.didInit) {
            return Promise.reject(PaymentErrors.NOT_INITIALIZED);
        }
        return this.runAsTestMaybe(function () { return _this.stripe.createCardToken(card); }, card.fake).then(function (result) { return ({
            token: result.id,
            last4: result.card.last4,
            type: result.card.brand,
            fake: card.fake,
        }); });
    };
    NativePaymentService.prototype.getCardType = function (cardNumber) {
        if (!this.didInit) {
            return Promise.reject(PaymentErrors.NOT_INITIALIZED);
        }
        return this.stripe.getCardType(cardNumber);
    };
    NativePaymentService.prototype.runAsTestMaybe = function (action, testMode) {
        return __awaiter(this, void 0, void 0, function () {
            var result, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!testMode) return [3, 2];
                        return [4, this.stripe.setPublishableKey(this.publishableTestKey)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4, action()];
                    case 3:
                        result = _a.sent();
                        return [3, 5];
                    case 4:
                        e_5 = _a.sent();
                        result = Promise.reject(e_5);
                        return [3, 5];
                    case 5: return [4, this.stripe.setPublishableKey(this.publishableKey)];
                    case 6:
                        _a.sent();
                        return [2, result];
                }
            });
        });
    };
    return NativePaymentService;
}());
export { NativePaymentService };
