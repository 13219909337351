import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ErrorDialogComponent, ErrorOptions } from '../../components/error-dialog/error-dialog.component';

@Injectable()
export class ErrorDialogService {

  private static dialogRef: MatDialogRef<ErrorDialogComponent>;

  constructor(
    private dialog: MatDialog,
  ) { }

  show(options: ErrorOptions): void {
    this.dismiss();
    ErrorDialogService.dialogRef = this.dialog.open(ErrorDialogComponent, { data: options, minWidth: '300px', maxWidth: '600px' });
  }

  dismiss(): void {
    if (ErrorDialogService.dialogRef) {
      ErrorDialogService.dialogRef.close();
      ErrorDialogService.dialogRef = undefined;
    }
  }

}

export { ErrorOptions };
