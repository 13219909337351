var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { Socket } from 'ngx-socket-io';
var MerchantServiceSocketService = (function (_super) {
    __extends(MerchantServiceSocketService, _super);
    function MerchantServiceSocketService(environmentService) {
        return _super.call(this, {
            url: environmentService.getConfig().apiUrls.joeMerchantService,
            options: {
                autoConnect: false,
            },
        }) || this;
    }
    return MerchantServiceSocketService;
}(Socket));
export { MerchantServiceSocketService };
