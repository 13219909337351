import { BuildData } from 'src/environments/interfaces/environment-config.interface';

/**
 * NOTE: this file is overwritten during vsts build
 */
export const buildData: BuildData = {
  number: 'DEV_1250867835',
  definition: 'NOT_SET',
  sourceBranch: 'main',
};
