import { DragulaDirective } from './directives/dragula/dragula.directive';
var EXPORTED_DECLARATIONS = [
    DragulaDirective,
];
var Ng2DragulaRebornModule = (function () {
    function Ng2DragulaRebornModule() {
    }
    return Ng2DragulaRebornModule;
}());
export { Ng2DragulaRebornModule };
