import { Component, Input, EventEmitter, Output, TemplateRef } from '@angular/core';

const SUPPORTED_COLORS = new Set(['primary', 'light', 'accent', 'dark', 'transparent']);
const DEFAULT_FRONT_COLOR = 'primary';
const DEFAULT_BACK_COLOR = 'primary';
const DEFAULT_FRONT_shadow = true;
const DEFAULT_BACK_shadow = true;

@Component({
  selector: 'ui-flip-fab',
  templateUrl: './ui-flip-fab.component.html',
  styleUrls: ['./ui-flip-fab.component.scss'],
})
export class UiFlipFabComponent {

  @Input()
  front: TemplateRef<any>;

  @Input()
  back: TemplateRef<any>;

  @Input()
  flipped = false;

  @Input()
  set colorFront(colorFront: string) {
    this.colorFrontClass = SUPPORTED_COLORS.has(colorFront) ? colorFront : DEFAULT_FRONT_COLOR;
  }
  colorFrontClass: string = DEFAULT_FRONT_COLOR;

  @Input()
  set colorBack(colorFront: string) {
    this.colorBackClass = SUPPORTED_COLORS.has(colorFront) ? colorFront : DEFAULT_BACK_COLOR;
  }
  colorBackClass: string = DEFAULT_BACK_COLOR;

  @Input()
  shadowFront = DEFAULT_FRONT_shadow;

  @Input()
  shadowBack = DEFAULT_BACK_shadow;

  @Output()
  flippedChange: EventEmitter<boolean> = new EventEmitter();

  toggle(): void {
    this.flipped = !this.flipped;
    this.flippedChange.emit(this.flipped);
  }

}
