var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CompanyService } from 'src/app/merchant/services/company/company.service';
import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
var ActiveCompanyService = (function () {
    function ActiveCompanyService(companyService) {
        this.companyService = companyService;
    }
    ActiveCompanyService.prototype.ngOnDestroy = function () {
        ActiveCompanyService.activeCompanySubject.complete();
    };
    ActiveCompanyService.prototype.updateCompanies = function (company, activateCompanyId) {
        return __awaiter(this, void 0, void 0, function () {
            var updateCompany, _a, hasActive;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(company && ActiveCompanyService.companies)) return [3, 1];
                        updateCompany = ActiveCompanyService.companies.find(function (c) { return c.id === company.id; });
                        if (updateCompany) {
                            Object.assign(updateCompany, company);
                        }
                        else {
                            ActiveCompanyService.companies.push(company);
                        }
                        return [3, 3];
                    case 1:
                        _a = ActiveCompanyService;
                        return [4, this.companyService.getUserCompanies().toPromise()];
                    case 2:
                        _a.companies = _b.sent();
                        _b.label = 3;
                    case 3:
                        if (ActiveCompanyService.companies && ActiveCompanyService.companies.length > 0) {
                            if (activateCompanyId) {
                                this.setActive(activateCompanyId);
                                return [2];
                            }
                            if (ActiveCompanyService.activeCompany) {
                                hasActive = ActiveCompanyService.companies.find(function (c) { return ActiveCompanyService.activeCompany.id === c.id; });
                                if (!hasActive) {
                                    this.clearActive();
                                    return [2];
                                }
                            }
                        }
                        else {
                            this.clearActive();
                            return [2];
                        }
                        this.emitChanges();
                        return [2];
                }
            });
        });
    };
    ActiveCompanyService.prototype.setActive = function (companyId) {
        if (!ActiveCompanyService.companies || (ActiveCompanyService.activeCompany && ActiveCompanyService.activeCompany.id === companyId)) {
            return;
        }
        ActiveCompanyService.activeCompany = ActiveCompanyService.companies.find(function (c) { return c.id === companyId; });
        this.emitChanges();
    };
    ActiveCompanyService.prototype.clearActive = function () {
        this.setActive(undefined);
    };
    ActiveCompanyService.prototype.getActive = function () {
        return ActiveCompanyService.activeCompany;
    };
    ActiveCompanyService.prototype.watch = function () {
        return ActiveCompanyService.activeCompanySubject.asObservable();
    };
    ActiveCompanyService.prototype.emitChanges = function () {
        ActiveCompanyService.activeCompanySubject.next({
            active: ActiveCompanyService.activeCompany,
            companies: ActiveCompanyService.companies,
        });
    };
    ActiveCompanyService.activeCompanySubject = new BehaviorSubject({ active: null, companies: [] });
    return ActiveCompanyService;
}());
export { ActiveCompanyService };
