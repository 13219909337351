import { PipeTransform } from '@angular/core';
var DistanceToMilesPipe = (function () {
    function DistanceToMilesPipe() {
    }
    DistanceToMilesPipe.prototype.transform = function (value) {
        var isFloat = value % 1 !== 0;
        var valueStr;
        if (isFloat || value !== 1) {
            if (isFloat) {
                valueStr = value.toString().slice(0, 4);
            }
            return (valueStr || value) + " miles";
        }
        else {
            return value + " mile";
        }
    };
    return DistanceToMilesPipe;
}());
export { DistanceToMilesPipe };
