import { PipeTransform } from '@angular/core';
var SecondsDurationToTextPipe = (function () {
    function SecondsDurationToTextPipe() {
    }
    SecondsDurationToTextPipe.prototype.transform = function (totalMinutes) {
        if (totalMinutes < 60) {
            return totalMinutes + " minutes";
        }
        var minutes = totalMinutes % 60;
        var hours = Math.floor(totalMinutes / 60);
        var minuteText = minutes > 0 ? " " + minutes + " minutes" : '';
        return hours + " hours" + minuteText;
    };
    return SecondsDurationToTextPipe;
}());
export { SecondsDurationToTextPipe };
