var OrderItemOptionChoice = (function () {
    function OrderItemOptionChoice(data, sizePrices) {
        this.data = data;
        this.sizePrices = sizePrices;
    }
    Object.defineProperty(OrderItemOptionChoice.prototype, "id", {
        get: function () {
            return this.data.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "flags", {
        get: function () {
            return this.data.flags;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "hidden", {
        get: function () {
            return this.data.hidden;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "name", {
        get: function () {
            return this.data.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "index", {
        get: function () {
            return this.data.index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "quantity", {
        get: function () {
            return this.data.quantity;
        },
        set: function (quantity) {
            this.data.quantity = quantity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "unit", {
        get: function () {
            return this.data.unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "quantityNames", {
        get: function () {
            return this.data.quantityNames;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "minQuantity", {
        get: function () {
            return this.data.minQuantity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "maxQuantity", {
        get: function () {
            return this.data.maxQuantity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderItemOptionChoice.prototype, "increment", {
        get: function () {
            return this.data.increment;
        },
        enumerable: true,
        configurable: true
    });
    OrderItemOptionChoice.prototype.getPrice = function (sizeId) {
        return this.sizePrices.hasOwnProperty(sizeId) ? this.sizePrices[sizeId] : this.data.price;
    };
    OrderItemOptionChoice.prototype.setQuantity = function (quantityIndex) {
        if (this.quantityNames !== undefined && quantityIndex < this.quantityNames.length) {
            this.quantity = quantityIndex;
            return this.quantity;
        }
        else {
            return undefined;
        }
    };
    OrderItemOptionChoice.prototype.incrementChoice = function () {
        this.quantity += this.increment;
        this.clampChoiceQuantity();
    };
    OrderItemOptionChoice.prototype.decrementChoice = function () {
        this.quantity -= this.increment;
        this.clampChoiceQuantity();
    };
    OrderItemOptionChoice.prototype.clampChoiceQuantity = function () {
        if (this.maxQuantity !== -1 && this.quantity > this.maxQuantity) {
            this.quantity = this.maxQuantity;
        }
        if (this.quantity < this.minQuantity) {
            this.quantity = this.minQuantity;
        }
    };
    return OrderItemOptionChoice;
}());
export { OrderItemOptionChoice };
