import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

@Component({
  selector: 'merchant-plus-nag-dialog',
  templateUrl: './merchant-plus-nag-dialog.component.html',
  styleUrls: ['./merchant-plus-nag-dialog.component.scss'],
})
export class MerchantPlusNagDialogComponent implements OnInit {
  isNative: boolean;
  platform: string;
  link: string;
  disableCloseDate: number;

  constructor(
    private environmentService: EnvironmentService,
  ) { }

  public async ngOnInit() {
    this.isNative = await this.environmentService.isNative();

    if (this.isNative) {
      this.platform = await this.environmentService.getPlatformName();

      if (this.platform === 'android') {
        this.link = `https://play.google.com/store/apps/details?id=coffee.joe.pos&hl=en_US&gl=US`;
      }

      if (this.platform === 'ios') {
        this.link = `https://apps.apple.com/us/app/joe-merchant-plus/id1543320107`;
      }
    } else {
      this.link = `https://support.joe.coffee/category/99-joe-merchant-plus`;
    }
  }

}
