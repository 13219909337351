import { Injectable } from '@angular/core';
import { AndroidFullScreen } from '@ionic-native/android-full-screen';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { TelemetryService } from '../../../telemetry/services/telemetry/telemetry.service';

/**
 * Handles all native device display related operations
 */
@Injectable()
export class NativeDisplayService {

  constructor(
    private androidFullScreen: AndroidFullScreen,
    private telemetryService: TelemetryService,
  ) { }

  async startFullscreen(): Promise<boolean> {
    try {
      // void - throws if not supported (not sure if this is even needed, but I'd rather be safe)
      await this.androidFullScreen.isImmersiveModeSupported();
      await this.androidFullScreen.immersiveMode();
      return true;
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
    }
    return false;
  }

  async endFullscreen(): Promise<boolean> {
    try {
      await this.androidFullScreen.showSystemUI();
      return true;
    } catch (error) {
      const errorMessage = safeGet(error, e => e.message || e.toString()) || 'Unknown error';
      this.telemetryService.logError(errorMessage);
    }
    return false;
  }

}
