var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApiService } from 'src/gen/joeServerCore';
import { downloadStringToFile } from 'src/app/shared/helpers/file/download-string-to-file';
import { getDateMMDDYYYY } from 'src/app/shared/helpers/date/get-date-mmddyyyy';
import { map } from 'rxjs/operators';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
var CompanyService = (function () {
    function CompanyService(apiService, telemetryService) {
        this.apiService = apiService;
        this.telemetryService = telemetryService;
    }
    CompanyService.prototype.getDashboardData = function (companyId, start, end, granularity, reports, storeIds) {
        return this.apiService.merchantDashboardV2({ companyId: companyId, start: start, end: end, granularity: granularity, reports: reports, storeIds: storeIds });
    };
    CompanyService.prototype.getLandingPage = function (companyId) {
        return this.apiService.companyGetLandingPage(companyId);
    };
    CompanyService.prototype.getUserCompanies = function () {
        return this.apiService.companyList();
    };
    CompanyService.prototype.create = function (company) {
        return this.apiService.companyCreate(company);
    };
    CompanyService.prototype.update = function (companyId, company) {
        return this.apiService.companyUpdate({ companyId: companyId, CompanyUpdateDto: company });
    };
    CompanyService.prototype.verify = function (companyId, verification) {
        return this.apiService.companyVerificationV2({ companyId: companyId, CompanyVerificationDto: verification })
            .pipe(map(function (_a) {
            var result = _a.result;
            return !!result;
        }));
    };
    CompanyService.prototype.setCompanyApproval = function (companyId, approved) {
        return this.apiService.adminSetCompanyApproval({ companyId: companyId, approved: approved })
            .pipe(map(function (_a) {
            var _b = _a.result, result = _b === void 0 ? false : _b;
            return result;
        }));
    };
    CompanyService.prototype.get = function (companyId) {
        return this.apiService.companyGet(companyId);
    };
    CompanyService.prototype.getStores = function (companyId) {
        return this.apiService.companyGetStores(companyId);
    };
    CompanyService.prototype.getMenus = function (companyId) {
        return this.apiService.companyGetMenus(companyId);
    };
    CompanyService.prototype.downloadTransferReport = function (transferBatch, type, format) {
        var _this = this;
        var companyId = safeGet(transferBatch, function (t) { return t.transfers[0].companyId; });
        var transferBatchId = safeGet(transferBatch, function (t) { return t.batchId; });
        return this.apiService.companyDownloadTransferReport({ companyId: companyId, type: type, transferBatchId: transferBatchId, format: format })
            .pipe(map(function (result) {
            var data = safeGet(result, function (r) { return r.data; });
            var dateStart = safeGet(transferBatch, function (t) { return getDateMMDDYYYY(t.start); });
            var dateEnd = safeGet(transferBatch, function (t) { return getDateMMDDYYYY(t.end); });
            var companyName = safeGet(transferBatch, function (t) { return t.transfers[0].companyName; }, companyId || '');
            var safeCompanyName = companyName.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/-{2,}/g, '');
            var downloadFilename = safeCompanyName + "-" + dateStart + "-" + dateEnd + "-" + type + "." + format;
            try {
                downloadStringToFile(downloadFilename, data);
                return true;
            }
            catch (error) {
                var errorMessage = safeGet(error, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                _this.telemetryService.logError(errorMessage);
                return false;
            }
        }));
    };
    CompanyService.prototype.getTransfers = function (companyId) {
        return this.apiService.companyGetTransfers(companyId);
    };
    CompanyService.prototype.getTransfersGroupedByBatch = function (companyId) {
        return this.getTransfers(companyId).pipe(map(function (transfers) {
            var transferBatchMap = {};
            (transfers || []).forEach(function (transfer) {
                var start = new Date(transfer.start);
                var end = new Date(transfer.end);
                var batchId = transfer.transferStatus === 'new' ? 'pending' : transfer.transferBatchIdentifier || transfer.id;
                if (!transferBatchMap[batchId]) {
                    transferBatchMap[batchId] = {
                        batchId: batchId,
                        start: start,
                        end: end,
                        emailed: 0,
                        transferred: 0,
                        total: 0,
                        stores: new Set(),
                        transfers: [],
                    };
                }
                var targetBatch = transferBatchMap[batchId];
                if (targetBatch.start > start) {
                    targetBatch.start = start;
                }
                if (targetBatch.end < end) {
                    targetBatch.end = end;
                }
                if (transfer.emailTime) {
                    targetBatch.emailed++;
                }
                if (transfer.transferTime) {
                    targetBatch.transferred++;
                }
                targetBatch.total += parseInt(transfer.total, 10);
                targetBatch.stores.add(transfer.storeId);
                targetBatch.transfers.push(transfer);
            });
            return Object.keys(transferBatchMap).map(function (transferId) {
                return transferBatchMap[transferId];
            }).sort(function (a, b) { return b.start.getTime() - a.start.getTime(); });
        }));
    };
    CompanyService.prototype.getReportSubscribers = function (companyId) {
        return this.apiService.companyReportSubscriberList(companyId);
    };
    CompanyService.prototype.addReportSubscriber = function (companyId, email, type) {
        return this.apiService.companyReportSubscriberCreate({ companyId: companyId, CompanyReportSubscriberDto: { email: email, type: type } });
    };
    CompanyService.prototype.removeReportSubscriber = function (companyId, subscriberId) {
        return this.apiService.companyReportSubscriberDelete({
            companyId: companyId, subscriberId: subscriberId,
        }).pipe(map(function (_a) {
            var result = _a.result;
            return result;
        }));
    };
    CompanyService.prototype.getUsers = function (companyId) {
        return this.apiService.companyGetUsers(companyId);
    };
    CompanyService.prototype.getInvites = function (companyId) {
        return this.apiService.companyGetInvites(companyId);
    };
    CompanyService.prototype.cancelInvite = function (companyId, inviteId) {
        return this.apiService.companyCancelInvite({ companyId: companyId, inviteId: inviteId })
            .pipe(map(function (_a) {
            var _b = _a.result, result = _b === void 0 ? false : _b;
            return result;
        }));
    };
    CompanyService.prototype.removeRole = function (companyId, userId, roleName) {
        return this.apiService.companyDeleteUserRole({ userId: userId, companyId: companyId, roleName: roleName })
            .pipe(map(function (_a) {
            var _b = _a.result, result = _b === void 0 ? false : _b;
            return result;
        }));
    };
    CompanyService.prototype.addBankAccount = function (companyId, bankAccount) {
        return this.apiService.companyBankAccountAdd({ companyId: companyId, CompanyBankAccountAddDto: bankAccount });
    };
    CompanyService.prototype.addLandingHeroBackground = function (companyId, file, options) {
        return this.apiService.companyLandingAddHeroBackground(__assign({ companyId: companyId, file: file }, options)).pipe(map(function (_a) {
            var url = _a.url;
            return url;
        }));
    };
    CompanyService.prototype.addLandingLogo = function (companyId, file) {
        return this.apiService.companyLandingAddLogo({ companyId: companyId, file: file }).pipe(map(function (_a) {
            var url = _a.url;
            return url;
        }));
    };
    CompanyService.prototype.sendUserInvite = function (companyId, userData) {
        return this.apiService.companyInviteUser({ companyId: companyId, CompanyCreateInviteRequestDto: userData })
            .pipe(map(function (_a) {
            var _b = _a.result, result = _b === void 0 ? false : _b;
            return result;
        }));
    };
    CompanyService.prototype.new = function () {
        return {
            name: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            taxId: '',
        };
    };
    return CompanyService;
}());
export { CompanyService };
