import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { environment } from '../environments/environment';

// PAGES IMPORTS
import { HomeModule } from './shared/pages/home/home.module';
import { LoginModule } from './shared/pages/login/login.module';
import { Error404Module } from './shared/pages/error-404/error-404.module';

// App Chromes
import { UiLoadingIndicatorModule } from './shared/packages/ui-loading-indicator';
import { UiMerchantChromeModule } from './merchant/packages/ui-merchant-chrome';
import { UiPosChromeModule } from './pos/packages/ui-pos-chrome';

// Services
import { ApiModule, ApiServiceTokenProviderInjectionToken } from 'src/gen/joeServerCore';
import { AuthGuardService } from './shared/route-guards/auth-guard/auth-guard.service';
import { EnvironmentService } from './shared/services/environment/environment.service';
import { IdentityService } from './shared/services/identity/identity.service';
import { ReactiveFormService } from './shared/services/reactive-form/reactive-form.service';
import { UpdateService } from './shared/services/update/update.service';

// Core package imports
import { DataTransformPipesModule } from './shared/packages/data-transform-pipes';
import { UiErrorDialogModule } from './shared/packages/ui-error-dialog/ui-error-dialog.module';
import { UiConsumerChromeModule } from './consumer/packages/ui-consumer-chrome/ui-consumer-chrome.module';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler/global-error-handler.service';
import { PosDeviceGuardService } from './shared/route-guards/pos-device-guard/pos-device-guard.service';
import { ConsumerGuardService } from './shared/route-guards/consumer-guard/consumer-guard.service';
import { MerchantGuardService } from './shared/route-guards/merchant-guard/merchant-guard.service';
import { MerchantMenuOptionEditModule } from './merchant/dialogs/merchant-menu-option-edit/merchant-menu-option-edit.module';
import { UserVerificationModule } from './shared/packages/user-verification/user-verification.module';
import { NativeSupportModule } from './shared/packages/native-support';
import { PaymentService } from './shared/services/payment/payment.service';
import { MerchantEditDialogsModule } from './merchant/pages/merchant-company-edit/dialogs/merchant-edit-dialogs/merchant-edit-dialogs.module';
import { StoreCheckoutModule } from './consumer/pages/store/store-checkout/store-checkout.module';
import { UserService } from './shared/services/user/user.service';
import { PosDeviceModule } from './pos/services/pos-device-module/pos-device.module';
import { FeatureSwitchService } from './shared/services/feature-switch/feature-switch.service';
import { NATIVE_HTTP_INTERCEPTOR_PROVIDER, NativeHttpInterceptorService } from './shared/packages/native-support/services/native-http-interceptor/native-http-interceptor.service';
import { StorageModule } from './shared/packages/storage';
import { AppInitializationService } from './shared/services/app-initialization/app-initialization.service';
import { LogoutGuardService } from './shared/route-guards/logout-guard/logout-guard.service';
import { UiConfirmDialogModule } from './shared/packages/ui-confirm-dialog';
import { TelemetryModule } from './shared/packages/telemetry';
import { ScrollServiceService } from './shared/services/scroll-service/scroll-service.service';
import { CloudinaryUploadService } from './shared/services/cloudinary-upload/cloudinary-upload.service';
import { UiBottomBarModule } from './shared/packages/ui-bottom-bar';
import { MenuSerializerService } from './shared/services/menu-serializer/menu-serializer.service';
import { UserConsumerLoginModule } from './shared/packages/user-consumer-login';
import { MerchantMenuItemOptionDialogModule } from './merchant/dialogs/merchant-menu-item-option-dialog/merchant-menu-item-option-dialog.module';
import { StoreCartService } from './consumer/services/store-cart/store-cart.service';
import { StringUtilService } from './shared/services/string-util/string-util.service';
import { SocialShareModule } from './shared/packages/social-share';
import { ApiTokenProviderServiceService } from './shared/services/api-token-provider-service/api-token-provider-service.service';
import { MerchantPlusNagDialogModule } from './merchant/dialogs/merchant-plus-nag-dialog/merchant-plus-nag-dialog.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NativeSupportModule.forRoot(),
    ApiModule,
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: environment.traceRoutes,
      onSameUrlNavigation: 'reload',
    }),
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    StorageModule,

    // PWA - disabled
    // TODO: possibly re-enable in the future and ensure disabled on mobile
    // environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],

    // Core Packages
    DataTransformPipesModule,
    UiErrorDialogModule,
    UiLoadingIndicatorModule.forRoot(),
    UiMerchantChromeModule.forRoot(),
    UiConsumerChromeModule.forRoot(),
    UiBottomBarModule.forRoot(),
    PosDeviceModule.forRoot(),
    UiPosChromeModule,
    TelemetryModule,

    // DIALOGS
    // Modules with dialogs have to be loaded here because entry components does not support lazy loaded modules
    // issue being tracked here: https://github.com/angular/angular/issues/14324
    MerchantEditDialogsModule,
    // TODO: break these into their own modules too like above. (reduce bundle size / cross-over)
    MerchantMenuOptionEditModule,
    UserVerificationModule,
    StoreCheckoutModule,
    UserConsumerLoginModule,
    SocialShareModule,
    UiConfirmDialogModule,
    MerchantMenuItemOptionDialogModule,
    MerchantPlusNagDialogModule,

    // SHARED PAGES
    HomeModule,
    LoginModule,
    Error404Module,

  ],
  providers: [
    { provide: ApiServiceTokenProviderInjectionToken, useClass: ApiTokenProviderServiceService },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

    // ApiService,
    EnvironmentService,
    AppInitializationService,
    IdentityService,
    UserService,
    ReactiveFormService,
    // StorageService,
    UpdateService,
    FeatureSwitchService,
    MenuSerializerService,
    StoreCartService,
    StringUtilService,

    PaymentService,
    ScrollServiceService,
    CloudinaryUploadService,

    // Route Guards
    AuthGuardService,
    PosDeviceGuardService,
    ConsumerGuardService,
    MerchantGuardService,
    LogoutGuardService,

    // HTTP Interceptors
    NativeHttpInterceptorService,
    NATIVE_HTTP_INTERCEPTOR_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
