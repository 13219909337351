import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatButtonModule, MatDialogModule, MatInputModule, MatCheckboxModule, MatTableModule, MatButtonToggleModule } from '@angular/material';
import { MatCurrencyInputModule } from 'src/app/shared/packages/mat-currency-input';
import { MerchantMenuItemOptionDialogComponent } from './components/merchant-menu-item-option-dialog/merchant-menu-item-option-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTransformPipesModule } from 'src/app/shared/packages/data-transform-pipes';
import { MenuItemSizeChipModule } from '../../packages/menu-item-size-chip/menu-item-size-chip.module';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatCheckboxModule,
  MatTableModule,
  MatButtonToggleModule,

  // custom
  MatCurrencyInputModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DataTransformPipesModule,
    MenuItemSizeChipModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    MerchantMenuItemOptionDialogComponent,
  ],
  entryComponents: [
    MerchantMenuItemOptionDialogComponent,
  ],
})
export class MerchantMenuItemOptionDialogModule { }
