var BANNED_PASSWORDS = new Set([
    'password', 'qwertyuiop', 'qwertyui', 'asdfghjk', '10293847', 'iloveyou', 'starwars', 'passw0rd', 'whatever', 'password1',
    'princess', '1qaz2wsx', '!qaz@wsx', '1qaz2wsx3edc', '!qaz@wsx#edc',
]);
var MAX_SEQUENTIAL_CHARS = 5;
var MIN_UNIQUE_CHARS = 3;
var MIN_LENGTH = 8;
function createErrorResponse(message) {
    return { pw_insecure: message };
}
function countUniqueChars(chars) {
    return new Set(chars.split('')).size;
}
function countSequentialChars(value) {
    var lowerValue = value.toLowerCase();
    var count = 0;
    var currentCount = 0;
    var lastCharCode = Infinity;
    for (var i = 0; i < lowerValue.length; i++) {
        var charCode = lowerValue.charCodeAt(i);
        if (Math.abs(lastCharCode - charCode) === 1) {
            currentCount++;
        }
        else {
            count = Math.max(count, currentCount);
            currentCount = 0;
        }
        lastCharCode = charCode;
    }
    return Math.max(count, currentCount);
}
var PasswordQualityValidators = (function () {
    function PasswordQualityValidators() {
    }
    PasswordQualityValidators.minLength = function (_a) {
        var value = _a.value;
        return value.length < MIN_LENGTH ?
            createErrorResponse('Password must be at least 8 characters long.') : null;
    };
    PasswordQualityValidators.uniqueChars = function (_a) {
        var value = _a.value;
        return countUniqueChars(value) < MIN_UNIQUE_CHARS ?
            createErrorResponse('Password must contain at least 3 unique characters.') : null;
    };
    PasswordQualityValidators.sequentialChars = function (_a) {
        var value = _a.value;
        return countSequentialChars(value) > MAX_SEQUENTIAL_CHARS ?
            createErrorResponse('Password cannot contain more than 5 sequential characters.') : null;
    };
    PasswordQualityValidators.bannedPasswords = function (_a) {
        var value = _a.value;
        return BANNED_PASSWORDS.has(value.toLowerCase()) ?
            createErrorResponse('Password not allowed try another.') : null;
    };
    PasswordQualityValidators.all = [
        PasswordQualityValidators.minLength,
        PasswordQualityValidators.uniqueChars,
        PasswordQualityValidators.sequentialChars,
        PasswordQualityValidators.bannedPasswords,
    ];
    return PasswordQualityValidators;
}());
export { PasswordQualityValidators };
