import { PosDeviceService } from '../pos-devices/pos-device.service';
import { BatteryStatus } from '@ionic-native/battery-status';
var PosDeviceModule = (function () {
    function PosDeviceModule() {
    }
    PosDeviceModule.forRoot = function () {
        return {
            ngModule: PosDeviceModule,
            providers: [PosDeviceService, BatteryStatus],
        };
    };
    return PosDeviceModule;
}());
export { PosDeviceModule };
