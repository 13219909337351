import * as i0 from "./ui-flip-fab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ui-flip-fab.component";
var styles_UiFlipFabComponent = [i0.styles];
var RenderType_UiFlipFabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UiFlipFabComponent, data: {} });
export { RenderType_UiFlipFabComponent as RenderType_UiFlipFabComponent };
export function View_UiFlipFabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "flip-container"]], [[2, "flipped", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "flipper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "front"]], [[2, "shadow", null]], null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "back"]], [[2, "shadow", null]], null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(9, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "front"; var currVal_3 = _co.colorFrontClass; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.front; _ck(_v, 5, 0, currVal_4); var currVal_6 = "back"; var currVal_7 = _co.colorBackClass; _ck(_v, 7, 0, currVal_6, currVal_7); var currVal_8 = _co.back; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.flipped; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.shadowFront; _ck(_v, 2, 0, currVal_1); var currVal_5 = _co.shadowBack; _ck(_v, 6, 0, currVal_5); }); }
export function View_UiFlipFabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-flip-fab", [], null, null, null, View_UiFlipFabComponent_0, RenderType_UiFlipFabComponent)), i1.ɵdid(1, 49152, null, 0, i3.UiFlipFabComponent, [], null, null)], null, null); }
var UiFlipFabComponentNgFactory = i1.ɵccf("ui-flip-fab", i3.UiFlipFabComponent, View_UiFlipFabComponent_Host_0, { front: "front", back: "back", flipped: "flipped", colorFront: "colorFront", colorBack: "colorBack", shadowFront: "shadowFront", shadowBack: "shadowBack" }, { flippedChange: "flippedChange" }, []);
export { UiFlipFabComponentNgFactory as UiFlipFabComponentNgFactory };
