import { MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatButtonToggleModule, MatCheckboxModule, MatRadioModule, MatMenuModule, } from '@angular/material';
import { MatCurrencyInputModule } from '../../../shared/packages/mat-currency-input';
export var MATERIAL_IMPORTS = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    MatCurrencyInputModule,
];
var MerchantMenuOptionEditModule = (function () {
    function MerchantMenuOptionEditModule() {
    }
    return MerchantMenuOptionEditModule;
}());
export { MerchantMenuOptionEditModule };
