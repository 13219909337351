<div [hidden]="loading">
  <!-- USER NOT LOGGED IN (login / signup) -->
  <div *ngIf="loginStep === 'phone'">
    <h1 class="login-title">What's your number?</h1>
    <!-- NOT LOGGED IN - SIGN IN (phone) -->
    <form (submit)="onUserPhoneSubmit($event)">
      <mat-form-field class="full-width">
        <input matInput type="tel" name="phone" minlength="10" maxlength="10" pattern="[0-9]{10}"
          (input)="verifyNumberOnlyInput($event)" placeholder="Phone Number" [(ngModel)]="userPhoneNumber">
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="20px">
        <button mat-flat-button color="primary" fxFlex type="button" (click)="dismiss()">
          <mat-icon>close</mat-icon>
          Cancel
        </button>
        <button mat-flat-button color="primary" fxFlex type="submit" [disabled]="userPhoneNumber?.length !== 10">
          <mat-icon>sms</mat-icon>
          Verify
        </button>
      </div>
    </form>
  </div>

  <!-- NOT LOGGED IN - SMS VERIFICATION CODE -->
  <div *ngIf="loginStep === 'code'">
    <h1 class="login-title">Enter verification code</h1>
    <user-verification [phoneNumber]="userInfo?.phone" [code]="enteredCode" (done)="onEnteredCode($event)"
      (resendCode)="sendCode()" [tryCount]="verificationTryCount"></user-verification>
  </div>

  <!-- NOT LOGGED IN - phone/password login -->
  <div *ngIf="loginStep === 'password-login'">
    <h1 class="login-title">Please enter your password</h1>
    <form #phonePasswordLoginForm="ngForm" (submit)="onPhonePasswordLoginSubmit(phonePasswordLoginForm.value, $event)">
      <mat-form-field class="full-width">
        <input matInput type="tel" name="phone" minlength="10" maxlength="10" pattern="[0-9]{10}"
          (input)="verifyNumberOnlyInput($event)" placeholder="Phone Number" required [ngModel]="userInfo?.phone">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput type="password" name="password" placeholder="Password" required ngModel>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="20px">
        <button mat-flat-button color="primary" fxFlex type="button" (click)="dismiss()">
          <mat-icon>close</mat-icon>
          Cancel
        </button>
        <button mat-flat-button color="primary" fxFlex type="submit" [disabled]="phonePasswordLoginForm.invalid">
          <mat-icon>lock</mat-icon>
          Login
        </button>
      </div>

      <div fxLayoutAlign="center center">
        <a class="forgot-password-link" (click)="showForgotPassword()">Forgot Password?</a>
      </div>
    </form>
  </div>

  <!-- NOT LOGGED IN - forgot password -->
  <div *ngIf="loginStep === 'forgot-password'">
    <h1 class="login-title">Forgot password</h1>
    <form #passwordResetForm="ngForm" (submit)="onForgotPasswordSubmit(passwordResetForm.value, $event)">
      <mat-form-field class="full-width">
        <input matInput type="email" name="email" placeholder="Email" required ngModel>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="20px">
        <button mat-flat-button color="primary" fxFlex type="button" (click)="cancelResetPassword()">
          <mat-icon>close</mat-icon>
          Cancel
        </button>
        <button mat-flat-button color="primary" fxFlex type="submit" [disabled]="passwordResetForm.invalid">
          <mat-icon>lock</mat-icon>
          Reset Password
        </button>
      </div>
    </form>
  </div>

  <!-- USER LOGGED IN BUT NO NAME YET (prompt for name) -->
  <div *ngIf="loginStep === 'name'">
    <h1 class="login-title">What's your name?</h1>
    <form #updateUserNameForm="ngForm" (submit)="onUserNameSubmit(updateUserNameForm.value, $event)">
      <mat-form-field class="full-width">
        <input matInput type="text" name="firstName" placeholder="First Name" required ngModel>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput type="text" name="lastName" placeholder="Last Name" required ngModel>
      </mat-form-field>
      <div>
        <button mat-flat-button color="primary" fxFlex type="submit" [disabled]="updateUserNameForm.invalid">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </form>
  </div>

</div>

<!-- PROGRESS BAR -->
<div class="code-verification-progress" *ngIf="loading">
  <p>Processing...</p>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>