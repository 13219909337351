export interface RewardProgressData {
  rewardPointBalance: number;
  newRewardEarned: boolean;
}

// TODO - When we have more push contracts, put consumer and merchant in respective places
export interface OrderStatusData {
  orderId: string;
  chargeStatus: string;
  orderStatus: string;
  storeId: string;
  storeName: string;
  itemNames: string[];
  preparerName: string;
  rewardProgress?: RewardProgressData;
  statusReason?: string;
  scheduledPickup?: string;
  scheduleAlertAcknowledged?: boolean;
}

export enum PushMessageType {
  userOrderStatusUpdate = 'userOrderStatusUpdate',
}
