<div class="card-entry-wrap">
  <h3 class="card-entry-title">Card Information</h3>
  <div fxLayout="row" class="card-entry-row">
    <div fxFlex="65" class="card-entry-input" #paymentCardInputTarget></div>
    <div fxFlex="35" class="card-entry-input" #paymentExpInputTarget></div>
  </div>
  <div fxLayout="row" class="card-entry-row">
    <div fxFlex="65" class="card-entry-input" #paymentCvcInputTarget></div>
    <div fxFlex="35" class="card-entry-input" #paymentPostalInputTarget></div>
  </div>
  <div class="card-error-message">
    {{cardElements?.cardNumber?.error || cardElements?.cardExpiry?.error || cardElements?.cardCvc?.error || cardElements?.postalCode?.error}}
  </div>
</div>