var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { forwardRef } from '@angular/core';
import { NativeEnvironmentService } from '../native-environment/native-environment.service';
import { HTTP } from '@ionic-native/http';
import { HttpResponse, HttpHeaders, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
var NativeHttpInterceptorService = (function () {
    function NativeHttpInterceptorService(environmentService, http) {
        this.environmentService = environmentService;
        this.http = http;
        this.native = this.environmentService.isNative();
        if (this.native) {
            this.http.setDataSerializer('json');
        }
    }
    NativeHttpInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        if (!this.native) {
            return next.handle(req);
        }
        var httpPromise;
        var requestBody = __assign({}, req.body);
        switch (req.method) {
            case 'POST':
                httpPromise = this.environmentService.ready().then(function () {
                    return _this.http.post(_this.appendParamsToUrl(req.url, _this.extractParams(req)), requestBody, _this.extractHeaders(req));
                });
                break;
            case 'PUT':
                httpPromise = this.environmentService.ready().then(function () {
                    return _this.http.put(_this.appendParamsToUrl(req.url, _this.extractParams(req)), requestBody, _this.extractHeaders(req));
                });
                break;
            case 'PATCH':
                httpPromise = this.environmentService.ready().then(function () {
                    return _this.http.patch(_this.appendParamsToUrl(req.url, _this.extractParams(req)), requestBody, _this.extractHeaders(req));
                });
                break;
            case 'DELETE':
                httpPromise = this.environmentService.ready().then(function () {
                    return _this.http.delete(req.url, _this.extractParams(req), _this.extractHeaders(req));
                });
                break;
            case 'GET':
                httpPromise = this.environmentService.ready().then(function () {
                    return _this.http.get(req.url, _this.extractParams(req), _this.extractHeaders(req));
                });
                break;
            default:
                return next.handle(req);
        }
        return new Observable(function (observer) {
            httpPromise
                .then(function (response) {
                var body = {};
                try {
                    body = JSON.parse(response.data);
                }
                catch (e) {
                    body = response.data;
                }
                observer.next(new HttpResponse({
                    body: body,
                    headers: new HttpHeaders(response.headers),
                    status: response.status,
                    url: response.url,
                }));
                observer.complete();
            })
                .catch(function (errorResponse) {
                var statusText;
                try {
                    var parsedError = JSON.parse(errorResponse.error);
                    statusText = parsedError && parsedError.message || errorResponse.error;
                }
                catch (e) {
                    statusText = errorResponse.error;
                }
                observer.error(new HttpErrorResponse({
                    headers: new HttpHeaders(errorResponse.headers),
                    status: errorResponse.status,
                    statusText: statusText,
                    url: errorResponse.url,
                    error: {
                        message: statusText || status || 'Unknown http error',
                    },
                }));
            });
        });
    };
    NativeHttpInterceptorService.prototype.extractHeaders = function (req) {
        var out = {};
        var keys = req.headers.keys();
        keys.forEach(function (key) {
            out[key] = req.headers.get(key);
        });
        return out;
    };
    NativeHttpInterceptorService.prototype.extractParams = function (req) {
        var out = {};
        if (req && req.params) {
            var keys = req.params.keys();
            keys.forEach(function (key) {
                out[key] = req.params.get(key);
            });
        }
        return out;
    };
    NativeHttpInterceptorService.prototype.appendParamsToUrl = function (url, params) {
        if (!params) {
            return url;
        }
        var paramKeys = Object.keys(params);
        if (!paramKeys || paramKeys.length < 1) {
            return url;
        }
        return url + '?' + paramKeys.map(function (key) { return key + '=' + encodeURIComponent(params[key]); }).join('&');
    };
    return NativeHttpInterceptorService;
}());
export { NativeHttpInterceptorService };
export var NATIVE_HTTP_INTERCEPTOR_PROVIDER = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(function () { return NativeHttpInterceptorService; }),
    multi: true,
};
