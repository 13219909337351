import { ModuleWithProviders } from '@angular/core';
import { ApiConfiguration } from './api-configuration';
var ApiModule = (function () {
    function ApiModule() {
    }
    ApiModule.forRoot = function (customParams) {
        return {
            ngModule: ApiModule,
            providers: [
                {
                    provide: ApiConfiguration,
                    useValue: { rootUrl: customParams.rootUrl }
                }
            ]
        };
    };
    return ApiModule;
}());
export { ApiModule };
