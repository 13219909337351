import { Component, OnInit, Input } from '@angular/core';

export interface LoadingOptions {
  type: string;
}

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit {

  @Input()
  small = true;

  loadingProgress: number;

  constructor() { }

  public ngOnInit(): void {
  }

  setLoadingProgress(progress: number): void {
    this.loadingProgress = progress;
  }

}
