import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService, StoreWithDetailsDto, StoreSearchResponseDto, CompanyLandingDataDto, StoreMinimalDto } from 'src/gen/joeServerCore';
import { take } from 'rxjs/operators';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';

export type GeoCoords = [number, number];

export type StoresGetNearbyParams = ApiService.StoresGetNearbyParams;

export interface StoreSearchFilterItem {
  displayName: string;
  selected: boolean;
}

export interface StoreSearchFilters {
  [key: string]: StoreSearchFilterItem;
}

export interface StoreSearchParams {
  address: string;
  storeName: string;
  filters: StoreSearchFilters;
}

@Injectable()
export class StoreService {
  lastStoreSearchOptions: StoresGetNearbyParams;

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
  ) { }

  findStores(options: StoresGetNearbyParams): Observable<StoreSearchResponseDto> {
    this.lastStoreSearchOptions = options;
    return this.apiService.storesGetNearby({
      resultOffset: options.resultOffset,
      maxResults: options.maxResults,
      maxDistance: options.maxDistance,
      lon: options.lon,
      lat: options.lat,
      postalCode: options.postalCode,
      storeName: options.storeName,
    }).pipe(take(1));
  }

  getCompanyLanding(companySlug: string, lat: string, lon: string): Observable<CompanyLandingDataDto> {
    return this.apiService.companyGetLandingPageFromSlug({ companySlug, lat, lon });
  }

  getStoreById(storeId: string): Observable<StoreWithDetailsDto> {
    return this.apiService.storeGetDetails(storeId).pipe(take(1));
  }

  getLastStoreSearchOptions(): StoresGetNearbyParams {
    return this.lastStoreSearchOptions;
  }

  saveStoreSearchParamsCache(storeSearchParams: StoreSearchParams): Promise<void> {
    return this.storageService.set<StoreSearchParams>(StorageKeys.STORE_SEARCH_PARAMS_CACHE, storeSearchParams);
  }

  getUserStores(): Observable<StoreMinimalDto[]> {
    return this.apiService.storeList();
  }

  async loadStoreSearchParamsCache(): Promise<StoreSearchParams> {
    const data = await this.storageService.get<StoreSearchParams>(StorageKeys.STORE_SEARCH_PARAMS_CACHE);
    if (data && Object.keys(data).length > 0) {
      return data;
    } else {
      return;
    }
  }
}
