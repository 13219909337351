import { EnvironmentConfig } from './interfaces/environment-config.interface';
import { buildData } from './build-data/build-data';
import { FEATURES_PRODUCTION } from './environment-features.production';

export const packageJson = require('../../package.json');

export const environment: EnvironmentConfig = {
  name: 'Production',
  production: true,
  apiUrls: {
    joeServerCore: 'https://prod-core-api-merchant.joe.coffee/api/v1',
    joeApi: 'https://shop-api.joe.coffee',
    joeMerchantService: 'https://prod-merchant-api.joe.coffee',
  },
  hotUpdateUrl: 'https://prod-hot-update.joe.coffee',
  traceRoutes: false,
  packageVersion: packageJson.version,
  build: buildData,
  payment: {
    iosMerchantId: 'merchant.consumer.coffee.joe',
    stripePublishableTest: 'pk_test_pVhmxVfpg2WXDdXjkLZtpXsc',
    // LIVE KEY!
    stripePublishable: 'pk_live_vYh0wop7SJ49LoeRvkSOPZUL',
  },
  pollingPosConfig: {
    checkinPollInterval: 30_000,
    orderPollInterval: 15_000,
  },
  socketPosConfig: {
    checkinPollInterval: 30_000,
    orderPollInterval: 60_000,
  },
  consumerOrderStatusPollInterval: 20000,
  featureSwitches: FEATURES_PRODUCTION,
  baseWebUrl: {
    consumer: 'https://order.joe.coffee',
    merchant: 'https://merchant.joe.coffee',
  },
  // disabled. If we re add this remember to re-add the script tag to index.html
  // telemetry: {
  //   localyticsKeys: {
  //     consumer: '4852bc05054d739bafe4294-2b1c13c8-b954-11e9-3280-007c928ca240',
  //     merchant: '9476303913bbc5179fde015-9283e480-b953-11e9-4ed2-007c928ca240',
  //     pos: 'f1f81b30267421813c45e49-9fc61f24-b953-11e9-5e0d-007c928ca240',
  //   },
  // },
  clover: {
    baseUrl: 'https://clover.com',
    clientId: '2BRVJK9WPSX52',
  },
  square: {
    baseUrl: 'https://connect.squareup.com',
    clientId: 'sq0idp-7s3TgCa5ahfa8CxCZBvjTw',
  },
  telemetry: {
    logDNAKeys: {
      merchant: '70ce308085f7e74aebc1574e9ea47ec9',
    },
  },
  joeApi: {
    key: '115e782e-7936-4684-804d-b25ef565fccc1645560367065',
  },
};
