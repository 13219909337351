import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaDirective } from './directives/dragula/dragula.directive';
import { DragulaService } from './services/dragula/dragula.service';

const EXPORTED_DECLARATIONS = [
  DragulaDirective,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ...EXPORTED_DECLARATIONS,
  ],
  providers: [
    DragulaService,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,
  ],
  entryComponents: [],

})
export class Ng2DragulaRebornModule { }
