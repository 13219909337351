import { Component, OnInit, Input } from '@angular/core';
import { MerchantTopbarService } from '../../services/merchant-topbar/merchant-topbar.service';

@Component({
  selector: 'topbar-options',
  template: '',
})
export class MerchantTopbarOptionsComponent implements OnInit {

  @Input()
  set title(title: string) {
    this.topbarService.setTitle(title);
  }

  constructor(
    private topbarService: MerchantTopbarService,
  ) { }

  public ngOnInit(): void {
  }

}
