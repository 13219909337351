import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./pos/packages/ui-pos-chrome/components/ui-pos-chrome/ui-pos-chrome.component.ngfactory";
import * as i4 from "./pos/packages/ui-pos-chrome/components/ui-pos-chrome/ui-pos-chrome.component";
import * as i5 from "./pos/services/pos-devices/pos-device.service";
import * as i6 from "./pos/packages/ui-pos-chrome/services/audio-alert/audio-alert.service";
import * as i7 from "./merchant/packages/ui-merchant-chrome/components/ui-merchant-chrome/ui-merchant-chrome.component.ngfactory";
import * as i8 from "./merchant/packages/ui-merchant-chrome/components/ui-merchant-chrome/ui-merchant-chrome.component";
import * as i9 from "@angular/router";
import * as i10 from "./shared/services/identity/identity.service";
import * as i11 from "./shared/services/environment/environment.service";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "./consumer/packages/ui-consumer-chrome/components/ui-consumer-chrome/ui-consumer-chrome.component.ngfactory";
import * as i14 from "./consumer/packages/ui-consumer-chrome/components/ui-consumer-chrome/ui-consumer-chrome.component";
import * as i15 from "./shared/packages/storage/storage/storage.service";
import * as i16 from "./shared/services/user/user.service";
import * as i17 from "@angular/material/snack-bar";
import * as i18 from "@angular/material/bottom-sheet";
import * as i19 from "./app.component";
import * as i20 from "./shared/services/app-initialization/app-initialization.service";
import * as i21 from "./shared/services/update/update.service";
import * as i22 from "./shared/packages/ui-loading-indicator/services/loading-indicator/loading-indicator.service";
import * as i23 from "./merchant/packages/ui-merchant-chrome/services/merchant-topbar/merchant-topbar.service";
import * as i24 from "./shared/packages/ui-bottom-bar/services/bottombar/bottombar.service";
import * as i25 from "./merchant/packages/ui-merchant-chrome/services/merchant-bottombar/merchant-bottombar.service";
import * as i26 from "../gen/joeServerCore/lib/api-configuration";
import * as i27 from "./shared/packages/native-support/services/native-back-button/native-back-button.service";
import * as i28 from "./shared/packages/telemetry/services/telemetry/telemetry.service";
import * as i29 from "./shared/services/feature-switch/feature-switch.service";
import * as i30 from "@angular/common/http";
import * as i31 from "@angular/material/dialog";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "https://get.joe.coffee"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update Now!"]))], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Having trouble? Give us a call: 1-206-531-3212"]))], null, null); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "update-required-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update required"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The app needs to be updated to proceed."])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please update from the app store directly."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.merchantMode; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.merchantMode; _ck(_v, 12, 0, currVal_1); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Checking for app updates..."]))], null, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "updating-message"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["version ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkingForUpdate; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.versionShortCode; _ck(_v, 4, 0, currVal_1); }); }
function View_AppComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-pos-chrome", [], null, null, null, i3.View_UiPosChromeComponent_0, i3.RenderType_UiPosChromeComponent)), i1.ɵdid(1, 245760, null, 0, i4.UiPosChromeComponent, [i5.PosDeviceService, i6.AudioAlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-merchant-chrome", [], null, null, null, i7.View_UiMerchantChromeComponent_0, i7.RenderType_UiMerchantChromeComponent)), i1.ɵdid(1, 245760, null, 0, i8.UiMerchantChromeComponent, [i9.Router, i10.IdentityService, i11.EnvironmentService, i12.MediaObserver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-consumer-chrome", [], null, null, null, i13.View_UiConsumerChromeComponent_0, i13.RenderType_UiConsumerChromeComponent)), i1.ɵdid(1, 245760, null, 0, i14.UiConsumerChromeComponent, [i9.Router, i10.IdentityService, i15.StorageService, i16.UserService, i17.MatSnackBar, i18.MatBottomSheet], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_9)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchantMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.merchantMode; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.merchantMode; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.criticalUpdateNeeded; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.loading && !_co.criticalUpdateNeeded); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.loading && !_co.criticalUpdateNeeded); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i19.AppComponent, [i9.Router, i11.EnvironmentService, i1.ChangeDetectorRef, i20.AppInitializationService, i21.UpdateService, i22.LoadingIndicatorService, i23.MerchantTopbarService, i24.BottombarService, i25.MerchantBottombarService, i26.ApiConfiguration, i27.NativeBackButtonService, i28.TelemetryService, i29.FeatureSwitchService, i30.HttpClient, i31.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i19.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
