import * as i0 from "./consumer-topbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../../../shared/packages/ui-flip-fab/components/ui-flip-fab/ui-flip-fab.component.ngfactory";
import * as i6 from "../../../../../shared/packages/ui-flip-fab/components/ui-flip-fab/ui-flip-fab.component";
import * as i7 from "./consumer-topbar.component";
import * as i8 from "../../services/topbar/topbar.service";
import * as i9 from "../../../../../shared/services/environment/environment.service";
var styles_ConsumerTopbarComponent = [i0.styles];
var RenderType_ConsumerTopbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsumerTopbarComponent, data: {} });
export { RenderType_ConsumerTopbarComponent as RenderType_ConsumerTopbarComponent };
function View_ConsumerTopbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "app-store-download-badge-img-android"], ["src", "public-web-assets/images/3rd-party/google-play-download-app.png"]], null, null, null, null, null))], null, null); }
function View_ConsumerTopbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "app-store-download-badge-img-apple"], ["src", "public-web-assets/images/3rd-party/apple-app-store-download.svg"]], null, null, null, null, null))], null, null); }
function View_ConsumerTopbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "app-download-badge"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["href", "https://get.joe.coffee"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumerTopbarComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumerTopbarComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mobileWebType === "android"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.mobileWebType === "iphone"); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ConsumerTopbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "topbar-logo"], ["src", "public-web-assets/images/logo-inverted.png"]], null, null, null, null, null))], null, null); }
function View_ConsumerTopbarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "topbar-user-icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ConsumerTopbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumerTopbarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "consumer-topbar"]], [[2, "drawer-open", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "joe-fab"]], [[2, "hidden", null], [2, "bounce", null], [2, "bounce-prepare", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ui-flip-fab", [["colorBack", "light"]], null, [[null, "flippedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("flippedChange" === en)) {
        var pd_0 = (_co.menuClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_UiFlipFabComponent_0, i5.RenderType_UiFlipFabComponent)), i1.ɵdid(5, 49152, null, 0, i6.UiFlipFabComponent, [], { front: [0, "front"], back: [1, "back"], flipped: [2, "flipped"], colorBack: [3, "colorBack"], shadowBack: [4, "shadowBack"] }, { flippedChange: "flippedChange" }), (_l()(), i1.ɵand(0, [["joeFabFront", 2]], null, 0, null, View_ConsumerTopbarComponent_4)), (_l()(), i1.ɵand(0, [["joeFabBack", 2]], null, 0, null, View_ConsumerTopbarComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mobileWebType; _ck(_v, 1, 0, currVal_0); var currVal_5 = i1.ɵnov(_v, 6); var currVal_6 = i1.ɵnov(_v, 7); var currVal_7 = _co.drawerOpen; var currVal_8 = "light"; var currVal_9 = false; _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.drawerOpen; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.topbarHidden; var currVal_3 = _co.topbarBounce; var currVal_4 = _co.topbarBouncePrepare; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_ConsumerTopbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "consumer-topbar", [], null, null, null, View_ConsumerTopbarComponent_0, RenderType_ConsumerTopbarComponent)), i1.ɵdid(1, 245760, null, 0, i7.ConsumerTopbarComponent, [i8.TopbarService, i9.EnvironmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsumerTopbarComponentNgFactory = i1.ɵccf("consumer-topbar", i7.ConsumerTopbarComponent, View_ConsumerTopbarComponent_Host_0, { show: "show", drawerOpen: "drawerOpen" }, { onToggleMenu: "onToggleMenu", onLogout: "onLogout" }, []);
export { ConsumerTopbarComponentNgFactory as ConsumerTopbarComponentNgFactory };
