var Timer = (function () {
    function Timer(fn, interval, runOnStart) {
        if (runOnStart === void 0) { runOnStart = true; }
        this.interval = interval;
        this.fn = fn;
        this.runOnStart = runOnStart;
    }
    Timer.prototype.start = function () {
        if (!this.fn || !this.interval) {
            throw new Error('Timer needs a function and an interval in order to start');
        }
        if (!this.timerObj) {
            this.stop();
            if (this.runOnStart) {
                this.fn();
            }
            this.timerObj = setInterval(this.fn, this.interval);
        }
        return this;
    };
    Timer.prototype.stop = function () {
        if (this.timerObj) {
            clearInterval(this.timerObj);
            this.timerObj = undefined;
        }
        return this;
    };
    Timer.prototype.reset = function (newInterval) {
        if (newInterval === void 0) { newInterval = this.interval; }
        this.interval = newInterval;
        return this.stop().start();
    };
    Timer.prototype.isRunning = function () {
        return Boolean(this.timerObj);
    };
    return Timer;
}());
export { Timer };
