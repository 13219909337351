import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { MerchantBottombarService } from '../../../services/merchant-bottombar/merchant-bottombar.service';

@Component({
  selector: 'merchant-bottombar-template',
  template: '',
})
export class MerchantBottombarTemplateComponent implements OnInit {

  @Input()
  set template(template: TemplateRef<any>) {
    this.bottombarService.setTemplate(template);
  }

  constructor(
    private bottombarService: MerchantBottombarService,
  ) { }

  public ngOnInit(): void {
  }
}
