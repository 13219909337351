import { OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { BottombarService } from '../../services/bottombar/bottombar.service';
var BottombarComponent = (function () {
    function BottombarComponent(bottombarService) {
        this.bottombarService = bottombarService;
    }
    BottombarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.bottombarService.watchTemplate().pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (templateRef) {
            _this.bottombar = templateRef;
            _this.show = _this.bottombar === undefined ? false : true;
        });
    };
    BottombarComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    return BottombarComponent;
}());
export { BottombarComponent };
