import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, MenuOptionCreateDto, MenuOptionWithChoicesDto } from 'src/gen/joeServerCore';
import { map } from 'rxjs/operators';

export interface MenuItemOptionChoice {
  name: string;
  price: string;
}

export interface MenuItemOption {
  id?: string;
  name: string;
  finiteQuanity: boolean;
  minimumQuantity: number;
  maximumQuantity: number;
  quantityIncrement: number;
  quantityUnit: string;
  quantityNames: string[];
  defaultQuantity: number;
  choices: MenuItemOptionChoice[];
}

@Injectable()
export class MenuOptionService {

  constructor(
    private apiService: ApiService,
  ) { }

  getCompanyMenuOptions(companyId: string, menuId: string): Observable<MenuOptionWithChoicesDto[]> {
    return this.apiService.menuOptionList({ companyId, menuId });
  }

  get(companyId: string, menuId: string, menuOptionId: string): Observable<MenuOptionWithChoicesDto> {
    return this.apiService.menuOptionGet({ companyId, menuId, menuOptionId });
  }

  update(companyId: string, menuId: string, menuOptionId: string, menuOption: MenuOptionCreateDto) {
    return this.apiService.menuOptionUpdate({ companyId, menuId, menuOptionId, MenuOptionCreateDto: menuOption });
  }

  create(companyId: string, menuId: string, menuOption: MenuOptionCreateDto): Observable<MenuOptionWithChoicesDto> {
    return this.apiService.menuOptionCreate({ companyId, menuId, MenuOptionCreateDto: menuOption });
  }

  delete(companyId: string, menuId: string, menuOptionId: string): Observable<boolean> {
    return this.apiService.menuOptionDelete({ companyId, menuId, menuOptionId }).pipe(map(({ result }) => result));
  }

  new(): MenuOptionWithChoicesDto {
    return {
      id: undefined,
      name: '',
      finiteQuantity: true,
      minimumQuantity: 1,
      maximumQuantity: 1,
      quantityUnit: '',
      quantityIncrement: 1,
      quantityNames: [],
      choices: [],
      defaultQuantity: 1,
    };
  }
}
