import { Injectable, forwardRef } from '@angular/core';
import { NativeEnvironmentService } from '../native-environment/native-environment.service';
import { HTTP, HTTPResponse } from '@ionic-native/http';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpResponse, HttpHeaders, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Provider } from '@angular/compiler/src/core';

@Injectable()
export class NativeHttpInterceptorService implements HttpInterceptor {

  private native: boolean;

  constructor(
    private environmentService: NativeEnvironmentService,
    private http: HTTP,
  ) {
    this.native = this.environmentService.isNative();
    if (this.native) {
      this.http.setDataSerializer('json');
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // pass through
    if (!this.native) {
      return next.handle(req);
    }

    let httpPromise: Promise<HTTPResponse>;
    const requestBody = { ...req.body };

    switch (req.method) {
      case 'POST':
        httpPromise = this.environmentService.ready().then(() =>
          this.http.post(this.appendParamsToUrl(req.url, this.extractParams(req)), requestBody, this.extractHeaders(req)),
        );
        break;
      case 'PUT':
        httpPromise = this.environmentService.ready().then(() =>
          this.http.put(this.appendParamsToUrl(req.url, this.extractParams(req)), requestBody, this.extractHeaders(req)),
        );
        break;
      case 'PATCH':
        httpPromise = this.environmentService.ready().then(() =>
          this.http.patch(this.appendParamsToUrl(req.url, this.extractParams(req)), requestBody, this.extractHeaders(req)),
        );
        break;
      case 'DELETE':
        httpPromise = this.environmentService.ready().then(() =>
          this.http.delete(req.url, this.extractParams(req), this.extractHeaders(req)),
        );
        break;
      case 'GET':
        httpPromise = this.environmentService.ready().then(() =>
          this.http.get(req.url, this.extractParams(req), this.extractHeaders(req)),
        );
        break;
      default:
        // ¯\_(ツ)_/¯ ... (fallback to browser http)
        return next.handle(req);
    }

    return new Observable(observer => {
      httpPromise
        .then(response => {
          let body: any = {};
          try {
            body = JSON.parse(response.data);
          } catch (e) {
            body = response.data;
          }

          observer.next(new HttpResponse({
            body,
            headers: new HttpHeaders(response.headers),
            status: response.status,
            url: response.url,
          }));
          observer.complete();
        })
        .catch((errorResponse: HTTPResponse) => {
          let statusText: any;
          try {
            const parsedError = JSON.parse(errorResponse.error);
            statusText = parsedError && parsedError.message || errorResponse.error;
          } catch (e) {
            // not json
            statusText = errorResponse.error;
          }
          observer.error(new HttpErrorResponse({
            headers: new HttpHeaders(errorResponse.headers),
            status: errorResponse.status,
            statusText,
            url: errorResponse.url,
            error: {
              message: statusText || status || 'Unknown http error',
            },
          }));
        });
    });
  }

  private extractHeaders(req: HttpRequest<any>): { [key: string]: string } {
    const out: { [key: string]: string } = {};
    const keys = req.headers.keys();
    keys.forEach(key => {
      out[key] = req.headers.get(key);
    });
    return out;
  }

  private extractParams(req: HttpRequest<any>): { [key: string]: string } {
    const out: { [key: string]: string } = {};

    if (req && req.params) {
      const keys = req.params.keys();
      keys.forEach(key => {
        out[key] = req.params.get(key);
      });
    }
    return out;
  }

  private appendParamsToUrl(url: string, params: { [key: string]: string }): string {
    if (!params) {
      return url;
    }

    const paramKeys = Object.keys(params);

    if (!paramKeys || paramKeys.length < 1) {
      return url;
    }

    return url + '?' + paramKeys.map(key => key + '=' + encodeURIComponent(params[key])).join('&');
  }
}

export const NATIVE_HTTP_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => NativeHttpInterceptorService),
  multi: true,
};
