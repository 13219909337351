import * as i0 from "./bottombar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./bottombar.component";
import * as i4 from "../../services/bottombar/bottombar.service";
var styles_BottombarComponent = [i0.styles];
var RenderType_BottombarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottombarComponent, data: {} });
export { RenderType_BottombarComponent as RenderType_BottombarComponent };
function View_BottombarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "bottom-bar-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bottombar; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BottombarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottombarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BottombarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bottombar", [], null, null, null, View_BottombarComponent_0, RenderType_BottombarComponent)), i1.ɵdid(1, 245760, null, 0, i3.BottombarComponent, [i4.BottombarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottombarComponentNgFactory = i1.ɵccf("bottombar", i3.BottombarComponent, View_BottombarComponent_Host_0, {}, {}, []);
export { BottombarComponentNgFactory as BottombarComponentNgFactory };
