import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoadingIndicatorService } from './services/loading-indicator/loading-indicator.service';
import { MatDialogModule } from '@angular/material';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    LoadingIndicatorComponent,
  ],
  providers: [],
  exports: [
    LoadingIndicatorComponent,
    ...MATERIAL_IMPORTS,
  ],
  entryComponents: [
    LoadingIndicatorComponent,
  ],

})
export class UiLoadingIndicatorModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UiLoadingIndicatorModule,
      providers: [LoadingIndicatorService],
    };
  }
}
