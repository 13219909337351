import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { confetti } from 'dom-confetti';

export interface NewUserDialogData {
  firstName: string;
}

@Component({
  selector: 'new-user-welcome-dialog',
  templateUrl: './new-user-welcome-dialog.component.html',
  styleUrls: ['./new-user-welcome-dialog.component.scss'],
})
export class NewUserWelcomeDialogComponent implements OnInit {

  @ViewChild('success')
  successTarget: ElementRef;

  customerName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: NewUserDialogData,
  ) {
    this.customerName = data.firstName;
  }

  public ngOnInit(): void {
    this.runAnimation();
  }

  private runAnimation(): void {
    // hold up.... wait a minute
    setTimeout(() => {
      // fire the confetti
      confetti(this.successTarget.nativeElement, { angle: 70, elementCount: 100 });
      confetti(this.successTarget.nativeElement, { angle: 90, elementCount: 200 });
      confetti(this.successTarget.nativeElement, { angle: 110, elementCount: 100 });

    }, 200);
  }

}
