import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { IdentityService } from '../../services/identity/identity.service';
var AuthGuardService = (function () {
    function AuthGuardService(identityService, router) {
        this.identityService = identityService;
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        return new Observable(function (resolver) {
            _this.identityService.isLoaded().then(function () {
                var isAuthenticated = !!_this.identityService.getActiveUser();
                if (!isAuthenticated) {
                    var returnUrl = state.url && state.url.length > 3 ? state.url : undefined;
                    _this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
                }
                resolver.next(isAuthenticated);
                resolver.complete();
            });
        });
    };
    return AuthGuardService;
}());
export { AuthGuardService };
