import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatProgressSpinnerModule, MatTabsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDialogModule } from '@angular/material';

import { LoginComponent } from './login.component';
import { FlexLayoutModule } from '@angular/flex-layout';

export const MATERIAL_IMPORTS = [
  MatProgressSpinnerModule,
  MatTabsModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatDialogModule,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [LoginComponent],
})
export class LoginModule { }
