import { merge, cloneDeep } from 'lodash';
var MINIMUM_ITEM_PRICE = 15;
var MenuSerializerService = (function () {
    function MenuSerializerService() {
    }
    MenuSerializerService.prototype.filterMenuSection = function (section, options) {
        var mergeSection = Object.assign({}, section);
        mergeSection.items = (mergeSection.items || []).filter(function (item) {
            var outOfStockCounter = 0;
            item.sizes = item.sizes.filter(function (size) {
                if (size.inventory && size.inventory.length > 0 && size.inventory[0].quantity === 0) {
                    outOfStockCounter++;
                }
                return parseInt(size.price, 10) >= MINIMUM_ITEM_PRICE;
            });
            var isValidItem = !!item.sizes && (item.sizes.length > 0) && item.enabled;
            if (outOfStockCounter === item.sizes.length) {
                item.outOfStock = true;
            }
            if (isValidItem) {
                item.options = item.options.map(function (option) {
                    var optionIndex = options.findIndex(function (opt) { return opt.id === option.id; });
                    if (optionIndex === -1) {
                        return;
                    }
                    var mergedChoices = options[optionIndex].choices.map(function (choice) {
                        var choiceIndex = option.choices.findIndex(function (chc) { return chc.id === choice.id; });
                        if (choiceIndex !== -1) {
                            return {
                                id: choice.id,
                                flags: option.choices[choiceIndex].flags,
                                name: choice.name,
                                price: choice.price,
                            };
                        }
                        else {
                            return {
                                id: choice.id,
                                flags: [],
                                name: choice.name,
                                price: choice.price,
                            };
                        }
                    }).filter(function (choice) { return !!choice; });
                    var mergedOptions = merge(option, options[optionIndex]);
                    mergedOptions.choices = mergedChoices;
                    return mergedOptions;
                });
            }
            return isValidItem;
        });
        return mergeSection;
    };
    MenuSerializerService.prototype.filterMenu = function (menuExport) {
        var _this = this;
        var menu = menuExport.menu;
        var options = menuExport.options;
        var filteredMenu = Object.assign({}, menu);
        filteredMenu.options = menuExport.options;
        filteredMenu.categories = (menu.categories || []).map(function (category) {
            category.sections = (category.sections || []).map(function (section) { return _this.filterMenuSection(section, options); });
            return category;
        });
        return filteredMenu;
    };
    MenuSerializerService.prototype.createMenuDtoFromMenuModel = function (menuData) {
        var choiceFlagNames = new Set(['free', 'default', 'hidden', 'required']);
        return {
            id: menuData.id || '',
            name: menuData.name,
            metaData: { itemTagLimits: menuData.itemTagLimits || [] },
            parentMenuId: menuData.parentMenuId || undefined,
            categories: menuData.categories.map(function (category) { return ({
                id: category.id || '',
                name: category.name,
                sections: category.sections.map(function (section) { return ({
                    id: section.id || '',
                    name: section.name,
                    items: section.items.map(function (item) { return ({
                        id: item.id || '',
                        name: item.name,
                        description: item.description || '',
                        enabled: !!item.enabled,
                        taxExempt: item.taxExempt,
                        accountingId: item.accountingId,
                        metaData: { tags: item.tags || [] },
                        image: item.image,
                        sizes: item.sizes.map(function (size) { return (cloneDeep(size)); }),
                        options: item.options.map(function (option) {
                            var mappedChoices = [];
                            var sizePrices = [];
                            var sizePriceMap = {};
                            option.choices.forEach(function (choice) {
                                if (choice.sizePrices && choice.sizePrices.length > 0) {
                                    choice.sizePrices.forEach(function (sizePrice) {
                                        var uniqueKey = sizePrice.menuSizeId + '--' + sizePrice.price;
                                        if (sizePriceMap.hasOwnProperty(uniqueKey)) {
                                            sizePriceMap[uniqueKey].menuChoiceId.push(sizePrice.menuChoiceId);
                                        }
                                        else {
                                            sizePriceMap[uniqueKey] = {
                                                menuChoiceId: [sizePrice.menuChoiceId],
                                                menuSizeId: sizePrice.menuSizeId,
                                                menuOptionId: option.id,
                                                price: sizePrice.price,
                                            };
                                        }
                                    });
                                    sizePrices = Object.keys(sizePriceMap).map(function (sizePriceKey) { return sizePriceMap[sizePriceKey]; });
                                }
                                var flags = Object.keys(choice).filter(function (flag) { return choiceFlagNames.has(flag) && choice[flag] === true; });
                                if (flags.length > 0) {
                                    mappedChoices.push({
                                        id: choice.id,
                                        flags: flags,
                                    });
                                }
                            });
                            return {
                                id: option.id,
                                minimumChoices: option.minChoices,
                                maximumChoices: option.maxChoices,
                                choices: mappedChoices,
                                sizePrices: sizePrices,
                            };
                        }),
                    }); }),
                }); }),
            }); }),
        };
    };
    return MenuSerializerService;
}());
export { MenuSerializerService };
