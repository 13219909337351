import { Injectable } from '@angular/core';
import { FeatureSwitch, EnvironmentFeatureSwitches } from 'src/environments/interfaces/environment-features.interface';
import { EnvironmentService } from '../environment/environment.service';

export { FeatureSwitch };

interface QueryParamMap {
  [key: string]: string;
}

@Injectable()
export class FeatureSwitchService {

  private static queryParamCache: QueryParamMap = {};

  private baseFeatureSwitches: EnvironmentFeatureSwitches;

  constructor(
    environmentService: EnvironmentService,
  ) {
    this.baseFeatureSwitches = environmentService.getConfig().featureSwitches;
  }

  initQueryParams() {
    FeatureSwitchService.queryParamCache = this.getQueryParamMap();
  }

  active(name: FeatureSwitch): boolean {
    const queryParams = FeatureSwitchService.queryParamCache;
    return this.baseFeatureSwitches[name] === true || !!(queryParams && queryParams[name] === 'true');
  }

  getValue(name: FeatureSwitch): string {
    const queryParams = FeatureSwitchService.queryParamCache;
    return this.baseFeatureSwitches[name] || queryParams ? queryParams[name] : undefined;
  }

  private getQueryParamMap(): QueryParamMap {
    const result: QueryParamMap = {};
    const queryString = location.search;
    if (queryString) {
      const params = queryString.slice(1).split('&');
      params.forEach(param => {
        const [key, value] = param.split('=');
        result[key] = decodeURIComponent(value);
      });
    }
    return result;
  }

}
