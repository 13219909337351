import { CompanyDto, CompanyService } from 'src/app/merchant/services/company/company.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ActiveCompanies {
  active: CompanyDto;
  companies: CompanyDto[];
}

@Injectable()
export class ActiveCompanyService implements OnDestroy {

  private static activeCompanySubject: BehaviorSubject<ActiveCompanies> = new BehaviorSubject({ active: null, companies: [] });
  private static activeCompany: CompanyDto;
  private static companies: CompanyDto[];

  constructor(
    private companyService: CompanyService,
  ) {
  }

  ngOnDestroy(): void {
    ActiveCompanyService.activeCompanySubject.complete();
  }

  /**
   * Updates companies list from server. If a company is provided it is replaced into the list (instead of an http call)
   */
  async updateCompanies(company?: CompanyDto, activateCompanyId?: string): Promise<void> {
    if (company && ActiveCompanyService.companies) {
      const updateCompany = ActiveCompanyService.companies.find(c => c.id === company.id);
      if (updateCompany) {
        Object.assign(updateCompany, company);
      } else {
        ActiveCompanyService.companies.push(company);
      }
    } else {
      ActiveCompanyService.companies = await this.companyService.getUserCompanies().toPromise();
    }

    if (ActiveCompanyService.companies && ActiveCompanyService.companies.length > 0) {
      if (activateCompanyId) {
        this.setActive(activateCompanyId);
        return;
      }

      if (ActiveCompanyService.activeCompany) {
        const hasActive = ActiveCompanyService.companies.find(c => ActiveCompanyService.activeCompany.id === c.id);
        // active company no longer in list
        if (!hasActive) {
          this.clearActive();
          // to avoid multiple emits
          return;
        }
      }
    } else {
      // no companies no active
      this.clearActive();
      // to avoid multiple emits1
      return;
    }

    this.emitChanges();
  }

  setActive(companyId: string): void {
    if (!ActiveCompanyService.companies || (ActiveCompanyService.activeCompany && ActiveCompanyService.activeCompany.id === companyId)) {
      return;
    }
    ActiveCompanyService.activeCompany = ActiveCompanyService.companies.find(c => c.id === companyId);
    this.emitChanges();
  }

  clearActive(): void {
    this.setActive(undefined);
  }

  getActive(): CompanyDto {
    return ActiveCompanyService.activeCompany;
  }

  watch(): Observable<ActiveCompanies> {
    return ActiveCompanyService.activeCompanySubject.asObservable();
  }

  private emitChanges() {
    ActiveCompanyService.activeCompanySubject.next({
      active: ActiveCompanyService.activeCompany,
      companies: ActiveCompanyService.companies,
    });
  }
}
