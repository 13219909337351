import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottombarComponent } from './components/bottombar/bottombar.component';
import { BottombarService } from './services/bottombar/bottombar.service';
import { BottombarTemplateComponent } from './components/bottombar/bottombar-template/bottombar-template.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const EXPORTED_COMPONENTS = [
  BottombarComponent,
  BottombarTemplateComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [
    ...EXPORTED_COMPONENTS,
  ],
  providers: [],
  exports: [
    ...EXPORTED_COMPONENTS,
  ],
  entryComponents: [],
})
export class UiBottomBarModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UiBottomBarModule,
      providers: [BottombarService],
    };
  }
}
