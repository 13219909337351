import { Big } from 'big.js';
import { throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ApplicationError, ErrorNames } from 'src/app/shared/errors/application-error';
import { StoreMenuService } from '../store-menu/store-menu.service';
import { v4 as uuidV4 } from 'uuid';
var StoreCartService = (function () {
    function StoreCartService(storeMenuService) {
        this.storeMenuService = storeMenuService;
        this.carts = {};
        this.idempotencyKeys = {};
        this.tagLimitMap = {};
    }
    StoreCartService.prototype.flattenOrderItem = function (orderItem, pricing) {
        var name = orderItem.getName();
        var options = orderItem.getSelectedOptions();
        var flatOptions = [];
        var price = pricing ? pricing.total : '0';
        options.forEach(function (option) {
            var optionName = option.name;
            option.getSelectedChoices().forEach(function (choice) {
                var quantity = choice.quantityNames && choice.quantityNames.length > choice.quantity
                    ? choice.quantityNames[choice.quantity] : choice.quantity;
                var displayQuantity = quantity === 1 ? '' : " (" + quantity + ")";
                var flatOption = optionName + ", " + choice.name + displayQuantity;
                flatOptions.push({
                    name: flatOption,
                    hidden: choice.hidden,
                });
            });
        });
        var discount = pricing ? pricing.discount : '0';
        return {
            name: name,
            options: flatOptions,
            price: price,
            note: orderItem.note,
            discount: discount,
            fullPrice: Big(price).plus(discount).toString(),
        };
    };
    StoreCartService.prototype.addToCart = function (orderItem, storeId) {
        if (!this.carts.hasOwnProperty(storeId)) {
            this.resetCart(storeId);
        }
        this.carts[storeId].push(orderItem);
    };
    StoreCartService.prototype.updateCartItem = function (orderItem, storeId, cartItemId) {
        if (this.carts.hasOwnProperty(storeId)) {
            this.carts[storeId].splice(cartItemId, 1, orderItem);
        }
    };
    StoreCartService.prototype.addCart = function (cart, storeId) {
        this.carts[storeId] = cart;
        this.generateCartIdempotencyKey(storeId);
    };
    StoreCartService.prototype.getCart = function (storeId) {
        return of(this.carts[storeId] || []);
    };
    StoreCartService.prototype.getCartTagCounts = function (storeId) {
        var cart = this.carts[storeId] || [];
        var tagCountAccumulator = {};
        cart.forEach(function (item) {
            var itemTags = item.getTags();
            if (itemTags && itemTags.length > 0) {
                itemTags.forEach(function (itemTag) {
                    tagCountAccumulator[itemTag] = (tagCountAccumulator[itemTag] || 0) + 1;
                });
            }
        });
        return tagCountAccumulator;
    };
    StoreCartService.prototype.canItemBeAddedToCart = function (storeId, orderItem) {
        var itemTags = orderItem.getTags();
        var tagCounts = this.getCartTagCounts(storeId);
        for (var i = 0, len = itemTags.length; i < len; i++) {
            var itemTag = itemTags[i];
            var tagLimit = this.tagLimitMap[itemTag];
            var itemTagCount = tagCounts[itemTag] || 0;
            if (tagLimit && tagLimit <= itemTagCount) {
                return { canAdd: false, reason: 'limit', limit: tagLimit };
            }
        }
        return { canAdd: true };
    };
    StoreCartService.prototype.getCartOrderItemById = function (storeId, cartItemIndex) {
        var cart = this.carts[storeId];
        if (cart) {
            var item = cart[cartItemIndex];
            return of(item).pipe(catchError(function (error) { return throwError(new ApplicationError({
                name: ErrorNames.DATA_RESOLUTION,
                message: 'Cart item does not exist',
                details: error,
            })); }));
        }
        else {
            return of(undefined);
        }
    };
    StoreCartService.prototype.removeCartItemById = function (storeId, cartItemId) {
        this.carts[storeId].splice(cartItemId, 1);
    };
    StoreCartService.prototype.copyCartItem = function (storeId, cartItemId) {
        var cart = this.carts[storeId];
        var clonedItem = _cloneDeep(cart[cartItemId]);
        cart.push(clonedItem);
    };
    StoreCartService.prototype.resetCart = function (storeId) {
        this.addCart([], storeId);
    };
    StoreCartService.prototype.getQuantityName = function (choice) {
        if (choice.quantityNames && choice.quantityNames.length > 0) {
            return choice.quantityNames[choice.quantity];
        }
        else {
            return choice.unit ? choice.quantity + choice.unit : choice.quantity + '';
        }
    };
    StoreCartService.prototype.getOrderDto = function (storeId) {
        var _this = this;
        var order = {
            storeId: storeId,
            items: this.carts[storeId].map(function (orderItem, index) {
                var size = orderItem.getSize();
                var sectionCategoryIds = _this.storeMenuService.getItemCategoryAndSectionIds(orderItem);
                var items = {
                    menuItemId: orderItem.itemId,
                    menuCategoryId: sectionCategoryIds.itemCategoryId,
                    menuSectionId: sectionCategoryIds.itemSectionId,
                    name: orderItem.getName(),
                    price: orderItem.getBasePrice(),
                    notes: [
                        {
                            type: 'barista',
                            value: orderItem.note,
                        },
                    ],
                    size: {
                        menuItemSizeId: size.id,
                        name: size.name,
                        price: size.price,
                    },
                    options: orderItem.getSelectedOptions().map(function (option) { return ({
                        menuOptionId: option.id,
                        name: option.name,
                        choices: option.getSelectedChoices().map(function (choice) { return ({
                            menuOptionChoiceId: choice.id,
                            quantity: choice.quantity,
                            quantityName: _this.getQuantityName(choice),
                            name: choice.name,
                            price: choice.getPrice(orderItem.getSize().id),
                        }); }),
                    }); }),
                };
                return items;
            }),
        };
        return order;
    };
    StoreCartService.prototype.getCartIdempotencyKey = function (storeId) {
        return this.idempotencyKeys[storeId] || this.generateCartIdempotencyKey(storeId);
    };
    StoreCartService.prototype.setCartMenuLimitations = function (menu) {
        var _this = this;
        if (menu.metaData && menu.metaData.itemTagLimits) {
            menu.metaData.itemTagLimits.forEach(function (tagLimit) {
                _this.tagLimitMap[tagLimit.name] = tagLimit.limit;
            });
        }
    };
    StoreCartService.prototype.generateCartIdempotencyKey = function (storeId) {
        this.idempotencyKeys[storeId] = uuidV4();
        return this.idempotencyKeys[storeId];
    };
    return StoreCartService;
}());
export { StoreCartService };
