<div class="consumer-side-nav-wrap">
  <header class="menu-header" fxLayout="row" fxLayoutAlign="start center">
    <!-- LOGGED IN -->
    <ng-container *ngIf="activeUser">
      <div fxFlex="75px">&nbsp;</div>
      <div fxFlex="grow" class="user-details-wrap">
        <h3>{{activeUser.firstName}}</h3>
      </div>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" *ngIf="activeUser">
      <a routerLink="/profile">
        <mat-icon class="profile-settings-icon">settings</mat-icon>
      </a>
      <a *ngIf="userHasStoreRoles" routerLink="/merchant-access">
        <mat-icon class="merchant-access-icon">store</mat-icon>
      </a>
    </div>
    <!-- NOT LOGGED IN -->
    <ng-container *ngIf="!activeUser">
      <div fxFlex>&nbsp;</div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button size="small" color="light" (click)="showLogin()">
          <mat-icon>perm_identity</mat-icon> Login
        </button>
      </div>
      <div fxFlex="10px">&nbsp;</div>
    </ng-container>
  </header>
  <div class="sidebar-content-wrap">
    <div *ngIf="!activeUser" class="sidebar-intro-content" fxFill fxLayout="column" fxLayoutAlign="space-evenly"
      fxLayoutAlign.sm="start center" fxLayoutGap.gt-xs="5%">
      <div fxLayout="row" fxLayout.gt-xs="column" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.gt-xs="center center">
        <div fxFlex.lt-sm="40" fxLayout="column" fxLayoutAlign="space-between center" routerLink="/stores"
          (click)="close()">
          <div class="sidebar-intro-icon" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon>search</mat-icon>
          </div>
          <p class="sidebar-icon-text">nearby stores</p>
        </div>
        <p class="sidebar-intro-text" fxFlex.lt-sm="60">Support local coffee and the business owners behind the counter
          at
          your favorite coffee shops.</p>
      </div>
      <div fxLayout="row" fxLayout.gt-xs="column" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.gt-xs="start center">
        <div fxFlex.lt-sm="40" fxLayout="column" fxLayoutAlign="space-between center">
          <div class="sidebar-intro-icon reward-icon" fxLayout="column" fxLayoutAlign="center center">
          </div>
          <p class="sidebar-icon-text">rewards</p>
        </div>
        <p class="sidebar-intro-text" fxFlex.lt-sm="60">Earn points towards free drinks and redeem them anywhere in the
          joe
          network.</p>
      </div>
      <div fxLayout="row" fxLayout.gt-xs="column" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.gt-xs="start center">
        <div fxFlex.lt-sm="40" fxLayout="column" fxLayoutAlign="space-between center">
          <div class="sidebar-intro-icon" fxLayout="column" fxLayoutAlign="center center">
            <p>
              <mat-icon>attach_money</mat-icon>
            </p>
          </div>
          <p class="sidebar-icon-text">balance</p>
        </div>
        <p class="sidebar-intro-text" fxFlex.lt-sm="60">Preload funds to make it easy to place your order when the time
          comes.
        </p>
      </div>
    </div>

    <div *ngIf="activeUser" fxFill fxLayout="column" fxLayoutAlign="space-between" fxLayoutAlign.gt-md="start stretch"
      fxLayoutGap="5px">
      <div fxFlex>
        <sidebar-last-order-card [loading]="!orderDataReady" [order]="lastOrder" (close)="close()">
        </sidebar-last-order-card>
      </div>
      <div fxFlex="40">
        <sidebar-links fxFill [joeBucksBalance]="joeBucksBalance" [rewardsRedeemable]="rewards?.length || 0"
          (showAddFunds)="showAddFunds()" (showRewardInfo)="showRewardInfo()" (showShareSheet)="showShareSheet()"
          (close)="close()">
        </sidebar-links>
      </div>
      <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center">
        <div class="reward-review-container" fxLayout="row">
          <div fxFlex="50" class="reward-progress-component-container">
            <sidebar-reward-progress class="sidebar-reward-progress" [rewardProgress]="rewardProgress"
              [imgUrl]="activeUser?.photo || null" routerLink="/profile" (click)="close()">
            </sidebar-reward-progress>
          </div>
          <div *ngIf="rewardDataReady" class="reward-bean-count-container" fxFlex fxLayout="column"
            fxLayout="center center" (click)="showRewardInfo()">
            <div class="reward-bean-count-place" fxFill fxLayout="row" fxLayoutAlign="center center">
              <div class="reward-bean-icon"></div>
              <div class="reward-bean-count">{{rewardProgress}} bean{{ rewardProgress !== 1 ? 's' : ''}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <ng-template #rewardInfoDialog>
    <div class="reward-detail-wrap">
      <img class="reward-image" src="public-web-assets/images/single-bean-64.png">
      <div class="reward-label">{{rewardProgress}}<small> / 1000</small></div>
      <div class="reward-note">Note: Balance may not reflect rewards for todays orders.</div>
      <hr>
      <ul class="reward-detail-info">
        <li class="reward-detail-info-item">Earn reward points with every purchase</li>
        <li class="reward-detail-info-item">Stay tuned for chances to earn bonus points every week</li>
        <li class="reward-detail-info-item">Every 1000 points earns you a free drink (up to $6 value)</li>
      </ul>
    </div>
  </ng-template>
</div>