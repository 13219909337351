import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, OrderCreateRequestDto, OrderDto, JoebucksBalanceResponseDto, UploadJoebucksRequestDto } from 'src/gen/joeServerCore';

@Injectable()
export class CheckoutService {

  constructor(
    private readonly apiService: ApiService,
  ) { }

  checkout(orderData: OrderCreateRequestDto): Observable<OrderDto> {
    return this.apiService.orderCreate(orderData);
  }

  uploadJoeBucks(joebucksData: UploadJoebucksRequestDto): Observable<JoebucksBalanceResponseDto> {
    return this.apiService.joebucksUploadFunds(joebucksData);
  }

}
