var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ApiConfiguration } from 'src/gen/joeServerCore';
import { EnvironmentService } from './shared/services/environment/environment.service';
import { UpdateService } from './shared/services/update/update.service';
import { LoadingIndicatorService } from './shared/packages/ui-loading-indicator';
import { MerchantTopbarService } from './merchant/packages/ui-merchant-chrome/services/merchant-topbar/merchant-topbar.service';
import { MerchantBottombarService } from './merchant/packages/ui-merchant-chrome/services/merchant-bottombar/merchant-bottombar.service';
import { takeWhile } from 'rxjs/operators';
import { AppInitializationService } from './shared/services/app-initialization/app-initialization.service';
import { NativeBackButtonService } from './shared/packages/native-support/services/native-back-button/native-back-button.service';
import { TelemetryService } from './shared/packages/telemetry/services/telemetry/telemetry.service';
import { BottombarService } from './shared/packages/ui-bottom-bar/services/bottombar/bottombar.service';
import { promiseDelayAtLeast } from './shared/helpers/promise/promise-delay-at-least';
import { HttpClient } from '@angular/common/http';
import { safeGet } from './shared/helpers/object/safe-get';
import { FeatureSwitchService } from './shared/services/feature-switch/feature-switch.service';
import { MatDialog } from '@angular/material';
import { MerchantPlusNagDialogComponent } from './merchant/dialogs/merchant-plus-nag-dialog/components/merchant-plus-nag-dialog/merchant-plus-nag-dialog.component';
var UPDATE_CHECK_INTERVAL = 3600000;
var AppComponent = (function () {
    function AppComponent(router, environmentService, changeDetectorRef, initializationService, updateService, loadingIndicatorService, merchantTopbarService, bottombarService, merchantBottombarService, apiConfig, nativeBackButtonService, telemetryService, featureSwitchService, http, dialog) {
        this.router = router;
        this.environmentService = environmentService;
        this.changeDetectorRef = changeDetectorRef;
        this.initializationService = initializationService;
        this.updateService = updateService;
        this.loadingIndicatorService = loadingIndicatorService;
        this.merchantTopbarService = merchantTopbarService;
        this.bottombarService = bottombarService;
        this.merchantBottombarService = merchantBottombarService;
        this.nativeBackButtonService = nativeBackButtonService;
        this.telemetryService = telemetryService;
        this.dialog = dialog;
        this.merchantMode = true;
        this.loading = true;
        this.criticalUpdateNeeded = false;
        featureSwitchService.initQueryParams();
        var coreApiUrlOverride = featureSwitchService.getValue('CORE_SERVICE_API_URL_OVERRIDE');
        var joeCoreApiUrl = this.environmentService.getConfig().apiUrls.joeServerCore;
        apiConfig.rootUrl = coreApiUrlOverride ? coreApiUrlOverride + '/api/v1' : joeCoreApiUrl;
        this.telemetryService.init(http);
    }
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var appVersionData, appMode, appBuildNumber, htmlTag, htmlTag, isNative;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.checkingForUpdate = false;
                        return [4, this.setMode()];
                    case 1:
                        _a.sent();
                        return [4, this.environmentService.getVersions()];
                    case 2:
                        appVersionData = _a.sent();
                        return [4, this.environmentService.getMode()];
                    case 3:
                        appMode = _a.sent();
                        appBuildNumber = appVersionData && appVersionData.build && appVersionData.build.number || 'unknown';
                        this.versionShortCode = appBuildNumber.replace(/.*_(?!.*_)/, '');
                        return [4, this.environmentService.getPlatform()];
                    case 4:
                        if ((_a.sent()) === 2) {
                            htmlTag = document.querySelector('html');
                            if (htmlTag && htmlTag.classList) {
                                if (!(window['Ionic'] && window['Ionic'].WebView)) {
                                    htmlTag.classList.add('old-webview-scroll-fix');
                                }
                            }
                        }
                        return [4, this.environmentService.getPlatform()];
                    case 5:
                        if ((_a.sent()) !== 0) {
                            htmlTag = document.querySelector('html');
                            if (htmlTag && htmlTag.classList) {
                                htmlTag.classList.add('block-document-selection');
                            }
                        }
                        this.componentActive = true;
                        this.router.events.pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (event) {
                            if (event instanceof NavigationStart) {
                                if (appMode === 1) {
                                    _this.loadingIndicatorService.show();
                                }
                            }
                            else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                                _this.loadingIndicatorService.dismiss();
                            }
                            if (event instanceof NavigationStart) {
                                _this.merchantTopbarService.setTitle('');
                                _this.bottombarService.setTemplate();
                                _this.merchantBottombarService.setTemplate();
                                _this.nativeBackButtonService.clearBackAction();
                            }
                            if (event instanceof NavigationEnd) {
                                var scrollContainer = document.querySelector('.mat-drawer-content');
                                if (scrollContainer) {
                                    if (scrollContainer.scrollTo) {
                                        scrollContainer.scrollTo(0, 0);
                                    }
                                    else {
                                        scrollContainer.scrollTop = 0;
                                    }
                                }
                                _this.telemetryService.logPageUrlChange(event.url);
                            }
                        });
                        return [4, this.initializationService.init()];
                    case 6:
                        _a.sent();
                        return [4, promiseDelayAtLeast(this.checkForUpdates(), 2000)];
                    case 7:
                        _a.sent();
                        this.environmentService.onResume().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.checkForUpdates()];
                                    case 1:
                                        _a.sent();
                                        this.loading = false;
                                        this.changeDetectorRef.detectChanges();
                                        return [2];
                                }
                            });
                        }); });
                        this.environmentService.onPause().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function () {
                            _this.clearUpdateTimer();
                        });
                        this.loading = false;
                        return [4, this.environmentService.isNative()];
                    case 8:
                        isNative = _a.sent();
                        if (isNative) {
                            this.dialog.open(MerchantPlusNagDialogComponent, {
                                disableClose: true,
                            });
                        }
                        return [2];
                }
            });
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
        this.clearUpdateTimer();
    };
    AppComponent.prototype.setMode = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.merchantMode = true;
                return [2];
            });
        });
    };
    AppComponent.prototype.clearUpdateTimer = function () {
        if (this.updateCheckTimeout) {
            clearTimeout(this.updateCheckTimeout);
            this.updateCheckTimeout = undefined;
        }
    };
    AppComponent.prototype.checkForUpdates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hotUpdateStatus, _a, dialogMessage, versionInfo, versionUpdateString, error_1, errorMessage;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.environmentService.isNative()];
                    case 1:
                        if (!(_b.sent())) {
                            return [2];
                        }
                        if (!this.merchantMode) {
                            this.criticalUpdateNeeded = true;
                            return [2];
                        }
                        this.checkingForUpdate = true;
                        this.clearUpdateTimer();
                        this.updateCheckTimeout = setTimeout(function () { return _this.checkForUpdates(); }, UPDATE_CHECK_INTERVAL);
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 14, , 15]);
                        return [4, this.updateService.fetchHotUpdate()];
                    case 3:
                        hotUpdateStatus = _b.sent();
                        _a = hotUpdateStatus;
                        switch (_a) {
                            case 1: return [3, 4];
                            case 4: return [3, 5];
                            case 2: return [3, 6];
                            case 3: return [3, 11];
                        }
                        return [3, 12];
                    case 4: return [2];
                    case 5:
                        this.criticalUpdateNeeded = true;
                        dialogMessage = 'Your application is out of date. A New version of the application is available on the store.';
                        this.updateService.requestApplicationUpdate(dialogMessage);
                        return [3, 13];
                    case 6: return [4, this.updateService.getVersionInfo()];
                    case 7:
                        versionInfo = _b.sent();
                        if (!(versionInfo.readyToInstallWebVersion &&
                            this.releaseToInt(versionInfo.currentWebVersion) < this.releaseToInt(versionInfo.readyToInstallWebVersion))) return [3, 9];
                        this.loading = true;
                        return [4, this.updateService.installHotUpdate()];
                    case 8:
                        _b.sent();
                        this.loading = false;
                        return [3, 10];
                    case 9:
                        versionUpdateString = versionInfo && versionInfo.currentWebVersion + " -> " + versionInfo.readyToInstallWebVersion;
                        this.telemetryService.logError("Hot Update Error INVALID_VERSION " + versionUpdateString, true);
                        _b.label = 10;
                    case 10: return [3, 13];
                    case 11:
                        this.telemetryService.logError('Hot Update Error FAILURE_DISK');
                        return [2];
                    case 12: return [2];
                    case 13: return [3, 15];
                    case 14:
                        error_1 = _b.sent();
                        errorMessage = safeGet(error_1, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError('Hot Update Error ' + errorMessage);
                        this.loading = false;
                        return [3, 15];
                    case 15:
                        this.checkingForUpdate = false;
                        return [2];
                }
            });
        });
    };
    AppComponent.prototype.releaseToInt = function (releaseVersion) {
        try {
            var dateArr = releaseVersion.split('-');
            var day = dateArr[0].split('.');
            var time = dateArr[1].split('.');
            return parseInt(day.concat(time).join(''), 10);
        }
        catch (error) {
            var errorMessage = safeGet(error, function (e) { return e.message || e.toString(); }) || 'Unknown error';
            this.telemetryService.logError(errorMessage);
        }
        return 0;
    };
    return AppComponent;
}());
export { AppComponent };
