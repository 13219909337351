import { Router } from '@angular/router';
import { EnvironmentService } from '../../services/environment/environment.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';
var ConsumerGuardService = (function () {
    function ConsumerGuardService(environment, router) {
        this.environment = environment;
        this.router = router;
    }
    ConsumerGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        return fromPromise(this.environment.getMode()).pipe(map(function (mode) {
            var isConsumer = mode === 0;
            if (!isConsumer) {
                _this.router.navigateByUrl('/');
            }
            return isConsumer;
        }));
    };
    return ConsumerGuardService;
}());
export { ConsumerGuardService };
