var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApiService } from 'src/gen/joeServerCore';
import { of, BehaviorSubject } from 'rxjs';
import { NativePushService } from 'src/app/shared/packages/native-support/services/native-push/native-push.service';
import { map, filter, switchMap } from 'rxjs/operators';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { StoreMenuService } from '../store-menu/store-menu.service';
import { OrderItem } from '../../models/order-item/order-item.class';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { fromPromise } from 'rxjs/internal-compatibility';
var PREVIOUS_STORE_CACHE_DURATION_MS = 10 * 24 * 60 * 60 * 1000;
var OrderService = (function () {
    function OrderService(apiService, nativePushService, identityService, storeMenuService, storageService) {
        this.apiService = apiService;
        this.nativePushService = nativePushService;
        this.identityService = identityService;
        this.storeMenuService = storeMenuService;
        this.storageService = storageService;
    }
    OrderService.prototype.sendReview = function (_a, review) {
        var orderId = _a.id;
        return this.apiService.orderReview({ orderId: orderId, OrderReviewDto: review })
            .pipe(map(function (_a) {
            var _b = _a.result, result = _b === void 0 ? false : _b;
            return result;
        }));
    };
    OrderService.prototype.getOrderPricing = function (order, paymentSourceType) {
        if (paymentSourceType === void 0) { paymentSourceType = 'card_new'; }
        return this.apiService.orderPricingComputeWithSourceType({ OrderDto: order, paymentSourceType: paymentSourceType });
    };
    OrderService.prototype.watchOrder = function (orderId) {
        if (orderId) {
            return this.nativePushService.orderStatusUpdateListener.pipe(filter(function (o) { return o.orderId === orderId; }));
        }
        return this.nativePushService.orderStatusUpdateListener;
    };
    OrderService.prototype.watchOrderRewards = function () {
        if (!OrderService.pendingRewardData) {
            OrderService.pendingRewardData = new BehaviorSubject(undefined);
            this.nativePushService.orderStatusUpdateListener.subscribe(function (orderData) {
                if (orderData.rewardProgress) {
                    OrderService.pendingRewardData.next(orderData.rewardProgress);
                }
            });
        }
        return OrderService.pendingRewardData.asObservable();
    };
    OrderService.prototype.clearOrderRewards = function () {
        OrderService.pendingRewardData.next(undefined);
    };
    OrderService.prototype.getOrder = function (orderId) {
        return this.apiService.orderGet(orderId);
    };
    OrderService.prototype.getUserOrders = function (storeId) {
        var _this = this;
        return fromPromise(this.identityService.isLoaded()).pipe(switchMap(function () {
            if (_this.identityService.getActiveUser()) {
                return _this.apiService.orderList(storeId);
            }
            else {
                return of([]);
            }
        }));
    };
    OrderService.prototype.getCartFromOrderViewConsumerDto = function (order) {
        return __awaiter(this, void 0, void 0, function () {
            var storeMenu, cart;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.storeMenuService.getStoreMenuById(order.storeId).toPromise()];
                    case 1:
                        storeMenu = _a.sent();
                        if (storeMenu) {
                            cart = order.items.map(function (item) {
                                return _this.getOrderItemFromDto(storeMenu, item, item.choices);
                            });
                            if (cart.indexOf(undefined) !== -1) {
                                return [2, []];
                            }
                            else {
                                return [2, cart];
                            }
                        }
                        else {
                            return [2, []];
                        }
                        return [2];
                }
            });
        });
    };
    OrderService.prototype.getOrderItemFromDto = function (storeMenu, itemDto, choices) {
        var selectedCategoryIndex;
        var selectedCategory = storeMenu.categories.find(function (category, index) {
            if (category.id === itemDto.menuCategoryId) {
                selectedCategoryIndex = index;
                return category.id === itemDto.menuCategoryId;
            }
        });
        if (selectedCategory && selectedCategoryIndex !== undefined) {
            var selectedSectionIndex_1;
            var selectedSection = selectedCategory.sections.find(function (section, index) {
                if (section.id === itemDto.menuSectionId) {
                    selectedSectionIndex_1 = index;
                    return section.id === itemDto.menuSectionId;
                }
            });
            if (selectedSection && selectedSectionIndex_1 !== undefined) {
                var selectedItem = selectedSection.items.find(function (item) { return item.id === itemDto.menuItemId; });
                if (selectedItem && selectedItem.enabled) {
                    var orderItem_1 = new OrderItem(selectedItem, selectedCategoryIndex, selectedSectionIndex_1, true);
                    if (itemDto.size !== '') {
                        var sizeIndex_1 = 0;
                        selectedItem.sizes.forEach(function (size, index) {
                            if (size.name === itemDto.size) {
                                sizeIndex_1 = index;
                            }
                            else if ((size.quantity + size.quantityUnit) === itemDto.size) {
                                sizeIndex_1 = index;
                            }
                        });
                        if (selectedItem.sizes[sizeIndex_1].inventory &&
                            selectedItem.sizes[sizeIndex_1].inventory.length > 0 &&
                            selectedItem.sizes[sizeIndex_1].inventory[0].quantity === 0) {
                            return;
                        }
                        if (sizeIndex_1 !== -1) {
                            orderItem_1.setSize(sizeIndex_1);
                        }
                        else {
                            orderItem_1.setSize(0);
                        }
                    }
                    else if (itemDto.sizeId !== null) {
                        var sizeIndex = selectedItem.sizes.findIndex(function (size) { return size.id === itemDto.sizeId; });
                        if (sizeIndex !== -1) {
                            orderItem_1.setSize(sizeIndex);
                        }
                        else {
                            orderItem_1.setSize(0);
                        }
                    }
                    else {
                        orderItem_1.setSize(0);
                    }
                    if (itemDto.notes.length > 0) {
                        var noteIndex = itemDto.notes.findIndex(function (note) { return note.type === 'barista'; });
                        {
                            orderItem_1.setNote(itemDto.notes[noteIndex].value);
                        }
                    }
                    var optionsVerified_1;
                    var choicesVerified_1;
                    if (choices.length > 0) {
                        var availableOptions_1 = orderItem_1.getAvailableOptions();
                        choices.forEach(function (choice) {
                            var optionIndex = availableOptions_1.findIndex(function (option) { return option.id === choice.optionId; });
                            if (optionIndex !== -1) {
                                optionsVerified_1 = true;
                                var addedChoice_1 = availableOptions_1[optionIndex].getAvailableChoices()
                                    .find(function (availableChoice) { return availableChoice.id === choice.id; });
                                var defaultChoice = availableOptions_1[optionIndex].getSelectedChoices()
                                    .find(function (availableChoice) { return availableChoice.id === choice.id; });
                                if (addedChoice_1) {
                                    choicesVerified_1 = true;
                                    var optionWasAdded = orderItem_1.addOption(optionIndex, addedChoice_1.index);
                                    if (optionWasAdded) {
                                        var addedChoiceToUpdate = orderItem_1.options[optionIndex].getSelectedChoices().find(function (selectedChoice) {
                                            return selectedChoice.index === addedChoice_1.index;
                                        });
                                        if (choice.quantity !== '') {
                                            addedChoiceToUpdate.setQuantity(parseInt(choice.quantity, 10));
                                        }
                                    }
                                }
                                else if (defaultChoice) {
                                    optionsVerified_1 = true;
                                    choicesVerified_1 = true;
                                }
                                else {
                                    choicesVerified_1 = false;
                                }
                            }
                            else {
                                optionsVerified_1 = false;
                            }
                        });
                    }
                    else {
                        optionsVerified_1 = true;
                        choicesVerified_1 = true;
                    }
                    if (choices.length === 0 || (optionsVerified_1 && choicesVerified_1)) {
                        return orderItem_1;
                    }
                }
            }
        }
    };
    OrderService.prototype.setPreviousOrderStoreUrl = function (storeUrl) {
        return this.storageService.set(StorageKeys.PREVIOUS_STORE_STORAGE_KEY, {
            storeUrl: storeUrl,
            orderTime: Date.now(),
        });
    };
    OrderService.prototype.getPreviousOrderStoreUrl = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.storageService.get(StorageKeys.PREVIOUS_STORE_STORAGE_KEY)];
                    case 1:
                        data = _a.sent();
                        if (!data || !data.orderTime || !data.storeUrl) {
                            return [2];
                        }
                        if (!(Date.now() - data.orderTime > PREVIOUS_STORE_CACHE_DURATION_MS)) return [3, 3];
                        return [4, this.storageService.remove(StorageKeys.PREVIOUS_STORE_STORAGE_KEY)];
                    case 2:
                        _a.sent();
                        return [2];
                    case 3: return [2, data.storeUrl];
                }
            });
        });
    };
    return OrderService;
}());
export { OrderService };
