import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiConfirmDialogComponent } from './components/ui-confirm-dialog/ui-confirm-dialog.component';
import { MatDialogModule, MatExpansionModule, MatButtonModule, MatIconModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

export const MATERIAL_IMPORTS = [
  MatDialogModule,
  MatExpansionModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ...MATERIAL_IMPORTS,
  ],
  declarations: [
    UiConfirmDialogComponent,
  ],
  providers: [],
  exports: [
    UiConfirmDialogComponent,
  ],
  entryComponents: [
    UiConfirmDialogComponent,
  ],

})
export class UiConfirmDialogModule { }
