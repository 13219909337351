var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NativeStorage } from '@ionic-native/native-storage';
import { File } from '@ionic-native/file';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { TelemetryService } from '../../telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
var JOE_BACKUP_FILE_PATH = 'joe-coffee-merchant-app-local-storage-backup.json';
var StorageService = (function () {
    function StorageService(nativeStorage, environmentService, nativeFile, telemetryService) {
        var _this = this;
        this.nativeStorage = nativeStorage;
        this.environmentService = environmentService;
        this.nativeFile = nativeFile;
        this.telemetryService = telemetryService;
        this.localStorage = localStorage;
        this.backupReady = new Promise(function (resolve) { return _this.backupReadyResolver = resolve; });
        this.initStorageBackup();
    }
    StorageService.prototype.set = function (key, value, bypassBackupReady) {
        if (bypassBackupReady === void 0) { bypassBackupReady = false; }
        return __awaiter(this, void 0, void 0, function () {
            var error_1, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!bypassBackupReady) return [3, 2];
                        return [4, this.backupReady];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4, this.environmentService.isNative()];
                    case 3:
                        if (!_a.sent()) return [3, 9];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 7, , 8]);
                        return [4, this.environmentService.onReady()];
                    case 5:
                        _a.sent();
                        return [4, this.nativeStorage.setItem(key, value)];
                    case 6:
                        _a.sent();
                        this.backupLocalStorage();
                        return [3, 8];
                    case 7:
                        error_1 = _a.sent();
                        errorMessage = safeGet(error_1, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 8];
                    case 8: return [2];
                    case 9: return [2, this.localStorage.setItem(key, JSON.stringify(value))];
                }
            });
        });
    };
    StorageService.prototype.get = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2, errorMessage, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.backupReady];
                    case 1:
                        _a.sent();
                        return [4, this.environmentService.isNative()];
                    case 2:
                        if (!_a.sent()) return [3, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        return [4, this.environmentService.onReady()];
                    case 4:
                        _a.sent();
                        return [4, this.nativeStorage.getItem(key)];
                    case 5: return [2, (_a.sent()) || {}];
                    case 6:
                        error_2 = _a.sent();
                        errorMessage = safeGet(error_2, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 7];
                    case 7: return [2, {}];
                    case 8:
                        try {
                            return [2, JSON.parse(this.localStorage.getItem(key)) || {}];
                        }
                        catch (error) {
                            errorMessage = safeGet(error, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                            this.telemetryService.logError(errorMessage);
                        }
                        return [2, {}];
                }
            });
        });
    };
    StorageService.prototype.remove = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.backupReady];
                    case 1:
                        _a.sent();
                        return [4, this.environmentService.isNative()];
                    case 2:
                        if (!_a.sent()) return [3, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        return [4, this.environmentService.onReady()];
                    case 4:
                        _a.sent();
                        return [4, this.nativeStorage.remove(key)];
                    case 5:
                        _a.sent();
                        this.backupLocalStorage();
                        return [3, 7];
                    case 6:
                        error_3 = _a.sent();
                        errorMessage = safeGet(error_3, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 7];
                    case 7: return [2];
                    case 8: return [2, this.localStorage.removeItem(key)];
                }
            });
        });
    };
    StorageService.prototype.getKeys = function (bypassBackupReady) {
        if (bypassBackupReady === void 0) { bypassBackupReady = false; }
        return __awaiter(this, void 0, void 0, function () {
            var error_4, errorMessage;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!bypassBackupReady) return [3, 2];
                        return [4, this.backupReady];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4, this.environmentService.isNative()];
                    case 3:
                        if (!_a.sent()) return [3, 8];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 7, , 8]);
                        return [4, this.environmentService.onReady()];
                    case 5:
                        _a.sent();
                        return [4, this.nativeStorage.keys()];
                    case 6: return [2, _a.sent()];
                    case 7:
                        error_4 = _a.sent();
                        errorMessage = safeGet(error_4, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 8];
                    case 8: return [2, new Array(this.localStorage.length).fill(0).map(function (_, i) {
                            return _this.localStorage.key(i);
                        })];
                }
            });
        });
    };
    StorageService.prototype.initStorageBackup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config, isNative, rawData, data, keys, i, key, error_5, errorMessage, isMerchant, existingKeys, error_6, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = this.environmentService.getConfig();
                        return [4, this.environmentService.isNative()];
                    case 1:
                        isNative = _a.sent();
                        if (!isNative) return [3, 16];
                        this.backupPersistencePath = this.nativeFile && this.nativeFile.externalRootDirectory;
                        if (!!!this.backupPersistencePath) return [3, 16];
                        this.backupPersistencePath = this.backupPersistencePath.replace(/\/$/, '');
                        if (!(config.name === 'Development')) return [3, 10];
                        this.backupEnabled = true;
                        console.log('restoring local storage from json file', this.backupPersistencePath + '/' + JOE_BACKUP_FILE_PATH);
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 8, , 9]);
                        return [4, this.nativeFile.readAsText(this.backupPersistencePath, JOE_BACKUP_FILE_PATH)];
                    case 3:
                        rawData = _a.sent();
                        data = JSON.parse(rawData);
                        keys = Object.keys(data);
                        i = 0;
                        _a.label = 4;
                    case 4:
                        if (!(i < keys.length)) return [3, 7];
                        key = keys[i];
                        return [4, this.set(key, data[key], true)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        i++;
                        return [3, 4];
                    case 7: return [3, 9];
                    case 8:
                        error_5 = _a.sent();
                        errorMessage = safeGet(error_5, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 9];
                    case 9: return [3, 16];
                    case 10: return [4, this.environmentService.getMode()];
                    case 11:
                        isMerchant = (_a.sent()) === 1;
                        if (!isMerchant) return [3, 16];
                        return [4, this.getKeys(true)];
                    case 12:
                        existingKeys = _a.sent();
                        if (!(existingKeys.length === 0)) return [3, 16];
                        _a.label = 13;
                    case 13:
                        _a.trys.push([13, 15, , 16]);
                        return [4, this.nativeFile.writeFile(this.backupPersistencePath, JOE_BACKUP_FILE_PATH, '{}', { replace: true })];
                    case 14:
                        _a.sent();
                        return [3, 16];
                    case 15:
                        error_6 = _a.sent();
                        errorMessage = safeGet(error_6, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 16];
                    case 16:
                        this.backupReadyResolver();
                        return [2];
                }
            });
        });
    };
    StorageService.prototype.backupLocalStorage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var keys, output, i, key, data, saveData, error_7, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.backupEnabled) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4, this.getKeys()];
                    case 2:
                        keys = _a.sent();
                        output = {};
                        i = 0;
                        _a.label = 3;
                    case 3:
                        if (!(i < keys.length)) return [3, 6];
                        key = keys[i];
                        return [4, this.get(key)];
                    case 4:
                        data = _a.sent();
                        output[key] = data;
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3, 3];
                    case 6:
                        saveData = JSON.stringify(output);
                        return [4, this.nativeFile.writeFile(this.backupPersistencePath, JOE_BACKUP_FILE_PATH, saveData, { replace: true })];
                    case 7:
                        _a.sent();
                        return [3, 9];
                    case 8:
                        error_7 = _a.sent();
                        errorMessage = safeGet(error_7, function (e) { return e.message || e.toString(); }) || 'Unknown error';
                        this.telemetryService.logError(errorMessage);
                        return [3, 9];
                    case 9: return [2];
                }
            });
        });
    };
    return StorageService;
}());
export { StorageService };
