// list of acceptable error names
// Used for queries and error dialogs. Should be human readable.
export enum ErrorNames {

  // error retrieving data in a resolver
  DATA_RESOLUTION = 'Data resolution error',

  // error writing data via api
  DATA_PERSISTENCE = 'Data persistence error',

  // 403 - fobidden (user lacks permissions)
  DATA_ACCESS = 'Data access error',

  // unable to contact joe server
  DATA_CONNECTIVITY = 'Data connectivity error',

  // unknown printer error after retry count reached
  PRINTER_RETRY_COUNT = 'Printer retry limit reached, Unknown error.',

  // bluetooth or cordova failure
  PRINTER_DISCOVERY_FAILURE = 'Printer discovery failure.',

  PRINTER_INACTIVE_FAILURE = 'Printer is offline or unavailable.',

  // unknown printer error
  PRINTER_UNKNOWN_ERROR = 'Unknown printer error.',
}
