import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, of, BehaviorSubject, Subscription, merge } from 'rxjs';
import { BatteryStatus } from '@ionic-native/battery-status';
import { Order, OrderStatus } from './pos.service.interfaces';
import { ApiService, OrderViewDto, CheckinResponseDto, MerchantDeviceDto, DeviceUnpairResponseDto } from 'src/gen/joeServerCore';
import { map, tap, catchError, switchMap, takeWhile } from 'rxjs/operators';
import { DeviceRegistrationService } from 'src/app/shared/services/device-registration/device-registration.service';
import { StorageService } from 'src/app/shared/packages/storage/storage/storage.service';
import { ApplicationError, ErrorNames } from 'src/app/shared/errors/application-error';
import { StorageKeys } from 'src/app/shared/enums/storage-keys';
import { fromPromise } from 'rxjs/internal-compatibility';
import { EnvironmentService, EnvironmentVersions, EnvironmentModes, EnvironmentPlatforms } from 'src/app/shared/services/environment/environment.service';
import { NativeAudioVolumeService } from 'src/app/shared/packages/native-support/services/native-audio-volume/native-audio-volume.service';
import { TelemetryService } from 'src/app/shared/packages/telemetry/services/telemetry/telemetry.service';
import { safeGet } from 'src/app/shared/helpers/object/safe-get';
import { PosConfig } from 'src/environments/interfaces/environment-config.interface';
import { activeOrdersFilter, newOrdersFilter, futureOrdersFilter } from '../../utils/order-filters';
import { Timer } from '../../utils/Timer.class';
import { MerchantServiceSocketService } from '../merchant-service-socket/merchant-service-socket.service';
import { Order as PosOrderCard } from 'src/app/pos/pages/pos-dashboard/components/pos-order-card/pos-order-card.component';
import { AudioAlertService } from '../../packages/ui-pos-chrome/services/audio-alert/audio-alert.service';
import * as jwtDecode from 'jwt-decode';

export { Order, OrderStatus };

// number of checkin failures before we thrown an error
const UPDATED_FAILURE_THRESHOLD = 5;

const JWT_REFRESH_EXPIRATION_PADDING = 30;

// if this amount of time has elapsed since the last refresh do it again
// const JWT_REFRESH_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes (server expiration is 15 minutes)

// Every 10 seconds, check to see if websockets are enabled, and set the poll frequency for checkin/orders accordingly
const POLLING_MODE_INTERVAL = 10_000;

const ACTIVE_ORDER_STATUSES = new Set(['draft', 'new', 'accepted']);

const API_ACTION_MAP: { [key: string]: 'approve' | 'reject' | 'cancel' | 'complete' } = {
  completed: 'complete',
  accepted: 'approve',
  rejected: 'reject',
  cancelled_store: 'cancel',
};

export interface PosPairDeviceOptions {
  companyId: string;
  storeId: string;
  deviceId: string;
}

export interface PairDeviceOptions {
  companyId: string;
  storeId: string;
  storeName: string;
}

export interface PairedDeviceDetails {
  deviceId: string;
  pushToken: string;
  companyId: string;
  storeId: string;
  storeName: string;
  token: string;
  refreshToken: string;
  active: boolean;
  lastRefresh?: number;
}

export interface StoreStats {
  orderCount?: number;
  totalSales?: string;
  totalTips?: string;
}

export enum PollingMode {
  SOCKET = 'SOCKET',
  POLLING = 'POLLING',
}

interface JwtData {
  exp: number;
  iat: number;
  id: string;
  globalRoles: string[];
}

@Injectable()
export class PosDeviceService implements OnDestroy {

  static authRefreshSubject: Subject<boolean>;

  private readonly _activeOrders = new BehaviorSubject<OrderViewDto[]>([]);

  public readonly activeOrders = this._activeOrders
    .pipe(
      map(activeOrdersFilter),
      map(this.sortOrderItems.bind(this)),
      map(this.populateOrderData.bind(this)),
    );
  public readonly newOrders = this._activeOrders
    .pipe(
      map(newOrdersFilter),
      map(this.populateOrderData.bind(this)),
    );
  public readonly futureOrders = this._activeOrders
    .pipe(
      map(futureOrdersFilter),
      map(this.populateOrderData.bind(this)),
    );

  public readonly completedOrders = new BehaviorSubject<OrderViewDto[]>([]);
  public readonly activeOrdersError = new BehaviorSubject<Error>(undefined);

  public readonly pairedDevice = new BehaviorSubject<PairedDeviceDetails>(undefined);

  public readonly storeStats = new BehaviorSubject<StoreStats>({});

  private activeOrderUpdateTimer = new Timer();
  private checkinTimer = new Timer();
  private orderTimer = new Timer();
  private pollingModeTimer = new Timer();
  private pollingMode: PollingMode = PollingMode.SOCKET;

  private serviceActive = true;

  private updateFailureCount = new BehaviorSubject<number>(0);
  private loadedPromise: Promise<void>;
  private loadedPromiseResolver: Function;
  private isDeviceUnplugged = new BehaviorSubject<boolean>(false);

  private deviceCheckinInProgress = false;
  private activeOrdersInProgress = false;
  private inactiveOrdersInProgress = false;
  private orderStatsInProgress = false;

  private environmentVersions: EnvironmentVersions;

  private activeOrdersEtag = '';
  private inactiveOrdersEtag = '';
  private orderStatsEtag = '';

  private errorSubscription: Subscription;
  private batteryStatusSubscription: Subscription;

  public readonly lastCheckinTime = new BehaviorSubject<number>(undefined);

  private readonly pollingPosConfig: PosConfig;
  private readonly socketPosConfig: PosConfig;

  private previousPairedStoreName: string;
  private previousPairedStoreId: string;
  private jwtDecode: (token: string) => JwtData;

  constructor(
    private environmentService: EnvironmentService,
    private apiService: ApiService,
    private storageService: StorageService,
    private batteryStatus: BatteryStatus,
    private deviceRegistrationService: DeviceRegistrationService,
    private audioVolumeService: NativeAudioVolumeService,
    private telemetryService: TelemetryService,
    private socket: MerchantServiceSocketService,
    private audioAlertService: AudioAlertService,
  ) {
    this.jwtDecode = jwtDecode;
    // Socket.io init
    this.socket.on('connect', () => this.setupSocketEvents());
    environmentService.getMode().then(mode => {
      if (mode === EnvironmentModes.MERCHANT) {
        this.socket.connect();
      }
    });

    // Poll interval init
    this.pollingPosConfig = environmentService.getConfig().pollingPosConfig;
    this.socketPosConfig = environmentService.getConfig().socketPosConfig;

    this.loadedPromise = new Promise(resolve => this.loadedPromiseResolver = resolve);

    // Set active orders periodically so that the completed/active orders update correctly
    this.activeOrderUpdateTimer = new Timer(() => this._activeOrders.next(this._activeOrders.value), 500).start();

    // Pass new store data to telemetry service on change
    this.pairedDevice.subscribe(() => this.setTelemetryDeviceTag());

    // set error
    this.errorSubscription = merge(
      this.updateFailureCount.pipe(map(updateFailureCount => ({ updateFailureCount, isDeviceUnplugged: this.isDeviceUnplugged.value }))),
      this.isDeviceUnplugged.pipe(map(isDeviceUnplugged => ({ isDeviceUnplugged, updateFailureCount: this.updateFailureCount.value }))),
    ).subscribe(({ updateFailureCount, isDeviceUnplugged }) => {
      let error: Error;
      if (updateFailureCount > UPDATED_FAILURE_THRESHOLD) {
        error = new ApplicationError({
          name: ErrorNames.DATA_CONNECTIVITY,
          message: `Unable to contact joe server please check your internet connection (${updateFailureCount} tries).`,
          details: {
            message: `Unsuccessfully tried to call server ${updateFailureCount} times.`,
          },
        });
      } else if (isDeviceUnplugged) {
        error = new Error('Device is unplugged. Please reconnect to ensure you remain available to receive orders.');
      }
      this.activeOrdersError.next(error);
    });

    this.setDeviceVersionMetaData();
    this.loadPairedDevice();
    this.watchBatteryStatus();
  }

  ngOnDestroy() {
    this.serviceActive = false;
    this.activeOrderUpdateTimer.stop();
    this.stopPolling();
    this.socket.removeAllListeners();
    this.socket.disconnect();

    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.pairedDevice) {
      this.pairedDevice.unsubscribe();
    }
    if (this.batteryStatusSubscription) {
      this.batteryStatusSubscription.unsubscribe();
    }
  }

  private async setTelemetryDeviceTag(): Promise<void> {
    const pairedDevice = this.getPairedDevice();

    if (pairedDevice && (pairedDevice.storeName !== this.previousPairedStoreName || pairedDevice.storeId !== this.previousPairedStoreId)) {
      this.telemetryService.setActiveDevice(pairedDevice.storeName, pairedDevice.storeId);
      this.previousPairedStoreName = pairedDevice.storeName;
      this.previousPairedStoreId = pairedDevice.storeId;
    }
  }

  isLoaded(): Promise<void> {
    return this.loadedPromise;
  }

  private setupSocketEvents() {
    this.socket.removeAllListeners();

    this.socket.on('connect', this.setupSocketEvents.bind(this));

    this.socket.on('device-check-in-ack', this.acknowledgeCheckin.bind(this));

    this.socket.on('order-created', this.getActiveOrders.bind(this));
    this.socket.on('order-approved', this.getActiveOrders.bind(this));
    this.socket.on('order-reviewed', this.getInactiveOrders.bind(this));
    this.socket.on('order-arrived', () => {
      this.getInactiveOrders();
      this.audioAlertService.playArrivedAlert();
    });
    this.socket.on('order-refunded', this.getInactiveOrders.bind(this));
    this.socket.on('order-rejected', () => {
      this.getActiveOrders();
      this.getInactiveOrders();
    });
    this.socket.on('order-canceled', () => {
      this.getActiveOrders();
      this.getInactiveOrders();
    });
    this.socket.on('order-completed', () => {
      this.getActiveOrders();
      this.getInactiveOrders();
      this.getOrderStats();
    });
  }

  // on device checkin acknowledgement, update store status and clear out any outstanding errors
  private acknowledgeCheckin(response: CheckinResponseDto) {
    this.lastCheckinTime.next(Date.now());

    const pairedDevice = this.getPairedDevice();
    if (pairedDevice && pairedDevice.active !== response.active) {
      this.setPairedDevice({ ...pairedDevice, active: response.active });
    }

    this.updateFailureCount.next(0);
    this.deviceCheckinInProgress = false;
  }

  private async watchBatteryStatus() {
    await this.isLoaded();

    if (this.batteryStatus && this.batteryStatus.onChange) {
      this.batteryStatusSubscription = this.batteryStatus.onChange()
        .pipe(takeWhile(() => this.serviceActive))
        .subscribe(status => this.isDeviceUnplugged.next(!status.isPlugged));
    }
  }

  getPairedDevices(companyId: string, storeId?: string): Observable<MerchantDeviceDto[]> {
    return this.apiService.deviceList({ companyId, storeId });
  }

  async pairPosDeviceFromCode({ storeId, companyId, deviceId }: PosPairDeviceOptions): Promise<boolean> {
    const { result } = await this.apiService.devicePairFromId({
      companyId,
      DevicePairFromCodeRequestDto: { storeId, deviceId },
    }).toPromise();
    return result;
  }

  async pair({ storeId, companyId, storeName }: PairDeviceOptions): Promise<void> {
    const [deviceInfo, pairedDeviceData] = await Promise.all([
      this.deviceRegistrationService.getDeviceInfo(),
      this.storageService.get<PairedDeviceDetails>(StorageKeys.MERCHANT_PAIRED_DEVICE_STORAGE_KEY),
    ]);

    const pushToken = deviceInfo.pushToken || pairedDeviceData.pushToken;

    if (deviceInfo.pushToken === undefined) {
      deviceInfo.pushToken = '';
    }

    if (deviceInfo.deviceType === undefined) {
      const isWeb = await this.environmentService.isWeb();
      const mobilePlatform = await this.environmentService.getPlatform();
      deviceInfo.deviceType = isWeb ? 'web' : (mobilePlatform === EnvironmentPlatforms.IOS ? 'ios' : 'android');
    }

    const { id, token, refreshToken, active } = await this.apiService.devicePairCreate({
      companyId,
      CreatePairDeviceRequestDto: {
        id: pairedDeviceData.deviceId,
        storeId,
        deviceInfo,
      },
    }).toPromise();

    await this.setPairedDevice({
      deviceId: id,
      companyId,
      storeId,
      storeName,
      pushToken,
      token,
      refreshToken,
      active,
    });

    this.startPolling();
  }

  unpairPosDeviceById({ storeId, companyId, deviceId }: PosPairDeviceOptions): Observable<DeviceUnpairResponseDto> {
    return this.apiService.deviceUnpair({ deviceId, companyId, storeId });
  }

  async unpair(): Promise<boolean> {
    const result = await this.disconnect();

    if (result) {
      const pairedDevice = this.getPairedDevice();
      this.socket.emit('leave-store', { deviceAuthorization: `Bearer ${pairedDevice.token}` });

      this.setPairedDevice(null);
    }
    return result;
  }

  getPairedDevice(): PairedDeviceDetails {
    return this.pairedDevice.value;
  }

  async connect(): Promise<boolean> {
    const pairedDevice = this.getPairedDevice();

    if (!pairedDevice || !pairedDevice.companyId) {
      return false;
    }

    const { result } = await this.apiService.merchantDeviceSetActive({ companyId: pairedDevice.companyId, isActive: 'true' }).toPromise();

    if (result) {
      this.setPairedDevice({ ...this.getPairedDevice(), active: true });
    }

    this.startPolling();

    return result;
  }

  async disconnect(): Promise<boolean> {
    this.stopPolling();

    const pairedDevice = this.getPairedDevice();

    if (!pairedDevice || !pairedDevice.companyId) {
      return false;
    }

    this.setPairedDevice({ ...this.getPairedDevice(), active: false });
    return true;
  }

  async acknowledgeScheduled({ id }: OrderViewDto): Promise<void> {
    await this.apiService.orderScheduleAlertAcknowledged(id).toPromise();
    await this.getActiveOrders();
  }

  async refundOrder(orderId: string, itemIds: string[]): Promise<void> {
    await this.apiService.orderRefund({
      orderId,
      RefundOrderRequestDto: {
        itemIds,
      },
    }).toPromise();

    await this.getInactiveOrders();
  }

  async setOrderStatus({ id: orderId, storeId }: OrderViewDto, status: OrderStatus, reason?: string): Promise<void> {
    if (!API_ACTION_MAP.hasOwnProperty(status)) {
      return;
    }

    await this.apiService.orderAcknowledge({
      orderId,
      AcknowledgeOrderRequestDto: { action: API_ACTION_MAP[status], storeId, reason },
    }).toPromise();

    const optimisticUpdateResult = this.updateOrderStatusLocal(orderId, status);
    if (optimisticUpdateResult === false) {
      await Promise.all([this.getActiveOrders(), this.getInactiveOrders()]);
    }
  }

  private updateOrderStatusLocal(orderId: string, status: OrderStatus): boolean {
    const orders = this._activeOrders.getValue();
    const updateOrderIdx = orders.findIndex(o => o.id === orderId);
    if (updateOrderIdx === -1) {
      return false;
    }
    orders[updateOrderIdx].orderStatus = status;

    if (!ACTIVE_ORDER_STATUSES.has(status)) {
      const completeOrders = this.completedOrders.getValue();
      const orderToMoveToComplete = orders[updateOrderIdx];
      completeOrders.unshift(orderToMoveToComplete);
      orders.splice(updateOrderIdx, 1);
      this.completedOrders.next(completeOrders);
    }

    this._activeOrders.next(orders);
    return true;
  }

  initAuthRefreshSubject(): void {
    PosDeviceService.authRefreshSubject = new Subject<boolean>();
    // don't need to call next as we've provided a value
  }

  private sendAndKillAuthRefreshSubject(value: boolean): void {
    if (PosDeviceService.authRefreshSubject) {
      // send the value to subscribers
      PosDeviceService.authRefreshSubject.next(value);
      // close the subject
      PosDeviceService.authRefreshSubject.complete();
      // free the resources
      PosDeviceService.authRefreshSubject.unsubscribe();
      // let it die / use for state detection
      PosDeviceService.authRefreshSubject = undefined;
    }
  }

  refreshActiveDeviceToken(force: boolean = false): Observable<boolean> {
    return fromPromise(this.isLoaded())
      .pipe(switchMap(() => {
        if (PosDeviceService.authRefreshSubject) {
          // In the middle of a refresh, send the observable
          return PosDeviceService.authRefreshSubject.asObservable();
        }
        this.initAuthRefreshSubject();

        const activeDevice = this.getPairedDevice();
        if (!activeDevice || !activeDevice.token || !activeDevice.refreshToken) {
          this.sendAndKillAuthRefreshSubject(false);
          return of(false);
        }

        const { exp } = this.jwtDecode(activeDevice.token);
        const expired = Date.now() / 1000 >= exp - JWT_REFRESH_EXPIRATION_PADDING;
        // const expired = !activeDevice.lastRefresh || activeDevice.lastRefresh + JWT_REFRESH_EXPIRATION_MS < Date.now();

        if (expired || force) {
          return this.apiService.merchantDeviceRefresh({
            deviceId: activeDevice.deviceId, refreshToken: activeDevice.refreshToken, public: true,
          })
            .pipe(
              tap(({ token, refreshToken }) => {
                activeDevice.lastRefresh = Date.now();
                this.setPairedDevice({ ...activeDevice, token, refreshToken });
                this.sendAndKillAuthRefreshSubject(true);
              }),
              map(() => true),
              catchError(err => {
                const errorMessage = safeGet(err, e => e.message || e.toString()) || 'Unknown error';
                this.telemetryService.logError(errorMessage);
                this.sendAndKillAuthRefreshSubject(false);
                return of(false);
              }),
            );

        }
        this.sendAndKillAuthRefreshSubject(false);
        return of(false);
      }));
  }

  async checkinDevice() {
    const pairedDevice = this.getPairedDevice();
    if (!pairedDevice || this.deviceCheckinInProgress) {
      return;
    }

    if (this.pollingMode === PollingMode.SOCKET) {
      this.deviceCheckinInProgress = true; // cleared by "device-check-in-ack"

      this.socket.emit('device-check-in', {
        deviceAuthorization: `Bearer ${this.getPairedDevice().token}`,
        companyId: pairedDevice.companyId,
        clientTimestamp: Date.now().toString(),
        buildNumber: safeGet(this.environmentVersions, e => e.build.number),
        nativeVersion: safeGet(this.environmentVersions, e => e.deviceNumber),
        nativeVersionCode: safeGet(this.environmentVersions, e => e.deviceCode),
      });

      this.updateFailureCount.next(this.updateFailureCount.value + 1); // cleared by "device-check-in-ack"
    } else {
      // fallback to HTTP
      try {
        this.deviceCheckinInProgress = true;

        const { active } = await this.apiService.merchantDeviceCheckinOnly({
          ifNoneMatch: '',
          companyId: pairedDevice.companyId,
          clientTimestamp: Date.now().toString(),
          buildNumber: safeGet(this.environmentVersions, e => e.build.number),
          nativeVersion: safeGet(this.environmentVersions, e => e.deviceNumber),
          nativeVersionCode: safeGet(this.environmentVersions, e => e.deviceCode),
        }).toPromise();

        this.setPairedDevice({ ...pairedDevice, active });
        this.lastCheckinTime.next(Date.now());
        this.updateFailureCount.next(0);
      } catch (e) {
        this.updateFailureCount.next(this.updateFailureCount.value + 1);
      }
    }

    this.deviceCheckinInProgress = false;
  }

  async getActiveOrders() {
    const pairedDevice = this.getPairedDevice();
    if (!pairedDevice || this.activeOrdersInProgress) {
      return;
    }

    try {
      this.activeOrdersInProgress = true;

      const activeOrdersResponse = await this.apiService.merchantDeviceActiveOrdersResponse({
        ifNoneMatch: this.activeOrdersEtag,
        companyId: pairedDevice.companyId,
      }).toPromise();
      this.activeOrdersEtag = activeOrdersResponse.headers.get('etag');

      if (activeOrdersResponse.status === 200) {
        this._activeOrders.next(activeOrdersResponse.body.orders);
      }

      this.updateFailureCount.next(0);
    } catch (e) {
      this.updateFailureCount.next(this.updateFailureCount.value + 1);
    }

    this.activeOrdersInProgress = false;
  }

  async getInactiveOrders() {
    const pairedDevice = this.getPairedDevice();
    if (!pairedDevice || this.inactiveOrdersInProgress) {
      return;
    }

    try {
      this.inactiveOrdersInProgress = true;

      const inactiveOrdersResponse = await this.apiService.merchantDeviceInactiveOrdersResponse({
        ifNoneMatch: this.inactiveOrdersEtag,
        companyId: pairedDevice.companyId,
      }).toPromise();
      this.inactiveOrdersEtag = inactiveOrdersResponse.headers.get('etag');

      if (inactiveOrdersResponse.status === 200) {
        this.completedOrders.next(inactiveOrdersResponse.body.orders);
      }

      this.updateFailureCount.next(0);
    } catch (e) {
      this.updateFailureCount.next(this.updateFailureCount.value + 1);
    }

    this.inactiveOrdersInProgress = false;
  }

  async getOrderStats() {
    const pairedDevice = this.getPairedDevice();
    if (!pairedDevice || this.orderStatsInProgress) {
      return;
    }

    try {
      this.orderStatsInProgress = true;

      const orderStatsResponse = await this.apiService.merchantDeviceOrderStatsResponse({
        ifNoneMatch: this.orderStatsEtag,
        companyId: pairedDevice.companyId,
      }).toPromise();
      this.orderStatsEtag = orderStatsResponse.headers.get('etag');

      if (orderStatsResponse.status === 200) {
        const { orderCount, totalSales, totalTips } = orderStatsResponse.body;
        this.storeStats.next({ orderCount, totalSales, totalTips });
      }

      this.updateFailureCount.next(0);
    } catch (e) {
      this.updateFailureCount.next(this.updateFailureCount.value + 1);
    }

    this.orderStatsInProgress = false;
  }

  private startPolling() {
    const pairedDevice = this.getPairedDevice();
    const { checkinPollInterval, orderPollInterval } = this.socketPosConfig;

    this.stopPolling();

    if (!pairedDevice) {
      return;
    }

    this.socket.emit('join-store', { deviceAuthorization: `Bearer ${pairedDevice.token}` });

    this.checkinTimer = new Timer(() => this.checkinDevice(), checkinPollInterval).start();

    this.orderTimer = new Timer(() => {
      this.getActiveOrders();
      this.getInactiveOrders();
      this.getOrderStats();
    }, orderPollInterval).start();

    this.pollingModeTimer = new Timer(() => {
      // Make sure volume is always maxed out so that the merchant can receive orders
      this.audioVolumeService.setMediaVolume(100);

      if (this.pollingMode === PollingMode.SOCKET && this.socket.ioSocket.disconnected) {
        this.pollingMode = PollingMode.POLLING;
        this.checkinTimer.reset(this.pollingPosConfig.checkinPollInterval);
        this.orderTimer.reset(this.pollingPosConfig.orderPollInterval);
      } else if (this.pollingMode === PollingMode.POLLING && this.socket.ioSocket.connected) {
        this.pollingMode = PollingMode.SOCKET;
        this.checkinTimer.reset(this.socketPosConfig.checkinPollInterval);
        this.orderTimer.reset(this.socketPosConfig.orderPollInterval);
      }
    }, POLLING_MODE_INTERVAL).start();
  }

  private stopPolling() {
    this.checkinTimer.stop();
    this.orderTimer.stop();
    this.pollingModeTimer.stop();
  }

  private async setPairedDevice(pairedDevice: PairedDeviceDetails): Promise<void> {
    this.pairedDevice.next(pairedDevice);
    await this.storageService.set<PairedDeviceDetails>(StorageKeys.MERCHANT_PAIRED_DEVICE_STORAGE_KEY, pairedDevice);
  }

  private async loadPairedDevice() {
    const pairedDevice = await this.storageService.get<PairedDeviceDetails>(StorageKeys.MERCHANT_PAIRED_DEVICE_STORAGE_KEY);

    if (safeGet(pairedDevice, d => d.storeId)) {
      await this.setPairedDevice(pairedDevice);
    }

    if (safeGet(pairedDevice, d => d.active)) {
      this.startPolling();
    }
    this.loadedPromiseResolver();
    // this.setTelemetryDeviceTag();
  }

  private async setDeviceVersionMetaData(): Promise<void> {
    this.environmentVersions = await this.environmentService.getVersions();
  }

  private populateOrderData(orders: OrderViewDto[]): PosOrderCard[] {
    return orders.map(order => {
      const isActive = ACTIVE_ORDER_STATUSES.has(order.orderStatus);
      return { ...order, isActive };
    });
  }

  private sortOrderItems(orders: OrderViewDto[]): OrderViewDto[] {
    return orders.map(order => {
      order.items.sort((a, b) => {
        const aVal = a.refunded ? -1 : 1;
        const bVal = b.refunded ? -1 : 1;
        return bVal - aVal;
      });
      return order;
    });
  }
}
