import * as i0 from "./merchant-bottombar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./merchant-bottombar.component";
import * as i4 from "../../services/merchant-bottombar/merchant-bottombar.service";
var styles_MerchantBottombarComponent = [i0.styles];
var RenderType_MerchantBottombarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantBottombarComponent, data: {} });
export { RenderType_MerchantBottombarComponent as RenderType_MerchantBottombarComponent };
function View_MerchantBottombarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "merchant-bottombar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bottombar; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MerchantBottombarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantBottombarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantBottombarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "merchant-bottombar", [], null, null, null, View_MerchantBottombarComponent_0, RenderType_MerchantBottombarComponent)), i1.ɵdid(1, 245760, null, 0, i3.MerchantBottombarComponent, [i4.MerchantBottombarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantBottombarComponentNgFactory = i1.ɵccf("merchant-bottombar", i3.MerchantBottombarComponent, View_MerchantBottombarComponent_Host_0, {}, {}, []);
export { MerchantBottombarComponentNgFactory as MerchantBottombarComponentNgFactory };
