import { OnInit, ElementRef } from '@angular/core';
import { confetti } from 'dom-confetti';
var NewUserWelcomeDialogComponent = (function () {
    function NewUserWelcomeDialogComponent(data) {
        this.customerName = data.firstName;
    }
    NewUserWelcomeDialogComponent.prototype.ngOnInit = function () {
        this.runAnimation();
    };
    NewUserWelcomeDialogComponent.prototype.runAnimation = function () {
        var _this = this;
        setTimeout(function () {
            confetti(_this.successTarget.nativeElement, { angle: 70, elementCount: 100 });
            confetti(_this.successTarget.nativeElement, { angle: 90, elementCount: 200 });
            confetti(_this.successTarget.nativeElement, { angle: 110, elementCount: 100 });
        }, 200);
    };
    return NewUserWelcomeDialogComponent;
}());
export { NewUserWelcomeDialogComponent };
