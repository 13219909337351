import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActiveCompanyService } from '../../services/active-company/active-company.service';
import { IdentityService } from 'src/app/shared/services/identity/identity.service';
import { takeWhile } from 'rxjs/operators';
import { EnvironmentService } from '../../../../../shared/services/environment/environment.service';
var MerchantSidebarComponent = (function () {
    function MerchantSidebarComponent(activeCompanyService, environmentService, identityService) {
        this.activeCompanyService = activeCompanyService;
        this.environmentService = environmentService;
        this.identityService = identityService;
        this.onToggleMenu = new EventEmitter();
        this.isProduction = false;
        this.isProduction = this.environmentService.getEnvName() === 'Production';
    }
    MerchantSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentActive = true;
        this.activeCompanyService.watch().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (_a) {
            var companies = _a.companies, active = _a.active;
            _this.activeCompanyId = active && active.id;
        });
        this.setUserRoles();
        this.identityService.watchActiveUser().pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (user) {
            _this.setUserRoles();
        });
    };
    MerchantSidebarComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    MerchantSidebarComponent.prototype.setUserRoles = function () {
        this.isUserAdmin = this.identityService.isActiveUserAdmin();
        this.isUserBiRole = this.identityService.isActiveUserRole('BiAdmin');
    };
    MerchantSidebarComponent.prototype.toggleSidNav = function () {
        this.onToggleMenu.emit();
    };
    return MerchantSidebarComponent;
}());
export { MerchantSidebarComponent };
